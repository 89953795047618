import { IonCheckbox, IonCol, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import { analyticsOutline, arrowForwardCircleOutline, caretDown, caretUp, chevronForward, document, documentAttachOutline, notifications, refreshOutline } from 'ionicons/icons';
import { useState,useEffect } from 'react';
import '../Main.css'
import { callInventoryComparisonList, callInventoryList } from '../../../dbconnections/Select';
import InventoryTable from '../../Tables/InventoryTable';
import StockItem from './StockItem';
import NewProjectForm from '../NewProject';
import FileLoader from '../../FileLoader/fileloader';
import InventoryTableComparison from '../../Tables/InventoryTableComparison';

const Inventory = (props:any) =>{

    const [getData, setData]                    = useState<any>()
    const [getOrderId, setOrderId]              = useState<any>(4)
    const [getTable, setTable]                  = useState<any>()
    const [getTableCompare, setTableCompare]    = useState<any>()
    const [getTableCompareView, setTableCompareView]    = useState<any>()
    const [getExceptions, setExceptions]        = useState<any>(0)
    const [getTableView, setTableView]          = useState<any>(true)
    const [getStockItem, setStockItem]          = useState<any>(0)
    const [getStockItemView, setStockItemView]  = useState<any>(false)
    const [getProduct, setProduct]               = useState<any>(false)
    const [getLoadFileView, setLoadFileView]     = useState<any>(false)
    const [getNewProjectForm, setNewProjectForm]                    = useState<any>(false)
    const [getProjectName, setProjectName]                          = useState<any>()
    const [getFileLoader, setFileLoader]                            = useState<any>()
    const [getCountryId, setCountryId]                              = useState<any>()
    const [getVersionId, setVersionId]                              = useState<any>()
    const [getMigrationId, setMigrationId]                          = useState<any>()

    const callInventory = () =>{
        var n:any = null
        setTable(n)
        var x:any = []
        x.push({primary_host:props.state.primary_host,database:"WPSSNOVAMETAL", order_id:getOrderId, exceptions_only:getExceptions})
        callInventoryList(x[0])
        .then(response =>{return response})
        .then(data =>{
            var options:any = []
            options.push(data.data)
            var table:any = <InventoryTable 
            data={options[0]} 
            filter={(e:any)=>{setOrderId(e); callInventory()}}
            id ={(e:any)=>{ alert(e.id);setView(2);setProduct(e.name); setStockItem(e.id)}}
            ></InventoryTable>
            setTable(table)
            setView(1)
        })
    }
    const callInventoryComparison = () =>{
        var n:any = null
        setTable(n)
        var x:any = []
        x.push({primary_host:props.state.primary_host,database:"WPSSNOVAMETAL", order_id:getOrderId, exceptions_only:getExceptions, version_id:getVersionId})
        callInventoryComparisonList(x[0])
        .then(response =>{return response})
        .then(data =>{
            var options:any = []
            options.push(data.data)
            var table:any = <InventoryTableComparison
            data={options[0]} 
            filter={(e:any)=>{setOrderId(e); callInventory()}}
            id ={(e:any)=>{ alert(e.id);setView(2);setProduct(e.name); setStockItem(e.id)}}
            ></InventoryTableComparison>
            setTableCompare(table)
            setView(5)
        })
    }
     
    const setView =(s:any)=>{
        resetView()
        switch(s){
            case 1: setTableView(true); break;
            case 2: setStockItemView(true); break;
            case 3: setLoadFileView(true);setNewProjectForm(true);break;
            case 4: setLoadFileView(true);setFileLoader(true);break;
            case 5: setLoadFileView(true);setTableCompareView(true);break;
        }
    }
    const resetView = () =>{
        setTableView(false)
        setStockItemView(false)
        setLoadFileView(false)
        setNewProjectForm(false);
        setFileLoader(false);
        setTableCompareView(false)
    }
    useEffect(() => {
        
        callInventory()
        
    }, []); 
    return(
        <div> 
            {getTableView &&
            <div>
                <IonRow>
                    <IonCol onClick={()=>{setView(3);}}>
                        Load Price Sheet &nbsp;
                        <IonIcon icon={documentAttachOutline} className="size-32"></IonIcon>
                    </IonCol>
                    <IonCol></IonCol>
                    <IonCol onClick={()=>{setStockItem(0); setProduct("All Sales"); setView(2)}} size="2" className="ion-text-right hover">
                        <IonLabel>View Chart</IonLabel>&nbsp;
                        <IonIcon icon={analyticsOutline} className="size-32"></IonIcon>
                    </IonCol>
                    <IonCol size="2" className="ion-text-right">
                        <IonItem>
                            <IonLabel>View Exceptions Only</IonLabel>
                            <IonCheckbox onClick={(e)=>{if(e.currentTarget.checked){setExceptions(1); callInventory()}else{setExceptions(0); callInventory()}}}></IonCheckbox>
                        </IonItem>
                    </IonCol>
                </IonRow>
            
                <IonRow>
                    <IonCol>
                        {getTable}
                    </IonCol>
                </IonRow>
            </div>
            }
            {getStockItemView &&
            <div>
                <IonRow>
                    <IonCol></IonCol>
                    <IonCol onClick={()=>{setView(1)}} size="2" className="ion-text-right hover">
                        <IonLabel>Return to Summary</IonLabel>&nbsp;
                        <IonIcon icon={refreshOutline} className="size-32"></IonIcon>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <StockItem id={getStockItem} primary_host={props.state.primary_host} product={getProduct}  />
                    </IonCol>
                </IonRow>
            </div>
            }
            {getLoadFileView &&
            <div>
                <IonRow>
                    <IonCol></IonCol>
                    <IonCol onClick={()=>{setView(1)}} size="2" className="ion-text-right hover">
                        <IonLabel>Return to Summary</IonLabel>&nbsp;
                        <IonIcon icon={refreshOutline} className="size-32"></IonIcon>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                    {getNewProjectForm &&
                        <IonRow>
                            <IonCol size="3"></IonCol>
                            <IonCol className="ion-padding" >
                                <div className="upload-form-left-division load-settings size-14 ion-padding">
                                    <NewProjectForm
                                        state={props} 
                                        application_id={-1}
                                        package_id={-1}
                                        setSystem={(e:any)=>{}} 
                                        setUpLoads={(e:any)=>{}} 
                                        setProjectName={(e:any)=>{setProjectName(e)}}
                                        setBillingCode={(e:any)=>{}}
                                        setCTT={(e:any)=>{}}
                                        setRounding={(e:any)=>{}}
                                        setCountry={(e:any)=>{setCountryId(e)}}
                                        setMigrationId={(e:any)=>{setMigrationId(e[0].migration_id);setCountryId(e[0].country_id);setView(4) }}
                                    />
                                </div>
                            </IonCol>
                            <IonCol size="3"></IonCol>
                        </IonRow>
                    }
                    {getFileLoader &&
                        <IonRow>
                            <IonCol>
                                <IonRow>
                                    <IonCol className='ion-padding ion-text-left'>
                                        <FileLoader
                                            state={props} 
                                            form_name="Price Sheet" 
                                            restore_id={1}
                                            ctt={0}
                                            rounding ={"0.00"}
                                            migration_name ={getProjectName}
                                            billing_code = {""}
                                            country = {getCountryId}
                                            migration_id = {getMigrationId}
                                            load_counter = {(e:any)=>{}}
                                            version_id={(e:any)=>{setVersionId(e);/*alert(e);callHeaders(e,1)*/ callInventoryComparison()}}
                                            file_name = {(e:any)=>{}}
                                            package_id ={ -1 }
                                            load_table ={(e:any)=>{setVersionId(e);}}
                                        />
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    }
                    {getTableCompareView &&
                        <IonRow>
                            <IonCol>{getTableCompare}</IonCol>
                        </IonRow>

                    }
                    </IonCol>
                </IonRow>
            </div>
            }
        </div>
    )
}
export default Inventory