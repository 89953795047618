import {  } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonIcon, IonCard, IonCardHeader, IonTitle, IonCardContent, IonFooter, IonHeader, IonToggle, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonButton, IonListHeader } from '@ionic/react';
import { checkmarkOutline, createOutline, pencilOutline, settingsOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { insertMigration } from '../../dbconnections/Insert';
import { selectVersion } from '../../dbconnections/Select';
import "../DataImport/Import.css"
import "./NewProject.css"

let projectName :any;
let ctt         :any;
let rounding    :any;
let job         :any;
let keyword     :any;
let country     :any;
let analyticalReviewsId:any;
let versionId   :any;

const NewProjectForm = (props:any) =>{
    const [getNewReviews, setNewReviews]                = useState<any>()
    const [getExistingReviews, setExistingReviews]      = useState<any>()
    const [getDuplicate, setDuplicate]                  = useState<any>()
    const [getProjectName, setProjectName]              = useState<any>()
    const [getBilling, setBilling]                      = useState<any>()
    const [getCountry, setCountry]                      = useState<any>()
    const [hideContinue, showContinue]                  = useState<any>()
    const [getCTT, setCTT]                              = useState<any>(0)
    const [getRounding, setRounding]                    = useState<any>('')
    const [getKeyword, setKeyword]                      = useState<any>('')
    const txtJournalProjectName:any                     = useRef<HTMLIonInputElement>();
    
    const ddlRounding:any                               = useRef<HTMLIonSelectElement>();
    const txtJobCode:any                                = useRef<HTMLIonInputElement>();
    const txtKeywordTextSearch:any                      = useRef<HTMLIonInputElement>();
    const ddlCountry:any                                = useRef<HTMLIonSelectElement>();
    const chkNoTB:any                                   = useRef<HTMLIonCheckboxElement>();
    const ddlDelimiter:any                              = useRef<HTMLIonSelectElement>();
    const ddlWorksheet:any                              = useRef<HTMLIonRadioGroupElement>();
    const ddlTextQualifier:any                          = useRef<HTMLIonSelectElement>();
    const txtFileDummy:any                              = useRef<HTMLIonInputElement>();
    const txtStartDate:any                              = useRef<HTMLIonDatetimeElement>();
    const txtEndDate:any                                = useRef<HTMLIonDatetimeElement>();
    const createProject = () =>{
        var x:any = []
        
        if(props.application_id == 67 || props.application_id == -1){
            rounding = 0
            ctt = 0
        } 

        x.push({
            project_name:txtJournalProjectName.current.value,
            billing_code:txtJobCode.current.value,
            ctt:ctt,
            country:ddlCountry.current.value,
            version_id:versionId,
            rounding:rounding,
            user_id:props.state.props.user_id,
            keyword:getKeyword,
            package_id:props.package_id,
            application_id:props.application_id,
            primary_host:props.state.props.primary_host
        })
        insertMigration(x[0])
        .then(response=>{console.log(response);return response})
        .then(data=>{
            var x:any = [];
            x.push({migration_id:data.migrationid,country_id:ddlCountry.current.value, project_name:txtJournalProjectName.current.value })
            if(props.application_id != -1)
                props.setMigrationId(data.migrationid)
            if(props.application_id == -1)
                props.setMigrationId(x)
            props.project_name(txtJournalProjectName.current.value)
        }) 
    }
    const formValidation = (form:any) =>{
        var x:any = document.getElementsByClassName("check")
        switch(form){
            case 1: 
            if(x.length >= 3){
                showContinue(true)
            }else{
                showContinue(false)
            }
            break;
        }
    }
    const resetFormValidation =()=>{
        showContinue(false)
    }
    const [getMateriality   , setMateriality]               =useState<boolean>(false)
    //const setMigrationData = async() =>{
    //    //file = txtFile.current.value;
    //    if(txtJournalProjectName.current != null){
    //        projectName = txtJournalProjectName.current.value;
    //        ctt = 10000;//txtJournalMaterialityValue.current.value;
    //        rounding = '000';//ddlRounding.current.value;
    //        job = txtJobCode.current.value;
    //        keyword = 0;//txtKeywordTextSearch.current.value;
    //        country = ddlCountry.current.value;
    //        selectVersion(props)
    //        .then(response=>{return response})
    //        .then(data=>{
    //            versionId = data.version_id
    //        })
    //    }
    //}
    return (
            <div className="general-form-left-division size-12">
                <div className="ion-text-center">
                    <IonRow>
                        <IonCol>
                            <IonIcon icon={createOutline} className="text-gt size-48">*</IonIcon>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center size-20">
                            New Project Details
                        </IonCol>
                    </IonRow>
                </div>
                {/*<IonRow className="ion-padding">
                    <IonCol size="8">
                        <h6><b>Update Existing Analytical Reviews</b></h6>
                    </IonCol>
                    <IonCol size="4">
                        <b>No</b>&nbsp;
                        <IonToggle onClick={ev=>{ 
                                if(ev.currentTarget.checked){
                                    setNewReviews(false); 
                                    setExistingReviews(true)
                                }else{
                                    setNewReviews(true); 
                                    setExistingReviews(false);
                                    analyticalReviewsId = 0;
                                }
                            }}>
                        </IonToggle>&nbsp;<b>Yes</b>
                    </IonCol>
                </IonRow>*/}
                <IonRow className="size-12"> 
                    
                    <IonCol size="10">
                        <IonItem>
                            <IonLabel className="size-12" position="floating">1) Project Name / Company</IonLabel>
                            <IonInput ref={txtJournalProjectName} className={getDuplicate} type="text" onKeyUp={
                                ev=>{const getDuplicate =  async () =>{
                                        if(ev.currentTarget!.value!.toString().length > 2){
                                            fetch(props.state.primary_host+"/journals/dbconnect/select.jsp?etl=findduplicateprojectname"+
                                                "&name="+txtJournalProjectName.current!.value+
                                                "&contact_id="+props.state.user_id                                            ,
                                                {
                                                    headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                                                }
                                            ) 
                                            .then(response => response.json())
                                            .then(d => {
                                                console.log(d)
                                                console.log(d.data[0].found)
                                                console.log("Duplicate:"+d.data[0].found)
                                                if(d.data[0].found==0){
                                                    setDuplicate("input-text-size-1-black")
                                                    setProjectName(true)
                                                }else{
                                                    setDuplicate("input-text-size-1-red")
                                                    setProjectName(false)
                                                }

                                            })
                                            .catch(error=>{
                                                //console.log(error)
                                                setDuplicate("input-text-size-1-black")
                                                setProjectName(true)
                                            })
                                        }
                                        
                                    }
                                    getDuplicate()
                                }}>
                            </IonInput>
                        </IonItem>
                    </IonCol>
                    {getProjectName &&
                    <IonCol className="check">
                        <IonIcon className="size-20 ion-padding" color="success" icon={checkmarkOutline}></IonIcon>
                    </IonCol>
                    }
                </IonRow>
                <IonRow>
                    <IonCol size="10">
                        <IonItem>
                            <IonLabel className="size-12" position="floating">2) Billing / Job Code</IonLabel>
                            <IonInput onKeyUp={ev=>{if(ev.currentTarget!.value!.toString().length > 0){setBilling(true)}else{setBilling(false)}; formValidation(1)}} ref={txtJobCode} className="input-text-size-1" type="text"></IonInput>
                        </IonItem>
                    </IonCol>
                    {getBilling &&
                    <IonCol className="check">
                        <IonIcon  color="success"  className="size-20 ion-padding" icon={checkmarkOutline}></IonIcon>
                    </IonCol>
                    }
                </IonRow>
                
                <IonRow>
                    <IonCol size="10">
                        <IonItem>
                            <IonLabel className="size-12" position="floating">3) Select Country</IonLabel>
                            <IonSelect ref={ddlCountry} className="input-text-size-1" onIonChange={ev=>{if(ev.detail.value != null){setCountry(true)}else{setCountry(false)}; formValidation(1)}}>
                                <IonSelectOption  value="90">Kenya</IonSelectOption>
                                <IonSelectOption  value="1099">South Africa</IonSelectOption>
                                <IonSelectOption  value="1099">Rwanda</IonSelectOption>
                                <IonSelectOption  value="89">Zimbabwe</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                    {getCountry &&
                    <IonCol className="check">
                        <IonIcon color="success"  className="size-20 ion-padding" icon={checkmarkOutline}></IonIcon>
                    </IonCol>
                    }
                </IonRow>
                {(props.application_id == 66)&&
                <IonRow className="">
                    <IonCol size="4">
                        <IonItem>
                            <IonLabel className="label-header" position="floating">4) Materiality</IonLabel>
                            <IonInput  className="input-text-size-1" type="number" onKeyUp={ev=>{
                                if(ev.currentTarget!.value!.toString().length > 0){
                                    setMateriality(true)
                                    ctt = ev.currentTarget!.value
                                }else{
                                    setMateriality(false)
                                }; 
                                formValidation(1)}}></IonInput>
                        </IonItem>
                    </IonCol>
                    {getMateriality &&
                    <IonCol className="check">
                        <IonIcon color="success" className="size-20 ion-padding" icon={checkmarkOutline}></IonIcon>
                    </IonCol>
                    }
                    <IonCol size="4">
                        <IonItem>
                            <IonLabel className="label-header"  position="floating">5) Select Rounding Option</IonLabel>
                            <IonSelect ref={ddlRounding} className="input-text-size-1" onIonChange={ev=>{if(ev.detail.value != null){rounding = ev.detail.value ;setRounding(true)}else{setRounding(false)}; formValidation(1)}} >
                                <IonListHeader>Select Round Option</IonListHeader>
                                <IonSelectOption value="0">0</IonSelectOption>
                                <IonSelectOption value="00">00</IonSelectOption>
                                <IonSelectOption value="000">000</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                    {getRounding &&
                    <IonCol className="check">
                        <IonIcon color="success" className="size-20 ion-padding" icon={checkmarkOutline}></IonIcon>
                    </IonCol>
                    }
                </IonRow>    
                }
                {/*<IonRow className="">
                    <IonCol size="10">
                        <IonItem>
                            <IonLabel className="label-header" ><h2><b>6) Include Word Search</b></h2></IonLabel>
                            <IonCheckbox ref={txtKeywordTextSearch} className="input-text-size-1"></IonCheckbox>
                        </IonItem>
                    </IonCol>
                </IonRow>*/}
                {hideContinue &&
                
                    <IonRow className="ion-padding">
                        <IonCol size="4" className="ion-text-left custom-account-text-icon">
                            <div className="size-14 ion-text-center"  onClick={e => {props.setSystem(0)}}>Cancel</div>
                        </IonCol>
                        <IonCol size="4"></IonCol>
                        <IonCol size="4" className="ion-text-right custom-account-text-icon ">
                            <div className="size-14 ion-text-center"  onClick={e => {
                                props.setUpLoads(3);
                                props.setCTT(ctt)
                                props.setProjectName(txtJournalProjectName.current.value)
                                props.setRounding(rounding)
                                props.setBillingCode(txtJobCode.current.value)
                                props.setCountry(ddlCountry.current.value)
                                createProject()
                                /*setMigrationData()*/
                                }}>Create Project</div>
                        </IonCol>
                    </IonRow>
                
                }
            </div>
        )
    
}
export default NewProjectForm;