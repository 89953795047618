var resultSet:any;
export const insertMigration = async (props:any) =>{
    var custom = 0
    if(props.package_id == 1163 || props.package_id == 113 || props.package_id == 112 || props.package_id == 2 || props.package_id == -1)
    custom = 1
    let option:any = [];
    console.log(
        "&versionid="+props.version_id+
        "&migration_name="+props.project_name+
        "&contactid="+props.user_id+
        "&application_id="+props.application_id+
        "&package_id="+props.package_id+
        "&ctt="+props.ctt+
        "&rounding="+props.rounding+
        "&custom_id="+custom+
        "&billing_code="+props.billing_code+
        "&country="+props.country+
        "&notb="+0+
        "&keywordsearch="+props.keyword
    )
    resultSet = fetch(props.primary_host+'/dmz/dbconnect/insert.jsp?etl=insertmigrationnew'+
        "&versionid="+0+//props.version_id+
        "&migration_name="+props.project_name+
        "&contactid="+props.user_id+
        "&application_id="+props.application_id+
        "&package_id="+props.package_id+
        "&ctt="+props.ctt+
        "&rounding="+props.rounding+
        "&custom_id="+custom+
        "&billing_code="+props.billing_code+
        "&country="+props.country+
        "&notb="+0+
        "&keywordsearch="+props.keyword 
    ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
    ) 
    .then(response => response.json())
    .then(d => {
      return d.data[0];
    })
    .catch(error =>{
        alert(error)
    })
    return resultSet
}
export const insertMigrationManifest = async (props:any) =>{
    console.log(props)
    let option:any = [];
    resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?mgx=insert_migration_manifest'+
    "&column_id="+props.column_id+
    "&version_id="+props.version_id+
    "&step_id="+props.step_id+
    "&action_id="+props.action_id+
    "&file_restore_id="+props.file_restore_id+
    "&migration_id="+props.migration_id,
    {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
    }
    ) 
    .then(response => response.json())
    .then(d => {
        return d
    })
    .catch(error =>{
        alert(error)
    })
    return resultSet
  }