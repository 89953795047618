
import { IonSpinner, IonListHeader, IonButton,IonCheckbox, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonTextarea, IonBadge, IonFooter } from '@ionic/react';
import { alarmOutline, alertCircleSharp, analytics, analyticsOutline, analyticsSharp, apps, arrowBackSharp, arrowForwardSharp, bluetoothSharp, businessOutline, calendarOutline, checkmarkCircle, checkmarkCircleSharp, checkmarkDone, checkmarkDoneCircle, checkmarkOutline, closeOutline, cloudUploadOutline, cogOutline, copySharp, exitOutline, filterSharp, gridOutline, hammerOutline, homeOutline, listOutline, listSharp, lockClosed, lockOpen, logInOutline, logOut, logOutOutline, mailOutline, mailSharp, pencilOutline, peopleCircle, peopleCircleSharp, peopleOutline, personOutline, personSharp, push, returnUpBack, sendOutline, settingsOutline, settingsSharp, stopCircleSharp, trashBin, trashBinSharp, trashOutline, walletOutline, warningOutline, warningSharp, wifiOutline } from 'ionicons/icons';
import React,{ useRef, useState } from 'react';
import './GlobalSetting.css'
import '../../theme/fonts/poppins-cufonfonts-webfont/Poppins-Regular.woff'
import {
	PayPalScriptProvider,
	PayPalButtons,
} from "@paypal/react-paypal-js";

let paypalAmount:any=0.00;
let payPalCurrency:any="USD";
let paypalClientId:any="AV-7sScDkhVqgsQaICd1fNC9PZNuUoosF4BDAQJgiYph4nnYL6WJS9-SDx5cUlUPpWDQMqXFrgInvDIo"
//let paypalClientId:any="AVJ-a35WufBEulnVXrr2DBUl12ONvqOuDtlaMCusc-NvDCRBJyZsIGgjjHOheAt8t7VE_8qdGasdMZpQ"
let paypalPlan:string = "";
var contact_type_id:any = "0"
var contact_id:any = "0"
var parent_contact_id:any = "0"
var tax_number:any = ""
var vat_number:any = ""
var contact_number:any = ""
var country:any = ""
var organization:any =0;
var organization_id:any = 0;
var forename:any="";
var surname:any="";
var email:any="";
var number:any = ""
var organization:any="";
var application_id:any=0;
var reference_id:any= 0;
var package_id:any=0;
var amount:any = 0;
var package_name:any = ''
var analytical_reviews_id:any = 0
var units:any = 0;
var plan_id1:any = "P-28J600857G994393NMJOVJEY"
var plan_id2:any = "P-28J600857G994393NMJOVJEY"
//var plan_id2:any = ""
const addCommas = (nStr:any) =>{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}
const UserSettings = (props:any) =>{
    contact_id = props.userId;
    const [getShowLoading, setShowLoading]                      = useState<boolean>(false)
    const [hideSettingsHome, showSettingsHome]          = useState<boolean>(false)
    const [hideUserDetails, showUserDetails]            = useState<boolean>(false)
    const [hidePayPalPayment, showPayPalPayment]        = useState<boolean>(false)
    const [hideAccountStatus, showAccountStatus]        = useState<boolean>(false)
    const [getAccountStatus, setAccountStatus]          = useState<HTMLIonRowElement>() 
    const [getARPaymentStatus, setARPaymentStatus]      = useState<string>("Trial Period")
    const [getJETsPaymentStatus, setJETsPaymentStatus]  = useState<string>("Trial Period")
    const [getGoProPaymentStatus, setGoProPaymentStatus] = useState<string>("Trial Period")
    const [getARMonthsLoaded, setARMonthsLoaded]        = useState<string>("0")
    const [getJETsMonthsLoaded, setJETsMonthsLoaded]    = useState<string>("0")
    const [getGoProMonthsLoaded, setGoProMonthsLoaded]  = useState<string>("0")
    const [getPackageName, setPackageName]              = useState<string>("")
    const [getAmount, setAmount]                        = useState<any>()
    const [getUnits, setUnits]                          = useState<number>(0)
    const [getSubscription1, setSubscription1]          = useState<boolean>(false)
    const [getSubscription2, setSubscription2]          = useState<boolean>(false)
    const [hideDashboard, showDashboard]                = useState<boolean>(false)
    const [getActivity, setActivity]                    = useState<boolean>(false)

    const setView = (v:any) =>{
        resetView()
        switch(v){
            case 0: showDashboard(true); showAccountStatus(true); callAnalyticalReviewsMonths(); break;
            case 1: showSettingsHome(true);break;
            case 2: showAccountStatus(true);break;
            case 3: showPayPalPayment(true);showDashboard(true);break;
            case 4: showUserDetails(true);break;

        }
    }
    const resetView = () =>{
        showAccountStatus(false)
        showPayPalPayment(false)
        showSettingsHome(false)
        showUserDetails(false)
        showDashboard(false)
    }
    const getSubscription = (qty:any) =>{
        setSubscription1(false)
        setSubscription2(false)
        qty = units
        console.log("quantity:"+qty)
        if(qty/1 >0 && qty/1 < 10){
            setSubscription1(true)
        }
        if(qty/1 >9 ){
            setSubscription2(true)
        }
    }
    const SubscriptionButtonWrapper1 = () => {
        return (
            <PayPalButtons
                
                createSubscription={(data, actions) => {
                        return actions.subscription
                        .create(
                                {
                                plan_id: "P-28J600857G994393NMJOVJEY",
                                quantity: units,
                            });
                        }}
                        style={{
                            shape: 'pill',
                            color: 'gold',
                            layout: 'horizontal',
                            label: "subscribe",
                        }}


            />
        );

        
    }
    const SubscriptionButtonWrapper2 = () => {
        return (
            <PayPalButtons
                createSubscription={(data, details) => paypalSubscribe(data, details)}
                onApprove={(data, actions) => paypalOnApprove(data, actions)}
                onError={(err) => paypalOnError(err)}
                style={{
                    shape: 'pill',
                    color: 'gold',
                    layout: 'horizontal',
                    label: "subscribe",
                }}
            />
        );
    }
    const paypalSubscribe = (data:any, actions:any) => {
        return actions.subscription.create({
        plan_id: plan_id2,
        custom_id:analytical_reviews_id,
        plan_overridden: true,
        payment_preferences: {
                setup_fee: {
                    value :"10",
                    currency_code: "USD"
                }
            }
        });
    };
    const paypalOnApprove = (data:any, detail:any) => {
        // call the backend api to store transaction details
        console.log("Payapl approved")
        console.log(data.subscriptionID)
        console.log(data)
        console.log(detail)
        return(data);
    };
    const paypalOnError = (err:any) => {
        console.log("Error")
    }
    
    const callContact = () =>{
        var options:any = [] 
        var err:any;
        fetch(props.primaryhost+"/dbconnect/dbdynamic.jsp?gt=select_contact"+
        "&parent_contact_id="+parent_contact_id+
        "&contact_id="+contact_id+
        "&contact_type_id="+contact_type_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            forename                = data.data[0].forename;
            surname                 = data.data[0].surname;
            email                   = data.data[0].email;
            number                  = data.data[0].number;
            organization            = data.data[0].company;
            tax_number              = data.data[0].tax_number;
            vat_number              = data.data[0].vat_number;
            country                 = data.data[0].country;
            organization_id         = data.data[0].company_id;
        })
        .catch(error=>{
            alert(error)
        })
    }
    const [getCountry, setCountry]                  = useState<HTMLIonSelectOptionElement>()
    const callCountry = () =>{
        var options:any = [] 
        var err:any;
        fetch(props.primaryhost+"/dbconnect/dbdynamic.jsp?dbo=select_country"+
        "&country_id="+0
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            options.push(data.data)
            var country:any = options[0].map((x:any, i:any)=>{
                return(
                    <IonSelectOption key={i} value={x.countryid}>{x.country}</IonSelectOption>
                )
            })
            setCountry(country)
            callContact()
        })
        .catch(error=>{
            alert(error)
        })

    }
    const txtOrganization:any       = useRef<HTMLDivElement>(null);
    const txtTaxNumber:any          = useRef<HTMLDivElement>(null);
    const txtContactNumber:any      = useRef<HTMLDivElement>(null);
    const txtVATNumber:any          = useRef<HTMLDivElement>(null);
    const ddlCountry:any            = useRef<HTMLDivElement>(null);
    const txtForename:any           = useRef<HTMLDivElement>(null);
    const txtSurname:any            = useRef<HTMLDivElement>(null);
    const txtEmail:any              = useRef<HTMLDivElement>(null);
    const txtNumber:any             = useRef<HTMLDivElement>(null);

    const setContactDetails = () =>{
        setShowLoading(true)
        fetch(props.primaryhost+"/dbconnect/dbdynamic.jsp?dbo=insert_contact_details"+
        "&contact_id="+contact_id+
        "&forename="+txtForename.current!.innerText+
        "&surname="+txtSurname.current!.innerText+
        "&email="+txtEmail.current!.innerText+
        "&number="+txtNumber.current!.innerText
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            setShowLoading(false)
            organization_id = data.data[0].contact_id
            callCountry()
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }

    
    const setCompanyDetails = () =>{
        setShowLoading(true)
        fetch(props.primaryhost+"/dbconnect/dbdynamic.jsp?dbo=insert_company_details"+
        "&contact_id="+organization_id+
        "&country_id="+ddlCountry.current!.value+
        "&name="+txtOrganization.current!.innerText+
        "&tax_number="+txtTaxNumber.current!.innerText+
        "&vat_number="+txtVATNumber.current!.innerText
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            setShowLoading(false)
            callCountry()
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }
    const callPriceList = () =>{
        var options:any = [] 
        fetch(props.primaryhost+"ar/dbconnect/select.jsp?etl=select_price_list_prices"+
        "&package_id="+1,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            package_name = data.data[0].package
            amount= data.data[0].amount
            setAmount(data.data[0].amount)
            setPackageName(data.data[0].package)
            getSubscription(getUnits)
        })
        .catch(error=>{
            alert(error)
        })
    }
    const callAnalyticalReviewsMonths = () =>{
        var options:any = [] 
        fetch(props.primaryhost+"ar/dbconnect/select.jsp?etl=select_analytical_reviews_months"+
        "&contact_id="+contact_id,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            console.log(data)
            options.push(data.data)
            var header:any =(<IonRow key={-1} className="size-20 ion-text-bold">
                <IonCol size="4">Project</IonCol>
                <IonCol className="ion-text-right">Months</IonCol>
                <IonCol className="ion-text-right">Subs.</IonCol>
                <IonCol className="ion-text-right">Paid</IonCol>
                <IonCol className="ion-text-center">Status</IonCol>
                <IonCol className="ion-text-right"></IonCol>
            </IonRow>)
            var history:any = options[0].map((x:any, i:number)=>{
                return(
                    <IonRow className="size-18 ar-card-line text-black" key={i}>
                        <IonCol size="4">{x.name}</IonCol>
                        <IonCol className="ion-text-right">{x.months}</IonCol>
                        <IonCol className="ion-text-right">{x.paid_months}</IonCol>
                        <IonCol className="ion-text-right">{x.balance}</IonCol>
                        <IonCol className="ion-text-center">
                            {x.lock == 0?
                                <IonIcon icon={lockOpen} className="size-24 text-green"></IonIcon>
                                :
                                <IonIcon icon={lockClosed} className="size-24 text-grey"></IonIcon>
                            }
                        </IonCol>
                        <IonCol className="ion-text-right" onClick={()=>{analytical_reviews_id = reference_id=x.id;application_id=x.appliction_id;setView(3);units = (x.balance/1)*-1;setUnits(((x.balance/1)*-1));callPriceList()}}>
                            <div className="pay-button-launch size-14 text-green">Pay Now</div>
                        </IonCol>
                    </IonRow> 
                )
            })
            var combined:any =[header,history]
            setAccountStatus(combined)
        })
    }
    
    React.useEffect(() => {
        callAnalyticalReviewsMonths()
        setView(0)
        callCountry()
      }, []);
    return(
    <div>
        <IonLoading onWillDismiss={() => setShowLoading(false)} 
            isOpen={getShowLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."} ></IonLoading>
        <IonRow className="">
            <IonRow>
                <IonCol className="">
                    <div className="settings-options ion-padding size-16" onClick={()=>{setView(0)}}>
                        <IonRow>
                            <IonHeader>
                                <IonIcon icon={apps} className="size-24"></IonIcon>&nbsp;
                                Settings Dashboard
                            </IonHeader>
                        </IonRow>
                        <div className="ion-text-center ion-padding setting-content">
                            <IonRow>
                                <IonCol>
                                    <IonIcon icon={analytics} className="size-20"></IonIcon>
                                </IonCol>
                            </IonRow>
                            <IonFooter>
                                <IonRow>
                                    <IonCol className="size-14">Summary view of account status</IonCol>
                                </IonRow>
                            </IonFooter>
                        </div>
                    </div>
                </IonCol>
                <IonCol className="ion-padding">
                    <div className="settings-options ion-padding size-16" onClick={()=>{setView(4)}}>
                        <IonRow>
                            <IonHeader>
                                <IonIcon icon={apps} className="size-24"></IonIcon>&nbsp;
                                Profile Management
                            </IonHeader>
                        </IonRow>
                        <div className="ion-text-center ion-padding setting-content">
                            <IonRow>
                                <IonCol>
                                    <IonIcon icon={businessOutline} className="size-20"></IonIcon>
                                </IonCol>
                            </IonRow>
                            <IonFooter>
                                <IonRow>
                                    <IonCol className="size-14">Maintain up to date company information</IonCol>
                                </IonRow>
                            </IonFooter>
                        </div>
                    </div>
                </IonCol>
                <IonCol className="ion-padding">
                    <div className="settings-options ion-padding size-16">
                        <IonRow>
                            <IonHeader>
                                <IonIcon icon={apps} className="size-24"></IonIcon>&nbsp;
                                User Manager
                            </IonHeader>
                        </IonRow>
                        <div className="ion-text-center ion-padding setting-content">
                            <IonRow>
                                <IonCol>
                                    <IonIcon icon={peopleOutline} className="size-20"></IonIcon>
                                </IonCol>
                            </IonRow>
                            <IonFooter>
                                <IonRow>
                                    <IonCol className="size-14">Grant access to colleagues, clients & investors</IonCol>
                                </IonRow>
                            </IonFooter>
                        </div>
                    </div>
                </IonCol>
                <IonCol className="ion-padding">
                    <div className="settings-options ion-padding size-16">
                        <IonRow>
                            <IonHeader>
                                <IonIcon icon={apps} className="size-24"></IonIcon>&nbsp;
                                Financing & Investments
                            </IonHeader>
                        </IonRow>
                        <div className="ion-text-center ion-padding setting-content">
                            <IonRow>
                                <IonCol>
                                    <IonIcon icon={walletOutline} className="size-20"></IonIcon>
                                </IonCol>
                            </IonRow>
                            <IonFooter>
                                <IonRow>
                                    <IonCol className="size-14">Gain access to Venture Capital & Finance firms are keen to provide funds for your business</IonCol>
                                </IonRow>
                            </IonFooter>
                        </div>
                    </div>
                </IonCol>
            </IonRow>
        </IonRow>
        {hideDashboard &&
        <IonRow className="">
            <div>
                <div className="dash-1 dash ion-text-center">
                    <IonRow>
                        <IonCol className="ion-text-center ion-padding ">
                            <IonIcon icon={peopleOutline} className="size-24 icon-gt"></IonIcon>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="size-16 text-grey">
                            Active Users
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRow className="size-32 icon-gt">
                                <IonCol>{10}</IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </div>
            </div>
            <div>
                <div className="dash-2 dash ion-text-center">
                    <IonRow>
                        <IonCol className="ion-text-center ion-padding">
                            <IonIcon icon={alarmOutline} className="size-24 icon-gt"></IonIcon>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="size-16 text-grey">
                            Active Subscriptions
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRow className="size-32 icon-gt">
                                <IonCol>{10}</IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </div>
                <div className="dash-3 dash ion-text-center">
                    <IonRow>
                        <IonCol className="ion-text-center ion-padding">
                            <IonIcon icon={listOutline} className="size-24 icon-gt"></IonIcon>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="size-16 text-grey">
                            New Projects
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRow className="size-32 icon-gt">
                                <IonCol>{10}</IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </div>
                <div className="dash-4 dash ion-text-center">
                    <IonRow>
                        <IonCol className="ion-text-center ion-padding">
                            <IonIcon icon={cloudUploadOutline} className="size-24 icon-gt"></IonIcon>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="size-16 text-grey">
                            Total Uploads
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRow className="size-32 icon-gt">
                                <IonCol>{10}</IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </div>
                <div className="dash-5 dash ">
                    <IonRow>
                        <IonHeader className="ion-padding">
                            <IonCol className='size-16'>Project Billing Manager</IonCol>
                        </IonHeader>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                            {hideAccountStatus &&
                                <div className="account-settings-paypal-card ion-padding">
                                    <IonRow>
                                        <IonCol className="">
                                            
                                            <IonContent className="paypal-content">
                                                {getAccountStatus}
                                            </IonContent>
                                        </IonCol>
                                    </IonRow>
                                </div>
                                }
                                {hidePayPalPayment &&
                                    <div className="account-settings-paypal-card ion-padding">
                                        <IonContent className="paypal-content">
                                            <IonRow className="ion-padding">
                                                <IonCol className="size-16 ion-text-left"><b>Description</b></IonCol>
                                                <IonCol className="size-16 ion-text-right"><b>Unit/Months</b></IonCol>
                                                <IonCol className="size-16 ion-text-right"><b>Rate/Month</b></IonCol>
                                                <IonCol className="size-16 ion-text-right"><b>Total</b></IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding">
                                                <IonCol className="size-16 ion-text-left">{getPackageName}</IonCol>
                                                <IonCol className="size-16 ion-text-right">{getUnits}</IonCol>
                                                <IonCol className="size-16 ion-text-right">{addCommas(getAmount)}</IonCol>
                                                <IonCol className="size-16 ion-text-right">{addCommas((getAmount/1)*(getUnits/1))}</IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding">
                                                <IonCol className="size-16 ion-text-center">{}</IonCol>
                                                <IonCol className="size-16 ion-text-center">{}</IonCol>
                                                <IonCol className="size-16 ion-text-center">{}</IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding">
                                                <IonCol size="4"></IonCol>
                                                <IonCol size="4" className="ion-text-center">
                                                    <PayPalScriptProvider
                                                        options={{
                                                            "client-id": paypalClientId,
                                                            components: "buttons",
                                                            intent: "subscription",
                                                            vault: true,
                                                        }}
                                                        >
                                                        {getSubscription2 &&
                                                            <SubscriptionButtonWrapper2 />
                                                        }
                                                        
                                                    </PayPalScriptProvider>
                                                </IonCol>
                                                
                                            </IonRow>
                                        </IonContent>
                                    </div> 
                                }
                            </div>
                        </IonCol>
                    </IonRow>
                </div>
            </div> 
        </IonRow>       
        }
        {hideUserDetails &&
        <IonRow>
            <div className=" account-settings-card-user ion-padding">
                <IonHeader>
                    <IonRow>
                        <IonCol className='size-16'>
                            <IonIcon icon={personOutline} className="size-32 icon-gt"></IonIcon>&nbsp;
                            User Profile
                        </IonCol>
                    </IonRow>
                </IonHeader>
                <IonContent className="user-content">
                    <IonRow>
                        <IonCol><div className="size-14"><b>Forename</b></div></IonCol>
                        <IonCol><div className="size-14"><b>Surname</b></div></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><div contentEditable={true} ref={txtForename} onClick={(e)=>{}} className="input-style-1 size-14">{forename}</div></IonCol>
                        <IonCol><div contentEditable={true} ref={txtSurname} className="input-style-1 size-14">{surname}</div></IonCol>
                    </IonRow>
                    <IonRow>&nbsp;</IonRow>
                    <IonRow>
                        <IonCol><div className="size-14"><b>Email</b></div></IonCol>
                        <IonCol><div className="size-14"><b>Mobile</b></div></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><div  contentEditable={true} ref={txtEmail} className="input-style-1 size-14">{email}</div></IonCol>
                        <IonCol><div  contentEditable={true} ref={txtNumber} className="input-style-1 size-14">{number}</div></IonCol>
                    </IonRow>
                </IonContent>
                <IonFooter>
                    <IonRow>
                        <IonCol className="ion-text-right">
                            <div className='launch-button-main-login-launch' onClick={()=>{setContactDetails()}}>&nbsp;Update&nbsp;</div>
                        </IonCol>
                    </IonRow>
                </IonFooter>
            </div>
            <div className=" account-settings-card-organization ion-padding">
                <IonHeader>
                    <IonRow>
                        <IonCol className='size-14'>
                            <IonIcon icon={personOutline} className="size-32 icon-gt"></IonIcon>&nbsp;
                            Organizational Profile
                        </IonCol>
                    </IonRow>
                </IonHeader>
                <IonContent className="user-content">
                    <IonRow>
                        <IonCol><div className="size-14"><b>Organization</b></div></IonCol>
                        <IonCol><div className="size-14"><b>Tax Number</b></div></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><div contentEditable={true} ref={txtOrganization} className="input-style-1 size-14">{organization}</div></IonCol>
                        <IonCol><div contentEditable={true} ref={txtTaxNumber} className="input-style-1 size-14">{tax_number}</div></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><div className="size-14"><b>Contact Number</b></div></IonCol>
                        <IonCol><div className="size-14"><b>VAT Number</b></div></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><div contentEditable={true} ref={txtContactNumber} className="input-style-1 size-14">{contact_number}</div></IonCol>
                        <IonCol><div contentEditable={true} ref={txtVATNumber} className="input-style-1 size-14">{vat_number}</div></IonCol>
                    </IonRow>
                    <IonRow>&nbsp;</IonRow>
                    <IonRow>
                        <IonCol><div className="size-14"><b>Country</b></div></IonCol>
                        <IonCol><div className="size-14"><b></b></div></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div  contentEditable={true} className="size-14">
                                <IonItem>
                                    <IonTitle  className="size-14">Country</IonTitle>
                                    <IonSelect ref={ddlCountry} onIonChange={(e)=>{}}>{getCountry}</IonSelect>
                                </IonItem>
                            </div>
                        </IonCol>
                        <IonCol></IonCol>
                    </IonRow>
                    
                </IonContent>
                <IonFooter>
                    <IonRow>
                        <IonCol className="ion-text-right">
                            <div className='launch-button-main-login-launch' onClick={()=>{setCompanyDetails()}}>Update</div>
                        </IonCol>
                    </IonRow>
                </IonFooter>
            </div>
        </IonRow>
        }
        
        </div>
        
    )
}
export default UserSettings