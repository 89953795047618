import { IonButton,IonCheckbox, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonTextarea, IonBadge } from '@ionic/react';
import { alertCircleSharp, analytics, analyticsOutline, analyticsSharp, arrowBackSharp, arrowForwardSharp, bluetoothSharp, calendarOutline, checkmarkCircle, checkmarkCircleSharp, checkmarkDone, checkmarkDoneCircle, checkmarkOutline, closeOutline, cogOutline, copySharp, exitOutline, filterSharp, gridOutline, hammerOutline, homeOutline, listSharp, logInOutline, logOut, logOutOutline, mailOutline, mailSharp, pencilOutline, peopleCircleSharp, peopleOutline, personOutline, personSharp, push, sendOutline, settingsOutline, settingsSharp, stopCircleSharp, trashBin, trashBinSharp, trashOutline, warningOutline, warningSharp, wifiOutline } from 'ionicons/icons';
import React,{ useRef, useState } from 'react';

var account_level_1:any = []
var staging:any = []
const Mapping = (props:any) =>{
    const [getShowLoading, setShowLoading]              = useState<boolean>(false)
    const [getLoadingMsg, setLoadingMsg]                = useState<any>("")
    const [getAccountLevel1, setAccountLevel1]          = useState<HTMLIonSelectOptionElement>();
    const [getMappingList, setMappingList]              = useState<HTMLIonRowElement>()
    const [getChange, setChange]                        = useState<boolean>(false)
    const [getHeader, setHeader]                        = useState<HTMLIonRowElement>()
    const callDimAccountLevel1  = () =>{
    setShowLoading(true)
    let options:any = []
        fetch(props.primaryhost+"select?etl=selectaccountlevel1_react"+
        "&account_level_2_key="+0+
        "&analyticalreviewsid="+props.analyticalReviewsId
        ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            options.push(data.data)
            
            let o = options[0].map((x:any, i:any)=>{
                return(
                    <IonSelectOption key={i} value={x.account_level_1_key}>{x.account_level_1}</IonSelectOption>
                )
            })
            
            setAccountLevel1(o);
            callDimAccounts()
            //callDimAccounts()
        })
        .catch(error=>{
            setShowLoading(false)
        })
        
    }

    const callDimAccounts = () =>{
        setShowLoading(true)
        var options:any = []
        fetch(props.primaryhost+'ar/dbconnect/select.jsp?etl=selectdimaccountlevel0'+
        "&analytical_reviews_id="+props.analyticalReviewsId
        ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response =>  response.json())
        .then(data =>{
            setShowLoading(false)
            options.push(data.data)
            var headers:any = (
                                <IonRow key={0} className="summary-row-header ">
                                    <IonCol size="2">Account Number</IonCol>
                                    <IonCol size="2">Account Name</IonCol>
                                    <IonCol size="2" className='ion-text-center'>Map To</IonCol>
                                    <IonCol>Current Setting</IonCol>
                                    <IonCol>Change Setting</IonCol>
                                </IonRow>
                            )
            setHeader(headers)
            var detail:any = options[0].map((x:any , i:number)=>{
                var key1:any = x.account_level_0_key
                var value:any = x.account_level_1_key
                staging.push({account:key1,mapping:value, mapping_old:value})
                return(
                    <IonRow className="summary-detail-row" key={i}>
                        <IonCol size="2">{x.account_number}</IonCol>
                        <IonCol size="2">{x.account_name}</IonCol>
                        <IonCol size="2" className="ion-text-center">
                            <IonIcon icon={arrowForwardSharp} className="size-32"></IonIcon>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonLabel>{x.account_level_1}</IonLabel>
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className="ion-text-left">
                                <IonLabel></IonLabel>
                                <IonSelect className="ion-text-left" onIonChange={ev=>{staging[i].mapping = ev.detail.value; checkForChanges(); console.log(staging)}}>
                                    {getAccountLevel1} 
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                )
            })
            setMappingList(detail)
            setShowLoading(false)
        })
        .catch(error=>{
            setShowLoading(false)
            alert("Could not find accounts :"+error)
        })
    }
   
    const checkForChanges = () =>{
        var change:boolean = false;
        for(var i = 0; i < staging.length; i++){
            if(staging[i].mapping != staging[i].mapping_old){
                change = true
            }
        }
        setChange(change)
    }
    const updateMapping = () =>{
        setShowLoading(true)
        for(var i = 0; i < staging.length; i++){
            if(staging[i].mapping != staging[i].mapping_old){
                fetch(props.primaryhost+'ar/dbconnect/update.jsp?etl=updatedimaccountlevel0'+
                "&analytical_reviews_id="+props.analyticalReviewsId+
                "&account_level_0_key="+staging[i].account+
                "&account_level_1_key="+staging[i].mapping
                ,
                    {
                        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                    }
                )
                .then(response =>  response.json())
                .then(data =>{
                    setShowLoading(false)
                    callDimAccountLevel1()
                    console.log("account:"+staging[i].account+", from:"+staging.mapping_old+" to:"+staging[i].mapping+" done")
                })
                .catch(error=>{
                    setShowLoading(false)
                }) 
            }
        }
        //updateFactTable()
    }
    const updateFactTable = () =>{
        setShowLoading(true)
        fetch(props.primaryhost+'ar/dbconnect/update.jsp?etl=updatefacttablebalance'+
        "&analytical_reviews_id="+props.analyticalReviewsId
        ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response =>  response.json())
        .then(data =>{
            setShowLoading(false)
            alert("Update complete")
        })
        .catch(error=>{
            setShowLoading(false)
            alert("Something went wrong")
        })
    }
    React.useEffect(() => {
        
      }, []);
    return(
        <div>
            <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={getLoadingMsg} ></IonLoading>
            <div className='is-area-to-print ion-padding'>
                <IonHeader>
                    <IonRow>
                        <IonCol size="9">
                            <div className="size-20 summary-account-level-1"><b>Chart Of Accounts - Mapping</b></div>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            {getChange &&
                                <div className="launch-button-main-login-launch" onClick={()=>{updateMapping()}}>Update Changes</div>
                            }
                        </IonCol>
                        <IonCol>
                            <div onClick={()=>{callDimAccountLevel1();callDimAccountLevel1()}} className="launch-button-main-login-launch">Get Mappings</div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {getHeader}
                        </IonCol>
                    </IonRow>
                </IonHeader> 
                <IonContent className='is-area-to-print-content ion-padding'>
                    <IonRow>
                        <IonCol>{getMappingList}</IonCol>
                    </IonRow>
                </IonContent>
            </div>
        </div>
    )
}

export default Mapping;