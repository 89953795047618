import { IonCol, IonIcon, IonRow } from '@ionic/react';
import { businessOutline, peopleOutline, shareOutline, trashOutline} from 'ionicons/icons';
import { useState,useEffect } from 'react';
import './AnalyticalReviews.css'
const AnalyticalReviews = (props:any) =>{
    console.log(props)
    const [getState, setState]      = useState<any>()
    const [getLiveConnectionsList, setLiveConnectionsList] = useState<any>()
    var prevState:any;
    const setProjects = () =>{
    var card:any = []
    try{
        card.push(props.data)
        var list:any = card[0].map((x:any,i:number)=>{
          return(
            <IonRow key={i} className="ar-card-line text-black" id={x.names} title={x.name} >
              <IonCol size="4" onClick={ev=>{
                            //alert(x.id)
                            props.name(x.name)
                            props.setAnalyticalReviewsId(x.id)
                            props.setYearEnd(x.yearend)
                            //props.start_date(x.startdate)
                            //props.end_date(x.enddate)
                            //props.state.analytical_reviews_id         = x.id;
                            //props.state.migration_id                  = x.migrationid;
                            //props.state.start_date                    = x.startdate;
                            //props.state.end_date                      = x.enddate;
                            //props.state.data_start_date               = x.startdate;
                            //props.state.data_end_date                 = x.enddate;
                            //props.state.current_end_date              = x.current_end_date
                            //props.state.target_currency_id            = x.nativecurrency;
                            //props.state.client_name                   = x.name;
                            //props.state.account_level_3               = 1;
                            //props.state.accounts_count                = x.accounts_count;
                            //props.state.client_secret                 = x.client_secret;
                            //props.state.client_id                     = x.client_id;
                            //props.state.client_name                   = x.name;
                            //props.state.setLiveConnections("live-connections-area-hide")
                            //props.state.setPageView(5); 
                            //props.state.setOrganizationId(x.organizationid);
                            //props.state.setOrganzationName(x.organization_name);
                            //props.state.setLastUpdate(x.updateddate); 
                            //props.state.setYearEndDay(x.yearendday);
                            //props.state.setYearEndMonth(x.yearendmonth);
                            //props.state.setAuthorizedOrganization(x.accesstoken.length>20? true:false);
                            //props.state.setIndustry(x.industry);
                            //props.state.setSector(x.sector);
                            //props.state.setNativeCurrency(x.currency);  
                            //props.state.showSettingStage(4);
                            
                            }}>
                <IonIcon icon={businessOutline} className="size-24 text-gt"></IonIcon> &nbsp;
                {x.name}
              </IonCol>
              <IonCol size="2" className="ion-text-left">
                {x.startdate}            
              </IonCol>
              <IonCol size="2">
                {x.enddate}             
              </IonCol>
              <IonCol size="2">
                {x.createdby == props.user_id ?
                  <IonIcon onClick={ev=>{ props.callProjectContacts(x.id)}} icon={peopleOutline} className="size-24 text-green"></IonIcon>             
                :
                <IonIcon onClick={ev=>{ }} icon={peopleOutline} className="size-24 text-grey"></IonIcon>
                }
                </IonCol>
              <IonCol size="2">
                {(x.id != 22 && x.createdby == props.user_id)?
                <IonIcon onClick={ev=>{var z:any = []; z.push({id:x.id, name:x.name});props.deleteProject(z[0])}} icon={trashOutline} className="size-24 text-red"></IonIcon>             
                :
                <IonIcon onClick={ev=>{}} icon={trashOutline} className="size-24 text-grey"></IonIcon>
                }
              </IonCol>
            </IonRow>
          )
        })
        setLiveConnectionsList(list)
    }catch(e){

    }
    }
    useEffect(() => {
        setState(()=>{
            if(props.data != prevState || props.location != prevState){
                prevState = null
                setProjects()
            }}
        )
        
    }, [props.data, props.location]);
    return(
        <div>
            {getLiveConnectionsList}
        </div>
    )

}
export default AnalyticalReviews