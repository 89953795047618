import { IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonCheckbox } from '@ionic/react';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, options, filter} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import $ from "jquery"
import { addCommas, isNumeric } from '../../functions/functions';
import './Basic.css'
 
const Detail = (props:any) =>{
    console.log(props.data)
    var options:any = []
    options.push(props.data[0])
    const [getData,setData]             = useState<any>()

    // get table column
    const column:any = []
    let x:any = "";

    console.log(options[0])

    for(x in options[0][0]){
        console.log(x)
        if(
            x != "row" &&
            x != "WIP" &&
            x != "id" &&
            x != "vendor_number" &&
            x != "vat_number" &&
            x != "address" &&
            x != "approved_date" && 
            x != "units" && 
            x != "is_invoice" && 
            x != "parent_id" && 
            x != "status_id" && 
            x != "contractor_id" && 
            x != "transaction_type_id" && 
            x != "vat_rate" && 
            x != "commission_rate" && 
            x != "discount_rate" &&
            x != "CREATED BY"
        ){
            column.push(x)
        }
    }
 
    // get table heading data
    console.log(column)
    const ThData:any =()=>{
        return column.map((header:any, i:number)=>{
            return <th  className="ion-text-center ion-padding" key={i}><b>{header}</b></th>
        })
    }
    // get table row data
    const tdData:any =() =>{
        return options[0].map((data:any, i:number)=>{
            console.log(data) 
            
            return(
               
                <tr key={i}>
                    {
                        column.map((v:any, z:number)=>{
                            console.log(v)
                            if(true){
                                if(!isNumeric(data[v]) || v == "INVOICE" || v == "DESCRITPION" || v == "REFERENCE" || v == "COMMISSION"){
                                    if(v == "DATE"){
                                        return <td  key={z} className={"ion-text-right ion-padding "}>{data[v]}</td>
                                    }
                                    if(v == "RATE"){
                                        var x:any = []
                                        x.push({id:data["id"],parent_id:data["parent_id"],invoice:data["INVOICE"], transaction_type_id:data["transaction_type_id"],type:1})
                                        return <td  key={z} className={"ion-text-right ion-padding hover text-blue"} onClick={()=>{props.setRate(x)}}><b>{data[v]}</b></td>
                                    }
                                    if(v == "UNITS" && (data["transaction_type_id"] == 3926 || data["transaction_type_id"] == 3937)){
                                        var x:any = []
                                        x.push({id:data["id"],parent_id:data["parent_id"],invoice:data["INVOICE"], transaction_type_id:data["transaction_type_id"],type:2})
                                        return <td  key={z} className={"ion-text-right ion-padding hover text-blue"} onClick={()=>{props.setUnits(x)}}><b>{data[v]}</b></td>
                                    }
                                    if(v == "UNITS" && data["transaction_type_id"] != 3926){ 
                                        return <td  key={z} className={"ion-text-right ion-padding "}>{data[v]}</td>
                                    }
                                    if(v == "INVOICE" || v == "REFERENCE" || v == "NAME" || v == "MANAGER" || v == "TRANSACTION TYPE" || v == "DESCRIPTION" || v == "COMMISSION" ){
                                        return <td  key={z} className={"ion-text-left ion-padding "}>{data[v]}</td>
                                    }
                                    
                                    if(v == "STATUS" && (data["transaction_type_id"] == 3926 || data["transaction_type_id"] == 3929 || data["transaction_type_id"] == 3937 )){
                                        var x:any = []
                                        x.push({id:data["id"], parent_id:data["parent_id"]})
                                        return  <td key={z} className={"ion-text-right ion-padding"}>
                                                    <div onClick={()=>{props.setStatus(x)}} className={(data[v] == "Pending")? "text-white ion-text-center button-orange":data[v] == "Approved"? "text-white ion-text-center button-green":data[v] == "Declined"? "text-white ion-text-center button-red":"green"}>{data[v]}</div>
                                                </td>
                                    }
                                    if(v == "STATUS" && (data["transaction_type_id"] != 3926  && data["transaction_type_id"] != 3929 && data["transaction_type_id"] != 3937  )){
                                        return  <td key={z} className={"ion-text-right ion-padding"}>
                                                    <div onClick={()=>{props.setStatus(x)}} className="ion-padding" ></div>
                                                </td>
                                    }
                                }
                                if(isNumeric(data[v])){
                                    if(v == "AMOUNT"){
                                        return <td  key={z} className={"ion-text-right ion-padding "}><b>R{addCommas((data[v]/1).toFixed(2))}</b></td>
                                    }                                    
                                }
                            }
                        })
                    }
                </tr>
            )
        })
    }
    return (
        <IonRow className="table size-12 ">
            <table >
                <tr>
                    {ThData()}
                </tr>
                {tdData()}
            </table>
        </IonRow>
    )
}
export default Detail