import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useState,useEffect, useRef } from 'react';
import { apps, businessOutline, listOutline, peopleCircleOutline, peopleOutline, time, timeOutline, trashBinOutline } from 'ionicons/icons';

const Contractors = (props:any) =>{

    return(
        <div></div>
    )
}
export default Contractors