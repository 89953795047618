import { IonButton, IonCard, IonCol, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonSpinner } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import React, { useRef, useState } from "react";
import { OAuth, XeroClient } from "xero-node";



// save the tokenSet


let client_id:any="";
let client_secret:any="";
let duration:any="";
let migrationId:any=0;
let migration_name:any="";
let opening_date:any="";
let closing_date:any="";
let versionId:any=0;
let analyticalReviewsId:any=0;
let applicationId:any=0;
let socket:any = null;
let data_start_date:any =""
let data_end_date:any = ""
let start_date:any="";
let end_date:any="";
let current_end_date:any = "";
let client_name:any="";

const Xero =(props:any) =>{
    const [get_1224, set_1224]               = useState<boolean>(false);
    const [get_1225, set_1225]               = useState<boolean>(false);
    const [get_1226, set_1226]               = useState<boolean>(false);
    const [get_1227, set_1227]               = useState<boolean>(false);
    const [get_1228, set_1228]               = useState<boolean>(false);
    const [get_1229, set_1229]               = useState<boolean>(false);
    const [get_1230, set_1230]               = useState<boolean>(false);
    const [get_1231, set_1231]               = useState<boolean>(false);
    const [get_1232, set_1232]               = useState<boolean>(false);
    const [get_1233, set_1233]               = useState<boolean>(false);
    const [get_1234, set_1234]               = useState<boolean>(false);
    const [get_1235, set_1235]               = useState<boolean>(false);
    const [get_1236, set_1236]               = useState<boolean>(false);
    const [hideXeroStatus, showXeroStatus]  = useState<boolean>(false)
    const [hideXeroUpdateStatus, showXeroUpdateStatus] = useState<boolean>(false)
    const txtClientId                     = useRef<any>(null)
    const txtClientSecret                 = useRef<any>(null)
    const ddlConnectDuration              = useRef<any>(null)
    const txtJournalStartDate             = useRef<any>(null)
    const txtJournalEndDate               = useRef<any>(null)
    const txtMigrationName                = useRef<any>(null)
    const [getDuplicate, setDuplicate]                  = useState<string>("input-text-size-1-black");
    const [getProjectName   , setProjectName]           = useState<boolean>(false)
    const [hideMigration, showMigration]  = useState<boolean>(true)
    const [hideAPI, showAPI]              = useState<boolean>(false)

    const showSettingStage = (stage:any) =>{
        resetSettingStage()
        switch(stage){
        case 1:showMigration(true);break;
        case 2:showAPI(true); break;
        case 3:showXeroStatus(true);break;
        }
    }
    const resetSettingStage = () =>{
        showMigration(false);
        showAPI(false);
        showXeroStatus(false);
    }
    const insertMigration = () =>{
        fetch(props.primary_host+"dbconnect/dbdynamic.jsp?dbo=insertmigration"+
        "&migration_name="+txtMigrationName.current!.value+
        "&contactid="+props.user_id+
        "&application_id="+applicationId+
        "&opening_date="+txtJournalStartDate.current!.value+
        "&closing_date="+txtJournalEndDate.current!.value+
        "&versionid="+versionId+
        "&custom_id="+1
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
        if(data.data.length > 0){
            migration_name = txtMigrationName.current!.value;
            opening_date = txtJournalStartDate.current!.value;
            closing_date = txtJournalEndDate.current!.value
            migrationId = data.data[0].migration_id;
            analyticalReviewsId = data.data[0].analyticalreviewsid;
            showSettingStage(2)
        }else{
            migrationId = 0;
        }
        })
    }
    const callVersion = () =>{
        fetch(props.primary_host+"insert?etl=insertversion"+
        "&createdby="+props.user_id+
        "&templatename="+
        "&companyid=0"+
        "&reporttypeid=0"+
        "&filename="+
        "&delimiter="
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
        console.log("version id:"+data.data[0].versionid)
        console.log("length:"+data.data.length)
        if(data.data.length > 0){
            versionId = data.data[0].versionid;
            showSettingStage(1)
        }else{
            versionId = 0;
        }
        })
    }
    const continueXero = () =>{
        showXeroUpdateStatus(true)
        if (current_end_date == ''){
        current_end_date = closing_date
        }
    
        var share_link = props.primary_host+"xero/Authorization?"+
        "&clientid="+client_id+
        "&clientsecret="+client_secret+
        "&versionid="+0+
        "&project_name="+client_name+
        "&journalstartdate="+data_start_date+
        "&journalenddate="+current_end_date+
        "&duration="+duration+
        "&createdby="+props.user_id+
        "&analyticalreviewsid="+analyticalReviewsId
        ;
        //alert(share_link)
        window.open(share_link, "social_share", "width=1024,height=1024");
        websocket()
    }
    const callXero = () =>{
        showSettingStage(3);
        client_id = txtClientId.current!.value;
        client_secret = txtClientSecret.current!.value;
        duration = ddlConnectDuration.current!.value;
  
        var share_link = props.primary_host+"xero/Authorization?"+
        "&clientid="+client_id+
        "&clientsecret="+client_secret+
        "&versionid="+0+
        "&project_name="+migration_name+
        "&journalstartdate="+opening_date+
        "&journalenddate="+closing_date+
        "&duration="+duration+
        "&createdby="+props.user_id+
        "&analyticalreviewsid="+analyticalReviewsId
        ;
        window.open(share_link, "social_share", "width=1024,height=1024");
        websocket()
  
    }
    const websocket = () =>{
      // Creates new WebSocket object with an ws URI as the parameter
      socket = new WebSocket('wss://dmzee.sng.za.com/xero/TrackXeroStatus');
  
      // Fired when a connection with a WebSocket is opened,
      socket.onopen = function () {
        handleOpenSocket()
      };
  
      // Fired when data is received through a WebSocket,
      socket.onmessage = function(event:any) {
          handleUpdateData(event.data);
      };
  
      // Fired when a connection with a WebSocket is closed,
      socket.onclose = function(event:any) {
          onSocketClose(event);
      };
  
      // Fired when a connection with a WebSocket has been closed because of an error,
      socket.onerror = function(event:any) {
          onSocketError(event);
      };
    }
    const handleOpenSocket = () =>{
      //alert("Open")
      socket.send(analyticalReviewsId);
    }
    const handleUpdateData = (data:any)=>{
      console.log(data)
      try{
        var data:any = JSON.parse(data);
        //alert(data.data)
        var status_id:any;
        var complete:any;
        for(var i = 0; i < data.data.length; i++){
          status_id = data.data[i].status_id/1
          complete = data.data[i].complete/1
          console.log("status:"+status_id+" complete:"+complete);
          if(status_id == 1237 || status_id == 1238){
            socket.close();
            if(status_id  == 1237){
              alert("Xero has timed out due to too many records. To continue where you have left off please press continue")
              setXeroContinue(true);
              socket.close()
            }
            if(status_id == 1238)
              alert("An error occurred during the building of AR Tables. Please contact your Analytics Administrator")
              socket.close()
          }
          if(complete == 1 && !(status_id == 1237 || status_id == 1238)){
            console.log("something found 1"+status_id)
            setStatus(status_id, data.data[i].duration)
            setMessage(status_id, data.data[i].message)
          }
          if(complete == 0 && !(status_id == 1237 || status_id == 1238)){
            console.log("something found 2"+status_id)
            setMessage(status_id , data.data[i].message)
          }
          if(complete == 0 && (status_id == 1237)){
            console.log("something found 3"+status_id)
            setMessage(status_id , data.data[i].message)
            //socket.close()
          }
        }
      }catch(error){
        //socket.close()
      }
    }
    const getOrganizationData = () =>{
      /*
      let option:any = []
      fetch(primaryhost+"xero/get?xero=getjournals"+
      "&analytical_reviews_id="+analyticalReviewsId
      ,
        {
          headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
      )
      .then(response => {
        websocket();
        showXeroStatus(true);
      })
      */
      //openXeroConnection()
      continueXero()
    }
    const openXeroConnection = () =>{
        //var credentials:any = base64_encode("26293CF712A742A69C2610EB99999559" + "0R1ImX7q_1DB4Bs_9xGwHWD_2pN5YpoqxUFmr6ITjxHKtmix")
        var access_token:any = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjFDQUY4RTY2NzcyRDZEQzAyOEQ2NzI2RkQwMjYxNTgxNTcwRUZDMTkiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJISy1PWm5jdGJjQW8xbkp2MENZVmdWY09fQmsifQ.eyJuYmYiOjE2Mzc2NzQ0OTEsImV4cCI6MTYzNzY3NjI5MSwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eS54ZXJvLmNvbSIsImF1ZCI6Imh0dHBzOi8vaWRlbnRpdHkueGVyby5jb20vcmVzb3VyY2VzIiwiY2xpZW50X2lkIjoiMjYyOTNDRjcxMkE3NDJBNjlDMjYxMEVCOTk5OTk1NTkiLCJzdWIiOiIxZWZjZDE3ZGY2N2U1YjYzYmJkOTU4NGRiNzg1NjY5MyIsImF1dGhfdGltZSI6MTYzNzY3MzY3MCwieGVyb191c2VyaWQiOiI0M2JkMTk0Zi1kNTc0LTQxMjAtYWU0ZS1iODE0OTMzZTcwYjIiLCJnbG9iYWxfc2Vzc2lvbl9pZCI6ImU4ZjA2ZmM0YjJjMTQ4MmVhZGJkNjA2ODdlOTEyMDc0IiwianRpIjoiZjA3MmE2YmY5MjA5YTE2NzI5MTM5NDM4ZTRmYWNhZGEiLCJhdXRoZW50aWNhdGlvbl9ldmVudF9pZCI6Ijk5MDUyMjYxLTczMmQtNDJhYS1iOGVkLWUxYTM4ZjUwODcwYiIsInNjb3BlIjpbImFjY291bnRpbmcuc2V0dGluZ3MucmVhZCIsImFjY291bnRpbmcuam91cm5hbHMucmVhZCJdfQ.SiIPIgJKhcYPOYQUiM1UPCK9phltyLvEPX-MBnSkDrW7Jbt4NhXLyQMhdbU3RSGUu407HsmX7TO2u5EzYVVZA94PlQ2U1DOlHu6mR3RBY57y976i9TtajVRt_Cwxx9IV5HbteDF8RjoZWR1a2faYpvhR4ebAg-cOzNnOfnXxWg4P0bz-cEnETjvJxKcRgOuHaTmyTM0Nr5Duo66ecJOS7Nla-T-mz4NMBW-8aG__iMpJ-tFPbocU7SS47HJl7I_8bewXbkH6H11yHuzgYTt9JpPoX6vmDkOOA-6VnI4e2tiYgv9D4yZbFqATTMIU7VNyKSrmjtkqwvtyBJH-NJS0Ug"
        var tenant_id:any = "8677ac95-6e9e-47d2-9467-3558f003c107"
        fetch('https://api.xero.com/connections', 
          {
            method: 'GET',
            mode:'cors',
            headers: {
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + access_token,
            "Accept":" application/json",
            "Xero-tenant-id": tenant_id
            }
          }
        )
        .then(response => console.log(response))
        
      
    }
   
    const [getXeroContinue, setXeroContinue]                  =useState<boolean>(false)
    const setMessage = (status_id:number, message:any) =>{
      switch(status_id){
        case 1224:$(".message_1224").html(message); break;
        case 1225:$(".message_1225").html(message); break;
        case 1226:$(".message_1226").html(message); break;
        case 1227:$(".message_1227").html(message); break;
        case 1228:$(".message_1228").html(message); break;
        case 1229:$(".message_1229").html(message); break;
        case 1230:$(".message_1230").html(message); break;
        case 1231:$(".message_1231").html(message); break;
        case 1232:$(".message_1232").html(message); break;
        case 1233:$(".message_1233").html(message); break;
        case 1234:$(".message_1234").html(message); break;
        case 1235:$(".message_1235").html(message); break;
        case 1236:$(".message_1236").html(message); break;
      }
    }
    const setStatus = (status_id:number, duration:any) =>{
      switch(status_id){
        case 1224:set_1225(true);$(".status_1225").html(duration+"(s)"); break;
        case 1225:set_1225(true);$(".status_1225").html(duration+"(s)"); break;
        case 1226:set_1226(true);$(".status_1226").html(duration+"(s)"); break;
        case 1227:set_1227(true);$(".status_1227").html(duration+"(s)"); break;
        case 1228:set_1228(true);$(".status_1228").html(duration+"(s)"); break;
        case 1229:set_1229(true);$(".status_1229").html(duration+"(s)"); break;
        case 1230:set_1230(true);$(".status_1230").html(duration+"(s)"); break;
        case 1231:set_1231(true);$(".status_1231").html(duration+"(s)"); break;
        case 1232:set_1232(true);$(".status_1232").html(duration+"(s)"); break;
        case 1233:set_1233(true);$(".status_1233").html(duration+"(s)"); break;
        case 1234:set_1234(true);$(".status_1234").html(duration+"(s)"); break;
        case 1235:set_1235(true);$(".status_1235").html(duration+"(s)"); break;
        case 1236:set_1236(true);$(".status_1236").html(duration+"(s)"); break;
      }
    }
    const onSocketClose =(event:any)=>{
      console.log(event)
    }
    const onSocketError =(event:any)=>{
      console.log(event)
    }
    React.useEffect(() => {
        console.log(props) 
        callVersion()
    }, []);
    return(
    <div>
        <IonRow className="settings-card">
            <IonCol size="3"></IonCol>
            <IonCol size="6">
                <IonCard className="ion-text-center">
                    <IonRow>
                        <IonCol size="5"></IonCol>
                        <IonCol size="2" className="ion-padding ion-text-center"> 
                        <IonImg src="../../../assets/images/xero.png" className="settings-image" ></IonImg>
                        </IonCol>
                        <IonCol size="5"></IonCol>
                    </IonRow>
                    <IonRow className="ion-padding ion-text-center">
                        <IonCol size="12" className="ion-text-center">
                            <h4><strong>New Xero API Connection</strong></h4>
                        </IonCol>
                        <div className="u-line"></div>
                    </IonRow>
                    {hideMigration &&
                    <div>
                    <IonRow>
                        <IonCol size="2"></IonCol>
                        <IonCol className=" ion-text-center">
                            <IonItem>
                                <IonLabel position="floating"><b>Project Name</b></IonLabel>
                                <IonInput 
                                    className={getDuplicate} 
                                    ref={txtMigrationName} onKeyUp={ev=>{
                                        const getDuplicate =  async () =>{
                                            if(ev.currentTarget!.value!.toString().length > 2){
                                                fetch(props.primary_host+"journals/dbconnect/select.jsp?etl=findduplicateprojectname"+
                                                    "&name="+txtMigrationName.current!.value+
                                                    "&contact_id="+props.user_id
                                                    ,
                                                    {
                                                        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                                                    }
                                                ) 
                                                .then(response => response.json())
                                                .then(d => {
                                                    console.log(d)
                                                    console.log(d.data[0].found)
                                                    console.log("Duplicate:"+d.data[0].found)
                                                    if(d.data[0].found==0){
                                                        setDuplicate("input-text-size-1-black")
                                                        setProjectName(true)
                                                    }else{
                                                        setDuplicate("input-text-size-1-red")
                                                        setProjectName(false)
                                                    }

                                                })
                                                .catch(error=>{
                                                    //console.log(error)
                                                    setDuplicate("input-text-size-1-black")
                                                    setProjectName(true)
                                                })
                                            }
                                            
                                        }
                                        getDuplicate()
                                    }}
                                />
                            </IonItem>
                            </IonCol>
                            <IonCol size="2"></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="2"></IonCol>
                            <IonCol className=" ion-text-center">
                            <IonItem>
                                <IonLabel position="floating"><b>Financial Year Opening Date</b></IonLabel>
                                <IonInput ref={txtJournalStartDate} type="date" />
                            </IonItem>
                            </IonCol>
                            <IonCol size="2"></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="2"></IonCol>
                            <IonCol className=" ">
                                <IonItem>
                                    <IonLabel position="floating"><b>Financial Year Closing Date</b></IonLabel>
                                    <IonInput ref={txtJournalEndDate} type="date" />
                                </IonItem>
                            </IonCol>
                            <IonCol size="2"></IonCol>
                        </IonRow>
                        <IonRow>&nbsp;</IonRow>
                        <IonRow>
                            <IonCol>
                            <IonButton className="button-width-max" onClick={ev=>{insertMigration()}}>Create Project</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>&nbsp;</IonRow>
                        <IonRow>&nbsp;</IonRow>
                    </div>
                    }
                    {hideAPI &&
                    <div>
                        <IonRow>
                            <IonCol>
                            <IonItem>
                                <IonLabel position="floating"><b>Client ID</b></IonLabel>
                                <IonInput ref={txtClientId} value="26293CF712A742A69C2610EB99999559"/>
                            </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            <IonItem>
                                <IonLabel position="floating"><b>Client Secret</b></IonLabel>
                                <IonInput ref={txtClientSecret} value="0R1ImX7q_1DB4Bs_9xGwHWD_2pN5YpoqxUFmr6ITjxHKtmix" />
                            </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel position="floating"><b>Connect Every</b></IonLabel>
                                    <IonSelect ref={ddlConnectDuration} >
                                    <IonSelectOption value={60*60}>1 Hr</IonSelectOption>
                                    <IonSelectOption value={60*60*6}>6 Hrs</IonSelectOption>
                                    <IonSelectOption value={60*60*12}>12 Hrs</IonSelectOption>
                                    <IonSelectOption value={60*60*24}>1 Day</IonSelectOption>
                                    <IonSelectOption value={60*60*24*7}>7 Days</IonSelectOption>
                                    <IonSelectOption value={60*60*24*14}>14 Days</IonSelectOption>
                                    <IonSelectOption value={60*60*24*28}>1 Month</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>&nbsp;</IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton className="button-width-max" onClick={ev=>{callXero()}}>Connect</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>&nbsp;</IonRow>
                        <IonRow>&nbsp;</IonRow>
                    </div>                 
                    } 
                </IonCard>
            </IonCol>
        </IonRow>
        {hideXeroStatus &&
        <table className="ion-text-left striped ">
            {getXeroContinue && 
            <tr className="ion-padding">
                <td>
                    &nbsp;
                </td>
                <td>
                    &nbsp;
                </td>
                <td>
                    <IonButton onClick={ev=>{continueXero()}}>Continue</IonButton>
                </td>
            </tr>
            }
            <tr className="ion-padding">
                <td>
                    <b>Stage</b>
                </td>
                <td>
                    <b>Duration(s)</b>
                </td>
                <td>
                    <b>Status</b>
                </td>
                <td>
                    <b>Message</b>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Awaiting Xero Access
                </td>
                <td  className="ion-text-center">
                    <div className="status_1224"></div>
                </td>
                <td className="ion-text-center">
                    <div>
                    {get_1224 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1224"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Xero System Accessed 
                </td>
                <td className="ion-text-center">
                    <div className="status_1225"></div>
                </td>
                <td>
                <div className="ion-text-center">
                    {get_1225 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1225"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Obtaining Company ID
                </td>
                <td className="ion-text-center">
                    <div className="status_1227"></div>
                </td>
                <td>
                <div className="ion-text-center">
                    {get_1227 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1227"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Obtaining Company Name
                </td>
                <td className="ion-text-center">
                    <div className="status_1226"></div>
                </td>
                <td className="ion-text-center">
                <div>
                    {get_1226 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Loading Accounts
                </td>
                <td className="ion-text-center">
                    <div className="status_1228"></div>
                </td>
                <td className="ion-text-center">
                <div >
                    {get_1228 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1228"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Loading Transactions
                </td>
                <td className="ion-text-center">
                    <div className="status_1229"></div>
                </td>
                <td className="ion-text-center">
                <div>
                    {get_1229 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1229"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Starting To Build AR
                </td>
                <td  className="ion-text-center">
                    <div className="status_1230"></div>
                </td>
                <td className="ion-text-center">
                <div>
                    {get_1230 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1230"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Creating Accounts Table
                </td>
                <td className="ion-text-center">
                    <div className="status_1231"></div>
                </td>
                <td className="ion-text-center">
                <div>
                    {get_1231 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1231"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Updating Accounts
                </td>
                <td className="ion-text-center">
                    <div className="status_1232"></div>
                </td>
                <td className="ion-text-center">
                <div>
                    {get_1232 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1232"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Creating Daily Transctions Table
                </td>
                <td className="ion-text-center">
                    <div className="status_1233"></div>
                </td>
                <td className="ion-text-center">
                <div>
                    {get_1233 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1233"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Updating Daily Transctions
                </td>
                <td className="ion-text-center">
                    <div className="status_1234"></div>
                </td>
                <td className="ion-text-center">
                <div>
                    {get_1234 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1234"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Indexing Data
                </td>
                <td className="ion-text-center">
                    <div className="status_1235"></div>
                </td>
                <td className="ion-text-center">
                <div>
                    {get_1235 == true? 
                    <IonIcon icon={checkmarkOutline} color="success" className="size-32"></IonIcon>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1235"></div>
                </td>
            </tr>
            <tr className="ion-padding">
                <td>
                    Process Complete
                </td>
                <td className="ion-text-center">
                    <div className="status_1236"></div>
                </td>
                <td className="ion-text-center">
                <div>
                    {get_1236 == true? 
                    <IonButton onClick={ev=>{socket.close();}}>Complete</IonButton>
                    :
                    <IonSpinner name="dots"></IonSpinner>
                    }
                    </div>
                </td>
                <td  className="ion-text-center">
                    <div className="message_1236"></div>
                </td>
            </tr>
        </table> 
        }
    </div>
    )
}
export default Xero