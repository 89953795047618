import { IonSearchbar, IonLoading, IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSelect, IonSelectOption, IonSpinner, IonThumbnail, IonTitle, IonToolbar, IonTextarea, IonPopover, IonVirtualScroll, IonCheckbox, IonCardTitle } from '@ionic/react';
import { apps,starOutline, ellipsisVerticalOutline, businessOutline, checkmarkOutline, analyticsOutline, analyticsSharp, arrowBackSharp, cloudOutline, documentOutline, filterSharp, gridOutline, homeOutline, list, listOutline, logInOutline, logOutOutline, menuOutline, personOutline, pulseOutline, settingsOutline, wifiOutline, trashBin, trashBinOutline, checkmarkCircle, closeCircle, refreshCircle, caretUp, caretDown, pulseSharp, pulse, notifications, notificationsCircle, pencilSharp, closeOutline, trashOutline, star, arrowForwardCircleOutline, print, chevronForward, chevronDown, searchOutline, videocamOutline, codeDownload, clipboardOutline, logoGoogle, logoGooglePlaystore, shareOutline, returnUpBack, analytics, grid, createOutline, addOutline, cloudUploadOutline } from 'ionicons/icons';
import React,{ useRef, useState,  } from 'react';
import Highcharts, { isNumber } from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import './Custom.css' 
import ProjectsSide from '../SideBar/SubComponents/ProjectsSide';
import { callAnalyticalReviews, callBalanceByAccount, callDetailTransactions, callMovementByAccount, callSummaryDetail } from '../../dbconnections/Select';
import $ from 'jquery'
import SummaryDetailTable from './SummaryDetailTableCustom';
import JournalDetail from './JournalDetail';
import CorrelationTable from '../Tables/Predictive/CorrelationTable';
import CustomImport from '../DataImport/CustomImport';
    
    var start_date:any              = '2021-01-01';
    var end_date:any                = '2021-12-31';
    var account_level_0:any         = '0';
    var account_level_1:any         = '0';
    var account_level_2:any         = '0';
    var account_level_3:any         = '0';
    let dim3List:any                = [];
    let dim3Filter:any              = '';
    let action_type_id:any          = 1;
    let custom_chart_id:any         = 0;
    let account_name:any            = '';
    let correlation:any             =0;
    const setDim3Filter = (id:any) =>{
        for(var i = 0; i < dim3List.length; i++){
            console.log("Before:"+dim3List[i].id+" "+dim3List[i].isChecked)
            if(dim3List[i].id == id){
                dim3List[i].isChecked = !dim3List[i].isChecked;
            }
            console.log("After:"+dim3List[i].id+" "+dim3List[i].isChecked)
        }
        
        dim3Filter="";
        for(var i = 0 ; i < dim3List.length; i++){
            if(dim3List[i].isChecked && dim3Filter.length > 0){
                dim3Filter = dim3Filter + ","+ dim3List[i ].id+"";
            }
            if(dim3List[i].isChecked && dim3Filter.length == 0){
                dim3Filter = ""+ dim3List[i].id+"";
            }
            
        }
        console.log(dim3Filter)
    }
    let dim2List:any                =[];
    let dim2Filter:any              = "";
    const setDim2Filter = (id:any) =>{
        for(var i = 0; i < dim2List.length; i++){
            console.log("Before:"+dim2List[i].id+" "+dim2List[i].isChecked)
            if(dim2List[i].id == id){
                dim2List[i].isChecked = !dim2List[i].isChecked;
            }
            console.log("After:"+dim3List[i].id+" "+dim3List[i].isChecked)
        }
        
        dim2Filter="";
        for(var i = 0 ; i < dim2List.length; i++){
            if(dim2List[i].isChecked && dim2Filter.length > 0){
                dim2Filter = dim2Filter + ","+ dim2List[i ].id+"";
            }
            if(dim2List[i].isChecked && dim2Filter.length == 0){
                dim2Filter = ""+ dim2List[i].id+"";
            }
            
        }
        console.log(dim2Filter)
    }
    var existing:any = []
    var properties:any = ''
    var state:any ={
        analytical_reviews_id : 0,
        migration_id: 0,
        project_name :'',
        migration_name:'',
        user_id:0
    }

    const CustomForm = (props:any) =>{

        const [getAnalyticalReviewsId, setAnalyticalReviewsId]              = useState<any>()
        const [getLiveConnectionsListData, setLiveConnectionsListData]      = useState<any>()
        const [getLiveConnections, setLiveConnections]                      = useState<any>("custom-live-connections-area-hide")
        const [getPickAccount, setPickAccount]                              = useState<any>(false)
        const [getNewChartProject, setNewChartProject]                      = useState<any>(false)
        const [getAccountSelector, setAccountSelector]                      = useState<string>("custom-account-selector-hide")
        const [getAccountSettings, setAccountSettings]                      = useState<string>("custom-account-settings-hide")
        const [getSelectedAccount, setSelectedAccount]                      = useState<HTMLIonColElement>()
        const [getAccountLevel3, setAccountLevel3]                          = useState<HTMLIonItemElement>()
        const [getAccountLevel2, setAccountLevel2]                          = useState<HTMLIonItemElement>()
        const [getAccountLevel1, setAccountLevel1]                          = useState<HTMLIonItemElement>()
        const [getAccountLevel0, setAccountLevel0]                          = useState<HTMLIonItemElement>()
        const [getMyCharts, setMyCharts]                                    = useState<HTMLIonColElement>()
        const txtStartDate:any                                              = useRef<HTMLIonInputElement>()
        const txtEndDate:any                                                = useRef<HTMLIonInputElement>()
        const txtChartName:any                                              = useRef<HTMLIonInputElement>()
        const [hideNewChart, showNewChart]                                  = useState<boolean>(false)
        const [getShowLoading, setShowLoading]                              = useState<boolean>(false)
        const [getFile, setFile]                                            = useState<boolean>(false)
        const setSelectorOptions = (level:any, id:any) =>{
            switch(level){
                case 3: switch(id){
                            case 1:;break;
                            case 2:;break;
                            case 3:;break;
                            case 4:;break;
                            case 5:;break;
                        }
                        ;break;
                case 2: switch(id){
                            case 1:;break;
                            case 2:;break;
                            case 3:;break;
                            case 4:;break;
                            case 5:;break;
                        };
                        break;
                case 1:;break;

            }
        }


        const setView = (v:any) =>{
            switch(v){
                case 1:resetView();break;
                case 2:setNewChartProject(true) ;break;
                case 3:setPickAccount(true)     ;break;
                case 4:setFile(!getFile); break;
            }
        }
        const resetView = () =>{
            setFile(false)
            setNewChartProject(false) ;
            setPickAccount(false)     ;
        }
        const callLiveConnections = () =>{
            callAnalyticalReviews(props.props)
            .then(response=>{return response})
            .then(data=>{
                setLiveConnectionsListData(data)
            })
            .then(()=>{
                setLiveConnections("custom-live-connections-area-show")
            })
        }
        const callDimAccountLevel3  = async() =>{
            let options:any = []
            fetch(props.props.primary_host+"select?etl=selectaccountlevel3"+
            "&account_level_3="+0 
            ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
            )
            .then(response => response.json())
            .then(data =>{
                options.push(data.data)
                
                let o = options[0].map((x:any, i:any)=>{
                    dim3List.push({id:x.account_level_3_key,isChecked:false})
                    console.log(x.account_level_3);
                    return(
                    <IonCol key={i} size="6">
                        <IonItem>
                            <IonLabel>{x.account_level_3}</IonLabel>
                            <IonCheckbox onClick={ev =>{setDim3Filter(x.account_level_3_key);}}  value={x.account_level_3_key}></IonCheckbox>
                        </IonItem>
                    </IonCol>
                    )
                })
        
            setAccountLevel3(o)
            //return true;
            })
            .then(done =>{
            account_level_3= //ddlAccountLevel3.current!.value;
            callDimAccountLevel2();
            })
        }
        
        const callDimAccountLevel2  = () =>{
            let options:any = []
            fetch(props.props.primary_host+"select?etl=selectaccountlevel2"+
            "&account_level_3_key="+ 0//ddlAccountLevel3.current!.value
            ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
            )
            .then(response => response.json())
            .then(data =>{
            options.push(data.data)
            let o = options[0].map((x:any, i:any)=>{
                return(
                <IonCol key={i} size="6">
                    <IonItem>
                        <IonLabel>{x.account_level_2}</IonLabel>
                        <IonCheckbox key={i} onClick={ev =>{setDim2Filter(x.account_level_2_key);}} value={x.account_level_2_key}></IonCheckbox>
                    </IonItem>
                </IonCol>
                )
            })
            setAccountLevel2(o);
            })
        }
        
        const callDimAccountLevel1  = () =>{
            let options:any = []
            fetch(props.props.primary_host+"select?etl=selectaccountlevel1_react"+
            "&account_level_2_key="+0+//ddlAccountLevel2.current!.value+
            "&analyticalreviewsid="+props.props.analytical_reviews_id
            ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
            )
            .then(response => response.json())
            .then(data =>{
            options.push(data.data)
            let o = options[0].map((x:any, i:any)=>{
                return(
                <IonRow>
                    <IonCol className="custom-account-text-icon">
                        <IonRow>
                            <IonCol size="10" onClick={()=>{setAccountProperty(1,x.account_level_1_key, x.account_level_1)}} >
                            {x.account_level_1}
                            </IonCol>
                            <IonCol className="ion-text-right" onClick={()=>{/*setSelectorOptions(2,1)*/}} >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                )
            })
            setAccountLevel1(o);
            })
        }
        
        const callDimAccountLevel0  = () =>{
            let options:any = []
            fetch(props.props.primary_host+"select?etl=selectaccountlevel0_react"+
            "&account_level_1_key="+0+//ddlAccountLevel1.current!.value+
            "&analyticalreviewsid="+props.props.analytical_reviews_id
            ,
                {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                }
            )
            .then(response => response.json())
            .then(data =>{
                options.push(data.data)
                let o = options[0].map((x:any, i:any)=>{
                return(
                    <IonCol size="12" key={i} className="ion-padding">
                        <div onClick={()=>{setAccountProperty(0,x.account_level_0_key,x.account_level_0_description)}} className="custom-account-text-icon">
                            <b>{x.account_level_0}</b><br/><div className="size-12">{x.account_level_0_description}</div>
                        </div>
                    </IonCol>
                )
                })
                setAccountLevel0(o)
            })
        }
        
        const setAccountProperty = (account_level:any , account_level_key:any, account_name:any) =>{
            setAccountSelector("custom-account-selector-hide")
            setCustomChartManifest(account_level, account_level_key, account_name)   
        }
        const setCustomChartManifest = (level:any, account_level_key:any, account_name:any) =>{
            setShowLoading(true)
            var s_account_level_0_key = 0
            var s_account_level_1_key = 0
            var s_account_level_2_key = 0
            var s_account_level_3_key = 0
            var custom_chart_type_id;
            if (level == 0 &&  action_type_id == 1){
                s_account_level_0_key =account_level_key
                custom_chart_type_id = 3511
            }
            if (level == 1 &&  action_type_id == 1){
                s_account_level_1_key =account_level_key
                custom_chart_type_id = 3512
            }
            if (level == 2 &&  action_type_id == 1){
                s_account_level_2_key =account_level_key
                custom_chart_type_id = 3513
            }
            if (level == 3 &&  action_type_id == 1){
                s_account_level_3_key =account_level_key
                custom_chart_type_id = 3514
            }

            if (level == 0 &&  action_type_id == 2){
                s_account_level_0_key =account_level_key
                custom_chart_type_id = 3515
            }
            if (level == 1 &&  action_type_id == 2){
                s_account_level_1_key =account_level_key
                custom_chart_type_id = 3516
            }
            if (level == 2 &&  action_type_id == 2){
                s_account_level_2_key =account_level_key
                custom_chart_type_id = 3517
            }
            if (level == 3 &&  action_type_id == 2){
                s_account_level_3_key =account_level_key
                custom_chart_type_id = 3518
            }

            fetch(props.props.primary_host+"ar/dbconnect/insert.jsp?etl=insert_custom_chart_manifest"+
            "&account_level_1_key="+s_account_level_1_key+
            "&account_level_2_key="+s_account_level_2_key+
            "&account_level_3_key="+s_account_level_3_key+
            "&account_level_0_key="+s_account_level_0_key+
            "&account_name="+account_name+
            "&custom_chart_id="+custom_chart_id+
            "&custom_chart_type_id="+custom_chart_type_id+
            "&start_date="+txtStartDate.current!.value+
            "&end_date="+txtEndDate.current!.value+
            "&analytical_reviews_id="+props.props.analytical_reviews_id+
            "&created_by="+props.props.user_id
            ,
                {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                }
            )
            .then(response => response.json())
            .then(data=>{
                console.log(data.data)
                callCustomCharts()
                setShowLoading(false)
            })
            .catch(error=>{
                setShowLoading(false)
            })
        }

        const setCustomChart = () =>{
            fetch(props.props.primary_host+"ar/dbconnect/insert.jsp?etl=insert_custom_chart"+
            "&name="+txtChartName.current!.value+
            "&analytical_reviews_id="+props.props.analytical_reviews_id+
            "&created_by="+props.props.user_id
            ,
                {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                }
            )
            .then(response => response.json())
            .then(data=>{
                custom_chart_id = data.data[0].id
                callCustomChartsList()
            })
        }
        const callCustomChartsList = () =>{ 
            var options:any = []
            setShowLoading(true)
            fetch(props.props.primary_host+"ar/dbconnect/select.jsp?etl=select_custom_chart"+
            "&analytical_reviews_id="+props.props.analytical_reviews_id+
            "&custom_chart_id=0"+
            "&created_by="+props.props.user_id
            ,
                {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                }
            )
            .then(response => response.json())
            .then(data=>{
                options.push(data.data)
                var list:any = options[0].map((x:any, i:number)=>{
                    return(
                        <IonCol size="12">
                            <IonRadio value={x.id}></IonRadio>&nbsp;{x.name}
                        </IonCol>
                    )
                })
                setMyCharts(list)
                setShowLoading(false)
            })
            .catch(error=>{
                setShowLoading(false)
            })
        }
        const setDateFormatHighCharts = (d:string) =>{
            var dt = new Date(d);
            var date = dt.getTime();
            //console.log(date)
            return date
        }

        const [getAccountForProfile, setAccountForProfile]              = useState<any>()
        const [getSummaryDetailTableData, setSummaryDetailTableData]    = useState<any>()
        const [getSelectedDate, setSelectedDate]                        = useState<any>()
        const [getJournalDetailData, setJournalDetailData]              = useState<any>()

        const drawlineSeries = (
                target:any, 
                data:any, 
                type:any, 
                series:any,
                account_level_3_key:any,
                account_level_2_key:any,
                account_level_1_key:any,
                account_level_0_key:any,
            ) =>{
            let previousPoint:any = null;
            var myPlotLineId="myPlotLine";
            
            let lineChart:any = {
                            chart:{
                                type: type,
                                //events: {
                                //    click: function(evt:any) {
                                //        var xValue = evt.xAxis[0].value;
                                //        var xAxis = evt.xAxis[0].axis;
                                //        console.log(this)
                                //        $.each(xAxis.plotLinesAndBands,function(){
                                //            if(this.id===myPlotLineId)
                                //            {
                                //                this.destroy();
                                //            }
                                //        });
                                //        xAxis.addPlotLine({
                                //            value: xValue,
                                //            width: 1,
                                //            color: 'red',
                                //            //dashStyle: 'dash',                   
                                //            id: myPlotLineId
                                //        });
                        //
                                //     }
                                //    }
                            },
                            title:{
                                text:''
                            },
                            subTitle:{
                                text:''
                            },
                            xAxis: {
                                type: 'datetime'
                            },
                            series:series,
                            plotOptions: {
                                series: {
                                    point: {
                                        events: {
                                            click: function(event:any) {
                                                console.log(event.point[event.point.name][0])
                                                console.log(event.point.series)
                                                console.log(event.point.name)
                                                console.log(event.point.datekey)
                                                console.log(event.point[event.point.name][0].account_level_0_key)
                                                console.log(event.point[event.point.name][0].account_level_1_key)
                                                console.log(event.point[event.point.name][0].account_level_2_key)
                                                console.log(event.point[event.point.name][0].account_level_3_key)
                                                start_date           =event.point.datekey
                                                end_date             =event.point.datekey
                                                var o:any = []
                                                o.push({
                                                primary_host         :props.props.primary_host,
                                                account_level_3      :event.point[event.point.name][0].account_level_3_key,
                                                account_level_2      :event.point[event.point.name][0].account_level_2_key,
                                                account_level_1      :event.point[event.point.name][0].account_level_1_key,
                                                account_level_0      :event.point[event.point.name][0].account_level_0_key,
                                                start_date           :event.point.datekey,
                                                end_date             :event.point.datekey,
                                                migration_id         :0,
                                                analytcat_report_id  :2,
                                                analytical_reviews_id:props.props.analytical_reviews_id,
                                                target_currency_id   :88,
                                                date_shortcut        :0
                                                })
                                                console.log(o[0])
                                                setAccountForProfile(event.point.name)
                                                
                                                callSummaryDetail(o[0])
                                                .then(response=>{return response})
                                                .then(data =>{
                                                    console.log(data)
                                                    setCharts(false)
                                                    setTable1x(true)
                                                    setTable2(false)
                                                    setSummaryDetailTableData(data)
                                                    
                                                })
                                                .catch(error=>{
                                                    console.log(error)
                                                })
                                                //if (previousPoint) {
                                                //    previousPoint.update({ color: '#7cb5ec' });
                                                //}
                                                //previousPoint = this;
                                                //this.update({ color: '#fe5800' });
                                            }
                                        }
                                    }
                                }
                            },
                            responsive: {
                                rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    layout: 'horizontal'
                                    },
                                    yAxis: {
                                    labels: {
                                        align: 'left',
                                        x: 0,
                                        y: -5
                                    },
                                    title: {
                                        text: null
                                    }
                                    },
                                    subtitle: {
                                    text: null
                                    },
                                    credits: {
                                    enabled: false
                                    }
                                }
                                }]
                            }
                        };
            let c:any = (<HighchartsReact highcharts={Highcharts} options={lineChart}></HighchartsReact>)
        
            setChart(c)
        }
        const callCustomCharts = () =>{ 
            setShowLoading(true)
            state.analytcal_reviews_id = props.props.analytical_reviews_id
            state.user_id = props.props.user_id

            fetch(props.props.primary_host+"ar/dbconnect/select.jsp?etl=selectfactaccountsbalancebyaccountscustom"+
                "&account_level_0_key="+0+
                "&account_level_1_key="+0+
                "&account_level_2_key="+0+
                "&account_level_3_key="+0+
                "&custom_chart_id="+custom_chart_id+
                "&created_by="+props.props.user_id+
                "&migrationid="+0+
                "&analyticalreviewsid="+props.props.analytical_reviews_id+
                "&startdate="+txtStartDate.current!.value+
                "&targetcurrencyid="+88+
                "&dateshortcut="+0+
                "&enddate="+txtEndDate.current!.value,
                {
                    headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                }
            )
            .then(response => response.json())
            .then(data =>{
                renderCharts(data)
                setShowLoading(false)
                callCustomChartsCorrelation()
            })
            .catch(error=>{
                setShowLoading(false)
                var z:any = null
                setSelectedAccount(z)
            })
        }
        const [getCorrelation, setCorrelation]          = useState<any>()
        const [getCorrelationData, setCorrelationData]  = useState<any>()
        const [getCorrelationTable, setCorrelationTable]    = useState<any>()
        const callCustomChartsCorrelation = () =>{ 
            setShowLoading(true)
            fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?dbo=selectfactaccountsbalancebyaccountscustom_correlation"+
                "&account_level_0_key="+0+
                "&account_level_1_key="+0+
                "&account_level_2_key="+0+
                "&account_level_3_key="+0+
                "&custom_chart_id="+custom_chart_id+
                //"&created_by="+props.props.user_id+
                //"&migrationid="+0+
                "&analyticalreviewsid="+props.props.analytical_reviews_id+
                "&startdate="+txtStartDate.current!.value+
                "&targetcurrencyid="+88+
                "&dateshortcut="+0+
                "&enddate="+txtEndDate.current!.value,
                {
                    headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                }
            )
            .then(response => response.json())
            .then(data =>{
                //alert(data.data[0].correlation)
                setShowLoading(false)
                setCorrelation((data.data[0].correlation/1).toFixed(6))
                setCorrelationData(data.data)
                var x:any = []
                x = <CorrelationTable data={data.data} />
                setCorrelationTable(x)
            })
            .catch(error=>{
                setShowLoading(false)
                var z:any = null
                setSelectedAccount(z)
                setCorrelation(0.000000)
            })
        }
        const deleteColumn = (name:any) =>{
            fetch(props.props.primary_host+"ar/dbconnect/delete.jsp?etl=delete_custom_chart_manifest"+
                "&account_name="+name+
                "&analytical_reviews_id="+props.props.analytical_reviews_id,
                {
                    headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                }
            )
            .then(response => response.json())
            .then(data=>{
                callCustomCharts()
            })

        }
        const renderCharts = (data:any) =>{
            setShowLoading(false)
            var series =[]
            var prop:any;
            var headers:any = []
            var TableHeaders:any = []
            var y:any = 0;
            existing = []
            var z:any = null
            setSelectedAccount(z)
            for(prop in data.data[0]){
                if(y >= 0){
                    console.log(prop)
                    headers.push(prop)
                    if(prop != "datekey" && !prop.includes("account_level_0_key") && !prop.includes("account_level_1_key") && !prop.includes("account_level_2_key") && !prop.includes("account_level_3_key")){
                        var o:any = (
                            <IonCol className="custom-account-text-icon-selected">
                                <IonRow>
                                    <IonCol size="10">
                                        {prop}
                                    </IonCol>
                                    <IonCol size="1" title={prop} onClick={(e)=>{setAccountSettings("custom-account-settings-show")}}>
                                        <IonIcon className="size-20" icon={settingsOutline} ></IonIcon>
                                    </IonCol>
                                    <IonCol size="1" title={prop} onClick={(e)=>{deleteColumn(e.currentTarget.title)}}>
                                        <IonIcon className="size-24 text-red" icon={closeOutline} ></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        )
                        existing = [existing, o]
                    }
                }
            }
            setSelectedAccount(existing);
            for (var j = 0 ; j < headers.length; j++){
                if(headers[j] != "datekey" && !headers[j].includes("account_level_0_key") && !headers[j].includes("account_level_1_key") && !headers[j].includes("account_level_2_key") && !headers[j].includes("account_level_3_key")){
                    var s = []
                    for(var i = 0 ; i < data.data.length; i++){
                        var account:any;
                        for(account in data.data[i]){
                            if(account.includes(headers[j]+"_account_level_0_key")){
                                account_level_0 = data.data[i][account]
                            }
                            if(account.includes(headers[j]+"_account_level_1_key")){
                                account_level_1 = data.data[i][account]
                            }
                            if(account.includes(headers[j]+"_account_level_2_key")){
                                account_level_2 = data.data[i][account]
                            }
                            if(account.includes(headers[j]+"_account_level_3_key")){
                                account_level_3 = data.data[i][account]
                            }  
                        }
                        var ticks:any = []
                        s.push({
                            x:setDateFormatHighCharts(data.data[i].datekey), 
                            y:data.data[i][headers[j]]/1,
                            datekey:data.data[i].datekey, 
                            name:[headers[j]],
                            [headers[j]]:[{
                            account_level_3_key: account_level_3, 
                            account_level_2_key: account_level_2,
                            account_level_1_key: account_level_1,
                            account_level_0_key: account_level_0, }]
                        })
                    }
                    series.push({name:[headers[j]], data:s})
                    drawlineSeries("drawBalanceAccounts",s, "line", series,null, null, null, null )
                }
            }
            var options:any = []
            for(prop in data.data[0]){
                if(y >= 0){
                    console.log(prop)
                    TableHeaders.push(prop)
                    if(true){
                        var o:any = (
                            <IonCol>
                                {prop}
                            </IonCol>
                        )
                    }
                }
            }
            options.push(data.data)
            var table:any = options[0].map((x:any, i:number)=>{
                return(
                    <IonCol>{x.date}</IonCol>
                )
            })
        }
        const [getChart, setChart]                            = useState<boolean>()
        const [getCharts, setCharts]                          = useState<boolean>(true)
        const [getTable1x, setTable1x]                          = useState<boolean>(false)
        const [getTable2, setTable2]                          = useState<boolean>(false)
        const [getCorrelationMatrix,setCorrelationMatrix]     = useState<boolean>(false)
        React.useEffect(() => {
            //callCustomCharts()
            
            
        }, []);
        return( 
        <div className="">
            <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'} ></IonLoading>
            <div className={getLiveConnections}>
                <ProjectsSide 
                    state={props.props} 
                    data={getLiveConnectionsListData}
                    setAnalyticalReviewsId={(e:any)=>{
                        console.log("return:"+props.props)
                        console.log(props.props)
                        console.log(e);
                        props.props.analytical_reviews_id = e.analytcal_reviews_id;
                        state.analytical_reviews_id = e.analytcal_reviews_id;
                        state.migration_id = e.migration_id;
                        state.project_name = e.name;
                        state.migration_name = e.name;
                        setAnalyticalReviewsId(e.analytcal_reviews_id);
                        txtStartDate.current!.value = e.start_date;
                        txtEndDate.current!.value = e.end_date;
                        callDimAccountLevel3()
                        callDimAccountLevel1()
                        callDimAccountLevel0()
                        callCustomChartsList()
                        setLiveConnections("custom-live-connections-area-hide")
                        setView(2)
                    }}
                    setState={(id:any)=>{}}
                    setLiveConnections={(e:any)=>{setLiveConnections(e)}}
                    getLiveConnectionsList={getLiveConnectionsListData}
                    setPageView={(e:any)=>{}} 
                    setOrganizationId={(e:any)=>{}} 
                    setOrganzationName={(e:any)=>{}} 
                    setLastUpdate={(e:any)=>{}} 
                    setYearEndDay={(e:any)=>{}} 
                    setYearEndMonth={(e:any)=>{}} 
                    setAuthorizedOrganization={(e:any)=>{}} 
                    setIndustry={(e:any)=>{}} 
                    setSector={(e:any)=>{}} 
                    setNativeCurrency={(e:any)=>{}} 
                    showSettingStage={(e:any)=>{}} 
                    callXeroAvailableYears={(e:any)=>{}} 
                />
            </div>
            <div className={getAccountSettings + " ion-padding"}>
                <IonHeader className="">
                    <IonRow>
                        <IonCol className="size-16"><b>Account Chart Settings</b></IonCol>
                        <IonCol size="1" className="ion-text-right">
                            <IonIcon onClick={()=>{setAccountSettings("custom-account-settings-hide")}} className="size-32" icon={closeOutline}></IonIcon>
                        </IonCol>
                    </IonRow>
                </IonHeader>
                <IonContent>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonRadio></IonRadio>&nbsp;Sum/Net
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonRadio></IonRadio>&nbsp;Divide/Ratio
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </div>
            <div className={getAccountSelector + " ion-padding"}>
            
                <IonHeader className="">
                    <IonRow>
                        <IonCol className="size-16"><b>Account Selector</b></IonCol>
                        <IonCol className="ion-text-right">
                            <IonIcon onClick={()=>{setAccountSelector("custom-account-selector-hide")}} className="size-32" icon={closeOutline}></IonIcon>
                        </IonCol>
                    </IonRow>
                </IonHeader>
                <IonRow>
                    <IonCol size="3" className="ion-text-center ion-padding">
                        <IonRow>
                            <IonCol><b>Account Level 3</b></IonCol>
                        </IonRow>
                        <IonContent className="custom-account-content size-14">
                        <IonRow>
                            <IonCol className="custom-account-text-icon">
                                <IonRow>
                                    <IonCol size="10" onClick={()=>{setAccountProperty(3,1,"Assets")}} >
                                        Assets
                                    </IonCol>
                                    <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(3,1)}} >
                                        <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="custom-account-text-icon">
                                <IonRow>
                                    <IonCol size="10" onClick={()=>{setAccountProperty(3,1,"Liabilities")}} >
                                        Liabilities
                                    </IonCol>
                                    <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(3,1)}} >
                                        <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="custom-account-text-icon">
                                <IonRow>
                                    <IonCol size="10" onClick={()=>{setAccountProperty(3,2,"Owners Equity")}} >
                                        Owners Equity
                                    </IonCol>
                                    <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(3,2)}} >
                                        <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="custom-account-text-icon">
                                <IonRow>
                                    <IonCol size="10" onClick={()=>{setAccountProperty(3,4,"Income")}} >
                                        Income
                                    </IonCol>
                                    <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(3,4)}} >
                                        <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="custom-account-text-icon">
                                <IonRow>
                                    <IonCol size="10" onClick={()=>{setAccountProperty(3,5,"Expenses")}} >
                                        Expenses
                                    </IonCol>
                                    <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(3,5)}} >
                                        <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        </IonContent>
                    </IonCol>
                    <IonCol size="3" className="ion-text-center ion-padding">
                        <IonRow>
                            <IonCol><b>Account Level 2</b></IonCol>
                        </IonRow>
                        <IonContent className="custom-account-content size-14">
                            <IonRow>
                                <IonCol className="custom-account-text-icon">
                                    <IonRow>
                                        <IonCol size="10" onClick={()=>{setAccountProperty(2,1,"Current Assets")}} >
                                            Current Assets
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(2,1)}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon">
                                    <IonRow>
                                        <IonCol size="10" onClick={()=>{setAccountProperty(2,2,"Non Current Assets")}} >
                                            Non Current Assets
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(2,2)}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon">
                                    <IonRow>
                                        <IonCol size="10" onClick={()=>{setAccountProperty(2,3,"Owners Equity")}} >
                                            Owners Equity
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(2,3)}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon">
                                    <IonRow>
                                        <IonCol size="10" onClick={()=>{setAccountProperty(2,4,"Current Liabilities")}} >
                                            Current Liabilities
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(2,4)}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon">
                                    <IonRow>
                                        <IonCol size="10" onClick={()=>{setAccountProperty(2,5,"Non Current Liabilities")}} >
                                            Non Current Liabilities
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(2,5)}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon">
                                    <IonRow>
                                        <IonCol size="10" onClick={()=>{setAccountProperty(2,6,"Income")}} >
                                           Income
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(2,6)}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon">
                                    <IonRow>
                                        <IonCol size="10" onClick={()=>{setAccountProperty(2,7,"Expenses")}} >
                                            Expenses
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{setSelectorOptions(2,7)}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonContent>
                    </IonCol>
                    <IonCol className="ion-text-center ion-padding">
                        <IonRow>
                            <IonCol><b>Account Level 1</b></IonCol>
                        </IonRow>
                        <IonContent className="custom-account-content size-14">
                            {getAccountLevel1}
                        </IonContent>
                    </IonCol>
                    <IonCol className="ion-text-center ion-padding">
                        <IonRow>
                            <IonCol><b>Account Level 0</b></IonCol>
                        </IonRow>
                        <IonContent className="custom-account-content size-14">
                            {getAccountLevel0}
                        </IonContent>
                    </IonCol>
                </IonRow>
            </div>
            <div className="custom-chart-control-panel ion-padding">
            <IonRow>
                <IonHeader>
                    <IonRow>
                        <IonCol className="size-16"><b>Control Panel</b></IonCol>
                    </IonRow>
                </IonHeader>
                <IonCol>
                    <IonRow>
                        <IonCol size="12" className="size-16"><b>Select A Data Source</b></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <div onClick={()=>{callLiveConnections();setView(1)}} className="ion-text-center custom-account-start-text-icon">
                                <IonRow>
                                    <IonCol size="10" >
                                        <div className="">Data Source</div>
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonIcon className="size-32 icon-gt icon-start" icon={cloudOutline}></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </div> 
                        </IonCol>
                    </IonRow>
                    {getNewChartProject &&
                    <div>
                        <IonRow>
                            <IonCol size="12" className="size-16"><b>Create A New Chart</b></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <div onClick={()=>{showNewChart(true)}} className="ion-text-center custom-account-start-text-icon">
                                    <IonRow>
                                        <IonCol size="10" >
                                            <div className="">New Chart</div>
                                        </IonCol>
                                        <IonCol size="2">
                                            <IonIcon className="size-32 icon-gt icon-start" icon={addOutline}></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </div> 
                            </IonCol>
                            {hideNewChart &&
                            <IonRow>
                                <IonCol size="10">
                                    <IonInput placeholder='Name Of Your Chart' type="text" className="input-style-1" ref={txtChartName}  />
                                </IonCol>
                                <IonCol size="2">
                                    <IonIcon onClick={()=>{setCustomChart(); showNewChart(false) }} icon={addOutline} className="size-32 icon-gt" />
                                </IonCol>
                            </IonRow>
                            }
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="size-16"><b>My Charts</b></IonCol>
                        </IonRow>
                        <IonRadioGroup onIonChange={(e)=>{setView(3);custom_chart_id = e.detail.value; callCustomCharts()}}>
                            <IonRow>
                                {getMyCharts}
                            </IonRow>
                        </IonRadioGroup>
                    </div>
                    }
                    {getPickAccount &&
                    <div>
                        <IonRow>&nbsp;</IonRow>
                        <IonRow>
                            <IonCol size="12" className="size-16"><b>Pick Account</b></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <div onClick={()=>{setAccountSelector("custom-account-selector-show")}} className="ion-text-center custom-account-start-text-icon">
                                    <IonRow>
                                        <IonCol size="10">
                                            <div className="">Select Account To Chart</div>
                                        </IonCol>
                                        <IonCol size="2" className="">
                                            <IonIcon className="size-32 icon-gt" icon={chevronForward}></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </div> 
                            </IonCol> 
                        </IonRow>
                        <IonRow>&nbsp;</IonRow>
                        <IonRow>
                            <IonCol size="12" className="size-16"><b>Load Data</b></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <div onClick={()=>{setView(4)}} className="ion-text-center custom-account-start-text-icon">
                                    <IonRow>
                                        <IonCol size="10">
                                            <div className="">Select File</div>
                                        </IonCol>
                                        <IonCol size="2" className="">
                                            <IonIcon className="size-32 icon-gt" icon={chevronForward}></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </div> 
                            </IonCol> 
                        </IonRow>
                    </div>
                    }
                </IonCol>
            </IonRow>
            </div>
            <div className="custom-chart-panel ion-padding">
                <IonHeader>
                    <IonRow>
                        <IonCol className="size-16"><b>Custom Chart</b></IonCol>
                        <IonCol size="4" className="ion-text-right">
                            <IonLabel className="size-16">Start Date:&nbsp;</IonLabel> 
                            <IonInput ref={txtStartDate}  placeholder="Start Date" type="date" className='input-style-11 size-16 '/>
                        </IonCol>
                        <IonCol size="4" className="ion-text-right">
                            <IonLabel className="size-16">End Date:&nbsp;</IonLabel>
                            <IonInput ref={txtEndDate}   placeholder="End Date" type="date"  className='input-style-11 size-16 '/> 
                        </IonCol>
                        <IonCol size="1">
                            <div className="fetch-button" onClick={()=>{start_date = txtStartDate.current!.value; end_date = txtEndDate.current!.value;callCustomCharts()}}>Fetch</div>
                        </IonCol>
                    </IonRow>
                </IonHeader>
                <IonContent className="custom-content">
                    {!getFile &&
                    <div>
                        {getCharts &&
                        <div>
                            <IonRow>
                                <IonCol size="12">{getChart}</IonCol>
                            </IonRow>
                        </div>
                        }
                        {getCorrelationMatrix &&
                        <div>
                            <IonRow className="">
                                <IonCol size="7">
                                    <IonRow className="size-18 summary-account-level-1">
                                        <IonCol><b>Correlation Matrix</b></IonCol>
                                    </IonRow>
                                </IonCol>
                                <IonCol className="ion-text-right ion-padding" >
                                    {getCharts &&
                                    <div  onClick={()=>{setCharts(false); setTable1x(true)}}>
                                        View Table &nbsp; <IonIcon icon={listOutline} className="size-32"></IonIcon>
                                    </div>
                                    }
                                    {!getCharts &&
                                    <div onClick={()=>{setCharts(true); setTable1x(false)}}>
                                        View Chart &nbsp; <IonIcon icon={analytics} className="size-32"></IonIcon>
                                    </div>
                                    }
                                </IonCol>
                                <IonCol  className="ion-text-right ion-padding" onClick={()=>{setCharts(true); setTable1x(false); setCorrelationMatrix(false)}}>
                                    Back &nbsp; <IonIcon icon={returnUpBack} className="size-32"></IonIcon>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >{getCorrelationTable}</IonCol>
                            </IonRow>
                        </div>
                        }
                    </div>
                    }
                    {getFile &&
                    <div>
                        <IonRow>
                            <IonCol><b>Load Custom Data File</b></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <CustomImport state={state} />
                            </IonCol>
                        </IonRow>                            
                    </div>
                    }
                    {getTable1x &&     
                    <div className="size-10">
                        <IonRow className="">
                            <IonCol size="7">
                                <IonRow className="size-18 summary-account-level-1">
                                    <IonCol><b>Account Daily Summary</b></IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">Activity profile of account({getAccountForProfile}) From {start_date} to {end_date}</IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol className="ion-text-right ion-padding" >
                                {getCharts &&
                                <div  onClick={()=>{setCharts(false); setTable1x(true)}}>
                                    View Table &nbsp; <IonIcon icon={listOutline} className="size-32"></IonIcon>
                                </div>
                                }
                                {!getCharts &&
                                <div onClick={()=>{setCharts(true); setTable1x(false)}}>
                                    View Chart &nbsp; <IonIcon icon={analytics} className="size-32"></IonIcon>
                                </div>
                                }
                            </IonCol>
                            <IonCol className="ion-text-right ion-padding" onClick={()=>{/*exportResults()*/}}>
                                Export &nbsp; <IonIcon icon={print} className="size-32"></IonIcon>
                            </IonCol>
                            <IonCol  className="ion-text-right ion-padding" onClick={()=>{setCharts(true); setTable1x(false)}}>
                                Back &nbsp; <IonIcon icon={returnUpBack} className="size-32"></IonIcon>
                            </IonCol>
                        </IonRow>
                        {(!getCharts)  &&
                        <IonRow className="summary-row-total">
                            <IonCol size="2" className="ion-text-left">Date</IonCol>
                            <IonCol size="5" className="ion-text-left">Desciption</IonCol>
                            <IonCol size="1" className="ion-text-right">Debit</IonCol>
                            <IonCol size="1" className="ion-text-right">Credit</IonCol>
                            <IonCol size="1" className="ion-text-right">Opening</IonCol>
                            <IonCol size="1" className="ion-text-right">Closing</IonCol>
                            <IonCol size="1" className="ion-text-right">Movement</IonCol>
                        </IonRow>
                        }              
                        <IonRow>
                            <IonCol size="12">
                                <SummaryDetailTable 
                                    data={getSummaryDetailTableData}
                                    callDetailTransactions={(e:any)=>{
                                        console.log(e)
                                        console.log(props.props.primary_host)
                                        setSelectedDate(e.datekey)
                                        var x:any = []
                                        x.push({primary_host:props.props.primary_host,analytical_reviews_id:props.props.analytical_reviews_id, datekey:e.datekey, code:e.code})
                                        callDetailTransactions(x[0], e.datekey, e.code)
                                        .then(response=>{return response})
                                        .then(data=>{
                                            console.log(data)
                                            setJournalDetailData(data)
                                            setView(3)
                                            setTable1x(false)
                                            setTable2(true)
                                        })
                                    }}
                                />
                            </IonCol>
                        </IonRow>
                    </div> 
                    }
                    {getTable2 &&
                    <div className="size-10">
                        <IonRow className="">
                            <IonCol size="7">
                                <IonRow className="size-18 summary-account-level-1">
                                    <IonCol><b>Account Daily Summary</b></IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">Activity profile of account({getAccountForProfile}) From {start_date} to {end_date}</IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol className="ion-text-right ion-padding" >
                                {getCharts &&
                                <div  onClick={()=>{setCharts(false); setTable2(true)}}>
                                    View Table &nbsp; <IonIcon icon={listOutline} className="size-32"></IonIcon>
                                </div>
                                }
                                {!getCharts &&
                                <div onClick={()=>{setCharts(true); setTable2(false)}}>
                                    View Chart &nbsp; <IonIcon icon={analytics} className="size-32"></IonIcon>
                                </div>
                                }
                            </IonCol>
                            <IonCol className="ion-text-right ion-padding" onClick={()=>{/*exportResults()*/}}>
                                Export &nbsp; <IonIcon icon={print} className="size-32"></IonIcon>
                            </IonCol>
                            <IonCol  className="ion-text-right ion-padding" onClick={()=>{setCharts(true); setTable2(false)}}>
                                Back &nbsp; <IonIcon icon={returnUpBack} className="size-32"></IonIcon>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" >
                            <JournalDetail
                                searchMatchingJournals={(e:any)=>{console.log(e)}}
                                data={getJournalDetailData}
                                global_props={props}
                            />
                            </IonCol>
                        </IonRow>
                    </div>
                }
                </IonContent>
                <IonFooter>
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol size="6" className="size-16"><b>Selected Accounts</b></IonCol>
                                <IonCol size="6" className="ion-text-right text-gt" onClick={()=>{setCorrelationMatrix(true); setCharts(false)}}>Pearsons Correlation (r)</IonCol>
                            </IonRow>
                            <IonRow className="custom-account-control-panel-content">
                                {getSelectedAccount}
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonFooter>
            </div>
        </div>
    )
}
export default CustomForm;