
import { IonCol, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import { arrowForwardCircleOutline, caretDown, caretUp, chevronDown, chevronForward, notifications } from 'ionicons/icons';
import { useState,useEffect } from 'react';
import { addCommas } from '../../dbconnections/Select';
const IncomeExpenses = (props:any, data:any) =>{ 
  //console.log(props)
  const [getPrevEndDate, setPrevEndDate]                        = useState<any>()
  const [getEndDate, setEndDate]                                = useState<any>()
  const [hideCurrentAssets, showCurrentAssets]                  = useState<boolean>(true)
  const [getRotateIcon, setRotateIcon]                          = useState<string>("rotate-icon-forward")
  const [hideNonCurrentAssets, showNonCurrentAssets]            = useState<boolean>(true)
  const [hideOwnersEquity, showOwnersEquity]                    = useState<boolean>(true)
  const [hideCurrentLiabilities, showCurrentLiabilities]        = useState<boolean>(true)
  const [hideNonCurrentLiabilities, showNonCurrentLiabilities]  = useState<boolean>(true)
  const [hideIncome, showIncome]                                = useState<boolean>(true)
  const [hideExpenses, showExpenses]                            = useState<boolean>(true)

  const [getState, setState]      = useState<any>()
  const [getCurrentAssets, setCurrentAssets] = useState<any>()
  const [getIncomeStart, setIncomeStart]                                = useState<any>()
  const [getEquityStart, setEquityStart]                                = useState<any>()
  const [getExpensesStart, setExpensesStart]                            = useState<any>()

  const [getCurrentAssetsStart, setCurrentAssetsStart]                  = useState<any>()
  const [getNonCurrentAssetsStart, setNonCurrentAssetsStart]            = useState<any>()
  const [getCurrentLiabilitiesStart, setCurrentLiabilitiesStart]        = useState<any>()
  const [getNonCurrentLiabilitiesStart, setNonCurrentLiabilitiesStart]  = useState<any>()

  const [getIncomeMovementTotal, setIncomeMovementTotal]                = useState<string>()
  const [getExpensesMovementTotal, setExpensesMovementTotal]            = useState<string>()

  const [getGains, setGains]                                             = useState<string>()

  const [getExpensesStartTotal, setExpensesStartTotal]                            = useState<string>()
  const [getIncomeStartTotal, setIncomeStartTotal]                                = useState<string>()

  const [getStartTotal, setStartTotal]                                            = useState<string>()
  const [getEndTotal, setEndTotal]                                                = useState<string>()
  const [getMovementTotal, setMovementTotal]                                      = useState<string>()

  const [getAssetsMovementTotal, setAssetsMovementTotal]                          = useState<string>()
  const [getCurrentAssetsMovementTotal, setCurrentAssetsMovementTotal]            = useState<string>()
  const [getNonCurrentAssetsMovementTotal, setNonCurrentAssetsMovementTotal]      = useState<string>()
  const [getCurrentAssetsStartTotal, setCurrentAssetsStartTotal]                  = useState<string>()
  const [getAssetsStartTotal, setAssetsStartTotal]                                = useState<string>()
  const [getNonCurrentAssetsStartTotal, setNonCurrentAssetsStartTotal]            = useState<string>()
  
  const [getEquityStartTotal, setEquityStartTotal]                                = useState<string>()


  const [getIncomeEndTotal, setIncomeEndTotal]                                    = useState<string>()
  const [getExpensesEndTotal, setExpensesEndTotal]                                = useState<string>()
  
  const [getLiabilitiesMovementTotal, setLiabilitiesMovementTotal]                = useState<string>()
  const [getCurrentLiabilitiesMovementTotal, setCurrentLiabilitiesMovementTotal]  = useState<string>()
  const [getNonCurrentLiabilitiesMovementTotal, setNonCurrentLiabilitiesMovementTotal]  = useState<string>()
  const [getEquityMovementTotal, setEquityMovementTotal]                          = useState<string>()

  const [getLiabilitiesStartTotal, setLiabilitiesStartTotal]                      = useState<string>()
  const [getCurrentLiabilitiesStartTotal, setCurrentLiabilitiesStartTotal]        = useState<string>()
  const [getNonCurrentLiabilitiesStartTotal, setNonCurrentLiabilitiesStartTotal]  = useState<string>()

  const [getAssetsEndTotal, setAssetsEndTotal]                                    = useState<string>()
  const [getCurrentAssetsEndTotal, setCurrentAssetsEndTotal]                      = useState<string>()
  const [getNonCurrentAssetsEndTotal, setNonCurrentAssetsEndTotal]                = useState<string>()
  const [getEquityEndTotal, setEquityEndTotal]                                    = useState<string>()

  const [getLiabilitiesEndTotal, setLiabilitiesEndTotal]                          = useState<string>()
  const [getCurrentLiabilitiesEndTotal, setCurrentLiabilitiesEndTotal]            = useState<string>()
  const [getNonCurrentLiabilitiesEndTotal, setNonCurrentLiabilitiesEndTotal]      = useState<string>()
  
  const [getTotalDebit          , setTotalDebit ]             = useState<string>()
  const [getTotalCredit         , setTotalCredit ]            = useState<string>()
  const [getTotalOpening_Balance, setTotalOpening_Balance]    = useState<string>()
  const [getTotalClosing_Balance, setTotalClosing_Balance]    = useState<string>()
  const [getTotalMovement       , setTotalMovement]           = useState<string>()
  var prevState:any;
  var gain_counter                          :number = 0;
  var gains:any;
  var equity_gains:any = []                     
  var net_gains                             :number = 0;
  var current_assets_start_total            :number = 0;
  var current_assets_end_total              :number = 0;
  var current_assets_movement_total         :number = 0;

  var income_start_total                    :number = 0;
  var income_end_total                      :number = 0;
  var income_movement_total                 :number = 0;

  var expense_start_total                    :number = 0;
  var expense_end_total                      :number = 0;
  var expense_movement_total                 :number = 0;

  var non_current_assets_start_total        :number = 0;
  var non_current_assets_end_total          :number = 0;
  var non_current_assets_movement_total     :number = 0;

  var equity_start_total            :number = 0;
  var equity_end_total              :number = 0;
  var equity_movement_total         :number = 0;

  var current_liabilities_start_total       :number = 0;
  var current_liabilities_end_total         :number = 0;
  var current_liabilities_movement_total    :number = 0;

  var non_current_liabilities_start_total   :number = 0;
  var non_current_liabilities_end_total     :number = 0;
  var non_current_liabilities_movement_total:number = 0;


  const setIncomeExenses = (global_props:any, balance_sheet:any) =>{
  console.log(balance_sheet.data)
  var options:any = []
  options.push(balance_sheet.data)
  //global_props.analytical_report_id=1;
  var current_assets_start:any;
 
  try{   
    setPrevEndDate(balance_sheet.data[0].opening_date)
    //setStartDate(data.data[0].start_date)
    setEndDate(balance_sheet.data[0].ending_date)
      
      var income_start:any = options[0].map((x:any, i:any)=>{

        if(x.account_level_3_key == 4  && (x.opening_value != 0 && x.closing_value != 0)){
          income_start_total += x.opening_value/1;
          income_end_total += x.ending_value/1;
          income_movement_total += x.ie_movement/1;
          //props.global_props.account_level_0 = 0
          //props.global_props.account_level_1 = x.account_level_1_key
          //props.account_level_2 = x.account_level_2_key
          //props.account_level_3 = x.account_level_3_key
          return(
            <IonRow key={i} className={"summary-detail-row"}>
              <IonCol className="summary-account" size="4">
                <span><IonIcon onClick={()=>{
                  //showActionPreDefined(false);showActionDefined(false);
                  //showKPIModal(true);
                  //setAccountLevel3kpi(x.account_level_3_key);
                  //setAccountLevel2kpi(x.account_level_2_key);
                  //setAccountLevel1kpi(x.account_level_1_key);
                  //setAccountLevel0kpi(x.account_level_0_key);
                  //setAccountForProfile(x.account_level_0_description)
                  //setAccountCurrentValue(addCommas(x.ending_value))
                  
                  }} icon={notifications} className={x.kpi == 0?"text-grey size-24":x.kpi == 1? "text-green size-24":x.kpi == -1?"text-red size-24":"text-grey size-24"}></IonIcon></span>
                <span>&nbsp;<IonIcon onClick={ev=>{
                //props.global_props.account_level_0 = x.account_level_0_key;
                //props.global_props.account_level_1 = x.account_level_1_key;
                //row_id = i; 
                //date_desc = x.datekey
                //props.global_props.account_level_0_desc = x.DESC;
                //props.global_props.account_level_1_desc = x.account_level_1
                //setHighlighted()
                }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-24':'text-grey size-24'}></IonIcon></span>
                <span onClick={()=>{
                //props.migration_id = 0;
                //showSettingStage(5); 
                //props.analytical_report_id = 8;
                //setSummaryDashBoardIncomeDetail(true);
                //props.account_level_3 = x.account_level_3_key
                //props.account_level_2 = x.account_level_2_key
                //props.global_props.account_level_1 = x.account_level_1_key
                //props.global_props.account_level_0 = x.account_level_0_key
                //props.start_date          = x.sdate
                //props.end_date            = x.edate
                props.callSummaryAssets(
                  x.account_level_3_key,
                  x.account_level_2_key,
                  x.account_level_1_key,
                  x.account_level_0_key,
                  x.sdate,
                  x.edate,
                  0,
                  8,
                  x.account_level_0_description
                )   
                }}> {x.account_level_0_description}</span>
              </IonCol>
              <IonCol size="6" className="ion-text-right">{addCommas(x.ie_movement)}{(x.ie_movement > 0)? <IonIcon icon={caretUp} color="success"></IonIcon>:x.ie_movement < 0? <IonIcon icon={caretDown} color="danger"></IonIcon> : <div></div>}</IonCol>
              <IonCol size="2" className="ion-text-right">{x.movement_percent}</IonCol>
            </IonRow>
          )
        }
      })
        var expense_start:any = options[0].map((x:any, i:any)=>{
          if(x.account_level_2_key == 7 && (x.opening_value != 0 && x.closing_value != 0)){
            expense_start_total += x.opening_value/1;
            expense_end_total += x.ending_value/1;
            expense_movement_total += x.ie_movement/1;
            //props.global_props.account_level_0 = 0
            //props.global_props.account_level_1 = x.account_level_1_key
            //props.account_level_2 = x.account_level_2_key
            //props.account_level_3 = x.account_level_3_key
            return(
              <IonRow key={i}  className={"summary-detail-row"}>
                <IonCol className="summary-account" size="4">
                  <span><IonIcon onClick={()=>{
                  //props.migration_id = 0;
                  //showActionPreDefined(false);showActionDefined(false);
                  //showKPIModal(true);
                  //setAccountLevel3kpi(x.account_level_3_key);
                  //setAccountLevel2kpi(x.account_level_2_key);
                  //setAccountLevel1kpi(x.account_level_1_key);
                  //setAccountLevel0kpi(x.account_level_0_key);
                  //setAccountForProfile(x.account_level_0_description)
                  //setAccountCurrentValue(addCommas(x.ending_value))
                  }} icon={notifications} className={x.kpi == 0?"text-grey size-24":x.kpi == 1? "text-green size-24":x.kpi == -1?"text-red size-24":"text-grey size-24"}></IonIcon></span>
                  <span>&nbsp;<IonIcon onClick={ev=>{
                //props.global_props.account_level_0 = x.account_level_0_key;
                //props.global_props.account_level_1 = x.account_level_1_key;
                //row_id = i; 
                //date_desc = x.datekey
                //props.global_props.account_level_0_desc = x.DESC;
                //props.global_props.account_level_1_desc = x.account_level_1
                //setHighlighted()
                }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-24':'text-grey size-24'}></IonIcon></span>
                <span onClick={()=>{
                  //showSettingStage(5); 
                  //props.analytical_report_id = 8;
                  //setSummaryDashBoardIncomeDetail(true);
                  props.callSummaryAssets(
                    x.account_level_3_key,
                    x.account_level_2_key,
                    x.account_level_1_key,
                    x.account_level_0_key,
                    x.sdate,
                    x.edate,
                    0,
                    8,
                    x.account_level_0_description
                  ) 
                  }}> {x.account_level_0_description}</span>
                </IonCol>
                <IonCol size="6" className="ion-text-right">{addCommas(x.ie_movement)}{(x.movement > 0)? <IonIcon icon={caretUp} color="success"></IonIcon>:x.ie_movement < 0? <IonIcon icon={caretDown} color="danger"></IonIcon> : <div></div>}</IonCol>
                <IonCol size="2" className="ion-text-right">{x.movement_percent}</IonCol>
              </IonRow>
            )
          }
      })
    }catch(error:any){
      console.log(error)
    }
    
    setIncomeStart(income_start)
    setExpensesStart(expense_start)
    setIncomeStartTotal(addCommas(income_start_total.toFixed(2)))            
    setIncomeEndTotal(addCommas(income_end_total.toFixed(2)))             
    setIncomeMovementTotal(addCommas(income_movement_total.toFixed(2)))  
    
    setExpensesStartTotal(addCommas(expense_start_total.toFixed(2)))            
    setExpensesEndTotal(addCommas(expense_end_total.toFixed(2)))             
    setExpensesMovementTotal(addCommas(expense_movement_total.toFixed(2))) 
    setGains(addCommas((income_movement_total /1 - expense_movement_total/1).toFixed(2)))
    }

    useEffect(() => {
        setState(()=>{
            if(props.props != prevState){
                prevState = props.props
                console.log(props.props)
                setIncomeExenses(props.props.global_props,props.data)
            }}
        )
        
    }, [props]); 
    return(
    <div>
      <div className="size-20 freeze-header"><b>Income & Expenses Current</b></div>
      <div className="main-content-bs">
      <div  >
        <IonRow className="summary-account-level-1 ">
          <IonCol>Income/Revenue</IonCol>
        </IonRow>
        <IonRow className="summary-row-header">
          <IonCol size="4">
            {hideIncome?
            <IonIcon onClick={()=>{showIncome(false)}} icon={chevronForward} className="size-20"></IonIcon>
            :
            <IonIcon onClick={()=>{showIncome(true)}} icon={chevronDown} className="size-20"></IonIcon>
            }&nbsp;
            Account
          </IonCol>
          <IonCol className="ion-text-right" size="6">Movement ({getPrevEndDate}) to ({getEndDate})</IonCol>
          <IonCol className="ion-text-right" size="2">Movement %</IonCol>
        </IonRow>
        {!hideIncome &&
        <div>
          {getIncomeStart}
        </div>
        }
        <IonRow className="summary-row-total">
          <IonCol size="4">Total Income</IonCol>
          <IonCol className="ion-text-right" size="6">{getIncomeMovementTotal}</IonCol>
          <IonCol className="ion-text-right" size="2"></IonCol>
        </IonRow>
        <IonRow className="summary-account-level-1">
          <IonCol>Expenses</IonCol>
        </IonRow>
        <IonRow className="summary-row-header">
          <IonCol size="4">
          {hideExpenses?
          <IonIcon onClick={()=>{showExpenses(false)}} icon={chevronForward} className="size-20"></IonIcon>
          :
          <IonIcon onClick={()=>{showExpenses(true)}} icon={chevronDown} className="size-20"></IonIcon>
          }&nbsp;
          Expenses
          </IonCol>
          <IonCol className="ion-text-right" size="6">Movement ({getPrevEndDate}) to ({getEndDate})</IonCol>
          <IonCol className="ion-text-right" size="2">Movement %</IonCol>
        </IonRow>
        {!hideExpenses &&
        <div>
          {getExpensesStart}
        </div>
        }
        <IonRow className="summary-row-total ">
          <IonCol size="4">Total Expenses</IonCol>
          <IonCol className="ion-text-right" size="6">{getExpensesMovementTotal}</IonCol>
          <IonCol className="ion-text-right" size="2">&nbsp;</IonCol>
        </IonRow>
        <IonRow className="summary-row-total ">
          <IonCol size="4">Gains/Losses</IonCol>
          <IonCol className="ion-text-right" size="6">{getGains}</IonCol>
          <IonCol className="ion-text-right" size="2">&nbsp;</IonCol>
        </IonRow>
      </div>
</div>
    </div>
    )
}
export default IncomeExpenses