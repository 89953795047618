import { IonCardHeader, IonFooter,IonInput, IonListHeader, IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonPage, IonProgressBar, IonRadio, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonRadioGroup, IonToggle, IonImg, IonCard, IonItemDivider, IonSpinner } from '@ionic/react';
import { cloudUploadOutline, analyticsSharp, arrowForwardOutline, calendarClearOutline, calendarOutline, checkmarkCircleOutline, checkmarkCircleSharp, checkmarkOutline, closeOutline, cloudUpload, cogOutline, createSharp, documentSharp, filterOutline, homeSharp, logOutOutline, refreshCircleOutline, settingsOutline, shareSocial, stopCircleSharp, textOutline, trash, chevronUp, chevronDown, documentOutline, checkmark } from 'ionicons/icons';
import React, { Component, useRef, useState } from 'react';
//import SlidingPane from "react-sliding-pane";
//import "react-sliding-pane/dist/react-sliding-pane.css";
//import ReactTable from "react-table-6";
import './fileloader.css';
import '../../theme/fonts/poppins-cufonfonts-webfont/Poppins-Regular.woff'
import { faCheckCircle, faCopy, faDatabase, faDesktop, faExclamationCircle, faFileExcel, faServer, faSortNumericUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import { selectVersion } from '../../dbconnections/Select';
//import Chart from '../../components/chart' 
//import {getUserId} from './Home';
//import { isNumber } from 'highcharts';

let host:string = "";
let append:any = false; 
let isPaneOpen :boolean =false; 
let errors:boolean = false;
let isPaneOpenLeft:boolean=false;
let glVersionId:any;
let userId:any;
let file:any;
const filePath ='F:/home/site/wwwroot/files/in'
let move_file:any="position-l-h"
let move_data:any="position-l-h"
let main_animation:any="position-main-h";
let blobId:any = 0;
let packageId:any = 2;
let versionId:any = 0;
let contactId:any = 4;
let migrationId:any = 0;
let applicationId:any = 67;
let projectName :any;
let fileRestoreId :any=1;
let fileArray:any="";
let glFileName :any;
let jnlFilter:any;
let set_file_load_option=0;
let createdBy:any = 4;

let trCodeList:any=[];
let worksheet:any="";
let delimiter:any=",";
let textqual:any="\"";
let extension:any="";
<input id="hdnDataTypeId" type="hidden"  />


const FileObjectLoader = (props:any) => {
    console.log(props)
    host        = props.state.primary_host;
    contactId   = props.state.user_id;
    //set_file_load_option = props.setOption
    const [getVersionId, setVersionId]                      = useState<any>()

    
    createdBy   = props.state.props.user_id;
    userId      = props.state.props.user_id;
    contactId   = props.state.props.user_id;
 
   
    const [getShowLoading, setShowLoading]              = useState<boolean>(false)
    const [getFileUpload, setFileUpload]            =useState<boolean>(false);
    
    const txtFile:any = useRef<HTMLIonInputElement>();
    const txtFileDummy:any = useRef<HTMLIonInputElement>();

    const callVersion = async () =>{
        selectVersion(props.state.props)
        .then(response=>{return response})
        .then(data=>{
            setVersionId(data.version_id)
        })
    }
  
  
    const uploadComplete = (evt:any) =>{
        //alert("complete")
        setShowLoading(false)
        
    }
    const uploadFailed = (evt:any) =>{
        //progress1.current.value = 0;
        setShowLoading(false)
        alert("Upload Failed")
    }
    const uploadCanceled = (evt:any) =>{
        //progress1.current.value = 0;
        setShowLoading(false)
        alert("Upload Cancelled")
    }
    const uploadProgress = (evt:any) =>{
        if (evt.lengthComputable) {
            //progress1.current.value = Math.round(evt.loaded / evt.total);
        }else {  
            console.log(evt);
        }
    }
    const UploadFile = () =>{
        let e = txtFileDummy.current.value;
        let q = e.split('.')
        let ext =  q[1]
        extension = ext;
        setShowLoading(true)
        const f = txtFile.current;
        console.log("******File*******:"+txtFileDummy.current.value)
        
        console.log("filerestoreid:"+fileRestoreId)
    
        glFileName = txtFileDummy.current.value;
        if(fileRestoreId == 2){
            console.log("setting gl file...")
            glFileName = txtFileDummy.current.value;
            glVersionId = versionId;
        }
        file = f;
        console.log("file:"+f.files)
        console.log("file-length:"+f.files.length)
        for(var i = 0 ; i < f.files.length; i++){
            let fd: FormData = new FormData();
            var xhr = new XMLHttpRequest();
            console.log("file"+ f.files[i]);
            console.log("versionid"+getVersionId);
            console.log("migrationid"+props.migration_id);
            console.log("contactid"+props.state.props.user_id);
            console.log("packageid"+packageId);
            console.log("filerestoreid"+props.restore_id);
            console.log("application"+applicationId);
            fd.append("file", f.files[i]);
            fd.append("versionid",getVersionId);
            fd.append("migrationid",props.migration_id);
            fd.append("contactid",props.state.props.user_id);
            fd.append("packageid",packageId);
            fd.append("filerestoreid",props.restore_id);
            fd.append("application",applicationId);
            xhr.addEventListener("progress", uploadProgress, false);
            xhr.addEventListener("load", uploadComplete, false);
            xhr.addEventListener("error", uploadFailed, false);
            xhr.addEventListener("abort", uploadCanceled, false);
            xhr.open("POST", props.state.props.primary_host+"UploadFile");
            console.log("sending...")
            xhr.send(fd);
            console.log("sent")
        }
    }
    
    React.useEffect(() => {
        callVersion()
    }, []);
    return (
        <div className="upload-form-left-division load-settings size-14 ion-padding">
            <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'} ></IonLoading>
            <IonRow className="">            
                <IonCol size="12" className='ion-text-center ion-padding'>
                    <IonRow>
                        <IonCol className="ion-text-left">
                            <IonLabel className="size-16">{props.form_name}</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <span>
                                <div className="fileUpload-ar" >
                                    <span>File</span>
                                    <input type="file" color="primary" className="upload-ar size-12"  name="file" ref={txtFile} multiple 
                                        onChange={e =>{
                                            var g = txtFile.current.value.split("\\")
                                            txtFileDummy.current.value = g[g.length-1]
                                            setFileUpload(true)
                                        }
                                    } 
                                    />
                                </div>
                                <input className="upload-selected-ar" placeholder="Selected Files" disabled={true} ref={txtFileDummy} />
                            </span>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
            {getFileUpload &&   
            <div>
                <IonRow className="">                     
                    <IonCol size="12" className="">
                        <IonButton className="button-width-max" onClick={e =>{UploadFile()}}><IonIcon icon={cloudUpload}></IonIcon>&nbsp;Upload</IonButton>
                    </IonCol>
                </IonRow>
            </div>
            }
        </div>
    );
};


export default FileObjectLoader; 
