import { IonRow, IonCol, IonIcon, IonLabel } from '@ionic/react';
import {apps,closeOutline} from 'ionicons/icons';

const Custom =(props:any)=>{

    return(
    <div>
        <IonRow>
            <IonCol className="ion-text-right">
                <IonIcon className="size-24" onClick={()=>{props.setCustom("custom-area-hide")}} icon={closeOutline}></IonIcon>
            </IonCol>
        </IonRow>
        <IonRow className="side-bar-v-spacing-ext-ar">
            <IonCol className="label-side-bar-ar view-end icon-gt-view-end-button" id="view_2" onClick={(ev) =>{props.setView(2);/*props.setSelectedView(ev.currentTarget.id);props.analytical_report_id = 2;*/props.setCustom("custom-area-hide")}}>
                <IonIcon className="iconeb-ext-ar size-20 "  icon={apps}></IonIcon>&nbsp;
                <IonLabel className="text-black summary-account">Charts <br/> </IonLabel>
            </IonCol>
        </IonRow>
    </div>
    )
}
export default Custom;