import { IonModal, IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonLoading, IonItem, IonTextarea, IonButton } from '@ionic/react';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, trendingUpSharp, trashBinOutline, closeOutline, peopleOutline} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
let ar_name:any                 = ""

const DeleteProject = (props:any) =>{
    const [hideDeleteProjectModal, showDeleteProjectModal]      =   useState<boolean>(false)
    const [hideShareModal, showShareModal]                      =   useState<boolean>(false)
    let txtEmailShare:any                                       =   useRef<HTMLIonInputElement>()
    const setShare = () =>{
        //setShowLoading(true)
        fetch(props.state.primary_host+"/ar/dbconnect/insert.jsp?etl=insertanalyticalreviewscontacts"+
            "&analytical_reviews_id="+props.state.analytical_reviews_id+
            "&email="+txtEmailShare.current!.value+
            "&created_by="+props.state.user_id 
            ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            //showShareModal(false)
            //callProjectContacts()
        })
        .catch(error=>{ 
            //setShowLoading(false)
        })
    }
    const deleteProject = (ar_id:any, name:any) =>{
        //alert(ar_id)
        //alert(name)
        ar_name = name;
        showDeleteProjectModal(true)
        props.state.analytical_reviews_id = ar_id
        //setAnalyticalReviewsId(ar_id)
    }
    const deleteAnalyticalReviews = () =>{
        //alert(state.analytical_reviews_id)
        fetch(props.state.primary_host+"/ar/dbconnect/delete.jsp?etl=deletereviewproject"+
        "&analyticalreviewsid="+props.state.analytical_reviews_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data=>{
            props.state.analytical_reviews_id = 0;
            //showDeleteProjectModal(false)
        })
        .catch(err=>{
            //callAnalyticalReviews(props)
        })
    }
    const [hideModal, showModal]                    = useState<any>()
    const [getState, setState]                      = useState<any>()
    useEffect(() => {
    }, []);
    return(
        <div>
            
                <IonRow>
                    <IonCol size="12" className="size-18 text-black ion-text-center">
                        <b>Confirm Delete Project</b>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="ion-text-center ion-padding size-18">
                        <IonIcon icon={trashBinOutline} className="text-red size-32"></IonIcon><br/>
                        {ar_name}
                    </IonCol>
                </IonRow>
                <IonFooter>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton color="danger" onClick={()=>{deleteAnalyticalReviews()}}>
                                Delete
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonFooter>
        </div>
    )
}
    
export default DeleteProject