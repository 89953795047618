import { IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonItem, IonLoading, IonModal, IonPage, IonToolbar, IonButtons, IonButton, IonTitle, useIonModal } from '@ionic/react';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, calculator, cloudUpload, peopleOutline} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import { callBalanceSheetInitial, callBalanceByAccount, callMovementByAccount, callAnalyticalReviews, callSummaryDetail, callDetailTransactions, callXeroAvailableYears, callFactDebtRatio, callFactDebtToEquityRatio, callFactCurrentRatio, callFactWorkingCapitalToAssetsRatio, callFactIncomeExpenses, callBalanceSheetMonthlyPivot, callFactAccountBalance, callCustomCharts, callBalanceSheet, callJournalProjects } from '../../dbconnections/Select';

import AR_JETs_Results from './AR_JETs_Results';
import JetsList from './JetsList';

import './AR_JETs_Results.css'
import ExportTests from '../DataExport/ExportTests';
import Import from '../DataImport/Import';
import Basic from '../Tables/Basic';
import ShareJETsProject from '../Modals/ShareJETsProject';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
let migrationId:any = 0
let project_name:any = ""
var state:any = {
    month:'',
    year:'',
    start:'',
    end:'',
    year_end:'',
    primary_host:'',
    user_id:'',
    migration_id:'',
    ar_name:''
}
//var categoryId:any = 0
const JetsMain = (props:any) =>{
    state.primary_host = props.global_props.primary_host
    state.user_id = props.global_props.user_id
    var data:any = [];
    
    /* Array Data States */
    const [getJetsData, setJetsData]                            = useState<any>()
    const [getCategoryId, setCategoryId]                        = useState<any>()
    const [getMigrationId, setMigrationId]                      = useState<any>()
    /*Objects & Variables & States*/
    const [getMaxDataMonth,setMaxDataMonth]                     = useState<any>()
    const [getMaxDataYear,setMaxDataYear]                       = useState<any>()
    const [getYearEnd, setYearEnd]                              = useState<any>()
    const [getStartDate, setStartDate]                          = useState<any>()
    const [getSelectedDate, setSelectedDate]                    = useState<any>()
    const txtStartDate:any                                      = useRef()
    const txtEndDate:any                                        = useRef()
    const [getLocation, setLocation]                            = useState<any>()
    const [getState, setState]                                  = useState<any>()
    const [getAnalyticalReviewsId, setAnalyticalReviewsId]      = useState<any>()
    const [getChart, setChart]                                  = useState<boolean>(true)
    const [getAccountForProfile, setAccountForProfile]          = useState<any>()
    const [getPrevEndDate, setPrevEndDate]                      = useState<any>()
    const [getEndDate, setEndDate]                              = useState<any>()
    const [getUserId, setUserId]                                = useState<any>()
    const [getCumulative,setCumulative]                         = useState<any>()
    const [getAccountLevel3, setAccountLevel3]                  = useState<any>(1)
    const [getAccountLevel2, setAccountLevel2]                  = useState<any>(0)
    const [getAccountLevel1, setAccountLevel1]                  = useState<any>(0)
    const [getAccountLevel0, setAccountLevel0]                  = useState<any>(0)
    const [getStartDateAbsolute, setStartDateAbsolute]          = useState<any>()
    const [getEndDateAbsolute, setEndDateAbsolute]              = useState<any>()
    const [balanceSheet, isBalanceSheet]                        = useState<any>(false)
    const [income, isIncome]                                    = useState<any>(false)

    const [hideTestList2, showTestList2]                        = useState<any>(false)
    
    /*Home*/    
    const [getHomeView, setHomeView]                            = useState<boolean>(false)
    const [getExport, setExport]                                = useState<boolean>(false)
    const [getResults, setResults]                              = useState<any>()
    const [getImportView, setImportView]                        = useState<any>(false)
    const [getContent, setContent]                              = useState<any>(true)
    /*Balance Sheet */
    const [getSummaryView, setSummaryView]                      = useState<boolean>(false)
    const [getSummaryDetailView, setSummaryDetailView]          = useState<boolean>(false)
    const [getSummaryJournalDetailView, setSummaryJournalDetailView]                = useState<boolean>(false)
    const [getBalanceSheetMonthlyPivotView,setBalanceSheetMonthlyPivotView]         = useState<boolean>(false)
    
    const [getBalanceSheetProView, setBalanceSheetProView]      = useState<boolean>(false)
    /*Cash Flow */
    const [getCashFlow, setCashFlow]                            = useState<boolean>(false)

    /*Income & Expenses */
    const [getIncomeExpensesView,setIncomeExpensesView]         = useState<boolean>(false)

    /*Solvency */
    const [getSolvencyView, setSolvencyView]                    = useState<boolean>(false)

    /*Liquidity */
    const [getLiquidityView, setLiquidityView]                  = useState<boolean>(false)

    /*Predictive */


    /*Net Income */
    const [getNetIncomeView, setNetIncomeView]                  = useState<boolean>(false)

    /*Mapping COA */
    const [getCOA, setCOA]                                      = useState<boolean>(false)

    /*Opening Balances */
    const [getOpeningBalances, setOpeningBalances]              = useState<boolean>(false)

    /*Audit */
    const [getAudit, setAudit]                                  = useState<boolean>(false)
    const [getAuditResults, setAuditResults]                    = useState<any>()

    

    const setARVariables = (id:any) =>{
        setAnalyticalReviewsId(id)
        var z:any=[]
        z.push({analytical_reviews_id:id,primary_host:props.global_props.primary_host })
        callXeroAvailableYears(z[0])
        .then(response=>{return response})
        .then(data=>{
            //props.setAnalyticalReviewsId(x.id)
            //console.log(data.month);
            console.log(data)
            //props.years(data.obj)
            state.month  = data.month
            state.year   = data.year
            state.start  = data.start
            state.end    = data.end
            state.year_end = data.year_end
            setStartDateAbsolute(data.start)
            setEndDateAbsolute(data.end)
            setMaxDataMonth(data.month);
            setMaxDataYear(data.year);
            setStartDate(data.start)
            setEndDate(data.end)
            setYearEnd(data.year_end)
        })
        .then(()=>{
            setView(1)
        })

    }
    const [getShowLoading, setShowLoading]              = useState<boolean>(false)
    const [getActiveUsers, setActiveUsers]              = useState<any>()
    var location:any = "";
    const setView = (v:any) =>{
        resetView()
        switch(v){
            case 0: setShowLoading(true);
                    callJournalProjects(props.global_props)
                    .then(response=>{ console.log(response); return response})
                    .then(data=>{
                        setJetsData(data)
                        setContent(true)
                        setShowLoading(false)
                    })
                    .then(()=>{
                        setHomeView(true);
                        setContent(true)
                        setShowLoading(false)
                    })
                    .catch(error=>{
                        setShowLoading(false)
                        alert(error)
                    })
                    break;
            case 1: setAuditResults(true);setContent(true);break;
            case 2: setExport(true);setContent(true); break;
            case 3: setImportView(true); setContent(false)
        }
    }
    const resetView = () =>{
        setHomeView(false);
        setAuditResults(false)
        setExport(false)
        setImportView(false)
    }
    const setLocationView = () =>{
        switch(props.location){
            case 0 : setView(0);break;
            case 1 : setView(1);break;
            case 2 : setView(4);break;
            case 3 : setView(10);break;
            case 4 : setView(6);break;
            case 5 : setView(5);break;
            case 6 : setView(7);break;
            case 7 : setView(12);break;
            default: setView(0);break;
        }
        
    }
    const [hideShareModal, showShareModal]                              = useState<boolean>(false)
    const txtEmailShare:any                                             = useRef<HTMLIonInputElement>()
    const shareJournalProject = (id:any) =>{
       
        fetch(props.primary_host+"/journals/dbconnect/select.jsp?etl=selectjournalprojectcontacts"+
        "&migration_id="+id+
        "&contactid"+props.user_id,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            //let rt:any = null
            //setTable(rt)
            //renderShareTable(data.data,"")
            let x:any = <Basic data= {data.data} />
            setActiveUsers(x)
        })
        .catch(error =>{
            alert(error +": Get Journal Contacts")
        })
    }
    const [getModalTitle, setModalTitle]                  = useState<any>()
    const [getModalContent1, setModalContent1]            = useState<any>(false)
    const [getModalContent2, setModalContent2]            = useState<any>(false)
    const [getModalContent3, setModalContent3]            = useState<any>(false)

    const openModal = (modal:any) =>{
        resetModal()
        switch (modal) {
            case 1: 
                    openModalFunction();
                    setModalTitle("Share Project - "+ state.ar_name);
                    setModalContent1(true);
                    break;
            case 3: openModalFunction();
                    setModalTitle("Delete");
                    setModalContent3(true);
                    break;
        }
    }
    const resetModal = () =>{
        setModalContent1(false)
        setModalContent2(false)
        setModalContent3(false)
    }
    const modal = useRef<HTMLIonModalElement>(null);
    const input = useRef<HTMLIonInputElement>(null);

    const ModalExample = ({
        onDismiss,
      }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
      }) => {
        const inputRef = useRef<HTMLIonInputElement>(null);
        return (
          <IonPage className='modal-area'>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{getModalTitle}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => onDismiss(inputRef.current?.value, 'confirm')}>Confirm</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {getModalContent1 &&
                    <ShareJETsProject state={state} />
                }
            </IonContent>
          </IonPage>
        );
    };
    const [message, setMessage] = useState(
        'This modal example uses triggers to automatically open a modal when the button is clicked.'
    );

    function confirm() {
        modal.current?.dismiss(input.current?.value, 'confirm');
    }

    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
        if (ev.detail.role === 'confirm') {
        setMessage(`Hello, ${ev.detail.data}!`);
        }
    }
    const [present, dismiss] = useIonModal(ModalExample, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
    });
    function openModalFunction() {

        present({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {
                setMessage(`Hello, ${ev.detail.data}!`);
                }
            },
        });
    }
    useEffect(() => {
        setState(()=>{
            //alert()
            setHomeView(true)
            if(props.location != getLocation || props.analytical_reviews_id != getAnalyticalReviewsId){ 
                setState([props.location, props.current_assets])
                //setAnalyticalReviewsId(props.analytical_reviews_id)
                setUserId(props.global_props.user_id)
                setLocation(props.location)
                setLocationView()
            }}
        ) 
    }, [props.location,props.analytical_reviews_id]);
    return(
        <div>
            <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'} ></IonLoading>
            { (hideShareModal) &&
                <ShareJETsProject state={state} modal={hideShareModal}/>
            }
            <IonHeader>
                {getHomeView &&
                    <IonRow className="ar-header-panel">
                        <IonCol size='2' className="size-16 ion-text-left">
                        <IonIcon className="size-24 text-gt" icon={businessOutline}></IonIcon>
                        &nbsp;Project Name
                        </IonCol>
                        <IonCol size='2' className="size-16 ion-text-left">
                        <IonIcon className="size-24 text-gt" icon={calendarOutline}></IonIcon>
                        &nbsp;From
                        </IonCol>
                        <IonCol size='2' className="size-16 ion-text-left">
                        <IonIcon className="size-24 text-gt" icon={calendarOutline}></IonIcon>
                        &nbsp;To
                        </IonCol>
                        <IonCol>
                        <IonIcon className="size-24 text-gt ion-text-right" icon={calculator}></IonIcon>
                        &nbsp;CTT
                        </IonCol>
                        <IonCol size='2' className="size-16 ion-text-right">
                        <IonIcon className="size-24 text-gt" icon={calculator}></IonIcon>
                        &nbsp;Rounding
                        </IonCol>
                        <IonCol size='1' className="size-16 ion-text-right">
                            <IonIcon className="size-24 text-green" icon={peopleOutline}></IonIcon>
                        </IonCol>
                        <IonCol size='1' className="size-16 ion-text-right">
                            <IonIcon className="size-24 text-red" icon={trashOutline}></IonIcon>
                        </IonCol>
                    </IonRow>
                }
                {(getAuditResults || getImportView || getExport) &&
                    <IonRow>
                        <IonCol className="ion-text-left "></IonCol>
                        {/*<IonCol className=" ion-text-left"  onClick={()=>{props.set_application(66)}}>
                            <IonLabel  className="size-16">New Project</IonLabel>&nbsp;
                            <IonIcon icon={cloudUpload} className="size-24" ></IonIcon>
                        </IonCol>*/}
                        {!getExport &&
                        <IonCol size="2" className="ion-text-right" title='Export' onClick={()=>{setView(2)}}>
                            <IonLabel  className="size-16">Export Test Results</IonLabel>&nbsp;
                            <IonIcon icon={print} className="size-24" ></IonIcon>
                        </IonCol>
                        }
                        <IonCol size="2" className="ion-text-right "  onClick={()=>{setView(0)}}>
                            <IonLabel  className="size-16">Back To Main</IonLabel>&nbsp;
                            <IonIcon icon={returnUpBack} className="size-24" ></IonIcon>
                        </IonCol>
                </IonRow>
                }
            </IonHeader>
            {getImportView &&
                <Import 
                    props={props.global_props}
                    application_id={props.application_id}
                />
            }
            {getContent &&
            <div className="main-content">
                {getHomeView &&
                    <JetsList
                        state={props} 
                        data={getJetsData} 
                        name={(e:any)=>{props.set_project(e); project_name = e; }}
                        setMigrationId={(e:any)=>{
                            setMigrationId(e)
                            migrationId = e
                            props.global_props.migration_id = e;
                            //props.setSideBarList(3)
                            setView(1)
                        }}
                        callProjectContacts={(e:any)=>{console.log(e);state.migration_id = e.id; state.ar_name = e.name ;openModal(1)  }}
                    />    
                }
                <IonRow>
                    <IonCol className="ion-padding">
                            {getAuditResults &&
                                <AR_JETs_Results
                                    migrationId={migrationId} 
                                    userId={props.global_props.user_id} 
                                    primaryhost={props.global_props.primary_host} 
                                    host={props.global_props.primary_host}
                                    categoryId= {getCategoryId}
                                />
                            }
                            {getExport &&
                                <ExportTests 
                                    migration_id={migrationId}
                                    project_name={project_name}
                                    primary_host={props.global_props.primary_host}
                                />
                            }
                            
                    </IonCol>
                </IonRow>
            </div>
            }
        </div>
    )
}

export default JetsMain;