import { IonSpinner, IonListHeader, IonButton,IonCheckbox, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonTextarea, IonBadge, IonButtons, useIonModal, IonItemOptions } from '@ionic/react';
import { alertCircleSharp, analytics, analyticsOutline, analyticsSharp, arrowBackSharp, arrowForwardSharp, bluetoothSharp, calendarOutline, checkmarkCircle, checkmarkCircleSharp, checkmarkDone, checkmarkDoneCircle, checkmarkOutline, closeOutline, cogOutline, copySharp, documentAttachOutline, exitOutline, filterSharp, gridOutline, hammerOutline, homeOutline, listOutline, listSharp, logInOutline, logOut, logOutOutline, mailOutline, mailSharp, pencilOutline, peopleCircleSharp, peopleOutline, personOutline, personSharp, push, returnUpBack, sendOutline, settingsOutline, settingsSharp, stopCircleSharp, trashBin, trashBinSharp, trashOutline, warningOutline, warningSharp, wifiOutline } from 'ionicons/icons';
import React,{ useRef, useState } from 'react';
//import ReactTable from "react-table";
//import 'react-table-6/react-table.css';
//import moment from 'moment';
import Highcharts, { keys } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import '../../theme/fonts/poppins-cufonfonts-webfont/Poppins-Regular.woff'
import $ from 'jquery';
//import 'floatthead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faDotCircle, faExclamationCircle, faFileCsv, faFileDownload, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Basic from '../Tables/Basic';
import "./Contracktor.css";
import Contractors from './Contractors';
import Statement from '../Tables/Statement';
import FileLoader from '../FileLoader/fileloader';
import NewProjectForm from './NewProject';
import { addCommas, isDate } from '../../functions/functions';
import Detail from '../Tables/TransactionDetail';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import FileObjectLoader from '../FileLoader/fileobjectloader';
import InvoiceTemplate from '../Tables/InvoiceTemplate';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

var vat_amount:any = 0;
var invoice_amount:any = 0;
var paid_amount:any = 0;
var outstanding_balance:any = 0;

const Contracktor =(props:any)=>{
    const [getContractorOptions, setContractorOptions]              = useState<any>(false)
    const [getContractor, setContractor]                            = useState<any>(false)
    const [getContractorList, setContractorList]                    = useState<any>()
    const [getStatement, setStatement]                              = useState<any>(false)
    const [getStatementList, setStatementList]                      = useState<any>()
    const [getFileLoader, setFileLoader]                            = useState<any>(false)
    const [getInvoiceTemplate, setInvoiceTemplate]                  = useState<any>(false)
    const [getLabel, setLabel]                                      = useState<any>("Contractor List")
    const [getProjectName, setProjectName]                          = useState<any>()
    const [getMigrationId, setMigrationId]                          = useState<any>()
    const [getNewProjectForm, setNewProjectForm]                    = useState<any>(false)
    const [getCountryId, setCountryId]                              = useState<any>()
    const [getVersionId, setVersionId]                              = useState<any>()
    const [getInvoiceDetail, setInvoiceDetail]                      = useState<any>()
    const [getShowLoading, setShowLoading]                          = useState<boolean>(false)  
    const [getData, setData]                                        = useState<any>()
    const [getData1, setData1]                                      = useState<any>()
    const [getBalance, setBalance]                                  = useState<any>()
    const [getNetAmount, setNetAmount]                              = useState<any>()
    const [getAmount, setAmount]                                    = useState<any>()
    const [getWIPAmount, setWIPAmount]                              = useState<any>()
    const [getDueVatAmount, setDueVatAmount]                        = useState<any>()
    const [getInvoiceVatAmount, setInvoiceVatAmount]                = useState<any>()
    const [getCommissionAmount, setCommissionAmount]                = useState<any>()
    const [getGrossAmount, setGrossAmount]                          = useState<any>()
    const [getDisbursementAmount, setDisbursementAmount]            = useState<any>()
    const [getPayInvoice, setPayInvoice]                            = useState<any>(false)
    const [getPayVAT, setPayVAT]                                    = useState<any>()
    const [getPaidAmount, setPaidAmount]                            = useState<any>()
    let txtPayAmount:any                                            = useRef<any>()
    let txtPayReference:any                                         = useRef<any>()
    let txtPaymentDate:any                                          = useRef<any>()
    const [getOutstandingBalance, setOutStandingBalance]            = useState<any>()
    const [getInvoiceNumber, setInvoiceNumber]                      = useState<any>()
    const [getPayButton, setPayButton]                              = useState<any>(false)
    const [getVATPaidAmount, setVATPaidAmount]                      = useState<any>()
    const [getModalTitle, setModalTitle]                            = useState<any>()
    const [getTransactionTypeId, setTransactionTypeId]              = useState<any>()
    const [getParentId, setParentId]                                = useState<any>()
    const [getType, setType]                                        = useState<any>()
    const [getId, setId]                                            = useState<any>()
    const [getGenerateInvoice, setGenerateInvoice]                  = useState<any>(false)
    const [getInvoiceDate, setInvoiceDate]                          = useState<any>()
    const [getVendorNumber, setVendorNumber]                        = useState<any>()
    const [getCompanyAddress, setCompanyAddress]                    = useState<any>()
    const [getJobDescription, setJobDescription]                    = useState<any>()
    const [getTotalUnits, setTotalUnits]                            = useState<any>()
    const [getAverageRate, setAverageRate]                          = useState<any>()
    const [getVATRate, setVATRate]                                  = useState<any>(15)
    const [getClientVATNumber, setClientVATNumber ]                 = useState<any>()
    const [getPaymentTemplate, setPaymentTemplate]                  = useState<any>(false)
    const [getContractorPaymentTemplate, setContractorPaymentTemplate] = useState<any>(false)
    const [getCommissionPaymentTemplate, setCommissionPaymentTemplate] = useState<any>(false)
    const [getContractorAmount, setContractorAmount]                = useState<any>()
    const [getReferenceNumber, setReferenceNumber]                  = useState<any>()
    const [getLoadedTimeSheet, setLoadedTimesheet]                  = useState<any>()
    const [getEntityOptions, setEntityOptions]                      = useState<any>()
    const [getClientEntityId, setClientEntityId]                    = useState<any>()
    const [getContractorEntityId, setContractorEntityId]            = useState<any>()
    const txtRate:any                                               = useRef<any>()
    const txtUnits:any                                              = useRef<any>()

    
    const ModalExample = ({
        onDismiss,
      }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
      }) => {
        const inputRef = useRef<HTMLIonInputElement>(null);
        return (
          <IonPage className="modal-area">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{getModalTitle}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => onDismiss(inputRef.current?.value, 'confirm')}>Confirm</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonRow>
                    <IonCol><b>Update Rates & Units</b></IonCol>
                </IonRow>
                {(getType == 1) &&
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position='floating'>Rate</IonLabel>
                            <IonInput className="bg-grey" type="text" ref={txtRate} placeholder="Update Rate" />
                        </IonItem>
                    </IonCol>
                </IonRow>
                }
                {(getType == 2) &&
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position='floating'>Units</IonLabel>
                            <IonInput className="bg-grey" type="text" ref={txtUnits} placeholder="Update Units" />
                        </IonItem>
                    </IonCol>
                </IonRow>
                }
                <IonRow>
                    <IonCol></IonCol>
                    <IonCol></IonCol>
                    <IonCol>
                        <div className="main-button ion-text-center" onClick={()=>{setUnitRate()}}>Update</div>
                    </IonCol>
                </IonRow>
            </IonContent>
          </IonPage>
        );
    };
    const [message, setMessage] = useState(
        'This modal example uses triggers to automatically open a modal when the button is clicked.'
    );
    const [present, dismiss] = useIonModal(ModalExample, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
    });
    function openModalFunction() {

        present({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {
                setMessage(`Hello, ${ev.detail.data}!`);
                }
            },
        });
    }
    const openModal = (modal:any) =>{
        resetModal()
        switch (modal) {
            case 1: 
                    openModalFunction();
                    setModalTitle("Update Rate");
                    break;
            case 2: openModalFunction();
                    setModalTitle("Update Units");
                    break;
        }
    }
    const resetModal = () =>{
        
    }
    const callUserEntity = () =>{
        var options:any = []
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=select_user_entity'+
        '&contact_id='+props.props.user_id,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
           options.push(data.data)
           var list:any = options[0].map((x:any, i:number)=>{
            return (
                <IonSelectOption value={x.id}>{x.entity}</IonSelectOption>
            )
           })
           setEntityOptions(list)
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }
    const lockInvoice = (ii:any) =>{
        
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=insert_invoice_detail_lock'+
        '&contact_id='+props.props.user_id+
        '&invoice_id='+getInvoiceNumber,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
           setView(8)
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }

    const setUnitRate = () =>{
        var rate = 0;
        var units = 0;
        if(txtRate.current != null)
            rate = txtRate.current.value
        if(txtUnits.current != null)
            units = txtUnits.current.value

        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=update_invoice_detail'+
        '&contact_id='+props.props.user_id+
        '&id='+getId+
        '&parent_id='+getParentId+
        '&transaction_type_id='+getTransactionTypeId+
        '&rate='+rate+
        '&units='+units,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            callDetail(getInvoiceNumber, 0)
            setView(4)
            
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }
    const setPayment = (transactionTypeId:any) =>{
        setLabel("Payment")
        if (transactionTypeId == 3933)
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=insert_payment'+
        '&contact_id='+props.props.user_id+
        '&transaction_type_id='+transactionTypeId+
        '&invoice_id='+getInvoiceNumber+
        '&payment_date='+txtPaymentDate.current.value+
        '&amount='+(getAmount).replace(",","")+
        '&reference='+txtPayReference.current.value,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            callDetail(getInvoiceNumber, 0)
            setView(4)
            
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })

        if (transactionTypeId == 3934)
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=insert_payment'+
        '&contact_id='+props.props.user_id+
        '&transaction_type_id='+transactionTypeId+
        '&invoice_id='+getInvoiceNumber+
        '&payment_date='+txtPaymentDate.current.value+
        '&amount='+(getDueVatAmount).replace(",","")+
        '&reference='+txtPayReference.current.value,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            callDetail(getInvoiceNumber, 0)
            setView(4)
            
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })

        /*Commission Payout */
        if (transactionTypeId == 3943)
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=insert_payment'+
        '&contact_id='+props.props.user_id+
        '&transaction_type_id='+transactionTypeId+
        '&invoice_id='+getInvoiceNumber+
        '&payment_date='+txtPaymentDate.current.value+
        '&amount='+(getCommissionAmount).replace(",","")+
        '&reference='+txtPayReference.current.value,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            callDetail(getInvoiceNumber, 0)
            setView(4)
            
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })

        /*Contractor Payout*/
        if (transactionTypeId == 3942)
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=insert_payment'+
        '&contact_id='+props.props.user_id+
        '&transaction_type_id='+transactionTypeId+
        '&invoice_id='+getReferenceNumber+
        '&payment_date='+txtPaymentDate.current.value+
        '&amount='+(getContractorAmount).replace(",","")+
        '&reference='+txtPayReference.current.value,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            callDetail(getInvoiceNumber, 0)
            setView(4)
            
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }
    const controlPayAmount = () =>{
        var date:any = false;
        var ref:any  = false;
        if (txtPaymentDate.current != null)
            date = isDate(txtPaymentDate.current.value)
        if (txtPayReference.current != null)
            if(txtPayReference.current.value.length > 0)
                ref = true
        
        if(date && ref){
            setPayButton(true)
        }else{
            setPayButton(false)
        }

        //if(amount/1 != 0)
        //amount = (amount).replace(",","")
        //outstanding_balance = invoice_amount/1 - amount/1 - paid_amount/1
        //if(outstanding_balance >= 0){
        //    setOutStandingBalance(addCommas((outstanding_balance/1).toFixed(2)))
        //    if(amount/1 > 0){
        //        setPayButton(true)
        //    }else{
        //        setPayButton(false)
        //    }
        //}else{
        //    setPayButton(false)
        //    alert("Payment amount is greater than outstanding balance!")
        //}
        //txtPayAmount.current.value = addCommas((amount/1).toFixed(2))
    }
    const setStatus = (id:any, parent_id:any) =>{
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=update_invoice_detail_status'+
        '&id='+id+
        '&contact_id='+props.props.user_id,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            callDetail(getInvoiceNumber, 0)
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }
    const callDetail = (invoiceId:any, transactionTypeId:any) =>{
        setLabel("Activity & Transaction Detail")
        console.log(props.props)
        setShowLoading(true)
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=select_invoice_detail'+
        '&contact_id='+props.props.user_id+
        '&transaction_type_id='+transactionTypeId+
        '&invoice_id='+invoiceId,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            var d:any = []
            d.push(data.data)
            setData(d)
            setView(4);
            var amount: any = 0;
            var net_amount:any = 0;
            var gross:any = 0;
            var vat_due:any = 0;
            var vat:any = 0;
            var paid:any = 0;
            var vat_paid:any = 0;
            var wip:any = 0;
            var wip_disbursement:any = 0;
            var units:any = 0;
            var work_only:any = 0;
            var disbursement_amount:any = 0;
            var invoice_total:any = 0;
            var contractor_total:any = 0;
            var commission_amount:any = 0;
            setGenerateInvoice(false)
            var has_inv_paid = 0;
            for(var i = 0; i < data.data.length; i++){
                if(data.data[i]["TRANSACTION TYPE"] == "Invoice" || data.data[i]["TRANSACTION TYPE"] == "Disbursement Fee" || data.data[i]["TRANSACTION TYPE"] == "VAT"){
                    amount  += data.data[i]["AMOUNT"]/1;
                    gross   += data.data[i]["AMOUNT"]/1;
                }
                if(data.data[i]["TRANSACTION TYPE"] == "Invoice" || data.data[i]["TRANSACTION TYPE"] == "Disbursement Fee"){
                    net_amount  += data.data[i]["AMOUNT"]/1;
                }
                if(data.data[i]["TRANSACTION TYPE"] == "Commission"){
                    commission_amount += data.data[i]["AMOUNT"]/1;
                }
                if(data.data[i]["TRANSACTION TYPE"] == "Invoice Payment"){
                    amount -= data.data[i]["AMOUNT"]/1;
                    paid += data.data[i]["AMOUNT"]/1;
                    has_inv_paid = 1;
                }
                if(data.data[i]["TRANSACTION TYPE"] == "VAT Payment"){
                    vat_paid += data.data[i]["AMOUNT"]/1;
                }
                if(data.data[i]["TRANSACTION TYPE"] == "VAT Due"){
                    vat_due += data.data[i]["AMOUNT"]/1;
                }
                if(data.data[i]["TRANSACTION TYPE"] == "VAT"){
                    vat += data.data[i]["AMOUNT"]/1;
                }
                if(data.data[i]["TRANSACTION TYPE"] == "Disbursement Fee"){
                    disbursement_amount += data.data[i]["AMOUNT"]/1;
                }
                if(data.data[i]["TRANSACTION TYPE"] == "WIP"){
                    wip += data.data[i]["AMOUNT"]/1;
                }
                if(data.data[i]["TRANSACTION TYPE"] == "WIP Disbursement Fee"){
                    wip_disbursement += data.data[i]["AMOUNT"]/1;
                }
                if(data.data[i]["is_invoice"] == "1" && has_inv_paid == 0){
                    setGenerateInvoice(true)
                }else{
                    setGenerateInvoice(false)
                }
                if(data.data[i]["TRANSACTION TYPE"] == "Invoice"){
                    units += data.data[i]["units"]/1
                    work_only += data.data[i]["AMOUNT"]/1
                }
            }
            
            setWIPAmount(addCommas((wip+wip_disbursement).toFixed(2)))
            setInvoiceNumber(data.data[0]["INVOICE"])
            setAmount(addCommas(amount.toFixed(2)))
            setNetAmount(addCommas(net_amount.toFixed(2)))
            setGrossAmount(addCommas(gross.toFixed(2)))
            setDueVatAmount(addCommas((vat_due-vat_paid).toFixed(2)))
            setInvoiceVatAmount(addCommas(vat.toFixed(2)))
            setVATPaidAmount(addCommas(vat_paid.toFixed(2)))
            setPaidAmount(paid)
            setInvoiceDate(data.data[0]["approved_date"])
            setClientVATNumber(data.data[0]["vat_number"])
            setCompanyAddress(data.data[0]["address"])
            setVendorNumber(data.data[0]["vendor_number"])
            setReferenceNumber(data.data[0]["REFERENCE"])
            setCommissionAmount(commission_amount.toFixed(2))
            invoice_amount = amount;
            vat_amount = vat;
            
            var average_rate = net_amount/units;
            setTotalUnits(addCommas(units.toFixed(2)))
            setAverageRate(addCommas(average_rate.toFixed(4)))
            controlPayAmount()
            setDisbursementAmount(addCommas(disbursement_amount.toFixed(2)))
            setContractorAmount((net_amount-commission_amount).toFixed(2))

        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }
    const callStatement = () =>{
        setLabel("Activity & Transaction Statement")
        console.log(props.props)
        setShowLoading(true)
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=select_invoice'+
        '&contact_id='+props.props.user_id+
        '&client_id='+getClientEntityId+
        '&contractor_id='+getContractorEntityId+
        '&invoice_id='+0,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            var d:any = []
            d.push(data.data)
            setData(d)
            setView(3);
            var net_amount: any = 0;
            var vat_amount: any = 0;
            var commission_amount: any = 0;
            var gross_amount: any = 0;
            var disbursement_amount:any = 0;
            var wip:any = 0;
            for(var i = 0; i < data.data.length; i++){
                net_amount += data.data[i]["NET"]/1;
                vat_amount += data.data[i]["VAT"]/1;
                commission_amount += data.data[i]["COMMISSION"]/1;
                gross_amount += data.data[i]["GROSS"]/1;
                disbursement_amount += data.data[i]["DISBURSEMENT"]/1;
                wip += data.data[i]["WIP"]/1
            }
            setWIPAmount(addCommas(wip.toFixed(2)))
            setNetAmount(addCommas(net_amount.toFixed(2)))
            setDueVatAmount(addCommas(vat_amount.toFixed(2)))
            setCommissionAmount(addCommas(commission_amount.toFixed(2)))
            setGrossAmount(addCommas(gross_amount.toFixed(2)))
            setDisbursementAmount(addCommas(disbursement_amount.toFixed(2)))
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }
    const setView = (s:any) =>{ 
        resetView()
        switch(s){
            case 1: setNewProjectForm(true);setLabel("Load Timesheet");break;
            case 2: setFileLoader(true); break;
            case 3: setStatement(true); break;
            case 4: setInvoiceDetail(true);setLabel("Activity & Transaction Detail"); break;
            case 5: setPayInvoice(true); setInvoiceTemplate(true);setLabel("Print Invoice");break;
            case 6: setPayVAT(true);setLabel("Confirm VAT Payment");break;
            case 7: setPayInvoice(true); setPaymentTemplate(true);setLabel("Confirm Invoice Payment");break;
            case 8: setPayInvoice(true); setContractorPaymentTemplate(true);setLabel("Confirm Contractor Payment");break;
            case 9: setPayInvoice(true); setCommissionPaymentTemplate(true);setLabel("Confirm Commission Payment");break;
            case 10: setLoadedTimesheet(true);break;
        }
    }
    const resetView = () =>{
        setNewProjectForm(false);
        setFileLoader(false);
        setStatement(false);
        setInvoiceDetail(false);
        setPayInvoice(false);
        setPayVAT(false);
        setPaymentTemplate(false);
        setInvoiceTemplate(false);
        setContractorPaymentTemplate(false);
        setCommissionPaymentTemplate(false);
        setLoadedTimesheet(false);
    }
    const callTimesheet = (version_id:any) =>{
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=select_timesheet'+
        '&version_id='+version_id,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            
            var d:any = []
            d.push(data.data)
            console.log(d)
            setData1(d)
            setView(10) 
        })
    }

    const setNewInvoice = () =>{
        fetch(props.props.primary_host+'/dbconnect/dbdynamic.jsp?co=insert_invoice_detail'+
        '&created_by='+props.props.user_id+
        '&version_id='+getVersionId,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            setView(3);
            callStatement()

        })
    }

    React.useEffect(() => {
        callUserEntity()
        
    }, []);
    return(
        <div>
            <div className="contracktor-control-panel ion-padding ion-text-center size-10">
            <IonRow>
                {/*<IonRow onClick={()=>{callStatement()}} className="ion-text-center  size-14">
                    <IonCol>
                        <IonIcon className="size-42 text-gt icon-start hover" icon={listOutline}></IonIcon> <br/>
                        <div className="">Statement</div>
                    </IonCol>
                </IonRow>*/}
                {!getContractorOptions &&
                <div>
                    <IonRow>
                        <IonCol>
                            <div onClick={()=>{props.setHome(0)}} className="ion-text-center size-14">
                                <IonRow>
                                    <IonCol size="12" className="">
                                        <IonIcon className="size-42 text-gt icon-start hover " icon={homeOutline}></IonIcon><br/>
                                        <div className="">Home</div>
                                    </IonCol>
                                </IonRow>
                            </div> 
                        </IonCol> 
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div onClick={()=>{callStatement()}} className="ion-text-center size-14">
                                <IonRow>
                                    <IonCol size="12" className="">
                                        <IonIcon className="size-42 text-gt icon-start hover " icon={listOutline}></IonIcon><br/>
                                        <div className="">Statement</div>
                                    </IonCol>
                                </IonRow>
                            </div> 
                        </IonCol> 
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div onClick={()=>{setView(1)}} className="ion-text-center size-14">
                                <IonRow>
                                    <IonCol size="12" className="">
                                        <IonIcon className="size-42 text-gt icon-start hover " icon={documentAttachOutline}></IonIcon><br/>
                                        <div className="">Load Timesheet</div>
                                    </IonCol>
                                </IonRow>
                            </div> 
                        </IonCol> 
                    </IonRow>
                </div>
                }    
                
            </IonRow>
            </div>
            <div className="contracktor-chart-panel">
                <IonRow className="ion-padding">
                    <IonHeader>
                        <IonRow>
                            <IonCol className="ion-padding size-24" size="8">
                            {getLabel}
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonLabel position='floating'>Contractor</IonLabel>
                                    <IonSelect onIonChange={(e:any)=>{ console.log(e.detail.value); setContractorEntityId(e.detail.value);callStatement()}}>
                                        {getEntityOptions}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonLabel position='floating'>Client</IonLabel>
                                    <IonSelect onIonChange={(e:any)=>{ console.log(e.detail.value); setClientEntityId(e.detail.value);callStatement()}}>
                                        {getEntityOptions}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonHeader>
                </IonRow>
                
                    {getNewProjectForm &&
                    <IonRow>
                        <IonCol size="3"></IonCol>
                        <IonCol className="ion-padding" >
                            <div className="upload-form-left-division load-settings size-14 ion-padding">
                                <NewProjectForm
                                    state={props} 
                                    application_id={-1}
                                    package_id={-1}
                                    setSystem={(e:any)=>{}} 
                                    setUpLoads={(e:any)=>{
                                        //if(getPackageId == 2){
                                        //setView(3)
                                        //}
                                        //if(getPackageId == 1){
                                        //setView(4)
                                        //}
                                    }} 
                                    setProjectName={(e:any)=>{setProjectName(e)}}
                                    setBillingCode={(e:any)=>{}}
                                    setCTT={(e:any)=>{}}
                                    setRounding={(e:any)=>{}}
                                    setCountry={(e:any)=>{setCountryId(e)}}
                                    setMigrationId={(e:any)=>{setMigrationId(e[0].migration_id);setCountryId(e[0].country_id);setView(2) }}
                                />
                            </div>
                        </IonCol>
                        <IonCol size="3"></IonCol>
                    </IonRow>
                    }
                    {getFileLoader &&
                    <IonRow>
                        <IonCol size="3"></IonCol>
                        <IonCol className='ion-padding ion-text-left'>
                            <FileLoader 
                                state={props} 
                                form_name="Time Sheet" 
                                restore_id={2}
                                ctt={0}
                                rounding ={"0.00"}
                                migration_name ={getProjectName}
                                billing_code = {""}
                                country = {getCountryId}
                                migration_id = {getMigrationId}
                                load_counter = {(e:any)=>{}}
                                version_id={(e:any)=>{setVersionId(e);alert(e);}}
                                file_name = {(e:any)=>{}}
                                package_id ={ -1 }
                                load_table ={(e:any)=>{callTimesheet(e);setVersionId(e);}}
                            />
                        </IonCol>
                        <IonCol size="3"></IonCol>
                    </IonRow>
                    }
                    {getLoadedTimeSheet &&
                    <IonRow>
                        <IonCol size="12">
                            <IonRow>
                                <IonCol size="10"></IonCol>
                                <IonCol>
                                    <div onClick={()=>{setView(4)}} className="main-button ion-text-center">BACK</div>
                                </IonCol>
                                <IonCol>
                                    <div onClick={()=>{setNewInvoice()}} className="main-button ion-text-center">LOAD</div>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3"></IonCol>
                                <IonCol className='ion-padding ion-text-center'>
                                    <Basic 
                                        data={getData1}
                                    />
                                </IonCol>
                                <IonCol size="3"></IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    }
                    {getContractor &&
                    <IonRow>
                        <IonCol>
                            {getContractorList &&
                                <Contractors
                                    userId={props.state.user_id} 
                                    forename={props.state.forename}  
                                    surname={props.state.surname} 
                                    email={props.state.email} 
                                    organization={props.state.organization_name}
                                    mobile={props.state.mobile}
                                    primaryhost={props.state.primary_host}
                                />
                            }
                        </IonCol>
                    </IonRow>
                    }
                    {getStatement &&
                    <IonRow>
                    <IonCol size="12">
                        <IonRow className="">
                            <IonCol size="2" className="size-18 ion-text-bold header-fixed bg-white ion-padding">
                                Statement
                            </IonCol>
                            <IonCol size="2" className="size-16 ion-text-center  header-fixed bg-white ion-padding">
                                <div className="main-button">
                                    <b>{getWIPAmount}</b> <br/>
                                    <div className="size-12">WIP Amount</div>
                                </div>
                            </IonCol>
                            <IonCol size="2" className="size-16 ion-text-center  header-fixed bg-white ion-padding">
                                <div className="main-button">
                                    <b>{getNetAmount}</b><br/>
                                    <div className="size-12">Net Amount</div>
                                </div>
                            </IonCol>
                            <IonCol size="2" className="size-16 ion-text-center  header-fixed bg-white ion-padding">
                                <div className="main-button">
                                    <b>{getDisbursementAmount}</b><br/>
                                    <div className="size-12">Disbursement Amount</div>
                                </div>
                            </IonCol>
                            <IonCol size="2" className="size-16 ion-text-center header-fixed bg-white ion-padding">
                                <div className="main-button">
                                    <b>{getDueVatAmount}</b><br/>
                                    <div className="size-12">VAT Amount</div>
                                </div>
                            </IonCol>
                            <IonCol size="2" className="size-16 ion-text-center  header-fixed bg-white ion-padding">
                                <div className="main-button">
                                    <b>{getGrossAmount}</b><br/>
                                    <div className="size-12">Gross Amount</div>
                                </div>
                            </IonCol>
                            <IonCol size="12" className="statement-table ion-padding" >
                                    <Statement
                                        data={getData}
                                        setInvoice={(e:any)=>{callDetail(e[0].invoice,e[0].transaction_type_id)}}
                                    />
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    </IonRow>
                    }
                    {getInvoiceDetail &&
                    <IonRow>
                        <IonCol size="12">
                            <IonRow className="">
                                <IonCol size="2" className="size-18 ion-text-bold header-fixed bg-white ion-padding">
                                    Invoice Detail
                                </IonCol>
                                <IonCol size="2" className="size-16 ion-text-center  header-fixed bg-white ion-padding">
                                    <div className="main-button" onClick={()=>{}}>
                                        <b>{getWIPAmount}</b><br/>
                                        <div className="size-12">WIP</div>
                                    </div>
                                </IonCol>
                                <IonCol size="2" className="size-16 ion-text-center  header-fixed bg-white ion-padding">
                                    <div className="main-button" onClick={()=>{setView(5)}}>
                                        <b>{getAmount}</b><br/>
                                        <div className="size-12">Invoice Amount Due</div>
                                    </div>
                                </IonCol>
                                <IonCol size="2" className="size-16 ion-text-center  header-fixed bg-white ion-padding">
                                    <div className="main-button" onClick={()=>{setView(6)}}>
                                        <b>{getDueVatAmount}</b><br/>
                                        <div className="size-12">VAT Amount Due</div>
                                    </div>
                                </IonCol>
                                <IonCol size="2" className="size-16 ion-text-center  header-fixed bg-white ion-padding">
                                    <div className="main-button" onClick={()=>{setView(8)}}>
                                        <b>{getContractorAmount}</b><br/>
                                        <div className="size-12">Payout Amount</div>
                                    </div>
                                </IonCol>
                                <IonCol size="2" className="size-16 ion-text-center  header-fixed bg-white ion-padding">
                                    <div className="main-button" onClick={()=>{setView(9)}}>
                                        <b>{getCommissionAmount}</b><br/>
                                        <div className="size-12">Payout Commission</div>
                                    </div>
                                </IonCol>
                                {getGenerateInvoice &&
                                <IonCol size="2" className="size-16 ion-text-center  header-fixed  ion-padding">
                                    <div className="main-button bg-green text-white" onClick={()=>{setView(5)}}>
                                        <b>{getGrossAmount}</b><br/>
                                        <div className="size-12">Generate Invoice</div>
                                    </div>
                                </IonCol>
                                }
                                <IonCol size="12" className="statement-table ion-padding" >
                                        <Detail
                                            data={getData}
                                            setStatus={(e:any)=>{setStatus(e[0].id,e[0].parent_id)}}
                                            setInvoice={(e:any)=>{callDetail(e[0].invoice,e[0].transaction_type_id)}}
                                            setRate={(e:any) =>{openModal(e[0].type);setTransactionTypeId(e[0].transaction_type_id);setId(e[0].id);setParentId(e[0].parent_id);setType(e[0].type);}}
                                            setUnits={(e:any)=>{openModal(e[0].type);setTransactionTypeId(e[0].transaction_type_id);setId(e[0].id);setParentId(e[0].parent_id);setType(e[0].type);}}
                                        />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    }
                    {getPayInvoice &&
                    <IonRow>
                    <IonCol size="12">
                            {getPaymentTemplate &&
                            <IonRow>
                                <IonCol size="3"></IonCol>
                                <IonCol size="6" className="ion-padding ion-text-center">
                                    <IonRow className="ion-padding border-top">
                                        <IonCol size="6" className="ion-text-left ion-padding size-20 ion-text-bold">Pay Invoice No.{getInvoiceNumber}</IonCol>
                                        {!getPayButton &&
                                            <IonCol size="3" ></IonCol>
                                        }
                                        <IonCol size="3" className="ion-text-right">
                                            <div className="main-button ion-text-center " onClick={()=>{setView(4)}}>BACK</div>
                                        </IonCol>
                                        {getPayButton &&
                                        <IonCol size="3" >
                                            <div className="main-button ion-text-center " onClick={()=>{setPayment(3933)}}>CONFIRM PAYMENT</div>
                                        </IonCol>
                                        }
                                    </IonRow>
                                    <IonRow className="ion-padding border-top">
                                        <IonCol size="6" className="size-16 ion-text-left">Payment Date</IonCol>
                                        <IonCol size="6" className="size-16 ion-text-right">
                                            <IonInput type="date" ref={txtPaymentDate} onIonChange={()=>{controlPayAmount()}} />
                                        </IonCol>
                                    </IonRow>
                                    {/*<IonRow className="ion-padding">
                                        <IonCol size="6" className="size-16 ion-text-left">Pay Amount</IonCol>
                                        <IonCol size="6" className="size-16 ion-text-right">
                                            <IonInput type="text" ref={txtPayAmount} onIonBlur={(e:any)=>{ txtPayAmount.current.value = addCommas((e.currentTarget!.value!/1).toFixed(2))}} onKeyUp={(e)=>{controlPayAmount(e.currentTarget.value)}} />
                                        </IonCol>
                                    </IonRow>*/}
                                    <IonRow className="ion-padding">
                                        <IonCol size="6" className="size-16 ion-text-left">Banking Reference</IonCol>
                                        <IonCol size="6" className="size-16 ion-text-right">
                                            <IonInput type="text" ref={txtPayReference} placeholder="Reference" onKeyUp={(e:any)=>{controlPayAmount()}} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding border-top border-bottom"> 
                                        <IonCol size="6" className="size-16 ion-text-left">Total Invoice</IonCol>
                                        <IonCol size="6" className="size-16 ion-text-right">{getAmount}</IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            }
                            {getContractorPaymentTemplate &&
                            <IonRow>
                                <IonCol size="3"></IonCol>
                                <IonCol size="6" className="ion-padding ion-text-center">
                                    <IonRow className="ion-padding border-top">
                                        <IonCol size="6" className="ion-text-left ion-padding size-20 ion-text-bold">Pay Contractor Reference No.{getReferenceNumber}</IonCol>
                                        {!getPayButton &&
                                            <IonCol size="3" ></IonCol>
                                        }
                                        <IonCol size="3" className="ion-text-right">
                                            <div className="main-button ion-text-center " onClick={()=>{setView(4)}}>BACK</div>
                                        </IonCol>
                                        {getPayButton &&
                                        <IonCol size="3" >
                                            <div className="main-button ion-text-center " onClick={()=>{setPayment(3942)}}>CONFIRM PAYMENT</div>
                                        </IonCol>
                                        }
                                    </IonRow>
                                    <IonRow className="ion-padding border-top">
                                        <IonCol size="6" className="size-16 ion-text-left">Payment Date</IonCol>
                                        <IonCol size="6" className="size-16 ion-text-right">
                                            <IonInput type="date" ref={txtPaymentDate} onIonChange={()=>{controlPayAmount()}} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding">
                                        <IonCol size="6" className="size-16 ion-text-left">Banking Reference</IonCol>
                                        <IonCol size="6" className="size-16 ion-text-right">
                                            <IonInput type="text" ref={txtPayReference} placeholder="Reference" onKeyUp={(e:any)=>{controlPayAmount()}} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding border-top border-bottom"> 
                                        <IonCol size="6" className="size-16 ion-text-left">Total Payment</IonCol>
                                        <IonCol size="6" className="size-16 ion-text-right">{getContractorAmount}</IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            }
                            {getCommissionPaymentTemplate &&
                            <IonRow>
                                <IonCol size="3"></IonCol>
                                <IonCol size="6" className="ion-padding ion-text-center">
                                    <IonRow className="ion-padding border-top">
                                        <IonCol size="6" className="ion-text-left ion-padding size-20 ion-text-bold">Pay Commission Invoice No.{getInvoiceNumber}</IonCol>
                                        {!getPayButton &&
                                            <IonCol size="3" ></IonCol>
                                        }
                                        <IonCol size="3" className="ion-text-right">
                                            <div className="main-button ion-text-center " onClick={()=>{setView(4)}}>BACK</div>
                                        </IonCol>
                                        {getPayButton &&
                                        <IonCol size="3" >
                                            <div className="main-button ion-text-center" onClick={()=>{setPayment(3943)}}>CONFIRM PAYMENT</div>
                                        </IonCol>
                                        }
                                    </IonRow>
                                    <IonRow className="ion-padding border-top">
                                        <IonCol size="6" className="size-16 ion-text-left">Payment Date</IonCol>
                                        <IonCol size="6" className="size-16 ion-text-right">
                                            <IonInput type="date" ref={txtPaymentDate} onIonChange={()=>{controlPayAmount()}} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding">
                                        <IonCol size="6" className="size-16 ion-text-left">Banking Reference</IonCol>
                                        <IonCol size="6" className="size-16 ion-text-right">
                                            <IonInput type="text" ref={txtPayReference} placeholder="Reference" onKeyUp={(e:any)=>{controlPayAmount()}} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding border-top border-bottom"> 
                                        <IonCol size="6" className="size-16 ion-text-left">Total Payment</IonCol>
                                        <IonCol size="6" className="size-16 ion-text-right">{getCommissionAmount}</IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            }
                            {getInvoiceTemplate &&
                            <IonRow>
                            <IonCol size="2"></IonCol>
                            <IonCol size="8" className="ion-padding ion-text-center invoice-scroll">
                                <IonRow>
                                    <IonCol size="12" className="size-16 ion-text-right">
                                        <InvoiceTemplate  
                                            invoice_date={getInvoiceDate}
                                            invoice_number={getInvoiceNumber}
                                            vendor_number={getVendorNumber}
                                            company_address={getCompanyAddress}
                                            job_description={getJobDescription}
                                            total_work={getTotalUnits}
                                            average_rate={getAverageRate}
                                            other_charges={getDisbursementAmount} 
                                            total_invoice_ex_vat={getNetAmount}
                                            vat_rate={getVATRate}
                                            vat={getInvoiceVatAmount}
                                            vat_number={getClientVATNumber}
                                            total_invoice_incl_vat={getGrossAmount}
                                            lockInvoice={(e:any)=>{lockInvoice(e)}}
                                            proceedToPayment={(e:any)=>{setView(7)}}
                                        />
                                    </IonCol>
                                </IonRow>
                            </IonCol>    
                            <IonCol size="2"></IonCol>
                            </IonRow>
                            } 
                            {getFileLoader &&
                            <IonRow>
                                <IonCol size="2"></IonCol>
                                <IonCol size="6">
                                    <IonRow>
                                        <IonCol size="12" className="size-16 ion-text-right">
                                            <FileObjectLoader 
                                                state={props}
                                            />
                                        </IonCol>
                                    </IonRow>
                                </IonCol> 
                                <IonCol size="2"></IonCol>
                            </IonRow>
                            }
                    </IonCol>
                    </IonRow>
                    }
                    {getPayVAT &&
                    <IonRow>
                    <IonCol size="12">
                        <IonRow>
                            <IonCol size="2"></IonCol>
                            <IonCol size="8" className="ion-padding">
                                <IonRow className="ion-padding border-top">
                                    <IonCol size="6" className="ion-text-left ion-padding size-20 ion-text-bold">Confirm VAT Payment For Invoice No.{getInvoiceNumber}</IonCol>
                                    <IonCol size="3" className="ion-text-rigt">
                                        <div className="main-button ion-text-center" onClick={()=>{setView(4)}}>BACK</div>
                                    </IonCol>
                                    {getPayButton &&
                                    <IonCol size="3" >
                                        <div className="main-button ion-text-center" placeholder='SARs Reference Number' onClick={()=>{setPayment(3934)}}>CONFIRM VAT PAYMENT</div>
                                    </IonCol>
                                    }
                                </IonRow>
                                <IonRow className="ion-padding border-top">
                                    <IonCol size="6" className="size-16 ion-text-left">Payment Date</IonCol>
                                    <IonCol size="6" className="size-16 ion-text-right">
                                        <IonInput type="date" ref={txtPaymentDate} onIonChange={()=>{controlPayAmount()}}  />
                                    </IonCol>
                                </IonRow>
                                {/*<IonRow className="ion-padding">
                                    <IonCol size="6" className="size-16 ion-text-left">Pay VAT</IonCol>
                                    <IonCol size="6" className="size-16 ion-text-right">
                                        <IonInput type="text" ref={txtPayAmount} placeholder="Enter Amount" onIonBlur={(e:any)=>{ txtPayAmount.current.value = addCommas((e.currentTarget!.value!/1).toFixed(2))}} onKeyUp={(e)=>{controlPayAmount()}} />
                                    </IonCol>
                                </IonRow>*/}
                                <IonRow className="ion-padding">
                                    <IonCol size="6" className="size-16 ion-text-left">SARs Reference</IonCol>
                                    <IonCol size="6" className="size-16 ion-text-right">
                                        <IonInput type="text" ref={txtPayReference} onKeyUp={()=>{controlPayAmount()}} />
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding border-top border-bottom"> 
                                    <IonCol size="6" className="size-16 ion-text-left">Total VAT</IonCol>
                                    <IonCol size="6" className="size-16 ion-text-right">{getDueVatAmount}</IonCol>
                                </IonRow>
                                {/*<IonRow className="ion-padding">
                                    <IonCol size="6" className="size-16 ion-text-left">Total VAT Paid</IonCol>
                                    <IonCol size="6" className="size-16 ion-text-right">{getVATPaidAmount}</IonCol>
                                </IonRow>
                                <IonRow className="ion-padding border-top border-bottom">
                                    <IonCol size="6" className="size-16 ion-text-left"><b>Outstanding VAT Balance</b></IonCol>
                                    <IonCol size="6" className="size-16 ion-text-right"><b>{getOutstandingBalance}</b></IonCol>
                                </IonRow>*/}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    </IonRow>
                    }
                
            </div>

        </div>
    )
}

export default Contracktor