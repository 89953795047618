import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonIcon, IonCard, IonCardHeader, IonTitle, IonCardContent, IonFooter, IonImg, IonHeader, IonItem, IonLabel, IonButton, IonInput, IonCheckbox, IonSpinner, IonContent, IonLoading, IonSelectOption, IonSelect } from '@ionic/react';
import {arrowForward, arrowForwardOutline, checkmarkOutline, chevronDown, chevronForward, chevronUp, close, closeOutline, cloudOutline, cloudUploadOutline, documentOutline, logoAndroid, serverOutline, settingsOutline} from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import './bot.css'
const Bot = (props:any)=>{
    const [getPage, setPage]                = useState<any>("bot-panel-up")
    const [getTypewriter, setTypeWriter]    = useState<any>("")
    const [getDetails, setDetails]          = useState<any>()
    const [getDetails1, setDetails1]          = useState<any>()
    const [getDetails2, setDetails2]          = useState<any>()
    const [getDetails3, setDetails3]          = useState<any>()
    const [getDetails4, setDetails4]          = useState<any>()
    const [getForename, setForename]        = useState<any>()

    const processChat = (id:any, step:any ) =>{
        switch(id){
            case 0: setGeneralEnquiry(1);setTimeout(function(){setTypeWriter("text-black typewriter");},2000); break;
            case 67:setDetailsML(step);break;
        }
    }
    const setGeneralEnquiry = (d:any)=>{
        switch(d){
            case 1: setTimeout(function(){setDetails(
            
            <div className="ion-padding">
                <IonRow>
                    <IonCol className={getTypewriter}>Hello {getForename}! How can I assist you? What would you like to do?</IonCol>
                </IonRow>
            </div>)
            },1000);
            setTimeout(function(){
                props.set_panel_height("bot-panel-up-1");
                setDetails1(
                <div className="ion-padding">
                    {true &&
                    <IonRow>
                        <IonCol>
                            <IonButton onClick={()=>{var x:any = []; x.push({application_id:67, view:7});props.setApplication(x[0])}}>Perform JETS</IonButton>
                        </IonCol>
                    </IonRow>}
                </div>)
            },2000);
            setTimeout(function(){
                props.set_panel_height("bot-panel-up-2");
                setDetails2(
                <div className="ion-padding">  
                { true &&
                <IonRow>
                    <IonCol>
                        <IonButton onClick={()=>{var x:any = []; x.push({application_id:67, view:6});props.setApplication(x[0])}}>Perform some Analytical Reviews</IonButton>
                    </IonCol>
                </IonRow>}
                </div>)
            },3000);
            setTimeout(function(){
                props.set_panel_height("bot-panel-up-3");
                setDetails3(
                <div className="ion-padding">
                {true &&
                <IonRow>
                    <IonCol>
                        <IonButton onClick={()=>{var x:any = []; x.push({application_id:67, view:10});props.setApplication(x[0])}}>Train Machine learning</IonButton>
                    </IonCol>
                </IonRow>}
                </div>)
            },4000);
            setTimeout(function(){
                props.set_panel_height("bot-panel-up-4");
                setDetails4(
                <div className="ion-padding">
                {true &&
                <IonRow>
                    <IonCol>
                        <IonButton onClick={()=>{var x:any = []; x.push({application_id:67, view:7});props.setApplication(x[0])}}>Perform some Predictive Analytics</IonButton>
                    </IonCol>
                </IonRow>}
                </div>)
            },5000)
            ;break;
        }
    }
    const setDetailsML = (d:any) =>{
        switch(d){
            case 0: setDetails(
            <div className="ion-padding">
                <IonRow>
                    <IonCol className="ion-text-center">
                        <IonIcon icon={logoAndroid} className="size-32"></IonIcon><br/>
                        <IonLabel>Machine Learning</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>Select source data</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className='ion-text-center size-16 hover' >
                        <IonIcon icon={serverOutline} className="size-36 text-gt"></IonIcon>
                        <br/>
                        Choose Existing Data
                    </IonCol>
                    <IonCol className='ion-text-center size-16 hover' onClick={()=>{props.set_view(1);/*setFileUploadPanel(true); setNewProjectForm(true); console.log(props.state)*/}}>
                        <IonIcon icon={documentOutline} className="size-36 text-gt"></IonIcon>
                        <br/>
                        Upload File
                    </IonCol>
                </IonRow>
            </div>
            );break;
            case 24: setDetails(
                <IonRow>
                    <IonCol>Please select first account number in the document?</IonCol>
                </IonRow>
            );break;
        }
    }
    useEffect(() => {
        setPage(props.get_position)
        processChat(props.application_id, props.step)
        if(getForename != props.forename){
            setForename(props.forename)
        }
        //alert(props.application_id)
        //alert(props.step)
    }, []);
    return(
    <div>
        <IonRow className='row-divider-bottom'>
            <IonCol size="2">
                <img src="../../assets/images/dmzee_logo-03.png" className="size-48" />
            </IonCol>
            <IonCol className="ion-text-right">
                {getPage == "bot-panel-up" &&
                    <IonIcon onClick={()=>{setPage("bot-panel-down"); props.set_position("bot-panel-down")}} icon={chevronDown} className="size-32" ></IonIcon>
                }
                {getPage == "bot-panel-down" &&
                    <IonIcon onClick={()=>{setPage("bot-panel-up"); props.set_position("bot-panel-up")}} icon={chevronUp} className="size-32" ></IonIcon>
                }
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol>
                {getDetails}
                {getDetails1}
                {getDetails2}
                {getDetails3}
                {getDetails4}
            </IonCol>
        </IonRow>
    </div>
    )
}
export default Bot;