import { IonRow, IonCol, IonIcon, IonLabel } from '@ionic/react';
import {analyticsOutline, barChartOutline, cloudUploadOutline, createOutline, exitOutline, homeOutline, listOutline, pulseOutline, settingsOutline,} from 'ionicons/icons';
import $ from 'jquery'
import { useEffect, useState } from 'react';
const SideBar = (props:any) =>{
    const [getPrevLocation, setPrevLocation]            = useState<any>()
    const [getState, setState]                          = useState<any>("icon-ar")
    const [hideOption00, showOption00]                  = useState<any>("icon-ar")
    const [hideOption0, showOption0]                    = useState<any>("icon-ar")
    const [hideOption1, showOption1]                    = useState<any>("icon-ar")
    const [hideOption2, showOption2]                    = useState<any>("icon-ar")
    const [hideOption3, showOption3]                    = useState<any>("icon-ar")
    const [hideOption4, showOption4]                    = useState<any>("icon-ar")
    const [hideOption5, showOption5]                    = useState<any>("icon-ar")
    const [hideOption6, showOption6]                    = useState<any>("icon-ar")
    const [hideOption7, showOption7]                    = useState<any>("icon-ar")

    const [hideOptionSide00, showOptionSide00]          = useState<any>("label-side-bar-ar")
    const [hideOptionSide0, showOptionSide0]            = useState<any>("label-side-bar-ar")
    const [hideOptionSide1, showOptionSide1]            = useState<any>("label-side-bar-ar")
    const [hideOptionSide2, showOptionSide2]            = useState<any>("label-side-bar-ar")
    const [hideOptionSide3, showOptionSide3]            = useState<any>("label-side-bar-ar")
    const [hideOptionSide4, showOptionSide4]            = useState<any>("label-side-bar-ar")
    const [hideOptionSide5, showOptionSide5]            = useState<any>("label-side-bar-ar")
    const [hideOptionSide6, showOptionSide6]            = useState<any>("label-side-bar-ar")
    const [hideOptionSide7, showOptionSide7]            = useState<any>("label-side-bar-ar")

    const [getHome      , setHome]                      = useState<any>(true)
    const [getProjects  , setProjects]                  = useState<any>(false)
    const [getReports   , setReports]                   = useState<any>(false)
    const [getAudit     , setAudit]                     = useState<any>(false)
    const [getSetting   , setSetting]                   = useState<any>(false)
    const [getGlobalSettings, setGlobalSettings]        = useState<any>(false)
    const [getCustom    , setCustom]                    = useState<any>(false)
    const [getNewProject, setNewProject]                = useState<any>(false)
    const [getPredictive, setPredictive]                = useState<any>(false)


    const setLocation = (v:any) =>{
        resetLocation()
        switch(v){
            case 1: ;break;
            case 2: //setReports(true);
                    //setAudit(true);
                    //setSetting(true);
                    setNewProject(true)
                    //setCustom(true)
                    //setGlobalSettings(true)
                    //setPredictive(true)
                    break;
            case 3: setNewProject(true)
                    //setCustom(true)
                    setReports(true);
                    //setSetting(true);
                    //setProjects(true)
                    //setPredictive(true)
                    ;break;
            case 4: setNewProject(true)
                    //setCustom(true);
                    break;
            case 5: ;break;
            case 6: ;break;
        }
    }
    const resetLocation = () =>{
        //setHome(false)  
        setProjects(false) 
        setReports(false) 
        setAudit(false)  
        setSetting(false) 
        setCustom(false) 
        setGlobalSettings(false)
        setNewProject(false)
        setPredictive(false)
    }
    const setSelected = (s:any)=>{
        resetSelected()
        console.log("option:"+s)
        switch(s){
            case -1:showOption00("iconeb-ar-active");showOptionSide00("label-side-bar-ar-active");break;
            case  0:showOption0 ("iconeb-ar-active");showOptionSide0 ("label-side-bar-ar-active");break;
            case  1:showOption1 ("iconeb-ar-active");showOptionSide1 ("label-side-bar-ar-active");break;
            case  2:showOption2 ("iconeb-ar-active");showOptionSide2 ("label-side-bar-ar-active");break;
            case  3:showOption3 ("iconeb-ar-active");showOptionSide3 ("label-side-bar-ar-active");break;
            case  4:showOption4 ("iconeb-ar-active");showOptionSide4 ("label-side-bar-ar-active");break;
            case  5:showOption5 ("iconeb-ar-active");showOptionSide5 ("label-side-bar-ar-active");break;
            case  6:showOption6 ("iconeb-ar-active");showOptionSide6 ("label-side-bar-ar-active");break;
            case  7:showOption6 ("iconeb-ar-active");showOptionSide6 ("label-side-bar-ar-active");break;
        }
    }
    const resetSelected = () =>{
        showOption00("iconeb-ar")
        showOption0("iconeb-ar");
        showOption1("iconeb-ar");
        showOption2("iconeb-ar");
        showOption3("iconeb-ar");
        showOption4("iconeb-ar");
        showOption5("iconeb-ar");
        showOption6("iconeb-ar");
        showOption7("iconeb-ar");
        showOptionSide00("label-side-bar-ar");
        showOptionSide0 ("label-side-bar-ar");
        showOptionSide1 ("label-side-bar-ar");
        showOptionSide2 ("label-side-bar-ar");
        showOptionSide3 ("label-side-bar-ar");
        showOptionSide4 ("label-side-bar-ar");
        showOptionSide5 ("label-side-bar-ar");
        showOptionSide6 ("label-side-bar-ar");
        showOptionSide7 ("label-side-bar-ar");
    }
    useEffect(() => {
        setState(()=>{
            if(props.state != null){
                setSelected(props.state.side_bar_option/1)  
            }      
            if(props.side_bar_list != getPrevLocation){
                setPrevLocation(props.side_bar_list)
                setLocation(props.side_bar_list)
            }
        })
    }, [props.state, props.side_bar_list]);
    return(
        <div>
            {getHome &&
            <IonRow className="side-bar-v-spacing-ar">
                <IonCol onClick={(e) =>{window.location.replace("https://www.dmzee.co.za");
 }} className={"ion-text-center "+hideOptionSide00}>
                    <IonIcon className={hideOption00+" size-32 selected selected--1"}  icon={exitOutline}></IonIcon><br/>
                    <IonLabel className="label-ar text-black selected selected--1">Exit<br/> </IonLabel>
                </IonCol>
            </IonRow> 
            }
            {getNewProject &&
            <IonRow className="side-bar-v-spacing-ar">
                <IonCol className={"ion-text-center "+hideOptionSide5} onClick={(e) =>{props.setSideBarOption([5,e]);props.setBar(5)}}>
                    <IonIcon className={hideOption5+" size-32 selected-5"} icon={cloudUploadOutline}></IonIcon><br/>
                    <IonLabel className="label-ar text-black  selected-5">New Project <br/> </IonLabel>
                </IonCol>
            </IonRow>
            }
            {getProjects &&
            <IonRow className="text-center side-bar-v-spacing-ar ">
                <IonCol onClick={(e) => {props.setSideBarOption([0,e]);props.setBar(1)}} className={"ion-text-center "+hideOptionSide0} >
                    <IonIcon className={hideOption0+" size-32 selected-0"}  icon={pulseOutline}></IonIcon><br/>
                    <IonLabel className="label-ar text-black selected-0">Existing Projects<br/></IonLabel>
                </IonCol>
            </IonRow>
            }
            
            {getReports &&
            <IonRow className="side-bar-v-spacing-ar">
                <IonCol className={"ion-text-center "+hideOptionSide1} onClick={(e) => {props.setSideBarOption([1,0]);props.setBar(2)}}>
                    <IonIcon className={hideOption1+" size-32 selected-1"}  icon={analyticsOutline}></IonIcon><br/>
                    <IonLabel className="label-ar text-black selected-1">Reports <br/> </IonLabel>
                </IonCol>
            </IonRow>  
            }
            {getAudit &&
            <IonRow className="side-bar-v-spacing-ar">
                <IonCol className={"ion-text-center "+hideOptionSide5} onClick={(e) =>{props.setSideBarOption([5,e]);}}>
                    <IonIcon className={hideOption5+" size-32  selected-5"}  icon={listOutline}></IonIcon><br/>
                    <IonLabel className="label-ar text-black selected-5">Audit <br/></IonLabel>
                </IonCol>
            </IonRow>
            }
            {getSetting &&
            <IonRow className="side-bar-v-spacing-ar">
                <IonCol className={"ion-text-center "+hideOptionSide3} onClick={(e) =>{props.setSideBarOption([3,e]);props.setBar(3)}}>
                    <IonIcon className={hideOption3+" size-32  selected-3"}  icon={settingsOutline}></IonIcon><br/>
                    <IonLabel className="label-ar text-black selected-3"> Project<br/>Settings </IonLabel>
                </IonCol>
            </IonRow>
            }
            {getPredictive &&
            <IonRow className="side-bar-v-spacing-ar">
                <IonCol className={"ion-text-center "+hideOptionSide7} onClick={(e) =>{props.setSideBarOption([7,e]);props.setBar(7)}}>
                    <IonIcon className={hideOption7+" size-32 selected-7"} icon={barChartOutline}></IonIcon><br/>
                    <IonLabel className="label-ar text-black  selected-7">Predictive <br/>Analytics </IonLabel>
                </IonCol>
            </IonRow>
            }
            {getCustom &&
            <IonRow className="side-bar-v-spacing-ar">
                <IonCol className={"ion-text-center "+hideOptionSide4} onClick={(e) =>{props.setSideBarOption([4,e]);props.setBar(4)}}>
                    <IonIcon className={hideOption4+" size-32 selected-4"} icon={createOutline}></IonIcon><br/>
                    <IonLabel className="label-ar text-black  selected-4">Custom <br/> </IonLabel>
                </IonCol>
            </IonRow>
            }
            
            {getGlobalSettings &&
            <IonRow className="side-bar-v-spacing-ar">
                <IonCol className={"ion-text-center "+hideOptionSide6} onClick={(e) =>{props.setSideBarOption([6,e]);props.setBar(6)}}>
                <IonIcon className={hideOption6+" size-32 selected-6"}  icon={settingsOutline}></IonIcon><br/>
                <IonLabel className="label-ar text-black  selected-6">System<br/>Settings </IonLabel>
                </IonCol>
            </IonRow>
            }
        </div>
    )
}
export default SideBar;