import { IonCardHeader, IonFooter,IonInput, IonListHeader, IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonPage, IonProgressBar, IonRadio, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonRadioGroup, IonToggle, IonImg, IonCard, IonItemDivider, IonSpinner } from '@ionic/react';
import { cloudUploadOutline, analyticsSharp, arrowForwardOutline, calendarClearOutline, calendarOutline, checkmarkCircleOutline, checkmarkCircleSharp, checkmarkOutline, closeOutline, cloudUpload, cogOutline, createSharp, documentSharp, filterOutline, homeSharp, logOutOutline, refreshCircleOutline, settingsOutline, shareSocial, stopCircleSharp, textOutline, trash, chevronUp, chevronDown, documentOutline, checkmark, close } from 'ionicons/icons';
import React, { Component, useRef, useState } from 'react';
//import SlidingPane from "react-sliding-pane";
//import "react-sliding-pane/dist/react-sliding-pane.css";
//import ReactTable from "react-table-6";
import './fileloader.css';
import '../../theme/fonts/poppins-cufonfonts-webfont/Poppins-Regular.woff'
import { faCheckCircle, faCopy, faDatabase, faDesktop, faExclamationCircle, faFileExcel, faServer, faSortNumericUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import { selectVersion } from '../../dbconnections/Select';
//import Chart from '../../components/chart' 
//import {getUserId} from './Home';
//import { isNumber } from 'highcharts';

let host:string = "";
let append:any = false; 
let isPaneOpen :boolean =false; 
let errors:boolean = false;
let isPaneOpenLeft:boolean=false;
let glVersionId:any;
let userId:any;
let file:any;
const filePath ='F:/home/site/wwwroot/files/in'
let move_file:any="position-l-h"
let move_data:any="position-l-h"
let main_animation:any="position-main-h";
let blobId:any = 0;
let packageId:any = 2;
let versionId:any = 0;
let contactId:any = 4;
let migrationId:any = 0;
let applicationId:any = 67;
let projectName :any;
let fileRestoreId :any=1;
let fileArray:any="";
let glFileName :any;
let jnlFilter:any;
let set_file_load_option=0;
let createdBy:any = 4;

let trCodeList:any=[];
let worksheet:any="";
let delimiter:any=",";
let textqual:any="\"";
let extension:any="";
<input id="hdnDataTypeId" type="hidden"  />


const FileLoader = (props:any) => {
    console.log(props)
    host        = props.state.primary_host;
    contactId   = props.state.user_id;
    //set_file_load_option = props.setOption
    const [getVersionId, setVersionId]                      = useState<any>()
    const [getFileRestore, setFileRestore]                  = useState<boolean>(false)
    const [getDBRestore, setDBRestore]                      = useState<boolean>(false)
    const [getOnlineAPIRestore, setOnlineAPIRestore]        = useState<boolean>(false)
    const [hideFunctionsList, showFunctionsList]            = useState<boolean>(true)
    const [hideAppendFunction, showAppendFunction]          = useState<boolean>(false)

    const [getProjectNameData, setProjectNameData]      = useState<any>('')
    const [getRoundingData, setRoundingData]            = useState<any>('')
    const [getBillingCodeData, setBillingCodeData]      = useState<any>('')
    const [getCTTData, setCTTData]                      = useState<any>(0)
    const [getCountryData, setCountryData]              = useState<any>(0)
    const [getRestoreDBId, setRestoreDBId]              = useState<any>(0)

    const setOption = () =>{
        resetOption()
        switch(set_file_load_option/1){
            case 1:setFileRestore(true);break;
            case 2:setDBRestore(true);break;
            case 3:setOnlineAPIRestore(true);break;
        }
    }
    const resetOption = () =>{
        setFileRestore(false)
        setDBRestore(false)
        setOnlineAPIRestore(false)
    }
    React.useEffect(() => {
            setOption()
        }, [])


    createdBy   = props.state.props.user_id;
    userId      = props.state.props.user_id;
    contactId   = props.state.props.user_id;
 
   
        
    const [getDataIntegrity, setDataIntegrity]          = useState<any>()
    const [getFileName, setFileName]                    = useState<any>()
    const [getShowLoading, setShowLoading]              = useState<boolean>(false)
    const [showModal, setShowModal]                     = useState<boolean>(true);
    const [getWorksheets, setWorksheets]                = useState<HTMLIonSelectOptionElement>();
    const [getLoader, setLoader]                        = useState<HTMLIonSelectOptionElement>();
    const [getHeaders, setHeaders]                      = useState<HTMLIonSelectOptionElement>();
    const [getTransactionCodes, setTransactionCodes ]   = useState<any>();
    const [getTestResults,setTestResults]               = useState<HTMLIonItemElement>();
    const [getFileTestResults,setFileTestResults]       = useState<HTMLIonItemElement>();
    const [hideFileTest,showFileTest]                   =useState<boolean>(false);
    const [hideContinue     , showContinue]              =useState<boolean>(false)
    const [getProjectName   , setProjectName]          =useState<any>(false)
    const [getBilling       , setBilling]                   =useState<boolean>(false)
    const [getCountry       , setCountry]                  =useState<boolean>(false)
    const [getMateriality   , setMateriality]          =useState<boolean>(false)
    const [getRounding      , setRounding]                =useState<boolean>(false)
    const [getFileUpload, setFileUpload]            =useState<boolean>(false);
    const [getExcelWorksheet, setExcelWorkSheet]    =useState<boolean>(false);
    const [getImportButton,setImportButton]         =useState<boolean>(false);
    const [getDelimiterOption, setDelimiterOption]  =useState<boolean>(false);
    const [getContinueButton, setContinueButton]    =useState<boolean>(false);
    const [getWorkbookName, setWorkbookName]        =useState<any>()
    const [hideDataIntegrity, showDataIntegrity]     =useState<boolean>(false)
    const callDataIntegrity = () =>{
        props.integrity(fileRestoreId)
    }

    const txtFile:any = useRef<HTMLIonInputElement>();
    const txtJournalProjectName:any = useRef<HTMLIonInputElement>();
    const txtJournalMaterialityValue:any = useRef<HTMLIonSelectElement>();
    const ddlRounding:any = useRef<HTMLIonSelectElement>();
    const txtJobCode:any = useRef<HTMLIonInputElement>();
    const txtKeywordTextSearch:any = useRef<HTMLIonInputElement>();
    const ddlCountry:any = useRef<HTMLIonSelectElement>();
    const chkNoTB:any = useRef<HTMLIonCheckboxElement>();
    const ddlDelimiter:any = useRef<HTMLIonSelectElement>();
    const ddlWorksheet:any = useRef<HTMLIonRadioGroupElement>();
    const ddlTextQualifier:any = useRef<HTMLIonSelectElement>();
    const txtFileDummy:any = useRef<HTMLIonInputElement>();
    const txtStartDate:any = useRef<HTMLIonDatetimeElement>();
    const txtEndDate:any = useRef<HTMLIonDatetimeElement>();

    const progress1:any = useRef<HTMLIonProgressBarElement>();

    const loadTextFile = async () =>{
        console.log("filerestoreid:"+fileRestoreId)
        setShowLoading(true)
        return fetch(props.state.props.primary_host+'/dbconnect/dbdynamic.jsp?dbo=loadFile'+
        '&versionid='+getVersionId+
        '&reporttypeid='+0+
        '&columns='+10+
        '&rows='+0+
        '&source='+filePath+"/"+
        "&extension="+extension+
        '&delimiter='+delimiter+
        '&sheetname='+worksheet+
        '&filename='+getVersionId+"-"+txtFileDummy.current.value+
        '&preview='+0+
        '&hdr='+0+
        '&migrationid='+props.migration_id+
        '&clean='+0+
        '&filerestoreid='+props.restore_id+
        "&packageid="+packageId+
        "&filearray="+fileArray
        
        ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => {
            setShowLoading(false)
            console.log("get file preview")
            if(fileRestoreId > 0){
                var x = fileRestoreId/1
                x++
                if(packageId == 2 || packageId == 112 || packageId == 113){
                    let isfile = 1
                    let smartactive = 0
                    var type = ""
                    switch(fileRestoreId/1){
                        case 1:type = "Opening TB";break;
                        case 2:type = "GL";break;
                        case 3:type = "Closing TB";break;
                        case 4:type = "COA";break;
                    }
                    console.log(migrationId)
                    console.log(packageId)
                    console.log(type)
                    console.log(fileRestoreId)
                    console.log(isfile)
                    validateFile(type,isfile)
                    props.load_counter(fileRestoreId)
                    props.file_name(txtFileDummy.current.value)
                    props.gl_version_id(getVersionId)
                }else{
                    //callTableObjectId(migrationId, fileRestoreId)
                    alert("File loaded successfully!")
                    x = x-1
                    if(x == 1){
                        //setJournals(13)
                        resetLoadForm();
                        //formNavigationController()
                    }
                    if(x == 2){
                        //setJournals(14)
                        resetLoadForm();
                        //formNavigationController()
                    }
                    if(x == 3){
                        //setJournals(15)
                        resetLoadForm();
                        //formNavigationController()
                    }
                    if(x == 4){
                        //setJournals(16)
                    }
                }
                                
            }
            blobId      = 0;
            worksheet   = '';
            delimiter   = ''
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }
  
    const loadFile = async () =>{
        if (extension == 'xls' || extension == 'xlsx'){
            console.log("filerestoreid:"+fileRestoreId)
            setShowLoading(true)
            return fetch(props.state.props.primary_host+'/getFilePreview?'+
            'versionid='+getVersionId+
            '&reporttypeid='+0+
            '&documentid='+0+
            '&companyid='+0+
            '&columns='+10+
            '&rows='+0+
            '&source='+filePath+
            '&delimiter='+delimiter+
            '&textqualifier='+textqual+
            '&worksheetid='+worksheet+
            '&worksheetname='+worksheet+
            '&filename='+txtFileDummy.current.value+
            '&preview='+0+
            '&hdr='+0+
            '&bulkload='+1+
            '&reload='+0+
            '&migrationid='+props.migration_id+
            '&clean='+0+
            '&filerestoreid='+props.restore_id+
            "&packageid="+packageId+
            "&filearray="+fileArray
            
            , 
                {
                    headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                }
            )
            .then(response => {
                setShowLoading(false)
                if( props.package_id != -1)
                    props.gl_version_id(getVersionId)
                if( props.package_id == -1)
                    props.version_id(getVersionId)
                console.log("get file preview")
                if (props.package_id == -1){
                    props.load_table(getVersionId)
                }else{
                if(fileRestoreId > 0){
                    var x = fileRestoreId/1
                    x++
                    if(packageId == 2 || packageId == 112 || packageId == 113){
                        let isfile = 1
                        let smartactive = 0
                        var type = ""
                        switch(fileRestoreId/1){
                            case 1:type = "Opening TB";break;
                            case 2:type = "GL";break;
                            case 3:type = "Closing TB";break;
                            case 4:type = "COA";break;
                        }
                        console.log(migrationId)
                        console.log(packageId)
                        console.log(type)
                        console.log(fileRestoreId)
                        console.log(isfile)
                        var z:any = []
                        z.push({type:type,isfile:isfile, packageId:packageId})
                        props.validateFile(z[0])
                        setContinueButton(true)
                        setExcelWorkSheet(false);
                        setDelimiterOption(false);
                        setWorkbookName(worksheet)
                        props.load_counter(fileRestoreId)
                        props.file_name(txtFileDummy.current.value)

                        if( props.package_id != -1)
                            props.gl_version_id(getVersionId)
                        if( props.package_id == -1)
                            props.version_id(getVersionId)
                    }else{
                        //callTableObjectId(migrationId, fileRestoreId)
                        alert("File loaded successfully!")
                        x = x-1
                        if(x == 1){
                            //setJournals(13)
                            resetLoadForm();
                            //formNavigationController()
                        }
                        if(x == 2){
                            //setJournals(14)
                            resetLoadForm();
                            //formNavigationController()
                        }
                        if(x == 3){
                            //setJournals(15)
                            resetLoadForm();
                            //formNavigationController()
                        }
                        if(x == 4){
                            //setJournals(16)
                        }
                    }
                                    
                }
            }
                blobId      = 0;
                worksheet   = '';
                delimiter   = ''
            })
            .catch(error=>{
                setShowLoading(false)
                alert(error)
            })
        }else{
            loadTextFile()
        }
    }
  
  const validateFile = async (type:any, isfile:any) =>{
      setShowLoading(true)
      console.log("validate file")
      let data:any = [];
      return fetch(props.state.props.primary_host+'/journals/dbconnect/select.jsp?etl=checkvalidfile2'+
          "&migrationid="+props.migration_id+
          "&packageid="+packageId+
          "&type="+type+
          "&isfile="+isfile
          ,
          {
              headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
          }
      )
      .then(response => response.json())
      .then(d =>{
          data.push(d.data); 
          callHeaders(data);
          setShowLoading(false)
      })
      .catch(error=>{
        setShowLoading(false)
        alert(error)
      })
  }
  
  const callHeaders = (results:any) =>{
      setShowLoading(true)
      let data:any = []
      return fetch(props.state.props.primary_host+'/journals/dbconnect/select.jsp?etl=checkvalidatefileheader'+
          "&migrationid="+props.migration_id+
          "&filerestoreid="+props.restore_id
          ,
          {
              headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
          }
      )
      .then(response => response.json())
      .then(d =>{
          data.push(d.data)
          console.log(data)
          let options:any = data[0].map((x:any,i:number) =>{
              console.log(x.header)
            return(
                <IonSelectOption key={i} value={x.header}>{x.header}</IonSelectOption>
            )
          
          })
          return options
      }).then(h =>{
        setShowLoading(false)
        console.log("check validate render data:"+results)
        let headers:any = h;
        let r:any = () =>{
            return(
            <div>
            <IonRow className="approval-table-header">
                    <IonCol size="4"><b>Required Headers</b></IonCol>
                    <IonCol size="1"></IonCol>
                    <IonCol size="3"><b>File Headers Found</b></IonCol>
                    <IonCol size="2"><b>Header Located</b></IonCol>
                    <IonCol size="2"><b>Data Validated</b></IonCol>
            </IonRow>
                {
                    results[0].map((x:any, i:number) =>{
                        return(
                            
                            <IonRow key={i} className="text-align-center ">
                                <IonCol size="4">{x.Details}</IonCol>
                                <IonCol size="1">
                                    <IonIcon size="large" icon={arrowForwardOutline}></IonIcon>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem>
                                    <IonSelect value={x.Details} >
                                        {headers}
                                    </IonSelect>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="2">
                                    {x.located = 1?
                                    <IonIcon size="large" color="success" icon={checkmarkOutline}></IonIcon>:
                                    <IonIcon size="large" color="danger" icon={closeOutline}></IonIcon>
                                    }
                                </IonCol>
                                <IonCol size="2">
                                    {x.ok = 1?
                                    <IonIcon size="large" color="success" icon={checkmarkOutline}></IonIcon>:
                                    <IonIcon size="large" color="danger" icon={closeOutline}></IonIcon>
                                    }
                                </IonCol>
                            </IonRow>
                        )
                    })
                }
                </div>
        )}
        setShowLoading(false)
        showFileTest(true);
        setContinueButton(true)
        setFileTestResults(r);
        setExcelWorkSheet(false);
        setDelimiterOption(false);
        callDataIntegrity();
      })
      .catch(error=>{
        setShowLoading(false)
        alert(error)
      })
  }

 
  const resetLoadForm = () =>{
    //showPackage2(false)
    //showPackage113(false)
    //showPackage1163(false)
    setFileUpload(false)
    var c:any = <div></div>
    setExcelWorkSheet(c)
    setExcelWorkSheet(false)
    setImportButton(false)
    setDelimiterOption(false)
    setContinueButton(false)
    showFileTest(false)
}

const formValidation = (form:any) =>{
    var x:any = document.getElementsByClassName("check")
    switch(form){
        case 1: 
        if(x.length == 3){
            showContinue(true)
        }else{
            showContinue(false)
        }
        break;
    }
}

const [getDuplicate, setDuplicate]              = useState<string>("input-text-size-1-black")
const callDuplicateName = async () =>{
    let val:any=0;
    val = await ( fetch(props.state.primary_host+"/journals/dbconnect/select.jsp?etl=findduplicateprojectname"+
        "&name="+txtJournalProjectName.current!.value+
        "&contact_id="+props.state.user_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
    ) 
    .then(response => response.json())
    .then(d => {
        console.log(d)
        console.log(d.data[0].found)
        
        
        if(d.data[0].found){
            val =  1
        }else{
            val =  0
        } 
        return val
    })
    .catch(error=>{
        //console.log(error)
        return  val
    }))
    
}
    
const restoreJournalsDB = (restoreOnly:any) =>{
    setShowLoading(true)
    fetch(props.state.props.primary_host+'/dbconnect/dbdynamic.jsp?dbo=restorejournalsdb'+
        "&versionid="+getVersionId+
        "&filename="+getFileName+
        "&projectname="+props.project_name+
        "&createdby="+createdBy+
        "&packageid="+props.package_id+
        "&application_id="+applicationId+
        "&migration_id="+props.migration_id+
        "&restoreonly="+restoreOnly,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
    ).then(response => response.json())
    .then(data =>{
        setShowLoading(false)
        if(data.data[0].done == 1){
            if(fileRestoreId > 0){
                var x = fileRestoreId/1
                x++
                if(packageId == 112 || packageId == 113){
                    let isfile = 1
                    let smartactive = 0
                    var type = ""
                    switch(fileRestoreId/1){
                        case 1:type = "Opening TB";break;
                        case 2:type = "GL";break;
                        case 3:type = "Closing TB";break;
                        case 4:type = "COA";break;
                    }
                    console.log(migrationId)
                    console.log(packageId)
                    console.log(type)
                    console.log(fileRestoreId)
                    console.log(isfile)
                    var z:any = []
                    z.push({type:type,isfile:isfile, packageId:packageId})
                    props.validateFile(z[0])
                    setContinueButton(true)
                    setExcelWorkSheet(false);
                    setDelimiterOption(false);
                    setWorkbookName(glFileName)
                    if( props.package_id != -1)
                        props.gl_version_id(getVersionId)
                    if( props.package_id == -1)
                        props.version_id(getVersionId)

                    props.load_counter(fileRestoreId)
                    props.file_name(txtFileDummy.current.value)
                    setSelectorManualTop("ml-top-panel-up")
                }else{
                    //callTableObjectId(migrationId, fileRestoreId)
                    alert("File loaded successfully!")
                    x = x-1
                    if(x == 1){
                        //setJournals(13)
                        resetLoadForm();
                        //formNavigationController()
                    }
                    if(x == 2){
                        //setJournals(14)
                        resetLoadForm();
                        //formNavigationController()
                    }
                    if(x == 3){
                        //setJournals(15)
                        resetLoadForm();
                        //formNavigationController()
                    }
                    if(x == 4){
                        //setJournals(16)
                    }
                }
            }
        }else{
            alert("Database Restore Failed!")
        }
        
    })
    .catch(error =>{
        alert(error)
        setShowLoading(false)
    })
}

const setJournalFilter = (id:any) =>{
    for(var i = 0; i < trCodeList.length; i++){
        console.log("Before:"+trCodeList[i].id+" "+trCodeList[i].isChecked)
        if(trCodeList[i].id == id){
            trCodeList[i].isChecked = !trCodeList[i].isChecked;
        }
        console.log("After:"+trCodeList[i].id+" "+trCodeList[i].isChecked)
    }
    
    jnlFilter="";
    for(var i = 0 ; i < trCodeList.length; i++){
        if(trCodeList[i].isChecked && jnlFilter.length > 0){
            jnlFilter = jnlFilter + ",\"jnl_"+ trCodeList[i ].id+"\"";
        }
        if(trCodeList[i].isChecked && jnlFilter.length == 0){
            jnlFilter = "\"jnl_"+ trCodeList[i].id+"\"";
        }
        
    }
}
  const callVersion = async () =>{
    selectVersion(props.state.props)
    .then(response=>{return response})
    .then(data=>{
        setVersionId(data.version_id)
    })
  }
  const selectWorksheet = () =>{
    //setShowLoading(true)
    let y:any =<div></div>;
    setWorksheets(y);
    let z:any = <IonRow className="ion-padding"><IonCol className="ion-text-center"><IonSpinner name="dots"></IonSpinner></IonCol></IonRow>
    setWorksheets(z);
    let a:any = <IonSpinner name="dots"></IonSpinner>
    setLoader(a)
    let option:any = [];
    //fetch(props.state.primary_host+"/UploadIntermediate?"+ /*blob*/
    fetch(props.state.props.primary_host+'/select?etl=selectworksheets'+
    //"&filename="+ versionId+'-'+txtFileDummy.current.value+ /*bloc*/
    "&filename="+ txtFileDummy.current.value+
    "&migrationid="+props.migration_id+
    "&versionid="+getVersionId+
    "&packageid="+packageId+
    "&filerestoreid="+props.restore_id+
    "&source=F:/home/site/wwwroot/files/in/"+
    "&contactid="+props.state.props.user_id+
    "&delimiter=,"
    ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
    ) 
    .then(response => response.json())
    .then(d => {
        //setShowLoading(false)
        option.push(d.data) ;
       
        let ddlListItems = option[0].map((x:any, i:number) =>{
            let value = x.TABLE_NAME;
            let sheet = x.TABLE_NAME;
            return(
                <IonCol key={i} size="12" className="size-8" >
                    <IonRadio value={value}   name={"rdbWorksheet_"+i} id={"rdbWorksheet_"+fileRestoreId+"_"+i}></IonRadio>
                    &nbsp;<IonLabel>{sheet}</IonLabel>
                </IonCol>
            )
        })
        setWorksheets(ddlListItems);
        //setShowLoading(false)
        
        a = null;
        setLoader(a)
       // setShowLoading(false)
    })
    .catch(error=>{
        alert(error)
        let ddlListItems:any =(<IonRow className="ion-padding"><IonCol className="ion-text-center">No Worksheets Found</IonCol></IonRow>)
    setWorksheets(ddlListItems);
    setShowLoading(false)
    })
  }
  
    const uploadComplete = (evt:any) =>{
        //alert("complete")
        setShowLoading(false)
        if(extension == "xls" || extension == "xlsx"){
            selectWorksheet();
        }
        if(extension == "bak"){
            setDBRestore(true)
            restoreJournalsDB(1)
        }
    }
    const uploadFailed = (evt:any) =>{
        //progress1.current.value = 0;
        setShowLoading(false)
        alert("Upload Failed")
    }
    const uploadCanceled = (evt:any) =>{
        //progress1.current.value = 0;
        setShowLoading(false)
        alert("Upload Cancelled")
    }
    const uploadProgress = (evt:any) =>{
        if (evt.lengthComputable) {
            //progress1.current.value = Math.round(evt.loaded / evt.total);
        }else {  
            console.log(evt);
        }
    }
    const UploadFile = () =>{
        let e = txtFileDummy.current.value;
        let q = e.split('.')
        let ext =  q[1]
        extension = ext;
        setShowLoading(true)
        if ((extension == "xls" || extension == "xlsx")){
            const f = txtFile.current;
            console.log("******File*******:"+txtFileDummy.current.value)
            
            console.log("filerestoreid:"+fileRestoreId)
        
            glFileName = txtFileDummy.current.value;
            setFileName(glFileName)
            if(fileRestoreId == 2){
                console.log("setting gl file...")
                glFileName = txtFileDummy.current.value;
                glVersionId = versionId;
            }
            file = f;
            console.log("file:"+f.files)
            console.log("file-length:"+f.files.length)
            for(var i = 0 ; i < f.files.length; i++){
                let fd: FormData = new FormData();
                var xhr = new XMLHttpRequest();
                console.log("file"+ f.files[i]);
                console.log("versionid"+getVersionId);
                console.log("migrationid"+props.migration_id);
                console.log("contactid"+props.state.props.user_id);
                console.log("packageid"+packageId);
                console.log("filerestoreid"+props.restore_id);
                console.log("application"+applicationId);
                fd.append("file", f.files[i]);
                fd.append("versionid",getVersionId);
                fd.append("migrationid",props.migration_id);
                fd.append("contactid",props.state.props.user_id);
                fd.append("packageid",packageId);
                fd.append("filerestoreid",props.restore_id);
                fd.append("application",applicationId);
                xhr.addEventListener("progress", uploadProgress, false);
                xhr.addEventListener("load", uploadComplete, false);
                xhr.addEventListener("error", uploadFailed, false);
                xhr.addEventListener("abort", uploadCanceled, false);
                xhr.open("POST", props.state.props.primary_host+"UploadFile");
                console.log("sending...")
                xhr.send(fd);
                console.log("sent")
            }
            
            console.log("extension:"+ext)
            if(ext == "xls" || ext == "xlsx"){
                setExcelWorkSheet(true)
            }else{
                setDelimiterOption(true)
            }
        }else{
            if(packageId != 112 && packageId != 113){
                UploadTextFile()
            }else{
                UploadDatabase()
            }
        }
    }
    const UploadDatabase = () =>{
        setShowLoading(true)
        packageId = props.package_id;
        const f = txtFile.current;
        console.log("******File Database Backup*******:"+txtFileDummy.current.value)
        
        console.log("filerestoreid:"+fileRestoreId)
       
        glFileName = txtFileDummy.current.value;
        setFileName(glFileName)
        if(fileRestoreId == 2 ){
            console.log("setting gl file...")
            glFileName = txtFileDummy.current.value;
            glVersionId = versionId;
        }
        file = f;
        console.log("file:"+f.files)
        console.log("file-length:"+f.files.length)
        for(var i = 0 ; i < f.files.length; i++){
            let fd: FormData = new FormData();
            var xhr = new XMLHttpRequest();
            console.log("file"+ f.files[i]);
            console.log("versionid"+getVersionId);
            console.log("migrationid"+props.migration_id);
            console.log("contactid"+props.state.props.user_id);
            console.log("packageid"+packageId);
            console.log("filerestoreid"+props.restore_id);
            console.log("application"+applicationId);
            fd.append("file", f.files[i]);
            fd.append("name", getVersionId+'-'+glFileName);
            fd.append("file_name", glFileName);
            fd.append("versionid",getVersionId);
            fd.append("migrationid",props.migration_id);
            fd.append("contactid",props.state.props.user_id);
            fd.append("packageid",packageId);
            fd.append("filerestoreid",props.restore_id);
            fd.append("application",applicationId);
            xhr.addEventListener("progress", uploadProgress, false);
            xhr.addEventListener("load", uploadComplete, false);
            xhr.addEventListener("error", uploadFailed, false);
            xhr.addEventListener("abort", uploadCanceled, false);
            xhr.open("POST", props.state.props.primary_host+"dbconnect/document_upload3.jsp");
            console.log("sending...")
            xhr.send(fd);
            console.log("sent")
        }
        let e = txtFileDummy.current.value;
        let q = e.split('.')
        let ext =  q[1]
        extension = ext;
        console.log("extension:"+ext)
        
    }
    const UploadTextFile = () =>{
        
        setShowLoading(true)
        const f = txtFile.current;
        console.log("******File*******:"+txtFileDummy.current.value)
        
        console.log("filerestoreid:"+fileRestoreId)
       
        glFileName = txtFileDummy.current.value;
        setFileName(glFileName)
        if(fileRestoreId == 2 ){
            console.log("setting gl file...")
            glFileName = txtFileDummy.current.value;
            glVersionId = versionId;
        }
        file = f;
        console.log("file:"+f.files)
        console.log("file-length:"+f.files.length)
        for(var i = 0 ; i < f.files.length; i++){
            let fd: FormData = new FormData();
            var xhr = new XMLHttpRequest();
            console.log("file"+ f.files[i]);
            console.log("versionid"+getVersionId);
            console.log("migrationid"+props.migration_id);
            console.log("contactid"+props.state.props.user_id);
            console.log("packageid"+packageId);
            console.log("filerestoreid"+props.restore_id);
            console.log("application"+applicationId);
            fd.append("file", f.files[i]);
            fd.append("file_name", glFileName);
            fd.append("versionid",getVersionId);
            fd.append("migrationid",props.migration_id);
            fd.append("contactid",props.state.props.user_id);
            fd.append("packageid",packageId);
            fd.append("filerestoreid",props.restore_id);
            fd.append("application",applicationId);
            xhr.addEventListener("progress", uploadProgress, false);
            xhr.addEventListener("load", uploadComplete, false);
            xhr.addEventListener("error", uploadFailed, false);
            xhr.addEventListener("abort", uploadCanceled, false);
            xhr.open("POST", props.state.props.primary_host+"dbconnect/document_upload3.jsp");
            console.log("sending...")
            xhr.send(fd);
            console.log("sent")
        }
        let e = txtFileDummy.current.value;
        let q = e.split('.')
        let ext =  q[1]
        extension = ext;
        console.log("extension:"+ext)
        if(ext == "xls" || ext == "xlsx"){
            setExcelWorkSheet(true)
        }else{
            setDelimiterOption(true)
        }
    }

    const ddlExistingReviews:any                            = useRef<HTMLIonSelectElement>()
    const [getSelectorManualTop, setSelectorManualTop]      = useState<any>("ml-top-panel-up")
    React.useEffect(() => {
        //callExistingReviews()
        
        packageId = props.package_id
        console.log(props) 
        callVersion()
        setProjectName(props.migration_name)
        //if(props.restore_db == getRestoreDBId){
        //    setRestoreDBId(props.restore_db)
        //    restoreJournalsDB(props.restore_db)
        //}
    }, []);
    return (
        <div className="upload-form-left-division load-settings size-14 ion-padding">
            <div  className={getSelectorManualTop+' ion-padding background-white text-black'}>
                <IonRow className="row-divider-bottom">
                    <IonCol size="11" className="ion-text-left size-20">
                        Manual Upload Instructions
                    </IonCol>
                    <IonCol size="1" className="ion-text-right">
                        <IonIcon icon={close} className="size-32" onClick={()=>{setSelectorManualTop("ml-top-panel-up")}}></IonIcon>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>Use FTP program, upload the file renamed as:-<br/> <b>{getVersionId+"-"+getFileName}</b></IonCol>
                </IonRow>
                <IonRow className="ion-padding">
                    <IonCol>
                      FTP Access Credentials<br/>
                      Server    : <b>154.0.169.10</b><br/>
                      User      : <b>upload</b><br/>
                      Password  : <b>mciTAR32!~</b>
                    </IonCol>
                </IonRow>
                <IonRow className="row-divider-top ion-padding">
                    <IonCol size="9" className="ion-text-left">When File is completed uploading please click continue</IonCol>
                    <IonCol size="3" className="ion-text-right">
                      <div  onClick={()=>{restoreJournalsDB(1)}} className="custom-account-text-icon"> Continue</div>
                    </IonCol>
                </IonRow>
            </div>
            <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'} ></IonLoading>
            <IonRow className="">
                {/*<IonCol size="1" className="ion-text-center">
                    <IonIcon icon={documentOutline} color="" className='size-20 text-gt'></IonIcon>
                </IonCol>*/}
            
                <IonCol size="12" className='ion-text-center ion-padding'>
                    <IonRow>
                        <IonCol className="ion-text-left">
                            <IonLabel className="size-16">{props.form_name}</IonLabel>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <IonLabel className="size-16">{getWorkbookName}</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            {/*<IonLabel className="size-12" position="floating">Select File To Upload</IonLabel><br/>*/}
                            <span>
                                <div className="fileUpload-ar" >
                                    <span>File</span>
                                    <input type="file" color="primary" className="upload-ar size-12"  name="file" ref={txtFile} multiple 
                                        onChange={e =>{
                                            var g = txtFile.current.value.split("\\")
                                            txtFileDummy.current.value = g[g.length-1]
                                            setFileUpload(true)
                                            setFileName(txtFileDummy.current.value)
                                        }
                                    } 
                                    />
                                </div>
                                <input className="upload-selected-ar" placeholder="Selected Files" disabled={true} ref={txtFileDummy} />
                            </span>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
            {getFileUpload &&   
            <div>
                <IonRow className="">                     
                    <IonCol className="ion-text-center">
                        {(packageId == 112 || packageId == 113 )&& <IonLabel>File under 2GB</IonLabel>}<br/>
                        <IonButton className="" onClick={e =>{UploadFile()}}><IonIcon icon={cloudUpload}></IonIcon>&nbsp;Upload File</IonButton>
                    </IonCol>
                    {(packageId == 112 || packageId == 113 || (extension != 'xls' && extension == 'xlsx'))&&
                    <IonCol className="ion-text-center">
                        <IonLabel>File over 2GB</IonLabel><br/>
                        <IonButton className="" onClick={ev=>{
                            setSelectorManualTop("ml-top-panel-down")
                            //var x:any = [];
                            //x.push({version_id:getVersionId, file_name:txtFileDummy.current.value})
                            //set
                            //props.load_manual(x[0])
                            
                        }}><IonIcon icon={refreshCircleOutline}></IonIcon>&nbsp;Manual Import</IonButton>
                    </IonCol>
                    }
                </IonRow>
                {/*<IonRow>&nbsp;</IonRow>
                <IonRow>&nbsp;</IonRow>
                <IonRow className="ion-padding">
                    <IonCol>
                        <IonProgressBar ref={progress1} value={0.01}></IonProgressBar>
                    </IonCol>
            </IonRow>*/}
            </div>
            }
            {getExcelWorksheet &&
            <div className="size-12">
                <IonRow>
                    <IonCol size="10">
                        <IonLabel className=""><b>Select Worksheet</b></IonLabel>
                    </IonCol>
                    <IonCol size="2">
                        {getLoader}
                    </IonCol>
                </IonRow>
                <IonRadioGroup radioGroup={'worksheets'+fileRestoreId} ref={ddlWorksheet} onClick={ev=>{blobId = worksheet = ddlWorksheet.current.value;setImportButton(true); console.log(worksheet)}}>
                    <IonRow>
                        {getWorksheets}
                    </IonRow>
                </IonRadioGroup>
                {getImportButton &&
                <div>
                    <IonRow>
                        <IonCol className="ion-text-right">
                            <IonButton className="button-width-max" onClick={ev=>{loadFile()}}><IonIcon icon={refreshCircleOutline}></IonIcon>&nbsp;Import</IonButton>
                        </IonCol>
                        
                    </IonRow>
                </div>
                }
            </div>
            }
            {getDelimiterOption &&
            <div>
                <IonRow>
                    <IonCol size="10">
                        <IonItem>
                            <IonLabel className=""><b>Select Delimiter</b></IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol size="2">
                        {getLoader}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonSelect ref={ddlTextQualifier} placeholder="Select Text Qualifier" onIonChange={ev=>{
                            textqual= ddlTextQualifier.current!.value;
                            if(ddlTextQualifier.current.value != null && ddlTextQualifier.current.value != null)
                                setImportButton(true)
                            }}>
                            <IonSelectOption value=''>None</IonSelectOption>
                            <IonSelectOption value='"'>" Double Quotation Mark</IonSelectOption>
                            <IonSelectOption value="'">' Single Quotation Mark</IonSelectOption>
                        </IonSelect>                     
                    </IonCol>
                    <IonCol>
                        <IonSelect ref={ddlDelimiter} placeholder="Select Field Delimiter" onIonChange={ev=>{
                            if(ddlDelimiter.current!.value == "custom"){
                                alert("custom delimeter")
                            } 
                            delimiter = ddlDelimiter.current!.value
                            if(ddlTextQualifier.current.value != null && ddlTextQualifier.current.value != null)
                                setImportButton(true)
                            }}>
                            <IonSelectOption value=','>, Comma</IonSelectOption>
                            <IonSelectOption value='|'>| Pipe</IonSelectOption>
                            <IonSelectOption value='tab'>Tab</IonSelectOption>
                            <IonSelectOption value="custom">Custom</IonSelectOption>
                        </IonSelect>                     
                    </IonCol>
                </IonRow>
                {getImportButton &&
                <IonRow>
                    <IonCol className="ion-text-right">
                        <IonButton onClick={ev=>{loadFile()}}><IonIcon icon={refreshCircleOutline}></IonIcon>&nbsp;Import</IonButton>
                    </IonCol>
                    
                </IonRow>
                }
            </div>
            }
            {getContinueButton &&
            /*<IonRow className="ion-padding">
                <IonCol size="3">restoreJournalsDB
                    <IonButton color="danger" onClick={e => {callVersion();resetLoadForm() }}>Start Again</IonButton>
                </IonCol>
                <IonCol size="6"></IonCol>
                <IonCol size="3">
                    <IonButton color="primary" onClick={e => {callVersion();}}>Continue</IonButton>
                </IonCol>
            </IonRow>
            */
           <IonRow className="ion-text-center">
                <IonCol>
                    <IonIcon icon={checkmark} className="size-42 text-green" />
                </IonCol>
           </IonRow>
            }
        </div>
    );
  };


export default FileLoader; 
