import { IonSearchbar, IonLoading, IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSelect, IonSelectOption, IonSpinner, IonThumbnail, IonTitle, IonToolbar, IonTextarea, IonPopover, IonCheckbox } from '@ionic/react';
import { apps,starOutline, ellipsisVerticalOutline, businessOutline, checkmarkOutline, analyticsOutline, analyticsSharp, arrowBackSharp, cloudOutline, documentOutline, filterSharp, gridOutline, homeOutline, list, listOutline, logInOutline, logOutOutline, menuOutline, personOutline, pulseOutline, settingsOutline, wifiOutline, trashBin, trashBinOutline, checkmarkCircle, closeCircle, refreshCircle, caretUp, caretDown, pulseSharp, pulse, notifications, notificationsCircle, pencilSharp, closeOutline, trashOutline, star, arrowForwardCircleOutline, print, chevronForward, chevronDown, searchOutline, videocamOutline, codeDownload, clipboardOutline, logoGoogle, logoGooglePlaystore, close } from 'ionicons/icons';
import React,{ useRef, useState, Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import { callLogin } from '../../dbconnections/Select';
import  './Login.css';

//import {getServers} from '../Home_20220422'

const Login = (props:any) =>{
    //primaryhost:any //= getServers().primaryhost;
    //host:any //= getServers().host;
    console.log(props)
    let userId:any = 0;
    let forename:any = "";
    let surname:any = "";
    let email:any = "";
    let initial:any = "";
    let txtEmail:any                                      = useRef<HTMLIonInputElement>(null);
    let txtPassword:any                                   = useRef<HTMLIonInputElement>(null);
    //const data:any = [];
    const setLogin = () =>{
      var l:any = []
      l.push({props:props.state, email:txtEmail.current.value, password:txtPassword.current.value})
      callLogin(l[0])
      .then(response=>{console.log(response);return response})
      .then(data=>{
        props.logged_in(data)
        if(data.user_id == 0){
          loginMessage()
        }
      })
      //  fetch(props.state.primary_host+"Controller/assets/login/dbconnect/select/dbconnect.jsp?analytics=selectlogin"+
      //  "&email="+txtEmail.current!.value+
      //  "&password="+txtPassword.current!.value
      //  ,
      //    {
      //      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      //    }
      //  )
      //  .then(response => response.json())
      //  .then(data =>{
      //      console.log(data.data[0])
      //    if(data.data.length > 0){
      //      console.log("step 1")
      //      if(data.data[0].success/1 == 1){
      //          console.log("step 2")
      //          userId = data.data[0].contactid;
      //          forename = data.data[0].forename;
      //          surname = data.data[0].surname;
      //          email = data.data[0].email;
      //          initial = data.data[0].initial;
      //          console.log("step 3")
      //          var z:any =[];
      //          z.push({user_id:userId, forname:forename, surname:surname, email:email, initial:initial, set_view:1})
      //          console.log(z[0])
      //          props.logged_in(z[0])
      //          //childToParent=data
      //          //setPageView(1);
      //          //showReviewsTop(true); 
      //      }
      //    }else{
      //      //setPageView(0);
      //    }
      //  })
      //  .catch(error =>{
      //    userId = 4;
      //    forename = 'Alvin';
      //    surname = 'Musengezi';
      //    email = 'alvin.musengezi@yauro.co.za';
      //    initial = 'AM';
      //    console.log('failed')
      //    //childToParent='failed'
      //    //setPageView(1);
      //    //showReviewsTop(true); 
      //  })
      //}
     /* const SubscriptionButtonWrapper = () => {
        return (<PayPalButtons
          createSubscription={(data, actions) => {
            return actions.subscription
              .create({
                plan_id: "P-28J600857G994393NMJOVJEY",
              });
          }}
          style={{
            label: "subscribe",
          }}
        />);*/
    }
      
      
      
    const  responseGoogle = (response:any) => {
        console.log(response.profileObj);
        for(prop in response.profileObj){
          console.log(prop)
        }
        var email:any = response.profileObj.email;
        var forename:any = response.profileObj.givenName;
        var surname:any = response.profileObj.familyName;
        var googleId:any = response.profileObj.googleId;
        var imageUrl:any = response.profileObj.imageUrl;
        console.log(email)
        var prop:any;
        fetch(props.state.primary_host+"select?etl=selectgooglecontact"+
          "&title="+
          "&industry="+
          "&location="+
          "&company="+
          "&emailaddress="+email+
          "&forename="+forename+
          "&surname="+surname+
          "&imageurl="+imageUrl+
          "&id="+googleId,
          {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
          }
        )
        .then(response => response.json())
        .then(data =>{
          console.log(data)
          //setSignIn(false)
          //setPayPalPayment(true)
        })
      }
    const [hideTerms, showTerms]                              = useState<any>("terms-up")
    const [hideSignIn, showSignIn]                            = useState<boolean>(false)
    const [hideLoginFailure, showLoginFailure]                = useState<any>("login-up")
    const loginMessage = () =>{
      showLoginFailure("login-down")
      setTimeout(()=>{showLoginFailure("login-up")}, 3000)
    }
    return(
      <div>
        <div className={hideLoginFailure+" size-14 ion-padding background-dark-red text-white"}>
          <IonRow>
            <IonCol>Login Failed</IonCol>
            <IonCol size="1">
              {/*<IonIcon icon={close}  onClick={()=>{showLoginFailure("login-up")}}  className="ion-text-right size-20" ></IonIcon>*/}
            </IonCol>
          </IonRow>
          <IonRow className="">
            <IonCol>Please check your email & password</IonCol>
          </IonRow>
        </div>
        <div className={hideTerms+" size-14 ion-padding background-white"}>
          <IonRow className="row-divider-bottom">
            <IonCol className="size-20"><b>Terms of Use</b></IonCol>
            <IonCol size="1" className="ion-text-right">
              <IonIcon onClick={()=>{showTerms("terms-up")}} icon={close} className="size-20 hover"></IonIcon>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonRow><IonCol>
                Welcome to Yauro Software’s terms of use. We’ve done our best to explain our terms without using too many legal terms and jargon. It's long but please carefully and thoroughly read this whole document, because it sets out your rights (and ours). Our terms of use apply to the use of this Website and by accessing this Website and/or using the Services you agree to be bound by the terms set out below. If you do not agree to be bound by these terms you may not use or access the Website. If you have any queries about these terms and conditions, please email us at enquires@yauro.co.za
            </IonCol></IonRow>
            <IonRow><IonCol><b>1. DEFINITIONS</b></IonCol></IonRow>
            <IonRow><IonCol>
                “Analytical Software” means any cloud- or desktop-based analytical software, such as DMZ.
                "Agreement" means these Terms of Use.
                "Confidential Information" includes all information exchanged between the parties to this Agreement, whether in writing, electronically or orally, including the Software but does not include information which is, or becomes, publicly available other than through unauthorised disclosure by the other party.
                "Data" means any data in the Software.
                "Intellectual Property Right" means any patent, trade mark, service mark, copyright, moral right, right in a design, know-how and any other intellectual or industrial property rights, anywhere in the world whether or not registered.
                "Software" means the software available (as may be changed or updated from time to time by Yauro) via the Website.
                “Yauro” means Yauro Software a registered South African company residing at 7 Marga Court, 43 Susman Ave, Blairgowrie.
                "Yauro Software" means Yauro.
                "Subscriber" means the person who registers to use the Software, and, where the context permits, includes any entity on whose behalf that person registers to use the Software.
                "You" means the Subscriber.
                "Your" has a corresponding meaning.
                "Website" means the Internet site at the domain www.dmzee.co.za or any other site operated by Yauro.
            </IonCol></IonRow>
            <IonRow><IonCol><b>2. USE OF SOFTWARE</b></IonCol></IonRow>
            <IonRow><IonCol>
                Yauro grants You the right to access and use the Software via the Website. This right is non-exclusive and non-transferable and limited by these Terms. You acknowledge that accessibility to any company’s financial data via the Software is set through the company’s respective Analytical Software.
            </IonCol></IonRow>
            <IonRow><IonCol><b>3. YOUR OBLIGATIONS</b></IonCol></IonRow>
            <IonRow><IonCol>
                You must only use the Software and Website for Your own lawful business purposes, in accordance with these Terms and any notice sent by Yauro or condition posted on the Website. You may use the Software and Website on behalf of others or in order to provide services to others but if You do so You must ensure that You are authorised to do so and that all persons for whom or to whom services are provided comply with and accept all terms of this Agreement that apply to You.
                You must ensure that all usernames and passwords required to access the Software are kept secure and confidential. You will immediately notify Yauro of any unauthorised use of Your passwords or any other breach of security and Yauro will reset Your password. You must take all other actions that Yauro reasonably deems necessary to maintain or enhance the security of Yauro's computing systems and networks and Your access to the Software. As a condition of these Terms, when accessing and using the Software, You must: not attempt to undermine the security or integrity of Yauro Analytics’ computing systems or networks or, where the Software is hosted by a third party, that third party's computing systems and networks; not use, or misuse, the Software in any way which may impair the functionality of the Software or Website, or other systems used to deliver the Software or impair the ability of any other user to use the Software or Website; not attempt to gain unauthorised access to any materials other than those to which You have been given express permission to access or to the computer system on which the Software is hosted; not transmit, or input into the Software, any: files that may damage any other person's computing devices or software, content that may be offensive, or material or Data in violation of any law (including Data or other material protected by copyright or trade secrets which You do not have the right to use); and not attempt to modify, copy, adapt, reproduce, disassemble, decompile or reverse engineer the Software or the Website except as is strictly necessary to use either of them for normal operation.
                By using any third-party functionality within the Software You confirm that You have read and accept the third-party’s terms and conditions. Use of the Software may be subject to limitations. Any such limitations will be advised. You indemnify Yauro against all claims, costs, damage and loss arising from Your breach of any of these terms or any obligation You may have to Yauro.
            </IonCol></IonRow>
            <IonRow><IonCol><b>4. CONFIDENTIALITY AND PRIVACY</b></IonCol></IonRow>
            <IonRow><IonCol>
                Unless the relevant party has the prior written consent of the other or unless required to do so by law: Each party will preserve the confidentiality of all Confidential Information of the other obtained in connection with these Terms. Neither party will, without the prior written consent of the other, disclose or make any Confidential Information available to any person, or use the same for its own benefit, other than as contemplated by these Terms. Each party's obligations under this clause will survive termination of these Terms. You expressly give Yauro the right to use Your information and Your Data to resolve Your support issues and/or queries and produce aggregated reports.
                Yauro maintains a privacy policy that sets out the parties’ obligations in respect of personal and company information. You should read the Privacy Policy and You will be taken to have accepted that policy when You accept these Terms.
            </IonCol></IonRow>
            <IonRow><IonCol><b>5. INTELLECTUAL PROPERTY</b></IonCol></IonRow>
            <IonRow><IonCol>
                Title to, and all Intellectual Property Rights in the Software, the Website and any documentation relating to the Software remain the property of Yauro (or its licensors). Title to, and all Intellectual Property Rights in, the Data remain Your property. You grant Yauro a licence to use, copy, transmit, store, and back-up Your information and Data for the purposes of enabling You to access and use the Software and for any other purpose related to provision of services to You and the industry.
                Yauro adheres to its best practice policies and procedures to prevent data loss, including a daily system data back-up regime, but does not make any guarantees that there will be no loss of Data. Yauro expressly excludes liability for any loss of Data no matter how caused.
                If You use third-party applications for use in conjunction with the Software, You acknowledge that Yauro may allow the providers of those third-party applications to access Your Data as required for the interoperation of such third-party applications with the Software. Yauro shall not be responsible for any disclosure, modification or deletion of Your Data resulting from any such access by third-party application providers.
            </IonCol></IonRow>
            <IonRow><IonCol><b>6. WARRANTIES AND ACKNOWLEDGEMENTS</b></IonCol></IonRow>
            <IonRow><IonCol>
                You warrant that where You have registered to use the Software on behalf of another person, You have the authority to agree to these Terms on behalf of that person and agree that by registering to use the Software You bind the person on whose behalf You act to the performance of any and all obligations that You become subject to by virtue of these Terms, without limiting Your own personal obligations under these Terms.
                You acknowledge that: You are authorised to use the Software and the Website and to access the information that You access using the Software and the Website (whether that information is Your own or that of anyone else). If You are using the Software and accessing the Website on behalf of or for the benefit of an organisation then Yauro will assume that You have the right to do so and that organisation will be liable for Your actions or omissions (including any breach of these Terms).
                Yauro has no responsibility to any person other than You and nothing in this Agreement confers, or purports to confer, a benefit on any person other than You. If You use the Software or access the Website on behalf of or for the benefit of anyone other than Yourself, You agree that: You are responsible for ensuring that You have the right to do so; You are responsible for authorising any person who is given access to information or Data, and You agree that Yauro has no obligation to provide any person access to such information or Data without Your authorisation and may refer any requests for information to You; and You will indemnify Yauro against any claims or loss relating to: Yauro's refusal to provide any person access to Your information or Data in accordance with these Terms, and Yauro making available information or Data to any person with Your authorisation.
                The provision of, access to, and use of, the Software is on an "as is, where is" basis and at Your own risk. Yauro does not warrant that the use of the Software will be uninterrupted or error free. Among other things, Your classification of items, suppliers, accounts, customers, and/or invoices, in your chosen Analytical Software will impact the reporting provided by Yauro. In addition, the operation and availability of the systems used for accessing the Software, including public telephone services, computer networks and the Internet, can be unpredictable and may from time to time interfere with or prevent access to the Software. Yauro is not in any way responsible for any such interference or prevention of Your access or use of the Software.
                . It is Your sole responsibility to determine that the Software meets the needs of Your business. You remain solely responsible for complying with all applicable accounting, tax and other laws. It is Your responsibility to check that storage of and access to Your Data via the Software and the Website will comply with laws applicable to You (including any laws requiring You to retain records).
                Yauro gives no warranty about the Software. Without limiting the foregoing, Yauro does not warrant that the Software will meet Your requirements or that it will be suitable for Your purposes. To avoid doubt, all implied conditions or warranties are excluded in so far as is permitted by law, including (without limitation) warranties of merchantability, fitness for purpose, title and non-infringement.
                You warrant and represent that You are acquiring the right to access and use the Software and agreeing to these Terms for the purposes of a business and that, to the maximum extent permitted by law, any statutory consumer guarantees or legislation intended to protect non-business consumers in any jurisdiction does not apply to the supply of the Software, the website or these Terms.
    
            </IonCol></IonRow>
            <IonRow><IonCol><b>7. LIMITATION OF LIABILITY</b></IonCol></IonRow>
            <IonRow><IonCol>
                To the maximum extent permitted by law, Yauro excludes all liability and responsibility to You (or any other person) in contract, tort (including negligence), or otherwise, for any loss (including loss of Data, profits and savings) or damage resulting, directly or indirectly, from the use of, or reliance on, the Software.
                You acknowledge that Yauro will accept no liability in the event a company has incorrectly set employee/user access in its Accounting Software and employees/users are then able to access confidential financial information via the Software.
                If You are not satisfied with the Software, Your sole and exclusive remedy is to terminate these Terms.
            </IonCol></IonRow><IonRow><IonCol><b>8. TERMINATION</b></IonCol></IonRow>
            <IonRow><IonCol>
                You may terminate the use of Yauro’s services at any time by giving a month’s notice in writing.
            </IonCol></IonRow><IonRow><IonCol><b>9. HELP DESK</b></IonCol></IonRow>
            <IonRow><IonCol>
                If You need technical help, please check the support provided online by Yauro or failing that email us at enquiries@yauro.co.za
                Whilst Yauro intends that the Software should be available 24 hours a day, seven days a week, it is possible that on occasion it may be unavailable to permit maintenance or other development activity to take place. If for any reason interruptions in the service last for longer periods than Yauro would normally expect, we will use reasonable endeavour to publish in advance details of such activity on the Website.
    
            </IonCol></IonRow><IonRow><IonCol><b>10. GENERAL</b></IonCol></IonRow>
            <IonRow><IonCol>
                These Terms, together with the terms of any other notices or instructions given to You under these terms, supersede and extinguish all prior agreements, representations (whether oral or written), and understandings and constitute the entire agreement between You and Yauro relating to the Software and the other matters dealt with in these Terms.
                If either party waives any breach of these Terms, this will not constitute a waiver of any other breach. No waiver will be effective unless made in writing. Neither party will be liable for any delay or failure in performance of its obligations under these Terms if the delay or failure is due to any cause outside its reasonable control. You may not assign or transfer any rights to any other person without Yauro’s prior written consent.
                This Agreement and any disputes or claims arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with the laws of South Africa.
                If any part or provision of these Terms is invalid, unenforceable or in conflict with the law, that part or provision is replaced with a provision which, as far as possible, accomplishes the original purpose of that part or provision.
                Any notice given under these Terms by either party to the other must be in writing by email and will be deemed to have been given on transmission. Notices to Yauro must be sent to enquiries@yauro.co.za or to any other email address notified by email to You by Yauro. Notices to You will be sent to the email address You provided when setting up Your access to the Software. A person who is not a party to these Terms has no right to benefit under or to enforce any term of these Terms.
    
            </IonCol></IonRow><IonRow><IonCol><b>Privacy Policy</b></IonCol></IonRow>
            <IonRow><IonCol>
                Yauro knows you value your privacy and the privacy of your company. By using Yaruo (the website) you agree to our Privacy Policy below.
                We only collect what we need
                Yauro collects information about you in a few ways. As a customer, you submit information to us such as your name, email address, physical address, and mobile number. We only ask for information that we need to make our service the best it can be. You'll know we have this information because either you will give it to us directly or you'll expressly authorise someone else to give it to us (such as your accounting software provider).
                We also passively gather basic web-browsing data which is used to provide us with reporting about where our visitors are coming from, what platforms they're using, and how we can better serve up Yauro. This includes things like your IP address, ISP, internet browser, and certain system settings that are generally made available to any website you visit. This data is aggregated and anonymised.
            </IonCol></IonRow>
            <IonRow><IonCol><b>We don’t share</b></IonCol></IonRow>
            <IonRow><IonCol>
                We don’t permit other companies to use any information about you in a way that we wouldn't. If we share any information about you and your company with third parties, it is always aggregated and anonymised.
                If we sell Yauro or if we merge with another company, the transaction will involve the sale of customer information. Under those circumstances, the acquirer of Yauro will be bound by this Policy.
            </IonCol></IonRow>
            <IonRow><IonCol><b>Clear and Transparent</b></IonCol></IonRow>
            <IonRow><IonCol>
                All of the rules of using Yauro posted on our website. This Policy and our Terms of Use are meant to be clear (if they aren’t, let us know). Your use of Yauro is governed by this Policy and our Terms of Use.
                You might see links to Yauro on other websites but we can't assure you they'll respect your privacy. If you see abusive use of Yauro(the website) or our name on other websites, please let us know as soon as possible at enquiries@yauro.co.za.
                We might change this Privacy Policy if we get lots of questions that indicate something is unclear. The copy of the Policy in place at the time we use a given piece of information is what controls the use of that information. If we change this Policy, we'll let you know by posting an announcement or sending you an email.
                By using Yauro, you agree that we can send you important notices by email and/or by posting them to our site. If there is a legal requirement that something be given to you in writing, our electronic communication (site or email) meets that requirement.
                Queries?
                If you have any questions or concerns regarding privacy, please send us a detailed message at enquiries@yauro.co.za. We will make every effort to resolve your concerns.
            </IonCol></IonRow>
          </IonRow>
            <IonRow className="row-divider-top">
              <IonCol></IonCol>
              <IonCol className="ion-text-right">
                <IonButton onClick={()=>{showTerms("terms-up")}}>Close</IonButton>
              </IonCol>
            </IonRow>
        </div>
        <div className="landing-image-main">
          <div className="login-card">
            <IonRow>
                <IonCol size="4"></IonCol>
                <IonCol size="4" className="ion-padding ion-text-center">
                    <IonImg src="../../../assets/images/dmzee_logo-01.png" />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="3"></IonCol>
                <IonCol size="6" className="ion-padding ion-text-center">
                  
                </IonCol>
                <IonCol size="12" className="ion-text-center">Analytical Reviews</IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="size-20 icon-gt ion-text-center">
                <IonIcon icon={personOutline} className="size-20"></IonIcon>
                &nbsp;Sign In
              </IonCol>
            </IonRow>
            <IonRow>
            <IonCol className="size-12 ion-text-center">
                <IonCheckbox onIonChange={(e)=>{showSignIn(e.detail.checked)}}></IonCheckbox>&nbsp;
                <IonLabel  className="size-12 summary-account"  onClick={()=>{showTerms("terms-down")}}>Accept Terms Of Service</IonLabel>
              </IonCol>
            </IonRow>
            {hideSignIn &&
            <div>
            <IonRow>
              <IonCol className="login-box">
                <IonLabel position='floating'>Email</IonLabel>
                <IonInput className="input-style-1 text-black" ref={txtEmail} type="text"></IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="login-box">
                <IonLabel>Password</IonLabel>
                <IonInput className="input-style-1 text-black" ref={txtPassword} type="password"></IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-right">
                <IonButton onClick={()=>{setLogin()}}>Login</IonButton>
              </IonCol>
            </IonRow>
            <IonRow></IonRow>
            
            <IonRow className="size-20 ion-text-center icon-gt">
              <IonCol>Or Sign In With</IonCol>
            </IonRow>
            <IonRow>&nbsp;</IonRow>
            <IonRow></IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <GoogleLogin
                  clientId={'779619641732-bpho9hqmui661fqmubp4fgigfnvav8ah.apps.googleusercontent.com'}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  className="google-btn size-36"
                >
                  <IonIcon icon={logoGooglePlaystore} />
                  <span className="size-14"> Login with Google</span>
                </GoogleLogin>
              </IonCol> 
            </IonRow> 
            </div>
            }
            <IonRow>
              <IonCol size="12">&nbsp;</IonCol>
            </IonRow>
            <IonRow>
                <IonCol  className="ion-padding ion-text-center">
                  <IonImg src="../../../assets/images/pkf.jpeg" />
                </IonCol>
                {/*<IonCol size="3" className="ion-padding ion-text-center">
                  <IonImg src="../../../assets/images/BDO_logo.webp" />
                </IonCol>*/}
                <IonCol  className="ion-padding ion-text-center">
                  <IonImg src="../../../assets/images/deloitte_logo.png" />
                </IonCol>
                <IonCol  className="ion-padding ion-text-center">
                  <IonImg src="../../../assets/images/gt_logo.png" />
                </IonCol>
                
            </IonRow>
          </div>
        </div>
      </div>
    )
}
export default Login;