import { IonCol, IonIcon, IonItem, IonLabel, IonLoading, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { businessOutline, shareOutline, trashOutline} from 'ionicons/icons';
import React, { useState,useEffect } from 'react';
import { callAnalyticalReviews, callJournalProjects } from '../../dbconnections/Select';

const SelectJETSProjects = (props:any) =>{
    const [getShowLoading, setShowLoading] = useState<any>()
    var spinner:any = <IonLoading onWillDismiss={() => setShowLoading(false)} 
                        isOpen={getShowLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'} ></IonLoading>

    const [getLiveConnectionsListData, setLiveConnectionsListData]      = useState<any>()
    const [getState, setState]      = useState<any>()
    const [getLiveConnectionsList, setLiveConnectionsList] = useState<any>()
    const callLiveConnections = () =>{
        setShowLoading(true)
        var list : any  = []
        callJournalProjects(props.props)
        .then(response=>{return response})
        .then(data=>{
            setShowLoading(false)
            list.push(data)
            var sideList:any = list[0].map((x:any,i:number)=>{
                return( 
                    <IonSelectOption key={i} value={x.id} >
                        {x.name}
                    </IonSelectOption>
                )
            })
            setLiveConnectionsList(sideList)

        })
    }
    React.useEffect(() => {
        callLiveConnections()    
    }, []);
    return (
        <div>
            
            <IonItem>
                <IonLabel position='floating'>JETs Projects</IonLabel>
                <IonSelect onIonChange={(e:any)=>{props.migration_id(e.target.value)}}>
                    <IonLoading onWillDismiss={() => setShowLoading(false)} 
                            isOpen={getShowLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'Loading...'} ></IonLoading>
                    {getLiveConnectionsList} 
                </IonSelect>
            </IonItem>
        </div>
    )
}
export default SelectJETSProjects