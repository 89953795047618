
import { IonRow, IonCol, IonIcon, IonContent } from '@ionic/react';
import {businessOutline, closeOutline, shareOutline, trashOutline} from 'ionicons/icons';
import { useState,useEffect } from 'react';
import { callXeroAvailableYears } from '../../../dbconnections/Select';
const ProjectsSide = (props:any) =>{
    console.log(props)
    const [getState, setState]      = useState<any>()
    const [getLiveConnectionsList, setLiveConnectionsList] = useState<any>()
    var prevState:any;
    const setProjects = () =>{
    var card:any = []
    try{
        card.push(props.data)
        var sideList:any = card[0].map((x:any,i:number)=>{
          return(
          <IonCol key={i} size="12"  className="ar-card-line text-black summary-account" id={x.names} title={x.name}>
            <IonRow >
              <IonCol size="10">
                <div onClick={ev=>{
                            var z:any = []
                            z.push({analytcal_reviews_id:x.id, start_date:x.startdate, end_date:x.enddate, name:x.name, migration_id:x.migrationid, migration_name:x.name})
                            props.setAnalyticalReviewsId(z[0])

                            }}>
                <IonIcon icon={businessOutline} className="size-32"></IonIcon> &nbsp;
                {x.name}
                </div>
              </IonCol>
            </IonRow>
          </IonCol>
          )
        })
        setLiveConnectionsList(sideList)
        
        
    }catch(e){

    }
    }
    useEffect(() => {
        setState(()=>{
            if(props.data != prevState){
                prevState = null
                setProjects()
            }}
        )
        
    }, [props.data]); 
    return(
    <div className="project-side-list">
        <IonRow>
            <IonCol className="ion-text-right">
                <IonIcon className="size-24" onClick={()=>{props.setLiveConnections("live-connections-area-hide")}} icon={closeOutline}></IonIcon>
            </IonCol>
        </IonRow>
        <IonContent className="live-connections">
            {getLiveConnectionsList}
        </IonContent>
    </div>
    )
}
export default ProjectsSide