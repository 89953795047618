import { IonIcon, IonLabel } from '@ionic/react';
import { analytics, apps, home} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import Highcharts, { isNumber } from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'

const BarChart = (props:any) =>{
    const [getChart, setChart]              = useState<any>()
    const [getState, setState]              = useState<any>()
    var prevState:any;
    const drawChart = () =>{
        const tip = '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.2f}</b> <br/>'
        let barChart:any = {
        chart: {
            type: 'column'
        },
        title:{
        text:''
        },
        xAxis: {
            type: 'datetime'
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: tip,
        },
        subTitle:{
            text:''
        },
        rangeSelector: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        series: [{
                name: props.name,
                negativeColor: props.fillColor,
                data: props.data
            }],

            responsive: {
            rules: [{
                condition: {
                maxWidth: 500
                },
                chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                yAxis: {
                    labels: {
                    align: 'left',
                    x: 0,
                    y: -5
                    },
                    title: {
                    text: null
                    }
                },
                subtitle: {
                    text: null
                },
                credits: {
                    enabled: false
                }
                }
            }]
            }
        }
        let c:any = (<HighchartsReact highcharts={Highcharts} options={barChart}></HighchartsReact>)
        setChart(c)
    }
    useEffect(() => {
        console.log(props.data)
        
        setState(()=>{
            if(props.data != prevState){
                prevState = null
                drawChart()
            }}
        )
        
    }, [props.data]);
    return(
        <div>
         {getChart}   
        </div>
    )
}

export default BarChart