import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRadio, IonRadioGroup, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState,useEffect, useRef } from 'react';
import { apps, attachOutline, businessOutline, caretForwardCircleOutline, caretForwardOutline, chevronForward, close, cloudUploadOutline, document, documentOutline, gridOutline, helpOutline, listOutline, logoAndroid, peopleCircleOutline, peopleOutline, serverOutline, time, timeOutline, trashBinOutline } from 'ionicons/icons';
import Basic from '../../Tables/Basic';
import BasicML from '../../Tables/BasicML';
import './ML.css'
import FileLoader from '../../FileLoader/fileloader';
import NewProjectForm from '../../Forms/NewProject';
import Bot from '../../../ChatBot/bot';
import "../../../ChatBot/bot.css"
var table_object_id:any = "1256091961"
var process:any = 1;
var step:any = 1;
const Learning = (props:any) =>{
    const [getBotStep, setBotStep]                      = useState<any>(0)
    const [getPage, setPage]                            = useState<any>("bot-panel-up")
    const [getShowLoading, setShowLoading]              = useState<boolean>(false)
    const [getTableObjectId, setTableObjectId]          = useState<any>("0")
    const [getData, setData]                            = useState<any>()
    const [getTable, setTable]                          = useState<any>()
    const [getDataSource, setDataSource]                = useState<any>()
    const [getTablePanel, setTablePanel]                = useState<any>(false)
    const [getExistingDataPanel, setExistingDataPanel]  = useState<any>()
    const [getFileuploadPanel, setFileUploadPanel]      = useState<any>()
    const [getFileUpload, setFileUpload]                = useState<any>(false)
    const [getNewProjectForm, setNewProjectForm]        = useState<any>(false)
    const [getProjectName, setProjectName]              = useState<any>()
    const [getMigrationId, setMigrationId]              = useState<any>()
    const [getCountryId, setCountryId]                  = useState<any>()
    const [getSelector, setSelector]                    = useState<any>("ml-selector-hide")
    const [getStartLearning, setStartLearning]          = useState<any>(false)
    const [getSelectorBottom, setSelectorBottom]        = useState<any>("ml-table-bottom-panel-down")
    const [getDefinitionId, setDefinitionId]            = useState<any>()
    const [getStep, setStep]                            = useState<any>()
    const [getStepNumber, setStepNumber]                = useState<any>()
    const [getColumnDefinitionId, setColumnDefinitionId]    = useState<any>()
    const [getVersionId, setVersionId]                      = useState<any>()

    const setView = (v:any) =>{
        switch(v){
            case 1: setFileUploadPanel(true); setNewProjectForm(true);break;
            case 2: ;break;
        }
    }

    const setLearningObject = (n:any) =>{
        switch(n){
            case 1: process = 1;setStepNumber(step);/*getLearningProcess1(step)*/;break;//TB Opening
            case 2: process = 2;getLearningProcess2(step);break;//General Ledger
            case 3: process = 3;getLearningProcess3(step);break;//TB Closing
            case 4: process = 4;getLearningProcess4(step);break;//Chart of Accounts
        }
    }
    const getLearningProcess1 = (n:any)=>{
        switch(n){
            case 1:
                
                var x:any = <IonRow>
                                <IonCol>Please select first account number entry in this document? </IonCol>
                            </IonRow>
                
                setStep(x);
                
            ;break;//Select First Account Number in document
            case 2:
                
                var x:any = <IonRow>
                                <IonCol>Please select first account description entry in this document? </IonCol>
                            </IonRow>
                
                setStep(x);
                
            ;break;//Select first Account Description in document
            case 3:
                var x:any = <div>
                                <IonRow>
                                    <IonCol>Does the document contain</IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonItem onClick={()=>{getLearningProcess1(4)}}>
                                            <IonLabel>Debit & Credit values</IonLabel>
                                            <IonRadio></IonRadio>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonItem onClick={()=>{getLearningProcess1(6)}}>
                                            <IonLabel>Balance / Net Value</IonLabel>
                                            <IonRadio></IonRadio>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </div>
                
                
                setStep(x);
                //setStepNumber(4)
            ;break;//Select first Debit value in document
            case 4:
                var x:any = <IonRow>
                                <IonCol>Please select first debit value in this document? </IonCol>
                            </IonRow>
                
                setStep(x);
                
            ;break;//Select first Debit value in document
            case 5:
                var x:any = <IonRow>
                                <IonCol>Please select first credit value in this document? </IonCol>
                            </IonRow>
                
                setStep(x);
                
            ;break;//Select first Credit value in document 
            case 6:
                var x:any = <IonRow>
                                <IonCol>Please select first balance value in this document? </IonCol>
                            </IonRow>
                
                setStep(x);
                
            ;break;//Select first Balance value in document 
            case 7:
                var x:any = <IonRow>
                                <IonCol>Please select first BS/IS entry in this document? </IonCol>
                            </IonRow>
                
                setStep(x);
                
            ;break;//Select first BS entry in document
            case 8:
                var x:any = <IonRow>
                                <IonCol>Please select Date entry in this document? </IonCol>
                            </IonRow>
                
                setStep(x);
                
            ;break;//Select first BS entry in document
        }
        //step++;
    }
   
    const getLearningProcess2 = (n:any)=>{
        switch(n){
            case 1:;break;//select first account number in document
            case 2:;break;//select first account description in document
            case 3:;break;//select first transaction date number in document
            case 4:;break;//select first debit value in document
            case 5:;break;//select first credit value in document
            case 6:;break;//select first balance value in document
            case 7:;break;//select first journal number in document
            case 8:;break;//select first journal description in document
            case 9:;break;//select first user name in document
            case 10:;break;//select first transaction code in document
        }
        //step++;
    }
    const getLearningProcess3 = (n:any)=>{
        switch(n){
            
        }
        step++;
    }
    const getLearningProcess4 = (n:any)=>{
        switch(n){
            
        }
        step++;
    }

    const callTable = (version_id:any) =>{
        setShowLoading(true)
        //setTablePanel(true)
        console.log("validate file")
        let data:any = [];
        fetch(props.props.primary_host+'/dbconnect/dbdynamic_ml.jsp?dbo=select_table'+
            "&version_id="+version_id
            ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(d =>{

            
            data.push(d.data); 
            setData(data)
            //var x:any = 
            //setTable(x)
            setShowLoading(false)
            setFileUploadPanel(false)
            setExistingDataPanel(false)
            setDataSource(false)
            setStartLearning(true)
            setTablePanel(true)
        })
        .catch(error=>{
          setShowLoading(false)
          alert(error)
        })
    }
    React.useEffect(() => {
        //callTable()
        if(getBotStep != props.set_bot_step)
            alert(props.set_bot_set)
            setBotStep(props.set_bot_step)
    }, []);
    return(
        <div >
            <div  className={getSelectorBottom+' ion-padding'}>
                <IonRow>
                    <IonHeader className="size-20 ion-padding">
                        <IonRow>
                            <IonCol size="11" className="ion-text-left">
                                Training Helper
                            </IonCol>
                            <IonCol size="1" className="ion-text-right">
                                <IonIcon icon={close} className="size-32" onClick={()=>{setSelectorBottom("ml-table-bottom-panel-down")}}></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonHeader>
                </IonRow>
                <IonRow>
                    <IonCol>{getStep}</IonCol>
                </IonRow>
            </div>
            <div className={getSelector + " ion-padding"}>
                <IonRow>
                    <IonHeader className="size-16 ion-text-center">
                        <IonIcon icon={caretForwardOutline} className="size-36 text-green"></IonIcon>
                        <br/>
                        Learning Mode
                    </IonHeader>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonRadioGroup>
                            <IonItem onClick={()=>{setSelector("ml-selector-hide"); setSelectorBottom("ml-table-bottom-panel-up"); setLearningObject(1)}}>
                                <IonLabel>Opening Trial Balance</IonLabel>
                                <IonRadio value="1"></IonRadio>
                            </IonItem>
                            <IonItem onClick={()=>{setSelector("ml-selector-hide"); setSelectorBottom("ml-table-bottom-panel-up"); setLearningObject(2)}}>
                                <IonLabel>Closing Trial Balance</IonLabel>
                                <IonRadio value="3"></IonRadio> 
                            </IonItem>
                            <IonItem onClick={()=>{setSelector("ml-selector-hide"); setSelectorBottom("ml-table-bottom-panel-up"); setLearningObject(3)}}>
                                <IonLabel>General Ledger</IonLabel>
                                <IonRadio value="2"></IonRadio>                               
                            </IonItem>
                        </IonRadioGroup>
                    </IonCol>
                </IonRow>
            </div>
            <IonLoading onWillDismiss={() => setShowLoading(false)} 
            isOpen={getShowLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Loading...'} ></IonLoading>
            <IonRow>
                <div className="ml-header-panel">
                    <IonRow className='ion-padding'>
                        <IonCol className='ion-text-center' size="2">
                            <IonIcon icon={logoAndroid} className="size-36 text-black"></IonIcon>&nbsp;
                            <br/>
                            Machine Learning
                        </IonCol>
                        <IonCol className='ion-text-center size-16 hover' size="2" onClick={()=>{setDataSource(!getDataSource)}}>
                            <IonIcon icon={cloudUploadOutline} className="size-36 text-black"></IonIcon>
                            <br/>
                            Select Data Source
                        </IonCol>
                        {(getStartLearning) &&
                        <IonCol className='ion-text-center size-16 hover' size="2" onClick={()=>{setSelector("ml-selector-show")}}>
                            <IonIcon icon={caretForwardOutline} className="size-36 text-green"></IonIcon>
                            <br/>
                            Start Learning
                        </IonCol>
                        }
                        {getDataSource &&
                            <IonCol className='ion-text-center size-16 hover' size="2">
                                <IonIcon icon={serverOutline} className="size-36 text-gt"></IonIcon>
                                <br/>
                               Choose Existing Data
                            </IonCol>
                        }
                        {getDataSource &&
                            <IonCol className='ion-text-center size-16 hover' size="2" onClick={()=>{setFileUploadPanel(true); setNewProjectForm(true); console.log(props.state)}}>
                                <IonIcon icon={documentOutline} className="size-36 text-gt"></IonIcon>
                                <br/>
                                Upload File
                            </IonCol>
                        }
                    </IonRow>
                </div>
            </IonRow>
            <IonRow>

                <div className='ml-table-panel ion-padding'>
                    {getTablePanel &&
                    <div>
                        {/*getTable*/}
                        <BasicML
                            state={props}
                            data={getData}
                            version_id = {getVersionId}
                            definition_id={getDefinitionId}
                            column_definition_id={getColumnDefinitionId}
                            step={getStepNumber}
                            process={1}
                            next_step={(e:any)=>{setStepNumber(e);setLearningObject(getDefinitionId)}}
                            learning_process ={()=>{getLearningProcess1(getStepNumber)}}
                            bot_step={(e:any)=>{setBotStep(e);props.bot_step(e)}}
                        />
                    </div>
                    }
                    {getFileuploadPanel &&
                    <div>
                        <IonRow>
                            <IonCol className="ion-text-center size-24">Upload File Center</IonCol>
                        </IonRow>
                        {(getNewProjectForm && !getFileUpload) &&
                        <div>
                            
                            <IonRow className="ion-padding">
                                <IonCol size="3"></IonCol>
                                <IonCol className="ion-padding" >

                                    <div className="upload-form-left-division load-settings size-14 ion-padding">
                                        <NewProjectForm
                                            state={props} 
                                            application_id={-1}
                                            package_id={-1}
                                            setSystem={(e:any)=>{}} 
                                            setUpLoads={(e:any)=>{
                                                //if(getPackageId == 2){
                                                //setView(3)
                                                //}
                                                //if(getPackageId == 1){
                                                //setView(4)
                                                //}
                                            }} 
                                            setProjectName={(e:any)=>{setProjectName(e)}}
                                            setBillingCode={(e:any)=>{}}
                                            setCTT={(e:any)=>{}}
                                            setRounding={(e:any)=>{}}
                                            setCountry={(e:any)=>{setCountryId(e)}}
                                            setMigrationId={(e:any)=>{setMigrationId(e[0].migration_id);setCountryId(e[0].country_id); setFileUpload(true); setNewProjectForm(false)}}
                                        />
                                    </div>
                                </IonCol>
                                <IonCol size="3"></IonCol>
                            </IonRow>
                            </div>
                            }
                            {(!getNewProjectForm && getFileUpload) &&
                            <IonRow>
                                <IonCol size="3"></IonCol>
                                <IonCol size="6">
                                    <FileLoader
                                        state={props} 
                                        form_name="Data Source" 
                                        restore_id={2}
                                        ctt={0}
                                        rounding ={"0.00"}
                                        migration_name ={getProjectName}
                                        billing_code = {""}
                                        country = {getCountryId}
                                        migration_id = {getMigrationId}
                                        load_counter = {(e:any)=>{}}
                                        version_id={(e:any)=>{setVersionId(e)}}
                                        file_name = {(e:any)=>{}}
                                        package_id ={ -1 }
                                        load_table ={(e:any)=>{ callTable(e);}}
                                    />
                                </IonCol>
                            </IonRow>
                            }
                        </div>
                        }
                    
                    {getExistingDataPanel &&
                        <div></div>
                    }
                </div>
            </IonRow>
            <div  className={getPage+" hover"}>
                <Bot 
                    state={props.state}
                    forename={props.state.forename}
                    get_position={getPage}
                    set_position={(e:any)=>{setPage(e)}}
                    application_id={props.state.application_id}
                    step={getBotStep}
                    set_view={(e:any)=>{setView(e)}}
                />
            </div>
        </div>
        )
}
export default Learning