import { IonModal, IonSpinner, IonListHeader, IonButton,IonCheckbox, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonTextarea, IonBadge, IonFooter } from '@ionic/react';
import { print, alertCircleSharp, analytics, analyticsOutline, analyticsSharp, arrowBackSharp, arrowForwardCircleOutline, arrowForwardSharp, bluetoothSharp, calendarOutline, caretDown, caretUp, checkmarkCircle, checkmarkCircleSharp, checkmarkDone, checkmarkDoneCircle, checkmarkOutline, chevronForward, closeOutline, cogOutline, copySharp, exitOutline, filterSharp, gridOutline, hammerOutline, homeOutline, listSharp, logInOutline, logOut, logOutOutline, mailOutline, mailSharp, notifications, pencilOutline, peopleCircle, peopleCircleSharp, peopleOutline, personOutline, personSharp, push, sendOutline, settingsOutline, settingsSharp, stopCircleSharp, trashBin, trashBinSharp, trashOutline, warningOutline, warningSharp, wifiOutline } from 'ionicons/icons';
import React,{ useRef, useState } from 'react';
import $ from 'jquery';
import './CashFlow.css'
let cashFlowId:any = 0;
let A1:any=0
let A2:any=0
let A1A2:any=0
let A3:any =0
let B1:any =0
let C1:any =0
let ABC:any =0
let CCE:any =0
let ABCCCE:any =0
let start_date:any="";
let end_date:any="";
let account_value_id = 0;
let accounts_count = 0;
let account_level_0_store:any = []
let account_level_1_store:any = []
let account_level_2_store:any = []
let account_level_3_store:any = []
let levelId =0;
let analyticalReviewsId:any = 0;
let account_level_3_key = 0;
let account_level_2_key = 0;  
let account_level_1_key = 0;
let account_level_0_key = 0;
let accounts_list:any = [];
let analytical_report_id = 17;
const addCommas = (nStr:any) =>{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}
const CashFlow = (props:any) =>{
    start_date = props.start_date
    end_date = props.end_date
    analyticalReviewsId = props.analyticalReviewsId;
    const txtStartDate                          = useRef<HTMLIonInputElement>(null)
    const txtEndDate                            = useRef<HTMLIonInputElement>(null)
    const exportResults = () => {
        setShowLoading(true)
        console.log(
        props.primaryhost+"GetARReport?"+
        "&account_level_0_key="+account_level_0_key+
        "&account_level_1_key="+account_level_1_key+
        "&account_level_2_key="+account_level_2_key+
        "&account_level_3_key="+account_level_3_key+
        "&year="+0+
        "&month="+0+
        "&migrationid="+0+
        "&analytical_report_id="+analytical_report_id+
        "&analytical_reviews_id="+analyticalReviewsId+
        "&startdate="+start_date+
        "&targetcurrencyid="+88+
        "&dateshortcut="+0+
        "&enddate="+end_date+
        "&compare_mode="+0
        )
        fetch(props.primaryhost+"GetARReport?"+
        "&account_level_0_key="+account_level_0_key+
        "&account_level_1_key="+account_level_1_key+
        "&account_level_2_key="+account_level_2_key+
        "&account_level_3_key="+account_level_3_key+
        "&year="+0+
        "&month="+0+
        "&migrationid="+0+
        "&analytical_report_id="+analytical_report_id+
        "&analytical_reviews_id="+analyticalReviewsId+
        "&startdate="+start_date+
        "&targetcurrencyid="+88+
        "&dateshortcut="+0+
        "&enddate="+end_date+
        "&compare_mode="+0,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => 
        //window.location.replace(primaryhost+"/files/out/"+response);
        response.text()
        
        ) 
        .then(data =>{
        //alert("response.data"+data);
        setShowLoading(false)
        window.location.replace(props.primaryhost+"/files/out/"+data);
        
        })
        .catch(error=>{
        setShowLoading(false)
        alert("An error occurred whilst exporting data")
        })
        
    }
    const [getShowLoading, setShowLoading]                      = useState<boolean>(false)
    const callCashFlowInitial = () =>{
        cashflowLoading();
        var options:any = []
          
        fetch(props.primaryhost+"/ar/dbconnect/select.jsp?etl=selectcashflow"+
            "&analytical_reviews_id="+props.analyticalReviewsId+
            "&start_date="+start_date+
            "&end_date="+end_date
            ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            //txtStartDate.current!.value = getStartDate;
            //txtEndDate.current!.value = getEndDate;
            resetCashFlow()
            for(var i = 0; i < data.data.length; i++){
            if($(".cash_flow_id_"+data.data[i].cash_flow_id) != null)
                $(".cash_flow_id_"+data.data[i].cash_flow_id).html(addCommas((data.data[i].value/1).toFixed(2)))
            }
            sumA1()
            sumA2() 
            sumA1A2()
            sumA3()
            sumB1()
            sumC1()
            sumABC()
            //if( txtStartDate.current == null && txtEndDate.current == null){
            //alert(start_date)
            ///alert(end_date)
            txtStartDate.current!.value = start_date;
            txtEndDate.current!.value = end_date;
            //}
        })
        .catch(error=>{
            //alert(error)
            resetCashFlow()
        })
    }
    const callCashFlow = () =>{
        cashflowLoading();
        var options:any = []
        if( txtStartDate.current! != null && txtEndDate.current! != null){
        start_date = txtStartDate.current!.value;
        end_date = txtEndDate.current!.value
        }
        if(txtStartDate.current != null && txtEndDate.current != null)
        fetch(props.primaryhost+"/ar/dbconnect/select.jsp?etl=selectcashflow"+
            "&analytical_reviews_id="+props.analyticalReviewsId+
            "&start_date="+txtStartDate.current!.value+
            "&end_date="+txtEndDate.current!.value
            ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            resetCashFlow()
            for(var i = 0; i < data.data.length; i++){
            if($(".cash_flow_id_"+data.data[i].cash_flow_id) != null)
                $(".cash_flow_id_"+data.data[i].cash_flow_id).html(addCommas((data.data[i].value/1).toFixed(2)))
            }
            sumA1()
            sumA2()
            sumA1A2()
            sumA3()
            sumB1()
            sumC1()
            sumABC()
        })
        .catch(error=>{
            alert(error)
            resetCashFlow()
        })
    }
    const cashflowLoading = () =>{
        var x:any = "<ion-spinner name='dots'></ion-spinner>"
        $(".cash_flow_id_1273").html(x);
        $(".cash_flow_id_1274").html(x);
        $(".cash_flow_id_1275").html(x);
        $(".cash_flow_id_1276").html(x);
        $(".cash_flow_id_1277").html(x);
        $(".cash_flow_id_1278").html(x);
        $(".cash_flow_id_1279").html(x);
        $(".cash_flow_id_1280").html(x);
        $(".cash_flow_id_1281").html(x);
        $(".cash_flow_id_1282").html(x);
        $(".cash_flow_id_1283").html(x);
        $(".cash_flow_id_1284").html(x);
        $(".cash_flow_id_1285").html(x);
        $(".cash_flow_id_1286").html(x);
        $(".cash_flow_id_1287").html(x);
        $(".cash_flow_id_1288").html(x);
        $(".cash_flow_id_1289").html(x);
        $(".cash_flow_id_1290").html(x);
        $(".cash_flow_id_1291").html(x);
        $(".cash_flow_id_1292").html(x);
        $(".cash_flow_id_1293").html(x);
        $(".cash_flow_id_1294").html(x);
        $(".cash_flow_id_1295").html(x);
        $(".cash_flow_id_1296").html(x);
        $(".cash_flow_id_1297").html(x);
        $(".cash_flow_id_1298").html(x);
        $(".cash_flow_id_1299").html(x);
        $(".cash_flow_id_1300").html(x);
        $(".cash_flow_id_1301").html(x);
        $(".cash_flow_id_1302").html(x);
        $(".cash_flow_id_1303").html(x);
        $(".cash_flow_id_1304").html(x);
        $(".cash_flow_id_1305").html(x);
        $(".cash_flow_id_1306").html(x);
        $(".cash_flow_id_1307").html(x);
        $(".cash_flow_id_1308").html(x);
        $(".cash_flow_id_1309").html(x);
        $(".cash_flow_id_1310").html(x);
        $(".cash_flow_id_1311").html(x);
    }
    const resetCashFlow = () =>{
        $(".cash_flow_id_1273").html("0.00")
        $(".cash_flow_id_1274").html("0.00")
        $(".cash_flow_id_1275").html("0.00")
        $(".cash_flow_id_1276").html("0.00")
        $(".cash_flow_id_1277").html("0.00")
        $(".cash_flow_id_1278").html("0.00")
        $(".cash_flow_id_1279").html("0.00")
        $(".cash_flow_id_1280").html("0.00")
        $(".cash_flow_id_1281").html("0.00")
        $(".cash_flow_id_1282").html("0.00")
        $(".cash_flow_id_1283").html("0.00")
        $(".cash_flow_id_1284").html("0.00")
        $(".cash_flow_id_1285").html("0.00")
        $(".cash_flow_id_1286").html("0.00")
        $(".cash_flow_id_1287").html("0.00")
        $(".cash_flow_id_1288").html("0.00")
        $(".cash_flow_id_1289").html("0.00")
        $(".cash_flow_id_1290").html("0.00")
        $(".cash_flow_id_1291").html("0.00")
        $(".cash_flow_id_1292").html("0.00")
        $(".cash_flow_id_1293").html("0.00")
        $(".cash_flow_id_1294").html("0.00")
        $(".cash_flow_id_1295").html("0.00")
        $(".cash_flow_id_1296").html("0.00")
        $(".cash_flow_id_1297").html("0.00")
        $(".cash_flow_id_1298").html("0.00")
        $(".cash_flow_id_1299").html("0.00")
        $(".cash_flow_id_1300").html("0.00")
        $(".cash_flow_id_1301").html("0.00")
        $(".cash_flow_id_1302").html("0.00")
        $(".cash_flow_id_1303").html("0.00")
        $(".cash_flow_id_1304").html("0.00")
        $(".cash_flow_id_1305").html("0.00")
        $(".cash_flow_id_1306").html("0.00")
        $(".cash_flow_id_1307").html("0.00")
        $(".cash_flow_id_1308").html("0.00")
        $(".cash_flow_id_1309").html("0.00")
        $(".cash_flow_id_1310").html("0.00")
        $(".cash_flow_id_1311").html("0.00")
        sumA1()
        sumA2()
        sumA1A2()
        sumA3()
        sumB1()
        sumC1()
        sumABC()
    }
    const [getSumA1, setSumA1]                = useState<any>(0.00)
    const sumA1 = () =>{
        var cashFlow1273:any = $(".cash_flow_id_1273").html().replaceAll(",","");
        var cashFlow1274:any = $(".cash_flow_id_1274").html().replaceAll(",","");
        var cashFlow1275:any = $(".cash_flow_id_1275").html().replaceAll(",","");
        var cashFlow1276:any = $(".cash_flow_id_1276").html().replaceAll(",","");
        var cashFlow1277:any = $(".cash_flow_id_1277").html().replaceAll(",","");
        var cashFlow1278:any = $(".cash_flow_id_1278").html().replaceAll(",","");
        var cashFlow1279:any = $(".cash_flow_id_1279").html().replaceAll(",","");
        var cashFlow1280:any = $(".cash_flow_id_1280").html().replaceAll(",","");
        var cashFlow1281:any = $(".cash_flow_id_1281").html().replaceAll(",","");
        var cashFlow1282:any = $(".cash_flow_id_1282").html().replaceAll(",","");
        var x:any =     cashFlow1273/1+
                        cashFlow1274/1+
                        cashFlow1275/1+
                        cashFlow1276/1+
                        cashFlow1277/1+
                        cashFlow1278/1+
                        cashFlow1279/1+
                        cashFlow1280/1+
                        cashFlow1281/1+
                        cashFlow1282/1
        A1 = x
        setSumA1(addCommas(x.toFixed(2)))
    }
    const [getSumA2, setSumA2]            = useState<any>(0.00)
    const sumA2 = () =>{
        var cashFlow1283:any = $(".cash_flow_id_1283").html().replaceAll(",","");
        var cashFlow1284:any = $(".cash_flow_id_1284").html().replaceAll(",","");
        var cashFlow1285:any = $(".cash_flow_id_1285").html().replaceAll(",","");
        var cashFlow1286:any = $(".cash_flow_id_1286").html().replaceAll(",","");
        var cashFlow1287:any = $(".cash_flow_id_1287").html().replaceAll(",","");
        var cashFlow1288:any = $(".cash_flow_id_1288").html().replaceAll(",","");
        var x:any =     cashFlow1283/1+
                        cashFlow1284/1+
                        cashFlow1285/1+
                        cashFlow1286/1+
                        cashFlow1287/1+
                        cashFlow1288/1
        A2 = x
        setSumA2(addCommas(x.toFixed(2))) 
    }
    
    const [getSumA1A2, setSumA1A2]            = useState<any>(0.00)
    const sumA1A2 = () =>{
        var x:any = A1/1+A2/1
        A1A2 = x/1
        setSumA1A2(addCommas(x.toFixed(2)))
    }
    const [getSumA3, setSumA3]                 = useState<any>(0.00)
    const sumA3 = () =>{
        var cashFlow1289:any = $(".cash_flow_id_1289").html().replaceAll(",","");
        var cashFlow1290:any = $(".cash_flow_id_1290").html().replaceAll(",","");
        var cashFlow1291:any = $(".cash_flow_id_1291").html().replaceAll(",","");
        var cashFlow1292:any = $(".cash_flow_id_1292").html().replaceAll(",","");
        var x:any =     cashFlow1289/1+
                        cashFlow1290/1+
                        cashFlow1291/1+
                        cashFlow1292/1+
                        A1A2/1
        A3 = x/1
        setSumA3(addCommas(x.toFixed(2))) 
    }
    const [getSumB1, setSumB1]                 = useState<any>(0.00)
    const sumB1 = () =>{
        var cashFlow1293:any = $(".cash_flow_id_1293").html().replaceAll(",","");
        var cashFlow1294:any = $(".cash_flow_id_1294").html().replaceAll(",","");
        var cashFlow1295:any = $(".cash_flow_id_1295").html().replaceAll(",","");
        var cashFlow1296:any = $(".cash_flow_id_1296").html().replaceAll(",","");
        var cashFlow1297:any = $(".cash_flow_id_1297").html().replaceAll(",","");
        var cashFlow1298:any = $(".cash_flow_id_1298").html().replaceAll(",","");
        var cashFlow1299:any = $(".cash_flow_id_1299").html().replaceAll(",","");
        var cashFlow1300:any = $(".cash_flow_id_1300").html().replaceAll(",","");
        var cashFlow1301:any = $(".cash_flow_id_1301").html().replaceAll(",","");
        var cashFlow1302:any = $(".cash_flow_id_1302").html().replaceAll(",","");
        var x:any =     cashFlow1293/1+
                        cashFlow1294/1+
                        cashFlow1295/1+
                        cashFlow1296/1+
                        cashFlow1297/1+
                        cashFlow1298/1+
                        cashFlow1299/1+
                        cashFlow1300/1+
                        cashFlow1301/1+
                        cashFlow1302/1
        B1 = x
        setSumB1(addCommas(x.toFixed(2))) 
    }
    const [getSumC1, setSumC1]                 = useState<any>(0.00)
    const sumC1 = () =>{
        var cashFlow1303:any = $(".cash_flow_id_1303").html().replaceAll(",","");
        var cashFlow1304:any = $(".cash_flow_id_1304").html().replaceAll(",","");
        var cashFlow1305:any = $(".cash_flow_id_1305").html().replaceAll(",","");
        var cashFlow1306:any = $(".cash_flow_id_1306").html().replaceAll(",","");
        var cashFlow1307:any = $(".cash_flow_id_1307").html().replaceAll(",","");
        var cashFlow1308:any = $(".cash_flow_id_1308").html().replaceAll(",","");
        var cashFlow1309:any = $(".cash_flow_id_1309").html().replaceAll(",","");
        var cashFlow1310:any = $(".cash_flow_id_1310").html().replaceAll(",","");
        var x:any =     cashFlow1303/1+
                        cashFlow1304/1+
                        cashFlow1305/1+
                        cashFlow1306/1+
                        cashFlow1307/1+
                        cashFlow1308/1+
                        cashFlow1309/1+
                        cashFlow1310/1
        C1 = x
        setSumC1(addCommas(x.toFixed(2))) 
    }
    const [getSumABC, setSumABC]              = useState<any>(0.00)
    const sumABC = () =>{
        var x = A3/1 + B1/1 + C1/1
        ABC = x
        setSumABC(addCommas(x.toFixed(2)))
    }
    const txtManualEntry:any                                = useRef<HTMLIonInputElement>()
    const [hideManualEntryModal, showManualEntryModal]      = useState<boolean>(false)
    const setPlusMinus = (option:any, id:any, level:any, account_level_store:any) =>{
        fetch(props.primaryhost+"/ar/dbconnect/update.jsp?etl=updateplusminus"+
            "&plus_minus="+option+
            "&cash_flow_id="+id
            ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data=>{
            //renderAccounts(account_level_store, level)
            var element:any = document.getElementsByClassName("plus-minus-"+id)
            console.log("plus-minus-"+id)
            console.log("selected option:"+option)
            element[0].classList.remove("user-color-2")
            element[0].classList.remove("user-color-4")
            element[0].classList.remove("user-color-13")
            console.log("plus minus before:"+account_level_store.plus_minus)
            console.log(account_level_store)
            for (var i = 0; i < account_level_store.length; i++ ){
            if(account_level_store[i].cf_id == id){
                account_level_store[i].plus_minus = option
            }
            }
            console.log("plus minus after:"+account_level_store.plus_minus)
            if(option == 1){
            //$(element[0]).addClass("user-color-4")
            //$(element[0]).unbind('click');
            //$(element[0]).on("click",function(e:any){setPlusMinus(-1,id,level, account_level_store); e.preventDefault();})
            //$(element[0]).html("+")
            renderAccounts(account_level_store, level)
            }
            if(option == -1){
            //$(element[0]).addClass("user-color-2")
            //$(element[0]).unbind('click');
            //$(element[0]).on("click",function(e:any){setPlusMinus(0,id,level, account_level_store); e.preventDefault();})
            //$(element[0]).html("-")
            renderAccounts(account_level_store, level)
            }
            if(option != 1 && option != -1){
            //$(element[0]).addClass("user-color-13")
            //$(element[0]).unbind('click');
            //$(element[0]).on("click",function(e:any){setPlusMinus(1,id,level, account_level_store);e.preventDefault();})
            //$(element[0]).html("+/-"); 
            renderAccounts(account_level_store, level)
            }
        }) 
        //.catch(error=>{
        //  alert("Error updating")
        //})
    }
    const renderAccounts = (options:any, level:any) =>{
        var x:any = null;
        setAccounts(x)
        var accounts:any = options[0].map((x:any, i:number)=>{
        account_level_3_key = 0;
        account_level_2_key = 0;  
        account_level_1_key = 0;
        account_level_0_key = 0;
        if (level == 3)
        return (
            <div key={i} id={x.account_level_0} className="ion-padding ion-text-center accounts ">
              <div className={x.type == 'IS' ? "cash-flow cash-flow-accounts cash-flow-text-icon is-key cash-flow-"+x.id : " cash-flow cash-flow-accounts cash-flow-text-icon text-white bss-key cash-flow-"+x.id } onClick={()=>{}}>
                  {x.name}
              </div>
              {x.cf_id != 0?
                <div>
                {
                  x.plus_minus == 1?
                  <div onClick={()=>{setPlusMinus(-1,x.cf_id,level, account_level_1_store)}} className={"plus-minus user-color-4 plus-minus-"+x.cf_id}>+</div>
                  :
                  <div onClick={()=>{setPlusMinus(1,x.cf_id,level, account_level_1_store)}} className={"plus-minus user-color-2 plus-minus-"+x.cf_id}>-</div>
                
                }
                </div>
                :
                <div></div>
              }
            
            {x.type == 'IS' ?
                <div>
                <IonRow>
                <IonCol>Movement</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1314:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1314} onClick={()=>{account_level_3_key = x.id;account_value_id=1314; insertCashFlow(level)}}>R{addCommas(x.movement)}</IonCol>
                </IonRow>
                </div>
                :
                <div>
                <IonRow>
                    <IonCol>Opening Bal.</IonCol>
                    <IonCol>Closing Bal.</IonCol>
                    <IonCol>Movement</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1316:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1316} onClick={()=>{account_level_3_key = x.id;account_value_id=1316; insertCashFlow(level)}}>R{addCommas(x.opening_balance)}</IonCol>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1318:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1318} onClick={()=>{account_level_3_key = x.id;account_value_id=1318; insertCashFlow(level)}}>R{addCommas(x.closing_balance)}</IonCol>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1317:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1317} onClick={()=>{account_level_3_key = x.id;account_value_id=1317; insertCashFlow(level)}}>R{addCommas(x.balance_movement)}</IonCol>
                </IonRow>
                </div>
            }
            </div>
        )
        if (level == 2)
        return (
            <div key={i} id={x.account_level_0} className="ion-padding ion-text-center accounts ">
              <div className={x.type == 'IS' ? "cash-flow cash-flow-text-icon is-key cash-flow-accounts cash-flow-"+x.id : " cash-flow cash-flow-text-icon text-white bss-key cash-flow-accounts cash-flow-"+x.id } onClick={()=>{}}>
                  {x.name}
              </div>
              {x.cf_id != 0?
              <div>
              {
                  x.plus_minus == 1?
                  <div onClick={()=>{setPlusMinus(-1,x.cf_id,level, account_level_1_store)}} className={"plus-minus user-color-4 plus-minus-"+x.cf_id}>+</div>
                  :
                  <div onClick={()=>{setPlusMinus(1,x.cf_id,level, account_level_1_store)}} className={"plus-minus user-color-2 plus-minus-"+x.cf_id}>-</div>
                  
              }
            </div>
            :
            <div></div>
            }
            {x.type == 'IS' ?
                <div>
                <IonRow>
                  <IonCol>Movement</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1314:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1314} onClick={()=>{account_level_3_key = x.id;account_value_id=1314; insertCashFlow(level)}}>R{addCommas(x.movement)}</IonCol>
                </IonRow>
                </div>
                :
                <div>
                <IonRow>
                    <IonCol>Opening Bal.</IonCol>
                    <IonCol>Closing Bal.</IonCol>
                    <IonCol>Movement</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1316:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1316} onClick={()=>{account_level_2_key = x.id;account_value_id=1316; insertCashFlow(level)}}>R{addCommas(x.opening_balance)}</IonCol>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1318:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1318} onClick={()=>{account_level_2_key = x.id;account_value_id=1318; insertCashFlow(level)}}>R{addCommas(x.closing_balance)}</IonCol>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1317:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1317} onClick={()=>{account_level_2_key = x.id;account_value_id=1317; insertCashFlow(level)}}>R{addCommas(x.balance_movement)}</IonCol>
                </IonRow>
                </div>
            }
            </div>
        )
        if (level == 1)
        return (
            <div key={i} id={x.account_level_0} className="ion-padding ion-text-center accounts ">
            <div className={x.type == 'IS' ? "cash-flow cash-flow-text-icon is-key cash-flow-accounts cash-flow-"+x.id : " cash-flow cash-flow-text-icon text-white bss-key cash-flow-accounts cash-flow-"+x.id } onClick={()=>{}}>
                {x.name}
            </div>
            {x.cf_id != 0?
            <div>
            {
                x.plus_minus == 1?
                <div onClick={()=>{setPlusMinus(-1,x.cf_id,level, account_level_1_store)}} className={"plus-minus user-color-4 plus-minus-"+x.cf_id}>+</div>
                :
                <div onClick={()=>{setPlusMinus(1,x.cf_id,level, account_level_1_store)}} className={"plus-minus user-color-2 plus-minus-"+x.cf_id}>-</div>
                
            }
            </div>
            :
            <div></div>
            }
            {x.type == 'IS' ?
                <div>
                <IonRow>
                  <IonCol>Movement</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1314:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1314} onClick={()=>{account_level_3_key = x.id;account_value_id=1314; insertCashFlow(level)}}>R{addCommas(x.movement)}</IonCol>
                </IonRow>
                </div>
                :
                <div>
                <IonRow>
                    <IonCol>Opening Bal.</IonCol>
                    <IonCol>Closing Bal.</IonCol>
                    <IonCol>Movement</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1316:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1316} onClick={()=>{account_level_1_key = x.id;account_value_id=1316; insertCashFlow(level)}}>R{addCommas(x.opening_balance)}</IonCol>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1318:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1318} onClick={()=>{account_level_1_key = x.id;account_value_id=1318; insertCashFlow(level)}}>R{addCommas(x.closing_balance)}</IonCol>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1317:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1317} onClick={()=>{account_level_1_key = x.id;account_value_id=1317; insertCashFlow(level)}}>R{addCommas(x.balance_movement)}</IonCol>
                </IonRow>
                </div>
            }
            </div>
        )
        if (level == 0)
        return (
            <div key={i} id={x.account_level_0} className="ion-padding ion-text-center accounts ">
            <div className={x.type == 'IS' ? "cash-flow cash-flow-text-icon is-key cash-flow-accounts cash-flow-"+x.id : " cash-flow cash-flow-text-icon text-white bss-key cash-flow-accounts cash-flow-"+x.id } onClick={()=>{account_level_0_key = x.id; insertCashFlow(level)}}>
                {x.name}
            </div>
            {x.cf_id != 0?
            <div>
            {
                x.plus_minus == 1?
                <div onClick={()=>{setPlusMinus(-1,x.cf_id,level, account_level_1_store)}} className={"plus-minus user-color-4 plus-minus-"+x.cf_id}>+</div>
                :
                <div onClick={()=>{setPlusMinus(1,x.cf_id,level, account_level_1_store)}} className={"plus-minus user-color-2 plus-minus-"+x.cf_id}>-</div>
                
                }
            </div>
            :
                <div></div>
            }
            {x.type == 'IS' ?
                <div>
                <IonRow>
                
                <IonCol>Movement</IonCol>
                </IonRow>
                <IonRow>
                <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1314:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1314} onClick={()=>{account_level_3_key = x.id;account_value_id=1314; insertCashFlow(level)}}>R{addCommas(x.movement)}</IonCol>
                </IonRow>
                </div>
                :
                <div>
                <IonRow>
                    <IonCol>Opening Bal.</IonCol>
                    <IonCol>Closing Bal.</IonCol>
                    <IonCol>Movement</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1316:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1316} onClick={()=>{account_level_0_key = x.id;account_value_id=1316; insertCashFlow(level)}}>R{addCommas(x.opening_balance)}</IonCol>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1318:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1318} onClick={()=>{account_level_0_key = x.id;account_value_id=1318; insertCashFlow(level)}}>R{addCommas(x.closing_balance)}</IonCol>
                    <IonCol className={false? "cash-flow-account cash-flow-account-option-icon-selected cash-flow-account-option-"+x.id+"_"+1317:"cash-flow-account cash-flow-account-option-icon cash-flow-account-option-"+x.id+"_"+1317} onClick={()=>{account_level_0_key = x.id;account_value_id=1317; insertCashFlow(level)}}>R{addCommas(x.balance_movement)}</IonCol>
                </IonRow>
                </div>
            }
            </div>
        )
        })
        setAccounts(accounts)
        callSelectedAccounts(level)
        showAccountLoader(false)
        callAccountsSelected(analyticalReviewsId,level)
    }
    const setSelectedAccount = (obj:any, selected:any) =>{
        console.log(obj)
        if(selected == 1 ){
        $(obj).removeClass("cash-flow-account-option-icon")
        $(obj).addClass("cash-flow-account-option-icon-selected")
        }
        if(selected == 0 ){
        $(obj).removeClass("cash-flow-account-option-icon-selected")
        $(obj).addClass("cash-flow-account-option-icon")
        }
    }
    const callAccountsSelected = (analytical_reviews_id:any,level:any) =>{
        fetch(props.primaryhost+"/ar/dbconnect/select.jsp?etl=selectaccountsselected"+
        "&analytical_reviews_id="+analyticalReviewsId+
        "&cash_flow_id="+cashFlowId+
        "&level="+level
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            for (var i = 0 ; i < data.data.length; i++){
                setSelectedAccount( ".cash-flow-account-option-"+data.data[i].account_level_key+"_"+data.data[i].account_value_id, data.data[i].selected)
            }
        })
    }
    const insertCashFlow = (level:any) =>{
        if(level == 3){
        account_level_0_key=0
        account_level_1_key=0
        account_level_2_key=0
        }
        if(level == 2){
        account_level_0_key=0
        account_level_1_key=0
        account_level_3_key=0
        }
        if(level == 1){
        account_level_0_key=0
        account_level_2_key=0
        account_level_3_key=0
        } 
        if(level == 0){
        account_level_1_key=0
        account_level_2_key=0
        account_level_3_key=0
        }
        fetch(props.primaryhost+"/ar/dbconnect/insert.jsp?etl=insertcashflow"+
        "&cash_flow_id="+cashFlowId+
        "&analytical_reviews_id="+analyticalReviewsId+
        "&account_level_0_key="+account_level_0_key+
        "&account_level_1_key="+account_level_1_key+
        "&account_level_2_key="+account_level_2_key+
        "&account_level_3_key="+account_level_3_key+
        "&account_value_id="+account_value_id+
        "&manual_entry="+0+
        "&value="+0
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
        var level:any = data.data[0].level
        setSelectedAccount( ".cash-flow-account-option-"+data.data[0].account_level_key+"_"+data.data[0].account_value_id, data.data[0].selected)
        //callAccounts(levelId)
        })
    }
    const saveManualEntry = () =>{
        fetch(props.primaryhost+"/ar/dbconnect/insert.jsp?etl=insertcashflow"+
        "&cash_flow_id="+cashFlowId+
        "&analytical_reviews_id="+props.analyticalReviewsId+
        "&account_level_0_key="+0+
        "&account_level_1_key="+0+
        "&account_level_2_key="+0+
        "&account_level_3_key="+0+
        "&manual_entry="+1+
        "&value="+txtManualEntry.current!.value
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
        callCashFlow()
        })
    }
    const [hideAccountsModal, showAccountsModal]      = useState<boolean>(false)
const [getAccounts, setAccounts]                  = useState<boolean>(false)
const callSelectedAccounts = (level:any) =>{
  var options:any = []
  resetSelectedAccounts()
  fetch(props.primaryhost+"/ar/dbconnect/select.jsp?etl=selectedaccounts"+
    "&level="+level+
    "&cash_flow_id="+cashFlowId+
    "&analytical_reviews_id="+props.analyticalReviewsId
    ,
    {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
    }
  )
  .then(response => response.json())
  .then(data =>{
    for (var i = 0; i < data.data.length; i++){
      var obj = $(".cash-flow-"+data.data[i].id)
      obj.addClass("user-color-1 text-white")
    }
  })
}
const resetSelectedAccounts = () =>{
  var obj = $(".cash-flow")
  //alert(obj.length)
  for(var i=0; i < obj.length; i++){
    $(obj[i]).removeClass("user-color-1") 
  }
}
const [hideAccountLoader, showAccountLoader]        = useState<boolean>(false)
const checkAccountsExists = (analytical_reviews_id:any, level:any, start_date:any, end_date:any) =>{
  console.log(level)
  console.log(analytical_reviews_id)
  console.log(start_date)
  console.log(end_date)
  //console.log(account_level_0_store[0][0])
  if(level == 0){
    console.log("level 0")
    if(
      account_level_0_store[0][0].analytical_reviews_id == analytical_reviews_id &&
      account_level_0_store[0][0].start_date == start_date &&
      account_level_0_store[0][0].end_date == end_date
    )
    return 1
  }
  if(level == 1){
    if(
      account_level_1_store[0][0].analytical_reviews_id == analytical_reviews_id &&
      account_level_1_store[0][0].start_date == start_date &&
      account_level_1_store[0][0].end_date == end_date
    )
    return 1 
  }
  if(level == 2){
    if(
      account_level_2_store[0][0].analytical_reviews_id == analytical_reviews_id &&
      account_level_2_store[0][0].start_date == start_date &&
      account_level_2_store[0][0].end_date == end_date
    )
    return 1
  }
  if(level == 3){
    if(
      account_level_3_store[0][0].analytical_reviews_id == analytical_reviews_id &&
      account_level_3_store[0][0].start_date == start_date &&
      account_level_3_store[0][0].end_date == end_date
    )
    return 1
  }
  return 0;
}
const callAccounts = (level:any) =>{
  showAccountLoader(true)
  var reset:any = null;
  setAccounts(reset);
  levelId = level
  let exists:any = 0; 
  var options:any = []
  if(level == 0){
    if(account_level_0_store[0] != undefined){
      exists = checkAccountsExists(analyticalReviewsId, level, txtStartDate.current!.value, txtEndDate.current!.value)
      console.log("exists in store:"+checkAccountsExists(analyticalReviewsId, level, txtStartDate.current!.value, txtEndDate.current!.value))
    }
  }
  if(level == 1){
    if(account_level_1_store[0] != undefined){
      exists = checkAccountsExists(analyticalReviewsId, level, txtStartDate.current!.value, txtEndDate.current!.value)
      console.log("exists in store:"+checkAccountsExists(analyticalReviewsId, level, txtStartDate.current!.value, txtEndDate.current!.value))
    }
  }
  if(level == 2){
    if(account_level_2_store[0] != undefined){
      exists = checkAccountsExists(analyticalReviewsId, level, txtStartDate.current!.value, txtEndDate.current!.value)
      console.log("exists in store:"+checkAccountsExists(analyticalReviewsId, level, txtStartDate.current!.value, txtEndDate.current!.value))
    }
  }
  if(level == 3){
    if(account_level_3_store[0] != undefined){
      exists = checkAccountsExists(analyticalReviewsId, level, txtStartDate.current!.value, txtEndDate.current!.value)
      console.log("exists in store:"+checkAccountsExists(analyticalReviewsId, level, txtStartDate.current!.value, txtEndDate.current!.value))
    }
  }
  if(exists == 0){
    fetch(props.primaryhost+"/ar/dbconnect/select.jsp?etl=selectaccounts"+
      "&level="+level+
      "&analytical_reviews_id="+analyticalReviewsId+
      "&start_date="+txtStartDate.current!.value+
      "&end_date="+txtEndDate.current!.value
      ,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      options.push(data.data)
      if(level == 0){
        account_level_0_store = options
        setLevel(0)
      }
      if(level == 1){
        account_level_1_store = options
        setLevel(1)
      }
      if(level == 2){
        account_level_2_store = options
        setLevel(2)
      }
      if(level == 3){
        account_level_3_store = options
        setLevel(3)
      }
      accounts_list = options
      renderAccounts(options, level)
    })
  }else{
    if(level == 0){
      renderAccounts(account_level_0_store,level)
      accounts_list = account_level_0_store
      setLevel(0)
    }
    if(level == 1){
      renderAccounts(account_level_1_store,level)
      accounts_list = account_level_1_store
      setLevel(1)
    }
    if(level == 2){
      renderAccounts(account_level_2_store,level)
      accounts_list = account_level_2_store
      setLevel(2)
    }
    if(level == 3){
      renderAccounts(account_level_3_store,level)
      accounts_list = account_level_3_store
      setLevel(3)
    }
  }
}
const [searchAccountsQuery, setSearchAccountsQuery] = useState('');
const [getLevel, setLevel]                          = useState<any>();
const resetSearchAccounts = () =>{
  var elements:any = $(".cash-flow-accounts")
  for(var i = 0; i < elements.length; i++){
    $(elements[i]).hide()
  }
}
const searchAccounts = (value:any) =>{
    //var v:any = null;
    //setAccounts(v)
    var level:any = getLevel;
    var elements:any =  document.getElementsByClassName("cash-flow-accounts");
    console.log("value:"+value)
    console.log("elements:"+elements.length)
    console.log(elements[0].id)  
    console.log(elements[0].id.toLowerCase().includes(value.toLowerCase()))
    if (elements != undefined){
        for(var i = 0; i < elements.length; i++){
        console.log("element id:"+elements[i].id)
        if(elements[i].id.toLowerCase().includes(value.toLowerCase()) || value.toLowerCase() == ""){
            elements[i].style.display = 'block'
        }else{
            elements[i].style.display = 'none'
        }
        } 
    }else{
        for(var i = 0; i < elements.length; i++){
        console.log("element id:"+elements[i].id)
        elements[i].style.display = 'block'
        } 
    }
}
React.useEffect(() => {
  callCashFlowInitial()
  
}, []);
    return(
        <div className="ion-padding"> 
            <IonModal className={""} isOpen={hideManualEntryModal} onDidDismiss={()=>{showManualEntryModal(false)}}>
              <IonHeader><h2>Cash Flow Manual Entry</h2></IonHeader>
              <IonContent>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="floating">Amount</IonLabel>
                      <IonInput type="number" ref={txtManualEntry} ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonContent>
              <IonFooter>
                <IonRow>
                  <IonCol className="ion-text-left">
                    <IonButton onClick={()=>{showManualEntryModal(false)}}>cancel</IonButton>
                  </IonCol>
                  <IonCol className="ion-text-right">
                    <IonButton onClick={()=>{if(txtManualEntry.current! != null){saveManualEntry(); showManualEntryModal(false)}}}>save</IonButton>
                  </IonCol>
                </IonRow>
              </IonFooter>
            </IonModal>
            <IonModal className={"modal-accounts"} isOpen={hideAccountsModal} onDidDismiss={()=>{showAccountsModal(false)}}>
              <IonHeader>
                <IonRow className="ion-padding">
                  <IonCol size="4" className="ion-text-left">
                    <h2>Account Options</h2>
                  </IonCol>
                  <IonCol>
                    <IonRow>
                      <IonCol><span className="bss-key">&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;<span>Balance Sheet Acc.</span></IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol><span className="is-key">&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;<span>Income Statement Acc.</span></IonCol>
                    </IonRow> 
                  </IonCol>
                  <IonCol>
                    <div className='ion-text-center'>
                      <span className="cash-flow-text-icon user-color-1  text-white" onClick={()=>{callAccounts(3);}}>Account Level 3</span>
                      
                      
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className='ion-text-center'>
                      <span className="cash-flow-text-icon user-color-1  text-white" onClick={()=>{callAccounts(2);}}>Account Level 2</span>
                      
                      
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className='ion-text-center'>
                      <span className="cash-flow-text-icon user-color-1  text-white" onClick={()=>{callAccounts(1);}}>Account Level 1</span>
                      
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className='ion-text-center'>
                      <span className="cash-flow-text-icon user-color-1 text-white" onClick={()=>{callAccounts(0);}}>Account Level 0</span>
                      
                    </div>
                  </IonCol>
                </IonRow>
              </IonHeader>
              <IonContent >
                <IonRow>
                {!hideAccountLoader &&
                  <IonCol>
                    <IonToolbar>
                      <IonTitle><b>Search Accounts</b></IonTitle>
                    </IonToolbar>
                    <IonToolbar>
                      <IonSearchbar value={searchAccountsQuery} onIonChange={e => {setSearchAccountsQuery(e.detail.value!); searchAccounts(e.detail.value!)}} ></IonSearchbar>
                    </IonToolbar>
                  </IonCol>
                }
                </IonRow>
                <IonRow>
                    {hideAccountLoader &&
                    <IonCol  className="ion-text-center">
                      <IonRow className='ion-text-center'>
                        <IonCol size="12" className='ion-text-center'>
                          Loading Accounts... <br/>It might take a short moment, if its the first time
                        </IonCol>
                        <IonCol size="12" className='ion-text-center'>
                          <IonSpinner name="dots"></IonSpinner>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    }
                  <IonCol>
                    <IonRow>
                      <IonCol size="12">{getAccounts}</IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonContent>
              <IonFooter>
                <IonRow>
                  <IonCol className="ion-text-right">
                    <IonButton onClick={()=>{showAccountsModal(false); callCashFlow()}}>Done</IonButton>
                  </IonCol>
                </IonRow>
              </IonFooter>
            </IonModal>
            <div className="bs-area-to-print-content ion-padding">
                <IonRow className="size-16 "><IonCol size="6"><h4><b>A. Cash flows from Operating Activities</b></h4></IonCol><IonCol className="ion-text-right"><h4><b>Amount</b></h4></IonCol><IonCol></IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row-total"><IonCol size="6"><b>	Profit/Loss for the year</b></IonCol><IonCol className="ion-text-right"><b><span className="cash_flow_id_1273">0.00</span></b></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1273	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1273;showManualEntryModal(true)}}>Manual Entry</div>	</IonCol></IonRow>
                <IonRow className="size-16 "><IonCol size="6"><h4><b>Adjusted for income statement items:	</b></h4></IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Add back Finance costs/Interest Paid	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1274	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1274	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1274;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Add back Income tax paid	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1275	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1275	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1275;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Add back amortization of all assets	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1276	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1276	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1276;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Add back depreciation of all assets	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1277	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1277	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1277;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Add back impairment of all assets	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1278	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1278	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1278;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Deduct Investment income/Interest received	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1279	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1279	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1279;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Deduct profit on disposal of assets	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1280	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1280	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1280;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Deduct deferred revenue	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1281	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1281	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1281;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Add back/Deduct adjustment in fair value of non-current assets	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1282	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1282	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1282;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row-total"><IonCol size="6"><b>	A1 Operating cash flow before working capital changes	</b></IonCol><IonCol className="ion-text-right"><b>{getSumA1}</b></IonCol><IonCol></IonCol></IonRow>
                <IonRow className="size-16 "><IonCol size="6"><h4><b>Working Capital Changes (Balance sheet)	</b></h4></IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Increase/decrease in financial assets	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1283	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1283	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1283;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Increase/decrease in inventory	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1284	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1284	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1284;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Increase/decrease trade and other receivables	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1285	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1285	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1285;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Increase/decrease in short term loans (Owed to external party)	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1286	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1286	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1286;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Increase/decrease in trade and other payables	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1287	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1287	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1287;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Increase/decrease in operating lease liabilities	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1288	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1288	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1288;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row-total"><IonCol size="6"><b>	A2 Total Working capital changes	</b></IonCol><IonCol className="ion-text-right"><b>{getSumA2}</b></IonCol><IonCol>					</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6"><b>Net cash flows from operations (A1 + A2)</b>	</IonCol><IonCol className="ion-text-right"><b>{getSumA1A2}</b></IonCol><IonCol>					</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Add Investment income/Interest income	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1289	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1289	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1289;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Dedust Finance costs/interest	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1290	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1290	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1290;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Add dividends received	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1291	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1291	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1291;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Deduct tax paid	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1292	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1292	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1292;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row-total"><IonCol size="6"><b>	Net cash flows from operating activities	</b></IonCol><IonCol className="ion-text-right"><b>{getSumA3}</b></IonCol><IonCol>					</IonCol></IonRow>
                <IonRow className="size-16  "><IonCol size="6"><h4><b>B. Cash flows from Investing Activities	</b></h4>		</IonCol><IonCol className="ion-text-right"></IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Investment property acquired	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1293	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1293	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1293;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Property plant and equipment acquired	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1294	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1294	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1294;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Intangible assets acquired	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1295	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1295	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1295;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Investment acquired	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1296	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1296	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1296;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Fair Value Gain	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1297	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1297	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1297;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Proceeds on disposal of non current assets	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1298	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1298	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1298;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Movement in other non current assets 	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1299	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1299	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1299;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Movement in assets held for sale	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1300	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1300	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1300;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Investment in subsidiaries and associates	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1301	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1301	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1301;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Other investments	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1302	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1302	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1302;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row-total"><IonCol size="6"><b>	Net cash flows from investing activities	</b></IonCol><IonCol className="ion-text-right"><b>{getSumB1}</b></IonCol><IonCol>					</IonCol></IonRow>
                <IonRow className="size-16 "><IonCol size="6"><h4><b>C. Cash flows from financing activities	</b></h4></IonCol><IonCol className="ion-text-right"></IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Capital issued	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1303	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1303	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1303;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Loans raised	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1304	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1304	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1304;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Prior period adjustments	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1305	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1305	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1305;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Current year adjustments to retained income	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1306	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1306	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1306;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Finance lease payments	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1307	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1307	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1307;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Amounts contributed to non-controlling interest	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1308	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1308	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1308;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Shareholders loans raised	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1309	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1309	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1309;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row"><IonCol size="6">	Dividends paid	</IonCol><IonCol className="ion-text-right"><span className="cash_flow_id_1310	 ">0.00</span></IonCol><IonCol className="ion-text-right">	<div className="cash-flow-button" onClick={()=>{cashFlowId=	1310	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1310;showManualEntryModal(true)}}>Manual Entry</div>		</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row-total"><IonCol size="6"><b>Net cash flows from financing activities	</b></IonCol><IonCol className="ion-text-right"><b>{getSumC1}</b></IonCol><IonCol>					</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row-total"><IonCol size="6"><b>Net increase/decrease in cash and cash equivalents (A + B + C)	</b></IonCol><IonCol className="ion-text-right"><b>{getSumABC}</b></IonCol><IonCol>					</IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row-total"><IonCol size="6"><b>Cash and cash equivalents at the beginning of the year</b></IonCol><IonCol className="ion-text-right"><b><span className="cash_flow_id_1311	 ">0.00</span></b></IonCol><IonCol className="ion-text-right"><div className="cash-flow-button" onClick={()=>{cashFlowId=	1311	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1311;showManualEntryModal(true)}}>Manual Entry</div></IonCol></IonRow>
                <IonRow className="size-16  summary-detail-row-total"><IonCol size="6"><b>Cash and cash equivalents at the end of the year</b></IonCol><IonCol className="ion-text-right"><b><span className="cash_flow_id_1319	 ">0.00</span></b></IonCol><IonCol  className="ion-text-right"><div className="cash-flow-button" onClick={()=>{cashFlowId=	1319	; showAccountsModal(true); var x:any = null; setAccounts(x); callCashFlow()}}>Accounts</div><div className="cash-flow-button" onClick={()=>{cashFlowId=1319;showManualEntryModal(true)}}>Manual Entry</div></IonCol></IonRow>
            </div>
        </div>
    )
}
export default CashFlow