
import { IonSpinner, IonListHeader, IonButton,IonCheckbox, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonTextarea, IonBadge } from '@ionic/react';
import { alertCircleSharp, analytics, analyticsOutline, analyticsSharp, arrowBackSharp, arrowForwardSharp, bluetoothSharp, calendarOutline, checkmarkCircle, checkmarkCircleSharp, checkmarkDone, checkmarkDoneCircle, checkmarkOutline, closeOutline, cogOutline, copySharp, exitOutline, filterSharp, gridOutline, hammerOutline, homeOutline, listSharp, logInOutline, logOut, logOutOutline, mailOutline, mailSharp, pencilOutline, peopleCircleSharp, peopleOutline, personOutline, personSharp, push, sendOutline, settingsOutline, settingsSharp, stopCircleSharp, trashBin, trashBinSharp, trashOutline, warningOutline, warningSharp, wifiOutline } from 'ionicons/icons';
import React,{ useRef, useState } from 'react';
import '../../theme/fonts/poppins-cufonfonts-webfont/Poppins-Regular.woff'
let exportList:any ={
    117:1,
    118:1,
    119:1,
    121:1,
    122:1,
    123:1,
    124:1,
    125:1,
    //126:1,
    127:1,
    128:1,
    129:1,
    130:1,
    145:1,
    146:1,
    //147:1,
    151:1
}
let ctt:any;
let rounding:any;
let keyword:any;
let trCodeList:any=[];
let jnlFilter:any;
let startdate:any=""; 
let enddate:any="";
let glVersionId:any= "";
let migrationId:any=""
let packageId:any=2

const JETS = (props:any) =>{
    const txtFile:any = useRef<HTMLIonInputElement>();
    const txtJournalProjectName:any = useRef<HTMLIonInputElement>();
    const txtJournalMaterialityValue:any = useRef<HTMLIonSelectElement>();
    const ddlRounding:any = useRef<HTMLIonSelectElement>();
    const txtJobCode:any = useRef<HTMLIonInputElement>();
    const txtKeywordTextSearch:any = useRef<HTMLIonInputElement>();
    const ddlCountry:any = useRef<HTMLIonSelectElement>();
    const chkNoTB:any = useRef<HTMLIonCheckboxElement>();
    const ddlDelimiter:any = useRef<HTMLIonSelectElement>();
    const ddlWorksheet:any = useRef<HTMLIonRadioGroupElement>();
    const ddlTextQualifier:any = useRef<HTMLIonSelectElement>();
    const txtFileDummy:any = useRef<HTMLIonInputElement>();
    const txtStartDate:any = useRef<HTMLIonDatetimeElement>();
    const txtEndDate:any = useRef<HTMLIonDatetimeElement>();
    const [getMateriality   , setMateriality]           =useState<boolean>(false)
    const [getRounding      , setRounding]              =useState<boolean>(false)
    const [hideContinue     , showContinue]             =useState<boolean>(false)
    const [getLoader, setLoader]                        = useState<HTMLIonSelectOptionElement>();
    const [getShowLoading, setShowLoading]              = useState<boolean>(false)
    const [getTransactionCodes, setTransactionCodes ]   = useState<HTMLIonColElement>();
    const [getTestResults,setTestResults]               = useState<HTMLIonItemElement>();
    const [getPage1, setPage1]                            = useState<boolean>(true)
    const [getPage2, setPage2]                            = useState<boolean>(false)    
    const [getPage3, setPage3]                            = useState<boolean>(false)
    const [getJNLFilter, setJNLFilter]                  = useState<string>("");

    

    const getTestArray = () =>{
        var jnl ="";
        console.log("start:"+jnl)
        for(var i = 0 ; i < trCodeList.length; i++){
          if(jnl != ""){
            if(trCodeList[i].isChecked)
              jnl = jnl + ",\"jnl_"+ trCodeList[i].id+"\"";
          }
          if(jnl == ""){
            if(trCodeList[i].isChecked){
              jnl = "\"jnl_"+ trCodeList[i].id+"\"";
            }
          } 
          console.log("loop:"+i+":"+jnl)
        }
        console.log("end:"+jnl)
        setJNLFilter(jnl)
        return jnl
      }


    const formValidation = (form:any) =>{
        var x:any = document.getElementsByClassName("check")
        switch(form){
            case 1: 
            if(x.length == 2){
                showContinue(true)
            }else{
                showContinue(false)
            }
            break;
        }
    }
    const resetFormValidation =()=>{
        showContinue(false)
    }
    const setMigrationData = async() =>{
        ctt           = txtJournalMaterialityValue.current.value;
        rounding      = ddlRounding.current.value;
        keyword       = 0;
        restoreJournalsFile()
    }
    const restoreJournalsFile = () =>{
        let a:any = <IonSpinner name="dots"></IonSpinner>
        setLoader(a)
        setShowLoading(true)
        let option:any = [];
        return fetch(props.host+'/dmz/dbconnect/select.jsp?etl=restorejournalsfilear'+
        "&analytical_reviews_id="+props.analyticalReviewsId+
        "&ctt="+ctt+
        "&rounding="+rounding,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        ).then(response => response.json())
        .then(data =>{
            option.push(data.data) ;
            txtStartDate.current.value = option[0][0].mindate;
            txtEndDate.current.value = option[0][0].maxdate;
            console.log("Start Date:"+option[0][0].mindate)
            console.log("End Date:"+option[0][0].maxdate)
            migrationId = option[0][0].migration_id;
            glVersionId = option[0][0].gl_version_id;
            let elements = option[0].map((x:any,i:number) =>{
            trCodeList.push({id:x.id,isChecked:false})
            return(
                <IonCol size="6" key={i}>
                    <IonItem>
                        <IonLabel>{x.code} {x.description}</IonLabel>
                        <IonCheckbox checked={false} className="jnl" onClick={ev =>{setJournalFilter(x.id);}} ></IonCheckbox>
                    </IonItem>
                </IonCol> 
            )
            })
            setTransactionCodes(elements);
            setShowLoading(false)
            a = null
            setLoader(a)
        })
        .catch(error =>{
            alert(error)
            setShowLoading(false)
        })
      }
      const runJournalsWithFilters=()=>{
        let a:any = <IonSpinner name="dots"></IonSpinner>
        setLoader(a)
          var x:any = <IonSpinner name="dots" />
          setTestResults(x)
          startdate = txtStartDate.current.value;
          enddate = txtEndDate.current.value
          setShowLoading(true)
          let option:any = []; 
          console.log(glVersionId)
          console.log(jnlFilter)
          console.log(startdate)
          console.log(enddate)
          console.log(ctt)
          console.log(migrationId)
          console.log(2)
          console.log(props.host)
          console.log(
              props.host+'/dmz/dbconnect/select.jsp?etl=runjournalswithfilter'+
              "&versionid="+glVersionId+
              "&filter="+jnlFilter+
              "&startdate="+startdate+
              "&enddate="+enddate+
              "&ctt="+ctt+
              "&migration_id="+migrationId+
              "&packageid="+packageId+
              "&application_id="+66
          )
          return fetch(props.host+'/dmz/dbconnect/select.jsp?etl=runjournalswithfilter'+
              "&versionid="+glVersionId+
              "&filter="+jnlFilter+
              "&startdate="+startdate+
              "&enddate="+enddate+
              "&ctt="+ctt+
              "&migration_id="+migrationId+
              "&packageid="+packageId+
              "&application_id="+66,
          {
              headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
          }
          )
          .then(response => response.json())
          .then(d =>{
              option.push(d.data)
              let results = option[0].map((x:any, i:number)=>{
                  return(
                      <IonCol className="" key={i} size="6">
                          <IonRow className="">
                              <IonCol size="10" className="">
                                  {x.listdesc}
                              </IonCol>
                              <IonCol size="2" className="">
                                  {
                                      (
                                      x.error == 0 ?
                                          <IonIcon color="success" size="large" icon={checkmarkOutline}></IonIcon>:
                                          <IonIcon color="danger" size="large" icon={closeOutline}></IonIcon>
                                      )
                                  }
                              </IonCol>
                          </IonRow>
                      </IonCol>
                  )
              })
              setTestResults(results)
              setShowLoading(false)
              a = null
              setLoader(a)
          })
          .catch(error =>{
            alert(error)
            setShowLoading(false)
            var x:any = <div>{error}</div>
            setTestResults(x)
        })
      }
      const setJournalFilter = (id:any) =>{
          for(var i = 0; i < trCodeList.length; i++){
              console.log("Before:"+trCodeList[i].id+" "+trCodeList[i].isChecked)
              if(trCodeList[i].id == id){
                  trCodeList[i].isChecked = !trCodeList[i].isChecked;
              }
              console.log("After:"+trCodeList[i].id+" "+trCodeList[i].isChecked)
          }
          
          jnlFilter="";
          for(var i = 0 ; i < trCodeList.length; i++){
              if(trCodeList[i].isChecked && jnlFilter.length > 0){
                  jnlFilter = jnlFilter + ",\"jnl_"+ trCodeList[i ].id+"\"";
              }
              if(trCodeList[i].isChecked && jnlFilter.length == 0){
                  jnlFilter = "\"jnl_"+ trCodeList[i].id+"\"";
              }
              
          }
          getTestArray()
      }
    return(
    <div>
    <IonLoading onWillDismiss={() => setShowLoading(false)} 
    isOpen={getShowLoading}
    onDidDismiss={() => setShowLoading(false)}
    message={'Loading...'} ></IonLoading>
    <IonRow>
        {getPage1 &&
        <IonCol size="7">
            <IonItem>
                <IonCol size="12">
                    <IonLabel className="size-24"><b>Setup Journal Entry Testing - Parameters</b></IonLabel>
                </IonCol>
            </IonItem>
            <IonRow className="">
                <IonCol size="10">
                    <IonItem>
                        <IonLabel className="label-header size-18" position="floating"><h2><b>1) Materiality</b></h2></IonLabel>
                        <IonInput ref={txtJournalMaterialityValue} className="input-text-size-1" type="number" onKeyUp={ev=>{if(ev.currentTarget!.value!.toString().length > 0){setMateriality(true)}else{setMateriality(false)}; formValidation(1)}}></IonInput>
                    </IonItem>
                </IonCol>
                {getMateriality &&
                <IonCol className="check">
                    <IonIcon size="large" color="success" className="ion-padding" icon={checkmarkOutline}></IonIcon>
                </IonCol>
                }
            </IonRow>
            <IonRow>&nbsp;</IonRow>
            <IonRow className="">
                <IonCol size="10">
                    <IonItem>
                        <IonLabel className="label-header size-18"  position="floating"><h2><b>2) Select Rounding Option</b></h2></IonLabel>
                        <IonSelect ref={ddlRounding} className="input-text-size-1" onIonChange={ev=>{if(ev.detail.value != null){setRounding(true)}else{setRounding(false)}; formValidation(1)}} >
                            <IonListHeader>Select Round Option</IonListHeader>
                            <IonSelectOption value="0">0</IonSelectOption>
                            <IonSelectOption value="00">00</IonSelectOption>
                            <IonSelectOption value="000">000</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                </IonCol>
                {getRounding &&
                <IonCol className="check">
                    <IonIcon size="large" color="success" className="ion-padding" icon={checkmarkOutline}></IonIcon>
                </IonCol>
                }
            </IonRow>
            <IonRow>&nbsp;</IonRow>
            <IonRow className="">
                <IonCol size="10">
                    <IonItem>
                        <IonLabel className="label-header size-18" ><h2><b>3) Include Word Search</b></h2></IonLabel>
                        <IonCheckbox ref={txtKeywordTextSearch} className="input-text-size-1"></IonCheckbox>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>&nbsp;</IonRow>
            {hideContinue &&
            <IonRow>
                <IonCol size="10"></IonCol>
                <IonCol size="2" className="" >
                    <IonButton className="button-width-max" color="primary" onClick={e => {setMigrationData(); setPage2(true);setPage1(false)}}>GET DETAILS</IonButton>
                </IonCol>
            </IonRow>
            }
        </IonCol>
        }
        {getPage2 &&
        <div>
        <IonCol size="6">
            <IonItem>
                <IonCol size="12">
                    <IonLabel className="size-24"><b>Date Range & Available Transaction Codes</b></IonLabel>
                </IonCol>
            </IonItem>
            <IonRow>&nbsp;</IonRow>
            <IonItem>
                <IonCol size="12">
                    <IonLabel className="size-18"><b>Select GL Start and End Date</b></IonLabel>
                </IonCol>
            </IonItem>
            <IonRow>
                <IonCol size="5">
                    <IonItem className="size-18">
                        <IonLabel position="floating">Start Date</IonLabel>
                        <IonInput type="date" ref={txtStartDate} />
                    </IonItem>
                </IonCol>
                <IonCol size="1">
                    {getLoader}
                </IonCol>
                <IonCol size="5">
                    <IonItem className="size-18">
                        <IonLabel position="floating">End Date</IonLabel>
                        <IonInput type="date" ref={txtEndDate}   />
                    </IonItem>
                </IonCol>
                <IonCol size="1">
                    {getLoader}
                </IonCol>
            </IonRow>
            <IonRow>&nbsp;</IonRow>
        </IonCol>
        <IonCol size="6">
            <IonItem>
                <IonCol size="10">
                    <IonLabel className="size-24"><b>Select Transaction Code(s) For Journals</b></IonLabel>
                </IonCol>
                <IonCol size="2">
                    {getLoader}
                </IonCol>
            </IonItem>
            <IonRow>
                {getTransactionCodes}
            </IonRow>
            <IonRow>&nbsp;</IonRow>
        </IonCol>
        <IonCol size="12">
            <IonRow>
                <IonCol size="2">
                    <IonButton className="button-width-max" color="primary" onClick={e => {setPage1(true);setPage2(false)}}>Back</IonButton>
                </IonCol>
                <IonCol size="8"></IonCol>
                <IonCol size="2">
                    <IonButton className="button-width-max" color="primary" onClick={e => {runJournalsWithFilters();setPage2(false);setPage3(true)}}>Continue</IonButton>
                </IonCol>
            </IonRow>
        </IonCol>
        </div>
        }
        {getPage3 &&
        <IonCol size="12">
            <IonRow>
                <IonCol size="8" className="test-result-background">
                    <IonLabel  className="test-result-background size-24"><b>Completed Test Status</b></IonLabel>
                </IonCol>
            </IonRow>
            <IonRow>&nbsp;</IonRow>
            <IonRow> 
                {getTestResults}
            </IonRow>
            <IonRow>
                <IonCol size="10"></IonCol>
                <IonCol size="2">
                    <IonButton className="button-width-max" color="primary" onClick={e => {}}>Complete</IonButton>
                </IonCol>
            </IonRow>
        </IonCol>
        }

    </IonRow>
    </div>
    )
}
export default JETS