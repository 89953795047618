
import { IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonCheckbox } from '@ionic/react';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, options, filter} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import $ from "jquery"
import { addCommas, isNumeric } from '../../functions/functions';

const InventoryTable = (props:any) =>{
    const [table, setTable]          = useState<any>()
    const [getData, setData]         = useState<any>()
    const getTable = () =>{
        var header:any = 
        <IonRow className="summary-row-total">
            <IonCol size="1"></IonCol>
            <IonCol size="2">Code</IonCol>
            <IonCol onClick={()=>{props.filter(5)}} >Description</IonCol>
            <IonCol onClick={()=>{props.filter()}} className="ion-text-right" size="1">Quantity</IonCol>
            {/*<IonCol onClick={()=>{props.filter()}} className="ion-text-right" size="1">Unit Cost</IonCol>
            <IonCol onClick={()=>{props.filter()}} className="ion-text-right" size="1">Unit Price</IonCol>*/}
            <IonCol onClick={()=>{props.filter()}} className="ion-text-right" size="1">Total Cost</IonCol>
            <IonCol onClick={()=>{props.filter(13)}} className="ion-text-right" size="1">Total Sales</IonCol>
            <IonCol onClick={()=>{props.filter(4)}} className="ion-text-right" size="1">SALES GAP</IonCol>
            <IonCol onClick={()=>{props.filter(12)}} className="ion-text-right" size="1">LAST SALES</IonCol>
        </IonRow>
        var options:any = []
        options.push(props.data)
        var table:any  = options[0].map((x:any, i:any)=>{
            return(
                <IonRow key={i} className={x.highlighted == 1? "highlighted-row":"summary-detail-row row-id-"+i}>
                    <IonCol className="ion-text-center" size="1">{x.EXCEPTION == 1? <div className="circle-red"></div>: x.EXCEPTION == 2? <div  className="circle-orange"></div> : <div className="circle-green"></div>}</IonCol>
                    <IonCol onClick={()=>{var y:any =[]; y.push({id:x.id,name:x["CODE"]+" "+x["DESCRIPTON 1"]});props.id(y[0])}}size="2" className="summary-account">{x.CODE}</IonCol>
                    <IonCol onClick={()=>{var y:any =[]; y.push({id:x.id,name:x["CODE"]+" "+x["DESCRIPTON 1"]});props.id(y[0])}}className="summary-account">{x['DESCRIPTION 1']}</IonCol>
                    <IonCol size="1" className="ion-text-right">{x["QUANTITY"]}</IonCol>
                    {/*<IonCol size="1" className="ion-text-right">{addCommas(x["UNIT COST"])}</IonCol>
                    <IonCol size="1" className="ion-text-right">{addCommas(x["UNIT PRICE"])}</IonCol>*/}
                    <IonCol size="1" className="ion-text-right">{addCommas(x["TOTAL COST"])}</IonCol>
                    <IonCol size="1" className="ion-text-right">{addCommas(x["TOTAL SALES"])}</IonCol>
                    <IonCol size="1" className="ion-text-right">{addCommas(x["LAST TWO SALES"])}</IonCol>
                    <IonCol size="1" className="ion-text-right">{addCommas(x["MOST RECENT SALE"])}</IonCol>
                </IonRow> 
            )
        })
        var t:any = [header, table]
        setTable(t)
    }
    useEffect(() => {
        if(getData != props.data){
            getTable()
            setData(props.data)
        }
    }, []); 
    return (
        <div>
            {table}
        </div>
    ) 
}
export default InventoryTable