import { IonModal, IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonLoading, IonItem, IonTextarea, IonButton } from '@ionic/react';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, trendingUpSharp, trashBinOutline, closeOutline, peopleOutline} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';

const ShareProject = (props:any) =>{
    const [hideShareModal, showShareModal]                      =   useState<boolean>(false)
    const [getActiveUsers, setActiveUsers]                              = useState<any>()
    let txtEmailShare:any                                       =   useRef<HTMLIonInputElement>()
    const setShare = () =>{
        //setShowLoading(true)
        fetch(props.state.primary_host+"/ar/dbconnect/insert.jsp?etl=insertanalyticalreviewscontacts"+
            "&analytical_reviews_id="+props.state.analytical_reviews_id+
            "&email="+txtEmailShare.current!.value+
            "&created_by="+props.state.user_id 
            ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            //showShareModal(false)
            callProjectContacts()
        })
        .catch(error=>{ 
            //setShowLoading(false)
        })
    }
    const callProjectContacts = () =>{
        //alert(0)
        var list:any = []
        fetch(props.state.primary_host+"/ar/dbconnect/select.jsp?etl=selectanalyticalreviewscontacts"+
            "&analytical_reviews_id="+props.state.analytical_reviews_id
            ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            //alert(1)
            list.push(data.data);
            //alert(2)
            var contacts:any = list[0].map((x:any, i:number)=>{
                return(
                    <IonRow>
                        <IonCol size="11">{x.email}</IonCol>
                        <IonCol size="1"><IonIcon color="danger" onClick={()=>{}} icon={closeOutline}></IonIcon></IonCol>
                    </IonRow>
                )
            })
            //alert(contacts)
            setActiveUsers(contacts)
        })
    }
    const [hideModal, showModal]                    = useState<any>()
    const [getState, setState]                      = useState<any>()
    useEffect(() => {
        
            callProjectContacts()
    }, []);
    return(
        <div>
            <IonRow>&nbsp;</IonRow>
            <IonRow>
                <IonCol className="size-20"><b>Enter Email Of User</b></IonCol>
            </IonRow>
            <IonRow>
                <IonCol  size="12" className="size-20 text-black">
                <IonItem>
                    <IonLabel position='floating'>Email</IonLabel>
                    <IonInput ref={txtEmailShare}></IonInput>
                </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="size-20"><b>Active Users</b></IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    {getActiveUsers}
                </IonCol>
            </IonRow>
            <IonFooter>
                <IonRow>
                    <IonCol className="ion-text-left"></IonCol>
                    <IonCol className="ion-text-right">
                    <div className="fetch-button-modal-continue" onClick={()=>{setShare();}} >Share</div>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </div>
    )
}
export default ShareProject