export function isNumeric(str:any){
    //console.log("test:"+isNaN(str/1))
    if(!isNaN(str/1) && str != ' ' && str != ''){
        return true
    }else{
        return false
    }
}

export const addCommas = (nStr:any) =>{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export function isDate(s:any){
	var bits = s.split('-');
	console.log(bits[0] + '/' + bits[1] + '/' + bits[2])
		var d = new Date(bits[0] + '/' + bits[1] + '/' + bits[2]);
		return !!(d && (d.getMonth() + 1) == bits[1] && d.getDate() == Number(bits[2]));
}

export const setJournalFilter = (id:any, trCodeList:any, jnlFilter:any) =>{
    for(var i = 0; i < trCodeList.length; i++){
        console.log("Before:"+trCodeList[i].id+" "+trCodeList[i].isChecked)
        if(trCodeList[i].id == id){
            trCodeList[i].isChecked = !trCodeList[i].isChecked;
        }
        console.log("After:"+trCodeList[i].id+" "+trCodeList[i].isChecked)
    }
    
    jnlFilter="";
    for(var i = 0 ; i < trCodeList.length; i++){
        if(trCodeList[i].isChecked && jnlFilter.length > 0){
            jnlFilter = jnlFilter + ",\"jnl_"+ trCodeList[i ].id+"\"";
        }
        if(trCodeList[i].isChecked && jnlFilter.length == 0){
            jnlFilter = "\"jnl_"+ trCodeList[i].id+"\"";
        }
        
    }
}