import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRadio, IonRadioGroup, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonModal } from '@ionic/react';
import { addCircleOutline, analytics, analyticsOutline, arrowForwardCircleOutline, caretDown, caretUp, checkmarkCircleOutline, chevronForward, cogOutline, listOutline, notifications, removeCircleOutline, returnUpBack, settingsOutline, trashBinOutline } from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import { addCommas, callAnalyticalReviews, callSummaryDetail, setDateFormatHighCharts } from '../../dbconnections/Select';
import { isNumeric } from '../../functions/functions';

const BudgetEntryFunctions = (props:any) =>{
    const [getInitialValue, setInitialValue]        = useState<any>(false) 
    const [getDerivedValue, setDerivedValue]        = useState<any>(false)
    const [getCustomValue, setCustomValue]          = useState<any>(false)
    const [getAnotherColumn, setAnotherColumn]      = useState<any>(false)
    const [getColumns, setColumns]                  = useState<any>()

    let txtInitialValue:any                         = useRef<any>(null)
    return(
        <div>
            <IonRow>
                <IonCol><b>Using</b></IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="6">
                    <div onClick={()=>{setInitialValue(true); setDerivedValue(false)}} className="kpi-text-icon text-black">
                        Initial Value
                    </div>
                </IonCol>
                <IonCol size="6">
                    <div onClick={()=>{setInitialValue(false); setDerivedValue(true)}} className="kpi-text-icon text-black">
                        Derived Value
                    </div>
                </IonCol>
            </IonRow>
            <IonRow>
                {getInitialValue &&
                <IonCol size="12">
                    <IonRow>
                        <IonCol><b>Initial Value</b></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>Initial Value</IonLabel>
                                <IonInput ref={txtInitialValue} value={props.initial_value} type="number" />
                            </IonItem>
                        </IonCol>
                    </IonRow>    
                        
                </IonCol>
                }
                {getDerivedValue &&
                <IonCol size="12">
                    <IonRow>
                        <IonCol><b>Derive Using</b></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="kpi-text-icon">Another Column</IonCol>
                        <IonCol className="kpi-text-icon">Custom Value</IonCol>
                    </IonRow>
                    {getCustomValue &&
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>Custom Value</IonLabel>
                                <IonInput ref={txtInitialValue} value={props.initial_value} type="number" />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    }
                    {getAnotherColumn &&
                    <IonRow>
                        <IonCol>
                            {getColumns}
                        </IonCol>
                    </IonRow>
                    }

                </IonCol>
                }
            </IonRow>
            <IonRow>
                <IonCol><b>Rule</b></IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="kpi-text-icon">+</IonCol>
                <IonCol className="kpi-text-icon">-</IonCol>
                <IonCol className="kpi-text-icon">/</IonCol>
                <IonCol className="kpi-text-icon">*</IonCol>
                <IonCol className="kpi-text-icon">^</IonCol>
                <IonCol className="kpi-text-icon">%</IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel>Value</IonLabel>
                        <IonInput ref={txtInitialValue} value={props.initial_value} type="number" />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol><b>Mode Of Application</b></IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="kpi-text-icon">Linear </IonCol>
                <IonCol className="kpi-text-icon">Compounded </IonCol>
            </IonRow>
            <IonRow>
                <IonCol><b>Apply</b></IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="6">
                    <div className="kpi-text-icon text-black">
                        On All Periods This FY
                    </div>
                </IonCol>
                <IonCol size="6">
                    <div className="kpi-text-icon text-black">
                        On All Periods
                    </div>
                </IonCol>
            </IonRow>
        </div>
    )
}
export default BudgetEntryFunctions;