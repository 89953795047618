import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useState,useEffect, useRef } from 'react';
import { apps, businessOutline, listOutline, logoAndroid, peopleCircleOutline, peopleOutline, swapHorizontalOutline, time, timeOutline, trashBinOutline } from 'ionicons/icons';

import '../theme/fonts/poppins-cufonfonts-webfont/Poppins-Regular.woff'
import SideBar from '../components/SideBar/SideBar';
import '../components/SideBar/SideBar.css';
import Reports from '../components/SideBar/SubComponents/Reports';
import '../components/SideBar/SubComponents/Reports.css';
import ProjectsSide from '../components/SideBar/SubComponents/ProjectsSide';
import '../components/SideBar/SubComponents/ProjectsSide.css';
import {callAnalyticalReviews} from '../dbconnections/Select'
import './Home.css';
import Settings from '../components/SideBar/SubComponents/Settings';
import '../components/SideBar/SubComponents/Settings.css';
import Custom from '../components/SideBar/SubComponents/Custom';
import '../components/SideBar/SubComponents/Custom.css';
import Main from '../components/Forms/Main';
import '../components/Forms/Main.css';
import Navigation from '../components/Forms/Navigation'; 
import CustomForm from '../components/Forms/Custom';
import Login from '../components/Forms/Login';
import { setFlagsFromString } from 'v8';
import Import from '../components/DataImport/Import';
import Terms from '../components/Modals/Terms';
import GlobalSettings from '../components/Forms/GlobalSettings';
import PredictiveForm from '../components/Forms/PredictiveForm';
import JetsMain from '../components/Forms/JetsMain';
import VCMain from '../components/Forms/VCMain';
import Contracktor from '../components/Forms/Contracktor';
import Learning from '../components/MachineLearning/Forms/ML';
import Bot from '../ChatBot/bot';
import '../ChatBot/bot.css';
import TransactionMapping from '../components/Forms/TransactionMapping';
import MigrationMain from '../components/Forms/Migration/Main';
import Inventory from '../components/Forms/Inventory/Main';


var application_id:any          = 0;
let ar_name:any                 = ""

const Home = () => {
  const [getTransactinsMappingForm, setTransactionsMappingForm] = useState<any>()
  const [getDataMigrationForm, setDataMigrationForm]        = useState<any>()
  const [getBotStep, setBotStep]                            = useState<any>()
  const [getForename, setForename]                          = useState<any>()
  const [getHideBot, setHideBot]                            = useState<any>()
  const [getPanelHeight, setPanelHeight]                    = useState<any>()
  const [getPage, setPage]                                  = useState<any>("bot-panel-up")
  const [getActiveUsers, setActiveUsers]                    = useState<any>()
  const [getState, setState]                                = useState<any>()
  const [hideSideBarClass, showSideBarClass]                = useState<string>("show-side-bar-v-spacing-ar")
  const [getReports, setReports]                            = useState<string>("report-area-hide");
  const [getSettings, setSettings]                          = useState<string>("settings-area-hide");
  const [getCustom, setCustom]                              = useState<string>("custom-area-hide");
  const [getLiveConnections, setLiveConnections]            = useState<string>("live-connections-area-hide")
  const [hideNavBeamARClass,showNavBeamARClass]             = useState<String>("shrink-nav-beam-ar")
  const [getLiveConnectionsList, setLiveConnectionsList]    = useState<any>()
  const [getLocation, setLocation]                          = useState<any>(0)
  const [getBalanceSheet, setBalanceSheet]                  = useState<any>(null)
  const [getYears, setYears]                                = useState<any>()
  const [getSideBarOption, setSideBarOption]                = useState<any>()
  const [getSideBarObject, setSideBarObject]                = useState<any>()
  const [getData, setData]                                  = useState<any>()
  const [getAnalyticalReviewsId, setAnalyticalReviewsId]    = useState<any>()
  const [getProject, setProject]                            = useState<any>()
  const [getLogin, setLogin]                                = useState<any>(true)
  const [getMain, setMain]                                  = useState<boolean>(true)
  const [getJetsMain, setJetsMain]                          = useState<boolean>(true)
  const [getCustomForm, setCustomForm]                      = useState<boolean>(false)
  const [getVCForm, setVCForm]                              = useState<boolean>(false)
  const [getSideFunctions, setSideFunctions]                = useState<any>()
  const [getImport, setImport]                              = useState<any>()
  const [hideTerms, showTerms]                              = useState<boolean>(false)
  const [getGlobalSettings, setGlobalSettings]              = useState<boolean>(false)
  const [getHome, setHome]                                  = useState<boolean>(false)
  const [getContactId, setContactId]                        = useState<any>()
  const [getSideBarList, setSideBarList]                    = useState<any>()
  const [getPredictiveForm, setPredictiveForm]              = useState<any>(false)
  const [getApplicationId, setApplicationId]                = useState<any>()
  const [getSide, setSide]                                  = useState<any>(true)
  const [getContracktorForm, setContracktorForm]            = useState<any>(false)
  const [getMachineLearningForm, setMachineLearningForm]    = useState<any>()
  const [getInventoryForm, setInventoryForm]                = useState<any>(false)
  var data:any;   
  var state:any ={
    //primary_host:'http://www.dmzee.co.za/',
    primary_host:'https://www.dmzee.co.za/',
    //primary_host:'https://dmzee.sng.za.com/',n
    user_id:getContactId,
    forename:'',
    surname:'',
    email:'',
    initial:'',
    application_id:67,
    analytical_reviews_id:0,
    migration_id:0,     
    start_date:'',       
    end_date:'',            
    data_start_date:'',   
    data_end_date:'',     
    current_end_date:'',  
    target_currency_id:88,
    client_name:'',       
    account_level_3:1,  
    account_level_3_desc:'',
    account_level_2:0,
    account_level_2_desc:'',
    account_level_1:0,
    account_level_1_desc:'',
    account_level_0:0, 
    account_level_0_desc:'',
    accounts_count:0,    
    client_secret:'',     
    client_id:'',
    max_data_year:'',  
    max_data_month:'',
    compare_mode:0,   
    get_location:getLocation, 
    location:getLocation,
    side_bar_option:getSideBarOption, 
    side_bar_option_obj:getSideBarObject,
    years:(e:any)=>{setYears(e)}, 
    set_location:(e:any)=>{setLocation(e)},
    balance_sheet:(e:any)=>{setBalanceSheet(e)},
    date_shortcut:0, 
    analytical_reviews_data:(e:any)=>{setData(e)}
  }
  const [getDemo, setDemo]              = useState<any>("")
  const [hideLive, showLive]            = useState<any>()
  const isDemo = (contact_id:any) =>{
    if(contact_id == 11289){
      //alert("contact_id")
      setDemo("disabled-card")
      showLive("demo-show")
    }else{
      setDemo("")
      showLive("demo-hide")
    }
  }
  const dbCall = async (db:any) =>{
    if (db == 0){

    }
    if (db == 1){
      data = await callAnalyticalReviews(state)
      setData(data)
      console.log(data);
      setState(state)
       //setLiveConnectionsListx()
    }
  } 
  
  const setBar = (s:any) =>{
    resetBar()
    switch(s){
      case -1: setView(5); break;
      case 0: break;
      case 1: dbCall(1);setLiveConnections("live-connections-area-show");break;
      case 2: setReports("report-area-show");break;
      case 3: setSettings("settings-area-show");;break;
      case 4: setCustom("custom-area-show");break;
      case 5: setView(3); break;
      case 6: setView(4); break;
      case 7: setView(6); break;
      case 8: setSideBarList(4);setView(7);break;
    }
  }
  
  const resetBar = () =>{
    setCustom("custom-area-hide");
    setLiveConnections("live-connections-area-hide");
    setReports("report-area-hide");
    setSettings("settings-area-hide");
    //setView(1)
  }

  const setView = (v:any) =>{
    resetView()
    switch(v){
      case 0 :  setLogin(true)      ;break;
      case 1 :  setMain(true);
                dbCall(1)
                break;
      case 2 :  setCustomForm(true) ;break;
      case 3 :  setImport(true);    ;break;
      case 4 :  setGlobalSettings(true);  ;break;
      case 5 :  setHome(true);break;
      case 6 :  setPredictiveForm(true); break;
      case 7 :  setSideBarList(4);setJetsMain(true);break;
      case 8 :  setVCForm(true);setSide(false);break;
      case 9 :  setContracktorForm(true); setSide(false);break;
      case 10 : setMachineLearningForm(true); showSideBarClass("hide-side-bar-v-spacing-ar"); break;
      case 11 : setTransactionsMappingForm(true);setSide(false); break;
      case 12 : setDataMigrationForm(true);setSide(false); break;
      case 13 : setInventoryForm(true);setSide(false); break;
    }
  }

  const resetView = () =>{

    setLogin(false)
    setMain(false)
    setCustomForm(false)
    setImport(false)
    showTerms(false)
    setGlobalSettings(false)
    setHome(false)
    setPredictiveForm(false);
    setJetsMain(false)
    setVCForm(false)
    setSide(true)
    setContracktorForm(false)
    setMachineLearningForm(false);
    setTransactionsMappingForm(false);
    setDataMigrationForm(false);
    setInventoryForm(false)
  }
  useEffect(() => {
    //dbCall(1)
    setState(state)
    setApplicationId(0);
    //setBotStep(1)
  }, []);
  return (
    <IonPage>
       
      {!getLogin &&
      <IonHeader>
        <div className="nav-width">
          <Navigation 
            location={getLocation} 
            years={getYears}
            project={getProject}
          />
        </div>
      </IonHeader>
      }
      <div className="body">
        
        {getLogin &&
          <Login 
            state={state}
            show_terms={(e:any)=>{showTerms(e)}}
            logged_in={(e:any)=>{
              console.log(e)
              state.email     = e.email
              state.user_id   = e.user_id
              state.forename  = e.forename
              state.surname   = e.surname
              state.initial   = e.initial
              state.location  = e.set_view; 
              
              setForename(e.forename)
              if(e.user_id != 0){
                setContactId(e.user_id)
                setSideFunctions(2)
                setView(5); 
                isDemo(e.user_id)
              }else{
                //alert("Login Failed")
              }
            }}
          />
        }
        {!getLogin &&
        <div>
           
           {getSide &&
          <div className={"containereb-ar "+hideSideBarClass}>
            <SideBar 
              state={getState} 
              functions={getSideFunctions}
              side_bar_list={getSideBarList}
              setSideBarOption={(o:any)=>{
                console.log("side bar option:"+o[0])
                console.log("side bar object:"+o[1])
                setSideBarOption(o[0]);
                setSideBarObject(o[1]);
              }} 
              setBar={(b:any)=>{setBar(b)}}
              set_application={(e:any)=>{application_id = 67; setApplicationId(67); setView(3)}}
            />
          </div>
          }

          <div className={getReports}>
            <Reports 
              state={state} 
              setReports={(s:any)=>{setReports(s)}} 
              location={(l:any)=>{
                setView(1)
                setLocation(l); 
                resetBar()}
              }
            />
          </div>
         
          <div className={getLiveConnections}>
            <ProjectsSide 
              state={state} 
              data={getData}
              setAnalyticalReviewsId={(e:any)=>{console.log(e);state.analytical_reviews_id = e;setAnalyticalReviewsId(e);setView(1)}}
              setState={(id:any)=>(state.analytical_report_id=id)}
              setLiveConnections={(e:any)=>{setLiveConnections(e)}}
              getLiveConnectionsList={getLiveConnectionsList}
              setPageView={(e:any)=>{}} 
              setOrganizationId={(e:any)=>{}} 
              setOrganzationName={(e:any)=>{}} 
              setLastUpdate={(e:any)=>{}} 
              setYearEndDay={(e:any)=>{}} 
              setYearEndMonth={(e:any)=>{}} 
              setAuthorizedOrganization={(e:any)=>{}} 
              setIndustry={(e:any)=>{}} 
              setSector={(e:any)=>{}} 
              setNativeCurrency={(e:any)=>{}} 
              showSettingStage={(e:any)=>{}} 
              callXeroAvailableYears={(e:any)=>{}} 
              
            />
          </div>
          <div className={getSettings}>
            <Settings setSettings={(e:any)=>{setSettings(e)}}/>
          </div>
          <div className={getCustom}>
            <Custom 
              setCustom={(e:any)=>{setCustom(e)}} 
              setView={(e:any)=>{setView(e)}}
            />
          </div>
          {getHome &&
            <div className="home-section ion-padding size-16">
              <IonRow>
                <IonCol size="4" >
                  <IonCard className="app-card" >
                  <IonRow>
                      <IonCol size="3" className="ion-padding ion-text-center">
                        <IonIcon icon={apps} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>Analytical Reviews</b></IonCardHeader>
                        <IonCardContent>
                          Import, process and analyse accounting information.
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{setSideBarList(2); setView(1); application_id = state.application_id=67}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black">Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow> 
                  </IonCard>
                </IonCol>
                <IonCol size="4">
                  <IonCard className="app-card">
                    <IonRow>
                      <IonCol size="3" className="ion-padding ion-text-center">
                        <IonIcon icon={listOutline} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>Journal Entry Testing</b></IonCardHeader>
                        <IonCardContent>
                          Run 15 standard audit tests on financial accounting data.
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{setView(7); application_id = state.application_id=66}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black">Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow> 
                  </IonCard>
                </IonCol>
                <IonCol size="4" className={hideLive}>
                  <IonCard className={"app-card " + getDemo}>
                    <IonRow>
                      <IonCol size="3" className="ion-padding ion-text-center">
                        <IonIcon icon={businessOutline} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>Custom Charting</b></IonCardHeader>
                        <IonCardContent>
                          Create customize charts using financial information obtained from you projects. 
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{setView(2)}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black">Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
                <IonCol size="4" className={hideLive}>
                  <IonCard className={"app-card " + getDemo}>
                    <IonRow>
                      <IonCol size="3" className="ion-padding ion-text-center">
                        <IonIcon icon={businessOutline} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>Inventory Analysis</b></IonCardHeader>
                        <IonCardContent>
                          Inventory analysis of stock items. <br/>
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{setView(13)}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black">Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
                <IonCol size="4" className={hideLive}>
                  <IonCard className={"app-card " + getDemo}>
                    <IonRow>
                      <IonCol size="3" className="ion-padding ion-text-center">
                        <IonIcon icon={businessOutline} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>Predictive Analytics</b></IonCardHeader>
                        <IonCardContent>
                          Statistical analysis of financial information. <br/>
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{setView(6)}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black">Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
                <IonCol size="4" className={hideLive}>
                  <IonCard className={"app-card " + getDemo}>
                    <IonRow>
                      <IonCol size="3" className="ion-padding ion-text-center">
                        <IonIcon icon={businessOutline} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>Venture Capital & Banking</b></IonCardHeader>
                        <IonCardContent>
                          Combine qualitative and quantitative analysis information. 
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{setView(8)}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black">Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
                <IonCol size="4" className={hideLive}>
                  <IonCard className="app-card ">
                    <IonRow>
                      <IonCol size="3" className="size-36 ion-padding ion-text-center">
                        <IonIcon icon={timeOutline} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>My Time</b></IonCardHeader>
                        <IonCardContent>
                          Manage project, tasks and time with your team members.
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black" >Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
                <IonCol size="4" className={hideLive}>
                  <IonCard className={"app-card " + getDemo}>
                    <IonRow>
                      <IonCol size="3" className="size-36 ion-padding ion-text-center">
                        <IonIcon icon={peopleOutline} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>Con-Track-Tor</b></IonCardHeader>
                        <IonCardContent>
                          Track, Reconcile & Manage contractor payments and part payments.
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{setView(9)}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black" >Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
                <IonCol size="4" className={hideLive}>
                  <IonCard className={"app-card " + getDemo}>
                    <IonRow>
                      <IonCol size="3" className="size-36 ion-padding ion-text-center">
                        <IonIcon icon={logoAndroid} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>Machine Learning</b></IonCardHeader>
                        <IonCardContent>
                          Train Machine Learing system to recognize, process & format financial data
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{setView(10)}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black" >Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
                <IonCol size="4" className={hideLive}>
                  <IonCard className={"app-card " + getDemo}>
                    <IonRow>
                      <IonCol size="3" className="size-36 ion-padding ion-text-center">
                        <IonIcon icon={logoAndroid} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>Transactions Mapping</b></IonCardHeader>
                        <IonCardContent>
                          Train mapping system to recognize misposted financial data
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{setView(11)}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black" >Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
                <IonCol size="4" className={hideLive}>
                  <IonCard className={"app-card " + getDemo}>
                    <IonRow>
                      <IonCol size="3" className="size-36 ion-padding ion-text-center">
                        <IonIcon icon={swapHorizontalOutline} className="size-72 text-gt" ></IonIcon>
                      </IonCol>
                      <IonCol size="9">
                        <IonCardHeader className="text-black"><b>Data Migration</b></IonCardHeader>
                        <IonCardContent>
                          Test & validate migrated data<br/>
                        </IonCardContent>
                        <IonFooter className="ion-padding">
                          <IonRow>
                            <IonCol></IonCol>
                            <IonCol onClick={()=>{setView(12)}} size="4" className="ion-text-right custom-account-text-icon">
                              <div className="ion-text-center text-black" >Open</div>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>
            </div>
          }
          {getVCForm &&
            <VCMain 
              global_props={state}
            />
          }
          {getMain &&
          <div className="bs-area-to-print ion-padding">
            <Main 
              balance_sheet={getBalanceSheet} 
              location={getLocation} 
              global_props={state}
              analytical_reviews_id= {getAnalyticalReviewsId}
              years={(e:any)=>{setYears(e)}}
              set_project={(e:any)=>{setProject(e)}}
              setView={(e:any)=>{state.location = e; setLocation(state)}}
              setSideBarList={(e:any)=>{setSideBarList(e);}}
              set_application={(e:any)=>{ setView(3)}}
            />
          </div>
          }
          {getJetsMain &&
          <div className="bs-area-to-print ion-padding">
            <JetsMain
              location={getLocation} 
              global_props={state}
              analytical_reviews_id= {getAnalyticalReviewsId}
              years={(e:any)=>{setYears(e)}}
              set_project={(e:any)=>{setProject(e)}}
              setView={(e:any)=>{state.location = e; setLocation(state)}}
              setSideBarList={(e:any)=>{setSideBarList(e);}}
              set_application={(e:any)=>{ setView(3)}}
            />
          </div>
          }
          {getCustomForm &&
          <div className="custom-area-to-print ion-padding">
            <CustomForm 
              props={state} 
              analytical_reviews_id= {getAnalyticalReviewsId}
            />
          </div>
          }
          {getImport &&
            <Import 
              props={state} 
              application_id={application_id}
            />
          }
          {getGlobalSettings &&
          <div className="custom-area-to-print ion-padding">
            <GlobalSettings 
                userId={state.user_id} 
                forename={state.forename}  
                surname={state.surname} 
                email={state.email} 
                organization={state.organization_name}
                mobile={state.mobile}
                primaryhost={state.primary_host}
            />
          </div> 
          }
          {getPredictiveForm &&
            <div className="custom-area-to-print ion-padding">
              <PredictiveForm 
                props={state} 
                analytical_reviews_id= {getAnalyticalReviewsId}
                setHome = {(e:any)=>{setView(5)}}
              />
            </div>
          }
          {getContracktorForm &&
          <div  className="custom-area-to-print ion-padding">
            <Contracktor 
              props = {state}
              setHome = {(e:any)=>{setView(5)}}
            />
          </div>
          }
          {getMachineLearningForm &&
          <div  className="custom-area-to-print ion-padding">
            <Learning 
              props = {state}
              state = {state}
              setHome = {(e:any)=>{setView(5)}}
              set_bot_step = {getBotStep}
              get_bot_step = {(e:any)=>{setBotStep(e)}}
            />
          </div>
          }
          {getDataMigrationForm &&
            <div  className="custom-area-to-print ion-padding">
              <MigrationMain 
                props = {state}
                state = {state}
                application_id ={-2}
              />
            </div>
          }
          {getInventoryForm &&
            <div  className="custom-area-to-print ion-padding">
              <Inventory 
                props = {state}
                state = {state}
                application_id ={-2}
              />
            </div>
          }
          {getTransactinsMappingForm &&
          <div  className="custom-area-mapping ion-padding">
            <TransactionMapping 
              props = {state}
              state = {state}
              setHome = {(e:any)=>{setView(5)}}
            />
          </div>
          }
        {/*
        <div  className={getPage+ " " +getPanelHeight+" hover"}>
          <Bot 
            state={state}
            forename={getForename}
            set_hide_bot={getHideBot}
            get_position={getPage}
            set_panel_height={(e:any)=>{setPanelHeight(e)}}
            set_position={(e:any)=>{setPage(e);}}
            application_id={getApplicationId}
            setApplication={(e:any)=>{setView(e.view); state.application_id= application_id;setApplicationId(state.application_id)}}
            step={getBotStep}
          />
        </div>
        */}
      </div>
      }
    
    </div>
      
    </IonPage>
  );
};

export default Home;
