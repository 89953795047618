import { IonSpinner, IonListHeader, IonButton,IonCheckbox, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonTextarea, IonBadge, IonButtons, useIonModal } from '@ionic/react';
import { alertCircleSharp, analytics, analyticsOutline, analyticsSharp, arrowBackSharp, arrowForwardSharp, bluetoothSharp, calendarOutline, checkmarkCircle, checkmarkCircleSharp, checkmarkDone, checkmarkDoneCircle, checkmarkOutline, closeOutline, cogOutline, copySharp, documentAttachOutline, exitOutline, filterSharp, gridOutline, hammerOutline, homeOutline, listOutline, listSharp, logInOutline, logOut, logOutOutline, mailOutline, mailSharp, pencilOutline, peopleCircleSharp, peopleOutline, personOutline, personSharp, print, push, returnUpBack, sendOutline, settingsOutline, settingsSharp, stopCircleSharp, trashBin, trashBinSharp, trashOutline, warningOutline, warningSharp, wifiOutline } from 'ionicons/icons';
import React,{ useRef, useState } from 'react';
import JsPDF from 'jspdf';
import $ from 'jquery'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { File } from '@ionic-native/file';

const InvoiceTemplate = (props:any) =>{

    const generatePdf =()=>{
        const div = document.getElementById("invoice");
        const options = {background:"white",height :div!.clientHeight , width : div!.clientWidth  };
        html2canvas(div!,options).then((canvas)=>{
          //Initialize JSPDF
          var doc = new jsPDF("p","mm","a4");
          //Converting canvas to Image
          let imgData = canvas.toDataURL("image/PNG");
          //Add image Canvas to PDF
          doc.addImage(imgData,'PNG',30,10,150,105);
          doc.save('Billing Notice 0000'+props.invoice_number+'.pdf')
          var x:any = []
          x.push({invoice_number:props.invoice_number})
          console.log(x)
          props.lockInvoice(props.invoice_number)
          
        });
    }
    return(
    <div>
    <IonRow className="header-fixed">
        <IonCol size="8"></IonCol>
        <IonCol size="2">
            <div onClick={()=>{props.proceedToPayment(1)}} className="main-button ion-text-center">
                Confirm Payment
            </div>
        </IonCol>
        <IonCol size="2">
            <div onClick={generatePdf} className="main-button ion-text-center">
                <IonIcon icon={print} className="size-20" ></IonIcon> &nbsp;
                Print Invoice & Confirm
            </div>
        </IonCol>
    </IonRow>
    <div id="invoice">
        <IonRow className="ion">
            <IonCol className="size-12" size="12">
                <IonRow>
                    <IonCol size="3">
                        <IonImg src="../../assets/images/yauro_logo.png" />
                    </IonCol>
                    <IonCol className="background-image ion-text-right ion-padding">
                        7 Marga Court,<br/>
                        43 Susman Avenue,<br/>
                        Blairgowrie,<br/>
                        Randburg,<br/>
                        Gauteng<br/>
                        2194
                    </IonCol>
                </IonRow>
            </IonCol>
            <IonCol className="size-12" size="6">
                <IonRow>
                </IonRow><IonRow>
                </IonRow><IonRow>
                </IonRow><IonRow>
                    <IonCol size="6" className="ion-text-left size-20"><b>Tax Invoice</b></IonCol>
                </IonRow><IonRow>
                    <IonCol size="6" className="ion-text-left"><b>Invoice Date</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right">{props.invoice_date}</IonCol>
                </IonRow><IonRow>
                    <IonCol size="6" className="ion-text-left"><b>Invoice Number</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right">{props.invoice_number}</IonCol>
                </IonRow><IonRow>
                    <IonCol size="6" className="ion-text-left"><b>Vendor Number</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right">{props.vendor_number}</IonCol>
                </IonRow><IonRow>
                    <IonCol size="6" className="ion-text-left"><b>Company</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right"><div dangerouslySetInnerHTML={{__html:props.company_address}}></div><br/>VAT No. {props.vat_number}</IonCol>
                </IonRow><IonRow>
                </IonRow><IonRow>
                </IonRow><IonRow>
                </IonRow><IonRow>
                    <IonCol size="6" className="ion-text-left"><b>Job Description</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right">{props.job_description}</IonCol>
                </IonRow><IonRow>
                    <IonCol size="6" className="ion-text-left"><b>Total Work</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right">{props.total_work}</IonCol>
                </IonRow><IonRow>
                    <IonCol size="6" className="ion-text-left"><b>Rate</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right">{props.average_rate}</IonCol>
                </IonRow><IonRow>
                    <IonCol size="6" className="ion-text-left"><b>Other Charges</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right">{props.other_charges}</IonCol>
                </IonRow><IonRow className="border-top border-bottom">
                    <IonCol size="6" className="ion-text-left"><b>Invoice Amount (Excl. VAT)</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right">{props.total_invoice_ex_vat}</IonCol>
                </IonRow><IonRow>
                    <IonCol size="6" className="ion-text-left"><b>VAT @{props.vat_rate}%</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right">{props.vat}</IonCol>
                </IonRow><IonRow className="border-top border-bottom">
                    <IonCol size="6" className="ion-text-left"><b>Invoice Amount (Incl.VAT)</b></IonCol>
                    <IonCol size="1">:</IonCol>
                    <IonCol className="ion-text-right">{props.total_invoice_incl_vat}</IonCol>
                </IonRow>
            </IonCol> 
            <IonCol size="12">   
                <IonRow className="size-8">
                    <IonCol className="ion-text-left">
                        Yauro Software (Pty) Ltd Registration Number:K2016090371
                    </IonCol>
                    <IonCol className="ion-text-center">
                        VAT Registration Number:4930278470
                    </IonCol>
                    <IonCol className="ion-text-center">
                        Directors: A S Musengezi, A Musengezi
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    </div>
    </div>
    )
}
export default InvoiceTemplate