import { IonSearchbar, IonLoading, IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSelect, IonSelectOption, IonSpinner, IonThumbnail, IonTitle, IonToolbar, IonTextarea, IonPopover, IonVirtualScroll, IonCheckbox, IonCardTitle } from '@ionic/react';
import { apps,starOutline, ellipsisVerticalOutline, businessOutline, checkmarkOutline, analyticsOutline, analyticsSharp, arrowBackSharp, cloudOutline, documentOutline, filterSharp, gridOutline, homeOutline, list, listOutline, logInOutline, logOutOutline, menuOutline, personOutline, pulseOutline, settingsOutline, wifiOutline, trashBin, trashBinOutline, checkmarkCircle, closeCircle, refreshCircle, caretUp, caretDown, pulseSharp, pulse, notifications, notificationsCircle, pencilSharp, closeOutline, trashOutline, star, arrowForwardCircleOutline, print, chevronForward, chevronDown, searchOutline, videocamOutline, codeDownload, clipboardOutline, logoGoogle, logoGooglePlaystore, shareOutline, returnUpBack, analytics, grid, createOutline, addOutline, statsChartOutline, heartDislikeSharp, searchCircle } from 'ionicons/icons';
import React,{ useRef, useState,  } from 'react';
import Highcharts, { isNumber } from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import './PredictiveForm.css' 
import ProjectsSide from '../SideBar/SubComponents/ProjectsSidePredictive';
import { callAccountComparisonByYear, callAccountComparisonByYearCorrelationMatrix, callAnalyticalReviews, callYearlyComparison } from '../../dbconnections/Select';
import SkewTable from '../Tables/Predictive/SkewTable';
import CorrelationTable from '../Tables/Predictive/CorrelationTable';
import { isNumeric } from '../../functions/functions';
import { Resizable } from 're-resizable';
    var items:any = [9,10,25,26]
    var start_date:any              = '2021-01-01';
    var end_date:any                = '2021-12-31';
    var account_level_0:any         ='0';
    var account_level_1:any         ='0';
    var account_level_2:any         ='0';
    var account_level_3:any         ='0';
    let dim3List:any                =[];
    let dim3Filter:any              = "";
    let action_type_id:any          = 1;
    let custom_chart_id:any         =0;
    const setDim3Filter = (id:any) =>{
        for(var i = 0; i < dim3List.length; i++){
            console.log("Before:"+dim3List[i].id+" "+dim3List[i].isChecked)
            if(dim3List[i].id == id){
                dim3List[i].isChecked = !dim3List[i].isChecked;
            }
            console.log("After:"+dim3List[i].id+" "+dim3List[i].isChecked)
        }
        
        dim3Filter="";
        for(var i = 0 ; i < dim3List.length; i++){
            if(dim3List[i].isChecked && dim3Filter.length > 0){
                dim3Filter = dim3Filter + ","+ dim3List[i ].id+"";
            }
            if(dim3List[i].isChecked && dim3Filter.length == 0){
                dim3Filter = ""+ dim3List[i].id+"";
            }
            
        }
        console.log(dim3Filter)
    }
    let dim2List:any                =[];
    let dim2Filter:any              = "";
    const setDim2Filter = (id:any) =>{
        for(var i = 0; i < dim2List.length; i++){
            console.log("Before:"+dim2List[i].id+" "+dim2List[i].isChecked)
            if(dim2List[i].id == id){
                dim2List[i].isChecked = !dim2List[i].isChecked;
            }
            console.log("After:"+dim3List[i].id+" "+dim3List[i].isChecked)
        }
        
        dim2Filter="";
        for(var i = 0 ; i < dim2List.length; i++){
            if(dim2List[i].isChecked && dim2Filter.length > 0){
                dim2Filter = dim2Filter + ","+ dim2List[i ].id+"";
            }
            if(dim2List[i].isChecked && dim2Filter.length == 0){
                dim2Filter = ""+ dim2List[i].id+"";
            }
            
        }
        console.log(dim2Filter)
    }
    var existing:any = []
    var properties:any = ''
    const PredictiveFormTreatment = (props:any) =>{
        const [getChart1, setChart1]                                    = useState<any>()
        const [getChart2, setChart2]                                    = useState<any>()
        
        

        const drawlineSeries = (type:any, series:any, chart:any, chartTitle:any, axisType:any) =>{
            let o:any = null
            setChart1(o)
            let lineChart:any = {
                            chart:{
                                type: type,
                            },
                            title:{
                                text:''
                            },
                            subTitle:{
                                text:''
                            },
                            xAxis: {
                                type: axisType
                            },
                            series:series,
                            turboThreshold:0,
                            responsive: {
                                rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    layout: 'horizontal'
                                    },
                                    yAxis: {
                                    labels: {
                                        align: 'left',
                                        x: 0,
                                        y: -5
                                    },
                                    title: {
                                        text: chartTitle
                                    }
                                    },
                                    subtitle: {
                                    text: null
                                    },
                                    credits: {
                                    enabled: false
                                    }
                                }
                                }]
                            }
                        };
            
            let c:any = (<HighchartsReact onLoad={(e:any)=>{reflow(e.current)}} highcharts={Highcharts} options={lineChart}></HighchartsReact>)
            setChart1(c)
            
        }
        const drawlineSeries2 = (type:any, series:any, chart:any, chartTitle:any, axisType:any) =>{
            let o:any = null
            setChart2(o)
            let lineChart:any = {
                            chart:{
                                type: type,
                                
                            },
                            title:{
                                text:''
                            },
                            subTitle:{
                                text:''
                            },
                            xAxis: {
                                type: {axisType}
                            },
                            series:series,
                            turboThreshold:0,
                            responsive: {
                                rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    layout: 'horizontal'
                                    },
                                    yAxis: {
                                    labels: {
                                        align: 'left',
                                        x: 0,
                                        y: -5
                                    },
                                    title: {
                                        text: chartTitle
                                    }
                                    },
                                    subtitle: {
                                    text: null
                                    },
                                    credits: {
                                    enabled: false
                                    }
                                }
                                }]
                            }
                        };
            let c:any = (<HighchartsReact onLoad={(e:any)=>{reflow(e.current)}} highcharts={Highcharts} options={lineChart} ></HighchartsReact>)
            console.log(chart)
            setChart2(c)
            
        }
        const reflow = (obj:any) =>{
            alert()
            obj.chart.reflow()
        }

        const renderCharts3 = (data:any, chart:any) =>{
            var series:any =[]
            //var s:any =[]
            var y:any = 0;
            var prop:any;
            var headers:any = []
            for(prop in data.data[0]){
                if(y >= 0){
                    if( 
                        prop == "1 stdev above"     || 
                        prop == "2 stdev above"     ||
                        prop == "3 stdev above"     || 
                        prop == "1 stdev below"     || 
                        prop == "2 stdev below"     ||
                        prop == "3 stdev below"     ||
                        prop == "mean"              ||
                        prop == "netdaily trend"    || 
                        prop == "value" 
                        ){
                        headers.push(prop)
                    }
                }
            }
            console.log(headers)
            for (var j = 0 ; j < headers.length; j++){
                var s = []
                for(var i = 0 ; i < data.data.length; i++){
                    s.push({x:data.data[i].period/1, y:data.data[i][headers[j]]/1})
                }
                if( 
                    headers[j] == "1 stdev above" || 
                    headers[j] == "2 stdev above" ||
                    headers[j] == "3 stdev above" || 
                    headers[j] == "1 stdev below" || 
                    headers[j] == "2 stdev below" ||
                    headers[j] == "3 stdev below" ||
                    headers[j] == "mean"          ||
                    headers[j] == "netdaily trend"
                ){
                    if( headers[j] == "1 stdev above" || headers[j] == "1 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'purple', marker:{radius:0, color: 'yellow'}})
                    if( headers[j] == "2 stdev above" || headers[j] == "2 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'orange', marker:{radius:0, color: 'orange'}})
                    if( headers[j] == "3 stdev above" || headers[j] == "3 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'red', marker:{radius:0, color: 'red'}})
                    if( headers[j] == "netdaily trend")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dash', radius:0, color: 'green', marker:{radius:0, color: 'green'}})
                    if( headers[j] == "mean")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, radius:0, color: 'blue', marker:{radius:0, color: 'blue'}})
                }else{
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, radius:0, color: 'black', marker:{radius:0, color: 'black'}})
                }
                drawlineSeries("line", series, chart,data.data[0].account_level_1, 'linear' )
            }
            console.log(series)
            //series.push({name:data.data[0].account_level_1, data:s})
            //drawlineSeries("spline", series, chart, null)
            //setView(4)
            //setRatio(false)
            //setLinearProgression(false)
            //setTreatment(true)
            //setTreatmentForecast(false)
            console.log(series)
        }
        const renderCharts4 = (data:any, chart:any) =>{
            var series:any =[]
            //var s:any =[]
            var headers:any = []
            var prop:any = []
            var y:any = 0
            for(prop in data.data[0]){
                if(y >= 0){
                    //console.log(prop)
                    if( 
                        prop == "1 stdev above" || 
                        prop == "2 stdev above" ||
                        prop == "3 stdev above" || 
                        prop == "1 stdev below" || 
                        prop == "2 stdev below" ||
                        prop == "3 stdev below" ||
                        prop == "mean"          ||
                        prop == "netdaily trend" || 
                        //prop == "balance trend" ||
                        prop == "value" 
                        ){
                        headers.push(prop)
                    }
                }
            }
            console.log(headers)
            for (var j = 0 ; j < headers.length; j++){
                var s = []
                for(var i = 0 ; i < data.data.length; i++){
                    s.push({x:data.data[i].period/1, y:data.data[i][headers[j]]/1})
                }
                if( 
                    headers[j] == "1 stdev above" || 
                    headers[j] == "2 stdev above" ||
                    headers[j] == "3 stdev above" || 
                    headers[j] == "1 stdev below" || 
                    headers[j] == "2 stdev below" ||
                    headers[j] == "3 stdev below" ||
                    headers[j] == "mean"          ||
                    headers[j] == "netdaily trend"
                ){
                    if( headers[j] == "1 stdev above" || headers[j] == "1 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'purple', marker:{radius:0, color: 'yellow'}})
                    if( headers[j] == "2 stdev above" || headers[j] == "2 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'orange', marker:{radius:0, color: 'orange'}})
                    if( headers[j] == "3 stdev above" || headers[j] == "3 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'red', marker:{radius:0, color: 'red'}})
                    if( headers[j] == "netdaily trend")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dash', radius:0, color: 'green', marker:{radius:0, color: 'green'}})
                    if( headers[j] == "mean")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1,  radius:0, color: 'blue', marker:{radius:0, color: 'blue'}})
                }else{
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, radius:0, color: 'black', marker:{radius:0, color: 'black'}})
                }
                drawlineSeries2("line", series, chart,data.data[0].account_level_1, 'linear' )
            }
            //series.push({name:data.data[0].account_level_1, data:s})
            //drawlineSeries2("spline", series, chart, null,'linear')
            //setView(4)
            //setRatio(false)
            //setLinearProgression(false)
            //setTreatment(true)
            //setTreatmentForecast(false)
            console.log(series)
        }

        const callPreTreatmentCharts = () =>{
            //setShowLoading(true)
            //var items:any = [9,10,25,26]
            //for(var i = 0 ; i < items.length; i++){
                fetch(props.primary_host+"dbconnect/dbdynamic.jsp?pre=select_treatment_pre1"+
                    //"&period_type="+0+
                    "&account_level_0_key="+props.account_key+
                    "&analyticalreviewsid="+props.analytical_reviews_id+
                    "&startdate="+props.start_date+
                    "&enddate="+props.end_date,
                    {
                        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                    }
                )
                .then(response => response.json())
                .then(data =>{
                    console.log(data.data[0].account_level_1_key)
                    renderCharts3(data, data.data[0].account_level_1_key)
                    //setShowLoading(false)
                })
                .catch(error=>{
                    //setShowLoading(false)
                    var z:any = null
                    //setSelectedAccount(z)
                })
            //}
            callPostTreatmentCharts()
        }

        const callPostTreatmentCharts = () =>{
            //setShowLoading(true)
            //var items:any = [9,10,25,26]
            //for(var i = 0 ; i < items.length; i++){
                fetch(props.primary_host+"dbconnect/dbdynamic.jsp?pre=select_treatment_post"+
                    "&period_type="+0+
                    "&account_level_0_key="+props.account_key+
                    "&analyticalreviewsid="+props.analytical_reviews_id+
                    "&startdate="+props.start_date+
                    "&enddate="+props.end_date,
                    {
                        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                    }
                )
                .then(response => response.json())
                .then(data =>{
                    console.log(data.data[0].account_level_1_key)
                    renderCharts4(data, data.data[0].account_level_1_key)
                    //setShowLoading(false)
                })
                .catch(error=>{
                    //setShowLoading(false)
                    var z:any = null
                    //setSelectedAccount(z)
                })
            //}
        }
        React.useEffect(() => {
            callPreTreatmentCharts()

        }, []);
        return(
            <div className='size-12'>
                <IonRow>
                    <IonCol size="6" >
                        <IonRow>
                            <IonCol><b>Pre-Treatment - {props.account_names}</b></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="chart-frame ion-padding" size="12">
                                {getChart1}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol size="6" >
                        <IonRow>
                            <IonCol><b>Post-Treatment - {props.account_names}</b></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="chart-frame ion-padding" size="12">
                                {getChart2}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </div>
        )
    }
    export default PredictiveFormTreatment