import { IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonCheckbox } from '@ionic/react';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, options, filter} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import $ from "jquery"
import { addCommas, isNumeric } from '../../functions/functions';
import './Basic.css'
 
const Basic = (props:any) =>{
    console.log(props)
    console.log(props.data)
    var options:any = []
    options.push(props.data[0])
    const [getData,setData]             = useState<any>()

    // get table column
    const column:any = []
    let x:any = "";

    console.log(options[0])

    for(x in options[0][0]){
        console.log(x)
        if( 
            x != "id" && 
            x != "row" &&
            x != "versionid" &&
            x != "reporttypeid"
        )
        column.push(x)
    }

    // get table heading data
    console.log(column)
    const ThData:any =()=>{
        return column.map((header:any, i:number)=>{
            
                return <th  className="ion-text-center ion-padding" key={i}><b>{header}</b></th>
        })
    }
    // get table row data
    const tdData:any =() =>{
        return options[0].map((data:any, i:number)=>{
            console.log(data) 
            
            return(
               
                <tr key={i}>
                    {
                        column.map((v:any, z:number)=>{
                            console.log(v)
                            if(true){
                                if(!isNumeric(data[v]) || v == "Account Number")
                                    return <td  key={z} className={"ion-text-left ion-padding "}>{data[v]}</td>
                                if(isNumeric(data[v]) && v != "Account Number")
                                    return <td  key={z} className={"ion-text-right ion-padding "}>{addCommas((data[v]/1).toFixed(2))}</td>
                            }
                        })
                    }
                </tr>
            )
        })
    }
    return (
        <IonRow className="table size-12 ">
            <table >
                <tr>
                    {ThData()}
                </tr>
                {tdData()}
            </table>
        </IonRow>
    )
}
export default Basic