import { IonCol, IonIcon, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { businessOutline, shareOutline, trashOutline} from 'ionicons/icons';
import React, { useState,useEffect } from 'react';
import { callAnalyticalReviews } from '../../dbconnections/Select';

const SelectARProjects = (props:any) =>{
    const [getLiveConnectionsListData, setLiveConnectionsListData]      = useState<any>()
    const [getState, setState]      = useState<any>()
    const [getLiveConnectionsList, setLiveConnectionsList] = useState<any>()
    const callLiveConnections = () =>{
        var list : any  = []
        callAnalyticalReviews(props.props)
        .then(response=>{return response})
        .then(data=>{
            list.push(data)
            var sideList:any = list[0].map((x:any,i:number)=>{
                return( 
                    <IonSelectOption key={i} value={x.id} >
                        {x.name}
                    </IonSelectOption>
                )
            })
            setLiveConnectionsList(sideList)
        })
    }
    React.useEffect(() => {
        callLiveConnections()    
    }, []);
    return (
        <div>
            <IonItem>
                <IonLabel position='floating'>AR Projects</IonLabel>
                <IonSelect  onIonChange={(e:any)=>{props.migration_id(e.target.value)}}>
                    {getLiveConnectionsList}
                </IonSelect>
            </IonItem>
        </div>
    )
}
export default SelectARProjects