import { IonModal, IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonLoading, IonItem, IonTextarea, IonButton, useIonModal, IonPage, IonToolbar, IonButtons, IonTitle } from '@ionic/react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, trendingUpSharp, trashBinOutline, closeOutline, peopleOutline} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import { callBalanceSheetInitial, callBalanceByAccount, callMovementByAccount, callAnalyticalReviews, callSummaryDetail, callDetailTransactions, callXeroAvailableYears, callFactDebtRatio, callFactDebtToEquityRatio, callFactCurrentRatio, callFactWorkingCapitalToAssetsRatio, callFactIncomeExpenses, callBalanceSheetMonthlyPivot, callFactAccountBalance, callCustomCharts, callBalanceSheet, exportResults } from '../../dbconnections/Select';
import AreaChart from '../Charts/AreaSplineChart';
import BarChart from '../Charts/BarChart';
import BarChartCombo from '../Charts/BarChartCombo';
import LineChart from '../Charts/LineChart';
import LineSeriesChart from '../Charts/LineSeriesChart';
import DeleteProject from '../Modals/DeleteARProject';
import ShareProject from '../Modals/ShareARProject';
import AnalyticalReviews from './AnalyticalReviews';
import JETS from './AR_JETs';
import AR_JETs_Results from './AR_JETs_Results';
import BalanceSheet from './BalanceSheet';
import BalanceSheetMonthlyPivot from './BalanceSheetMonthlyPivot';
import CashFlow from './CashFlow';
import IncomeExpenses from './IncomeExpenses';
import JournalDetail from './JournalDetail';
import JournalDetal from './JournalDetail';
import Mapping from './Mapping';
import OpeningBalance from './OpeningBalances';
import SummaryDetailTable from './SummaryDetailTable';
var state:any = {
    month:'',
    year:'',
    start:'',
    end:'',
    year_end:'',
    analytical_reviews_id:'',
    primary_host:'',
    user_id:'',
    ar_name:''
}

let ar_name:any                 = ""
let account_level_0_desc:any    = '';
let account_level_1_desc:any    = '';
let account_level_0:any         = "0";
let account_level_1:any         = "0";
let account_level_2:any         = "0";
let account_level_3:any         = "0";
let date_desc:any               = '';
let kpi_exception:any           = 0;
let kpi_number:any              = 0;
let cashFlowId:any              = 0;
let row_id:any                  = "";
let action_type_id:any          = 0;
let analytical_report_id:any    = 0;
const Main = (props:any) =>{
    state.primary_host = props.global_props.primary_host
    state.user_id = props.global_props.user_id
    var data:any = [];
    const [hideHighLightModal, showHighLightModal]              = useState<boolean>(false)
    let btnUpdateHighlightNote:any                              = useRef<HTMLIonButtonElement>()
    let txtHighlightNote:any                                    = useRef<HTMLIonTextareaElement>()
    const [hideDeleteProjectModal, showDeleteProjectModal]      = useState<boolean>(false)
    const [hideKPIModal, showKPIModal]                          = useState<boolean>(false)
    const [getAccountLevel3kpi, setAccountLevel3kpi]            = useState<string>("0")
    const [getAccountLevel2kpi, setAccountLevel2kpi]            = useState<string>("0")
    const [getAccountLevel1kpi, setAccountLevel1kpi]            = useState<string>("0")
    const [getAccountLevel0kpi, setAccountLevel0kpi]            = useState<string>("0")
    const [hideActionPreDefined, showActionPreDefined]          = useState<boolean>(false)
    const [hideActionDefined, showActionDefined]                = useState<boolean>(false)
    const [hideSaveKPI, showSaveKPI]                            = useState<boolean>(false)
    const [hideActionValue, showActionValue]                    = useState<boolean>(false)
    const [getAction, setAction]                                = useState<boolean>(false)
    const [getAccountCurrentValue, setAccountCurrentValue]      = useState<String>()
    const setActionTypeId = (s:any) =>{
        action_type_id = s;
        
    }
    const deleteProject = (ar_id:any, name:any) =>{
        //alert(ar_id)
        //alert(name)
        ar_name = name;
        showDeleteProjectModalGroup(true)
        showDeleteProjectModal(true)
        state.analytical_reviews_id = ar_id
        //setAnalyticalReviewsId(ar_id)
    }
    const deleteAnalyticalReviews = () =>{
        //alert(state.analytical_reviews_id)
        fetch(props.global_props.primary_host+"/ar/dbconnect/delete.jsp?etl=deletereviewproject"+
        "&analyticalreviewsid="+state.analytical_reviews_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data=>{
        state.analytical_reviews_id = 0;
        showDeleteProjectModal(false)
        showDeleteProjectModalGroup(false)
        //callAnalyticalReviews(props)
        setView(0)
        })
        .catch(err=>{callAnalyticalReviews(props)})
    }
    const setHighlighted = () =>{
    var obj:any = $(".row-id-"+row_id)
    if($(obj).hasClass("highlighted-row")){
        obj.removeClass("highlighted-row")
    }else{
        showHighLightModal(true)
    }
        
    }
    const saveHighlightedRow = () =>{
    fetch(props.primary_host+"/ar/dbconnect/insert.jsp?etl=inserthighlightedrow"+
        "&analytical_reviews_id="+props.analytical_reviews_id+
        "&account_level_0_key="+account_level_0+
        "&account_level_1_key="+account_level_1+
        "&date="+date_desc+
        "&note="+txtHighlightNote.current!.value+
        "&created_by="+props.user_id 
        ,
        {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
    )
    .then(response => response.json())
    .then(data =>{
        var o:any = $(".row-id-"+row_id)
        o.addClass("highlighted-row")
        showHighLightModal(false)
    })
    }
    const [hideShareModal, showShareModal]              =useState<boolean>(false)
    let txtEmailShare:any                               =useRef<HTMLIonInputElement>()
    const setShare = () =>{
        setShowLoading(true)
        fetch(props.global_props.primary_host+"/ar/dbconnect/insert.jsp?etl=insertanalyticalreviewscontacts"+
            "&analytical_reviews_id="+state.analytical_reviews_id+
            "&email="+txtEmailShare.current!.value+
            "&created_by="+props.global_props.user_id 
            ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            //showShareModal(false)
            callProjectContacts()
        })
        .catch(error=>{ setShowLoading(false)})
    }
    const [getActiveUsers, setActiveUsers]                              = useState<any>()
    const [hideDeleteProjectModalGroup, showDeleteProjectModalGroup]    = useState<any>()  
    const [hideShareModalGroup, showShareModalGroup]                    = useState<any>()  
    const callProjectContacts = () =>{
    var list:any = []
    fetch(props.global_props.primary_host+"/ar/dbconnect/select.jsp?etl=selectanalyticalreviewscontacts"+
        "&analytical_reviews_id="+props.global_props.analytical_reviews_id
        ,
        {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
    )
    .then(response => response.json())
    .then(data =>{
        list.push(data.data);
        var contacts:any = list[0].map((x:any, i:number)=>{
        return(
            <IonRow>
            <IonCol size="11">{x.email}</IonCol>
            <IonCol size="1"><IonIcon color="danger" onClick={()=>{}} icon={closeOutline}></IonIcon></IonCol>
            </IonRow>
        )
        })
        setActiveUsers(contacts)
    })
    }
    /* Array Data States */
    const [getBalanceChartData, setBalanceChartData]            = useState<any>()
    const [getMovmentChartData, setMovementChartData]           = useState<any>()
    const [getAnalyticalReviewsData, setAnalyticalReviewsData]  = useState<any>()
    const [getSummaryDetailTableData, setSummaryDetailTableData] = useState<any>()
    const [getJournalDetailData, setJournalDetailData]          = useState<any>()
    const [getBalanceSheetData, setBalanceSheetData]            = useState<any>()
    const [getDebtRatioChartData, setDebtRatioChartData]        = useState<any>()
    const [getDebtToEquityRatioChartData, setDebtToEquityRatioChartData] = useState<any>()
    const [getCurrentRatioChartData, setCurrentRatioChartData]  = useState<any>()
    const [getCurrentRatioTableData, setCurrentRatioTableData]  = useState<any>()
    const [getWorkingCapitalToAssetsRatioChartData, setWorkingCapitalToAssetsRatioChartData] = useState<any>()
    const [getWorkingCapitalToAssetsRatioTableData, setWorkingCapitalToAssetsRatioTableData] = useState<any>()
    const [getCurrentAssetsChartData, setCurrentAssetsChartData] = useState<any>()
    const [getCurrentAssetsTableData, setCurrentAssetsTableData] = useState<any>()
    const [getCurrentLiabilitiesChartData, setCurrentLiabilitiesChartData] = useState<any>()
    const [getNetIncomeChartData, setNetIncomeChartData]         = useState<any>()
    const [getIncomeChartData, setIncomeChartData]               = useState<any>()
    const [getExpensesChartData, setExpensesChartData]           = useState<any>()
    const [getBalanceSheetMonthlyPivotData, setBalanceSheetMonthlyPivotData]   = useState<any>()
    const [getBalanceAllChartData, setBalanceAllChartData]                     = useState<any>()
    const [getBalanceBreakDownChartData, setBalanceBreakDownChartData]         = useState<any>()

    /*Objects & Variables & States*/
    const [getMaxDataMonth,setMaxDataMonth]                     = useState<any>()
    const [getMaxDataYear,setMaxDataYear]                       = useState<any>()
    const [getYearEnd, setYearEnd]                              = useState<any>()
    const [getStartDate, setStartDate]                          = useState<any>()
    const [getSelectedDate, setSelectedDate]                    = useState<any>()
    const txtStartDate:any                                      = useRef()
    const txtEndDate:any                                        = useRef()
    const [getLocation, setLocation]                            = useState<any>()
    const [getState, setState]                                  = useState<any>()
    const [getAnalyticalReviewsId, setAnalyticalReviewsId]      = useState<any>()
    const [getChart, setChart]                                  = useState<boolean>(true)
    const [getAccountForProfile, setAccountForProfile]          = useState<any>()
    const [getPrevEndDate, setPrevEndDate]                      = useState<any>()
    const [getEndDate, setEndDate]                              = useState<any>()
    const [getUserId, setUserId]                                = useState<any>()
    const [getCumulative,setCumulative]                         = useState<any>()
    const [getAccountLevel3, setAccountLevel3]                  = useState<any>(1)
    const [getAccountLevel2, setAccountLevel2]                  = useState<any>(0)
    const [getAccountLevel1, setAccountLevel1]                  = useState<any>(0)
    const [getAccountLevel0, setAccountLevel0]                  = useState<any>(0)
    const [getStartDateAbsolute, setStartDateAbsolute]          = useState<any>()
    const [getEndDateAbsolute, setEndDateAbsolute]              = useState<any>()
    const [balanceSheet, isBalanceSheet]                        = useState<any>(false)
    const [income, isIncome]                                    = useState<any>(false)
    
    /*Home*/    
    const [getHomeView, setHomeView]                            = useState<boolean>(false)

    /*Balance Sheet */
    const [getSummaryView, setSummaryView]                      = useState<boolean>(false)
    const [getSummaryDetailView, setSummaryDetailView]          = useState<boolean>(false)
    const [getSummaryJournalDetailView, setSummaryJournalDetailView]                = useState<boolean>(false)
    const [getBalanceSheetMonthlyPivotView,setBalanceSheetMonthlyPivotView]         = useState<boolean>(false)
    const [getBalanceSheetProView, setBalanceSheetProView]      = useState<boolean>(false)
    /*Cash Flow */
    const [getCashFlow, setCashFlow]                            = useState<boolean>(false)

    /*Income & Expenses */
    const [getIncomeExpensesView,setIncomeExpensesView]         = useState<boolean>(false)

    /*Solvency */
    const [getSolvencyView, setSolvencyView]                    = useState<boolean>(false)

    /*Liquidity */
    const [getLiquidityView, setLiquidityView]                  = useState<boolean>(false)

    /*Predictive */


    /*Net Income */
    const [getNetIncomeView, setNetIncomeView]                  = useState<boolean>(false)

    /*Mapping COA */
    const [getCOA, setCOA]                                      = useState<boolean>(false)

    /*Opening Balances */
    const [getOpeningBalances, setOpeningBalances]              = useState<boolean>(false)

    /*Audit */
    const [getAudit, setAudit]                                  = useState<boolean>(false)
    const [getAuditResults, setAuditResults]                    = useState<any>()

    

    const setARVariables = (id:any) =>{
        setAnalyticalReviewsId(id)
        var z:any=[]
        z.push({analytical_reviews_id:id,primary_host:props.global_props.primary_host })
        callXeroAvailableYears(z[0])
        .then(response=>{return response})
        .then(data=>{
            //props.setAnalyticalReviewsId(x.id)
            //console.log(data.month);
            console.log(data)
            //props.years(data.obj)
            state.month  = data.month
            state.year   = data.year
            state.start  = data.start
            state.end    = data.end
            state.year_end = data.year_end
            setStartDateAbsolute(data.start)
            setEndDateAbsolute(data.end)
            setMaxDataMonth(data.month);
            setMaxDataYear(data.year);
            setStartDate(data.start)
            setEndDate(data.end)
            setYearEnd(data.year_end)
        })
        .then(()=>{
            setView(1)
        })

    }

    var location:any = "";
    const [getShowLoading, setShowLoading]                      = useState<any>(false)
    const setView = (v:any) =>{
        resetView()
        switch(v){
            case 0: setShowLoading(true)
                    analytical_report_id = 1;
                    callAnalyticalReviews(props.global_props)
                    .then(response=>{ console.log(response); return response})
                    .then(data=>{
                        setShowLoading(false)
                        setAnalyticalReviewsData(data)
                        
                    })
                    .then(()=>{
                        setHomeView(true);
                    })
                    .catch(error=>{setShowLoading(false)})
                    break;
            case 1: setShowLoading(true)
                    setSummaryView(true) ;
                    analytical_report_id = 1;
                    txtStartDate.current.value = getStartDate
                    txtEndDate.current.value = getEndDate;
                    state.start = txtStartDate.current.value
                    state.end   = txtEndDate.current.value
                    var y:any = []
                    y.push({
                        primary_host:props.global_props.primary_host,
                        analytical_reviews_id:state.analytical_reviews_id, 
                        year:state.year, 
                        month:state.month, 
                        start_date:state.start,
                        end_date:state.end,
                        user_id:getUserId,
                        compare_mode:0
                    })
                    callBalanceSheetInitial(y)
                    .then(response=>{return response})
                    .then(data=>{
                        console.log(data)
                        var x:any= []
                        x.push({global_props:props.global_props,data:data})
                        setBalanceSheetData(x[0])
                        console.log(x[0])
                        txtStartDate.current.value = x[0].data[0].sdate
                        txtEndDate.current.value = x[0].data[0].edate
                        setStartDate(x[0].data[0].sdate)
                        setEndDate(x[0].data[0].edate)
                        setShowLoading(false)
                    })
                    .catch(error=>{
                        setShowLoading(false)
                        alert(error)
                    })
                    break;
            case 2: setSummaryDetailView(true);
                    if(analytical_report_id == 1)
                        analytical_report_id = 2;
                    if(analytical_report_id == 7)
                        analytical_report_id = 8;
                    setChart(false)
                    
                    ;break;
            case 3: setSummaryJournalDetailView(true);
                    
                    break;
            case 4: setIncomeExpensesView(true);
                    analytical_report_id = 7;
                    break;
            case 5: 
                    setShowLoading(true)
                    var y:any = []
                    y.push({
                        primary_host:props.global_props.primary_host,
                        analytical_reviews_id:getAnalyticalReviewsId, 
                        year:state.year, 
                        month:state.month, 
                        start_date:state.start,
                        end_date:state.end,
                        user_id:getUserId,
                        compare_mode:0
                    })
                    callFactDebtRatio(y[0])
                    .then(response=>{return response})
                    .then(data=>{
                        setShowLoading(false)
                        setDebtRatioChartData(data)
                    })
                    .catch(error=>{
                        setShowLoading(false)
                    });
                    callFactDebtToEquityRatio(y[0])
                    .then(response=>{return response})
                    .then(data=>{
                        setShowLoading(false)
                        setDebtToEquityRatioChartData(data)
                    })
                    .catch(error=>{
                        setShowLoading(false)
                    });

                    setSolvencyView(true);
                    setChart(false)
                    break;
            case 6 :setShowLoading(true)
                    var y:any = []
                    y.push({
                        primary_host:props.global_props.primary_host,
                        analytical_reviews_id:getAnalyticalReviewsId, 
                        year:getMaxDataYear, 
                        month:getMaxDataMonth, 
                        start_date:getStartDate,
                        end_date:getEndDate,
                        user_id:getUserId,
                        compare_mode:0,
                        target_currency_id:88
                    })
                    callFactCurrentRatio(y[0])
                    .then(response=>{return response})
                    .then(data=>{
                        setShowLoading(false)
                        setCurrentRatioChartData(data[0].current_ratio)
                    })
                    .catch(error=>{
                        setShowLoading(false)
                    });
                    callFactWorkingCapitalToAssetsRatio(y[0])
                    .then(response=>{return response})
                    .then(data=>{
                        setShowLoading(false)
                        setWorkingCapitalToAssetsRatioChartData(data)
                    })
                    .catch(error=>{
                        setShowLoading(false)
                    });
                    setLiquidityView(true);
                    setChart(false)
                    break;
            case 7 :setShowLoading(true)
                    var y:any = []
                    y.push({
                        primary_host:props.global_props.primary_host,
                        analytical_reviews_id:getAnalyticalReviewsId, 
                        year:getMaxDataYear, 
                        month:getMaxDataMonth, 
                        start_date:getStartDate,
                        end_date:getEndDate,
                        user_id:getUserId,
                        compare_mode:0,
                        date_shortcut:0,
                        migration_id:0,
                        target_currency_id:88
                    })
                    callFactIncomeExpenses(y[0])
                    .then(response=>{return response})
                    .then(data=>{
                        setShowLoading(false)
                        setNetIncomeChartData(data[0].net_income_data)
                        setIncomeChartData(data[0].income_data)
                        setExpensesChartData(data[0].expense_data)
                    })
                    .catch(error=>{
                        setShowLoading(false);
                        alert(error)
                    });
                    //callFactWorkingCapitalToAssetsRatio(y[0])
                    //.then(response=>{return response})
                    //.then(data=>{
                    //    setWorkingCapitalToAssetsRatioChartData(data)
                    //});
                    setNetIncomeView(true);
                    setChart(false)
                    break;
                    setShowLoading(true);
            case 8  :setShowLoading(true)
                    var y:any = []
                    y.push({
                        primary_host:props.global_props.primary_host,
                        analytical_reviews_id:getAnalyticalReviewsId, 
                        year:getMaxDataYear, 
                        year_end:getYearEnd,
                        cumulative:getCumulative,
                        month:getMaxDataMonth, 
                        start_date:getStartDate,
                        end_date:getEndDate,
                        user_id:getUserId,
                        compare_mode:0,
                        date_shortcut:0,
                        migration_id:0,
                        target_currency_id:88
                    })
                    callBalanceSheetMonthlyPivot(y[0])
                    .then(response=>{return response})
                    .then(data=>{
                        //console.log(data)
                        setShowLoading(false)
                        setBalanceSheetMonthlyPivotData(data);
                    })
                    .then(()=>{
                        setShowLoading(false)
                        setBalanceSheetMonthlyPivotView(true);
                    })
                    .catch(error=>{
                        setShowLoading(false)
                    })
                    break;
            case 9  :setShowLoading(true)
                    analytical_report_id = 1;
                    var y:any = []
                    y.push({
                        primary_host:props.global_props.primary_host,
                        analytical_reviews_id:getAnalyticalReviewsId, 
                        year:getMaxDataYear, 
                        year_end:getYearEnd,
                        cumulative:getCumulative,
                        month:getMaxDataMonth, 
                        start_date:getStartDate,
                        end_date:getEndDate,
                        user_id:getUserId,
                        compare_mode:0,
                        date_shortcut:0,
                        migration_id:0,
                        target_currency_id:88,
                        account_level_0_key:getAccountLevel0,
                        account_level_1_key:getAccountLevel1,
                        account_level_2_key:getAccountLevel2,
                        account_level_3_key:getAccountLevel3,
                    })
                    callFactAccountBalance(y[0])
                    .then(response=>{return response})
                    .then(data=>{
                        setShowLoading(false)
                        setBalanceAllChartData(data)
                    })
                    .catch(error=>{
                        setShowLoading(false)
                    })
                    callCustomCharts(y[0])
                    .then(response=>{return response})
                    .then(data=>{
                        setShowLoading(false)
                        setBalanceBreakDownChartData(data)
                    })
                    .catch(error=>{
                        setShowLoading(false)
                    })
                    setBalanceSheetProView(true)
                    break;
            case 10 :setCashFlow(true)
                
                    ;break;
            case 11 : setShowLoading(true)
                    setSummaryView(true);
                    txtStartDate.current.value = getStartDate
                    txtEndDate.current.value = getEndDate;
                    state.start = txtStartDate.current.value
                    state.end   = txtEndDate.current.value
                    var y:any = []
                    y.push({
                        primary_host:props.global_props.primary_host,
                        analytical_reviews_id:state.analytical_reviews_id, 
                        year:state.year, 
                        month:state.month, 
                        start_date:state.start,
                        end_date:state.end,
                        user_id:getUserId,
                        compare_mode:0
                    })
                    if(txtStartDate.current != null){
                        if(txtStartDate!.current!.value != "" && txtEndDate!.current!.value != ""){
                            callBalanceSheet(y)
                            .then(response=>{return response})
                            .then(data=>{
                                setShowLoading(false)
                                console.log(data)
                                var x:any= []
                                x.push({global_props:props.global_props,data:data})
                                setBalanceSheetData(x[0])
                            })
                            .catch(error=>{
                                setShowLoading(false)
                            })
                        }
                    }
                    break;
            case  12:;
                    break;
        }
    }
    const resetView = () =>{
        setHomeView(false);
        setSummaryView(false);
        setSummaryDetailView(false)
        setSummaryJournalDetailView(false);
        setIncomeExpensesView(false)
        setSolvencyView(false)
        setLiquidityView(false)
        setNetIncomeView(false)
        setBalanceSheetMonthlyPivotView(false)
        setBalanceSheetProView(false)
        setCashFlow(false)
    }
    const setLocationView = () =>{
        switch(props.location){
            case 0 : setView(0);break;
            case 1 : setView(1);break;
            case 2 : setView(4);break;
            case 3 : setView(10);break;
            case 4 : setView(6);break;
            case 5 : setView(5);break;
            case 6 : setView(7);break;
            case 7 : setView(12);break;
            default: setView(0);break;
        }
        
    }
    const [getModalTitle, setModalTitle]                  = useState<any>()
    const [getModalContent1, setModalContent1]            = useState<any>(false)
    const [getModalContent2, setModalContent2]            = useState<any>(false)
    const [getModalContent3, setModalContent3]            = useState<any>(false)

    const openModal = (modal:any) =>{
        resetModal()
        switch (modal) {
            case 1: 
                    openModalFunction();
                    setModalTitle("Delete Project - "+ state.ar_name);
                    setModalContent1(true);
                    break;
            case 2: openModalFunction();
                    setModalTitle("Share Project - "+ state.ar_name);
                    setModalContent2(true);
                    break;
            case 3: openModalFunction();
                    setModalTitle("Delete");
                    setModalContent3(true);
                    break;
        }
    }
    const resetModal = () =>{
        setModalContent1(false)
        setModalContent2(false)
        setModalContent3(false)
    }
    const modal = useRef<HTMLIonModalElement>(null);
    const input = useRef<HTMLIonInputElement>(null);

    const ModalExample = ({
        onDismiss,
      }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
      }) => {
        const inputRef = useRef<HTMLIonInputElement>(null);
        return (
          <IonPage className="modal-area">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{getModalTitle}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => onDismiss(inputRef.current?.value, 'confirm')}>Confirm</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {getModalContent1 &&
                    <DeleteProject state={state} />
                }
                {getModalContent2 &&
                    <ShareProject state={state} />
                }
            </IonContent>
          </IonPage>
        );
    };
    const [message, setMessage] = useState(
        'This modal example uses triggers to automatically open a modal when the button is clicked.'
    );

    function confirm() {
        modal.current?.dismiss(input.current?.value, 'confirm');
    }

    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
        if (ev.detail.role === 'confirm') {
        setMessage(`Hello, ${ev.detail.data}!`);
        }
    }
    const [present, dismiss] = useIonModal(ModalExample, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
    });
    function openModalFunction() {

        present({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {
                setMessage(`Hello, ${ev.detail.data}!`);
                }
            },
        });
    }
    useEffect(() => {
        setState(()=>{
            //alert()
            
            if(props.location != getLocation || props.analytical_reviews_id != getAnalyticalReviewsId){ 
                setState([props.location, props.current_assets])
                //setAnalyticalReviewsId(props.analytical_reviews_id)
                setUserId(props.global_props.user_id)
                setLocation(props.location)
                setLocationView()
            }}
        ) 
    }, [props.location,props.analytical_reviews_id]);
    return(
        <div>
        <div>
            
            <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'} ></IonLoading>
            <IonHeader>
            
            {getHomeView &&
                <IonRow className="ar-header-panel">
                    <IonCol size='4' className="size-16 ion-text-left">
                        <IonIcon className="size-24 text-gt" icon={businessOutline}></IonIcon>
                        &nbsp;Project Name
                    </IonCol>
                    <IonCol size='2' className="size-16 ion-text-left">
                        <IonIcon className="size-24 text-gt" icon={calendarOutline}></IonIcon>
                        &nbsp;From
                    </IonCol>
                    <IonCol size='2' className="size-16 ion-text-left">
                        <IonIcon className="size-24 text-gt" icon={calendarOutline}></IonIcon>
                        &nbsp;To
                    </IonCol>
                    <IonCol size='2' className="size-16 ion-text-left">
                        <IonIcon className="size-24 text-green" icon={peopleOutline}></IonIcon>
                            &nbsp;Share
                    </IonCol>
                    <IonCol size='2' className="size-16 ion-text-left">
                        <IonIcon className="size-24 text-red" icon={trashOutline}></IonIcon>
                        &nbsp;Delete
                    </IonCol>
                </IonRow>
            }
            {(getSummaryView || getIncomeExpensesView || getNetIncomeView || getSolvencyView || getLiquidityView ||getBalanceSheetMonthlyPivotView || getBalanceSheetProView) &&
                <IonRow>
                    <IonCol size="3" className="ion-text-left ">
                        <IonLabel className="size-16">Start Date:&nbsp;</IonLabel> 
                        <IonInput 
                            ref={txtStartDate} 
                            placeholder="Start Date"  
                            onIonChange={()=>{ setStartDate(txtStartDate.current!.value)}} 
                            type="date" 
                            className='input-style-11 size-16 '
                            //startDate={getStartDateAbsolute}
                            //endDate={getEndDateAbsolute}
                        />
                    </IonCol>
                    <IonCol size="3" className="ion-text-left">
                            <IonLabel className="size-16">End Date:&nbsp;</IonLabel>
                            <IonInput 
                            ref={txtEndDate}  
                            placeholder="End Date" 
                            onIonChange={()=>{setEndDate(txtEndDate.current!.value)}} 
                            type="date"  
                            className='input-style-11 size-16 '
                    /> 
                    </IonCol>
                    <IonCol size="1" className="">
                        <div className="fetch-button" onClick={()=>{setView(11)}}>Fetch</div>
                    </IonCol>
                    {(!getSolvencyView && !getLiquidityView) &&
                    <IonCol className=" ion-text-left">
                        <div className="dropdown4 " >
                        <IonLabel  className="size-16">View Options</IonLabel>
                        &nbsp;<IonIcon  icon={chevronDown} className="size-16"></IonIcon>
                        <div className="dropdown-content-views">
                            <a className="summary-account" onClick={()=>{setView(1);if(getSummaryDetailView){analytical_report_id = 1}; if(getIncomeExpensesView){analytical_report_id = 7}}}>Current</a>
                            <a className="summary-account" onClick={()=>{setView(8);if(getSummaryDetailView){analytical_report_id = 11}; if(getIncomeExpensesView){analytical_report_id = 14}}}>Monthly</a>
                            {/*<a onClick={()=>{setView(3)}}>Quarterly</a>
                            <a onClick={()=>{setView(4)}}>Yearly</a>*/}
                            <a className="summary-account" onClick={()=>{setView(9)}}>Pro View</a>
                        </div>
                        </div>
                    </IonCol>
                    }
                    {(getSolvencyView || getLiquidityView || getNetIncomeView) &&
                    <IonCol className="ion-text-right " >
                        {!getChart &&
                        <div className="">
                            <IonLabel onClick={()=>{
                                setChart(true)
                            }}>
                                View Table 
                            </IonLabel>&nbsp; 
                            <IonIcon icon={listOutline} className="size-24"></IonIcon>
                        </div>
                        }
                        {getChart &&
                        <div>
                            <IonLabel onClick={()=>{
                                setChart(false)
                                if(getSolvencyView)
                                    analytical_report_id = 5;
                                if(getLiquidityView)
                                    analytical_report_id = 3;
                                if(getNetIncomeView)
                                    analytical_report_id = 10;
                            }}>
                                View Chart  
                            </IonLabel>&nbsp;
                            <IonIcon icon={analytics} className="size-24"></IonIcon>
                        </div>
                        }
                    </IonCol>
                    }
                    <IonCol className="ion-text-right" title='Export' onClick={()=>{
                        //alert()
                        //start_date = txtStartDate.current!.value;
                        //end_date=txtEndDate.current!.value;
                        var y:any = []
                        var account_level_0_key:any = 0;
                        var account_level_1_key:any = 0;
                        var account_level_2_key:any = 0;
                        var account_level_3_key:any = 0;
                        y.push({
                            account_level_0:account_level_0_key,
                            account_level_1:account_level_1_key,
                            account_level_2:account_level_2_key,
                            account_level_3:account_level_3_key,
                            migration_id:props.global_props.migration_id,
                            analytical_report_id:analytical_report_id,
                            primary_host:props.global_props.primary_host,
                            analytical_reviews_id:getAnalyticalReviewsId, 
                            target_currency_id:88,
                            date_shortcut:0,
                            year:0, 
                            month:0, 
                            start_date:txtStartDate.current!.value,
                            end_date:txtEndDate.current!.value,
                            user_id:getUserId,
                            compare_mode:0
                        })
                        console.log(y[0])
                        exportResults(y[0])
                        
                    }}>
                        <IonLabel  className="size-16">Export</IonLabel>&nbsp;
                        <IonIcon icon={print} className="size-24" ></IonIcon>
                    </IonCol>
                </IonRow>
            }  
            {getCashFlow &&
                <IonRow>
                    {/*<IonCol size="" className="size-20 summary-account-level-1">
                        <b>Cash flow calculation</b>
                    </IonCol>*/}
                    <IonCol size="3" className="ion-text-right">
                        <IonLabel className="text-black size-16 " position="floating">Start Date</IonLabel>
                        <IonInput className=" size-16 input-style-11" ref={txtStartDate}  type="date" onChange={()=>{/*callCashFlow()*/}}></IonInput>
                        
                    </IonCol>
                    <IonCol size="3" className="ion-text-right">
                        <IonLabel className="text-black size-16" position="floating">End Date</IonLabel>
                        <IonInput className=" size-16  input-style-11" ref={txtEndDate}  type="date" onChange={()=>{/*callCashFlow()*/}} ></IonInput>
                        
                    </IonCol>
                    <IonCol size="1">
                        <div className="fetch-button" onClick={()=>{/*callCashFlow()*/}}>Update</div>
                    </IonCol>
                    <IonCol onClick={()=>{}} className="ion-text-right ion-padding">
                        Configure &nbsp; <IonIcon icon={settingsOutline} className="size-24"></IonIcon>
                    </IonCol>
                    <IonCol className="ion-text-right ion-padding" >
                        Export &nbsp; <IonIcon icon={print} className="size-24" onClick={()=>{/*exportResults()*/}}></IonIcon>
                    </IonCol>
                </IonRow>
            }
            {( (getSummaryDetailView && income)) &&
            <div>
                <IonRow className="">
                    <IonCol size="7">
                        <IonRow className="size-18 summary-account-level-1">
                            <b>Account Daily Summary</b>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">Activity profile of account(<b className="text-gt">{getAccountForProfile}</b>) From {getPrevEndDate} to {getEndDate}</IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol className="ion-text-right ion-padding" >
                        {!getChart &&
                        <div  onClick={()=>{setChart(true)}}>
                            View Table &nbsp; <IonIcon icon={listOutline} className="size-32"></IonIcon>
                        </div>
                        }
                        {getChart &&
                        <div onClick={()=>{setChart(false)}}>
                            View Chart &nbsp; <IonIcon icon={analytics} className="size-32"></IonIcon>
                        </div>
                        }
                    </IonCol>
                    <IonCol className="ion-text-right ion-padding" onClick={()=>{/*exportResults()*/}}>
                        Export &nbsp; <IonIcon icon={print} className="size-32"></IonIcon>
                    </IonCol>
                    <IonCol  className="ion-text-right ion-padding" onClick={()=>{setSummaryDetailView(false); setIncomeExpensesView(true)}}>
                        Back &nbsp; <IonIcon icon={returnUpBack} className="size-32"></IonIcon>
                    </IonCol>
                </IonRow>
                {(getChart)  &&
                <IonRow className="summary-row-total">
                    <IonCol size="2" className="ion-text-left">Date</IonCol>
                    {/*<IonCol size="5" className="ion-text-left">Desciption</IonCol>*/}
                    <IonCol size="2" className="ion-text-right">Debit</IonCol>
                    <IonCol size="2" className="ion-text-right">Credit</IonCol>{/*
                    <IonCol size="2" className="ion-text-right">Opening Balance</IonCol>
                <IonCol size="2" className="ion-text-right">Closing Balance</IonCol>*/}
                    <IonCol size="6" className="ion-text-right">Movement</IonCol>
                </IonRow>
                }
            </div>
            }
            { (getSummaryDetailView && balanceSheet) &&
            <div>
                <IonRow className="">
                    <IonCol size="7">
                        <IonRow className="size-18 summary-account-level-1">
                            <b>Account Daily Summary</b>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">Activity profile of account(<b className="text-gt">{getAccountForProfile}</b>) From {getPrevEndDate} to {getEndDate}</IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol className="ion-text-right ion-padding" >
                        {!getChart &&
                        <div  onClick={()=>{setChart(true)}}>
                            View Table &nbsp; <IonIcon icon={listOutline} className="size-32"></IonIcon>
                        </div>
                        }
                        {getChart &&
                        <div onClick={()=>{setChart(false)}}>
                            View Chart &nbsp; <IonIcon icon={analytics} className="size-32"></IonIcon>
                        </div>
                        }
                    </IonCol>
                    <IonCol className="ion-text-right ion-padding" onClick={()=>{/*exportResults()*/}}>
                        Export &nbsp; <IonIcon icon={print} className="size-32"></IonIcon>
                    </IonCol>
                    <IonCol  className="ion-text-right ion-padding" onClick={()=>{/*setView(1)*/ setSummaryView(true); setSummaryDetailView(false)}}>
                        Back &nbsp; <IonIcon icon={returnUpBack} className="size-32"></IonIcon>
                    </IonCol>
                </IonRow>
                {(getChart)  &&
                <IonRow className="summary-row-total">
                    <IonCol size="2" className="ion-text-left">Date</IonCol>
                    {/*<IonCol size="5" className="ion-text-left">Desciption</IonCol>*/}
                    <IonCol size="2" className="ion-text-right">Debit</IonCol>
                    <IonCol size="2" className="ion-text-right">Credit</IonCol>
                    <IonCol size="2" className="ion-text-right">Opening Balance</IonCol>
                    <IonCol size="2" className="ion-text-right">Closing Balance</IonCol>
                    <IonCol size="2" className="ion-text-right">Movement</IonCol>
                </IonRow>
                }
            </div>
            }
            {getSummaryJournalDetailView &&
            <div>
                <IonRow className="">
                    <IonCol size="7">
                        <IonRow className="size-18">
                            <IonCol><b>Detailed Transactions Posted To {getSelectedDate}</b></IonCol>
                        </IonRow>
                        <IonRow className="size-16">
                            <IonCol> For Account (<span className="summary-account-level-1">{getAccountForProfile}</span>)</IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol className="ion-text-right ion-padding" onClick={()=>{/*exportResults()*/}}>
                        Export &nbsp; <IonIcon icon={print} className="size-32"></IonIcon>
                    </IonCol>
                    <IonCol  className="ion-text-right ion-padding" onClick={()=>{setView(2)}}>
                        Back &nbsp; <IonIcon icon={returnUpBack} className="size-32"></IonIcon>
                    </IonCol>
                </IonRow>
            </div>
            }
            </IonHeader>
            
            <div className="main-content">
                
                {getHomeView &&
                    <AnalyticalReviews 
                        state={props} 
                        data={getAnalyticalReviewsData} 
                        user_id={getUserId}
                        name={(e:any)=>{props.set_project(e)}}
                        setAnalyticalReviewsId={(e:any)=>{
                            setAnalyticalReviewsId(e); 
                            state.analytical_reviews_id = e;
                            props.global_props.analytical_reviews_id = e;
                            setARVariables(e)
                            props.setSideBarList(3)
                        }}
                        callProjectContacts={(e:any)=>{ state.analytical_reviews_id = e;props.global_props.analytical_reviews_id = e; setAnalyticalReviewsId(e);openModal(2)}}
                        deleteProject={(e:any)=>{ state.ar_name = e.name; setAnalyticalReviewsId(e.id); state.analytical_reviews_id = e;console.log(e);props.global_props.analytical_reviews_id = e; openModal(1) ; }}
                    />    
                }
                
                {getSummaryView &&
                <BalanceSheet 
                    props={props}
                    data={getBalanceSheetData} 
                    showKPI={(e:any)=>{
                        showActionPreDefined(e.showActionPreDefined);
                        showActionDefined(e.showActionDefined);
                        showKPIModal(e.showKPIModal);
                        setAccountLevel3kpi(e.setAccountLevel3kpi);
                        setAccountLevel2kpi(e.setAccountLevel2kpi);
                        setAccountLevel1kpi(e.setAccountLevel1kpi);
                        setAccountLevel0kpi(e.setAccountLevel0kpi);
                        setAccountForProfile(e.setAccountForProfile)
                        setAccountCurrentValue(e.setAccountCurrentValue)

                    }}
                    setHighlighted={(e:any)=>{
                        account_level_0         = e.account_level_0;
                        account_level_1         = e.account_level_1;
                        row_id                  = e.row_id; 
                        date_desc               = e.date_desc
                        account_level_0_desc    = e.account_level_0_desc;
                        account_level_1_desc    = e.account_level_1_desc
                        setHighlighted()
                    }}
                    callSummaryAssets={  
                        (
                            account_level_3_key:any,
                            account_level_2_key:any,
                            account_level_1_key:any,
                            account_level_0_key:any,
                            start_date:any,
                            end_date:any,
                            migration_id:any,
                            analytical_report_id:any,
                            account_name:any
                        )=>{
                        props.global_props.account_level_3          =account_level_3_key;
                        props.global_props.account_level_2          =account_level_2_key;
                        props.global_props.account_level_1          =account_level_1_key;
                        props.global_props.account_level_0          =account_level_0_key;
                        props.global_props.start_date               =start_date;
                        props.global_props.end_date                 =end_date;
                        props.global_props.migration_id             =migration_id
                        props.global_props.analytcat_report_id      =analytical_report_id
                        props.global_props.analytical_reviews_id    =getAnalyticalReviewsId
                        console.log(props)
                        setAccountForProfile(account_name)
                        isBalanceSheet(true)
                        isIncome(false)
                        callBalanceByAccount(props.global_props)
                        .then(response=>{ return response})
                        .then(data =>{
                            setBalanceChartData(data)
                            console.log(data)
                            setView(2)
                        })
                        callMovementByAccount(props.global_props)
                        .then(response=>{ return response})
                        .then(data =>{
                            setMovementChartData(data)
                            console.log(data)
                            setView(2)
                        })
                        callSummaryDetail(props.global_props)
                        .then(response=>{return response})
                        .then(data =>{
                            console.log(data)
                            setSummaryDetailTableData(data)
                        })
                    }}
                /> 
                }
                {getIncomeExpensesView &&
                    <IncomeExpenses  
                        props={props}
                        data={getBalanceSheetData} 
                        callSummaryAssets={  
                            (
                                account_level_3_key:any,
                                account_level_2_key:any,
                                account_level_1_key:any,
                                account_level_0_key:any,
                                start_date:any,
                                end_date:any,
                                migration_id:any,
                                analytical_report_id:any,
                                account_name:any
                            )=>{
                            props.global_props.account_level_3          =account_level_3_key;
                            props.global_props.account_level_2          =account_level_2_key;
                            props.global_props.account_level_1          =account_level_1_key;
                            props.global_props.account_level_0          =account_level_0_key;
                            props.global_props.start_date               =start_date;
                            props.global_props.end_date                 =end_date;
                            props.global_props.migration_id             =migration_id
                            props.global_props.analytcat_report_id      =analytical_report_id
                            props.global_props.analytical_reviews_id    =getAnalyticalReviewsId
                            console.log(props)
                            setAccountForProfile(account_name)
                            isBalanceSheet(false)
                            isIncome(true)
                            callBalanceByAccount(props.global_props)
                            .then(response=>{ return response})
                            .then(data =>{
                                setBalanceChartData(data)
                                console.log(data)
                                setView(2)
                            })
                            callMovementByAccount(props.global_props)
                            .then(response=>{ return response})
                            .then(data =>{
                                setMovementChartData(data)
                                console.log(data)
                                setView(2)
                            })
                            callSummaryDetail(props.global_props)
                            .then(response=>{return response})
                            .then(data =>{
                                console.log(data)
                                setSummaryDetailTableData(data)
                            })
                        }}
                    />
                }
                {getBalanceSheetMonthlyPivotView &&
                    <BalanceSheetMonthlyPivot  
                        data={getBalanceSheetMonthlyPivotData}
                        setBalanceSheetPivotCumulative={(e:any)=>{setCumulative(e); setView(8)}}
                    />
                }
                {(getNetIncomeView ) &&
                <div className="ion-padding">
                    {!getChart &&
                    <div>
                        <div className="size-20 freeze-header"><b>Net Income Charts</b></div>
                            <div className="main-content-bs">
                            <IonRow>
                                <IonCol size="6">
                                    <div className="chart-1-ar-summary ion-padding">
                                        <BarChart 
                                            name="Net Income By Month"
                                            color="#7CB5EC"
                                            fillColor="#9DC8F1"
                                            data={getNetIncomeChartData}
                                        />
                                    </div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="chart-1-ar-summary ion-padding">
                                        <BarChartCombo
                                            name="Income & Expenses By Month"
                                            color="#7CB5EC"
                                            fillColor="#9DC8F1"
                                            data1={getIncomeChartData}
                                            data2={getExpensesChartData}
                                            title1="Income"
                                            title2="Expenses"
                                        />
                                    </div>
                                </IonCol>
                            </IonRow>
                        </div>
                    </div>
                    }
                    {getChart &&
                    <div>
                        <div className="size-20 freeze-header"><b>Net Income Tables</b></div>
                        <div className="main-content-bs">
                        
                        </div>
                    </div>
                    }
                </div>
                }
                {(getSolvencyView ) &&
                <div className="ion-padding">
                    {!getChart &&
                    <div>
                        <IonRow className="size-20"><b>Solvency Charts</b></IonRow>
                        <div className="">
                            <IonRow>
                                <IonCol size="6">
                                    <div className="chart-1-ar-summary ion-padding">
                                        <AreaChart 
                                            name="Debt Ratio"
                                            color="#7CB5EC"
                                            fillColor="#9DC8F1"
                                            data={getDebtRatioChartData}
                                        />
                                    </div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="chart-1-ar-summary ion-padding">
                                        <AreaChart
                                            name="Debt To Equity Ratio"
                                            color="#7CB5EC"
                                            fillColor="#9DC8F1"
                                            data={getDebtToEquityRatioChartData}
                                        />
                                    </div>
                                </IonCol>
                            </IonRow>
                        </div>
                    </div>
                    }
                    {getChart &&
                    <div>
                        <IonRow className="size-20"><b>Solvency Tables</b></IonRow>
                        <div className="">
                        
                        </div>
                    </div>
                    }
                </div>
                }
                {(getLiquidityView) &&
                <div className="ion-padding">
                    {!getChart &&
                    <div>
                        <IonRow className="size-20"><b>Liquidity Charts</b></IonRow>
                        <div className="">
                            <IonRow>
                                <IonCol size="6">
                                    <div className="chart-1-ar-summary ion-padding">
                                        <AreaChart 
                                            name="Current Ratio"
                                            color="#7CB5EC"
                                            fillColor="#9DC8F1"
                                            data={getCurrentRatioChartData}
                                        />
                                    </div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="chart-1-ar-summary ion-padding">
                                        <AreaChart 
                                            name="Working Capital To Assets Ratio"
                                            color="#7CB5EC"
                                            fillColor="#9DC8F1"
                                            data={getWorkingCapitalToAssetsRatioChartData}
                                        />
                                    </div>
                                </IonCol>
                            </IonRow>
                        </div>
                    </div>
                    }
                    {getChart &&
                    <div>
                        <IonRow className="size-20"><b>Liquidity Tables</b></IonRow>
                        <div className="main-content-bs">

                        </div>
                    </div>
                    }
                </div>
                }
                {(getSummaryDetailView && !getChart) &&
                <div className="ion-padding">
                <IonRow>
                    <IonCol size="6">
                        <div className="chart-1-ar-summary ion-padding">
                            <AreaChart 
                                name="Account Balance By Date"
                                color="#7CB5EC"
                                fillColor="#9DC8F1"
                                data={getBalanceChartData}
                            />
                        </div>
                    </IonCol>
                    <IonCol size="6">
                        <div className="chart-1-ar-summary ion-padding">
                            <LineChart 
                                name="Movement By Date"
                                color="#7CB5EC"
                                fillColor="#9DC8F1"
                                data={getMovmentChartData}
                            />
                        </div>
                    </IonCol>
                </IonRow>
                </div>
                }
                {(getSummaryDetailView && getChart) &&
                    <SummaryDetailTable 
                        data={getSummaryDetailTableData}
                        callDetailTransactions={(e:any)=>{
                            console.log(e)
                            setSelectedDate(e.datekey)
                            callDetailTransactions(props.global_props, e.datekey, e.code)
                            .then(response=>{return response})
                            .then(data=>{
                                console.log(data)
                                setJournalDetailData(data)
                                setView(3)
                                
                            })
                        }}
                    />
                }
                {getSummaryJournalDetailView &&
                    <JournalDetail
                        searchMatchingJournals={(e:any)=>{console.log(e)}}
                        data={getJournalDetailData}
                        global_props={props}
                    />
                }
                {getBalanceSheetProView &&
                <div>
                    <IonRow>
                        <IonCol size="4">
                            <div className="size-24"><b>Balance Sheet Summary</b></div>
                        </IonCol>
                        <IonCol>
                            <IonRadioGroup onIonChange={(ev)=>{setAccountLevel3(ev.detail.value); setView(9);}}>
                                <IonRow>
                                    <IonCol>
                                        <IonRadio value="1"></IonRadio>&nbsp;Assets
                                    </IonCol>
                                    <IonCol>
                                        <IonRadio value="3"></IonRadio>&nbsp;Liabilities
                                    </IonCol>
                                    <IonCol>
                                        <IonRadio value="2"></IonRadio>&nbsp;Owners Equity
                                    </IonCol>
                                    <IonCol>
                                        <IonRadio value="4"></IonRadio>&nbsp;Income
                                    </IonCol>
                                    <IonCol>
                                        <IonRadio value="5"></IonRadio>&nbsp;Expense
                                    </IonCol>
                                </IonRow>
                            </IonRadioGroup>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6">
                            <div className="chart-1-ar-summary ion-padding">
                                <IonRow>
                                    <IonCol><div><b>&nbsp;&nbsp;&nbsp;Balance By Date</b></div></IonCol>
                                </IonRow>
                                <IonRow>
                                    
                                    <AreaChart 
                                        name="All Account Balance By Date"
                                        color="#7CB5EC"
                                        fillColor="#9DC8F1"
                                        data={getBalanceAllChartData}
                                    />
                                    
                                </IonRow>
                            </div>
                        </IonCol>
                        <IonCol size="6">
                            <div className="chart-1-ar-summary ion-padding">
                                <IonRow>
                                    <IonCol><div><b>&nbsp;&nbsp;&nbsp;Balance By Account Classificaton & Date</b></div></IonCol>
                                </IonRow>
                                <IonRow>
                                    <LineSeriesChart 
                                        name="Movement By Date"
                                        color="#7CB5EC"
                                        fillColor="#9DC8F1"
                                        data={getBalanceBreakDownChartData}
                                    />
                                </IonRow>
                            </div>
                        </IonCol>
                    </IonRow>
                </div>
                }
                {getCashFlow &&
                    <CashFlow />
                }
                {getCOA &&
                    <Mapping primaryhost={props.primary_host} host={props.primary_host} analyticalReviewsId={props.analytical_reviews_id} />
                }
                {getOpeningBalances &&
                    <OpeningBalance primaryhost={props.primary_host} host={props.primary_host} analyticalReviewsId={props.analytical_reviews_id} />
                }
                {getAudit &&
                <div  className="is-detail-area-to-print ion-padding">
                    <IonHeader>
                    <IonRow className="">
                        <IonCol size="7">
                        <IonRow className="size-20 summary-account-level-1">
                            <IonCol><b>Audit</b></IonCol>
                        </IonRow>
                        <IonRow className="size-18 ">
                            <IonCol>Journal Entry Testing</IonCol>
                        </IonRow>
                        </IonCol>
                        <IonCol className="ion-text-right ion-padding" >
                        <div onClick={()=>{setAuditResults(false)}}>
                            <IonIcon icon={settingsOutline} className="size-32"></IonIcon>&nbsp; 
                            Test Data 
                        </div>
                        </IonCol>
                        <IonCol className="ion-text-right ion-padding">
                        <div onClick={()=>{setAuditResults(true)}}>
                            <IonIcon icon={analytics} className="size-32"></IonIcon>&nbsp; 
                            View Results 
                        </div>
                        </IonCol>
                    </IonRow>
                    </IonHeader>
                    <IonContent className="is-area-to-print-content ion-padding">

                        {getAuditResults &&
                            <div>
                                <AR_JETs_Results migrationId={props.migration_id} userId={props.user_id} primaryhost={props.primary_host} host={props.primary_host} />
                            </div>
                        }
                        {!getAuditResults &&
                            <div>
                                <JETS host={props.primary_host} analyticalReviewsId={props.analytical_reviews_id} userId={props.user_id}/>
                            </div>
                        }
                    </IonContent>
                </div>
                }
            </div>
        </div>   
    </div>
    )
}

export default Main;