import { IonSearchbar, IonLoading, IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSelect, IonSelectOption, IonSpinner, IonThumbnail, IonTitle, IonToolbar, IonTextarea, IonPopover, IonVirtualScroll, IonCheckbox, IonCardTitle } from '@ionic/react';
import { apps,starOutline, ellipsisVerticalOutline, businessOutline, checkmarkOutline, analyticsOutline, analyticsSharp, arrowBackSharp, cloudOutline, documentOutline, filterSharp, gridOutline, homeOutline, list, listOutline, logInOutline, logOutOutline, menuOutline, personOutline, pulseOutline, settingsOutline, wifiOutline, trashBin, trashBinOutline, checkmarkCircle, closeCircle, refreshCircle, caretUp, caretDown, pulseSharp, pulse, notifications, notificationsCircle, pencilSharp, closeOutline, trashOutline, star, arrowForwardCircleOutline, print, chevronForward, chevronDown, searchOutline, videocamOutline, codeDownload, clipboardOutline, logoGoogle, logoGooglePlaystore, shareOutline, returnUpBack, analytics, grid, createOutline, addOutline, statsChartOutline, heartDislikeSharp, searchCircle } from 'ionicons/icons';
import React,{ useEffect, useRef, useState,  } from 'react';
import $ from 'jquery';
import './VCMain.css';
import Budget from './Budget';

var questions:any = []
var cat:any = ''

const VCMain = (props:any) =>{
    console.log(props)
    const [getClientName, setClientName]                    = useState<any>("")
    const [getCategory, setCategory]                        = useState<any>()
    const [getDetails, setDetails]                          = useState<any>()
    const [getOptions, setOptions]                          = useState<any>("options-area-hide")
    const [getBudget, setBudget]                            = useState<any>(false)
    const [getQualitative, setQualitative]                  = useState<any>(false)
    const setPageView = (v:any) =>{
        resetPageView()
        switch(v){
            case 1:setBudget(true);break;
            case 2:setQualitative(true);break;
        }
    }
    const resetPageView = () =>{
        setBudget(false);
        setQualitative(false);
    }
    const callQuestions = (parent_list_id:any) =>{
        var options:any = []
        fetch(props.global_props.primary_host+"vc/dbconnect/select.jsp?vc=select_list"+
        "&parent_list_id="+parent_list_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            options.push(data.data)  
            var size:any = data.data.length;
            //alert(size)
            var details:any = options[0].map((x:any, i:number)=>{
                questions.push({"question":(i+1), "id":"question_"+(i+1), "response":x.response})
                return(
                <IonRow key={i} className={"ion-text-left ion-padding questions question_"+(i+1)}>
                   
                    <IonCol size='12' key={i} className="ion-text-left " >
                        <IonRow>
                            <IonCol size="2" className="ion-padding size-16 ion-text-left">{(i+1)})</IonCol>
                            <IonCol size="10"className="ion-padding size-16 ion-text-left">
                                <div className="" onClick={()=>{}}>{x.listdesc}</div>
                            </IonCol>
                        </IonRow> 
                        <IonRadioGroup onIonChange={(e)=>{console.log(x.id,e.detail.value); showNext(true); questions[(i+1)].response = e.detail.value;console.log(questions) }}>
                            <IonRow className="ion-text-center">
                                <IonCol size="6" className="ion-padding size-16">
                                    <IonRadio value="0"></IonRadio>&nbsp;No
                                </IonCol>
                                <IonCol size="6" className="ion-padding size-16">
                                    <IonRadio value="1"></IonRadio>&nbsp;Yes
                                </IonCol>
                            </IonRow>
                        </IonRadioGroup>
                        <IonFooter className="ion-padding">
                            <IonRow >
                                {i == 0 ?
                                    <IonCol size="4" className="size-16">
                                        <div className="vc-account-text-icon ">Cancel</div>
                                    </IonCol>
                                    :
                                    <IonCol size="4" className="size-16">
                                        <div onClick={()=>{setNext(i);}} className="vc-account-text-icon ">Back</div>
                                    </IonCol>
                                }
                                <IonCol>&nbsp;</IonCol>
                                {(i+1) == size ?
                                    <IonCol size="4" className="size-16">
                                        <div onClick={()=>{setNext(i);}} className="vc-account-text-icon ">Complete</div>
                                    </IonCol>
                                    :
                                    <IonCol size="4" className="size-16">
                                        <div onClick={()=>{setNext(i+2);}} className="vc-account-text-icon ">Next</div>
                                    </IonCol>
                                }
                            </IonRow>
                        </IonFooter>
                    </IonCol>
                </IonRow>
                )
            })
            setDetails(details)
            setNext(1)
        })
        .catch(error =>{
            alert("Error Collecting or Processing Data:"+error)
        })
    }
    const setNext = (n:any) =>{
        resetNext()
        var x:any = document.getElementsByClassName("question_"+n)
        $(x[0]).show()
    }
    const resetNext = () =>{
        var obj:any = document.getElementsByClassName("questions")
        for(var i = 0; i < obj.length; i++){
            $(obj[i]).hide()
        }
    }
    const [hideNext, showNext]               = useState<boolean>(false)
    const [hidePrevious, showPrevious]       = useState<boolean>(false)
    const callList = (parent_list_id:any) =>{
        var options:any = []
        fetch(props.global_props.primary_host+"vc/dbconnect/select.jsp?vc=select_list"+
        "&parent_list_id="+parent_list_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            options.push(data.data)    
            var category:any = options[0].map((x:any, i:number)=>{
                return(
                    <IonCol size="2" key={i} className="ion-padding">
                        <div className="vc-account-text-icon" onClick={()=>{x.has_grand_children/1 > 0 ?callList(x.id) : callQuestions(x.id);cat = x.listdesc}}>{x.listdesc}&nbsp;({x.has_children})</div>
                    </IonCol>
                )
            })
            setCategory(category)
        })
        .catch(error =>{
            alert("Error Collecting or Processing Data")
        })
    }
    useEffect(() => {
        setPageView(1)
    }, []);
    return(
        <div>
            <div className="side-bar-vc">
                <IonRow className="side-bar-v-spacing-vc hover">
                    <IonCol onClick={()=>{setPageView(1)}}  className="ion-text-center label-side-bar-ar-active">
                    <IonIcon className="iconeb-ar "  icon={businessOutline}></IonIcon><br/>
                    <IonLabel className="label-ar "> Budget <br/>/ Income Statement </IonLabel>
                    </IonCol>
                </IonRow>  
                <IonRow className="text-center side-bar-v-spacing-vc ">
                    <IonCol onClick={() => {}} className="ion-text-center label-side-bar-ar" >
                    <IonIcon className="iconeb-ar "  icon={pulseOutline}></IonIcon><br/>
                    <IonLabel className="label-ar ">Live<br/>Projects</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow className="side-bar-v-spacing-vc">
                    <IonCol className="ion-text-center label-side-bar-ar" onClick={() =>{setPageView(2);setOptions('options-area-show') }}>
                    <IonIcon className="iconeb-ar "  icon={listOutline}></IonIcon><br/>
                    <IonLabel className="label-ar "> Qualitative <br/>Data</IonLabel>
                    </IonCol> 
                </IonRow>
                <IonRow className="side-bar-v-spacing-vc">
                    <IonCol className="ion-text-center label-side-bar-ar" onClick={() =>{}}>
                    <IonIcon className="iconeb-ar "  icon={analyticsOutline}></IonIcon><br/>
                    <IonLabel className="label-ar "> Quantitative <br/>Data </IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow className="side-bar-v-spacing-vc">
                    <IonCol className="ion-text-center label-side-bar-ar" onClick={() =>{}}>
                    <IonIcon className="iconeb-ar "  icon={searchOutline}></IonIcon><br/>
                    <IonLabel className="label-ar "> Audit <br/> </IonLabel>
                    </IonCol>
                </IonRow>
            </div>
            <div className={getOptions}>
                <IonRow>
                    <IonCol className="ion-text-right">
                        <IonIcon onClick={()=>{setOptions("options-area-hide")}} className="size-32" icon={closeOutline}></IonIcon>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <div onClick={()=>{callList(3533)}}>
                            Due Diligence Considerations
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <div onClick={()=>{callList(3532)}}>
                            Due Diligence Checklist
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <div onClick={()=>{callList(3534)}}>
                            Due Diligence Detail
                        </div>
                    </IonCol>
                </IonRow>
            </div>
            <div className="body-vc ion-padding">
                <IonHeader>
                    {getQualitative &&
                        <IonRow className="ion-text-left">
                            {getCategory}
                        </IonRow>
                    }
                    {getBudget &&
                        <IonRow>
                            <IonCol className="size-20 ion-text-left"><b>Budget</b></IonCol>
                        </IonRow>
                    }
                </IonHeader>
                <IonContent className="ion-padding ion-text-center vc-content">
                    {getBudget &&
                    <div>
                        <Budget state={props.global_props} />
                    </div>
                    }
                    {getQualitative &&
                    <div className="question-template">
                        <IonRow>
                            <IonCol size="3"></IonCol>
                            <IonCol size="6">
                                <IonImg src="../../assets/images/dmzee_logo-03.png" ></IonImg>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol></IonCol>
                            <IonCol size="10">
                                <div className="size-20 ion-text-left"><b>{cat}</b></div>
                            </IonCol>
                            <IonCol></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                {getDetails}
                            </IonCol>
                        </IonRow>
                    </div>
                    }
                </IonContent>
            </div>
        </div>
    )
}
export default VCMain