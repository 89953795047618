import { IonRow, IonCol, IonIcon, IonLabel } from '@ionic/react';
import {apps,closeOutline} from 'ionicons/icons';

const Settings = (props:any) =>{

    return(
    <div>
        <IonRow>
            <IonCol className="ion-text-right">
            <IonIcon className="size-24" onClick={()=>{props.setSettings("settings-area-hide")}} icon={closeOutline}></IonIcon>
            </IonCol>
        </IonRow>
        <IonRow className="side-bar-v-spacing-ext-ar">
            <IonCol className="label-side-bar-ar view-end icon-gt-view-end-button" id="view_1" onClick={ev=>{;props.setSettings("settings-area-hide") }} >
            <IonIcon className="iconeb-ext-ar size-20 "  icon={apps}></IonIcon>&nbsp;
            <IonLabel className="text-black summary-account">Accounts Mapping <br/> </IonLabel>
            </IonCol>
        </IonRow>
        <IonRow className="side-bar-v-spacing-ext-ar">
            <IonCol className="label-side-bar-ar view-end icon-gt-view-end-button" id="view_2" onClick={(ev) =>{props.setSettings("settings-area-hide")}}>
            <IonIcon className="iconeb-ext-ar size-20 "  icon={apps}></IonIcon>&nbsp;
            <IonLabel className="text-black summary-account">New Opening Balances <br/> </IonLabel>
            </IonCol>
        </IonRow> 
        <IonRow className="side-bar-v-spacing-ext-ar">
            <IonCol className="label-side-bar-ar view-end icon-gt-view-end-button" id="view_3" onClick={(ev) =>{props.setSettings("settings-area-hide")}}>
            <IonIcon className="iconeb-ext-ar size-20 "  icon={apps}></IonIcon>&nbsp;
            <IonLabel className="text-black summary-account">Update GL Data <br/> </IonLabel>
            </IonCol>
        </IonRow>
        
    </div>
    )
}
export default Settings