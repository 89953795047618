import { IonCol, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import { arrowForwardCircleOutline, caretDown, caretUp, chevronForward, notifications } from 'ionicons/icons';
import { useState,useEffect } from 'react';
import LineChart from '../../Charts/LineChart';
import { callStockItemCostPriceDetail, callStockItemCostPriceVarianceDetail, callStockItemDetail, setDateFormatHighCharts } from '../../../dbconnections/Select';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { isNumber } from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'

const StockItem = (props:any) =>{
    const [getChart1, setChart1]                  = useState<any>()
    const [getChart2, setChart2]                  = useState<any>()
    const [getChart3, setChart3]                  = useState<any>()
    const [getChart4, setChart4]                  = useState<any>()
    const [getStockItem, setStockItem]            = useState<any>()
    const [getProduct, setProduct]                = useState<any>()
    
    const drawlineSeries = (
            target:any, 
            data:any, 
            type:any, 
            series:any,
            obj:any
        ) =>{
        let previousPoint:any = null;
        var myPlotLineId="myPlotLine";
        
        let lineChart:any = {
                        chart:{
                            type: type,
                        },
                        title:{
                            text:''
                        },
                        subTitle:{
                            text:''
                        },
                        xAxis: {
                            type: 'datetime'
                        },
                        series:series
                    };
        let c:any = (<HighchartsReact highcharts={Highcharts} options={lineChart}></HighchartsReact>)
        if (obj == 1 )
            setChart1(c)
        if (obj == 2 )
            setChart2(c)
        if (obj == 3 )
            setChart3(c)
        if (obj == 4 )
            setChart4(c)
    }
    const callStockItem = (id:any) =>{
        var x:any = []
        x.push({primary_host:props.primary_host,database:"WPSSNOVAMETAL", id:id})
        callStockItemDetail(x[0])
        .then(response =>{return response})
        .then(data =>{
            var options = []
            options.push(data)
            renderCharts(options[0], 1)
        })
    }
    const callStockItemCostPrice = (id:any) =>{
        var x:any = []
        x.push({primary_host:props.primary_host,database:"WPSSNOVAMETAL", id:id})
        callStockItemCostPriceDetail(x[0])
        .then(response =>{return response})
        .then(data =>{
            var options = []
            options.push(data)
            renderCharts(options[0], 2)
        })
    }
    const callStockItemCostPriceVariance = (id:any) =>{
        var x:any = []
        x.push({primary_host:props.primary_host,database:"WPSSNOVAMETAL", id:id})
        callStockItemCostPriceVarianceDetail(x[0])
        .then(response =>{return response})
        .then(data =>{
            var options = []
            options.push(data)
            renderCharts(options[0], 3)
        })
    }
    const renderCharts = (data:any, obj:any) =>{
        //setShowLoading(false)
        var series =[]
        var prop:any;
        var headers:any = []
        var TableHeaders:any = []
        var y:any = 0;
        //existing = []
        var z:any = null
        //setSelectedAccount(z)
        for(prop in data.data[0]){
                console.log(prop)
                headers.push(prop)
        }
        for (var j = 0 ; j < headers.length; j++){
            if(headers[j] != "datekey" ){
                var s = []
                for(var i = 0 ; i < data.data.length; i++){
                    s.push({
                        x:setDateFormatHighCharts(data.data[i].datekey), 
                        y:data.data[i][headers[j]]/1,
                        datekey:data.data[i].datekey, 
                        name:[headers[j]],
                        
                    })
                }
                series.push({name:[headers[j]], data:s})
                console.log(s)
                console.log(series)
                drawlineSeries("drawBalanceAccounts",s, "line", series, obj )
            }
        }
        
        
    }
    useEffect(() => {
        
        if(true){
            
            callStockItem(props.id)
            callStockItemCostPrice(props.id)
            callStockItemCostPriceVariance(props.id)
            setStockItem(props.id)
            setProduct(props.product)
        }
        
    }, [props.id]); 
    return (
    <div>
        <IonRow>
            <IonCol className="size-24">Inventory Item ({getProduct})</IonCol>
        </IonRow>
        <IonRow>
            <IonCol >
                {getChart1}
            </IonCol>
            <IonCol>
                {getChart2}
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol >
                {getChart3}
            </IonCol>
            <IonCol>
                {getChart4}
            </IonCol>
        </IonRow>
    </div>
    )
}
export default StockItem