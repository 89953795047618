import { IonCol, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import { arrowForwardCircleOutline, returnDownBackSharp, returnUpBack } from 'ionicons/icons';
import { useState,useEffect } from 'react';
import { addCommas, searchMatchingJournals } from '../../dbconnections/Select';
const JournalDetail = (props:any) =>{
    const [getDetailTable, setDetailTable]                    = useState<any>()
    const [getDetailTable2, setDetailTable2]                  = useState<any>()
    const [getDetailTable3, setDetailTable3]                  = useState<any>()
    const [getTotalNetAmount, setTotalNetAmount]              = useState<string>("0")
    const [getTotalNetAmount2, setTotalNetAmount2]            = useState<string>("0")
    const [getState, setState]                                = useState<any>()
    const [getTransactions, setTransactions]                  = useState<any>(true)
    const [getMatching, setMatching]                          = useState<any>(false)
    let prevState:any;
    const setView = (v:any) =>{
      resetView()
      switch(v){
        case 1:setTransactions(true); break;
        case 2:setMatching(true); break;
      }
    }
    const resetView =()=>{
      setTransactions(false)
      setMatching(false)
    }
    const setMatchingJournals = (data:any) =>{
      console.log(data[0])
      var d:any = []
      d.push(data[0])
      var net_total2     :any  =0;
      var gross_total   :any  =0;
      var table:any     = d[0].map((x:any, i:number)=>{
        net_total2       +=x["Net Amount"]/1;
        return(
        <IonRow key={i} className={x.highlighted == 1? "highlighted-row":"summary-detail-row row-id-"+i}>
          <IonCol size="2" className="ion-text-left searchable" onClick={()=>{}}>{x["Journal Number"]}</IonCol>
          {/*<IonCol size="1" className="ion-text-left">{x.Date}</IonCol>*/}
          <IonCol size="2" className="ion-text-left">{x.Dated}</IonCol>
          <IonCol size="1" className="ion-text-left">{x["Back Dated Days"]}</IonCol>
          
          <IonCol size="1" className="ion-text-left">{x.Code}</IonCol>
          {/*<IonCol size="2" className="ion-text-left">{x.Account}</IonCol>*/}
          <IonCol size="2" className="ion-text-left">{x.Type}</IonCol>
          <IonCol size="2" className="ion-text-left">{x["Transaction Description"]}</IonCol>
          <IonCol size="2" className="ion-text-right">{addCommas((x["Net Amount"]/1).toFixed(2))}</IonCol>
        </IonRow>
        )
      })
      setDetailTable2(table)
      setTotalNetAmount2(addCommas(net_total2.toFixed(2)))
    }
    const setJournalDetail = (props:any, data:any)=>{
    
      var d:any = []
      d.push(data[0])
      console.log(d)
      var net_total     :any  =0;
      var gross_total   :any  =0;
      var table:any     = d[0].map((x:any, i:number)=>{
        net_total       +=x["Net Amount"]/1;
        return(
        <IonRow key={i} className={x.highlighted == 1? "highlighted-row":"summary-detail-row row-id-"+i}>
          <IonCol size="2" className="summary-account-level-1 size-16 ion-text-left searchable" onClick={()=>{
            setView(2);
            searchMatchingJournals(props.global_props, x["Journal Number"])
            .then(response=>{return response}) 
            .then(data=>{
              setMatchingJournals(data);
            })
            }
          }>{x["Journal Number"]}</IonCol> 
          <IonCol size="2" className="size-16 ion-text-left">{x.Date}</IonCol>
          {/*<IonCol size="1" className="size-16 ion-text-left">{x.Dated}</IonCol>*/}
          <IonCol size="1" className="size-16 ion-text-left">{x["Back Dated Days"]}</IonCol>
          
          <IonCol size="1" className="size-16 ion-text-left">{x.Code}</IonCol>
          {/*<IonCol size="2" className="size-16 ion-text-left">{x.Account}</IonCol>*/}
          <IonCol size="2" className="size-16 ion-text-left">{x.Type}</IonCol>
          <IonCol size="2" className="size-16 ion-text-left">{x["Transaction Description"]}</IonCol>
          <IonCol size="2" className="size-16 ion-text-right searchable" onClick={()=>{/*callCore(x.Date, x["Journal Number"],x["Net Amount"])*/}}>{addCommas((x["Net Amount"]/1).toFixed(2))}</IonCol>
        </IonRow>
        )
      })
      
      setDetailTable(table)
      setTotalNetAmount(addCommas(net_total.toFixed(2)))
    } 
    useEffect(() => {
      //console.log(balance_sheet)
      //setCurrentAssets("current assets array:\n"+props)
      setState(()=>{
          if(props.data != prevState){
              prevState = props.data
              console.log(props.data)
              setJournalDetail(props.global_props,props.data)
          }}
      )
      
  }, [props]); 
    return(
        <div>
          {getTransactions &&
          <div>
            <IonRow className="summary-account-level-1">
              <IonCol>Detailed Transactions</IonCol>
            </IonRow>
            <div className="ion-padding">
              <IonRow>&nbsp;</IonRow>
              <IonRow className="summary-row-total">
                {/*<IonCol className="ion-text-left" size="2">Posted To</IonCol>*/}
                <IonCol className="ion-text-left" size="2">Journal Number</IonCol>
                <IonCol className="ion-text-left" size="2">Posted On</IonCol>
                <IonCol className="ion-text-left" size="1">Back Dated Days</IonCol>
                
                <IonCol className="ion-text-left" size="1">Code</IonCol>
                {/*<IonCol className="ion-text-left" size="2">Account</IonCol>*/}
                <IonCol className="ion-text-left" size="2">Type</IonCol>
                <IonCol className="ion-text-left" size="2">Desc</IonCol>
                <IonCol className="ion-text-right" size="2">Amount</IonCol>
              </IonRow>
              {getDetailTable}
              <IonRow  className="summary-row-total">
                {/*<IonCol className="ion-text-left" size="2">Total</IonCol>*/}
                <IonCol className="ion-text-left" size="2"></IonCol>
                <IonCol className="ion-text-left" size="2"></IonCol>
                <IonCol className="ion-text-left" size="1"></IonCol>
                
                <IonCol className="ion-text-left" size="1"></IonCol>
                {/*<IonCol className="ion-text-left" size="2"></IonCol>*/}
                <IonCol className="ion-text-left" size="2"></IonCol>
                <IonCol className="ion-text-left" size="2"></IonCol>
                <IonCol className="ion-text-right" size="2">{getTotalNetAmount}</IonCol>
              </IonRow>
            </div>
          </div>
          }
          {getMatching &&
          <div>
            <IonRow className="summary-account-level-1">
              <IonCol>Matching Journals / Batch Transactions</IonCol>
              <IonCol onClick={()=>{setView(1)}} className='ion-text-right text-black'>
                <IonLabel>Back To Detailed Transactions</IonLabel>
                <IonIcon className='size-32' icon={returnUpBack}></IonIcon>
              </IonCol>
            </IonRow>
            <div className="ion-padding">
              <IonRow>&nbsp;</IonRow>
              <IonRow className="summary-row-total">
                {/*<IonCol className="ion-text-left" size="1">Posted To</IonCol>*/}
                <IonCol className="ion-text-left" size="2">Journal Number</IonCol>
                <IonCol className="ion-text-left" size="2">Posted On</IonCol>
                <IonCol className="ion-text-left" size="1">Back Dated Days</IonCol>
                
                <IonCol className="ion-text-left" size="1">Code</IonCol>
                {/*<IonCol className="ion-text-left" size="2">Account</IonCol>*/}
                <IonCol className="ion-text-left" size="2">Type</IonCol>
                <IonCol className="ion-text-left" size="2">Desc</IonCol>
                <IonCol className="ion-text-right" size="2">Amount</IonCol>
              </IonRow>
              {getDetailTable2}
              <IonRow  className="summary-row-total">
                <IonCol className="ion-text-left" size="1">Total</IonCol>
                <IonCol className="ion-text-left" size="1"></IonCol>
                <IonCol className="ion-text-left" size="1"></IonCol>
                <IonCol className="ion-text-left" size="1"></IonCol>
                <IonCol className="ion-text-left" size="1"></IonCol>
                <IonCol className="ion-text-left" size="2"></IonCol>
                <IonCol className="ion-text-left" size="2"></IonCol>
                <IonCol className="ion-text-left" size="1"></IonCol>
                <IonCol className="ion-text-right" size="2">{getTotalNetAmount2}</IonCol>
              </IonRow>
            </div>
          </div>
          }
        </div>
    )
}
export default JournalDetail