import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonIcon, IonCard, IonCardHeader, IonTitle, IonCardContent, IonFooter, IonImg, IonHeader, IonItem, IonLabel, IonButton, IonInput, IonCheckbox, IonSpinner, IonContent } from '@ionic/react';
import {arrowForward, checkmarkOutline, chevronForward, closeOutline, cloudOutline, cloudUploadOutline, documentOutline, settingsOutline} from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { runInThisContext } from 'vm';
import { restoreJournalsFile, runJournalsWithFilters } from '../../dbconnections/Select';
//import { setJournalFilter } from '../../functions/functions';
import "../DataImport/Import.css"
import FileLoader from '../FileLoader/fileloader';
import NewCustomProjectForm from '../Forms/NewCustomProject';
import NewProjectForm from '../Forms/NewProject';
let trCodeList:any=[];
let jnlFilter:any;
let counter:any = 0;
let glVersionId:any = 0;
let glFileName:any = "";
let files:any ={
  opening:0,
  closing:0,
  gl:0,
  coa:0,
}

const CustomImport = (props:any) =>{
    const [getJNLFilter, setJNLFilter]                  = useState<string>("");
    
    const [getMainOptions, setMainOptions]      = useState<any>(true)
    const [getSetupDetails, setSetupDetails]    = useState<any>(false)
    const [getUploads, setUploads]              = useState<any>(false)

    const [getOnlineSystems, setOnlineSystems]  = useState<any>(true)
    const [getFileSystems, setFileSystems]      = useState<any>(true)
    const [getDatabaseSystems, setDatabaseSystems] = useState<any>(true)

    const [getProjectName, setProjectName]      = useState<any>('')
    const [getRounding, setRounding]            = useState<any>('')
    const [getBillingCode, setBillingCode]      = useState<any>('')
    const [getCTT, setCTT]                      = useState<any>(0)
    const [getCountry, setCountry]              = useState<any>(0)
    const [getMigrationId, setMigrationId]      = useState<any>(0)
    const [getGLVersionId, setGLVersionId]          = useState<any>(0)
    const [getGLName, setGLName]                = useState<any>("")
    const [getStructuredForm5, setStructuredForm5] = useState<any>(false)
    const [getStructuredForm6, setStructuredForm6] = useState<any>(false)

    const [getPackageId, setPackageId]          = useState<any>()

    const [getFileCounter, setFileCounter]      = useState<number>(0)
    const [getLoader, setLoader]                        = useState<HTMLIonSelectOptionElement>();

    const [getTransactionCodes, setTransactionCodes ]   = useState<any>();
    const [getTestResults,setTestResults]               = useState<HTMLIonItemElement>();
    const [getFileTestResults,setFileTestResults]       = useState<HTMLIonItemElement>();

    let txtStartDate:any                            = useRef<HTMLIonInputElement>()
    let txtEndDate:any                              = useRef<HTMLIonInputElement>()
    const runTest = () =>{
      let a:any = <IonSpinner name="dots" ></IonSpinner>
        setLoader(a)
      setTestResults(a)
      var d:any = []
      if(props.application_id == 67){
        d.push({
          primary_host:props.props.primary_host,
          glVersionId:glVersionId,
          jnlFilter:jnlFilter,
          startdate:txtStartDate.current.value,
          enddate:txtEndDate.current.value,
          ctt:0,
          migrationId:getMigrationId,
          packageId:getPackageId,
          applicationId:props.application_id
        })
      }
      if(props.application_id == 66){
        d.push({
          primary_host:props.props.primary_host,
          glVersionId:glVersionId,
          jnlFilter:jnlFilter,
          startdate:txtStartDate.current.value,
          enddate:txtEndDate.current.value,
          ctt:getCTT,
          migrationId:getMigrationId,
          packageId:getPackageId,
          applicationId:props.application_id
        })
      }
      var option:any = []
      runJournalsWithFilters(d[0])
      .then(response=>{return response})
      .then(data =>{
        console.log(data)
        option.push(data)
        let results = option[0].map((x:any, i:number)=>{
          return(
            <IonCol className="" key={i} size="4">
              <IonRow className="">
                <IonCol size="10" className="">
                  {x.listdesc}
                </IonCol>
                <IonCol size="2" className="">
                  {
                    (
                      x.error == 0 ?
                      <IonIcon color="success" size="large" icon={checkmarkOutline}></IonIcon>:
                      <IonIcon color="danger" size="large" icon={closeOutline}></IonIcon>
                    )
                  }
                </IonCol>
              </IonRow>
            </IonCol>
          )
        })
        setStructuredForm5(false)
        setStructuredForm6(true)
        a = null
        setTestResults(a)
        setTestResults(results)
      })
    }
    const setSystem = (s:any) =>{
      resetSystem()
      switch(s){
        case 1: setFileSystems(false); setDatabaseSystems(false);setView(2);break;
        case 2: setOnlineSystems(false); setDatabaseSystems(false);setView(2);break;
        case 3: setFileSystems(false); setFileSystems(false);setView(2);break;
      }
    }

    const resetSystem = () =>{
      setOnlineSystems(true);
      setFileSystems(true);
      setDatabaseSystems(true);
      setSetupDetails(false);
      setUploads(false);
    }

    const setView = (v:any) =>{
      resetView()
      switch(v){
        case  1: setMainOptions(true); break;
        case  2: setMainOptions(true);setSetupDetails(true); break;
        case  3: setMainOptions(true);setSetupDetails(true);setUploads(true); break;
      }
    }
    const resetView = () =>{
      setMainOptions(false); 
      setSetupDetails(false);
      setUploads(false);     
    }
    const [getResults, setResults]                    = useState<any>()
    const processFiles =()=>{
      //console.log(props)
      //alert(counter)
      //alert(getFileCounter)
      console.log(files)
      console.log(files.length)
      var f:any;
      counter = 0
      for(f in files){
        console.log(f)
        console.log(files[f]/1)
        if(files[f]/1 != 0)
          counter += files[f]/1
      }
      var option:any = []
      console.log("counter:"+counter)
      console.log("app_id"+props.application_id)
      if((counter == 10 && props.application_id/1 == 67) || (counter == 6  && props.application_id/1 == 66)){
        console.log("process files")
        setStructuredForm5(true)
        var data:any = []
        let a:any = <IonSpinner name="dots"></IonSpinner>
        setLoader(a)
        data.push({
          primary_host:props.props.primary_host,
          version_id:glVersionId, 
          gl_file_name:glFileName, 
          project_name:getProjectName, 
          created_by:props.props.user_id,
          package_id:getPackageId,
          application_id:props.application_id,
          migration_id:getMigrationId
        })
        restoreJournalsFile(data[0])
        .then(response =>{return response})
        .then(data =>{
          //alert(data)
          option.push(data) ;
          //alert(option)
          txtStartDate.current.value = option[0][0].mindate;
          txtEndDate.current.value = option[0][0].maxdate;
          console.log("Start Date:"+option[0][0].mindate)
          console.log("End Date:"+option[0][0].maxdate)
          let elements = option[0].map((x:any,i:number) =>{
            trCodeList.push({id:x.id,isChecked:false})
            return(
                <IonCol size="4" key={i}>
                    <IonItem>
                        <IonLabel>{x.code} {x.description}</IonLabel>
                        <IonCheckbox checked={false} className="jnl" onClick={ev =>{}} ></IonCheckbox>
                    </IonItem>
                </IonCol> 
            )
          })
        
        setTransactionCodes(elements);
        //setShowLoading(false)
        a = null
        setLoader(a)
        })
      }
    }
    return (
        <div className="">
          
          {getMainOptions &&
          <IonRow>
            {getSetupDetails &&
            <IonCol size="1" className='ion-padding ion-text-center'>
              &nbsp;
            </IonCol>
            }
            
            {getFileSystems &&
            <IonCol size="4" className="ion-text-center">
              <div className="" onClick={ev=>{/*set_file_load_option=1;setFileLoader(true); setAPIConfig(false);showNewConnectionOptions(false)*/}}>
                <IonCardHeader>
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <IonIcon icon={documentOutline} className="size-48 text-gt"></IonIcon>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonTitle className='text-black size-18'>Exported Data File Loader</IonTitle>
                    </IonCol>
                  </IonRow>
                </IonCardHeader>
                <IonCardContent className="ion-text-left">
                  <IonRow>
                    <IonCol className="custom-account-text-icon" onClick={()=>{setPackageId(0); setView(2); setSystem(2); setPackageId(2)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/excel.png" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              Excel
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="custom-account-text-icon" onClick={()=>{setPackageId(0); setView(2); setSystem(2); setPackageId(2)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/text.png" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              Custom Text Delimited Files
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="custom-account-text-icon" onClick={()=>{setPackageId(0); setView(2); setSystem(2); setPackageId(2)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/csv.png" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              CSV Files
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </div>
            </IonCol>
            }
            
            {getSetupDetails &&
            <IonCol size="1" className='ion-padding ion-text-center'>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <IonIcon icon={arrowForward} className="size-42"></IonIcon>
            </IonCol>
            }
            {!getSetupDetails &&
              <IonCol>
                <IonCardHeader>
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <IonIcon icon={documentOutline} className="size-48 text-gt"></IonIcon>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <IonTitle className='text-black size-18'>Existing Custom Files</IonTitle>
                    </IonCol>
                  </IonRow>
                </IonCardHeader>
              </IonCol>
            }
            {getSetupDetails &&
              <IonCol size="5" className="">
                <NewCustomProjectForm 
                  state={props} 
                  application_id={props.application_id}
                  package_id={getPackageId}
                  setSystem={(e:any)=>{resetSystem()}} 
                  setUpLoads={(e:any)=>{setView(3)}} 
                  setProjectName={(e:any)=>{setProjectName(e)}}
                  setBillingCode={(e:any)=>{setBillingCode(e)}}
                  setCTT={(e:any)=>{setCTT(e)}}
                  setRounding={(e:any)=>{setRounding(e)}}
                  setCountry={(e:any)=>{setCountry(e)}}
                  setMigrationId={(e:any)=>{setMigrationId(e)}}
                />
              </IonCol>
            }
            </IonRow>
          }
          {getUploads &&
          <div className="file-loader-section ion-padding">
            <IonRow>
              <IonCol size="12">
                  <FileLoader 
                    state={props} 
                    form_name="Custom File" 
                    restore_id={5} 
                    ctt={"0"}
                    rounding ={"000"}
                    migration_name ={getProjectName}
                    billing_code = {"custome"}
                    country = {"0"}
                    migration_id = {getMigrationId}
                    load_counter = {(e:any)=>{files.opening = 1; processFiles();}}
                    file_name = {(e:any)=>{}}
                    gl_version_id={(e:any)=>{}}
                  />
              </IonCol>
            </IonRow>
            {getStructuredForm5 &&
            <IonRow>
              <IonCol className="size-10">
                    <div className="load-settings size-10">
                        <IonRow>
                            <IonCol className="ion-text-left size-14">
                                <IonIcon icon={settingsOutline} size="large" className="icon-gt"></IonIcon>
                                &nbsp;&nbsp;&nbsp;<b>Details</b>
                            </IonCol>
                        </IonRow>
                        <IonRow>&nbsp;</IonRow>
                        <IonItem>
                            <IonCol size="12">
                                <IonLabel className=""><b>Date Range</b></IonLabel>
                            </IonCol>
                        </IonItem>
                        <IonRow>&nbsp;</IonRow>
                        <IonItem>
                            <IonCol size="12">
                                <IonLabel className=""><b>Select GL Start and End Date</b></IonLabel>
                            </IonCol>
                        </IonItem>
                        <IonRow>
                            <IonCol size="5">
                                <IonItem>
                                    <IonLabel position="floating">Start Date</IonLabel>
                                    <IonInput type="date" ref={txtStartDate} />
                                </IonItem>
                            </IonCol>
                            <IonCol size="1" className="ion-padding">
                                {getLoader}
                            </IonCol>
                            <IonCol size="5">
                                <IonItem>
                                    <IonLabel position="floating">End Date</IonLabel>
                                    <IonInput type="date" ref={txtEndDate}   />
                                </IonItem>
                            </IonCol>
                            <IonCol size="1" className="ion-padding">
                                {getLoader}
                            </IonCol>
                        </IonRow>
                    </div>
              </IonCol>
            </IonRow>
            }
            {getStructuredForm6 &&
            <div>
              <IonRow>
                <IonCol className="ion-text-left size-20">
                  <IonIcon icon={checkmarkOutline} className="size-48 text-gt"></IonIcon>Test Results
                </IonCol>
              </IonRow>
              <IonRow className="size-12">
                {getTestResults}
              </IonRow>
            </div>
            }
          </div>
          }

        </div>
    )
}

export default CustomImport