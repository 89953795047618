import { IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonCheckbox } from '@ionic/react';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, options, filter} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import "./CorrelationTable.css"
import $ from "jquery"

const CorrelationTable = (props:any) =>{
    console.log(props.data)
    var options:any = []
    options.push(props.data)
    const [getData,setData]             = useState<any>()
    // get table column
    //const column = Object.keys(options);
    const column:any = []
    let x:any = "";
    for(x in options[0][0]){
        column.push(x)
    }
    // get table heading data
    console.log(column)
    const ThData:any =()=>{
        return column.map((data:any, i:number)=>{
            if(data == "Fields")
                return <IonCol  className="cor-header-box ion-text-center header-rotate ion-padding" key={i}><b>{/*data*/}</b></IonCol>
            if(data != "Fields")
                return <IonCol  className="cor-header-box ion-text-center header-rotate ion-padding" key={i}><b>{data}</b></IonCol>
        })
    }
    // get table row data
    const tdData:any =() =>{
        return options[0].map((data:any, i:number)=>{
            return(
                <IonRow key={i}>
                    {
                        column.map((v:any, z:number)=>{
                            if(v != "Fields")
                            return <IonCol  key={z} className={"cor-box cor text-white ion-text-center ion-padding "+getColor((data[v]/1).toFixed(1).toString())}>{(data[v]/1).toFixed(4)}</IonCol>
                            if(v == "Fields")
                            return <IonCol  key={z} className="cor-box ion-text-left ion-padding"><b>{(data[v])}</b></IonCol>
                        })
                    }
                </IonRow>
            )
        })
    }
    const getColor = (value:any) =>{
        console.log(value)
        value = value.replaceAll('-','')
        switch(value){
            case "0.0" : return("cor_0_0"); break;
            case "0.1" : return("cor_0_1"); break;
            case "0.2" : return("cor_0_2"); break;
            case "0.3" : return("cor_0_3"); break;
            case "0.4" : return("cor_0_4"); break;
            case "0.5" : return("cor_0_5"); break;
            case "0.6" : return("cor_0_6"); break;
            case "0.7" : return("cor_0_7"); break;
            case "0.8" : return("cor_0_8"); break;
            case "0.9" : return("cor_0_9"); break;
            case "1.0" : return("cor_1_0"); break;
            default    : return("cor_null");break;
        }
    }

    const filterColors = (option:any, state:boolean) =>{
        var x:any = document.getElementsByClassName("cor");
        for (var i = 0 ; i < x.length; i++){
            console.log(x[i])
            if(state)
            if($(x[i]).hasClass(option+"_disabled")){
                console.log("found")
                {
                    $(x[i]).addClass(option)
                    $(x[i]).removeClass(option+"_disabled")
                }
            }

            if(state == false)
            if($(x[i]).hasClass(option)){
                console.log("found")
                {
                    $(x[i]).addClass(option+"_disabled")
                    $(x[i]).removeClass(option)
                }
            }
            
        }
    }
    useEffect(() => {
        if(props.data != getData){
            setData(props.data)
        }
    }, [props.data]);
    return (
        <IonRow className="table size-8 correlaion-table">
            <IonCol size="7">
                <IonRow>
                    {ThData()}
                </IonRow>
                {tdData()}
            </IonCol>
            <IonCol className="size-8 ion-text-right ion-padding">
                <IonRow>
                    <IonCol size="1"></IonCol>
                    <IonCol size="1"></IonCol>
                    <IonCol size="5" className="ion-text-right"><b>Size Of Correlation</b></IonCol>
                    <IonCol><b>Interpretation</b></IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_1_0",e.currentTarget.checked )}}></IonCheckbox></IonCol>    
                    <IonCol size="1" className="cor_1_0"></IonCol>
                    <IonCol size="5" className="ion-text-right">.90 to 1.00 (−.90 to −1.00)</IonCol>
                    <IonCol>Very high positive (negative)</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_0_9",e.currentTarget.checked )}}></IonCheckbox></IonCol>
                    <IonCol size="1" className="cor_0_9"></IonCol>
                    <IonCol size="5" className="ion-text-right">.80 to .90 (−.80 to −.90)</IonCol>
                    <IonCol>High positive (negative)</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_0_8",e.currentTarget.checked )}}></IonCheckbox></IonCol>
                    <IonCol size="1" className="cor_0_8"></IonCol>
                    <IonCol size="5" className="ion-text-right">.70 to .80 (−.70 to −.80)</IonCol>
                    <IonCol>High positive (negative)</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_0_7",e.currentTarget.checked )}}></IonCheckbox></IonCol>
                    <IonCol size="1" className="cor_0_7"></IonCol>
                    <IonCol size="5" className="ion-text-right">.60 to .70 (−.60 to −.70)</IonCol>
                    <IonCol>Moderate positive (negative)</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_0_6",e.currentTarget.checked )}}></IonCheckbox></IonCol>
                    <IonCol size="1" className="cor_0_6"></IonCol>
                    <IonCol size="5" className="ion-text-right">.50 to .60 (−.50 to −.60)</IonCol>
                    <IonCol>Moderate positive (negative)</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_0_5",e.currentTarget.checked )}}></IonCheckbox></IonCol>
                    <IonCol size="1" className="cor_0_5"></IonCol>
                    <IonCol size="5" className="ion-text-right">.40 to .50 (−.40 to −.50)</IonCol>
                    <IonCol>Low positive (negative)</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_0_4",e.currentTarget.checked )}}></IonCheckbox></IonCol>
                    <IonCol size="1" className="cor_0_4"></IonCol>
                    <IonCol size="5" className="ion-text-right">.30 to .40 (−.30 to −.40)</IonCol>
                    <IonCol>Low positive (negative)</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_0_3",e.currentTarget.checked )}}></IonCheckbox></IonCol>
                    <IonCol size="1" className="cor_0_3"></IonCol>
                    <IonCol size="5" className="ion-text-right">.20 to .30 (-.20 to −.30)</IonCol>
                    <IonCol>negligible </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_0_2",e.currentTarget.checked )}}></IonCheckbox></IonCol>
                    <IonCol size="1" className="cor_0_2"></IonCol>
                    <IonCol size="5" className="ion-text-right">.10 to .20 (-.10 to −.20)</IonCol>
                    <IonCol>negligible </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_0_1",e.currentTarget.checked )}}></IonCheckbox></IonCol>
                    <IonCol size="1" className="cor_0_1"></IonCol>
                    <IonCol size="5" className="ion-text-right">.00 to .10 ( .00 to −.10)</IonCol>
                    <IonCol>negligible </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1"><IonCheckbox checked={true} onClick={(e)=>{filterColors("cor_null",e.currentTarget.checked )}}></IonCheckbox></IonCol>
                    <IonCol size="1" className="cor_null"></IonCol>
                    <IonCol size="5" className="ion-text-right">No Data</IonCol>
                    <IonCol>Indeterminable </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    )
}

export default CorrelationTable