import { IonCol, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { analytics, apps, businessOutline, home} from 'ionicons/icons';
import { useState,useEffect } from 'react';

const Navigation = (props:any) =>{
    const[getState, setState]       = useState<any>()
    const[getProject, setProject]   = useState<any>()
    var location:any = [];
    if(props.location == 0){
        location = (
            <IonRow className="text-black size-16 ion-padding">
                <IonCol size="8">
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Analytical Reviews</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={home} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Home</IonLabel>
                </IonCol>
                <IonCol className="ion-text-right years-area ">
                    {props.years}
                </IonCol>
            </IonRow>
        )
    }
    if(props.location == 1){
        location = (
            <IonRow className="text-black size-16 ion-padding">
                <IonCol size="8">
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Analytical Reviews</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={home} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Home</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={businessOutline} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>{props.project}</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={analytics} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>Reports</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;  <IonLabel>Balance Sheet</IonLabel>
                </IonCol>
                <IonCol className="ion-text-right years-area">
                    {props.years}
                </IonCol>
            </IonRow>
        )
    }
    if(props.location == 2){
        location = (
            <IonRow className="text-black size-16 ion-padding">
                <IonCol size="8">
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Analytical Reviews</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={home} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Home</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={businessOutline} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>{props.project}</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={analytics} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>Reports</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;  <IonLabel>Income & Expense</IonLabel>
                </IonCol>
                <IonCol className="ion-text-right years-area">
                    {props.years}
                </IonCol>
            </IonRow>
        )
    }
    if(props.location == 3){
        location = (
            <IonRow className="text-black size-16 ion-padding">
                <IonCol size="8">
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Analytical Reviews</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={home} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Home</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={businessOutline} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>{props.project}</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={analytics} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>Reports</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;  <IonLabel>Cash Flow</IonLabel>
                </IonCol>
                <IonCol className="ion-text-right years-area">
                    {props.years}
                </IonCol>
            </IonRow>
        )
    }
    if(props.location == 4){
        location = (
            <IonRow className="text-black size-16 ion-padding">
                <IonCol size="8">
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Analytical Reviews</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={home} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Home</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={businessOutline} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>{props.project}</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={analytics} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>Reports</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>Liquidity</IonLabel>
                </IonCol>
                <IonCol className="ion-text-right years-area">
                    {props.years}
                </IonCol>
            </IonRow>
        )
    }
    if(props.location == 5){
        location = (
            <IonRow className="text-black size-16 ion-padding">
                <IonCol size="8">
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Analytical Reviews</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={home} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Home</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={businessOutline} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>{props.project}</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={analytics} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>Reports</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;  <IonLabel>Solvency</IonLabel>
                </IonCol>
                <IonCol className="ion-text-right years-area">
                    {props.years}
                </IonCol>
            </IonRow>
        )
    }
    if(props.location == 6){
        location = (
            <IonRow className="text-black size-16 ion-padding">
                <IonCol size="8">
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Analytical Reviews</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={home} className="size-20 text-gt"></IonIcon>&nbsp;<IonLabel>Home</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={businessOutline} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>{props.project}</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={analytics} className="size-20 text-gt"></IonIcon>&nbsp; <IonLabel>Reports</IonLabel>&nbsp; /&nbsp;
                    <IonIcon icon={apps} className="size-20 text-gt"></IonIcon>&nbsp;  <IonLabel>Net Income</IonLabel>
                </IonCol>
                <IonCol className="ion-text-right years-area">
                    {props.years}
                </IonCol>
            </IonRow>
        )
    }
    useEffect(() => {
        setState(()=>{
            if(props.location != getState || props.project != getProject){
                setState(props.location)
                setProject(props.project)
            }}
        )
        
    }, [props.location]); 
    return(
        <div>
            {location}
        </div>
        
    )
}
export default Navigation;