import { IonButton,IonCheckbox, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonTextarea, IonBadge } from '@ionic/react';
import { alertCircleSharp, analytics, analyticsOutline, analyticsSharp, arrowBackSharp, arrowForwardSharp, bluetoothSharp, calendarOutline, checkmarkCircle, checkmarkCircleSharp, checkmarkDone, checkmarkDoneCircle, checkmarkOutline, closeOutline, cogOutline, copySharp, exitOutline, filterSharp, gridOutline, hammerOutline, homeOutline, listSharp, logInOutline, logOut, logOutOutline, mailOutline, mailSharp, pencilOutline, peopleCircleSharp, peopleOutline, personOutline, personSharp, push, sendOutline, settingsOutline, settingsSharp, stopCircleSharp, trashBin, trashBinSharp, trashOutline, warningOutline, warningSharp, wifiOutline } from 'ionicons/icons';
import React,{ useRef, useState } from 'react';

var account_level_1:any = []
var staging:any = []
const addCommas = (nStr:any) =>{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}
const OpeningBalance = (props:any) =>{
    const [getShowLoading, setShowLoading]              = useState<boolean>(false)
    const [getLoadingMsg, setLoadingMsg]                = useState<any>("")
    const [getMappingList, setMappingList]              = useState<HTMLIonRowElement>()
    

    const callOpeningAccountBalance = () =>{
        var options:any = []
        fetch(props.primaryhost+'ar/dbconnect/select.jsp?etl=selectdimaccountlevel0balance'+
        "&analytical_reviews_id="+props.analyticalReviewsId
        ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response =>  response.json())
        .then(data =>{
            setShowLoading(false)
            options.push(data.data)
            var headers:any = (
                                <IonRow key={0} className="summary-row-header freeze-header">
                                    <IonCol size="2">Date</IonCol>
                                    <IonCol size="2">Account Number</IonCol>
                                    <IonCol size="4">Account Name</IonCol>
                                    <IonCol size="2">Balance</IonCol>
                                </IonRow>
                            )
            var detail:any = options[0].map((x:any , i:number)=>{
                var key1:any = x.account_level_0_key
                var value:any = x.value
                staging.push({account:key1,value:value, value_old:value})
                //console.log(staging)
                return(
                    <IonRow className="summary-detail-row" key={i}>
                        <IonCol size="2">{x.datekey}</IonCol>
                        <IonCol size="2">{x.account_number}</IonCol>
                        <IonCol size="4">{x.account_name}</IonCol>
                        <IonCol size="2">
                            <IonInput className="input-style-11 ion-text-right" value={addCommas(x.value/1)} onIonChange={ev=>{staging[i].value = ev.detail.value; checkForChanges(); console.log(staging)}} />  
                        </IonCol>
                    </IonRow>
                )
            })
            var complete:any = [headers,detail]
            setMappingList(complete)
            setShowLoading(false)
        })
        .catch(error=>{
            setShowLoading(false)
            alert("Could not find accounts :"+error)
        })
    }
    const [getChange, setChange]                = useState<boolean>(false)
    const checkForChanges = () =>{
        var change:boolean = false;
        for(var i = 0; i < staging.length; i++){
            if(staging[i].value != staging[i].value_old){
                change = true
            }
        }
        setChange(change)
    }
    const updateOpeningAccountBalance = () =>{
        for(var i = 0; i < staging.length; i++){
            if(staging[i].value != staging[i].value_old){
                fetch(props.primaryhost+'ar/dbconnect/update.jsp?etl=updateopeningbalance'+
                "&analytical_reviews_id="+props.analyticalReviewsId+
                "&account_level_0_key="+staging[i].account+
                "&value="+staging[i].value
                ,
                    {
                        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                    }
                )
                .then(response =>  response.json())
                .then(data =>{
                    console.log("account:"+staging[i].account+", from:"+staging[i].value_old+" to:"+staging[i].value+" done")
                })
            }
        }
        updateFactTable()
    }
    const updateFactTable = () =>{
        setShowLoading(true)
        fetch(props.primaryhost+'ar/dbconnect/update.jsp?etl=updatefacttablebalance'+
        "&analytical_reviews_id="+props.analyticalReviewsId
        ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response =>  response.json())
        .then(data =>{
            setShowLoading(false)
            alert("Update complete")
        })
        .catch(error=>{
            setShowLoading(false)
            alert("Something went wrong")
        })
    }
    React.useEffect(() => {
        callOpeningAccountBalance()
      }, []);
    return(
        <div>
            <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={getLoadingMsg} ></IonLoading>
            <div className='is-area-to-print ion-padding'>
                <IonHeader>
                    <IonRow>
                        <IonCol size="11">
                            <div className="size-20 summary-account-level-1"><b>Opening Balance- Update</b></div>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            {getChange &&
                                <IonButton onClick={()=>{updateOpeningAccountBalance()}}>update</IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </IonHeader> 
                <IonContent className='is-area-to-print-content ion-padding'>
                    <IonRow>&nbsp;</IonRow>
                    <IonRow>&nbsp;</IonRow>
                    <IonRow>
                        <IonCol>{getMappingList}</IonCol>
                    </IonRow>
                </IonContent>
            </div>
        </div>
    )
}

export default OpeningBalance;