
import { IonCol, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import { arrowForwardCircleOutline, caretDown, caretUp, chevronForward, notifications } from 'ionicons/icons';
import { useState,useEffect } from 'react';
import { addCommas } from '../../dbconnections/Select';
const BalanceSheet = (props:any, data:any) =>{ 
  //console.log(props)
  
  const [getPrevEndDate, setPrevEndDate]                        = useState<any>()
  const [getEndDate, setEndDate]                                = useState<any>()
  
  const [hideCurrentAssets, showCurrentAssets]                  = useState<boolean>(true)
  const [getRotateIcon, setRotateIcon]                          = useState<string>("rotate-icon-forward")
  const [hideNonCurrentAssets, showNonCurrentAssets]            = useState<boolean>(true)
  const [hideOwnersEquity, showOwnersEquity]                    = useState<boolean>(true)
  const [hideCurrentLiabilities, showCurrentLiabilities]        = useState<boolean>(true)
  const [hideNonCurrentLiabilities, showNonCurrentLiabilities]  = useState<boolean>(true)
  const [hideIncome, showIncome]                                = useState<boolean>(true)
  const [hideExpenses, showExpenses]                            = useState<boolean>(true)

  const [getState, setState]      = useState<any>()
  const [getCurrentAssets, setCurrentAssets] = useState<any>()
  const [getIncomeStart, setIncomeStart]                                = useState<any>()
  const [getEquityStart, setEquityStart]                                = useState<any>()
  const [getExpensesStart, setExpensesStart]                            = useState<any>()

  const [getCurrentAssetsStart, setCurrentAssetsStart]                  = useState<any>()
  const [getNonCurrentAssetsStart, setNonCurrentAssetsStart]            = useState<any>()
  const [getCurrentLiabilitiesStart, setCurrentLiabilitiesStart]        = useState<any>()
  const [getNonCurrentLiabilitiesStart, setNonCurrentLiabilitiesStart]  = useState<any>()

  const [getIncomeMovementTotal, setIncomeMovementTotal]                = useState<string>()
  const [getExpensesMovementTotal, setExpensesMovementTotal]            = useState<string>()

  const [getGains, setGains]                                             = useState<string>()

  const [getExpensesStartTotal, setExpensesStartTotal]                            = useState<string>()
  const [getIncomeStartTotal, setIncomeStartTotal]                                = useState<string>()

  const [getStartTotal, setStartTotal]                                            = useState<string>()
  const [getEndTotal, setEndTotal]                                                = useState<string>()
  const [getMovementTotal, setMovementTotal]                                      = useState<string>()

  const [getAssetsMovementTotal, setAssetsMovementTotal]                          = useState<string>()
  const [getCurrentAssetsMovementTotal, setCurrentAssetsMovementTotal]            = useState<string>()
  const [getNonCurrentAssetsMovementTotal, setNonCurrentAssetsMovementTotal]      = useState<string>()
  const [getCurrentAssetsStartTotal, setCurrentAssetsStartTotal]                  = useState<string>()
  const [getAssetsStartTotal, setAssetsStartTotal]                                = useState<string>()
  const [getNonCurrentAssetsStartTotal, setNonCurrentAssetsStartTotal]            = useState<string>()
  
  const [getEquityStartTotal, setEquityStartTotal]                                = useState<string>()


  const [getIncomeEndTotal, setIncomeEndTotal]                                    = useState<string>()
  const [getExpensesEndTotal, setExpensesEndTotal]                                = useState<string>()
  
  const [getLiabilitiesMovementTotal, setLiabilitiesMovementTotal]                = useState<string>()
  const [getCurrentLiabilitiesMovementTotal, setCurrentLiabilitiesMovementTotal]  = useState<string>()
  const [getNonCurrentLiabilitiesMovementTotal, setNonCurrentLiabilitiesMovementTotal]  = useState<string>()
  const [getEquityMovementTotal, setEquityMovementTotal]                          = useState<string>()

  const [getLiabilitiesStartTotal, setLiabilitiesStartTotal]                      = useState<string>()
  const [getCurrentLiabilitiesStartTotal, setCurrentLiabilitiesStartTotal]        = useState<string>()
  const [getNonCurrentLiabilitiesStartTotal, setNonCurrentLiabilitiesStartTotal]  = useState<string>()

  const [getAssetsEndTotal, setAssetsEndTotal]                                    = useState<string>()
  const [getCurrentAssetsEndTotal, setCurrentAssetsEndTotal]                      = useState<string>()
  const [getNonCurrentAssetsEndTotal, setNonCurrentAssetsEndTotal]                = useState<string>()
  const [getEquityEndTotal, setEquityEndTotal]                                    = useState<string>()

  const [getLiabilitiesEndTotal, setLiabilitiesEndTotal]                          = useState<string>()
  const [getCurrentLiabilitiesEndTotal, setCurrentLiabilitiesEndTotal]            = useState<string>()
  const [getNonCurrentLiabilitiesEndTotal, setNonCurrentLiabilitiesEndTotal]      = useState<string>()
  
  const [getTotalDebit          , setTotalDebit ]             = useState<string>()
  const [getTotalCredit         , setTotalCredit ]            = useState<string>()
  const [getTotalOpening_Balance, setTotalOpening_Balance]    = useState<string>()
  const [getTotalClosing_Balance, setTotalClosing_Balance]    = useState<string>()
  const [getTotalMovement       , setTotalMovement]           = useState<string>()
  var prevState:any;
  var gain_counter                          :number = 0;
  var gains:any;
  var equity_gains:any = []                     
  var net_gains                             :number = 0;
  var current_assets_start_total            :number = 0;
  var current_assets_end_total              :number = 0;
  var current_assets_movement_total         :number = 0;

  var income_start_total                    :number = 0;
  var income_end_total                      :number = 0;
  var income_movement_total                 :number = 0;

  var expense_start_total                    :number = 0;
  var expense_end_total                      :number = 0;
  var expense_movement_total                 :number = 0;

  var non_current_assets_start_total        :number = 0;
  var non_current_assets_end_total          :number = 0;
  var non_current_assets_movement_total     :number = 0;

  var equity_start_total            :number = 0;
  var equity_end_total              :number = 0;
  var equity_movement_total         :number = 0;

  var current_liabilities_start_total       :number = 0;
  var current_liabilities_end_total         :number = 0;
  var current_liabilities_movement_total    :number = 0;

  var non_current_liabilities_start_total   :number = 0;
  var non_current_liabilities_end_total     :number = 0;
  var non_current_liabilities_movement_total:number = 0;


  const setBalanceSheet = (global_props:any, balance_sheet:any) =>{
  console.log(balance_sheet.data)
  var options:any = []
  options.push(balance_sheet.data)
  //global_props.analytical_report_id=1;
  var current_assets_start:any;
 
  try{   
    setPrevEndDate(balance_sheet.data[0].opening_date)
    //setStartDate(data.data[0].start_date)
    setEndDate(balance_sheet.data[0].ending_date)
      current_assets_start = options[0].map((x:any, i:any)=>{
          //console.log(i)
        if(x.account_level_2_key == 1 && (x.opening_value != 0 && x.closing_value != 0) ){
        current_assets_start_total += x.opening_value/1;
        current_assets_end_total += x.ending_value/1;
        current_assets_movement_total += x.movement/1;
          return(
            <IonRow key={i} className={x.highlighted == 1? "highlighted-row":"summary-detail-row row-id-"+i}>
              <IonCol  className="summary-account" size="4">
                <span><IonIcon onClick={()=>{
                  //showActionPreDefined(false);showActionDefined(false);
                  //showKPIModal(true);                  
                  //setAccountLevel3kpi(x.account_level_3_key);
                  //setAccountLevel2kpi(x.account_level_2_key);
                  //setAccountLevel1kpi(x.account_level_1_key);
                  //setAccountLevel0kpi(x.account_level_0_key);
                  //setAccountForProfile(x.account_level_0_description)
                  //setAccountCurrentValue(addCommas(x.ending_value))
                  }} icon={notifications} className={x.kpi == 0?"text-grey size-24":x.kpi == 1? "text-green size-24":x.kpi == -1?"text-red size-24":"text-grey size-24"}></IonIcon></span>
                <span>&nbsp;<IonIcon onClick={ev=>{
                //global_props.global_props.account_level_0 = x.account_level_0_key;
                //global_props.global_props.account_level_1 = x.account_level_1_key;
                //row_id = i; 
                //date_desc = x.datekey
                //global_props.global_props.account_level_0_desc = x.DESC;
                //global_props.global_props.account_level_1_desc = x.account_level_1
                //setHighlighted()
                }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-24':'text-grey size-24'}></IonIcon>
                </span>
                <span onClick={()=>{
                //global_props.migration_id = 0;
                //showSettingStage(5); 
                //setSummaryDashBoardBalancesheet(true);
                //global_props.analytical_report_id = 2;
                
                props.callSummaryAssets(
                  x.account_level_3_key,
                  x.account_level_2_key,
                  x.account_level_1_key,
                  x.account_level_0_key,
                  x.sdate,
                  x.edate,
                  2,
                  0,
                  x.account_level_0_description
                ) 
                }}>&nbsp;{x.account_level_0_description}</span>
              </IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.opening_value)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.ending_value)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.movement)}{(x.movement > 0)? <IonIcon icon={caretUp} color="success"></IonIcon>:x.movement < 0? <IonIcon icon={caretDown} color="danger"></IonIcon> : <div></div>}</IonCol>
              <IonCol size="2" className="ion-text-right">{x.movement_percent}</IonCol>
            </IonRow>
          )
        }
      }) 



      var non_current_assets_start:any = options[0].map((x:any, i:any)=>{
        if(x.account_level_2_key == 2  && (x.opening_value != 0 && x.closing_value != 0)){
          non_current_assets_start_total += x.opening_value/1;
          non_current_assets_end_total += x.ending_value/1;
          non_current_assets_movement_total += x.movement/1;
          return(
            <IonRow key={i}  className={"summary-detail-row"}>
              <IonCol className="summary-account" size="4">
                <span><IonIcon onClick={()=>{
                  //showActionPreDefined(false);showActionDefined(false);
                  //showKPIModal(true);
                  //setAccountLevel3kpi(x.account_level_3_key);
                  //setAccountLevel2kpi(x.account_level_2_key);
                  //setAccountLevel1kpi(x.account_level_1_key);
                  //setAccountLevel0kpi(x.account_level_0_key);
                  //setAccountForProfile(x.account_level_0_description)
                  //setAccountCurrentValue(addCommas(x.ending_value))
                  }} icon={notifications} className={x.kpi == 0?"text-grey size-24":x.kpi == 1? "text-green size-24":x.kpi == -1?"text-red size-24":"text-grey size-24"}></IonIcon></span>
              <span>&nbsp;<IonIcon onClick={ev=>{
                //props.global_props.account_level_0 = x.account_level_0_key;
                //props.global_props.account_level_1 = x.account_level_1_key;
                //row_id = i; 
                //date_desc = x.datekey
                //props.global_props.account_level_0_desc = x.DESC;
                //props.global_props.account_level_1_desc = x.account_level_1
                //setHighlighted()
                }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-24':'text-grey size-24'}></IonIcon></span>
                <span onClick={()=>{
                //props.global_props.migration_id = 0;
                //</IonCol>showSettingStage(5); 
                //props.global_props.analytical_report_id = 2;
                //</IonCol>setSummaryDashBoardBalancesheet(true);
                //props.global_props.account_level_3 = x.account_level_3_key
                //props.global_props.account_level_2 = x.account_level_2_key
                //props.global_props.global_props.account_level_1 = x.account_level_1_key
                //props.global_props.global_props.account_level_0 = x.account_level_0_key
                //props.global_props.start_date          = x.sdate
                //props.global_props.end_date            = x.edate
                props.callSummaryAssets(
                  x.account_level_3_key,
                  x.account_level_2_key,
                  x.account_level_1_key,
                  x.account_level_0_key,
                  x.sdate,
                  x.edate,
                  0,
                  2,
                  x.account_level_0_description
                ) 
                }}> {x.account_level_0_description}</span>
              </IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.opening_value)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.ending_value)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.movement)}{(x.movement > 0)? <IonIcon icon={caretUp} color="success"></IonIcon>:x.movement < 0? <IonIcon icon={caretDown} color="danger"></IonIcon> : <div></div>}</IonCol>
              <IonCol size="2" className="ion-text-right">{x.movement_percent}</IonCol>
            </IonRow>
          )
        }
      })
    
      var equity_start:any = options[0].map((x:any, i:any)=>{
        
        if(gain_counter == 0 && x.isLive == 1 ){
          equity_start_total += x.net_movement0/1;
          equity_end_total += x.net_movement/1;
          equity_movement_total += (x.net_movement/1 - x.net_movement0/1);
          gains =(
            <IonRow key={i}  className={"summary-detail-row"}>
              <IonCol className="summary-account" size="4">
                <span><IonIcon icon={notifications} className={x.kpi == 0?"text-grey size-24":x.kpi == 1? "text-green size-24":x.kpi == -1?"text-red size-24":"text-grey size-24"}></IonIcon></span>
                  <span>&nbsp;<IonIcon icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-24':'text-grey size-24'}></IonIcon></span>
                <span><b>{"Net Gains"}</b></span>
              </IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.net_movement0)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.net_movement)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.net_movement)}{(x.net_movement > 0)? <IonIcon icon={caretUp} color="success"></IonIcon>:x.net_movement < 0? <IonIcon icon={caretDown} color="danger"></IonIcon> : <div></div>}</IonCol>
              <IonCol size="2" className="ion-text-right">{}</IonCol>
            </IonRow>
          )
        }
        if(x.account_level_2_key == 3  ){
          equity_start_total += x.opening_value/1;
          equity_end_total += x.ending_value/1;
          equity_movement_total += x.movement/1;
          return(
            <IonRow key={i}  className={"summary-detail-row"}>
              <IonCol className="summary-account" size="4">
                <span><IonIcon onClick={()=>{
                  //showActionPreDefined(false);showActionDefined(false);
                  //showKPIModal(true);
                  //setAccountLevel3kpi(x.account_level_3_key);
                  //setAccountLevel2kpi(x.account_level_2_key);
                  //setAccountLevel1kpi(x.account_level_1_key);
                  //setAccountLevel0kpi(x.account_level_0_key);
                  //setAccountForProfile(x.account_level_0_description)
                  //setAccountCurrentValue(addCommas(x.ending_value))
                let a:any = []
                a.push({
                  setAccountLevel3kpi:x.account_level_3_key,
                  setAccountLevel2kpi:x.account_level_2_key,
                  setAccountLevel1kpi:x.account_level_1_key,
                  setAccountLevel0kpi:x.account_level_0_key,
                  setAccountForProfile:x.account_level_0_description,
                  setAccountCurrentValue:addCommas(x.ending_value),
                  showActionPreDefined:false,
                  showActionDefined:false,
                  showKPIModal:true
                })
                props.showKPI(a[0])
                
                
                
              }} icon={notifications} className={x.kpi == 0?"text-grey size-24":x.kpi == 1? "text-green size-24":x.kpi == -1?"text-red size-24":"text-grey size-24"}></IonIcon></span>
              <span>&nbsp;<IonIcon onClick={ev=>{
                //props.global_props.account_level_0 = x.account_level_0_key;
                //props.global_props.account_level_1 = x.account_level_1_key;
                //row_id = i; 
                //date_desc = x.datekey
                //props.global_props.account_level_0_desc = x.DESC;
                //props.global_props.account_level_1_desc = x.account_level_1
                //setHighlighted()
                let a:any = []
                a.push({
                    account_level_0: x.account_level_0_key, 
                    account_level_1: x.account_level_1_key, 
                    row_id:i, 
                    date_desc:x.datekey,
                    account_level_0_desc : x.DESC,
                    account_level_1_desc : x.account_level_1
                })
                props.setHighlighted(a[0])
                }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-24':'text-grey size-24'}></IonIcon></span>
                <span onClick={()=>{
                //props.migration_id = 0;
                //showSettingStage(5); 
                //props.analytical_report_id = 2;
                //setSummaryDashBoardBalancesheet(true);
                //props.account_level_3 = x.account_level_3_key
                //props.account_level_2 = x.account_level_2_key
                //props.global_props.account_level_1 = x.account_level_1_key
                //props.global_props.account_level_0 = x.account_level_0_key
                //props.start_date          = x.sdate
                //props.end_date            = x.edate
                props.callSummaryAssets(
                  x.account_level_3_key,
                  x.account_level_2_key,
                  x.account_level_1_key,
                  x.account_level_0_key,
                  x.sdate,
                  x.edate,
                  0,
                  2,
                  x.account_level_0_description
                ) 
                }}> {x.account_level_0_description}</span>
              </IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.opening_value)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.ending_value)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.movement)}{(x.movement > 0)? <IonIcon icon={caretUp} color="success"></IonIcon>:x.movement < 0? <IonIcon icon={caretDown} color="danger"></IonIcon> : <div></div>}</IonCol>
              <IonCol size="2" className="ion-text-right">{x.movement_percent}</IonCol>
            </IonRow>
          )
          
        }gain_counter++;
      })
      var current_liabilities_start:any = options[0].map((x:any, i:any)=>{
        if(x.account_level_2_key == 4   && (x.opening_value != 0 && x.closing_value != 0)){
          current_liabilities_start_total += x.opening_value/1;
          current_liabilities_end_total += x.ending_value/1;
          current_liabilities_movement_total += x.movement/1;
          return(
            <IonRow key={i}  className={"summary-detail-row"}>
              <IonCol className="summary-account" size="4">
                <span><IonIcon onClick={()=>{
                  //showActionPreDefined(false);showActionDefined(false);
                  //showKPIModal(true);
                  //setAccountLevel3kpi(x.account_level_3_key);
                  //setAccountLevel2kpi(x.account_level_2_key);
                  //setAccountLevel1kpi(x.account_level_1_key);
                  //setAccountLevel0kpi(x.account_level_0_key);
                  //setAccountForProfile(x.account_level_0_description)
                  //setAccountCurrentValue(addCommas(x.ending_value))
                  }} icon={notifications} className={x.kpi == 0?"text-grey size-24":x.kpi == 1? "text-green size-24":x.kpi == -1?"text-red size-24":"text-grey size-24"}></IonIcon></span>
                <span>&nbsp;<IonIcon onClick={ev=>{
                //props.global_props.account_level_0 = x.account_level_0_key;
                //props.global_props.account_level_1 = x.account_level_1_key;
                //row_id = i; 
                //date_desc = x.datekey
                //props.global_props.account_level_0_desc = x.DESC;
                //props.global_props.account_level_1_desc = x.account_level_1
                //setHighlighted()
                }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-24':'text-grey size-24'}></IonIcon></span>
                <span onClick={()=>{
                //props.migration_id = 0;
                //showSettingStage(5); 
                //props.analytical_report_id = 2;
                //setSummaryDashBoardBalancesheet(true);
                //props.account_level_3 = x.account_level_3_key
                //props.account_level_2 = x.account_level_2_key
                //props.global_props.account_level_1 = x.account_level_1_key
                //props.global_props.account_level_0 = x.account_level_0_key
                //props.start_date          = x.sdate
                //props.end_date            = x.edate
                props.callSummaryAssets(
                  x.account_level_3_key,
                  x.account_level_2_key,
                  x.account_level_1_key,
                  x.account_level_0_key,
                  x.sdate,
                  x.edate,
                  0,
                  2,
                  x.account_level_0_description
                )
              }}> {x.account_level_0_description}</span></IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.opening_value)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.ending_value)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.movement)}{(x.movement > 0)? <IonIcon icon={caretUp} color="success"></IonIcon>:x.movement < 0? <IonIcon icon={caretDown} color="danger"></IonIcon> : <div></div>}</IonCol>
              <IonCol size="2" className="ion-text-right">{x.movement_percent}</IonCol>
            </IonRow>
          )
        }
      })
      var non_current_liabilities_start:any = options[0].map((x:any, i:any)=>{
        if(x.account_level_2_key == 5   && (x.opening_value != 0 && x.closing_value != 0)){
          non_current_liabilities_start_total += x.opening_value/1;
          non_current_liabilities_end_total += x.ending_value/1;
          non_current_liabilities_movement_total += x.movement/1;
          return(
            <IonRow key={i}   className={"summary-detail-row"}>
              <IonCol className="summary-account" size="4">
                <span><IonIcon onClick={()=>{
                  //showActionPreDefined(false);showActionDefined(false);
                  //showKPIModal(true);
                  //setAccountLevel3kpi(x.account_level_3_key);
                  //setAccountLevel2kpi(x.account_level_2_key);
                  //setAccountLevel1kpi(x.account_level_1_key);
                  //setAccountLevel0kpi(x.account_level_0_key);
                  //setAccountForProfile(x.account_level_0_description)
                  //setAccountCurrentValue(addCommas(x.ending_value))
                  }} icon={notifications} className={x.kpi == 0?"text-grey size-24":x.kpi == 1? "text-green size-24":x.kpi == -1?"text-red size-24":"text-grey size-24"}></IonIcon></span>
                <span>&nbsp;<IonIcon onClick={ev=>{
                //account_level_0 = x.account_level_0_key;
                //account_level_1 = x.account_level_1_key;
                //row_id = i; 
                //date_desc = x.datekey
                //account_level_0_desc = x.DESC;
                //account_level_1_desc = x.account_level_1
                //setHighlighted()
                }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-24':'text-grey size-24'}></IonIcon></span>
              <span onClick={()=>{
                props.migration_id = 0;
                //</IonCol>showSettingStage(5); 
                //</IonCol>analytical_report_id = 2;
                //</IonCol>setSummaryDashBoardBalancesheet(true);
                //</IonCol>account_level_3 = x.account_level_3_key
                //</IonCol>account_level_2 = x.account_level_2_key
                //</IonCol>account_level_1 = x.account_level_1_key
                //</IonCol>account_level_0 = x.account_level_0_key
                //</IonCol>start_date          = x.sdate
                //</IonCol>end_date            = x.edate
                props.callSummaryAssets(
                  x.account_level_3_key,
                  x.account_level_2_key,
                  x.account_level_1_key,
                  x.account_level_0_key,
                  x.sdate,
                  x.edate,
                  0,
                  2,
                  x.account_level_0_description
                )
              }}> {x.account_level_0_description}</span>
              </IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.opening_value)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.ending_value)}</IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.movement)}{(x.movement > 0)? <IonIcon icon={caretUp} color="success"></IonIcon>:x.movement < 0? <IonIcon icon={caretDown} color="danger"></IonIcon> : <div></div>}</IonCol>
              <IonCol size="2" className="ion-text-right">{x.movement_percent}</IonCol>
            </IonRow>
          )
        }
      })
      var income_start:any = options[0].map((x:any, i:any)=>{

        if(x.account_level_3_key == 4  && (x.opening_value != 0 && x.closing_value != 0)){
          income_start_total += x.opening_value/1;
          income_end_total += x.ending_value/1;
          income_movement_total += x.ie_movement/1;
          //props.global_props.account_level_0 = 0
          //props.global_props.account_level_1 = x.account_level_1_key
          //props.account_level_2 = x.account_level_2_key
          //props.account_level_3 = x.account_level_3_key
          return(
            <IonRow key={i} className={"summary-detail-row"}>
              <IonCol className="summary-account" size="4">
                <span><IonIcon onClick={()=>{
                  //showActionPreDefined(false);showActionDefined(false);
                  //showKPIModal(true);
                  //setAccountLevel3kpi(x.account_level_3_key);
                  //setAccountLevel2kpi(x.account_level_2_key);
                  //setAccountLevel1kpi(x.account_level_1_key);
                  //setAccountLevel0kpi(x.account_level_0_key);
                  //setAccountForProfile(x.account_level_0_description)
                  //setAccountCurrentValue(addCommas(x.ending_value))
                  
                  }} icon={notifications} className={x.kpi == 0?"text-grey size-24":x.kpi == 1? "text-green size-24":x.kpi == -1?"text-red size-24":"text-grey size-24"}></IonIcon></span>
                <span>&nbsp;<IonIcon onClick={ev=>{
                //props.global_props.account_level_0 = x.account_level_0_key;
                //props.global_props.account_level_1 = x.account_level_1_key;
                //row_id = i; 
                //date_desc = x.datekey
                //props.global_props.account_level_0_desc = x.DESC;
                //props.global_props.account_level_1_desc = x.account_level_1
                //setHighlighted()
                }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-24':'text-grey size-24'}></IonIcon></span>
                <span onClick={()=>{
                //props.migration_id = 0;
                //showSettingStage(5); 
                //props.analytical_report_id = 8;
                //setSummaryDashBoardIncomeDetail(true);
                //props.account_level_3 = x.account_level_3_key
                //props.account_level_2 = x.account_level_2_key
                //props.global_props.account_level_1 = x.account_level_1_key
                //props.global_props.account_level_0 = x.account_level_0_key
                //props.start_date          = x.sdate
                //props.end_date            = x.edate
                props.callSummaryAssets(
                  x.account_level_3_key,
                  x.account_level_2_key,
                  x.account_level_1_key,
                  x.account_level_0_key,
                  x.sdate,
                  x.edate,
                  0,
                  8,
                  x.account_level_0_description
                )   
                }}> {x.account_level_0_description}</span>
              </IonCol>
              <IonCol size="2" className="ion-text-right"></IonCol>
              <IonCol size="2" className="ion-text-right"></IonCol>
              <IonCol size="2" className="ion-text-right">{addCommas(x.ie_movement)}{(x.ie_movement > 0)? <IonIcon icon={caretUp} color="success"></IonIcon>:x.ie_movement < 0? <IonIcon icon={caretDown} color="danger"></IonIcon> : <div></div>}</IonCol>
              <IonCol size="2" className="ion-text-right">{x.movement_percent}</IonCol>
            </IonRow>
          )
        }
      })
        var expense_start:any = options[0].map((x:any, i:any)=>{
          if(x.account_level_2_key == 7 && (x.opening_value != 0 && x.closing_value != 0)){
            expense_start_total += x.opening_value/1;
            expense_end_total += x.ending_value/1;
            expense_movement_total += x.ie_movement/1;
            //props.global_props.account_level_0 = 0
            //props.global_props.account_level_1 = x.account_level_1_key
            //props.account_level_2 = x.account_level_2_key
            //props.account_level_3 = x.account_level_3_key
            return(
              <IonRow key={i}  className={"summary-detail-row"}>
                <IonCol className="summary-account" size="4">
                  <span><IonIcon onClick={()=>{
                  //props.migration_id = 0;
                  //showActionPreDefined(false);showActionDefined(false);
                  //showKPIModal(true);
                  //setAccountLevel3kpi(x.account_level_3_key);
                  //setAccountLevel2kpi(x.account_level_2_key);
                  //setAccountLevel1kpi(x.account_level_1_key);
                  //setAccountLevel0kpi(x.account_level_0_key);
                  //setAccountForProfile(x.account_level_0_description)
                  //setAccountCurrentValue(addCommas(x.ending_value))
                  }} icon={notifications} className={x.kpi == 0?"text-grey size-24":x.kpi == 1? "text-green size-24":x.kpi == -1?"text-red size-24":"text-grey size-24"}></IonIcon></span>
                  <span>&nbsp;<IonIcon onClick={ev=>{
                //props.global_props.account_level_0 = x.account_level_0_key;
                //props.global_props.account_level_1 = x.account_level_1_key;
                //row_id = i; 
                //date_desc = x.datekey
                //props.global_props.account_level_0_desc = x.DESC;
                //props.global_props.account_level_1_desc = x.account_level_1
                //setHighlighted()
                }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-24':'text-grey size-24'}></IonIcon></span>
                <span onClick={()=>{
                  //showSettingStage(5); 
                  //props.analytical_report_id = 8;
                  //setSummaryDashBoardIncomeDetail(true);
                  props.callSummaryAssets(
                    x.account_level_3_key,
                    x.account_level_2_key,
                    x.account_level_1_key,
                    x.account_level_0_key,
                    x.sdate,
                    x.edate,
                    0,
                    8,
                    x.account_level_0_description
                  ) 
                  }}> {x.account_level_0_description}</span>
                </IonCol>
                <IonCol size="2" className="ion-text-right">{}</IonCol>
                <IonCol size="2" className="ion-text-right">{}</IonCol>
                <IonCol size="2" className="ion-text-right">{addCommas(x.ie_movement)}{(x.movement > 0)? <IonIcon icon={caretUp} color="success"></IonIcon>:x.ie_movement < 0? <IonIcon icon={caretDown} color="danger"></IonIcon> : <div></div>}</IonCol>
                <IonCol size="2" className="ion-text-right">{x.movement_percent}</IonCol>
              </IonRow>
            )
          }
      })
    }catch(error:any){
      console.log(error)
    }
    setIncomeStart(income_start)
    setExpensesStart(expense_start)
  
    setCurrentAssetsStart(current_assets_start)
    setNonCurrentAssetsStart(non_current_assets_start)
    setCurrentLiabilitiesStart(current_liabilities_start)
    setNonCurrentLiabilitiesStart(non_current_liabilities_start)
    equity_gains = [gains,equity_start];
    setEquityStart(equity_gains)
    setCurrentAssetsStartTotal(addCommas(current_assets_start_total.toFixed(2)))            
    setCurrentAssetsEndTotal(addCommas(current_assets_end_total.toFixed(2)))             
    setCurrentAssetsMovementTotal(addCommas(current_assets_movement_total.toFixed(2)))      
    
    setIncomeStartTotal(addCommas(income_start_total.toFixed(2)))            
    setIncomeEndTotal(addCommas(income_end_total.toFixed(2)))             
    setIncomeMovementTotal(addCommas(income_movement_total.toFixed(2)))  
    
    setExpensesStartTotal(addCommas(expense_start_total.toFixed(2)))            
    setExpensesEndTotal(addCommas(expense_end_total.toFixed(2)))             
    setExpensesMovementTotal(addCommas(expense_movement_total.toFixed(2)))  

    setEquityStartTotal(addCommas(equity_start_total.toFixed(2)))        
    setEquityEndTotal(addCommas(equity_end_total.toFixed(2)))          
    setEquityMovementTotal(addCommas(equity_movement_total.toFixed(2)))

    setGains(addCommas((income_movement_total /1 - expense_movement_total/1).toFixed(2)))


    setNonCurrentAssetsStartTotal(addCommas(non_current_assets_start_total.toFixed(2)))        
    setNonCurrentAssetsEndTotal(addCommas(non_current_assets_end_total.toFixed(2)))          
    setNonCurrentAssetsMovementTotal(addCommas(non_current_assets_movement_total.toFixed(2)))     
    setCurrentLiabilitiesStartTotal(addCommas(current_liabilities_start_total.toFixed(2)))       
    setCurrentLiabilitiesEndTotal(addCommas(current_liabilities_end_total.toFixed(2)))         
    setCurrentLiabilitiesMovementTotal(addCommas(current_liabilities_movement_total.toFixed(2)))    
    setNonCurrentLiabilitiesStartTotal(addCommas(non_current_liabilities_start_total.toFixed(2)))   
    setNonCurrentLiabilitiesEndTotal(addCommas(non_current_liabilities_end_total.toFixed(2)))     
    setNonCurrentLiabilitiesMovementTotal(addCommas(non_current_liabilities_movement_total.toFixed(2)))

    setEquityStartTotal(addCommas(equity_start_total.toFixed(2)))        
    setEquityEndTotal(addCommas(equity_end_total.toFixed(2)))          
    setEquityMovementTotal(addCommas(equity_movement_total.toFixed(2)))
    
    setLiabilitiesStartTotal(addCommas((current_liabilities_start_total/1 + non_current_liabilities_start_total/1).toFixed(2)))
    setLiabilitiesEndTotal(addCommas((current_liabilities_end_total/1 + non_current_liabilities_end_total/1).toFixed(2)))
    setLiabilitiesMovementTotal(addCommas((current_liabilities_movement_total + non_current_liabilities_movement_total).toFixed(2)))

    setCurrentLiabilitiesStartTotal(addCommas(current_liabilities_start_total.toFixed(2)))       
    setCurrentLiabilitiesEndTotal(addCommas(current_liabilities_end_total.toFixed(2)))         
    setCurrentLiabilitiesMovementTotal(addCommas(current_liabilities_movement_total.toFixed(2)))    
    
    setNonCurrentLiabilitiesStartTotal(addCommas(non_current_liabilities_start_total.toFixed(2)))   
    setNonCurrentLiabilitiesEndTotal(addCommas(non_current_liabilities_end_total.toFixed(2)))     
    setNonCurrentLiabilitiesMovementTotal(addCommas(non_current_liabilities_movement_total.toFixed(2)))

    setAssetsStartTotal(addCommas((current_assets_start_total/1 + non_current_assets_start_total/1 ).toFixed(2)))
    setAssetsEndTotal(addCommas((current_assets_end_total/1 + non_current_assets_end_total/1 ).toFixed(2)))
    setAssetsMovementTotal(addCommas((current_assets_movement_total/1 + non_current_assets_movement_total/1 ).toFixed(2)))

    setStartTotal(addCommas(((current_assets_start_total/1 + non_current_assets_start_total/1) - (equity_start_total/1 + current_liabilities_start_total/1 + non_current_liabilities_start_total/1)).toFixed(2)))
    setEndTotal(addCommas(((current_assets_end_total/1 + non_current_assets_end_total/1) - (equity_end_total/1 + current_liabilities_end_total/1 + non_current_liabilities_end_total/1)).toFixed(2)))
    setMovementTotal(addCommas(((current_assets_movement_total/1 + non_current_assets_movement_total/1) - (equity_movement_total/1 + current_liabilities_movement_total + non_current_liabilities_movement_total)).toFixed(2)))

    }

    useEffect(() => {
        //console.log(balance_sheet)
        //setCurrentAssets("current assets array:\n"+props)
        setState(()=>{
            if(props.data != prevState ){
                prevState = props.data
                console.log(props.data)
                setBalanceSheet(props.props.global_props,props.data)
            }}
        )
        
    }, [props.data]); 
    return(
    <div>
      <div className="size-20 freeze-header"><b>Balance Sheet Current</b></div>
      <div className="main-content-bs">
      <IonRow className="summary-account-level-1 ">
        <IonCol className="ion-text-left ">Current Assets</IonCol>
      </IonRow> 
      <IonRow className="summary-row-header">
        <IonCol size="4">
          <IonIcon onClick={()=>{showCurrentAssets(!hideCurrentAssets)}} icon={chevronForward} className={hideCurrentAssets? "size-14 rotate-icon-forward" : "size-14 rotate-icon-downward" }></IonIcon>
          <span>&nbsp;Account</span></IonCol>
        <IonCol className="ion-text-right" size="2">Opening ({getPrevEndDate})</IonCol>
        <IonCol className="ion-text-right" size="2">Closing ({getEndDate})</IonCol>
        <IonCol className="ion-text-right" size="2">Movement</IonCol>
        <IonCol className="ion-text-right" size="2">Movement %</IonCol>
      </IonRow>
      {!hideCurrentAssets &&
      <div>
        {getCurrentAssetsStart}
      </div>
      }
      <IonRow className="summary-row-total">
        <IonCol size="4"
          //onClick={()=>{
          //showSettingStage(5); 
          //analytical_report_id = 8;
          //setSummaryDashBoardBalancesheet(true);
          //account_level_3 = 1
          //account_level_2 = 1
          //account_level_1 = 1
          //account_level_0 = 0
          //callSummaryAssets(
          //  1,
          //  1,
          //  1,
          //  0,
          //  start_date,
          //  end_date
          //) }}
        >Total Current Assets</IonCol>
        <IonCol className="ion-text-right" size="2">{getCurrentAssetsStartTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getCurrentAssetsEndTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getCurrentAssetsMovementTotal}</IonCol>
        <IonCol className="ion-text-right" size="2"></IonCol>
      </IonRow>
      <IonRow className="summary-account-level-1">
        <IonCol>Non Current Assets</IonCol>
      </IonRow>
      <IonRow className="summary-row-header">
        <IonCol size="4">
          <IonIcon onClick={()=>{showNonCurrentAssets(!hideNonCurrentAssets)}} icon={chevronForward} className={hideNonCurrentAssets? "size-14 rotate-icon-forward" : "size-14 rotate-icon-downward" }></IonIcon>
          
          <span>&nbsp;Account</span>
        </IonCol>
        <IonCol className="ion-text-right" size="2">Opening ({getPrevEndDate})</IonCol>
        <IonCol className="ion-text-right" size="2">Closing ({getEndDate})</IonCol>
        <IonCol className="ion-text-right" size="2">Movement</IonCol>
        <IonCol className="ion-text-right" size="2">Movement %</IonCol>
      </IonRow>
      {!hideNonCurrentAssets&& 
      <div>
        {getNonCurrentAssetsStart}
      </div>
      } 
      <IonRow className="summary-row-total">
        <IonCol size="4"
          //onClick={()=>{
          //showSettingStage(5); 
          //analytical_report_id = 8;
          //setSummaryDashBoardBalancesheet(true);
          //account_level_3 = 1
          //account_level_2 = 2
          //account_level_1 = 8
          //account_level_0 = 0
          //callSummaryAssets(
          //  1,
          //  2,
          //  8,
          //  0,
          //  start_date,
          //  end_date
          //) }}
        
        >Total Non Current Assets</IonCol>
        <IonCol className="ion-text-right" size="2">{getNonCurrentAssetsStartTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getNonCurrentAssetsEndTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getNonCurrentAssetsMovementTotal}</IonCol>
        <IonCol className="ion-text-right" size="2"></IonCol>
      </IonRow>
      <IonRow className="summary-row-total">
        <IonCol size="4" 
          //onClick={()=>{
          //showSettingStage(5); 
          //analytical_report_id = 8;
          //setSummaryDashBoardBalancesheet(true);
          //account_level_3 = 1
          //account_level_2 = 0
          //account_level_1 = 0
          //account_level_0 = 0
          //callSummaryAssets(
          //  2,
          //  3,
          //  0,
          //  0,
          //  start_date,
          //  end_date
          //) }}
        >Total Assets</IonCol>
        <IonCol className="ion-text-right" size="2">{getAssetsStartTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getAssetsEndTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getAssetsMovementTotal}</IonCol>
        <IonCol className="ion-text-right" size="2"></IonCol>
      </IonRow>
      <IonRow className="summary-account-level-1">
        <IonCol>Owner Equity</IonCol>
      </IonRow>
      <IonRow className="summary-row-header">
        <IonCol size="4">
          <IonIcon onClick={()=>{showOwnersEquity(!hideOwnersEquity)}} icon={chevronForward} className={hideOwnersEquity? "size-14 rotate-icon-forward" : "size-14 rotate-icon-downward" }></IonIcon>
          
          <span>&nbsp;Account</span>
        </IonCol>
        <IonCol className="ion-text-right" size="2">Opening ({getPrevEndDate})</IonCol>
        <IonCol className="ion-text-right" size="2">Closing ({getEndDate})</IonCol>
        <IonCol className="ion-text-right" size="2">Movement</IonCol>
        <IonCol className="ion-text-right" size="2">Movement %</IonCol>
      </IonRow>
      {!hideOwnersEquity &&
      <div>    
        {getEquityStart}
      </div>
      }
      <IonRow className="summary-row-total">
        <IonCol size="4"
        //onClick={()=>{
        //showSettingStage(5); 
        //analytical_report_id = 8;
        //setSummaryDashBoardBalancesheet(true);
        //account_level_3 = 2
        //account_level_2 = 3
        //account_level_1 = 0
        //account_level_0 = 0
        //callSummaryAssets(
        //  2,
        //  3,
        //  0,
        //  0,
        //  start_date,
        //  end_date
        //) }}
        >Total Owner Equity</IonCol>
        <IonCol className="ion-text-right" size="2">{getEquityStartTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getEquityEndTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getEquityMovementTotal}</IonCol>
        <IonCol className="ion-text-right" size="2"></IonCol>
      </IonRow>
      
      <IonRow className="summary-account-level-1">
        <IonCol>Current Liabilities</IonCol>
      </IonRow>
      <IonRow className="summary-row-header">
        <IonCol size="4">
          <IonIcon onClick={()=>{showCurrentLiabilities(!hideCurrentLiabilities)}} icon={chevronForward} className={hideCurrentLiabilities? "size-14 rotate-icon-forward" : "size-14 rotate-icon-downward" }></IonIcon>
          
          <span>&nbsp;Account</span>
        </IonCol>
        <IonCol className="ion-text-right" size="2">Opening ({getPrevEndDate})</IonCol>
        <IonCol className="ion-text-right" size="2">Closing ({getEndDate})</IonCol>
        <IonCol className="ion-text-right" size="2">Movement</IonCol>
        <IonCol className="ion-text-right" size="2">Movement %</IonCol>
      </IonRow>
      {!hideCurrentLiabilities &&
      <div>     
        {getCurrentLiabilitiesStart}
      </div>
      }
      <IonRow className="summary-row-total">
        <IonCol size="4"
        //onClick={()=>{
        //showSettingStage(5); 
        //analytical_report_id = 8;
        //setSummaryDashBoardBalancesheet(true);
        //account_level_3 = 3
        //account_level_2 = 4
        //account_level_1 = 5
        //account_level_0 = 0
        //callSummaryAssets(
        //  3,
        //  4,
        //  5,
        //  0,
        //  start_date,
        //  end_date
        //) }}
        >Total Current Liabilities</IonCol>
        <IonCol className="ion-text-right" size="2">{getCurrentLiabilitiesStartTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getCurrentLiabilitiesEndTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getCurrentLiabilitiesMovementTotal}</IonCol>
        <IonCol className="ion-text-right" size="2"></IonCol>
      </IonRow>
      <IonRow className="summary-account-level-1">
        <IonCol>Non Current Liabilities</IonCol>
      </IonRow>
      <IonRow className="summary-row-header">
        <IonCol size="4">
          <IonIcon onClick={()=>{showNonCurrentLiabilities(!hideNonCurrentLiabilities)}} icon={chevronForward} className={hideNonCurrentLiabilities? "size-14 rotate-icon-forward" : "size-14 rotate-icon-downward" }></IonIcon>
          
          <span>&nbsp;Account</span>
        </IonCol>
        <IonCol className="ion-text-right" size="2">Opening ({getPrevEndDate})</IonCol>
        <IonCol className="ion-text-right" size="2">Closing ({getEndDate})</IonCol>
        <IonCol className="ion-text-right" size="2">Movement</IonCol>
        <IonCol className="ion-text-right" size="2">Movement %</IonCol>
      </IonRow>
      {!hideNonCurrentLiabilities &&
      <div>    
        {getNonCurrentLiabilitiesStart}
      </div>
      }
      <IonRow className="summary-row-total ">
        <IonCol size="4"
        //onClick={()=>{
        //showSettingStage(5); 
        //analytical_report_id = 8;
        //setSummaryDashBoardBalancesheet(true);
        //account_level_3 = 3
        //account_level_2 = 5
        //account_level_1 = 6
        //account_level_0 = 0
        //callSummaryAssets(
        //  3,
        //  5,
        //  6,
        //  0,
        //  start_date,
        //  end_date
        //) }}
        >Total Non Current Liabilities</IonCol>
        <IonCol className="ion-text-right" size="2">{getNonCurrentLiabilitiesStartTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getNonCurrentLiabilitiesEndTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getNonCurrentLiabilitiesMovementTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">&nbsp;</IonCol>
      </IonRow>
      <IonRow className="summary-row-total ">
        <IonCol size="4"
        //onClick={()=>{
        //showSettingStage(5); 
        //analytical_report_id = 8;
        //setSummaryDashBoardBalancesheet(true);
        //account_level_3 = 3
        //account_level_2 = 0
        //account_level_1 = 0
        //account_level_0 = 0
        //callSummaryAssets(
        //  3,
        //  0,
        //  0,
        //  0,
        //  start_date,
        //  end_date
        //) }}
        >Total Liabilities</IonCol>
        <IonCol className="ion-text-right" size="2">{getLiabilitiesStartTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getLiabilitiesEndTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getLiabilitiesMovementTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">&nbsp;</IonCol>
      </IonRow>
      <IonRow className="summary-row-total ">
        <IonCol size="4"
        //onClick={()=>{
        //showSettingStage(5); 
        //analytical_report_id = 8;
        //setSummaryDashBoardBalancesheet(true);
        //account_level_3 = 0
        //account_level_2 = 0
        //account_level_1 = 0
        //account_level_0 = 0
        //callSummaryAssets(
        //  0,
        //  0,
        //  0,
        //  0,
        //  start_date,
        //  end_date
        //) }}
        >Total</IonCol>
        <IonCol className="ion-text-right" size="2">{getStartTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getEndTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">{getMovementTotal}</IonCol>
        <IonCol className="ion-text-right" size="2">&nbsp;</IonCol>
      </IonRow>
    </div>
    </div>
    )
}
export default BalanceSheet