import { IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonCheckbox, IonItem } from '@ionic/react';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, options, filter} from 'ionicons/icons';
import React, { useState,useEffect, useRef } from 'react';
import $ from "jquery"
import { addCommas, isNumeric } from '../../functions/functions';
import './Basic.css'

var character_pattern:any = "";
var is_anchor:any = 0;
var table_object_id:any = 0;
var process:any = 1;
var step:any = 1;

const getPattern = (word:any) =>{
    var array:any = []
    array = word.split("")
    var pattern:any = ""
    for(var i = 0; i < array.length; i++){
        if (i == 0){
            pattern = getPatternCharacter(array[i])
        }
        if (i > 0){
            pattern = pattern +','+getPatternCharacter(array[i])
        }

        
    }
    console.log(pattern)
    return pattern
}
const getPatternCharacter = (letter:any)=>{
    if(isNumeric(letter) && letter != ' ' && letter != ''){
        return "n"
    }else{
        if(letter.length == 0 || letter == ' '){
            return "s"
        }else{
            return "x"
        }
    }
}
const BasicML = (props:any) =>{
    //table_object_id = props.table_object_id;
    $(".ml-cell").removeClass("background-gt")
    $(".ml-cell").removeClass("text-white")
    console.log(props)
    console.log(props.data)
    var options:any = []
    options.push(props.data[0])

    const [getData,setData]                                 = useState<any>()
    const [getStep, setStep]                                = useState<any>()
    const [getColumnDefinitionId, setColumnDefinitionId]    = useState<any>()
    const [getVersionId, setVersionId]                      = useState<any>()
    const [getDefinitionId, setDefinitionId]                = useState<any>()
    const [getStepNumber, setStepNumber]                    = useState<any>()
    const getLearningProcess1 = (n:any)=>{
        switch(n){
            case 1:
                step = n;
                setColumnDefinitionId(24)
                setStepNumber(2);
                props.learning_process()
                props.bot_step(24)
            ;break;//Select First Account Number in document
            case 2:
                step = n;
                setColumnDefinitionId(22)
                setStepNumber(3)
                props.learning_process()
                props.bot_step(22)
            ;break;//Select first Account Description in document
            case 3:
                step = n;
                setColumnDefinitionId(25)
                props.learning_process()
                //setStepNumber(4)
            ;break;//Select first Debit value in document
            case 4:
                step = n;
                setStepNumber(5);
                setColumnDefinitionId(8)
                props.learning_process()
            ;break;//Select first Debit value in document
            case 5:
                step = n;
                setStepNumber(7)
                setColumnDefinitionId(8)
                props.learning_process()
            ;break;//Select first Credit value in document 
            case 6:
                step = n;
                setStepNumber(7)
                setColumnDefinitionId(15)
                props.learning_process()
            ;break;//Select first Balance value in document 
            case 7:
                step = n;
                setStepNumber(8)
                setColumnDefinitionId(26)
                props.learning_process()
            ;break;//Select first BS entry in document
            case 8:
                step = n;
                setColumnDefinitionId(27)
                props.learning_process()
            ;break;//Select first BS entry in document
        }
        //step++;
    }

    const setPatternDefinition = (x:any, y:any, word:any) =>{
        //console.log("") 
        var data:any = [];
        console.log(word)
        character_pattern = getPattern(word)
        console.log(character_pattern)
        $(".ml-cell").removeClass("background-gt")
        $(".ml-cell").removeClass("text-white")
        
        fetch(props.state.props.primary_host+'/dbconnect/dbdynamic_ml.jsp?dbo=insert_pattern_definitions'+
            "&version_id="+getVersionId+
            "&directory_id="+getDefinitionId+
            "&column_definition_id="+getColumnDefinitionId+
            "&is_anchor="+is_anchor+
            "&character_pattern="+character_pattern+
            "&character_vector_x="+x+
            "&character_vector_y="+y+
            "&created_by="+props.state.props.user_id
            ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(d =>{
            data.push(d.data); 
            options = []
            options.push(data[0])
            var column:any = []
            $(".ml-cell").removeClass("background-gt")
            $(".ml-cell").removeClass("text-white")
            for(x in options[0][0]){
                console.log(x)
                column.push(x)
            }
            options[0].map((data:any , i:number)=>{
                var row = data
                column.map((col:any, z:number)=>{
                    console.log(row.id)
                    var y:any = col.split("F")
                    if(y[1] != undefined ){
                        console.log(y[1])
                        var obj:any = $(".row-"+row.id +".col-"+y[1])
                        console.log(obj)
                        $(obj).addClass("background-gt text-white")
                    }
                })
                
            })
            //drawTable()
            props.next_step(getStep)
        })
        .catch(error=>{
          alert(error)
        })
    }

    
        // get table column
        const column:any = []
        let x:any = "";

        console.log(options[0])

        for(x in options[0][0]){
            console.log(x)
            if( 
                x != "id" && 
                x != "row" &&
                x != "versionid" &&
                x != "reporttypeid"
            )
            column.push(x)
        }

        // get table heading data
        
        console.log(column)
        const ThData:any =()=>{
            return column.map((header:any, i:number)=>{
                
                    return <th  className="ion-text-center ion-padding" key={i}><b>{header}</b></th>
            })
        }
        // get table row data
        const tdData:any =() =>{
            return options[0].map((data:any, i:number)=>{
                console.log(data) 
                return(
                    <tr key={i}>
                        {
                            column.map((v:any, z:number)=>{
                                console.log(v)
                                if(true){
                                    if(!isNumeric(data[v]) || v == "Account Number")
                                        return <td onClick={(e:any)=>{setPatternDefinition((z/1+1),(i/1+1), data[v])}}  key={z} className={"ion-text-left ion-padding ml-cell row-"+data["id"]+" col-"+(z+1)}>{data[v]}</td>
                                    if(isNumeric(data[v]) && v != "Account Number")
                                        return <td onClick={(e:any)=>{setPatternDefinition((z/1+1),(i/1+1),data[v])}}  key={z} className={"ion-text-right ion-padding ml-cell row-"+data["id"]+" col-"+(z+1)}>{addCommas((data[v]/1).toFixed(2))}</td>
                                }
                            })
                        }
                    </tr>
                )
            })
        }
    
    React.useEffect(() => {
        //alert(props.process)
        //alert(props.step)
        //if (getStep != props.step && props.step != undefined){
            //alert(100)
            setStep(props.step)
            //setColumnDefinitionId(props.column_definition_id)
            setVersionId(props.version_id)
            setDefinitionId(props.process)
            //drawTable()
            getLearningProcess1(props.process)
            alert(props.step)
            alert(props.process)
            //alert(props.definition_id)
        //}
    }, []);
    return (
        <IonRow className="table size-12 ">
            <table >
                <tr>
                    {ThData()}
                </tr>
                {tdData()}
            </table>
        </IonRow>
    )
}
export default BasicML