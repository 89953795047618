import { IonCol, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import { useState,useEffect } from 'react';
import { addCommas } from '../../dbconnections/Select';
const SummaryDetailTableCustom = (props:any) =>{
    const [getState, setState]                                  = useState<any>()
    const [getTable1, setTable1]                                = useState<any>()
    const [getTable2, setTable2]                                = useState<any>()
    const [getAccountForProfile, setAccountForProfile]          = useState<string>()
    const setTable = (data:any)=>{
    let d = []

    d.push(data[0])
    console.log(d)    
    //console.log(d[0][0])
    var debit_total     :any    = 0;
    var credit_total    :any    = 0;
    var opening_total   :any    = 0;
    var closing_total   :any    = 0;
    var movement_total  :any    = 0;
    var ie_movement_total  :any = 0;
    var bs:boolean              = false
    var is:boolean              = false
    setAccountForProfile(d[0].DESC)

    var table1:any = d[0].map((x:any, i:number)=>{
        debit_total       +=x.DEBIT/1;
        credit_total      +=x.CREDIT/1;
        opening_total     =x["OPENING BALANCE"]/1;
        closing_total     =x["CLOSING BALANCE"]/1 ;
        movement_total    +=x.MOVEMENT/1;
        ie_movement_total    +=(x.DEBIT/1 - x.CREDIT/1);
        if (x.account_level_3_key == 1 || x.account_level_3_key == 2 || x.account_level_3_key == 3 ){
            bs = true
            return(
            <IonRow onClick={()=>{
                            var z:any=[]
                            z.push({datekey:x.datekey, code:x.code })
                            props.callDetailTransactions(z[0])
                        }
                    } key={i} className={x.highlighted == 1? "highlighted-row summary-account":"summary-account summary-detail-row row-id-"+i}
            >
                <IonCol size="2" className="ion-text-left">
                    <span><IonIcon onClick={ev=>{
                        //account_level_0 = x.account_level_0_key;
                        //account_level_1 = x.account_level_1_key;
                        //row_id = i; 
                        //date_desc = x.datekey
                        //account_level_0_desc = x.DESC;
                        //account_level_1_desc = x.account_level_1
                        //setHighlighted()
                        }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-16':'text-grey size-16'}></IonIcon>
                    </span>&nbsp;
                    <span>
                    {x.datekey}
                    </span>
                </IonCol>
                <IonCol size="5" className="ion-text-left summary-account">
                    {x.DESC}
                </IonCol>
                <IonCol size="1" className="ion-text-right">{addCommas((x.DEBIT/1).toFixed(2))}</IonCol>
                <IonCol size="1" className="ion-text-right">{addCommas((x.CREDIT/1).toFixed(2))}</IonCol>
                <IonCol size="1" className="ion-text-right">{addCommas((x["OPENING BALANCE"]/1).toFixed(2))}</IonCol>
                <IonCol size="1" className="ion-text-right">{addCommas((x["CLOSING BALANCE"]/1).toFixed(2))}</IonCol>
                <IonCol size="1" className="ion-text-right">{addCommas((x["CLOSING BALANCE"]/1-x["OPENING BALANCE"]/1).toFixed(2))}</IonCol>
            </IonRow>
            )
        }
        })
        var table2:any = d[0].map((x:any, i:number)=>{  
            if (x.account_level_3_key == 4 || x.account_level_3_key == 5 ){
            is =true
            return(
            <IonRow onClick={()=>{
                        var z:any=[]
                        z.push({datekey:x.datekey, code:x.code })
                        props.callDetailTransactions(z)
                    }
                } key={i} className={x.highlighted == 1? "highlighted-row summary-account":"summary-account summary-detail-row row-id-"+i}
            >
                <IonCol size="2" className="ion-text-left">
                <span><IonIcon onClick={ev=>{
                    //account_level_0 = x.account_level_0_key;
                    //account_level_1 = x.account_level_1_key;
                    //row_id = i; 
                    //date_desc = x.datekey
                    //account_level_0_desc = x.DESC;
                    //account_level_1_desc = x.account_level_1
                    //setHighlighted()
                    }} icon={arrowForwardCircleOutline} className={x.highlighted == 1? 'text-red size-16':'text-grey size-16'}></IonIcon>
                </span>&nbsp;
                <span>
                    {x.datekey}
                </span>
                </IonCol>
                {/*<IonCol size="5" className="ion-text-left summary-account"
                
                ><IonItem>{x.DESC}</IonItem></IonCol>
                <IonCol size="1" className="ion-text-right">{addCommas((x.DEBIT/1).toFixed(2))}</IonCol>
                <IonCol size="1" className="ion-text-right">{addCommas((x.CREDIT/1).toFixed(2))}</IonCol>
                <IonCol size="1" className="ion-text-right">{}</IonCol>
                <IonCol size="1" className="ion-text-right">{}</IonCol>*/}
                <IonCol size="10" className="ion-text-right">{addCommas((x.DEBIT/1-x.CREDIT/1))}</IonCol>
            </IonRow>
            )}
            
        })
        setTable1(table1)
        setTable2(table2)
        console.log(table1)
        console.log(table2)
    }
    useEffect(() => {
        setState(()=>{
            //alert()
            if(props.data != getState){ 
                setState(props.data)
                setTable(props.data)
            }}
        ) 
    }, [props.data]);
    return (
        <div className="main-content-bs">
            {getTable1}
            {getTable2}
        </div>
    )    
}

export default SummaryDetailTableCustom