import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRadio, IonRadioGroup, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonModal } from '@ionic/react';
import { addCircleOutline, analytics, analyticsOutline, arrowForwardCircleOutline, caretDown, caretUp, checkmarkCircleOutline, chevronForward, cogOutline, listOutline, notifications, removeCircleOutline, returnUpBack, settingsOutline, trashBinOutline } from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import { addCommas, callAnalyticalReviews, callSummaryDetail, setDateFormatHighCharts } from '../../dbconnections/Select';
import { isNumeric } from '../../functions/functions';
import ProjectsSide from '../SideBar/SubComponents/ProjectsSide';
import "./Budget.css"
import $ from 'jquery'
import Highcharts, { isNumber } from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import { OverlayEventDetail } from '@ionic/core/components';
import BudgetEntryFunctions from './BudgetEntryFunctions';
var years:any   = 5
var months:any  = 0
var fy:any      = 1;
var total:any   = 0
var fy1:any     = 0
var fy2:any     = 0
var fy3:any     = 0
var fy4:any     = 0
var fy5:any     = 0
var ledger:any  = 0
var parentAccount:any = "";
var state:any ={
    primary_host:'', 
    user_id:0,
    analytical_reviews_id : 0,
    account_level_0_key:0,
    account_level_1_key:0,
    account_level_3_key:0,
    name:'',
    migration_name:'',
    project_name:'',
    start_up:false,
    corporate_tax:0,
    depreciation:0
}
const Budget = (props:any) =>{
    state.primary_host = props.state.primary_host
    state.user_id = props.state.user_id
    const [getHome, setHome]                                              = useState<any>(true)
    const [getExistingBudget, setExistingBudget]                          = useState<any>(false)
    const [getNewBudget, setNewBudget]                                    = useState<any>(false)
    const [getShowLoading, setShowLoading]                                = useState<any>(false)
    const [getLiveConnectionsListData, setLiveConnectionsListData]        = useState<any>()
    const [getProjectList, setProjectList]                                = useState<any>()
    const [getSummaryName, setSummaryName]                                = useState<any>("")
    const [getSummaryAnnualPeriod, setSummaryAnnualPeriod]                = useState<any>()
    const [getSummaryRevenue, setSummaryRevenue]                          = useState<any>()
    const [getGrossProfit, setGrossProfit]                                = useState<any>()
    const [getSummaryGP, setSummaryGP]                  = useState<any>()
    const [getSummaryRevenueDetail, setSummaryRevenueDetail]              = useState<any>()
    const [getSummaryRevenueViews, setSummaryRevenueViews]                = useState<any>(false)
    const [getAnalyticalReviewsData, setAnalyticalReviewsData]            = useState<any>()
    
    const [getSummaryExpenses, setSummaryExpenses]                        = useState<any>()
    const [getSummaryExpensesDetail, setSummaryExpensesDetail]            = useState<any>()
    const [getSummaryExpensesViews, setSummaryExpensesViews]              = useState<any>(false)

    const [getSummaryNetOperatingIncome, setSummaryNetOperatingIncome]    = useState<any>()
    const [getSummaryDepreciation, setSummaryDepreciation]                = useState<any>()
    const [getSummaryProfitBeforeTax, setSummaryProfitBeforeTax]          = useState<any>()
    const [getSummaryCorporateTax,setSummaryCorporateTax]                 = useState<any>()
    const [getSummaryProfitAfterTax, setSummaryProfitAfterTax]            = useState<any>()
    const [getSummaryTotalInvestmentNeeds, setSummaryTotalInvestmentNeeds] = useState<any>()
    const [getSummaryFeeCashflows, setSummaryFreeCashflow]                = useState<any>()
    const [getSummaryNetCashflows, setSummaryNetCashflows]                = useState<any>()
    const [getSummaryLoanInvestmentAmounts, setSummaryLoanInvestmentAmounts] = useState<any>()
    const [getSummary, setSummary]                                        = useState<any>(true)
    const [getSummaryDetail, setSummaryDetail]                            = useState<any>(false)
    const [getNewAccount, setNewAccount]                                  = useState<any>(false)  
    const [getActual, setActual]                                          = useState<any>(false)
    const [getChart, setChart]                                            = useState<any>(false)
    const [getChart1, setChart1]                                          = useState<any>()
    const [getChart2, setChart2]                                          = useState<any>()
    const [getHeaders, setHeaders]                                        = useState<any>()
    const [getData, setData]                                              = useState<any>()
    const [getDataTotal, setDataTotal]                                    = useState<any>()
    const [getBudgetList, setBudgetList]                                  = useState<any>()

    const [getActualHeaders, setActualHeaders]                            = useState<any>()
    const [getActualData, setActualData]                                  = useState<any>()
    const [getActualDataTotal, setActualDataTotal]                        = useState<any>()
    

    let txtProjectName:any                                                = useRef<any>()
    let txtNewAccount:any                                                 = useRef<any>()

    const drawlineSeries = (type:any, series:any, chart:any, chartTitle:any, axisType:any) =>{
        let o:any = null
        if(chart == 1)
            setChart1(o)
        if(chart == 2 )
            setChart2(o)
        let lineChart:any = {
                        chart:{
                            type: type,
                        },
                        title:{
                            text:''
                        },
                        subTitle:{
                            text:''
                        },
                        xAxis: {
                            type: axisType
                        },
                        series:series,
                        turboThreshold:0,
                        responsive: {
                            rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal'
                                },
                                yAxis: {
                                labels: {
                                    align: 'left',
                                    x: 0,
                                    y: -5
                                },
                                title: {
                                    text: chartTitle
                                }
                                },
                                subtitle: {
                                text: null
                                },
                                credits: {
                                enabled: false
                                }
                            }
                            }]
                        }
                    };
        
        let c:any = (<HighchartsReact highcharts={Highcharts} options={lineChart}></HighchartsReact>)
        if(chart == 1 )
            setChart1(c)
        if(chart == 1 )
            setChart2(c)
        
    }
    const renderCharts = (data:any, chart:any) =>{

        var series =[]
        var prop:any;
        var headers:any = []
        var y:any = 0;
        var z:any = null;
        var r:any = 0;
        var c:any = 0;
        var tr:any = 0;
        var tp:any = 0;

        var series =[]
        var prop:any;
        var headers:any = []
        var y:any = 0;
        var z:any = null;
        if (chart == 1)
        for(prop in data.data[0]){
            if(prop != "ACCOUNT" && prop != "MONTH PERIOD" && prop != "FY" ){
                headers.push(prop)
            }
        }
        console.log(headers)
        console.log(data.data[0])
        console.log(data.data[0][headers[0]])
        for(var i = 0 ; i < headers.length; i++){
            var s:any = []
            for (var j = 0 ; j < data.data.length; j++){
                if(isNumeric(data.data[j][headers[i]]))
                    s.push({x:data.data[j]["MONTH PERIOD"]/1, y:data.data[j][headers[i]]/1})
                if(!isNumeric(data.data[j][headers[i]]))
                    s.push({x:data.data[j]["MONTH PERIOD"]/1, y:0})                    
            }
            if(headers[i] == "ACTUAL" )
                series.push({name:headers[i], data:s, type:'column'})
            if(headers[i] == "BUDGET" )
                series.push({name:headers[i], data:s, type:'column'})
        }
        drawlineSeries("spline", series, chart, null, 'linear' )
          
        console.log(series) 
    }
    const setView = (s:any) =>{
        resetView()
        switch(s){ 
            case 0: setHome(true); break;
            case 1: setExistingBudget(true);callBudgets(); break;
            case 2: setNewBudget(true); 
                    setShowLoading(true)
                    callAnalyticalReviews(state)
                    .then(response=>{ console.log(response); return response})
                    .then(data=>{
                        setShowLoading(false)
                        setLiveConnectionsListData(data)
                        
                    })
                    
                    .catch(error=>{setShowLoading(false)})
            
                    break;
            case 3: setSummary(true);
                    //callSummary()
                    break;
            case 4: setSummaryDetail(true);
                    callBudgetActual()
                    callBudgetActualSummary()
                    setChart(true)
                    //getActual(false);
                    break;
        }
    }
    const resetView = () =>{
        setHome(false)
        setExistingBudget(false)
        setNewBudget(false)
        setSummary(false)
        setSummaryDetail(false)
    }
    
    const callAccountDetail = (i:any, account:any, analytical_reviews_id:any) =>{
        state.account_level_1_key = i
        parentAccount = account
        var options:any = []
        
        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=select_budget"+
        "&account_level_1_key="+i+
        "&analytical_reviews_id="+analytical_reviews_id+
        "&fy="+fy
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            setView(4)
            state.account_level_1_key = data.data[0].account_level_1_key
            state.account_level_2_key = data.data[0].account_level_2_key
            state.account_level_3_key = data.data[0].account_level_3_key
            total = 0;
            for(var i = 0; i < data.data.length; i++){
                total += data.data[i]["TOTAL"]/1
                console.log("total:"+total)
            }
            options.push(data.data)    
            var headers:any  = []
            var x:any;
            headers.push("FY")
            headers.push("ACCOUNT")
            if (state.account_level_1_key != 9)
                headers.push("STARTUP")
            headers.push("TOTAL")
            
            for(x in options[0][0]){
                console.log(x)
                if(state.account_level_1_key != 9){
                    if(
                        x != "account_level_0_key" && 
                        x != "analytical_reviews_id" && 
                        x != "FY"  && 
                        x != "ACCOUNT" &&
                        x != "TOTAL" &&
                        x != "STARTUP" &&
                        x != "account_level_1_key" &&
                        x != "account_level_2_key" &&
                        x != "account_level_3_key" 
                    )
                    headers.push(x)    
                }
                if(state.account_level_1_key == 9){
                    if(
                        x != "account_level_0_key" && 
                        x != "analytical_reviews_id" && 
                        x != "FY"  && 
                        x != "ACCOUNT" &&
                        x != "TOTAL" &&
                        x != "STARTUP" &&
                        x != "account_level_1_key" &&
                        x != "account_level_2_key" &&
                        x != "account_level_3_key" 
                    )
                    headers.push(x)
                }
            }
            

            console.log(headers)
            //headers = headers.reverse();

            const ThData:any =()=>{
                return headers.map((header:any, i:number)=>{
                    if(!isNumeric(header) && header != "TOTAL")
                        return <th  className="ion-text-left ion-padding account-header-color" key={i}><b>{header}</b></th>
                    if(!isNumeric(header) && header == "TOTAL")
                        return <th  className="ion-text-center ion-padding data-header-total-color" key={i}><b>{header}</b></th>
                    if(isNumeric(header))
                        return <th  className="ion-text-center ion-padding data-header-color" key={i}><b>{header}</b></th>
                })
            }
            
            var tdData:any = () =>{
                return options[0].map((datax:any, i:number)=>{
                    //console.log(datax) 
                    return  (
                            <tr key={i}>
                                {
                                    headers.map((v:any, z:number)=>{
                                        console.log(v)
                                        if(true){
                                            if(!isNumeric(datax[v]) || v == 'FY'){
                                                if(v != 'FY')
                                                return <td   key={z} className={"ion-text-left ion-padding "}>
                                                    <span onClick={()=>{openModal(1)}} title="Settings"><IonIcon  className="size-20 text-green hover-icon" icon={settingsOutline}></IonIcon>&nbsp;</span>
                                                    <span onClick={()=>{openModal(2)}} title="Ledger"><IonIcon  className="size-20 text-blue hover-icon" icon={listOutline}></IonIcon>&nbsp;</span>
                                                    <span onClick={()=>{openModal(3)}} title="Delete"><IonIcon  className="size-20 text-red hover-icon" icon={trashBinOutline}></IonIcon>&nbsp;</span>
                                                    {datax[v]}
                                                </td>
                                                if(v = 'FY')
                                                return <td   key={z} className={"ion-text-left ion-padding "}>
                                                    {datax[v]}
                                                </td>
                                            }
                                            if(isNumeric(datax[v]) && v != 'FY' && v != "STARTUP" ){
                                                return <td contentEditable={true} onBlur={(e)=>{setBudget(v,e.currentTarget.innerText, datax["FY"], datax["account_level_0_key"])}} key={z} className={"ion-text-right ion-padding "}>{addCommas((datax[v]/1).toFixed(0))}</td>
                                            }
                                            if(isNumeric(datax[v]) && v != 'FY' && v == "STARTUP" && state.account_level_1_key != 9 ){
                                                if(datax["STARTUP"]/1 == 1)
                                                return (
                                                    <td key={z} className={"ion-text-right ion-padding "}>
                                                        <IonCheckbox checked={true} onIonChange={()=>{setStartup(datax["account_level_0_key"],datax["FY"])}}></IonCheckbox>
                                                    </td>
                                                )
                                                if(datax["STARTUP"]/1 == 0)
                                                return (
                                                    <td key={z} className={"ion-text-right ion-padding "}>
                                                        <IonCheckbox  onIonChange={()=>{setStartup(datax["account_level_0_key"],datax["FY"])}}></IonCheckbox>
                                                    </td>
                                                )
                                            }
                                        }
                                    })
                                }
                            </tr> 
                        )
                })
                
            }
            var totals:any
            if(state.account_level_1_key == 9)
                totals = <tr key={-1}>
                        <td colSpan={2}><b>Total</b></td>
                        <td colSpan={1}  className={"ion-text-right ion-padding"}><b>{addCommas((total/1).toFixed(0))}</b></td>
                        {/*<td colSpan={11}  className={"ion-text-right ion-padding"}><b></b></td>*/}
                    </tr>
            if(state.account_level_1_key != 9)
                totals = <tr key={-1}>
                        <td colSpan={2}><b>Total</b></td>
                        <td colSpan={1}  className={"ion-text-right ion-padding"}><b></b></td>
                        <td colSpan={1}  className={"ion-text-right ion-padding"}><b>{addCommas((total/1).toFixed(0))}</b></td>
                        {/*<td colSpan={12}  className={"ion-text-right ion-padding"}><b></b></td>*/}
                    </tr>

            setData(tdData)
            setDataTotal(totals)
            setHeaders(ThData)
            //setView(4)
        })
        .catch(error =>{
            alert(error)
            alert("Error Collecting Budget Data")
        })
    }
    
    const callAccountDetailActual = (i:any, account:any, analytical_reviews_id:any) =>{
        var options:any = [];
        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=select_actual"+
        "&account_level_1_key="+i+
        "&analytical_reviews_id="+state.analytical_reviews_id+
        "&fy="+fy
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            setView(4)
            state.account_level_1_key = data.data[0].account_level_1_key
            state.account_level_2_key = data.data[0].account_level_2_key
            state.account_level_3_key = data.data[0].account_level_3_key
            total = 0;
            for(var i = 0; i < data.data.length; i++){
                total += data.data[i]["TOTAL"]/1
                console.log("total:"+total)
            }
            options.push(data.data)    
            var headers:any  = []
            var x:any;
            headers.push("FY")
            headers.push("ACCOUNT")
            headers.push("TOTAL")
            if (state.account_level_1_key != 9)
                headers.push("STARTUP")
            for(x in options[0][0]){
                console.log(x)
                if(state.account_level_1_key != 9){
                    if(
                        x != "account_level_0_key" && 
                        x != "analytical_reviews_id" && 
                        x != "FY"  && 
                        x != "ACCOUNT" &&
                        x != "TOTAL" &&
                        x != "STARTUP" &&
                        x != "account_level_1_key" &&
                        x != "account_level_2_key" &&
                        x != "account_level_3_key" 
                    )
                    headers.push(x)    
                }
                if(state.account_level_1_key == 9){
                    if(
                        x != "account_level_0_key" && 
                        x != "analytical_reviews_id" && 
                        x != "FY"  && 
                        x != "ACCOUNT" &&
                        x != "TOTAL" &&
                        x != "STARTUP" &&
                        x != "account_level_1_key" &&
                        x != "account_level_2_key" &&
                        x != "account_level_3_key" 
                    )
                    headers.push(x)
                }
            }
            

            console.log(headers)
            //headers = headers.reverse();

            const ThData:any =()=>{
                return headers.map((header:any, i:number)=>{
                    if(!isNumeric(header) && header != "TOTAL")
                        return <th  className="ion-text-left ion-padding account-actual-header-color" key={i}><b>{header}</b></th>
                    if(!isNumeric(header) && header == "TOTAL")
                        return <th  className="ion-text-center ion-padding data-actual-header-total-color" key={i}><b>{header}</b></th>
                    if(isNumeric(header))
                        return <th  className="ion-text-center ion-padding data-actual-header-color" key={i}><b>{header}</b></th>
                })
            }
            
            var tdData:any = () =>{
                return options[0].map((datax:any, i:number)=>{
                    //console.log(datax) 
                    return  (
                            <tr key={i} className="actual-row">
                                {
                                    headers.map((v:any, z:number)=>{
                                        console.log(v)
                                        if(true){
                                            if(!isNumeric(datax[v]) || v == 'FY')
                                                return <td   key={z} className={"ion-text-left ion-padding "}>{datax[v]}</td>
                                            if(isNumeric(datax[v]) && v != 'FY' && v != "STARTUP" ){
                                                return <td contentEditable={true} onBlur={(e)=>{setActualValue(v,e.currentTarget.innerText, datax["FY"], datax["account_level_0_key"])}} key={z} className={"ion-text-right ion-padding "}>{addCommas((datax[v]/1).toFixed(0))}</td>
                                            }
                                            if(isNumeric(datax[v]) && v != 'FY' && v == "STARTUP" && state.account_level_1_key != 9 ){
                                                if(datax["STARTUP"]/1 == 1)
                                                return (
                                                    <td key={z} className={"ion-text-right ion-padding "}>
                                                        <IonCheckbox checked={true} onIonChange={()=>{setStartup(datax["account_level_0_key"],datax["FY"])}}></IonCheckbox>
                                                    </td>
                                                )
                                                if(datax["STARTUP"]/1 == 0)
                                                return (
                                                    <td key={z} className={"ion-text-right ion-padding "}>
                                                        <IonCheckbox  onIonChange={()=>{setStartup(datax["account_level_0_key"],datax["FY"])}}></IonCheckbox>
                                                    </td>
                                                )
                                            }
                                        }
                                    })
                                }
                            </tr> 
                        )
                })
                
            }
            var totals:any
            if(state.account_level_1_key == 9)
                totals = <tr key={-1}>
                    <td colSpan={2}><b>Total</b></td>
                    <td colSpan={1}  className={"ion-text-right ion-padding"}><b>{addCommas((total/1).toFixed(0))}</b></td>
                    {/*<td colSpan={11}  className={"ion-text-right ion-padding"}><b></b></td>*/}
                </tr>
            if(state.account_level_1_key != 9)
                totals = <tr key={-1}>
                    <td colSpan={2}><b>Total</b></td>
                    <td colSpan={1}  className={"ion-text-right ion-padding"}><b></b></td>
                    <td colSpan={1}  className={"ion-text-right ion-padding"}><b>{addCommas((total/1).toFixed(0))}</b></td>
                    {/*<td colSpan={12}  className={"ion-text-right ion-padding"}><b></b></td>*/}
                </tr>

            setActualData(tdData)
            setActualDataTotal(totals)
            setActualHeaders(ThData)
        })
        .catch(error =>{
            alert("Failed to collect actual values for budget")
        })
    }

    const setStartup = (account_level_0_key:any, year_period:any ) =>{
        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=update_start_up"+
        "&account_level_0_key="+account_level_0_key+
        "&analytical_reviews_id="+state.analytical_reviews_id+
        "&year_period="+year_period
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data=>{
            callAccountDetail(state.account_level_1_key, parentAccount, state.analytical_reviews_id)
        })
    }
    const setBudget = (month:any, value:any, fy:any, account_level_0_key:any) =>{
        state.account_level_0_key = account_level_0_key
        var options:any = []
        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=update_budget"+
        "&year_period="+fy+
        "&month_period="+month+
        "&value="+value+
        "&account_level_0_key="+state.account_level_0_key+
        "&analytical_reviews_id="+state.analytical_reviews_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            options.push(data.data)   
            callAccountDetail(state.account_level_1_key, parentAccount, state.analytical_reviews_id)
        })
        .catch(error =>{
            alert("Failed to set budget values")
        })
    }
    const setActualValue = (month:any, value:any, fy:any, account_level_0_key:any) =>{
        state.account_level_0_key = account_level_0_key
        var options:any = []
        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=update_actual"+
        "&year_period="+fy+
        "&month_period="+month+
        "&value="+value+
        "&account_level_0_key="+state.account_level_0_key+
        "&analytical_reviews_id="+state.analytical_reviews_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            options.push(data.data)   
            callAccountDetailActual(state.account_level_1_key, parentAccount, state.analytical_reviews_id)
        })
        .catch(error =>{
            alert("Failed to set actual values for budget")
        })
    }
    
    const callBudgetActual = () =>{
        var options:any = []
        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=select_budget_actual"+
        "&account_level_1_key="+state.account_level_1_key+
        "&analytical_reviews_id="+state.analytical_reviews_id+
        "&fy="+fy
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{ 
            renderCharts(data, 2)
        })
        .catch(error =>{
            alert("Failed to set actual values for budget")
        })
    }

    const callBudgetActualSummary = () =>{
        var options:any = []
        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=select_budget_actual_summary"+
        "&account_level_1_key="+state.account_level_1_key+
        "&analytical_reviews_id="+state.analytical_reviews_id+
        "&fy="+fy
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{ 
            renderCharts(data, 1)
        })
        .catch(error =>{
            alert("Failed to set actual values for budget")
        })
    }
    const ddlPeriod:any                                     = useRef<any>()
    const ddlStartingMonth:any                              = useRef<any>()
    const ddlStartingYear:any                               = useRef<any>()
    const txtCorporateTaxRate:any                           = useRef<any>()
    const txtDepreciationRate:any                           = useRef<any>()

    const createBudget = () =>{
        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=insert_budget_manifest"+
        "&year_period="+ddlPeriod.current.value+
        "&name="+txtProjectName.current.value+
        "&analytical_reviews_id="+state.analytical_reviews_id+
        "&year_start="+ddlStartingYear.current.value+
        "&month_start="+ddlStartingMonth.current.value+
        "&corporate_tax="+txtCorporateTaxRate.current.value+
        "&depreciation="+txtDepreciationRate.current.value+
        "&created_by="+state.user_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{ 
            setView(3)
            callSummary(data.data[0].analytical_reviews_id)
           
        })
    }
    const callSummary = (analytical_reviews_id:any) =>{
        var options:any = []
        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=select_budget_summary"+
        "&analytical_reviews_id="+analytical_reviews_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            total = 0

            state.depreciation = data.data[0].depreciation/1
            state.corporate_tax = data.data[0].corporate_tax/1

            options.push(data.data)    
            var headers:any  = []
            var x:any;
            headers.push("ACCOUNT")
            headers.push("TOTAL")
            for(x in options[0][0]){
                console.log(x)
                if(
                    x != "account_level_1_key" &&
                    x != "account_level_2_key" &&
                    x != "account_level_3_key" && 
                    x != "analytical_reviews_id"  && 
                    x != "ACCOUNT" &&
                    x != "TOTAL" &&
                    x != "depreciation" &&
                    x != "corporate_tax"
                )
                headers.push(x)
            }
            

            console.log(headers)
            //headers = headers.reverse();

            const ThData:any =()=>{
                return headers.map((header:any, i:number)=>{
                    if(isNumeric(header)){
                        if(header != "TOTAL" )
                            return <th  className="ion-text-left ion-padding data-header-color" key={i}><b>FY {header}</b></th>
                        if(header == "TOTAL" )
                            return <th  className="ion-text-center ion-padding data-header-total-color" key={i}><b>FY {header}</b></th>
                    }
                    if(!isNumeric(header))
                        if(header != "TOTAL" )
                            return <th  className="ion-text-left ion-padding account-header-color" key={i}><b>{header}</b></th>
                        if(header == "TOTAL" )
                            return <th  className="ion-text-center ion-padding data-header-total-color" key={i}><b>{header}</b></th>
                })
            }
            const tdData:any =() =>{
                return options[0].map((datax:any, i:number)=>{
                    console.log(datax) 
                    return(
                        <tr key={i}>
                            {
                                headers.map((v:any, z:number)=>{
                                    console.log(v)
                                    if(datax["account_level_1_key"] != 8){
                                        if(!isNumeric(datax[v]) )
                                            return( 
                                            <td onClick={()=>{callAccountDetail(datax["account_level_1_key"], datax[v], datax["analytical_reviews_id"])}} key={z} className={"ion-text-left ion-padding hover "}>
                                                <IonIcon icon={arrowForwardCircleOutline} className="size-24 text-grey"></IonIcon>&nbsp;
                                                {datax[v]}
                                            </td>)
                                        if(isNumeric(datax[v]) && v == 'TOTAL')
                                            return <td onClick={()=>{}} key={z} className={"ion-text-right ion-padding "}><b>{addCommas((datax[v]/1).toFixed(0))}</b></td>
                                        if(isNumeric(datax[v]) && v != 'FY' && v != 'TOTAL')
                                            return <td onClick={()=>{}} key={z} className={"ion-text-right ion-padding fy-"+v+"-"+datax["account_level_1_key"]}>{addCommas((datax[v]/1).toFixed(0))}</td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }
            const tdDataGP:any =() =>{
                return options[0].map((datax:any, i:number)=>{
                    console.log(datax) 
                    if(i == 0)
                    return(
                        <tr key={i}>
                            {
                                headers.map((v:any, z:number)=>{
                                    console.log(v)
                                    if(true){
                                        if(!isNumeric(datax[v]) )
                                            return <td onClick={()=>{}} key={z} className={"ion-text-left ion-padding account-header-color"}><b>GROSS PROFIT / NET OPERATING INCOME</b></td>
                                        if(isNumeric(datax[v]) )
                                            return <td className={"ion-text-right ion-padding data-header-color"}>{addCommas(getGP(v,data))}</td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }
            const tdDataDepreciation:any =() =>{
                return options[0].map((datax:any, i:number)=>{
                    console.log(datax) 
                    if(i == 0)
                    return(
                        <tr key={i}>
                            {
                                headers.map((v:any, z:number)=>{
                                    console.log(v)
                                    if(true){
                                        if(!isNumeric(datax[v]) )
                                            return (<td onClick={()=>{callAccountDetail(8, "Property, Plant & Equipment", datax["analytical_reviews_id"])}} key={z} className={"ion-text-left ion-padding hover"}>
                                                <IonIcon icon={arrowForwardCircleOutline} className="size-24 text-grey"></IonIcon>&nbsp;
                                                DEPRECIATION {state.depreciation*100}%
                                            </td>)
                                        if(isNumeric(datax[v]) )
                                            return <td className={"ion-text-right ion-padding "}>{addCommas(getDepreciation(v,data))}</td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }

            const tdDataProfitBeforeTax:any =() =>{
                return options[0].map((datax:any, i:number)=>{
                    console.log(datax) 
                    if(i == 0)
                    return(
                        <tr key={i}>
                            {
                                headers.map((v:any, z:number)=>{
                                    console.log(v)
                                    if(true){
                                        if(!isNumeric(datax[v]) )
                                            return <td onClick={()=>{}} key={z} className={"ion-text-left ion-padding account-header-color"}><b>PROFIT BEFORE TAX</b></td>
                                        if(isNumeric(datax[v]) )
                                            return <td className={"ion-text-right ion-padding data-header-color"}>{addCommas(getGP(v,data)-getDepreciation(v,data))}</td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }
            const tdDataCorporateTax:any =() =>{
                return options[0].map((datax:any, i:number)=>{
                    console.log(datax) 
                    if(i == 0)
                    return(
                        <tr key={i}>
                            {
                                headers.map((v:any, z:number)=>{
                                    console.log(v)
                                    if(true){
                                        if(!isNumeric(datax[v]) )
                                            return <td onClick={()=>{}} key={z} className={"ion-text-left ion-padding account-header-color"}><b>CORPORATE TAX {state.corporate_tax*100}%</b></td>
                                        if(isNumeric(datax[v]) )
                                            return <td className={"ion-text-right ion-padding data-header-color"}>{addCommas(getCorporateTax((getGP(v,data)/1-getDepreciation(v,data)/1)))}</td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }

            const tdDataProfitAfterTax:any =() =>{
                return options[0].map((datax:any, i:number)=>{
                    console.log(datax) 
                    if(i == 0)
                    return(
                        <tr key={i}>
                            {
                                headers.map((v:any, z:number)=>{
                                    console.log(v)
                                    if(true){
                                        if(!isNumeric(datax[v]) )
                                            return <td onClick={()=>{}} key={z} className={"ion-text-left ion-padding account-header-color"}><b>PROFIT AFTER TAX</b></td>
                                        if(isNumeric(datax[v]) )
                                            return <td className={"ion-text-right ion-padding data-header-color"}>{getProfitAfterTax((getCorporateTax((getGP(v,data)/1-getDepreciation(v,data)/1))),getGP(v,data)-getDepreciation(v,data))}</td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }

            const tdDataTotalInvestmentNeeds:any =() =>{
                return options[0].map((datax:any, i:number)=>{
                    console.log(datax) 
                    if(i == 0)
                    return(
                        <tr key={i}>
                            {
                                headers.map((v:any, z:number)=>{
                                    console.log(v)
                                    if(true){
                                        if(!isNumeric(datax[v]) )
                                            return (<td onClick={()=>{}} key={z} className={"ion-text-left ion-padding hover"}>TOTAL INVESTMENT NEEDS</td>)
                                        if(isNumeric(datax[v]) )
                                            return <td key={z} className={"ion-text-right ion-padding "}>{0}</td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }

            const tdDataFreeCashflows:any =() =>{
                return options[0].map((datax:any, i:number)=>{
                    console.log(datax) 
                    if(i == 0)
                    return(
                        <tr key={i}>
                            {
                                headers.map((v:any, z:number)=>{
                                    console.log(v)
                                    if(true){
                                        if(!isNumeric(datax[v]) )
                                            return <td onClick={()=>{}} key={z} className={"ion-text-left ion-padding account-header-color"}><b>FREE CASHFLOWS</b></td>
                                        if(isNumeric(datax[v]) )
                                            return <td className={"ion-text-right ion-padding data-header-color"}>{getProfitAfterTax((getCorporateTax((getGP(v,data)/1-getDepreciation(v,data)/1))),getGP(v,data))}</td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }

            const tdDataLoanInvestmentAmounts:any =() =>{
                return options[0].map((datax:any, i:number)=>{
                    console.log(datax) 
                    if(i == 0)
                    return(
                        <tr key={i}>
                            {
                                headers.map((v:any, z:number)=>{
                                    console.log(v)
                                    if(true){
                                        if(!isNumeric(datax[v]) )
                                            return <td onClick={()=>{}} key={z} className={"ion-text-left ion-padding hover"}>LOAN INVESTMENT AMOUNT</td>
                                        if(isNumeric(datax[v]) )
                                            return <td key={z} className={"ion-text-right ion-padding "}>{0}</td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }

            const tdDataNetflows:any =() =>{
                return options[0].map((datax:any, i:number)=>{
                    console.log(datax) 
                    if(i == 0)
                    return(
                        <tr key={i}>
                            {
                                headers.map((v:any, z:number)=>{
                                    console.log(v)
                                    if(true){
                                        if(!isNumeric(datax[v]) )
                                            return <td onClick={()=>{}} key={z} className={"ion-text-left ion-padding account-header-color"}><b>NET CASHFLOWS</b></td>
                                        if(isNumeric(datax[v]) )
                                            return <td className={"ion-text-right ion-padding data-header-color"}>{getProfitAfterTax((getCorporateTax((getGP(v,data)/1-getDepreciation(v,data)/1))),getGP(v,data))}</td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }
            setSummaryAnnualPeriod(ThData)
            setSummaryRevenue(tdData)
            setSummaryGP(tdDataGP)
            setSummaryDepreciation(tdDataDepreciation)
            setSummaryProfitBeforeTax(tdDataProfitBeforeTax)
            setSummaryCorporateTax(tdDataCorporateTax)
            setSummaryProfitAfterTax(tdDataProfitAfterTax)
            setSummaryTotalInvestmentNeeds(tdDataTotalInvestmentNeeds)
            setSummaryFreeCashflow(tdDataFreeCashflows)
            setSummaryNetCashflows(tdDataNetflows)
            setSummaryLoanInvestmentAmounts(tdDataLoanInvestmentAmounts)
        })
    }
    const getInvestmentNeeds = async (period:any) =>{
        
        var resultSet:any
        if (period != "TOTAL"){
            resultSet = await fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=select_investment_summary"+
            "&analytical_reviews_id="+state.analytical_reviews_id+
            "&period="+period
            ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
            )
            .then(response => response.json())
            .then(data =>{
                var x:any = 0;
                if(isNumeric(data.data[0][period]))
                    x = data.data[0][period]
                return x
            })
            .catch(error=>{

                return 0
            })
        }else{
            resultSet = 0
        }
        return resultSet
    }
    const getProfitAfterTax = (corporate_tax:any, profit_before:any) =>{
        return (profit_before - corporate_tax)
    }
    const getCorporateTax= (profit_before_tax:any) =>{
        //alert(profit_before_tax)
        //alert(state.corporate_tax)
        return (profit_before_tax/1 * state.corporate_tax).toFixed(0)
    }
    const getDepreciation= (period:any, data:any) =>{
        var sumProperty:any = 0
        

        for(var i = 0; i < data.data.length; i++){
            if(data.data[i].account_level_1_key == '8')
                sumProperty += data.data[i][period]/1
        }
        //alert(state.depreciation)
        return (sumProperty*state.depreciation)
    }
    const getGP= (period:any, data:any) =>{
        var sumRevenue:any = 0
        var sumExpenses:any = 0
        console.log(data.data[0][1])

        for(var i = 0; i < data.data.length; i++){
            if(data.data[i].account_level_1_key == '9')
                sumRevenue += data.data[i][period]/1
            if(data.data[i].account_level_1_key == '10')
                sumExpenses += data.data[i][period]/1
        }

        return (sumRevenue - sumExpenses)
    }
    const callBudgets = () =>{
        var options:any = []
        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=select_budget_list"+
        "&created_by="+state.user_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            options.push(data.data)
            var list:any = options[0].map((x:any, i:number)=>{
                return(
                    <IonCol key={i} size="3" onClick={()=>{
                        state.analytical_reviews_id = x.analytical_reviews_id;
                        state.account_level_3_key = x.account_level_3_key;
                        callSummary(x.analytical_reviews_id);setView(3)
                    }}>
                        <IonItem>
                            <IonRadio value={x.analytcal_reviews_id}></IonRadio>&nbsp;
                            <IonLabel>{x.name}</IonLabel>
                        </IonItem>
                    </IonCol>
                )
            })
            setBudgetList(list)
        })
    }
    const getOptions = (start:number, end:number) =>{
        var options:any = []
        for(var i = start; i < end; i++){
            options.push(<IonSelectOption value={i}>{i}</IonSelectOption>)
        }
        return options
    }
    const chkIsLedger:any                           = useRef<HTMLIonCheckboxElement>()
    const addAccount = () =>{
        if(chkIsLedger.detail.checked){
            ledger = 1
        }else{
            ledger = 0
        }

        fetch(props.state.primary_host+"dbconnect/dbdynamic.jsp?vc=insert_account"+
        "&name="+txtNewAccount.current.value+
        "&account_level_3_key="+state.account_level_3_key+
        "&parent_key="+state.account_level_1_key+
        "&analytical_reviews_id="+state.analytical_reviews_id+
        "&is_ledger="+ ledger
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            callAccountDetail(state.account_level_1_key, parentAccount, state.analytical_reviews_id)
            setNewAccount(false)
        })
    }
    const showActual = () =>{
        setActual(!getActual)
        callAccountDetailActual(state.account_level_1_key, parentAccount, state.analytical_reviews_id)
    }
    const hideActual = () =>{
        setActual(!getActual)
        callAccountDetail(state.account_level_1_key, parentAccount, state.analytical_reviews_id)
    }

    const [getModalTitle, setModalTitle]                  = useState<any>()
    const [getModalContent1, setModalContent1]            = useState<any>(false)
    const [getModalContent2, setModalContent2]            = useState<any>(false)
    const [getModalContent3, setModalContent3]            = useState<any>(false)

    const openModal = (modal:any) =>{
        resetModal()
        switch(modal){
            case 1: 
                openModalFunction();
                setModalTitle("Settings");
                setModalContent1(true);

                break;
            case 2: openModalFunction();setModalTitle("Make Ledger");setModalContent2(true);break;
            case 3: openModalFunction();setModalTitle("Delete");setModalContent3(true);break;
        }
    }
    const resetModal = () =>{
        setModalContent1(false)
        setModalContent2(false)
        setModalContent3(false)
    }
    const modal = useRef<HTMLIonModalElement>(null);
    const input = useRef<HTMLIonInputElement>(null);

    const ModalExample = ({
        onDismiss,
      }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
      }) => {
        const inputRef = useRef<HTMLIonInputElement>(null);
        return (
          <IonPage>
            <IonHeader>
              <IonToolbar>
                <IonRow>
                    <IonCol>
                        <IonButtons slot="start">
                            <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                                Cancel
                            </IonButton>
                        </IonButtons>
                    </IonCol>
                    <IonCol className='ion-text-center'>
                        <IonTitle>{getModalTitle}</IonTitle>
                    </IonCol>
                    <IonCol>
                        <IonButtons slot="end">
                        <IonButton onClick={() => onDismiss(inputRef.current?.value, 'confirm')}>Confirm</IonButton>
                        </IonButtons>
                    </IonCol>
                </IonRow>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {getModalContent1 &&
                    <BudgetEntryFunctions />
                }
                {getModalContent2 &&
                    <BudgetEntryFunctions />
                }
                {getModalContent3 &&
                <div>
                    <BudgetEntryFunctions />
                </div>    
                }
            </IonContent>
          </IonPage>
        );
    };
    const [message, setMessage] = useState(
        'This modal example uses triggers to automatically open a modal when the button is clicked.'
    );

    function confirm() {
        modal.current?.dismiss(input.current?.value, 'confirm');
    }

    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
        if (ev.detail.role === 'confirm') {
        setMessage(`Hello, ${ev.detail.data}!`);
        }
    }
    const [present, dismiss] = useIonModal(ModalExample, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
    });
    function openModalFunction() {

        present({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {
                setMessage(`Hello, ${ev.detail.data}!`);
                }
            },
        });
    }
    useEffect(() => {
        setView(0)
    }, []);
    return(

    <div className="text-black ion-text-left">
        <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'} ></IonLoading>
        {getHome &&
        <div>
            <IonRow>
                <IonCol></IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="3">
                    <div className="fetch-button" onClick={()=>{setView(1)}}>Choose Existing Budget</div>
                </IonCol>
                <IonCol size="3">
                    <div className="fetch-button" onClick={()=>{setView(2)}}>Create New Budget</div>
                </IonCol>
            </IonRow>
        </div>
        }
        {getExistingBudget &&
        <div>
            <IonRow>
                <IonCol size="12"  onClick={()=>{setView(0)}} className="hover ion-text-right">
                    Return to Home&nbsp;
                    <IonIcon icon={returnUpBack} className="size-24 text-gt"></IonIcon>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12"><b>Existing Budgets</b></IonCol>
            </IonRow>
            <IonRadioGroup onIonChange={(e)=>{state.analytical_reviews_id = e.detail.value;alert(e.detail.value);setView(3)}} onChange={(e)=>{state.analytical_reviews_id = e.currentTarget.value;alert(e.currentTarget.value);setView(3)}}>
                <IonRow>
                    {getBudgetList}
                </IonRow>
            </IonRadioGroup>
            
        </div>
        }
        {getNewBudget &&    
        <div>
            <IonRow>
                <IonCol size="12"  onClick={()=>{setView(0)}} className="hover ion-text-right">
                    Return to Home&nbsp;
                    <IonIcon icon={returnUpBack} className="size-24 text-gt"></IonIcon>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <b>Create New Budget</b>
                </IonCol>
                <IonCol>
                    <b>Use Existing Dataset</b>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="size-12">
                    <IonRow>
                        <IonCol size="5">
                            <IonItem>
                                <IonLabel position='floating'>Name</IonLabel>
                                <IonInput ref={txtProjectName}></IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol></IonCol>
                        
                        <IonCol size="5">
                            <IonItem>
                                <IonLabel position='floating'>Period(Years)</IonLabel>
                                <IonSelect ref={ddlPeriod} onIonChange={(e)=>{years = e.detail.value; }}>
                                    {getOptions(1,6)}
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                        <IonCol></IonCol>

                        <IonCol size="5">
                            <IonItem>
                                <IonLabel position='floating'>FY Starting Year</IonLabel>
                                <IonSelect ref={ddlStartingYear} onIonChange={(e)=>{years = e.detail.value; }}>
                                   {getOptions(2000,2041)}
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                        <IonCol></IonCol>
                        
                        <IonCol size="5">
                            <IonItem>
                                <IonLabel position='floating'>Corporate Tax Rate %</IonLabel>
                                <IonInput type="number" ref={txtCorporateTaxRate}  />
                            </IonItem>
                        </IonCol>
                        <IonCol></IonCol>
                        
                        <IonCol size="5">
                            <IonItem>
                                <IonLabel position='floating'>Annnual Depreciation Rate %</IonLabel>
                                <IonInput type="number" ref={txtDepreciationRate}  />
                            </IonItem>
                        </IonCol>
                        <IonCol></IonCol>

                        <IonCol size="5">
                            <IonItem>
                                <IonLabel position='floating'>FY Starting Month</IonLabel>
                                <IonSelect ref={ddlStartingMonth} onIonChange={(e)=>{months = e.detail.value; }}>
                                   {getOptions(1,13)}
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                        <IonCol></IonCol>
                        <IonCol size="5">
                            <IonItem>
                                <IonLabel >Start Up</IonLabel>
                                <IonCheckbox onChange={(e)=>{state.start_up = e.currentTarget.checked}}></IonCheckbox>
                            </IonItem>
                        </IonCol>
                        <IonCol></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="5"></IonCol>
                        <IonCol size="5" className="ion-padding ion-text-right">
                            <div className="fetch-button" onClick={()=>{state.analytical_reviews_id = 0; createBudget()}}>Create</div>
                        </IonCol>
                    </IonRow>
                </IonCol>
                <IonCol>
                    <IonRow>
                        <IonCol className="size-12">
                        <ProjectsSide
                            state={state} 
                            data={getLiveConnectionsListData}
                            setAnalyticalReviewsId={(e:any)=>{
                                //console.log("return:"+props.props)
                                //console.log(props.props)
                                console.log(e);
                                //props.props.analytical_reviews_id = e.analytcal_reviews_id;
                                state.analytical_reviews_id = e.analytcal_reviews_id;
                                state.migration_id = e.migration_id;
                                state.name = e.name
                                state.project_name = e.name;
                                state.migration_name = e.name;
                                setView(1)
                            }}
                            setState={(id:any)=>{}}
                            setLiveConnections={(e:any)=>{/*setLiveConnections(e)**/}}
                            getLiveConnectionsList={getLiveConnectionsListData}
                            setPageView={(e:any)=>{}} 
                            setOrganizationId={(e:any)=>{}} 
                            setOrganzationName={(e:any)=>{}} 
                            setLastUpdate={(e:any)=>{}} 
                            setYearEndDay={(e:any)=>{}} 
                            setYearEndMonth={(e:any)=>{}} 
                            setAuthorizedOrganization={(e:any)=>{}} 
                            setIndustry={(e:any)=>{}} 
                            setSector={(e:any)=>{}} 
                            setNativeCurrency={(e:any)=>{}} 
                            showSettingStage={(e:any)=>{}} 
                            callXeroAvailableYears={(e:any)=>{}} 
                        />
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </div>
        }
        {getSummary &&
        <div>
            <IonRow>
                <IonCol size="3"><b>{state.name}</b></IonCol>
                <IonCol size="5"><b>Annual Budget</b></IonCol>
                <IonCol size="4"  onClick={()=>{setView(0)}} className="hover ion-text-right">
                    Return to Options&nbsp;
                    <IonIcon icon={returnUpBack} className="size-24 text-gt"></IonIcon>
                </IonCol>
            </IonRow> 
            <table>
                <tr>
                    {getSummaryAnnualPeriod}   
                </tr>
                {getSummaryRevenue}
                {getSummaryGP}
                {getSummaryDepreciation}
                {getSummaryProfitBeforeTax}
                {getSummaryCorporateTax}
                {getSummaryProfitAfterTax}
                {getSummaryTotalInvestmentNeeds}
                {getSummaryFeeCashflows}
                {getSummaryLoanInvestmentAmounts}
                {getSummaryNetCashflows}
            </table>
        </div>
        }
        {getSummaryDetail &&
            <div>
                <IonRow className="">
                    <IonCol className="ion-padding size-24" size="3"><b>Parent Account - <IonLabel className="text-gt">{parentAccount}</IonLabel></b></IonCol>
                    
                    <IonCol className="ion-padding" size="3">
                        <IonItem>
                            <IonLabel>Select Financial Period</IonLabel>
                            <IonSelect  onIonChange={(e)=>{fy = e.detail.value;callAccountDetail(state.account_level_1_key,state.project_name,state.analytical_reviews_id) }}>
                                {getOptions(1,years)}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                    {!getNewAccount &&
                    <IonCol className="ion-padding hover ion-text-right" onClick={()=>{setNewAccount(!getNewAccount)}}>
                        <IonIcon icon={addCircleOutline} className="size-24 text-gt"></IonIcon> &nbsp;
                        <b>Add Account</b>
                    </IonCol>
                    }
                    {getNewAccount &&
                    <IonCol className="ion-padding hover ion-text-right" onClick={()=>{setNewAccount(!getNewAccount)}}>
                        <IonIcon icon={removeCircleOutline} className="size-24 text-gt"></IonIcon> &nbsp;
                        <b>Hide New Account</b>
                    </IonCol>
                    }
                    {!getActual &&
                    <IonCol className="ion-padding hover ion-text-right" onClick={()=>{showActual()}}>
                        <IonIcon icon={checkmarkCircleOutline} className="size-24 text-gt"></IonIcon> &nbsp;
                        <b>Show Actual</b>
                    </IonCol>
                    }
                    {getActual &&
                    <IonCol className="ion-padding hover ion-text-right" onClick={()=>{hideActual()}}>
                        <IonIcon icon={checkmarkCircleOutline} className="size-24 text-gt"></IonIcon> &nbsp;
                        <b>Show Budget</b>
                    </IonCol>
                    }
                    <IonCol className="ion-padding hover ion-text-right" onClick={()=>{}}>
                        <IonIcon icon={analyticsOutline} className="size-24 text-gt"></IonIcon> &nbsp;
                        <b>View Historical</b>
                    </IonCol>
                    <IonCol onClick={()=>{setView(3); callSummary(state.analytical_reviews_id)}} className="ion-padding hover ion-text-right">
                        <b>Back</b>&nbsp;
                        <IonIcon icon={returnUpBack} className="size-24 text-gt"></IonIcon>
                    </IonCol>
                </IonRow>
                {getNewAccount &&
                <IonRow>
                    <table>
                        <tr>
                            <td className='ion-padding'>
                                <IonItem>
                                    <IonLabel>Add Account</IonLabel>&nbsp;
                                    <IonInput 
                                        ref={txtNewAccount}  
                                        placeholder="Add Account Name" 
                                        type="text"  
                                        className='size-16 ion-text-left'
                                    /> 
                                </IonItem>
                            </td>
                            <td className="ion-text-left ion-padding">
                                <IonItem>
                                    <IonLabel>Is Ledger Account</IonLabel>&nbsp;
                                    <IonCheckbox ref={chkIsLedger} onIonChange={(e)=>{if(e.detail.checked){ledger = 1}else{ledger=0}}}></IonCheckbox>
                                </IonItem>
                            </td>
                            <td className="ion-text-left ion-padding">
                                <div className="fetch-button" onClick={()=>{addAccount()}}>Add</div>
                            </td>
                        </tr>
                    </table>
                </IonRow>
                }
                {!getActual &&
                <table>
                    <tr>
                        {getHeaders}
                    </tr>
                    {getData}
                    {getDataTotal}
                </table>
                }
                {getActual &&
                <table>
                    <tr>
                        {getActualHeaders}
                    </tr>
                    {getActualData}
                    {getActualDataTotal}
                </table>
                }
                {getChart &&
                <div >
                    <IonRow>
                        <IonCol className="ion-padding"><b>Account Summary</b></IonCol>
                        <IonCol className="ion-padding"><b>Composition</b></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding">{getChart1}</IonCol>
                        <IonCol className="ion-padding">{getChart2}</IonCol>
                    </IonRow>
                </div>
                }
            </div>
        }
    </div>

    )
}
export default Budget