import { IonRow, IonCol, IonIcon, IonLabel } from '@ionic/react';
import {apps,closeOutline} from 'ionicons/icons';
import $ from 'jquery'

const Reports = (props:any) =>{
  const setSelectedView = (ev:any) =>{
    resetSelectedView()    
    $("#"+ev).removeClass("icon-gt-view-end-button")
    $("#"+ev).addClass("icon-gt-view-end-button-selected")
  }
  const resetSelectedView = () =>{
    var obj = document.getElementsByClassName("view-end")
    for(var i = 0 ; i < obj.length ; i++){
      $(obj[i]).removeClass("icon-gt-view-end-button-selected")
      $(obj[i]).addClass("icon-gt-view-end-button")
    }
  }
  return(
      <div>
        <IonRow>
          <IonCol className="ion-text-right">
            <IonIcon className="size-24" onClick={()=>{props.setReports("report-area-hide")}} icon={closeOutline}></IonIcon>
          </IonCol>
        </IonRow>
        <IonRow className="side-bar-v-spacing-ext-ar text-black summary-account">
          <IonCol className="label-side-bar-ar view-end icon-gt-view-end-button" id="view_1" onClick={ev=>{setSelectedView(ev.currentTarget.id);props.state.analytical_report_id = 1;props.setReports("report-area-hide");props.location(1); }} >
            <IonIcon className="iconeb-ext-ar size-20 "  icon={apps}></IonIcon>&nbsp;
            <IonLabel className="text-black summary-account">Balance Sheet <br/> </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow className="side-bar-v-spacing-ext-ar text-black summary-account">
          <IonCol className="label-side-bar-ar view-end icon-gt-view-end-button" id="view_2" onClick={(ev) =>{setSelectedView(ev.currentTarget.id);props.state.analytical_report_id = 2;props.setReports("report-area-hide");props.location(2);}}>
            <IonIcon className="iconeb-ext-ar size-20 "  icon={apps}></IonIcon>&nbsp;
            <IonLabel className="text-black summary-account">Income & Expenses <br/> </IonLabel>
          </IonCol>
        </IonRow> 
        <IonRow className="side-bar-v-spacing-ext-ar text-black summary-account">
          <IonCol className="label-side-bar-ar view-end icon-gt-view-end-button" id="view_3" onClick={(ev) =>{setSelectedView(ev.currentTarget.id);props.state.analytical_report_id = 1;props.setReports("report-area-hide");props.location(3);}}>
            <IonIcon className="iconeb-ext-ar size-20 "  icon={apps}></IonIcon>&nbsp;
            <IonLabel className="text-black summary-account">Cashflow <br/> </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow className="side-bar-v-spacing-ext-ar text-black summary-account">
          <IonCol className="label-side-bar-ar view-end icon-gt-view-end-button" id="view_4" onClick={(ev) =>{setSelectedView(ev.currentTarget.id);props.state.analytical_report_id = 3;props.setReports("report-area-hide");props.location(4);}}>
            <IonIcon className="iconeb-ext-ar size-20 "  icon={apps}></IonIcon>&nbsp;
            <IonLabel className="text-black summary-account">Liquidity <br/> </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow className="side-bar-v-spacing-ext-ar text-black summary-account">
          <IonCol className="label-side-bar-ar view-end icon-gt-view-end-button" id="view_5" onClick={(ev) =>{setSelectedView(ev.currentTarget.id);props.state.analytical_report_id = 5;props.setReports("report-area-hide");props.location(5);}}>
            <IonIcon className="iconeb-ext-ar size-20 "  icon={apps}></IonIcon>&nbsp;
            <IonLabel className="text-black summary-account">Solvency <br/> </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow className="side-bar-v-spacing-ext-ar text-black summary-account">
          <IonCol className="label-side-bar-ar view-end icon-gt-view-end-button" id="view_6" onClick={(ev) =>{setSelectedView(ev.currentTarget.id);props.state.analytical_report_id = 9;props.setReports("report-area-hide");props.location(6);}}>
            <IonIcon className="iconeb-ext-ar size-20 "  icon={apps}></IonIcon>&nbsp;
            <IonLabel className="text-black summary-account">Net Income <br/> </IonLabel>
          </IonCol>
        </IonRow>
    </div>
  )
            
}
export default Reports;