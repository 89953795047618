import { IonSpinner, IonListHeader, IonButton,IonCheckbox, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonLoading, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonTextarea, IonBadge } from '@ionic/react';
import { alertCircleSharp, analytics, analyticsOutline, analyticsSharp, arrowBackSharp, arrowForwardSharp, bluetoothSharp, calendarOutline, checkmarkCircle, checkmarkCircleSharp, checkmarkDone, checkmarkDoneCircle, checkmarkOutline, closeOutline, cogOutline, copySharp, exitOutline, filterSharp, gridOutline, hammerOutline, homeOutline, listSharp, logInOutline, logOut, logOutOutline, mailOutline, mailSharp, pencilOutline, peopleCircleSharp, peopleOutline, personOutline, personSharp, push, returnUpBack, sendOutline, settingsOutline, settingsSharp, stopCircleSharp, trashBin, trashBinSharp, trashOutline, warningOutline, warningSharp, wifiOutline } from 'ionicons/icons';
import React,{ useRef, useState } from 'react';
//import ReactTable from "react-table";
//import 'react-table-6/react-table.css';
//import moment from 'moment';
import Highcharts, { keys } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import '../../theme/fonts/poppins-cufonfonts-webfont/Poppins-Regular.woff'
import $ from 'jquery';
//import 'floatthead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faDotCircle, faExclamationCircle, faFileCsv, faFileDownload, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Basic from '../Tables/Basic';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
var host:any;
var primryhost:any;
let testListData:any;
let heavy:any = [];
let categoryId:any=0;
let dt:any = []
let tests:any = [
    {id:123,test:"GL Completeness",description:"Tests the completeness of the GL by comparing the GL movement per accounts against the TB movement per account."},
    {id:122,test:"Math Accuracy",description:"Tests the completeness of the GL entries by checking that the total debit amount equals the total credit amount."},
    {id:121,test:"Unbalanced Journals",description:"Tests the completeness of the GL by identifying GL entries where the total debit amount does not equal the total credit amount."},
    {id:124,test:"Journals Without Descriptions",description:"Identifies manual journals without descriptions."},
    {id:146,test:"Account Analysis",description:"Summarises the GL by account showing the number and value of manual journals."},
    {id:0,test:"Least Used Accounts",description:"Tests the completeness of journal entries by summarising the number of manual journal entries by the GL account and identifying those with less than 12 entries"},
    {id:0,test:"Manual Entries To Automated Accounts",description:"Tests the validity of journal entries by identifying manual journals in the automated accounts (Revenue; Cash)"},
    {id:129,test:"Journals Above Performance Materiality",description:"Identifies all manual journals with a value greater than the performance materiality."},
    {id:145,test:"Journals Below Performance Materiality",description:"Identifies all manual journals with a value lower than the performance materiality but within 1% of the performance materiality."},
    {id:128,test:"Value Stratification Of Journals",description:"Performs a stratification of manual journals according to value bands (0 - 99.99; 100 - 999.99; 1 000 - 9 999.99; 10 000 - 99 999.99; 100 000 - 999 999.99; 1 000 000 - 9 999 999.99; 10 000 000 - 99 999 999.99; 100 000 000 and above"},
    {id:119,test:"Journals With Rounded Numbers",description:"Identifies all manual journals with a round amount (e.g. X00)"},
    {id:130,test:"Benfords First Two Digits Test",description:"Identifies manual journals (at line level) where the first two digits are above the Benford upper limit."},
    {id:125,test:"User Analysis",description:"Summarizes the number of manual journal entries by the user that created the journal."},
    {id:0,test:"Users With The Least Manual Journal Entries",description:"Tests the validity of journal entries by summarising the number of manual journal entries by the user that created the journal"},
    {id:0,test:"Segregation of duties",description:"Tests the segregation of duties by checking if the user that created the manual journal is the same user that authorised / posted the journal"},
    {id:118,test:"Weekend Manual Journals",description:"Identifies manual journals created on weekends."},
    {id:117,test:"Public Holiday Manual Journals",description:"Identifies manual journals created on public holidays."},
    {id:151,test:"Back Dated Entries",description:"Tests the validity of journal entries by identifying manual journals that have been backdated (entry date after posting date)"},
    {id:127,test:"Journals Passed On The Last Day Of The Financial Year",description:"Identifies journals passed on the last day of the financial year"},
    {id:1223, test:"Sequence Test",description:"Identifies missing journal numbers in a journal sequence"},
    {id:1224, test:"Duplicate Journals",description:"Identifies all manual journals where the journal number is duplicated"},
    {id:1225, test:"Journals By Period",description:"Summarises the GL by month showing the number and value of manual journals."},
    {id:1226, test:"Ending Amounts",description:"Identifies all manual journals with which ends with 99 (e.g. 99.X, X.99)"}
  ]
  function getTestDescription(test:any){
    //console.log(tests)
    for(var i = 0; i < tests.length; i++){
      if(tests[i].test.toUpperCase().includes(test.toUpperCase())){
        return tests[i].description
      }
    }
  }
 
  const setDateFormatHighCharts = (d:string) =>{
    var dt = new Date(d);
    var date = dt.getTime();
    //console.log(date)
    return date
  }
  function isNumber(str:any){
    //console.log("test:"+isNaN(str/1))
    if(!isNaN(str/1)){
        return true
    }else{
        return false
    }
  }
  const addCommas = (nStr:any) =>{
      nStr += '';
      var x = nStr.split('.');
      var x1 = x[0];
      var x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
          x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      return x1 + x2;
  }
  function getHeavyData(id:any){
    var data = []
    data.push(heavy.data[0][id].data)
    return data
  }
  
  function existsHeavyData(id:any){
    var exists = false;
    var x:any = Object.keys(heavy.data[0])
    for(var i  = 0 ; i < x.length; i ++){
      if(x[i] == id)
        exists = true
    }
    return exists;
  }
  function existsHeavyDataHasData(id:any){
    var hasdata = false;
    if (heavy.data[0][id].data.length > 0){
      hasdata = true
    }
    console.log("hasdata:"+hasdata)
    return hasdata;
  }
  function sumColumn(data:any,column:any){
    var value = 0;
    for(var i =0 ; i< data.length; i++){
      value = value + data[i][column]/1
    }
    console.log(value)
    return value; 
  }
  function getColumnValueArray(data:any,column:any){
    var columnData = []
    for (var i = 0; i < data.length; i++){
      if (data[i][column] != undefined)
        columnData.push(data[i][column])
      if (data[i][column] == undefined)
        columnData.push(0)
    }
    //console.log(columnData)
    return columnData
  }
  function getSplineArray(data:any,x:any,y:any){
    var s = []
    for (var i = 0; i < data.length; i++){
      s.push({x:setDateFormatHighCharts(data[i][x]), y:data[i][y]/1})
    }
    return s
  }
  function validateEmail(obj:any,valueToTest:any){
    var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (testEmail.test(valueToTest)){
        // Do whatever if it passes
        console.log(1)
        $(obj).css("color",'#000')
        return true
    }else{
        // Do whatever if it fails.
        console.log(2)
        $(obj).css("color",'red')
        return false
    }
  }
  var projectName:any ="";
  

  function getSheetName(id:any){
    switch(id){
      case 117:return "Holiday Exceptions";
      case 118:return "Weekend Exceptions";
      case 119:return "Round Amounts";
      case 121:return "Unbalanced Journals";
      case 122:return "Math Accuracy";
      case 123:return "GL Completenes";
      case 124:return "Journals Without Descriptions";
      case 125:return "User Analysis";
      //case 126:return "Least Used Accounts User";
      case 127:return "Last Day Journals";
      case 128:return "Value Stratification";
      case 129:return "Journals Above CTT";
      //case 130:return "Benford First Two Digits";
      case 145:return "Journals Under CTT";
      case 146:return "Account Analysis";
      //case 147:return "Least Used Accounts";
      case 151:return "Back Dated Entries";
      case 1190:return "Journals";
    }
  }
  const spinner:any = <div className="spinner">
    <img src="../../../assets/images/spinner.gif"></img>
  </div>
  let exportList:any ={
    117:1,
    118:1,
    119:1,
    121:1,
    122:1,
    123:1,
    124:1,
    125:1,
    //126:1,
    127:1,
    128:1,
    129:1,
    130:1,
    145:1,
    146:1,
    //147:1,
    151:1
  }
  let export_journal_id :any = "";


const AR_JETs_Results = (props:any) =>{
    //props.primaryhost;
    //props.host
    const [getState, setState]                                  = useState<any>()
    const [getShowLoading, setShowLoading]                      = useState<boolean>(false)
    const [getLoadingMsg, setLoadingMsg]                        = useState<string>("Loading Data...")
    const [getTestName, setTestName]                            = useState<string>("")
    const [getTestDescriptionDetail,setTestDescriptionDetail]   = useState<string>("")
    const [getResults, setResults]                              = useState<boolean>(true)
    const [getAccountAnalysisTest, setAccountAnalysisTest]      = useState<boolean>(false)
    const [getAccountAnalysisChart, setAccountAnalysisChart]    = useState<HTMLIonColElement>()
    const [getLastDayTest ,setLastDayTest]                      = useState<boolean>(false)
    const [getLastDayChart1 ,setLastDayChart1]                  = useState<HTMLIonColElement>()
    const [getLastDayChart2 ,setLastDayChart2]                  = useState<HTMLIonColElement>()
    const [getMaterialityAboveTest , setMaterialityAboveTest]   = useState<boolean>(false)
    const [getMaterialityAboveChart1 , setMaterialityAboveChart1] = useState<HTMLIonColElement>()
    const [getMaterialityAboveChart2 , setMaterialityAboveChart2] = useState<HTMLIonColElement>()
    const [getMaterialityAboveChart3 , setMaterialityAboveChart3] = useState<HTMLIonColElement>()
    const [getMaterialityAboveChart4 , setMaterialityAboveChart4] = useState<HTMLIonColElement>()
    const [getMaterialityBelowTest , setMaterialityBelowTest]   = useState<boolean>(false)
    const [getMaterialityBelowChart1 , setMaterialityBelowChart1] = useState<HTMLIonColElement>()
    const [getMaterialityBelowChart2 , setMaterialityBelowChart2] = useState<HTMLIonColElement>()
    const [getMaterialityBelowChart3 , setMaterialityBelowChart3] = useState<HTMLIonColElement>()
    const [getMaterialityBelowChart4 , setMaterialityBelowChart4] = useState<HTMLIonColElement>()
    const [getMathTest , setMathTest]                           = useState<boolean>(false)
    const [getMathDebit, setMathDebit]                          = useState<string>("0")
    const [getMathCredit, setMathCredit]                        = useState<string>("0")
    const [getMathDifference, setMathDifference]                = useState<string>("0")
    const [getMathCount, setMathCount]                          = useState<string>("0")
    const [getUnbalancedTest , setUnbalancedTest]               = useState<boolean>(false)
    const [getUserAnalysisTest , setUserAnalysisTest]           = useState<boolean>(false)
    const [getUserAnalysisChart1 , setUserAnalysisChart1]       = useState<HTMLIonColElement>()
    const [getUserAnalysisChart2, setUserAnalysisChart2]        = useState<HTMLIonColElement>()
    const [getValueStratificationTest , setValueStratificationTest]       = useState<boolean>(false)
    const [getValueStratificationChart1 , setValueStratificationChart1]   = useState<boolean>(false)
    const [getWithoutDescriptionTest, setWithoutDescriptionTest]      = useState<boolean>(false)
    const [getWithoutDescriptionChart1, setWithoutDescriptionChart1]      = useState<HTMLIonColElement>()
    const [getGLTest,  setGLTest]                               = useState<boolean>(false)
    const [getGLChart, setGLChart]                              = useState<HTMLIonColElement>()
    const [getBackdatedTest,setBackdatedTest]                   = useState<boolean>(false)
    const [getBackdatedChart1, setBackdatedChart1]              = useState<HTMLIonColElement>()
    const [getBackdatedChart2, setBackdatedChart2]              = useState<HTMLIonColElement>()
    const [getBackdatedChart3, setBackdatedChart3]              = useState<HTMLIonColElement>()
    const [getBenfordTest, setBenfordTest]                      = useState<boolean>(false)
    const [getBenfordChart, setBenfordChart]                    = useState<HTMLIonColElement>()
    const [getHolidayTest, setHolidayTest]                      = useState<boolean>(false)
    const [getHolidayChart1, setHolidayChart1]                  = useState<HTMLIonColElement>()
    const [getHolidayChart2, setHolidayChart2]                  = useState<HTMLIonColElement>()
    const [getHolidayChart3, setHolidayChart3]                  = useState<HTMLIonColElement>()
    const [getRoundedTest, setRoundedTest]                      = useState<boolean>(false)
    const [getRoundedChart1, setRoundedChart1]                  = useState<HTMLIonColElement>()
    const [getRoundedChart2, setRoundedChart2]                  = useState<HTMLIonColElement>()
    const [getWeekendTest,  setWeekendTest]                     = useState<boolean>(false)
    const [getWeekendChart1, setWeekendChart1]                  = useState<HTMLIonColElement>()
    const [getWeekendChart2, setWeekendChart2]                  = useState<HTMLIonColElement>()
    const [getProjectName, setProjectName]                      = useState<string>("")
    
    

    const setChart = (test:any) =>{
        let c:any;
       resetChart()
       switch(test){
        case "results" : setResults(true);break;
        case "rounded": setRoundedTest(true);break;
        case "rounded1": setRoundedTest(true);break;
        case "rounded2": setRoundedTest(true);break;
        case "backdated": setBackdatedTest(true);break;
        case "backdated1": setBackdatedTest(true);break;
        case "backdated2": setBackdatedTest(true);break;
        case "backdated3": setBackdatedTest(true);break;
        case "gl"      : setGLTest(true);      break;
        case "holiday1": setHolidayTest(true); break;
        case "holiday2": setHolidayTest(true); break;
        case "holiday3": setHolidayTest(true); break;
        case "weekend1": setWeekendTest(true); break;
        case "weekend2": setWeekendTest(true); break;
        case "accountanalysis": setAccountAnalysisTest(true);  ; break;
        case "lastday"         : setLastDayTest(true)          ; break;
        case "lastday1"        : setLastDayTest(true);         ; break;
        case "lastday2"        : setLastDayTest(true);         ; break;
        case "abovectt"        : setMaterialityAboveTest(true); ; break;
        case "abovectt1"       : setMaterialityAboveTest(true);; break;
        case "abovectt2"       : setMaterialityAboveTest(true);; break;
        case "abovectt3"       : setMaterialityAboveTest(true);; break;
        case "abovectt4"       : setMaterialityAboveTest(true);; break;
        case "belowctt"        : setMaterialityBelowTest(true) ;; break;
        case "belowctt1"       : setMaterialityBelowTest(true);; break;
        case "belowctt2"       : setMaterialityBelowTest(true);; break;
        case "belowctt3"       : setMaterialityBelowTest(true);; break;
        case "belowctt4"       : setMaterialityBelowTest(true);; break;
        case "unbalanced"     : setUnbalancedTest(true);       ; break;
        case "accountanalysis": setAccountAnalysisTest(true);  ; break;
        case "accountanalysis1": setAccountAnalysisTest(true); ; break;
        case "accountanalysis2": setAccountAnalysisTest(true); ; break;
        case "accountanalysis3": setAccountAnalysisTest(true); ; break;
        case "useranalysis"   : setUserAnalysisTest(true);     ; break;
        case "useranalysis1"   : setUserAnalysisTest(true);     ; break;
        case "useranalysis1"   : setUserAnalysisTest(true);     ; break;
        case "useranalysis1"   : setUserAnalysisTest(true);    ; break;
        case "useranalysis2"   : setUserAnalysisTest(true);    ; break;
        case "useranalysis3"   : setUserAnalysisTest(true);    ; break;
        case "math"   : setMathTest(true);    break;
        case "stratification"   : setValueStratificationTest(true);   break;
        case "benford":setBenfordTest(true);break;
      }
     }
     const resetChart = () =>{
        //setAR(false)
        setValueStratificationTest(false)
        setMathTest(false)
        setBackdatedTest(false)
        setRoundedTest(false)
        setResults(false)
        setGLTest(false);
        setBenfordTest(false)
        setAccountAnalysisTest(false)
        setHolidayTest(false)
        setWeekendTest(false)
        setAccountAnalysisTest(false); 
        setBenfordTest(false);         
        setLastDayTest(false);         
        setMaterialityAboveTest(false);
        setMaterialityBelowTest(false);
        setUnbalancedTest(false);      
        setAccountAnalysisTest(false); 
        setUserAnalysisTest(false);    
     }
     const rdbFilterTest       = useRef<HTMLIonRadioGroupElement>(null);
  
        function filterTestList() {
          var dta = testListData;
             var result = [];
             if( categoryId != 0)
                 result = dta.data.filter(function (item:any) { return item.categoryid == categoryId });
             if( categoryId == 0)
                 result = dta.data.filter(function (item:any) { return item.categoryid == item.categoryid });
             var data = []
             var data1 = []
             data.push(result)
             /*if( categoryId != 0)
                 $(".category-view").html(data[0][0].category)
             if( categoryId == 0)
                 $(".category-view").html("View By Category")
             console.log(data[0])*/
             renderTests(props.migrationId,data[0],false)
         }

        const callJournalProject = (id:any) =>{
        setShowLoading(true)
        fetch(props.primaryhost+"select?etl=selectmigrationmanifesttable"+
        "&migration_id="+id+
        "&isdistinct=1"
        ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            setShowLoading(false)
            testListData = data;
            renderTests(id,data.data, false);
            rdbFilterTest.current!.value = 0;
    
        })
        .catch(error=>{
            alert("call journal project:"+error)
            setShowLoading(false)
        })
        }
        const [getTestList, setTestList]              = useState<HTMLIonItemElement>()
    
        const renderTests = (migrationId:any, data:any, showTable:any) =>{
        setShowLoading(true)
        let d:any = []
        
        data.sort(function(a:any, b:any) {
            if (true) {
                return (a["test_name"] > b["test_name"]) ? 1 : ((a["test_name"] < b["test_name"]) ? -1 : 0);
            } else {
                return (b["test_name"] > a["test_name"]) ? 1 : ((b["test_name"] < a["test_name"]) ? -1 : 0);
            }
        })
        
        d.push(data)
        let tests:any = d[0].map((x:any, i:number)=>{
            if(x.test_name != "null" && include(x.journal_test_id) )
            return(
            <IonCol key={i} size="3">
                <IonCard  className="project-element"  onClick={ev=>{callJournalResultSet(migrationId, x.journal_test_id, false);setLevel(3)}}>
                    <IonLabel className="ptext">{x.test_name}</IonLabel>
                    <IonLabel className="prows">{addCommas(x.rows)}</IonLabel>
                </IonCard>
            </IonCol>
            )
        })
        var c:number = 0;
        let e:any = d[0].map((x:any, i:number)=>{
            
            if(x.test_name != "null" && include(x.journal_test_id) ){
            c++;
            return(
            <IonCol className="card-col-test" key={i} size="4">
                <div  key={i} className="card-outline-gt">
                    <div onClick={e =>{callJournalResultSet(migrationId, x.journal_test_id, false);setLevel(3)}} className={"blue" == "blue"? "card-v-division-gt": "card-v-division-white-left"}>
                        <div className="card-v-division-top">
                        <IonIcon icon={checkmarkCircle} className="icon-gt size-32"></IonIcon> 
                        </div>  
                        <div className={"blue" == "blue"?"card-v-division-bottom size-10":"card-v-division-bottom-white size-10"}>
                        {c}. {x.test_name}
                        <div className="u-line-test"></div>
                        </div>
                    </div>
                    <div className="card-v-division-white size-10">
                        <div className="card-v-right-division-left"><b>Records</b></div><div className="card-v-right-division-right default-highlight"> ({addCommas(x.rows)})</div><br/>
                        <div className="ion-text-left size-10"><span><i>{getTestDescription(x.test_name)}</i></span></div><br/>
                        <div className="u-line"></div> 
                        {x.rows != 0?
                        <div className="card-v-lauch-buttons">
                        <div onClick={e =>{callJournalResultSet(migrationId, x.journal_test_id, true);setLevel(3);showTable1(true)}} className="card-v-right-division-left">
                            <IonIcon icon={gridOutline} color="primary" className="size-8"></IonIcon>&nbsp;<span  className="ptext">View Table</span>&nbsp;
                        </div>
                        <div onClick={e =>{callJournalResultSet(migrationId, x.journal_test_id, false);setLevel(3);;showTable1(false)}} className="card-v-right-division-right">
                            <IonIcon icon={analyticsOutline} color="primary" className="size-8"></IonIcon>&nbsp;<span  className="ptext">View Chart</span>&nbsp;
                        </div>
                        </div>
                        :
                        <div className="card-v-lauch-buttons">
                        <div  className="ion-text-right"><span  className="">No Data Available</span><IonIcon icon={warningSharp} color="danger"  className="size-16"></IonIcon></div>
                        </div>
                        }
                    </div>
                </div>
            </IonCol>
            )}
        })
        //setTestList2(tests)
        setShowLoading(false)
        setTestList2(e)
        setLevel(2)
        
        //callHeavy(migrationId);
        }
        const callHeavy = (migrationId:any, test_id:any) =>{
        setShowLoading(true)
        setLoadingMsg("Gathering Data...")
        fetch(props.primaryhost+"journals/dbconnect/select.jsp?etl=selectjournalheavy"+
            "&migrationid="+migrationId+
            "&journal_test_id="+test_id
            ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            setShowLoading(false)
            console.log(data)
            //console.log(data.data[0].file_path)
            //var JSONfile =  data.data[0].file_path;
            manageHeavy(data)
        })
        .catch(error=>{setShowLoading(false)})
        }
        const manageHeavy = (data:any) =>{
        setShowLoading(true)
        //console.log(data.data)
        let d:any = []
        d.push(data.data[0])
        heavy = data;
        let x:any  = Object.keys(d[0])
        for(var i = 0 ; i < x.length; i++){
            console.log(x[i])
            callHeavyLoader(x[i])
        }
        setShowLoading(false)
        }
        const [hideTable1, showTable1]                              = useState<boolean>(false)
        const [getTestId, setTestId]                                = useState<number>()
        const [getMigrationId, setMigrationId]                      = useState<number>()
        const callJournalResultSet = (migrationId:any, testId:any, showTable:boolean)=>{
        setShowLoading(true)
        fetch(props.primaryhost+"journals/dbconnect/select.jsp?etl=selectjournalheavy"+
            "&migrationid="+migrationId+
            "&journal_test_id="+testId
            ,
            {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        )
        .then(response => response.json())
        .then(data =>{
            
            console.log(data)
            //console.log(data.data[0].file_path)
            //var JSONfile =  data.data[0].file_path;
            manageHeavy(data)
        })
        .then(next =>{
            setShowLoading(false)
            setTestId(testId)
            setMigrationId(migrationId)
            showTable1(showTable)
            var script = "";
            dt = getHeavyData(testId/1)
            switch(testId/1){
            case 117://Holiday
            setTestDescriptionDetail(" - "+tests[16].description) 
            setChart("holiday1")  
            setTestName(tests[16].test)
            if(!showTable){
                
                        
            }
            if(showTable){
                //renderJournalsTableToObject('.holiday', dt, ".table-holiday", ".page-holiday") 
                renderTable(dt, "Holiday Journals Table Data")
            } 
            ;break;
            case 118://weekend
            setTestDescriptionDetail(" - "+tests[15].description)
            setChart("weekend1")
            setTestName(tests[15].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.weekend', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Weekend Journals Table Data")
            }
            ;break;
            case 119://round
            setTestDescriptionDetail(" - "+tests[10].description)
            setChart("rounded")
            setTestName(tests[10].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.rounded', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Rounded Journals Table Data")
            }
            ;break;
            case 121://unbalanced
            setTestDescriptionDetail(" - "+tests[2].description)
            setChart("unbalanced")
            setTestName(tests[2].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.unbalanced', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Unbalanced Journals Table Data")
            }
            ;break;
            case 122://math
            setTestDescriptionDetail(" - "+tests[1].description)
            setTestName(tests[1].test)
            if(!showTable){
                
                setChart("math")
            }
            if(showTable){
                //renderJournalsTableToObject('.math', dt, ".table-holiday", ".page-holiday")
                setChart("math")
            }
            ;break;
            case 123://gl completeness
            setTestDescriptionDetail(" - "+tests[0].description)
            setChart("gl")
            setTestName(tests[0].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.gl', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "GL Completeness Table Data")
            }
            ;break;
            case 124://journals without descriptions
            setTestDescriptionDetail(" - "+tests[3].description)
            setChart("withoutdescription")
            setTestName(tests[3].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.withoutdescription', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Journals Without Description Table Data")
            }
            ;break;
            case 125://most used user
            setTestDescriptionDetail(" - "+tests[12].description)
            setChart("useranalysis")
            setTestName(tests[12].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.useranalysis', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "User Analysis Table Data")
            }
            ;break;
            case 126://least used user
            ;break;
            case 127://last day journals
            setTestDescriptionDetail(" - "+tests[18].description)
            setChart("lastday")
            setTestName(tests[18].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.lastday', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Last Day Journals Table Data")
            }
            ;break;
            case 128://Value Stratification
            setTestDescriptionDetail(" - "+tests[9].description)
            setChart("stratification")
            setTestName(tests[9].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.stratification', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Value Stratification Table Data")
            }
            ;break;
            case 129://above ctt
            setTestDescriptionDetail(" - "+tests[7].description)
            setChart("abovectt")
            setTestName(tests[7].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.abovectt', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Above CTT Table Data")
            }
            ;break;
            case 130://benford
            setTestDescriptionDetail(" - "+tests[11].description)
            setChart("benford")
            setTestName(tests[11].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.benford', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Benford Analysis Data")
            }
            ;break;
            case 145://under ctt
            setTestDescriptionDetail(" - "+tests[8].description)
            setChart("belowctt")
            setTestName(tests[8].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.belowctt', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Below CTT Table Data")
            }
            ;break;
            case 146://most used accounts
            setTestDescriptionDetail(" - "+tests[4].description)
            setChart("accountanalysis")
            setTestName(tests[4].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.accountanalysis', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Account Analysis Table Data")
            }
            ;break;
            case 147://least used accounts
            ;break;
            case 151://Back Dated
            setTestDescriptionDetail(" - "+tests[17].description)
            setChart("backdated")
            setTestName(tests[17].test)
            if(!showTable){
                
                
            }
            if(showTable){
                //renderJournalsTableToObject('.backdated', dt, ".table-holiday", ".page-holiday")
                renderTable(dt, "Back Dated Table Data")
            }
            ;break;
            }
        })
        .catch(error=>{setShowLoading(false)})
        //alert(testId)
        
        
        }
        const callHeavyLoader = (id:any) =>{
            setShowLoading(true)
            switch(id/1){
              case 117://Holiday
                setLoadingMsg("Loading Test Data...")
                if(existsHeavyData(id)){
                  if(existsHeavyData(137))
                  if(existsHeavyDataHasData(137)){
                    var data1 = getHeavyData(137);
                    data1[0].sort(function(a:any, b:any) {
                          if (false) {
                              return (a["debit"] > b["debit"]) ? 1 : ((a["debit"] < b["debit"]) ? -1 : 0);
                          } else {
                              return (b["count"] > a["count"]) ? 1 : ((b["count"] < a["count"]) ? -1 : 0);
                          }
                      });
                      drawChart1(setHolidayChart1, getColumnValueArray(data1[0],"count"), "bar", "Public Holiday Transaction Count", getColumnValueArray(data1[0],"Holiday"),"holiday1")
                  }
                  if(existsHeavyData(138))
                  if(existsHeavyDataHasData(138)){
                    var data2 = getHeavyData(138);
                    data2[0].sort(function(a:any, b:any) {
                          if (false) {
                              return (a["debit"] > b["debit"]) ? 1 : ((a["debit"] < b["debit"]) ? -1 : 0);
                          } else {
                              return (b["debit"] > a["debit"]) ? 1 : ((b["debit"] < a["debit"]) ? -1 : 0);
                          }
                      });
                      drawChart3(setHolidayChart2, getColumnValueArray(data2[0],"debit"), getColumnValueArray(data2[0],"credit"), "bar", "debit", "credit", getColumnValueArray(data2[0],"Holiday"), "holiday2")
                  }
                  if(existsHeavyData(139))
                  if(existsHeavyDataHasData(139)){
                    var data3 = getHeavyData(139);
                      data3[0].sort(function(a:any, b:any) {
                          if (false) {
                              return (a["debit"] > b["debit"]) ? 1 : ((a["debit"] < b["debit"]) ? -1 : 0);
                          } else {
                              return (b["count"] > a["count"]) ? 1 : ((b["count"] < a["count"]) ? -1 : 0);
                          }
                      });
                    drawChart1(setHolidayChart3, getColumnValueArray(data3[0],"count"), "bar", "Public Holiday Transaction User Count ", getColumnValueArray(data3[0],"username"), "holiday3")
                  }
                }else{
          
                }
                
              ;
              break;
              case 118://weekend;
                setLoadingMsg("Loading Test Data...")
                id = 118
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    if(existsHeavyDataHasData(id)){
                      var data = getHeavyData(id);
                    }
                    if(existsHeavyData(142))
                    if(existsHeavyDataHasData(142)){
                      var data1 = getHeavyData(142);
                      data1[0].sort(function(a:any, b:any) {
                            if (false) {
                                return (a["datekey"] > b["datekey"]) ? 1 : ((a["datekey"] < b["datekey"]) ? -1 : 0);
                            } else {
                                return (b["datekey"] > a["datekey"]) ? 1 : ((b["datekey"] < a["datekey"]) ? -1 : 0);
                            }
                        });
                        drawChart(setWeekendChart1,  getSplineArray(data1[0], "datekey","count"), "spline", "Weekend Journal Count", ["Count"],"weekend1")
                    }
                    if(existsHeavyData(140))
                    if(existsHeavyDataHasData(140)){
                      var data2 = getHeavyData(140);
                      data2[0].sort(function(a:any, b:any) {
                            if (false) {
                                return (a["count"] > b["count"]) ? 1 : ((a["count"] < b["count"]) ? -1 : 0);
                            } else {
                                return (b["count"] > a["count"]) ? 1 : ((b["count"] < a["count"]) ? -1 : 0);
                            }
                        });
                      drawChart1(setWeekendChart2, getColumnValueArray(data2[0],"count"), "bar", "Weekend Journal User Count", getColumnValueArray(data2[0],"username"),"weekend2")
                    }
                  }else{
          
                  }
                }
              ;break;
              case 119://round
                setLoadingMsg("Loading Test Data...")
                id = 119
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    if(existsHeavyDataHasData(id)){
                      var data = getHeavyData(id);
                    }
                    if(existsHeavyData(143))
                    if(existsHeavyDataHasData(143)){
                      var data1 = getHeavyData(143);
                      data1[0].sort(function(a:any, b:any) {
                          if (false) {
                                return (a["count"] > b["count"]) ? 1 : ((a["count"] < b["count"]) ? -1 : 0);
                            } else {
                                return (b["count"] > a["count"]) ? 1 : ((b["count"] < a["count"]) ? -1 : 0);
                            }
                        });
                      drawChart1(setRoundedChart1, getColumnValueArray(data1[0],"count"), "bar", "Round Journal User Count", getColumnValueArray(data1[0],"username"),"rounded1")
                    }
                    if(existsHeavyData(144))
                    if(existsHeavyDataHasData(144)){
                      var data2 = getHeavyData(144);
                        data2[0].sort(function(a:any, b:any) {
                          if (false) {
                              return (a["count"] > b["count"]) ? 1 : ((a["count"] < b["count"]) ? -1 : 0);
                            } else {
                                return (b["count"] > a["count"]) ? 1 : ((b["count"] < a["count"]) ? -1 : 0);
                            }
                        });
                      drawChart1(setRoundedChart2, getColumnValueArray(data2[0],"count"), "bar", "Round Journal Account Count", getColumnValueArray(data2[0],"Account Description"),"rounded2")
                    }
                  }else{
          
                  }
                }
                
              ;break;
              case 121://unbalanced
                setLoadingMsg("Loading Test Data...")
                id = 121
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    var data = getHeavyData(id);
                    console.log(data);
                    //renderJournalsTableToObject($(".table-unbalanced"), data[0])
                  }else{
          
                  }
                }
                
              ;break;
              case 122: 	//math
                setLoadingMsg("Loading Test Data...")
                id = 122
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    if(existsHeavyDataHasData(id)){
                      var data = getHeavyData(id);
                      setMathDebit(addCommas(sumColumn(data[0],"debit")))
                      setMathCredit(addCommas(sumColumn(data[0],"credit")))
                      setMathDifference(addCommas(sumColumn(data[0],"debit")/1-sumColumn(data[0],"credit")/1))
                      setMathCount(addCommas(sumColumn(data[0],"Number Of Jounals")/1))
                      //$(".math-debit").html(addCommas(sumColumn(data[0],"debit")));
                      //$(".math-credit").html(addCommas(sumColumn(data[0],"credit")));
                      //$(".math-difference").html(addCommas(sumColumn(data[0],"debit")/1-sumColumn(data[0],"credit")/1));
                      //$(".math-records").html(sumColumn(data[0],"Number Of Jounals"))
                    }
                    if(existsHeavyData(150))
                    if(existsHeavyDataHasData(150)){
                      var data1 = getHeavyData(150);
                    }
          
                    //renderJournalsTableToObject($(".table-math"), data1[0])
                  }else{
          
                  }
                }
          
              ;break;
              case 123: 	//gl completenes
              console.log("Loading GL Test Data...")
                id = 123
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    var data = getHeavyData(id);
                    //$(".gl-variance").html(addCommas(sumColumn(data[0],"Movement Difference").toFixed(2)));
                    drawChart6(
                      setGLChart, 
                      getColumnValueArray(data[0],"Difference"), 
                      "bar", 
                      "Difference By Account", 
                      getColumnValueArray(data[0],"Account Description"),
                      "gl"
                    )
                    //$(".heavy-progress").css({'width':'30%'})
                  }else{
          
                  }
                }
              ;break;
              case 124://journals without descriptions
              setLoadingMsg("Loading Test Data...")
                id =  124
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    var data = getHeavyData(id);
                    //var data1 = getHeavyData(162)
                    //console.log(data1);	
                    // data1[0].sort(function(a, b) {
                      //    if (false) {
                      //        return (a["count"] > b["count"]) ? 1 : ((a["count"] < b["count"]) ? -1 : 0);
                      //    } else {
                      //        return (b["count"] > a["count"]) ? 1 : ((b["count"] < a["count"]) ? -1 : 0);
                      //    }
                      //});
                    //drawChart1(setWithoutDescriptionChart1, getColumnValueArray(data1[0],"count"), "bar", "Count Journals With No Description By User", getColumnValueArray(data1[0],"User"),"withoutdescription")
                    drawChart3(setWithoutDescriptionChart1, getColumnValueArray(data[0],"Debit"), getColumnValueArray(data[0],"Credit"), "bar", "Debit", "Credit", getColumnValueArray(data[0],"User"), "withoutdescription")
                  }else{
          
                  }
                }
                
              ;break;
              case 125://most used user
              setLoadingMsg("Loading Test Data...")
                id = 125
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    var data = getHeavyData(id);
                    console.log(data);
                    var dd:any = data[0]
                    var da:any = []
                    for(var i = 0; i < dd.length; i++){
                      if(dd[i]["Transaction Type"] == "Manual")
                        da.push(dd[i]["Transaction Count"])
                    }
                    
                    var un:any = []
                    for(var i = 0; i < dd.length; i++){
                      if(dd[i]["Transaction Type"] == "Manual")
                        un.push(dd[i]["User Name"])
                    }
      
                    var dav:any = []
                    for(var i = 0; i < dd.length; i++){
                      if(dd[i]["Transaction Type"] == "Manual")
                        dav.push(dd[i]["Debit"])
                    }
                    
                    var unv:any = []
                    for(var i = 0; i < dd.length; i++){
                      if(dd[i]["Transaction Type"] == "Manual")
                        unv.push(dd[i]["User Name"])
                    }
      
                    drawChart1(setUserAnalysisChart1, da, "bar", "User Analysis - Manual Journals Count", un, "useraccountanalysis1")
      
                    drawChart1(setUserAnalysisChart2, dav, "bar", "User Analysis - Manual Journals Debit Value", unv, "useraccountanalysis2")
                  }else{
          
                  }
                }
                
              ;break;
              case 126://least used user
                id = 126
                if(existsHeavyData(id)){
                  var data = getHeavyData(id);
                  console.log(data);
                  //drawChart1($(".journal-user-least-used"), getColumnValueArray(data[0],"Number of Journals"), "bar", "Least Used Journals By User Bottom(12)", getColumnValueArray(data[0],"User"))
                }
                
              ;break;
              case 127://last day journals
              setLoadingMsg("Loading Test Data...")
                id = 127
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    if(existsHeavyData(148))
                    if(existsHeavyDataHasData(148)){
                      var data1 = getHeavyData(148);
                      data1[0].sort(function(a:any, b:any) {
                            if (false) {
                                return (a["count"] > b["count"]) ? 1 : ((a["count"] < b["count"]) ? -1 : 0);
                            } else {
                                return (b["count"] > a["count"]) ? 1 : ((b["count"] < a["count"]) ? -1 : 0);
                            }
                        });
                      drawChart1(setLastDayChart1, getColumnValueArray(data1[0],"count"), "bar", "Last Day Journals By User Count", getColumnValueArray(data1[0],"User"),"lastday1")
                    }
                    if(existsHeavyData(149))
                    if(existsHeavyDataHasData(149)){
                      var data2 = getHeavyData(149);
                      drawChart1(setLastDayChart2, getColumnValueArray(data2[0],"count"), "bar", "Last Day Journals By Account Count", getColumnValueArray(data2[0],"account_description"), "lastday2")
                    }
                  }else{
          
                  }
                }
                
              ;break;
              case 128://Value Stratification
              setLoadingMsg("Loading Test Data...")
                id = 128
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    var data = getHeavyData(id);
                    console.log(data)
                    drawChart1(
                      setValueStratificationChart1, 
                      getColumnValueArray(data[0],"Number of Journals"), 
                      "column", 
                      "Value Stratification", 
                      getColumnValueArray(data[0],"Value Band"),
                      "stratification"
                    )
                  }else{
          
                  }
                }
              ;break;
              case 129://above ctt
              setLoadingMsg("Loading Test Data...")
                id = 129
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    if(existsHeavyData(131))
                    if(existsHeavyDataHasData(131)){
                      var data1 = getHeavyData(131);
                      data1[0].sort(function(a:any, b:any) {
                            if (false) {
                                return (a["count"] > b["count"]) ? 1 : ((a["count"] < b["count"]) ? -1 : 0);
                            } else {
                                return (b["datekey"] > a["datekey"]) ? 1 : ((b["datekey"] < a["datekey"]) ? -1 : 0);
                            }
                        });
                      drawChart(setMaterialityAboveChart1,  getSplineArray(data1[0], "datekey","count"), "spline", "Transaction Count Above Materiality", ["Count"], "abovectt1")
                      drawChart2(setMaterialityAboveChart2, getSplineArray(data1[0], "datekey","debit"), getSplineArray(data1[0], "datekey","credit"), "areaspline", "Debit", "Credit","#7CB5EC","9DC8F1", "abovectt2")
                    }
                    if(existsHeavyData(133))
                    if(existsHeavyDataHasData(133)){
                        var data3 = getHeavyData(133);
                      data3[0].sort(function(a:any, b:any) {
                            if (false) {
                                return (a["count"] > b["count"]) ? 1 : ((a["count"] < b["count"]) ? -1 : 0);
                            } else {
                                return (b["count"] > a["count"]) ? 1 : ((b["count"] < a["count"]) ? -1 : 0);
                            }
                        });
                      drawChart1(setMaterialityAboveChart3, getColumnValueArray(data3[0],"count"), "bar", "User Transactions Above Materiality", getColumnValueArray(data3[0],"username"), "abovectt3")
                    }
                    if(existsHeavyData(135))
                    if(existsHeavyDataHasData(135)){
                        var data5 = getHeavyData(135);
                        data5[0].sort(function(a:any, b:any) {
                            if (false) {
                                return (a["debit"] > b["debit"]) ? 1 : ((a["debit"] < b["debit"]) ? -1 : 0);
                            } else {
                                return (b["debit"] > a["debit"]) ? 1 : ((b["debit"] < a["debit"]) ? -1 : 0);
                            }
                        });
                      drawChart3(setMaterialityAboveChart4, getColumnValueArray(data5[0],"debit"), getColumnValueArray(data5[0],"credit"), "bar", "debit", "credit", getColumnValueArray(data5[0],"username"), "abovectt4")
                    }
          
          
                  }else{
          
                  }
                }
                
              ;break;
              case 130://benford
              setLoadingMsg("Loading Test Data...")
                id = 130
                
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    var data = getHeavyData(id);
                    data[0].sort(function(a:any, b:any) {
                          if (true) {
                              return (a["First_2_Digits"] > b["First_2_Digits"]) ? 1 : ((a["First_2_Digits"] < b["First_2_Digits"]) ? -1 : 0);
                          } else {
                              return (b["count"] > a["count"]) ? 1 : ((b["count"] < a["count"]) ? -1 : 0);
                          }
                      });
                    drawChart5(setBenfordChart, 
                      getColumnValueArray(data[0],"Expected_Count"), 
                      getColumnValueArray(data[0],"Actual_Count"), 
                      getColumnValueArray(data[0],"Upper_Bound"),
                      getColumnValueArray(data[0],"Lower_Bound"),
                      "column", 
                      "spline",
                      "spline",
                      "spline", 
                      "Expected Count",
                      "Actual Count",
                      "Upper Bound",
                      "Lower Bound",
                      getColumnValueArray(data[0],"First_2_Digits"),
                      "benford"
                    )
                    
                  }else{
          
                  }
                }
                
              ;break;
              case 145://under ctt
              setLoadingMsg("Loading Test Data...")
                id = 145
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    if(existsHeavyData(132))
                    if(existsHeavyDataHasData(132)){
                      var data2 = getHeavyData(132);
                      drawChart(setMaterialityBelowChart1,  getSplineArray(data2[0], "datekey","count"), "spline", "Transaction Count Below Materiality", ["Count"], "belowctt1")
                      drawChart2(setMaterialityBelowChart2,getSplineArray(data2[0], "datekey","debit"), getSplineArray(data2[0], "datekey","credit"), "areaspline", "Debit", "Credit","#7CB5EC","9DC8F1", "below2ctt")
                    }
                    if(existsHeavyData(134))
                    if(existsHeavyDataHasData(134)){
                      var data4 = getHeavyData(134);
                      data4[0].sort(function(a:any, b:any) {
                            if (false) {
                                return (a["count"] > b["count"]) ? 1 : ((a["count"] < b["count"]) ? -1 : 0);
                            } else {
                                return (b["count"] > a["count"]) ? 1 : ((b["count"] < a["count"]) ? -1 : 0);
                            }
                        });
                      drawChart1(setMaterialityBelowChart3, getColumnValueArray(data4[0],"count"), "bar", "User Transactions Below Materiality", getColumnValueArray(data4[0],"username"), "belowctt3")
                    }
                    if(existsHeavyData(136))
                    if(existsHeavyDataHasData(136)){
                      var data6 = getHeavyData(136);
                      data6[0].sort(function(a:any, b:any) {
                            if (false) {
                                return (a["debit"] > b["debit"]) ? 1 : ((a["debit"] < b["debit"]) ? -1 : 0);
                            } else {
                                return (b["debit"] > a["debit"]) ? 1 : ((b["debit"] < a["debit"]) ? -1 : 0);
                            }
                        });
                      drawChart3(setMaterialityBelowChart4, getColumnValueArray(data6[0],"debit"), getColumnValueArray(data6[0],"credit"), "bar", "debit", "credit", getColumnValueArray(data6[0],"username"), "belowctt4")
                    }
                              
                  }else{
          
                  }
                }
              ;break;
              case 146://most used accounts
              setLoadingMsg("Loading Test Data...")
                id = 146
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    var data  = getHeavyData(id);
                    console.log(data)
                    drawChart1(setAccountAnalysisChart, getColumnValueArray(data[0],"Manual Count"), "column", "Accounts Analysis Manual Count", getColumnValueArray(data[0],"Account Description"), "accountanalysis")
                  
                  }else{
          
                  }
                }
                
              ;break;
              case 147://least used accounts
                id = 147
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    var data  = getHeavyData(id);
                    console.log(data)
                    //drawChart1($(".journal-least-used-account-chart"), getColumnValueArray(data[0],"Number of Journals"), "bar", "Least Used Accounts Count", getColumnValueArray(data[0],"account_description"))
                  }else{
          
                  }
                }
                
              ;break;	
              case 151://Back Dated
              setLoadingMsg("Loading Test Data...")
                id = 151
                if(existsHeavyData(id)){
                  if(existsHeavyDataHasData(id)){
                    if(existsHeavyData(159))
                    if(existsHeavyDataHasData(159)){
                      var data2 = getHeavyData(159)
                      data2[0].sort(function(a:any, b:any) {
                            if (true) {
                                return (a["days"] > b["days"]) ? 1 : ((a["days"] < b["days"]) ? -1 : 0);
                            } else {
                                return (b["Number of Days backdated"] > a["Number of Days backdated"]) ? 1 : ((b["Number of Days backdated"] < a["Number of Days backdated"]) ? -1 : 0);
                            }
                        });
                      drawChart1(setBackdatedChart1,  getSplineArray(data2[0], "days","transactions"), "spline", "Transaction Count By Back Dated Days", ["transactions"], "backdated1")
                    }
                    if(existsHeavyData(160))
                    if(existsHeavyDataHasData(160)){
                      var data3 = getHeavyData(160)
                        data3[0].sort(function(a:any, b:any) {
                            if (false) {
                                return (a["transactions"] > b["days"]) ? 1 : ((a["days"] < b["days"]) ? -1 : 0);
                            } else {
                                return (b["transactions"] > a["transactions"]) ? 1 : ((b["transactions"] < a["transactions"]) ? -1 : 0);
                            }
                        });
                        drawChart1(setBackdatedChart2, getColumnValueArray(data3[0],"transactions"), "bar", "User Transactions Count", getColumnValueArray(data3[0],"User"), "backdated2")
                    }
                    if(existsHeavyData(id))
                      if(existsHeavyDataHasData(161)){
                        var data4 = getHeavyData(161)
                        data4[0].sort(function(a:any, b:any) {
                            if (true) {
                                return (a["yearmonthkey"] > b["yearmonthkey"]) ? 1 : ((a["yearmonthkey"] < b["yearmonthkey"]) ? -1 : 0);
                            } else {
                                return (b["transactions"] > a["transactions"]) ? 1 : ((b["transactions"] < a["transactions"]) ? -1 : 0);
                            }
                        });
                      drawChart1(setBackdatedChart3, getColumnValueArray(data4[0],"transactions"), "column", "Monthly Transactions Count", getColumnValueArray(data4[0],"monthname"), "backdated3")
                    }
                    
                  }else{
          
                  }
                }
                
              ;break;
              
            }/**/
            setShowLoading(false)
          }
          function drawChart5(obj:any,data1:any, data2:any, data3:any, data4:any, type1:any, type2:any, type3:any, type4:any, name1:any, name2:any, name3:any, name4:any, xAxis:any, chartName:any){
            //alert("benford loaded")
            var chart:any = {
                 title:{
                   text:''
               },
               subTitle:{
                   text:''
               },
               xAxis: {
                   categories:xAxis
               },
               plotOptions: {
                   series: {
                       cursor: 'pointer',
                       point: {
                           events: {
                               click: function () {
                                   //alert('Category: ' + this.category + ', value: ' + this.y);
                                   //getFilteredData(obj, this.category)
                               }
                           }
                       }
                   }
               },
                 series: [{
                         name: name1,
                         type: type1,
                         data: data1
                     },{
                         name: name2,
                         type: type2,
                         data: data2
                     },{
                         name: name3,
                         type: type3,
                         data: data3
                     },{
                         name: name4,
                         type: type4,
                         data: data4
                     }
                 ]
             };
            let c:any = (<HighchartsReact highcharts={Highcharts} options={chart}></HighchartsReact>)
            if(chartName == "benford"){
              setChart("benford")
              obj(c)
            }
         }
         function drawChart6(obj:any,data:any, type:any, name:any, xAxis:any,chartName:any){
         var chart ={
               chart: {
                   type: type
               }, 
               title:{
                 text:''
             },
             subTitle:{
                 text:''
             },
             xAxis: {
                 categories:xAxis
             },
               series: [{
                   name: name,
                   data: data
               }]
           }
           let c:any = (<HighchartsReact highcharts={Highcharts} options={chart}></HighchartsReact>)
            if(chartName == "gl"){
              setChart("gl")
              obj(c)
            }
        }
        function drawChart(obj:any,data:any, type:any, name:any, xAxis:any, chartName:any){
          let chart:any ={
               chart: {
                   type: type
               }, 
               title:{
                 text:''
             },
             subTitle:{
                 text:''
             },
             xAxis: {
                 type:'datetime',
             },
               series: [{
                   name: name,
                   data: data
               }]
           };
           let c:any = (<HighchartsReact highcharts={Highcharts} options={chart}></HighchartsReact>)
           if(chartName == "weekend1"){
            setChart("weekend1")
            obj(c)
          }
          if(chartName == "abovectt1"){
            setChart("abovectt1")
            obj(c)
          }
          if(chartName == "belowctt1"){
            setChart("belowctt1")
            obj(c)
          }
          
       }
       
       function drawChart1(obj:any,data:any, type:any, name:any, xAxis:any, chartName:any){
          let chart:any ={
               chart: {
                   type: type
               }, 
               title:{
                 text:''
             },
             subTitle:{
                 text:''
             },
             xAxis: {
                 categories:xAxis,
                 allowDecimals: false
             },
             yAxis: {
                 allowDecimals: false
             },
               series: [{
                   name: name,
                   data: data
               }]
           }
          let c:any = (<HighchartsReact highcharts={Highcharts} options={chart}></HighchartsReact>)
          if(chartName == "holiday1"){
            setChart("holiday1")
            obj(c)
          }
          if(chartName == "weekend2"){
            setChart("weekend2")
            obj(c)
          }
          if(chartName == "holiday3"){
            setChart("holiday3")
            obj(c)
          }
          if(chartName == "backdated1"){
            setChart("backdated1")
            obj(c)
          }
          if(chartName == "backdated2"){
            setChart("backdated2")
            obj(c)
          }
          if(chartName == "backdated3"){
            setChart("backdated3")
            obj(c)
          }
          if(chartName == "rounded1"){
            setChart("rounded1")
            obj(c)
          }
          if(chartName == "rounded2"){
            setChart("rounded2")
            obj(c)
          }
          if(chartName == "lastday1"){
            setChart("lastday1")
            obj(c)
          }
          if(chartName == "lastday2"){
            setChart("lastday2")
            obj(c)
          }
          if(chartName == "stratification"){
            setChart("stratification")
            obj(c)
          }
          if(chartName == "useraccountanalysis1"){
            setChart("useraccountanalysis2")
            obj(c)
          }
          if(chartName == "useraccountanalysis2"){
            setChart("useraccountanalysis2")
            obj(c)
          }
          obj(c)
       }
       function drawChart3(obj:any,data1:any, data2:any,type:any, name1:any, name2:any, xAxis:any, chartName:any){
        let chart = {
             chart: {
                 type: type
             }, 
             title:{
               text:''
           },
           subTitle:{
               text:''
           },
           xAxis: {
               categories:xAxis
           },
             series: [{
                     name: name1,
                     data: data1
                 },{
                     name: name2,
                     data: data2
                 }
             ]
          };
          let c:any = (<HighchartsReact highcharts={Highcharts} options={chart}></HighchartsReact>)
          if(chartName == "benford"){
            setChart("benford")
            obj(c)
          }
          if(chartName == "abovectt4"){
            setChart("abovectt4")
            obj(c)
          }
          if(chartName == "belowctt4"){
            setChart("belowctt4")
            obj(c)
          }
          if(chartName == "holiday2"){
            setChart("holiday2")
            obj(c)
          }
          if(chartName == "withoutdescription"){
            setChart("withoutdescription")
            obj(c)
          }
      }
      function drawChart2(obj:any,data1:any, data2:any, type:any, name1:any, name2:any,color1:any,color2:any, chartName:any){
       let chart:any = {
             chart: {
                 type: type
             }, 
             title:{
               text:''
           },
           subTitle:{
               text:''
           },
           xAxis: {
               type:'datetime',
           },
             series: [{
                     name: name1,
                     data: data1,
                     color: color1
                 }, {
                     name: name2,
                     type: type,
                     data: data2,
                     color:color2
                 }]
        };
        let c:any = (<HighchartsReact highcharts={Highcharts} options={chart}></HighchartsReact>)
        obj(c)
      }
      
        const [getLogin, setLogin]                = useState<boolean>(true);
    const [getLoginAuto, setLoginAuto]        = useState<boolean>(false);
    const [hideLogin, showLogin]              = useState<boolean>(false);
    const [hideMainPage, showMainPage]        = useState<boolean>(false);
    const [hideSettings, showSettings]        = useState<boolean>(false);
    const [hideDashboard, showDashBoard]      = useState<boolean>(false);
    const [hideFilters, showFilters]          = useState<boolean>(false);
    const [hideFilter, showFilter]            = useState<boolean>(false);
    const [getProjectList, setProjectList]    = useState<HTMLIonColElement>();
    const [hideProjectList, showProjectList]  = useState<boolean>(false);
    //const [getConfigTest, setConfigTest]    = useState<HTMLIonColElement>()
    const [hideConfigTest, showConfigTest]    = useState<boolean>(false);
    const [getShareList, setShareList]        = useState<HTMLIonColElement>();
    const [hideShareList, showShareList]      = useState<boolean>(false);
    const [hideMessages, showMessages]        = useState<boolean>(false);
    const [hideDataIntegrity, showDataIntegrity]    = useState<boolean>(false);
    const [hidePrintList, showPrintList]            = useState<boolean>(false);
    const [getPrintList, setPrintList]              = useState<HTMLIonColElement>();
    const [hideDeleteProject, showDeleteProject]    = useState<boolean>(false);
    const [hideApproveProject, showApproveProject]  = useState<boolean>(false);
    const [hideApproval, showApproval]        = useState<boolean>(true);
    const [hideDeclining, showDeclining]      = useState<boolean>(false);
    const [getTestList2, setTestList2]        = useState<HTMLIonColElement>();
    const [hideTestList2, showTestList2]      = useState<boolean>(false);
    const [getTestResults, setTestResults]    = useState<HTMLIonColElement>();
    const [hideTestResults, showTestResults]  = useState<boolean>(false);
    const [getAR,setAR]                       = useState<HTMLIonColElement>();
    const  getContent  =() => {
        return document.querySelector('ion-content');
      }
    
    function scrollToTop() {
        getContent()!.scrollToTop(500);
      }
      const shareJournalProject = (id:any) =>{
       
        fetch(props.primaryhost+"/journals/dbconnect/select.jsp?etl=selectjournalprojectcontacts"+
          "&migration_id="+id+
          "&contactid"+props.userId,
          {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
          }
        )
        .then(response => response.json())
        .then(data =>{
          let rt:any = null
          setTable(rt)
          renderShareTable(data.data,"")
          
        })
        .catch(error =>{
          alert(error +": Get Journal Contacts")
        })
      }
      let txtShareEmail                           = useRef<HTMLIonInputElement>(null);
      let txtShareEmailSearch                     = useRef<HTMLIonSearchbarElement>(null);
      let txtShareForename                        = useRef<HTMLIonInputElement>(null);
      let txtShareSurname                         = useRef<HTMLIonInputElement>(null);
      let ddlShareDesignation                     = useRef<HTMLIonSelectElement>(null)
      const [checkEmail, setEmail]                = useState<boolean>(false)
      const [checkForename, setForename]          = useState<boolean>(false)
      const [checkSurname, setSurname]            = useState<boolean>(false)
      const [checkDesignation, setDesignation]    = useState<boolean>(false)
      const [hideSearchDetail, showSearchDetail]  = useState<boolean>(true)
      const [hideSearchDesignation, showSearchDesignation] = useState<boolean>(false)
      const [getSearchList, setSearchList]        = useState<HTMLIonItemElement>()
      const resetJournalContacts = () =>{
        if(ddlShareDesignation.current != null)
          ddlShareDesignation.current!.value = null
        if(txtShareForename.current! != null)
          txtShareForename.current!.value! = "";
        if(txtShareSurname.current! != null)
          txtShareSurname.current!.value! = "";
        if(txtShareEmail.current! != null)
          txtShareEmail.current!.value! = "";
        if(txtShareEmailSearch.current! != null)
          txtShareEmailSearch.current!.value! = "";
      }
    const setLevel =(l:any)=>{
            scrollToTop()
            resetLevel()
            switch(l){
              case 1: showProjectList(true);break;
              case 2: showTestList2(true);break;
              case 3: showTestResults(true);break;
              case 4: showConfigTest(true);break;
              case 5: showShareList(true);resetJournalContacts();shareJournalProject(props.migrationId) ;break;
              case 6: showDeleteProject(true);break;
              case 7: showApproveProject(true);break;
              case 8: showMessages(true);break;
              case 9: showDataIntegrity(true);break;
              case 10: showPrintList(true);break;
            }
          }
          const resetLevel = () =>{
            showMessages(false);
            showApproveProject(false);
            showProjectList(false) ;
            showTestList2(false)   ;
            showTestResults(false) ;
            showConfigTest(false);
            showShareList(false) ;
            showDeleteProject(false);
            showDataIntegrity(false);
            showPrintList(false);
          }
        const include =(testId:any)=>{
            let list:any = [117,118,119,121,122,123,124,125,126,127,128,129,130,145,146,147,151]
            for(var i = 0 ; i < list.length;i++){
                if(testId == list[i]){
                    return true;
                }
            }
            return false;
        }
        const [getTable, setTable]        = useState<HTMLIonColElement>()
        const [getTitle, setTitle]        = useState<string>("")
        const renderShareTable = (data:any, title:any) =>{
        setTitle(getTitle)
        let tableData :any =data
        let prop:any;
        console.log(data[0])
        const column:any=[];
        let x:number =0
        for(prop in data[0]){
            console.log(prop)
            if( prop != 'id' && prop != 'versionid' && prop != 'row' && prop != 'reporttypeid' && prop != 'id_1' && prop != 'row_1' && prop != 'rows' && prop != 'total_rows' && prop != 'current_rows' && prop != 'next_page' && prop != 'previous_page' && prop != 'max_page'  && prop != 'order_by' ){
            if(x == -1)
            column.push({
                accessor:prop,
                Header:prop,
                Cell:(prop:any) => (
                <input type="checkbox"
                onClick={(e:any) => {
                    alert(prop)
                }}
                checked={false}
                />
            )
            })
            if(x >=0)
            column.push({
                accessor:prop,
                Header:prop,
                //className: "sticky",
                //headerClassName: "sticky",
                
            })
            x++;
            }
        }
        console.log(column)
        console.log(tableData)
        }
        const renderTable = (data:any, title:any) =>{
        setTitle(getTitle)
        
        let rt:any =  <Basic data={data} />
        setTable(rt);
        }
        function renderJournalsTableToObject(obj:any, data:any, table_obj:any, pager_obj:any){
        console.log("rendering...:"+obj)
        
        $(obj).html("")	
        var $table = $("<table></table>").addClass("report-table").addClass("striped").css("table-layout","fixed").css("height","400px").css("overflow-x","none").css("width","100%")
        var thead = $("<thead></thead>").addClass("white")
        var tbody = $("<tbody></tbody>").css("overflow-x","auto").css("height","400px")
        
        
        for(var i = 0; i < data[0].length; i++){
            var tr = $("<tr></tr>")
            if(i == 0){
            let prop:any;
            //console.log(data)
            for(prop in data[0][i]){
                if(prop != 'id' && prop != 'versionid' && prop != 'row' && prop != 'reporttypeid' && prop != 'id_1' && prop != 'row_1' && prop != 'rows' && prop != 'total_rows' && prop != 'current_rows' && prop != 'next_page' && prop != 'previous_page' && prop != 'max_page'  && prop != 'order_by' ){
                var th = $("<th></th>")
                //th = th.attr("width","168")
                th = th.css("border-top","5px solid")
                th = th.css("border-right","3px solid")
                th = th.css("border-color","#4CAF50 #888 #888 #000")
                th = th.css("padding","0px")
                //th = th.css("padding-left","0px")
                th = th.html("<div class='pull-left col-md-12 truncate valign-wrapper' title='"+prop+"' onclick='' style='word-wrap: break-word;cursor:pointer; text-align:left'>"+
                        "<div class='pull-left truncate ' style='margin-top:5%; width:85%'> "+prop+"</div><div class='pull-right center ' style='width:10%'><div class='center' style='width:100%'><i class='material-icons' title='"+prop+"' onclick=filterJournalTable(1,this,'"+table_obj+"~"+pager_obj+"')></i></div><div class='center' style='width:100%'><i class='material-icons' title='"+prop+"' onclick=filterJournalTable(0,this,'"+table_obj+"~"+pager_obj+"')></i></div></div>")
                tr.append(th)
                }
            }
            thead.append(tr)
            $table.append(thead)
            }
            
            
            var tr = $("<tr></tr>")
            var xx =1;
            let prop:any;
            //console.log(data[0][i])
            
            for(prop in data[0][i]){
            //console.log(prop)
            if(prop != 'id' && prop != 'versionid' && prop != 'row' && prop != 'reporttypeid' && prop != 'id_1' && prop != 'row_1' && prop != 'rows' && prop != 'total_rows' && prop != 'current_rows' && prop != 'next_page' && prop != 'previous_page' && prop != 'max_page'  && prop != 'order_by'){
                var td = $("<td></td>")
                td = td.css("border-right","3px solid")
                td = td.css("border-color","#888")
                td = td.css("font-size","1em")
                td = td.css("height","35px")
                td = td.css("color","#333") 
                td = td.css("color","#333")
                td = td.css("word-wrap", "break-word")
                td = td.css("padding","3px")
                td = td.addClass("row_"+(i+1))
                if(isNumber(data[0][i][prop]) && prop != "Account Number" && prop != "Journal Number"){
                td = td.css("text-align","right")
                td = td.html((addCommas(data[0][i][prop])))
                }else{
                td = td.css("text-align","left")
                td = td.html((data[0][i][prop]))
                }
                
                tr.append(td)
                tbody.append(tr)
            }
            xx++
            }
            
            $table.append(tbody)
            console.log($table)
            //$(".pagenation").show()
            //$(".header-row-selection").show()
            //$(".show-hidden").show()
        }
        //selectShowHiddenColumns()
        console.log($table)
        $(obj).append($table)	
        console.log("rendering...ended-->"+obj)
        $(obj).html("rendering...ended")
        console.log($(obj).is(":visible"))
        $(".accountanalysis").html("See Me")
        //$($table).css("padding-bottom","5%");
        //$table.floatThead();
        //$table.css('marginTop', 0);
        }  
        
        const [hideReviews,showReviews]                               = useState<boolean>(true)
        const [getDetailChange, setDetailChange]                      = useState<boolean>(true)
        const [getOpeningTB,setOpeningTB]                             = useState<boolean>(false)
        const [getClosingTB,setClosingTB]                             = useState<boolean>(false)
        const [getGeneralLedger,setGeneralLedger]                     = useState<boolean>(false)
        const [hideFileLoader, showFileLoader]                        = useState<boolean>(false)
        const [getAPIConfig, setAPIConfig]                            = useState<boolean>(false)
        const [getFileLoader,setFileLoader]                           = useState<boolean>(false)
        const [getNotes,setNotes]                                      = useState<boolean>(false)
        const [getTest0 ,setTest0 ]                                   = useState<boolean>(false)
        const [getTest00 ,setTest00 ]                                 = useState<boolean>(false)
        const [getTest1 ,setTest1 ]                                   = useState<boolean>(false)
        const [getTest2 ,setTest2 ]                                   = useState<boolean>(false)
        const [getTest3 ,setTest3 ]                                   = useState<boolean>(false)
        const [getTest4 ,setTest4 ]                                   = useState<boolean>(false)
        const [getTest5 ,setTest5 ]                                   = useState<boolean>(false)
        const [getTest6 ,setTest6 ]                                   = useState<boolean>(false)
        const [getTest7 ,setTest7 ]                                   = useState<boolean>(false)
        const [getTest8 ,setTest8 ]                                   = useState<boolean>(false)
        const [getTest9 ,setTest9 ]                                   = useState<boolean>(false)
        const [getTest10,setTest10]                                   = useState<boolean>(false)
        const [getTest11,setTest11]                                   = useState<boolean>(false)
        const [getTest12,setTest12]                                   = useState<boolean>(false)
        const [getTest13,setTest13]                                   = useState<boolean>(false)
        const [getTest14,setTest14]                                   = useState<boolean>(false)
        const [getTest15,setTest15]                                   = useState<boolean>(false)
        const [getTest16,setTest16]                                   = useState<boolean>(false)
        const [getTest17,setTest17]                                   = useState<boolean>(false)
        const [getTest18,setTest18]                                   = useState<boolean>(false)
        const [getTest19,setTest19]                                   = useState<boolean>(false)
      
    React.useEffect(() => {
      if(setState != props.migrationId  || categoryId != props.categoryId){
        callJournalProject(props.migrationId)
        setState(props.migrationId)
      }
    }, [props.migrationId, props.categoryId]);    
    return(
        <div>
          {!hideTestList2 &&
         <IonRow className="nav-beam-main-jets-ar" >
            <IonCol className="ion-text-left text-black">
                {hideTable1 &&
                <div onClick={ev=>{callJournalResultSet(getMigrationId,getTestId,false)}} className="ptextn text-black">
                <IonIcon icon={analyticsOutline} className="size-20"></IonIcon>&nbsp;
                View Charts
                </div>
                }
                {!hideTable1 &&
                <div onClick={ev=>{callJournalResultSet(getMigrationId,getTestId,true)}} className="ptextn text-black">
                <IonIcon icon={gridOutline} className="size-20"></IonIcon>&nbsp;
                View Table
                </div>
                }
            </IonCol>
            <IonCol onClick={()=>{setLevel(2)}} className="ion-text-right text-black">
              <IonIcon icon={returnUpBack} className="size-20"></IonIcon>&nbsp;
              Back To Test Results 
            </IonCol>
        </IonRow>
        }
        <IonRow>
            {hideTestList2 &&
                <div className="nav-project-ar">
                <IonRadioGroup ref={rdbFilterTest} onClick={ev=>{categoryId = ev.currentTarget.value;filterTestList()}}>
                    <IonRow className="">
                    <IonCol className="project-element">
                        <IonItem>
                        <IonRadio value="0"></IonRadio>&nbsp;
                        <IonLabel className="ptext">All Categories</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol className="project-element">
                        <IonItem>
                        <IonRadio value="156"></IonRadio>&nbsp;
                        <IonLabel className="ptext">Accuracy / Valuation</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol className="project-element">
                        <IonItem>
                        <IonRadio value="153"></IonRadio>&nbsp;
                        <IonLabel className="ptext">Completeness</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol className="project-element">
                        <IonItem>
                        <IonRadio value="154"></IonRadio>&nbsp;
                        <IonLabel className="ptext">Cut Off</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol className="project-element">
                        <IonItem>
                        <IonRadio value="155"></IonRadio>&nbsp;
                        <IonLabel className="ptext">Occurrence</IonLabel>
                        </IonItem>
                    </IonCol>
                    </IonRow>
                </IonRadioGroup>
            </div>
        }
        </IonRow>
        {hideTestList2 &&
            <div className="test-list-ar">
                <IonRow>
                    {getTestList2}
                </IonRow>
            </div>
        }
        {hideProjectList &&
            <div className="project-list">
                <IonRow>
                {getProjectList}
                </IonRow>
            </div>
        }
        {hideTestResults &&
        <div className="area-51-jets">
            <div className="top-line-area-jets"><b>Test Definition For (<i>{getTestName}</i>)</b><i>{getTestDescriptionDetail}</i></div>
            {getMathTest && 
            <div>
                <IonRow className="chart-area-1-ar">
                <IonCol className="ion-text-center top-line" >
                    <IonRow>
                    <IonCol>
                        <h6>DEBIT</h6>
                    </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol>
                    <h5><b>{getMathDebit}</b></h5>
                    </IonCol>
                    </IonRow>
                </IonCol>
                <IonCol className="ion-text-center top-line" >
                    <IonRow>
                    <IonCol>
                        <h6>CREDIT</h6>
                    </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol>
                    <h5><b>{getMathCredit}</b></h5>
                    </IonCol>
                    </IonRow>
                </IonCol>
                <IonCol className="ion-text-center top-line" >
                    <IonRow>
                    <IonCol>
                        <h6>DIFFERENCE</h6>
                    </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol>
                    <h5><b>{getMathDifference}</b></h5>
                    </IonCol>
                    </IonRow>
                </IonCol>
                <IonCol className="ion-text-center top-line" >
                    <IonRow>
                    <IonCol>
                        <h6>NUMBER OF JOURNALS</h6>
                    </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol>
                    <h5><b>{getMathCount}</b></h5>
                    </IonCol>
                    </IonRow>
                </IonCol>
                </IonRow>
            </div>
            }
            {getGLTest &&
            <div>
                {!hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-3">
                    <b>GL Completeness</b>
                    {getGLChart}
                </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-3">
                    <div className="gl"></div>
                </div>
                </div>
                }
            </div>
            }
            {getUnbalancedTest && 
            <div>
                <div className="chart-area-1-ar">
                {hideTable1 &&
                <div className="chart-3">
                    <div className="unbalanced"></div>
                </div>
                }
                </div>
            </div>
            }
            {getAccountAnalysisTest &&
            <div>
                {!hideTable1 &&
                <div className="chart-area-1-ar">
                    <div className="chart-3">
                    <b>Account Analysis</b>
                    {getAccountAnalysisChart}
                    </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-1-ar">
                    <div className="chart-3">
                    <div className="accountanalysis"></div>
                    </div>
                </div>
                }
            </div>
            }
            {getMaterialityAboveTest && 
            <div>
                {!hideTable1 &&
                <div>
                <div className="chart-area-1-ar">
                    <div className="chart-1">
                    <b>Transaction Count Above Materiality</b>
                        {getMaterialityAboveChart1}
                    </div>
                    <div  className="chart-2">
                    <b>Transactions Above Materiality Debit & Credit</b>
                    {getMaterialityAboveChart2}
                    </div>
                </div>
                <div className="chart-area-2">
                    <div className="chart-1">
                    <b>User Transactions Above Materiality</b>
                        {getMaterialityAboveChart3}
                    </div>
                    <div className="chart-2">
                    <b>User Transactions Above Materiality  Debit & Credit</b>
                        {getMaterialityAboveChart4}
                    </div>
                </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-3">
                    <div className="abovectt"></div>
                </div>
                </div>
                }
            </div>
            }
            {getMaterialityBelowTest && 
            <div>
                {!hideTable1 &&
                <div>
                <div className="chart-area-1-ar">
                    <div className="chart-1">
                    <b>Transaction Count Below Materiality</b>
                        {getMaterialityBelowChart1}
                    </div>
                    <div  className="chart-2">
                    <b>Transactions Below Materiality Debit & Credit</b>
                    {getMaterialityBelowChart2}
                    </div>
                </div>
                <div className="chart-area-2">
                    <div className="chart-1">
                    <b>User Transactions Below Materiality</b>
                        {getMaterialityBelowChart3}
                    </div>
                    <div className="chart-2">
                    <b>User Transactions Below Materiality  Debit & Credit</b>
                        {getMaterialityBelowChart4}
                    </div>
                </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-3">
                    <div className="belowctt"></div>
                </div>
                </div>
                }
            </div>
            }
            {getValueStratificationTest && 
            <div>
                {!hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-3">
                    <b>Value Stratification</b>
                    {getValueStratificationChart1}
                </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-3">
                    <b>Value Stratification</b>
                    <div className="stratification"></div>
                </div>
                </div>
                }

            </div>
            }
            {getRoundedTest &&
            <div>
                {!hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-1">
                    <b>Round Journal User Count</b>
                    {getRoundedChart1}
                </div>
                <div className="chart-2">
                    <b>Round Journal Account Count</b>
                    {getRoundedChart2}
                </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-1-ar">
                    <div className="chart-3">
                    <div className="rounded"></div>
                    </div>
                </div>
                }
            </div>
            }
            {getBenfordTest &&
            <div>
                {!hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-3">
                    <b>Benfords 2 Digit Tests</b>
                    {getBenfordChart}
                </div>
                </div>
                } 
                {hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-3">
                    <div className="benford"></div>
                </div>
                </div>
                }
            </div>
            }
            {getUserAnalysisTest && 
            <div>
                {!hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-1">
                    <b>User Analysis - Manual Journal Count</b>
                    {getUserAnalysisChart1}
                </div>
                <div className="chart-2">
                    <b>User Analysis - Manual Journal Debit Value</b>
                    {getUserAnalysisChart2}
                </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-3">
                    <div className="useranalysis"></div>
                </div>
                </div>
                }
            </div>
            }
            {getWeekendTest &&
            <div>
                {!hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-1">
                    <b>Weekend Journal Count</b>
                    {getWeekendChart1}
                </div>
                <div className="chart-2">
                    <b>Weekend Journal User Count</b>
                    {getWeekendChart2}
                </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-2">
                    <div className="chart-3">
                    <div className="weekend"></div>
                    </div>
                </div>
                }
            </div>
            }
            {getHolidayTest &&
            <div>
                {!hideTable1 &&
                <div>
                <div className="chart-area-1-ar">
                    <div className="chart-1">
                    <b>Public Holiday Transactions Count</b>
                    {getHolidayChart1}
                    </div>
                    <div className="chart-2">
                    <b>Public Holiday Transactions Debit & Credit</b>
                    {getHolidayChart2}
                    </div>
                </div>
                <div className="chart-area-2">
                    <div className="chart-1">
                    <b>Public Holiday Transaction User Count</b>
                    {getHolidayChart3}
                    </div>
                </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-1-ar">
                    <div className="chart-3">
                    <div className="holiday"></div>
                    </div>
                </div>
                }
            </div>
            }
            {getBackdatedTest &&
            <div>
                {!hideTable1 &&
                <div>
                <div className="chart-area-1-ar">
                    <div className="chart-3">
                    <b>Transaction Count By Backdated Days</b>
                    {getBackdatedChart1}
                    </div>
                </div>
                <div className="chart-area-2">
                    <div className="chart-1">
                    <b>User Transactions Count</b>
                    {getBackdatedChart2}
                    </div>
                    <div className="chart-2">
                    <b>Monthly Transactions Count</b>
                    {getBackdatedChart3}
                    </div>
                </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-1-ar">
                    <div className="chart-3">
                    <div className="backdated"></div>
                    </div>
                </div>
                }
            </div>
            }
            {getLastDayTest && 
            <div>
                {!hideTable1 &&
                <div className="chart-area-1-ar">
                <div className="chart-1">
                    <b>Last Day Journals By User Count</b>
                    {getLastDayChart1}
                </div>
                <div className="chart-2">
                <b>Last Day Journals By Account Count</b>
                    {getLastDayChart2}
                </div>
                </div>
                }
                {hideTable1 &&
                <div className="chart-area-1-ar">
                    <div className="chart-3">
                    <div className="lastday"></div>
                    </div>
                </div>
                }
            </div>
            }
            {getWithoutDescriptionTest &&
            <div>
                <div className="chart-area-1-ar">
                <div className="chart-3">
                    {getWithoutDescriptionChart1}
                </div>
                </div>
                {hideTable1 &&
                <div className="chart-area-1-ar">
                    <div className="chart-3">
                    <div className="withoutdescription"></div>
                    </div>
                </div>
                }
            </div>
            }
            {hideTable1 &&
            <div className="chart-area-1-ar">
                <b>{getTitle}</b>
                <div className="chart-3-table">
                    {getTable}
                </div>
            </div>
            }
        </div>
        }
    </div>
    )

}

export default AR_JETs_Results;