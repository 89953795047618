import {  } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonIcon, IonCard, IonCardHeader, IonTitle, IonCardContent, IonFooter, IonHeader, IonToggle, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonButton, IonListHeader, IonLoading, IonCheckbox } from '@ionic/react';
import { checkmarkOutline, createOutline, pencilOutline, settingsOutline, close, refresh, refreshOutline, arrowRedoOutline, checkmark, closeOutline, addCircleSharp, documentAttachOutline, atCircle, arrowForward, analyticsOutline, addOutline, flashOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import "./Main.css"
import CustomImport from '../../DataImport/CustomImport';
import NewProjectForm from '../NewProject';
import FileLoader from '../../FileLoader/fileloader';
import { callCountRowMatchOnly, callMGXList, callMigrationList, callSummaryReport, callSummaryReportList, callSummaryReportTable, callTableColumns, callTableSample } from '../../../dbconnections/Select';
import Basic from '../../Tables/Basic';
import { insertMigrationManifest,  } from '../../../dbconnections/Insert';
import NewMigrationProjectForm from '../NewMigrationProject';
import { create } from 'domain';

var step:any = 1;
var source_column:any = [];
var target_column:any = [];
var source_params:any = {
    column_id:0,
    action_id:0,
    version_id:0,
    step_id:0,
    migration_id:0,
    file_restore_id:0
}
var target_params:any = {
    column_id:0,
    action_id:0,
    version_id:0,
    step_id:0,
    migration_id:0,
    file_restore_id:0
}
const MigrationMain = (props:any) =>{
    const [getNewProjectForm, setNewProjectForm]                    = useState<any>(false)
    const [getProjectName, setProjectName]                          = useState<any>()
    const [getFileLoader, setFileLoader]                            = useState<any>()
    const [getCountryId, setCountryId]                              = useState<any>()
    const [getVersionId, setVersionId]                              = useState<any>()
    const [getVersionId1, setVersionId1]                              = useState<any>()
    const [getVersionId2, setVersionId2]                              = useState<any>()
    const [getMigrationId, setMigrationId]                          = useState<any>()
    const [getLabel, setLabel]                                      = useState<any>("")
    const [getSourceColumns, setSourceColumns]                      = useState<any>()
    const [getTargetColumns, setTargetColumns]                      = useState<any>()
    const [getTable, setTable]                                      = useState<any>()
    const [getSourceTable, setSourceTable]                          = useState<any>()
    const [getTargetTable, setTargetTable]                          = useState<any>()
    const [getTableView, setTableView]                              = useState<any>()    
    const [getTargetTableView, setTargetTableView]                  = useState<any>()
    const [getSourceTableView, setSourceTableView]                  = useState<any>()
    const [getContinue, setContinue]                                = useState<any>(false)
    const [getStep, setStep]                                        = useState<any>(1)
    const [getAction, setAction]                                    = useState<any>()
    const [getActionId, setActionId]                                = useState<any>(2)
    const [getMigrationList, setMigrationList]                      = useState<any>()
    const [getMigrationListView, setMigrationListView]              = useState<any>(true)
    const [getSourceColumnView, setSourceColumnView]                = useState<any>(false)
    const [getTargetColumnView, setTargetColumnView]                = useState<any>(false)
    const [getLoadSourceFileView, setLoadSoureFileView]             = useState<any>()
    const [getLoadTargetFileView, setLoadTargetFileView]            = useState<any>()
    const [getSummaryView, setSummaryView]                          = useState<any>()
    const [getSourceColumnsArchive, setSourceColumnsArchive]        = useState<any>()
    const [getTargetColumnsArchive, setTargetColumnsArchive]        = useState<any>()
    const [getActionView, setActionView]                            = useState<any>(false)
    const [getStage, setStage]                                      = useState<any>(1)
    const [getStepStageView, setStepStageView]                      = useState<any>()
    const [getStepView, setStepView]                                = useState<any>(false)

    const setView = (s:any) =>{ 
        resetView()
        switch(s){
            case 1: setNewProjectForm(true);setLabel("Migration Summary");break;
            case 2: setFileLoader(true);setLabel("Load Migration Data");setLoadSoureFileView(true);setLoadTargetFileView(true); break;
            case 3: setFileLoader(true);setSourceColumnView(true);setLoadTargetFileView(true);break;
            case 4: setFileLoader(true);setTargetColumnView(true);setTargetColumnView(true); break;
            case 5: setFileLoader(true);setSummaryView(true);break;
            case 6: setStepStageView(true);
                    setStepView(true);
                    setStepStage(1);
                    setContinue(false);
                    break;
            case 7: setMigrationListView(true);setContinue(false);callMigration();break;
            case 8: setFileLoader(true);setLabel("Load Migration Data");setLoadTargetFileView(true);
            
        }
    }
    const resetView = () =>{
        setNewProjectForm(false);
        setFileLoader(false);
        setTableView(false)
        setSourceTableView(false)
        setTargetTableView(false)
        setLoadSoureFileView(false)
        setLoadTargetFileView(false)
        setSourceColumnView(false);
        setTargetColumnView(false);
        setMigrationListView(false)
        setSummaryView(false)
        setStepStageView(false)
        setMigrationListView(false)
        setStepView(false);
    }
    const setStepStage = (s:any) =>{
        resetStepStage()
        switch(s){
            case 1: setSourceColumnView(true);break;
            case 3: setActionView(true) ;break;
            case 2: setTargetColumnView(true);  ;break;
        }
    }
    const resetStepStage = () =>{
        setSourceColumnView(false);
        setActionView(false);
        setTargetColumnView(false);
        setStepStageView(false)
    }
    const callMigration = () =>{
        var x:any = null
        setMigrationList(x)
        let option:any = [];
        option.push({primary_host:props.state.primary_host,created_by:props.state.user_id})
        callMigrationList(option[0])
        .then(response =>{return response})
        .then(d =>{
            console.log(d)
            var y:any = []
            y.push(d.data)
            //var header:any = 
            
            let listItems = y[0].map((x:any, i:number) =>{ 
                return(
                <IonRow key={i} className="row-hover summary-detail-row">
                    <IonCol size="2" className='size-12 ion-text-center ion-padding'>{
                        x.complete == 1? <div className="circle-orange"></div>:
                        x.complete == 2? <div className="circle-green"></div>: 
                        <div className="circle-red"></div>}
                        &nbsp;{x["migration_name"]}
                    </IonCol>
                    <IonCol size="2" className="size-12 ion-text-left ion-padding" >{x["Source File"]}</IonCol>
                    <IonCol size="1" className='size-16 ion-text-center ion-padding' ><IonIcon icon={arrowForward} className="size-32"></IonIcon></IonCol>
                    <IonCol size="2" className={"size-12 ion-text-left  ion-padding "+ (x["Target File"] == 'null'? 'text-red' : '')} onClick={()=>{
                       if( x["Target File"] == 'null'){
                            setMigrationId(x.migration_id)
                            setView(8)
                       }else{

                       }
                    }}>{x["Target File"] == 'null'? '<No File>' : x["Target File"] }</IonCol>
                    <IonCol size="1" className="size-12 hover ion-text-left ion-padding" onClick={()=>{setView(5);setMigrationId(x.migration_id);callSummaryDirectList(x.migration_id)}}>{x["count"]}</IonCol>
                    <IonCol size="1" className='size-12 hover ion-text-left ion-padding'>
                        <IonIcon icon={createOutline}  className="size-24" onClick={()=>{
                        setMigrationId(x.migration_id)
                        callHeaders(x.source_version_id, 1,x.step_id/1); 
                        callHeaders(x.target_version_id, 2,x.step_id/1);
                        callSample(x.source_version_id, 1);
                        callSample(x.target_version_id, 2);
                        setStep(x.step_id);
                        setView(6);
                    }}></IonIcon>&nbsp;
                        <IonIcon icon={flashOutline} className="size-24" onClick={()=>{
                            CallMatchRows(x.migration_id, x.source_version_id, x.target_version_id)
                        }}></IonIcon>
                    </IonCol>
                    <IonCol className="size-12 hover ion-text-left ion-padding">{x.source_count}/{x.target_count}</IonCol>
                    <IonCol className="size-12 hover ion-text-left ion-padding">{x.variance_count}/{x.variance_value}</IonCol>
                </IonRow>

                )
            })
            var x:any = [listItems]
            
            setMigrationList(x)
        })
    }
    const AddColumn = (col:any, restore_id:any)=>{
        
        if (restore_id == 1){
            source_column = []
            var x:any = document.getElementsByClassName('source-column')
            console.log(x)
            for(var i = 0; i < x.length; i++ ){
                    if(x[i].checked){
                        source_column.push(x[i].value)
                    }
            }
            console.log(source_column)
            console.log(source_column.length)
            if(source_column.length > 0){
                setStepStageView(true)
            }else{
                setStepStageView(false)
            }
        }

        if (restore_id == 2){
            target_column = []
            var x:any = document.getElementsByClassName('target-column')
            console.log(x)
            for(var i = 0; i < x.length; i++ ){
                    if(x[i].checked){
                        target_column.push(x[i].value)
                    }
            }
            console.log(target_column)
            if(target_column.length > 0){
                setStepStageView(true)
            }else{
                setStepStageView(false)
            }
        }

    }

    const setStepParameters = (restore_id:any) =>{
        var z:any = []
        
        if(restore_id == 1){
            for(var i = 0; i < source_column.length; i++){
                z.push({primary_host    :props.state.primary_host,
                column_id           :source_column[i], 
                version_id          :source_params.version_id, 
                file_restore_id     :source_params.file_restore_id, 
                action_id           :source_params.action_id, 
                migration_id        :source_params.migration_id, 
                step_id             :source_params.step_id 
                })

                insertMigrationManifest(z[0])
                .then(response=>{return response})
                .then(data=>{
                    console.log(data)
                    if(data.data[0].done == 1){
                        //alert("done")
                        //setContinue(true)
                    }else{
                        //alert("in progress")
                        //setContinue(false)
                        
                    }
                })
            }
        }

        if(restore_id == 2){
            for(var i = 0; i < source_column.length; i++){
                z.push({primary_host    :props.state.primary_host,
                column_id           :target_column[i], 
                version_id          :target_params.version_id, 
                file_restore_id     :target_params.file_restore_id, 
                action_id           :target_params.action_id, 
                migration_id        :target_params.migration_id, 
                step_id             :target_params.step_id 
                })
                insertMigrationManifest(z[0])
                .then(response=>{return response})
                .then(data=>{
                    console.log(data)
                    if(data.data[0].done == 1){
                        //alert("done")
                        //setContinue(true)
                    }else{
                        //alert("in progress")
                        //setContinue(false)
                        
                    }
                })
            }
        }
    }
    const CallMatchRows = (migration_id:any, source_version_id:any, target_version_id:any) =>{
        var x:any = []
        x.push({primary_host:props.state.primary_host,migration_id:migration_id, source_table:'temp_'+source_version_id+"_0", target_table:"temp_"+target_version_id+"_0" })
        callCountRowMatchOnly(x[0])
        .then(response=>{return response})
        .then(d => {
            callMigration()
        })
    
    }
    const callHeaders = (version_id:any, file_restore_id:any, step_id:any) =>{
        var z:any = null
        if(file_restore_id == 1)
            setSourceColumns(z);
        if(file_restore_id == 2)
            setTargetColumns(z);

        let table_object_id:any = ""
        let option:any = [];
        option.push({primary_host:props.state.primary_host,version_id:version_id, table_object_id:table_object_id})
        callTableColumns(option[0])
        .then(response=>{return response})
        .then(d => {
            console.log(d)
            var y:any = []
            y.push(d.data)
            let listItems = y[0].map((x:any, i:number) =>{
                if(i > 3)
                return(
                    <IonCol key={i} size="2" className="size-12" >
                        <IonCheckbox className={file_restore_id == 1?"source-column ":"target-column"} value={x.id} onIonChange={(e)=>{
                            //if(e.detail.checked){
                                if(file_restore_id == 1){
                                    source_params.version_id        = version_id
                                    source_params.migration_id      = x.migration_id
                                    source_params.file_restore_id   = file_restore_id
                                    source_params.step_id           = step_id
                                    AddColumn(x.id, file_restore_id)
                                }

                                if(file_restore_id == 2){
                                    target_params.version_id        = version_id
                                    target_params.migration_id      = x.migration_id
                                    target_params.file_restore_id   = file_restore_id
                                    target_params.step_id           = step_id
                                    AddColumn(x.id, file_restore_id)
                                }
                                console.log(source_params)
                                console.log(target_params)
                            //}
                            //else{
                            //    if(file_restore_id == 1){
                            //        source_params.version_id        = 0
                            //        source_params.migration_id      = 0
                            //        source_params.file_restore_id   = 0
                            //        source_params.step_id           = 0
                            //        source_params.column_id         = 0
                            //    }
//
                            //    if(file_restore_id == 2){
                            //        target_params.version_id        = 0
                            //        target_params.migration_id      = 0
                            //        target_params.file_restore_id   = 0
                            //        target_params.step_id           = 0
                            //        target_params.column_id         = 0
                            //    }
                            //    console.log(source_params)
                            //    console.log(target_params)
                            //}
                        }}></IonCheckbox>
                        &nbsp;<IonLabel>{x.name}</IonLabel>
                    </IonCol>
                )    

            })
            if(file_restore_id == 1){
                setTableView(false)
                setSourceColumns(listItems);
                setSourceColumnsArchive(listItems)
                callSample(version_id, file_restore_id)
                //setView(3)
            }
            if(file_restore_id == 2){
                setTableView(false)
                setTargetColumns(listItems);
                setTargetColumnsArchive(listItems)
                callSample(version_id, file_restore_id)
                //setView(4)
            }
        })
        .catch(error=>{
            alert(error)
        })
    }
    const callSample = (version_id:any, file_restore_id:any) =>{
        var x:any = null
        setTable(null)
        let table_object_id:any = ""
        let option:any = [];
        option.push({primary_host:props.state.primary_host,version_id:version_id, table_object_id:table_object_id})
        callTableSample(option[0])
        .then(response=>{return response})
        .then(d => {
            console.log(d)
            var y:any = []
            y.push(d.data)
            var table:any = [] 
            table = <Basic data={y} />
            setTable(table)
        })
        .catch(error=>{
            alert(error)
        })
    }
    const callSummaryDirect = (id:any) =>{
        var x:any = null
        setTable(null)
        let table_object_id:any = ""
        let option:any = [];
        option.push({primary_host:props.state.primary_host, migration_manifest_id: id})
        callSummaryReportTable(option[0])
        .then(response=>{return response})
        .then(d => {
            console.log(d)
            var y:any = []
            y.push(d.data)
            var table:any = [] 
            table = <Basic data={y} />
            setTable(table)
        })
        .catch(error=>{
            alert(error)
        })
    }
    const callSummaryDirectList = (migration_id:any) =>{
        setMigrationId(migration_id)
        var x:any = null
        setTable(null)
        let table_object_id:any = ""
        let option:any = [];
        option.push({primary_host:props.state.primary_host, migration_id:migration_id})
        callSummaryReportList(option[0])
        .then(response=>{return response})
        .then(d => {
            console.log(d)
            var y:any = []
            y.push(d.data)
            var header:any =
            <IonRow   className="summary-row-header ion-padding">
                <IonCol size="4" className="size-16 hover" ><b>Migration Project</b></IonCol>
                <IonCol size="4" className="size-16 hover" ><b>Process Name</b></IonCol>
            </IonRow> 
            var table:any = [] 
            let listItems = y[0].map((x:any, i:number) =>{
                return(
                <IonRow key={i} className="row-hover summary-detail-row">
                    <IonCol size="4" className="size-16 hover" onClick={()=>{callSummaryDirect(x.id)}} >{x["Migration Name"]}</IonCol>
                    <IonCol size="4" className="size-16 hover" onClick={()=>{callSummaryDirect(x.id)}} >{x["alias"]}</IonCol>
                </IonRow>
                )
            })
            var x:any = [header, listItems]
            setTable(x)
        })
        .catch(error=>{
            alert(error)
        })
    }
    const callSummary = () =>{
        var x:any = null
        setTable(null)
        let table_object_id:any = ""
        let option:any = [];
        option.push({primary_host:props.state.primary_host, migration_id:getMigrationId})
        callSummaryReport(option[0])
        .then(response=>{return response})
        .then(d => {
            console.log(d)
            var y:any = []
            y.push(d.data)
            var table:any = [] 
            table = <Basic data={y} />
            setTable(table)
        })
        .catch(error=>{
            alert(error)
        })
    }

    const callActionList = () =>{
        let option:any = [];
        option.push({primary_host:props.state.primary_host,parent_id:1})
        callMGXList(option[0])
        .then(response=>{return response})
        .then(d => {
            console.log(d)
            var y:any = []
            y.push(d.data)
            let listItems = y[0].map((x:any, i:number) =>{
                return(
                    <IonSelectOption value={x.id}>{x.name}</IonSelectOption>
                )    
            })
            setAction(listItems)
        })
        .catch(error=>{
            alert(error)
        })
    }
    const resetColumns = (step_id:any) =>{
        var x:any = null
        setSourceColumns(x)
        setTargetColumns(x)
        setSourceColumns(getSourceColumnsArchive)
        setTargetColumns(getTargetColumnsArchive)
        callHeaders(source_params.version_id, source_params.file_restore_id,step_id)
        callHeaders(target_params.version_id, target_params.file_restore_id,step_id)
    }
   
    useEffect(() => {
        callActionList()
        callMigration()
    }, []);
    return (
        <div className="migration">
            <IonRow className="ion-padding">
                <IonCol size="6" className="size-20">
                    <b>{getLabel} {getStepView &&<div>Step/Stage ({getStep})</div> }</b>
                </IonCol>
                {getStepStageView &&
                    <IonCol size="5">
                        <IonRow  className=' ion-padding'>
                            <IonCol  className="ion-text-right  custom-account-text-icon" onClick={()=>{
                                
                                if((getStage-1)>0){
                                    setStepStage(getStage-1);
                                }else{
                                    setView(7)
                                    callMigration()
                                }
                                }}>
                                <div className="ion-text-center text-black hover" >Back</div>
                            </IonCol>
                            <IonCol size="1"></IonCol>
                            <IonCol  className="ion-text-right  custom-account-text-icon" onClick={()=>{
                                setStepStage((getStage/1)+1);
                                console.log("stage:"+getStage)
                                if(((getStage/1)+1)<=3){
                                    setStage((getStage/1)+1)
                                    setContinue(false)
                                
                                }else{
                                    setStage(1)
                                    setContinue(true)
                                    setStepStageView(false)
                                };
                                console.log("stage:"+getStage)
                                }}>
                                <div className="ion-text-center text-black hover" >Next</div>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                }
                {getContinue &&
                <IonCol size="5">
                    <IonRow  className='ion-padding'>
                        <IonCol  className="ion-text-right  custom-account-text-icon" onClick={()=>{
                            setStepParameters(1);
                            setStepParameters(2);
                            setStep((getStep/1)+1); 
                            setStage(1)
                            resetColumns((getStep/1)+1); 
                            setView(6)
                            setContinue(false)
                            }}>
                            <div className="ion-text-center text-black hover" >
                                <IonIcon icon={addOutline} className="size-24 text-orange"></IonIcon>&nbsp;
                                Add Columns
                            </div>
                        </IonCol>
                        <IonCol size="1"></IonCol>
                        <IonCol  className="ion-text-right  custom-account-text-icon" onClick={()=>{setStepParameters(1);setStepParameters(2);callSummary(); setView(5)}}>
                            <div className="ion-text-center text-black hover" >
                                <IonIcon icon={checkmarkOutline} className="size-24 text-green"></IonIcon>&nbsp;
                                Run Test
                            </div>
                        </IonCol>
                    </IonRow>
                </IonCol>
                }
                <IonCol className="ion-text-right hover ion-padding" onClick={()=>{setView(7)}}>
                    Home &nbsp;
                    <IonIcon icon={refreshOutline} className="size-32"></IonIcon>
                </IonCol>
            </IonRow>
            {getMigrationListView &&
            <div>
                <IonRow>
                    <IonCol>
                        <IonButton onClick={()=>{setView(1)}}>
                          Add New Test
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                    <IonRow key={-1}  className="summary-row-header ion-padding">
                        <IonCol size="2" className='size-12 ion-padding' ><b>TEST NAME</b></IonCol>
                        <IonCol size="2" className="size-12 ion-padding ion-text-left" ><b>SOURCE FILE</b></IonCol>
                        <IonCol size="1" className='size-12 ion-padding' ><b></b></IonCol>
                        <IonCol size="2" className="size-12 ion-padding ion-text-left" ><b>TARGET FILE</b></IonCol>
                        <IonCol size="1" className="size-12 ion-padding ion-text-left" ><b>RESULTS</b></IonCol>
                        <IonCol size="1" className="size-12 ion-padding ion-text-left" ><b>MODIFY</b></IonCol>
                        <IonCol className="size-12 ion-padding ion-text-left" ><b>SOURCE / TARGET</b></IonCol>
                        <IonCol className="size-12 ion-padding ion-text-left" ><b>VARIANCE COUNT / VALUE </b></IonCol>
                    </IonRow>
                    <div className="list-scroll">
                        {getMigrationList}
                    </div>
                    </IonCol>
                </IonRow>
            </div>
            }
            {getNewProjectForm &&
                <IonRow>
                    <IonCol size="3"></IonCol>
                    <IonCol className="ion-padding" >
                        <div className="upload-form-left-division load-settings size-14 ion-padding">
                            <NewMigrationProjectForm
                                state={props} 
                                application_id={-1}
                                package_id={-1}
                                setSystem={(e:any)=>{}} 
                                setUpLoads={(e:any)=>{}} 
                                setProjectName={(e:any)=>{setProjectName(e)}}
                                setBillingCode={(e:any)=>{}}
                                setCTT={(e:any)=>{}}
                                setRounding={(e:any)=>{}}
                                setCountry={(e:any)=>{setCountryId(e)}}
                                setMigrationId={(e:any)=>{setMigrationId(e[0].migration_id);setCountryId(e[0].country_id);setView(2) }}
                            />
                        </div>
                    </IonCol>
                    <IonCol size="3"></IonCol>
                </IonRow>
            }
            {getFileLoader &&
            <div>
            
            <IonRow>
                <IonCol>
                    {getLoadSourceFileView &&
                    <IonRow>
                        <IonCol className='ion-padding ion-text-left'>
                            <FileLoader 
                                state={props} 
                                form_name="Source Data" 
                                restore_id={1}
                                ctt={0}
                                rounding ={"0.00"}
                                migration_name ={getProjectName}
                                billing_code = {""}
                                country = {getCountryId}
                                migration_id = {getMigrationId}
                                load_counter = {(e:any)=>{}}
                                version_id={(e:any)=>{setVersionId(e);/*alert(e);*/callHeaders(e,1, getStep)}}
                                file_name = {(e:any)=>{}}
                                package_id ={ -1 }
                                load_table ={(e:any)=>{setVersionId(e);}}
                            />
                        </IonCol>
                    </IonRow>
                    }
                </IonCol>
                <IonCol>
                    {getLoadTargetFileView &&
                    <IonRow>
                        <IonCol className='ion-padding ion-text-left'>
                            <FileLoader 
                                state={props} 
                                form_name="Target Data" 
                                restore_id={2}
                                ctt={0}
                                rounding ={"0.00"}
                                migration_name ={getProjectName}
                                billing_code = {""}
                                country = {getCountryId}
                                migration_id = {getMigrationId}
                                load_counter = {(e:any)=>{}}
                                version_id={(e:any)=>{setVersionId(e);/*alert(e);*/callHeaders(e, 2, getStep)}}
                                file_name = {(e:any)=>{}}
                                package_id ={ -1 }
                                load_table ={(e:any)=>{setVersionId(e);}}
                            />
                        </IonCol>
                    </IonRow>
                    }
                </IonCol>
            </IonRow>
            
            {(getSummaryView) &&
            <div>
                <IonRow>
                    <IonCol>
                        <b></b>
                    </IonCol>
                    
                    <IonCol className="ion-text-right" onClick={()=>{setView(7);callMigration()}}>
                        <IonIcon icon={arrowRedoOutline} className="size-32" ></IonIcon>&nbsp;
                        <IonLabel className=" hover text-blue">Home</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <div>
                            {getTable}
                        </div>
                    </IonCol>
                </IonRow>
                
            </div>
            }
        </div>
        }
        {/*getStepStageView &&
        <IonRow  className='migration-footer ion-padding'>
            <IonCol size="7"></IonCol>
            <IonCol size="2" className="ion-text-right  custom-account-text-icon" onClick={()=>{
                
                if((getStage-1)>0){
                    setStepStage(getStage-1);
                }else{
                    setView(7)
                    callMigration()
                }
                }}>
                <div className="ion-text-center text-black hover" >Back</div>
            </IonCol>
            <IonCol></IonCol>
            <IonCol size="2" className="ion-text-right  custom-account-text-icon" onClick={()=>{
                setStepStage(getStage+1);
                if((getStage+1)<=3){
                    setStage(getStage+1)
                }else{
                    setStage(1)
                    setContinue(true)
                    setStepStageView(false)
                };}}>
                <div className="ion-text-center text-black hover" >Next</div>
            </IonCol>
        </IonRow>
        */}
        
        {getActionView &&
        <div>
            <IonRow>
                <IonCol size="3"></IonCol>
                <IonCol size="6">
                    <b>Select an action to be performed when testing between the two table columns</b>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="3"></IonCol>
                <IonCol size="6" className="ion-text-center">
                    <IonItem>
                        <IonLabel position='floating'>Select Actions</IonLabel>
                        <IonSelect onIonChange={(e)=>{setActionId(e.detail.value); source_params.action_id = target_params.action_id = e.detail.value; setStepStageView(true)}}>
                            {getAction}
                        </IonSelect>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
        }
        {(getSourceTableView) &&
            <div>
                <IonRow>
                    <IonCol>
                        <b>Source Table Summary View</b>
                    </IonCol>
                    <IonCol className="ion-text-right hover" onClick={()=>{setTableView(!getTableView); setSourceTableView(!getSourceTableView)}}>
                        <IonIcon icon={arrowRedoOutline} className="size-32" ></IonIcon>&nbsp;
                        <IonLabel className=" hover text-blue">Return to Pick Column</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <div className="table-list">
                            {getTable}
                        </div>
                    </IonCol>
                </IonRow>
            </div>
        }
        {(getTargetTableView) &&
            <div>
                <IonRow>
                    <IonCol>
                        <b>Target Table Summary View</b>
                    </IonCol>
                    <IonCol className="ion-text-right hover" onClick={()=>{setTableView(!getTableView); setTargetTableView(!getTargetTableView)}}>
                        <IonIcon icon={arrowRedoOutline} className="size-32" ></IonIcon>&nbsp;
                        <IonLabel className="ion-text-right hover text-blue">Return to Pick Column</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <div className="table-list">
                            {getTable}
                        </div>
                    </IonCol>
                </IonRow>
            </div>
        }
        {(getSourceColumnView) &&
            <IonRow>
                <IonCol className='ion-padding ion-text-left'>
                    <IonRow> 
                        <IonCol size="9"><IonLabel>Source Column (Select Primary Row Identifier(s) which link with Target)</IonLabel></IonCol>
                        <IonCol size="3" className="ion-text-right hover" onClick={()=>{setTableView(!getTableView); setSourceTableView(!getSourceTableView)}}>
                            <IonLabel className=" text-blue">View Table</IonLabel>
                        </IonCol>
                        <IonCol size="12">
                            <div className="upload-form-left-division load-settings size-14 ion-padding">
                                <IonRow>
                                    {getSourceColumns}
                                </IonRow>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        }
        {(getTargetColumnView) &&
            <IonRow>
                <IonCol className='ion-padding ion-text-left'>
                    <IonRow>
                        <IonCol size="9"><IonLabel>Target Column (Select Primary Row Identifier(s) which link with Source)</IonLabel></IonCol>
                        <IonCol size="3" className="ion-text-right hover" onClick={()=>{setTableView(!getTableView); setTargetTableView(!getTargetTableView)}}>
                            <IonLabel className=" text-blue">View Table</IonLabel>
                        </IonCol>
                        <IonCol size="12">
                            <div className="upload-form-left-division load-settings size-14 ion-padding">
                                <IonRow>
                                {getTargetColumns}
                                </IonRow> 
                            </div>                                   
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        }
        
    </div>
    )
}

export default MigrationMain;