import {  } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonIcon, IonCard, IonCardHeader, IonTitle, IonCardContent, IonFooter, IonHeader, IonToggle, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonButton, IonListHeader, IonLoading } from '@ionic/react';
import { checkmarkOutline, createOutline, pencilOutline, settingsOutline, close, refresh, refreshOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import SelectJETSProjects from '../DropDowns/SelectJETsProjects';
import Basic from '../Tables/Basic';
import MappingCredit from '../Tables/MappingCredit';
import MappingDebit from '../Tables/MappingDebit';
import MappingDetails from '../Tables/MappingDetails'
import MappingJournal from '../Tables/MappingJournal'
import SelectARProjects from '../DropDowns/SelectARProjects';
import "./TransactionMapping.css"
//import  DiagramWrapper  from '../Charts/GoJs/DiagramWrapper';


var nodeDataArray:any = []
var linkDataArray:any = []

const TransactionMapping = (props:any) =>{
    const [getCreditData, setCreditData]        = useState<any>()
    const [getDebitData, setDebitData]          = useState<any>()
    const [hideCreditData, showCreditData]      = useState<any>(false)
    const [hideDebitData, showDebitData]         = useState<any>(false)
    const [getMigrationId, setMigrationId]      = useState<any>()
    const [getDebitAccountName, setDebitAccountName]      = useState<any>("")
    const [getCreditAccountName, setCreditAccountName]      = useState<any>("")
    const [hideDetails, showDetails]            = useState<any>("details-up")
    const [getDetails, setDetails]              = useState<any>()
    const [getJournalDetails, setJournalDetails]              = useState<any>()
    const [hideDetailsTable, showDetailsTable]  = useState<any>(false)
    const [hideJournalTable, showJournalTable]  = useState<any>(false)
    const [getCreditAccountLevel1Key, setCreditAccountLevel1Key] = useState<any>(0)
    const [getDebitAccountLevel1Key, setDebitAccountLevel1Key] = useState<any>(0)
    const [getShowLoading, setShowLoading] = useState<any>()
    const [getNodeData, setNodeData]            = useState<any>()
    const [getLinkData, setLinkData]            = useState<any>()

    const callTransactionsDebitAccounts = (account_name:any)=>{
        setShowLoading(true)
        console.log(getCreditAccountName)
        let options:any = []
        fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?map=select_transaction_mapping_debit_accounts"+
        "&account_name="+account_name+
        "&migration_id="+getMigrationId
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
           // { key: 0, text: 'Alpha', color: 'lightblue', loc: '0 0' },
            nodeDataArray.push({ key: 0, text: account_name, color: 'lightblue', loc: '0 0' })
            for(var i = 0 ; i < data.data; i++){
                var y:any = 0 + (30)
                if(data.data[i].account_level_2_key == 1){
                    nodeDataArray.push({ key: (i+1), text: data.data[i][account_name], color: 'blue', loc: '0 '+y })
                    linkDataArray.pusy({ key: (-1 + i*-1), from: 0, to: (i+1) })
                }

                if(data.data[i].account_level_2_key == 2){
                    nodeDataArray.push({ key: (i+1), text: data.data[i][account_name], color: 'lightblue', loc: '0 '+y })
                    linkDataArray.pusy({ key: (-1 +i*-1), from: 0, to: (i+1) })
                }

                if(data.data[i].account_level_2_key == 3){
                    nodeDataArray.push({ key: (i+1), text: data.data[i][account_name], color: 'darkblue', loc: '0 '+y })
                    linkDataArray.pusy({ key: (-1 +i*-1), from: 0, to: (i+1) })
                }

                if(data.data[i].account_level_2_key == 4){
                    nodeDataArray.push({ key: (i+1), text: data.data[i][account_name], color: 'pink', loc: '0 '+y })
                    linkDataArray.pusy({ key: (-1 +i*-1), from: 0, to: (i+1) })
                }

                if(data.data[i].account_level_2_key == 5){
                    nodeDataArray.push({ key: (i+1), text: data.data[i][account_name], color: 'lightpink', loc: '0 '+y })
                    linkDataArray.pusy({ key: (-1 +i*-1), from: 0, to: (i+1) })
                }

                if(data.data[i].account_level_2_key == 6){
                    nodeDataArray.push({ key: (i+1), text: data.data[i][account_name], color: 'darkgreen', loc: '0 '+y })
                    linkDataArray.pusy({ key: (-1 +i*-1), from: 0, to: (i+1) })
                }

                if(data.data[i].account_level_2_key == 7){
                    nodeDataArray.push({ key: (i+1), text: data.data[i][account_name], color: 'darkred', loc: '0 '+y })
                    linkDataArray.pusy({ key: (-1 +i*-1), from: 0, to: (i+1) })
                }
            }
            setNodeData(nodeDataArray[0])
            setLinkData(linkDataArray[0])
            options.push(data.data)
            setDebitData(options)
            showDebitData(true)
            setShowLoading(false)
            
        })
    }
    const callTransactionsCreditAccounts = (migration_id:any, account_name:any)=>{
        setShowLoading(true)
        setMigrationId(migration_id)
        let options:any = []
        fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?map=select_transaction_mapping_credit_accounts"+
        "&account_name="+account_name+
        "&migration_id="+migration_id
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            //callTransactionsDebitAccounts();
            options.push(data.data)
            setCreditData(options)
            showCreditData(true)
            setShowLoading(false)
            showDebitData(false)
            if(account_name != ""){
                callTransactionsDebitAccounts(account_name)
            }else{
                var x:any = null
                setDebitData(x)
            }

        })
    }
    const callTransactionDetails = (debit_account_name:any)=>{
        setShowLoading(true)
        let options:any = []
        fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?map=select_transaction_mapping_details"+
        "&debit_account_name="+debit_account_name+
        "&credit_account_name="+getCreditAccountName+
        "&migration_id="+getMigrationId
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            options.push(data.data)
            setDetails(options)
            showDetails("details-down")
            showDetailsTable(true)
            setShowLoading(false)
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }
    const callJournal = (journal_number:any)=>{
        setShowLoading(true)
        let options:any = []
        fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?map=select_transaction_journal"+
        "&journal_number="+journal_number+
        "&migration_id="+getMigrationId
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            options.push(data.data)
            setJournalDetails(options)
            showDetailsTable(false)
            showJournalTable(true)
            setShowLoading(false)
        })
        .catch(error=>{
            setShowLoading(false)
            alert(error)
        })
    }
    const setTransactionMappingDetails = (debit_account_name:any, debit_account_level_1_key:any)=>{
        setShowLoading(true)
        let options:any = []
        fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?map=insert_transaction_mapping"+
        "&debit_account_name="+debit_account_name+
        "&credit_account_name="+getCreditAccountName+
        "&debit_account_level_1_key="+debit_account_level_1_key+
        "&credit_account_level_1_key="+getCreditAccountLevel1Key+
        "&migration_id="+getMigrationId
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            setShowLoading(false)
        })
    }
    return (
        <div  className="">
            <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'} ></IonLoading>
            <IonRow>
                <IonCol size="6">
                    Transaction Mapping
                </IonCol>
                <IonCol>
                    <SelectJETSProjects props={props.props} migration_id={(e:any)=>{callTransactionsCreditAccounts(e,"")}}/>
                </IonCol>
                <IonCol>
                    <SelectARProjects props={props.props} migration_id={(e:any)=>{callTransactionsCreditAccounts(e,"")}}/>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>&nbsp;</IonCol>
            </IonRow>
            {hideCreditData &&
            <div>
                <IonRow>
                    <IonCol size="4">
                        Credited Accounts (Transactions From)
                    </IonCol>
                    <IonCol size="1" className="ion-text-right">
                        <IonIcon className="size-20" icon={refreshOutline} onClick={()=>{
                            setCreditAccountName("");
                            callTransactionsCreditAccounts(getMigrationId, "")
                            }}></IonIcon>
                    </IonCol>
                    <IonCol size="1">
                        &nbsp;
                    </IonCol>
                    <IonCol>
                        Debit Accounts (Transactions To)
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6" className="ion-padding">
                        <div className="mapping-scroll-credit ion-padding">
                            <MappingCredit data={getCreditData} 
                                account_name={(e:any)=>{
                                    if(e != ""){
                                        setCreditAccountName(e.account_name);
                                        setCreditAccountLevel1Key(e.account_level_1_key)
                                        callTransactionsCreditAccounts(getMigrationId, e.account_name)
                                    }
                                }
                            }
                            
                            />
                            {hideDebitData &&
                            <div></div>
                            /*   <DiagramWrapper node_data={getNodeData} link_data={getLinkData}/>
                            */}
                        </div>
                    </IonCol>
                    
                    <IonCol size="6" className="ion-padding">
                        <div className="mapping-scroll-debit ion-padding">
                        {hideDebitData &&
                        
                            <MappingDebit data={getDebitData} 
                                account_name={(e:any)=>{
                                        showDetails("details-down")
                                        console.log(e)
                                        setDebitAccountName(e.account_name)
                                        setDebitAccountLevel1Key(e.account_level_1_key)
                                        callTransactionDetails(e.account_name)
                                    }
                                }
                                set_mapping={(e:any)=>{
                                        setDebitAccountName(e.account_name)
                                        setDebitAccountLevel1Key(e.account_level_1_key)
                                        setTransactionMappingDetails(e.account_name, e.account_level_1_key)
                                    }
                                }
                            />
                        
                        }
                        </div>
                    </IonCol>
                </IonRow>
                
            </div>
            }
            <div className={hideDetails+" size-14 ion-padding background-white"}>
                <IonRow className="row-divider-bottom">
                    <IonCol className="size-20"><b>Transactions Details</b></IonCol>
                    <IonCol size="1" className="ion-text-right">
                    <IonIcon onClick={()=>{showDetails("terms-up")}} icon={close} className="size-20 hover"></IonIcon>
                    </IonCol>
                </IonRow>
                {hideDetailsTable &&
                    <MappingDetails data={getDetails} show_journal={(e:any)=>{callJournal(e)}}/>
                }
                {hideJournalTable &&
                <div>
                <IonRow>
                    <IonCol className='ion-text-right text-green hover'>
                        <IonIcon icon={refreshOutline} className="size-20" onClick={()=>{showJournalTable(false); showDetailsTable(true)}}></IonIcon>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                    <MappingJournal data={getJournalDetails} />
                    </IonCol>
                </IonRow>
                </div>
                }

                </div>
        </div>
    )
}

export default TransactionMapping;