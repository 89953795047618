import { IonRow, IonCol, IonIcon} from '@ionic/react';
import {businessOutline,trashOutline, shareOutline} from 'ionicons/icons';
import AreaChart from '../components/Charts/AreaSplineChart';
import LineChart from '../components/Charts/LineChart';

export const setDateFormatHighCharts = (d:string) =>{
  var dt = new Date(d);
  var date = dt.getTime();
  //console.log(date)
  return date
}
export const isNumeric = (str:any) =>{
  if(!isNaN(str/1)){
    return true
  }else{
    return false
  }
}
export const addCommas = (nStr:any) =>{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}
export function getDaysInMonth(month:any, year:any) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  //console.log(days);
  return days;
}
var resultSet:any;
export const selectVersion = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/insert?etl=insertversion'+
  "&createdby="+props.user_id+
  "&templatename=''"+
  "&companyid=0"+
  "&reporttypeid=0"+
  "&filename=''"+
  "&delimiter=''",
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      var y:any = []
      y.push({version_id : d.data[0].versionid})
      return y[0]
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callMGXList = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?mgx=select_list'+
  "&parent_id="+props.parent_id,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callStockItemDetail = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?inv=select_stock_item_sales'+
  "&database="+props.database+
  "&id="+props.id
  ,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callStockItemCostPriceDetail = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?inv=select_stock_item_unit_cost_unit_price'+
  "&database="+props.database+
  "&id="+props.id
  ,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callStockItemCostPriceVarianceDetail = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?inv=select_stock_item_unit_cost_unit_price_profit'+
  "&database="+props.database+
  "&id="+props.id
  ,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callInventoryList = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?inv=select_sage_inventory'+
  "&database="+props.database+
  "&order_id="+props.order_id+
  "&exceptions_only="+props.exceptions_only
  ,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callInventoryComparisonList = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?inv=select_sage_inventory_comparison'+
  "&database="+props.database+
  "&order_id="+props.order_id+
  "&exceptions_only="+props.exceptions_only+
  "&version_id="+props.version_id
  ,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callMigrationList = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?mgx=select_migration'+
  "&created_by="+props.created_by,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callSummaryReport = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?mgx=select_migration_column'+
  "&migration_id="+props.migration_id,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callSummaryReportTable = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?mgx=migration_report_summary_table'+
  "&id="+props.migration_manifest_id
  ,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callCountRowMatchOnly = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?mgx=select_count_row_match_only'+
  "&migration_id="+props.migration_id+
  "&source_table="+props.source_table+
  "&target_table="+props.target_table
  ,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callSummaryReportList = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?mgx=select_migration_reports'+
  "&migration_id="+props.migration_id,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callTableSample = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?mgx=select_table_sample'+
  "&table_object_id="+props.table_object_id+
  "&version_id="+props.version_id,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callTableColumns = async (props:any) =>{
  let option:any = [];
  resultSet = await fetch(props.primary_host+'/dbconnect/dbdynamic.jsp?mgx=select_column_headers'+
  "&table_object_id="+props.table_object_id+
  "&version_id="+props.version_id,
  {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
  }
  ) 
  .then(response => response.json())
  .then(d => {
      
      return d
  })
  .catch(error =>{
      alert(error)
  })
  return resultSet
}
export const callLogin = async (props:any) =>{
  console.log(props)
  //var userId = 4;
  //  var forename = 'Alvin';
  //  var surname = 'Musengezi';
  //  var email = 'alvin.musengezi@yauro.co.za';
  //  var initial = 'AM';
  //  console.log('failed')
  //  var z:any =[];
  //  z.push({user_id:userId, forname:forename, surname:surname, email:email, initial:initial, set_view:1})
  //  console.log(z[0])
  //  return (z[0])
  resultSet = await fetch(props.props.primary_host+"Controller/assets/login/dbconnect/select/dbconnect.jsp?analytics=selectlogin"+
  "&email="+props.email+
  "&password="+props.password
  ,
    {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
    }
  )
  .then(response => response.json())
  .then(data =>{
      console.log(data.data[0])
    if(data.data.length > 0){
      console.log("step 1")
      //if(data.data[0].contactid/1 > 0){
          console.log("step 2")
          var userId = data.data[0].contactid;
          var forename = data.data[0].forename;
          var surname = data.data[0].surname;
          var email = data.data[0].email;
          var initial = data.data[0].initial;
          console.log("step 3")
          var z:any =[];
          z.push({user_id:userId, forename:forename, surname:surname, email:email, initial:initial, set_view:0})
          console.log(z[0])
          return (z[0])
          //childToParent=data
          //setPageView(1);
          //showReviewsTop(true); 
      //}
    }else{
      //setPageView(0);
    }
  })
  .catch(error =>{
    //var userId = 4;
    //var forename = 'Alvin';
    //var surname = 'Musengezi';
    //var email = 'alvin.musengezi@yauro.co.za';
    //var initial = 'AM';
    var userId = 0;
    var forename = '';
    var surname = '';
    var email = '';
    var initial = '';
    console.log('failed')
    var z:any =[];
    z.push({user_id:userId, forname:forename, surname:surname, email:email, initial:initial, set_view:1})
    console.log(z[0])
    return (z[0])
    //childToParent='failed'
    //setPageView(1);
    //showReviewsTop(true); 
  })
  return resultSet
} 
export const callBalanceSheetMonthlyPivot = async (props:any) =>{
  var resultSet:any;
  resultSet = await fetch(props.primary_host+"/ar/dbconnect/select.jsp?etl=selectbalancesheetpivot"+
  "&analyticalreviewsid="+props.analytical_reviews_id+
  "&yearend="+props.year_end+
  "&cumulative="+props.cumulative
  ,
    {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
    }
  )
  .then(response => response.json())
  .then(data=>{
    return data
  })
  .catch(error=>{
    alert(error)
  })
  return resultSet
}

export const searchMatchingJournals = async (props:any, journal_number:any )=>{
  var resultSet:any
  let d:any = [] 
  var z:any = null
  //setDetailTable2(z)
  //setShowLoading(true)
  resultSet = fetch(props.primary_host+"ar/dbconnect/select.jsp?etl=selectmatchingjournals"+
  "&journal_number="+journal_number+
  "&analytical_reviews_id="+props.analytical_reviews_id,
    {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
    }
  )
  .then(response => response.json())
  .then(data=>{
    d.push(data.data)
    return d;
    
  })
  .catch(error=>{
    //setShowLoading(false)
    alert(error)
  })
  return resultSet
}

export const callAnalyticalReviews  = async (props:any) =>{
    
    var result:any = []
    let card:any = []
    result = await fetch(props.primary_host+"select?etl=selectanalyticalreviews"+
    "&contactid="+props.user_id+
    "&migrationid="+0
    ,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      //console.log(data.data)
      //props.analytical_reviews_data(data.data)
      //console.log(result)
      return data.data
      
    })
    .catch(error=>{
      alert(error)
      //props.setShowLoading(false)
    })
    return result;
  }
  export const callFactDebtToEquityRatio = async (props:any) =>{
    
    var resultSet:any
    resultSet = await fetch(props.primary_host+"select?etl=selectfactdebttoequityratio"+
    "&analyticalreviewsid="+props.analytical_reviews_id+
    "&startdate="+props.start_date+
    "&enddate="+props.end_date,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      var debt_to_equity_ratio:any = []
      if(data.data.length > 0){
        for(var i = 0 ; i < data.data.length; i++){
          debt_to_equity_ratio.push({x:setDateFormatHighCharts(data.data[i].datekey), y:data.data[i].debt_to_equity_ratio/1})
        }
        //starting_debt_to_equity_ratio = (data.data[0].debt_to_equity_ratio/1).toFixed(2)
        //ending_debt_to_equity_ratio = (data.data[data.data.length -1].debt_to_equity_ratio/1).toFixed(2)
        //movement_debt_to_equity_ratio = (ending_debt_to_equity_ratio - starting_debt_to_equity_ratio).toFixed(2)
        //drawSpline("drawDebtToEquityRatio",debt_to_equity_ratio,"areaspline", "Debt to Equity Ratio", "#7CB5EC", "#9DC8F1")
        return debt_to_equity_ratio
      }
    })
    .catch(error =>{
      var x:any = <IonRow><IonCol className="ion-text-center">No Data</IonCol></IonRow>
      //setDebtToEquityRatioChart(x)
    })
    return resultSet
  }
  
  export const callFactDebtRatio = async (props:any) =>{
    console.log(props)
    var resultSet:any
    resultSet = await fetch(props.primary_host+"select?etl=selectfactdebtratio"+
    "&analyticalreviewsid="+props.analytical_reviews_id+
    "&startdate="+props.start_date+
    "&enddate="+props.end_date,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      var debt_ratio = []
      if(data.data.length > 0){
        for(var i = 0 ; i < data.data.length; i++){
          debt_ratio.push({x:setDateFormatHighCharts(data.data[i].datekey), y:data.data[i].debt_ratio/1})
        }
        //starting_debt_ratio= (data.data[0].debt_ratio/1).toFixed(2)
        //ending_debt_ratio= (data.data[data.data.length -1].debt_ratio/1).toFixed(2);
        //movement_debt_ratio = (ending_debt_ratio -starting_debt_ratio).toFixed(2)
        //drawSpline("drawDebtRatio",debt_ratio,"areaspline", "Debt Ratio", "#7CB5EC", "#9DC8F1")
        return debt_ratio
      }
    })
    return resultSet
  }
  export const callXeroAvailableYears = async (props:any) =>{
    console.log(props.primary_host)
    console.log(props.analytical_reviews_id)
    let option:any = []
    var result:any
    result = await fetch(props.primary_host+"ar/dbconnect/select.jsp?etl=selectxeroavailableyears"+
    "&id="+props.analytical_reviews_id
    ,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      option.push(data.data)
      var max_year:any= 0;
      var year_end:any=0;
      var sd:any;
      var ed:any;
      var j:any=0;
      let c = option[0].map((x:any,i:number)=>{
        return(
          <span key={i} title={"from:"+x.startdate+" to:"+x.enddate} id={"year_end_"+i} className="icon-gt-year-end-button year-end" onClick={ev=>{props.setPeriodToEpochDefault(x.startdate, x.enddate); props.callDashFunctions(); props.setSelected(ev.currentTarget.id); }}>
            {x.yearend}
          </span>
        )}
      )
      console.log(c)
      //props.years(c);
      let s = option[0].map((x:any,i:number)=>{
        props.max_data_month = x.max_data_month
        props.max_data_year = x.max_data_year
        if(max_year/1 < x.max_data_year/1)
          max_year   = x.max_data_year/1
        if(year_end/1 < x.yearend/1)
          year_end = x.yearend/1
        sd = x.startdate
        ed = x.enddate
        j = i;
        return(
          <span title={"from:"+x.startdate+" to:"+x.enddate} key={i} id={"year_end_"+i} className="icon-gt-year-end-button year-end" onClick={ev=>{props.showMonths(true);props.showDays(false);props.setYear(x.yearend);props.start_year= x.yearend;props.setSelected(ev.currentTarget.id)}}>{x.yearend}</span>
        )}
      )
      if (year_end < max_year){
        s = [s,<span title={"from:"+sd+" to:"+ed} key={j+1} id={"year_end_"+j+1} className="icon-gt-year-end-button year-end" onClick={ev=>{props.showMonths(true);props.showDays(false);props.setYear(max_year);props.start_year= max_year;props.setSelected(ev.currentTarget.id)}}>{max_year}</span>]
      }
      var resultSet:any=[];
      resultSet.push({obj:s, month:props.max_data_month, year:props.max_data_year, start:sd, end:ed, year_end:year_end})
      console.log(resultSet[0])
      return resultSet[0]
    })
    .catch(error =>{
      alert("Error could not find financial years for Company")
    })
    return result
  }

  export const callBalanceSheetInitial = async (props:any) =>{
    console.log(props[0])
    props = props[0]
    var options:any = []
    var resultSet:any;
    resultSet = await fetch(props.primary_host+"/ar/dbconnect/select.jsp?etl=selectbalancesheet"+
    "&analyticalreviewsid="+props.analytical_reviews_id+
    "&compare_mode="+props.compare_mode+
    "&year="+props.year+
    "&month="+props.month+
    "&created_by="+props.user_id
    ,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data=>{
      return(data.data)
      //  props.balance_sheet(data.data)
    })
    .catch(error=>{
      alert("Error could not get organizational data")
    })
    return resultSet
  }

  export const callBalanceSheet = async (props:any) =>{
    console.log(props[0])
    props = props[0]
    var options:any = []
    var resultSet:any;
    resultSet = await fetch(props.primary_host+"/ar/dbconnect/select.jsp?etl=selectbalancesheetdated"+
    "&analyticalreviewsid="+props.analytical_reviews_id+
    "&compare_mode="+props.compare_mode+
    "&start_date="+props.start_date+
    "&end_date="+props.end_date+
    "&year="+0+
    "&month="+0+
    "&created_by="+props.user_id
    ,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data=>{
      return(data.data)
      //  props.balance_sheet(data.data)
    })
    .catch(error=>{
      alert("Error could not get organizational data")
    })
    return resultSet
  }
  export const callSummaryDetail = async (props:any) =>{
    var resultSet:any
    var options:any = []
    resultSet = await fetch(props.primary_host+"ar/dbconnect/select.jsp?etl=selectfactaccountsdetailnetdailysummarysummary"+
    "&account_level_0_key="+props.account_level_0+
    "&account_level_1_key="+props.account_level_1+
    "&account_level_2_key="+props.account_level_2+
    "&account_level_3_key="+props.account_level_3+
    "&migrationid="+props.migration_id+
    "&analyticalreviewsid="+props.analytical_reviews_id+
    "&startdate="+props.start_date+
    "&targetcurrencyid="+props.target_currency_id+
    "&dateshortcut="+props.date_shortcut+
    "&enddate="+props.end_date,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      let d = []
      d.push(data.data)
      return d
    })
    .catch(error=>{
      //setShowLoading(false)
      alert(error)
    })     
    return resultSet;
  }
  export const callDetailTransactions = async (props:any, datekey:any, code:any)=>{
    var resultSet:any
    let d:any = []
    resultSet = await fetch(props.primary_host+"ar/dbconnect/select.jsp?etl=selectjournaldetail"+
    "&code="+code+
    "&analytical_reviews_id="+props.analytical_reviews_id+
    "&start_date="+datekey,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data=>{
      d.push(data.data)
      return d
    })
    return resultSet
  }
  export const callBalanceByAccount = async (props:any) =>{
    var result = await fetch(props.primary_host+"select?etl=selectfactaccountsbalance"+
    "&account_level_0_key="+props.account_level_0+
    "&account_level_1_key="+props.account_level_1+
    "&account_level_2_key="+props.account_level_2+
    "&account_level_3_key="+props.account_level_3+
    "&migrationid="+props.migration_id+
    "&analyticalreviewsid="+props.analytical_reviews_id+
    "&startdate="+props.start_date+
    "&targetcurrencyid="+props.target_currency_id+
    "&dateshortcut="+props.date_shortcut+
    "&enddate="+props.end_date,
    {
    headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
    }
    )
    .then(response => response.json())
    .then(data =>{
    var x:any=[]
    var y:any=[]
    var f:any=[]
    var s:any=[]
    var trend:any=[]
    var trend_rated:any=[]
    console.log(data.data.length)
    if(data.data.length > 0){
      for(var i = 0; i < data.data.length; i++){
      let balance:number = data.data[i].balance
      let datekey:number = setDateFormatHighCharts(data.data[i].datekey)
      s.push({x:datekey/1, y:balance/1})
    }
      console.log(s)
      return s;
      //props.data(s)
      //AreaChart("drawBalance",s,"areaspline", "Balance", "#7CB5EC", "#9DC8F1")
    }
    })
    .catch(error=>{
      //setShowLoading(false)
      alert(error)
    })     
    return result;
  }
  export const callFactCurrentRatio = async (props:any) =>{
    var rows1:any =[];
    var rows2:any =[];
    var resultSet:any;
    resultSet = await fetch(props.primary_host+"select?etl=selectfactcurrentratio"+
    "&analyticalreviewsid="+props.analytical_reviews_id+
    "&startdate="+props.start_date+
    "&targetcurrencyid="+props.target_currency_id+
    "&enddate="+props.end_date,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      //rows1.push(data.data)
      var x:any=[]
      var y:any=[]
      var f:any=[]
      var s:any=[]
      var trend:any=[]
      var trend_rated:any=[]
      console.log(data.data.length)
      var current_assets = []
      var current_liabilities = []
      var current_ratio:any = []
      var current_ratio2 = []
      var current_ratio_limits = []
      if(data.data.length > 0){
        for(var i = 0 ; i < data.data.length; i++){
          current_assets.push({x:setDateFormatHighCharts(data.data[i].datekey), y:data.data[i].current_assets/1})
          current_liabilities.push({x:setDateFormatHighCharts(data.data[i].datekey), y:data.data[i].current_liability/1, color:'#0066ff', dashStyle:'ShortDash'})
          current_ratio.push({x:setDateFormatHighCharts(data.data[i].datekey), y:data.data[i].current_ratio/1})
          current_ratio2.push({x:setDateFormatHighCharts(data.data[i].datekey), y:data.data[i].current_ratio/1})
          current_ratio_limits.push({x:setDateFormatHighCharts(data.data[i].datekey), y:1.5 })
        }
        var x:any = []
        x.push({"current_ratio":current_ratio, "current_assets":current_assets, "current_liabilities":current_liabilities})
        return x
        //starting_current_ratio = (data.data[0].current_ratio/1).toFixed(2);
        //ending_current_ratio = (data.data[data.data.length-1].current_ratio/1).toFixed(2);
        //movement_current_ratio = (ending_current_ratio - starting_current_ratio).toFixed(2) ;
        //
        //drawSpline("drawCurrentRatio",current_ratio,"areaspline", "Current Ratio", "#7CB5EC", "#9DC8F1")
        //drawAreaLineSeries("drawCurrentAssetsAndCurrentLiabilitiesChart", current_assets,'Current Assets',current_liabilities,'Current Liabilities')
      }
    })
    return resultSet
  }
  export const callFactWorkingCapitalToAssetsRatio = async (props:any) =>{
    var resultSet:any 
    resultSet = await fetch(props.primary_host+"select?etl=selectfactworkingcapitaltototalassetsratio"+
    "&analyticalreviewsid="+props.analytical_reviews_id+
    "&startdate="+props.start_date+
    "&targetcurrencyid="+props.target_currency_id+
    "&enddate="+props.end_date,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      
      var working_capital_to_assets_ratio:any = []
      if(data.data.length > 0){
        for(var i = 0 ; i < data.data.length; i++){
          working_capital_to_assets_ratio.push({x:setDateFormatHighCharts(data.data[i].datekey), y:data.data[i].working_capital_to_assets_ratio/1})
        }
        //starting_working_capital = (data.data[0].working_capital_to_assets_ratio/1).toFixed(2);
        //ending_working_capital = (data.data[data.data.length -1].working_capital_to_assets_ratio/1).toFixed(2);
        //movement_working_capital = (ending_working_capital - starting_working_capital).toFixed(2);
        //drawSpline("drawWorkingRatio",working_capital_to_assets_ratio,"areaspline", "Working Capital To Assets Ratio", "#7CB5EC", "#9DC8F1")
      }
      return working_capital_to_assets_ratio
    })
    return resultSet
  }
  export const callMovementByAccount = async (props:any) =>{
    var result =await fetch(props.primary_host+"select?etl=selectfactaccountsnetdaily"+
      "&account_level_0_key="+props.account_level_0+
      "&account_level_1_key="+props.account_level_1+
      "&account_level_2_key="+props.account_level_2+
      "&account_level_3_key="+props.account_level_3+
      "&migrationid="+props.migration_id+
      "&analyticalreviewsid="+props.analytical_reviews_id+
      "&startdate="+props.start_date+
      "&targetcurrencyid="+props.target_currency_id+
      "&dateshortcut="+props.date_shortcut+
      "&enddate="+props.end_date,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      var x:any=[]
      var y:any=[]
      var f:any=[]
      var s:any=[]
      var trend:any=[]
      var trend_rated:any=[]
      console.log(data.data.length)
      if(data.data.length > 0){
        for(var i = 0; i < data.data.length; i++){
          let movement:number = data.data[i].value
          let datekey:number = setDateFormatHighCharts(data.data[i].datekey)
          s.push({x:datekey/1, y:movement/1})
        }
        console.log(s)
        return s;
      //drawSpline("drawMovement",s,"spline", "Movement", "#7CB5EC", "#9DC8F1")
      }
    })        
    .catch(error=>{
      //setShowLoading(false)
      alert(error)
    })
    return result
  }

  export const callFactIncomeExpenses = async (props:any) =>{
    var resultSet:any = []
    resultSet = await fetch(props.primary_host+"ar/dbconnect/select.jsp?etl=selectfactaccountsnetbyday"+
    "&migrationid="+props.migration_id+
    "&analyticalreviewsid="+props.analytical_reviews_id+
    "&startdate="+props.start_date+
    "&targetcurrencyid="+props.target_currency_id+
    "&dateshortcut="+props.date_shortcut+
    "&enddate="+props.end_date,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      //options.push(data.data)
      var x = []
      var v = []
      var a = []
      var ic = []
      var e = []
      let ici:number = 0
      let ee:number = 0
      
      console.log(data.data.length)
      if(data.data.length > 0){
        for(var i = 0; i < data.data.length; i++){
          ici += data.data[i].income/1
          ee += data.data[i].expense/1
          x.push(setDateFormatHighCharts(data.data[i].datekey))
          ic.push([setDateFormatHighCharts(data.data[i].datekey), (data.data[i].income/1)/1])
          e.push([setDateFormatHighCharts(data.data[i].datekey), (data.data[i].expense/1)/1])
          v.push([setDateFormatHighCharts(data.data[i].datekey), (data.data[i].income/1 - data.data[i].expense/1)/1])
        }
        //drawNetMonthlyNetBar(v,x,'Net Income By Day')
        //drawNetMonthlyEBar(ic,e,x,'Income By Day','Expense By Day' )
      }
      var resultsData:any=[]
      resultsData.push({net_income_data:v, income_data:ic, expense_data:e, datekey:x })
      return resultsData;
    })
    return resultSet
  }
  export const callFactAccountBalance = async (props:any) =>{
    var resultSet:any;
    //setShowLoading(true)
    resultSet = await fetch(props.primary_host+"select?etl=selectfactaccountsbalance"+
    "&account_level_0_key="+props.account_level_0_key+
    "&account_level_1_key="+props.account_level_1_key+
    "&account_level_2_key="+props.account_level_2_key+
    "&account_level_3_key="+props.account_level_3_key+
    "&migrationid="+0+
    "&analyticalreviewsid="+props.analytical_reviews_id+
    "&startdate="+props.start_date+
    "&targetcurrencyid="+88+
    "&dateshortcut="+0+
    "&enddate="+props.end_date,
      {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
    )
    .then(response => response.json())
    .then(data =>{
      var x:any=[]
      var y:any=[]
      var f:any=[]
      var s:any=[]
      var trend:any=[]
      var trend_rated:any=[]
      console.log(data.data.length)
      if(data.data.length > 0){
        for(var i = 0; i < data.data.length; i++){
          let balance:number = data.data[i].balance
          let datekey:number = setDateFormatHighCharts(data.data[i].datekey)
          s.push({x:datekey/1, y:balance/1})
        }
        console.log(s)
        return s
      }
    })
    .catch(error =>{
        //setShowLoading(false)
        alert("Error fetching data:"+error)
    })
    return resultSet
  }
  export const callCustomCharts = async (props:any) =>{ 
    var resultSet:any
    //setShowLoading(true)
    resultSet = await fetch(props.primary_host+"ar/dbconnect/select.jsp?etl=selectfactaccountsbalancebyaccounts"+
        "&account_level_0_key="+props.account_level_0_key +
        "&account_level_1_key="+props.account_level_1_key+
        "&account_level_2_key="+props.account_level_2_key+
        "&account_level_3_key="+props.account_level_3_key+
        "&migrationid="+0+
        "&analyticalreviewsid="+props.analytical_reviews_id+
        "&startdate="+props.start_date+
        "&targetcurrencyid="+88+
        "&dateshortcut="+0+
        "&enddate="+props.end_date,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
    )
    .then(response => response.json())
    .then(data =>{
        //setShowLoading(false)
        var series =[]
        for(var i = 0 ; i < data.data.length; i++){
        var s = []

        for(var j= 0 ; j < data.data[i].account.length; j++){
            s.push({x:setDateFormatHighCharts(data.data[i].account[j].datekey), y:data.data[i].account[j].balance/1})
        }
        series.push({name:data.data[i].account_level_1, data:s})
        }
        return series
        //drawlineSeries("drawBalanceAccounts",s, "line", series)
    })
    .catch(error =>{
        //setShowLoading(false)
        alert("Error fetching data:"+error)
    })
    return resultSet
  }
    
  export const restoreJournalsFile = async (props:any) =>{
    //let option:any = [];
    resultSet = await fetch(props.primary_host+'/dmz/dbconnect/select.jsp?etl=restorejournalsfile'+
    "&versionid="+props.version_id+
    "&filename="+props.gl_file_name+
    "&projectname="+props.project_name+
    "&createdby="+props.created_by+
    "&packageid="+props.package_id+
    "&application_id="+props.application_id+
    "&migration_id="+props.migration_id,
    {
        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
    }
    ).then(response => response.json())
    .then(data =>{
      return data.data;
    })
    .catch(error =>{
        alert(error)
    })
    return resultSet
  }
  export const runJournalsWithFilters= async (props:any)=>{
      resultSet = await fetch(props.primary_host+'/dmz/dbconnect/select.jsp?etl=runjournalswithfilter'+
        "&versionid="+props.glVersionId+
        "&filter="+props.jnlFilter+
        "&startdate="+props.startdate+
        "&enddate="+props.enddate+
        "&ctt="+props.ctt+
        "&migration_id="+props.migrationId+
        "&packageid="+props.packageId+
        "&application_id="+props.applicationId,
      {
          headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
      )
      .then(response => response.json())
      .then(d =>{
          return d.data
      })
      .catch(error =>{
        alert(error)
    })
    return resultSet
  }

export const callJournalProjects = async (props:any) =>{
  //setShowLoading(true)
  let card:any = []
  resultSet = await fetch(props.primary_host+"select?etl=selectmigration"+
  "&contactid="+props.user_id+
  "&applicationid="+66
  ,
    {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
    }
  )
  .then(response => response.json())
  .then(data =>{
    return data.data
    
  })
  .catch(error=>{
    //alert("call journal projects:"+error)
    //setShowLoading(false)
  })
  return resultSet
}
export const callAccountComparisonByYearCorrelationMatrix = async (props:any) =>{
  resultSet = await fetch(props.primary_host+"dbconnect/dbdynamic.jsp?pre=select_account_comparison_by_year_correlation"+
      "&period_type="+0+
      "&account_level_1_key="+props.key+
      "&analyticalreviewsid="+props.analytical_reviews_id+
      "&startdate="+props.start_date+
      "&enddate="+props.end_date,
      {
          headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
  )
  .then(response => response.json())
  .then(data =>{
      return data
  })
  .catch(error=>{
      alert(error)
  })
  return resultSet
}
export const callAccountComparisonByYear = async (props:any) =>{
  resultSet = await fetch(props.primary_host+"dbconnect/dbdynamic.jsp?pre=select_account_comparison_by_year"+
      "&period_type="+0+
      "&account_level_1_key="+props.key+
      "&analyticalreviewsid="+props.analytical_reviews_id+
      "&startdate="+props.start_date+
      "&enddate="+props.end_date,
      {
          headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
  )
  .then(response => response.json())
  .then(data =>{
      return data
  })
  .catch(error=>{
      alert(error)
  })
  return resultSet
}
export const callYearlyComparison = async (props:any) =>{
  //setShowLoading(true)
 
  resultSet = await fetch(props.primary_host+"dbconnect/dbdynamic.jsp?pre=select_period_year_comparison"+
      "&period_type="+0+
      "&account_level_1_key="+props.key+
      "&account_names="+props.account_names+
      "&analyticalreviewsid="+props.analytical_reviews_id+
      "&startdate="+props.start_date+
      "&enddate="+props.end_date,
      {
          headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
  )
  .then(response => response.json())
  .then(data =>{
      return data
  })
  .catch(error=>{
      alert(error)
  })
  return resultSet
}
export const exportResults = async (props:any) => {
  //setShowLoading(true)
  console.log(
  props.primary_host+"GetARReport?"+
  "&account_level_0_key="+props.account_level_0+
  "&account_level_1_key="+props.account_level_1+
  "&account_level_2_key="+props.account_level_2+
  "&account_level_3_key="+props.account_level_3+
  "&year="+0+
  "&month="+0+
  "&migrationid="+props.migration_id+
  "&analytical_report_id="+props.analytical_report_id+
  "&analytical_reviews_id="+props.analytical_reviews_id+
  "&startdate="+props.start_date+
  "&targetcurrencyid="+props.target_currency_id+
  "&dateshortcut="+props.date_shortcut+
  "&enddate="+props.end_date+
  "&compare_mode="+props.compare_mode
  )
  if(props.analytical_report_id == 11){
    props.compare_mode = props.cumulative;
  }
  fetch(props.primary_host+"GetARReport?"+
  "&account_level_0_key="+props.account_level_0+
  "&account_level_1_key="+props.account_level_1+
  "&account_level_2_key="+props.account_level_2+
  "&account_level_3_key="+props.account_level_3+
  "&year="+0+
  "&month="+0+
  "&migrationid="+props.migration_id+
  "&analytical_report_id="+props.analytical_report_id+
  "&analytical_reviews_id="+props.analytical_reviews_id+
  "&startdate="+props.start_date+
  "&targetcurrencyid="+props.target_currency_id+
  "&dateshortcut="+props.date_shortcut+
  "&enddate="+props.end_date+
  "&compare_mode="+props.compare_mode,
    {
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
    }
  )
  .then(response => 
    //window.location.replace(primaryhost+"/files/out/"+response);
    response.text()
    
  ) 
  .then(data =>{
    //alert("response.data"+data);
    //setShowLoading(false)
    window.location.replace(props.primary_host+"/files/out/"+data);
    
  })
  .catch(error=>{
    //setShowLoading(false)
    alert("An error occurred whilst exporting data")
  })
  
}