import {  } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonIcon, IonCard, IonCardHeader, IonTitle, IonCardContent, IonFooter, IonHeader, IonToggle, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonButton, IonListHeader } from '@ionic/react';
import { checkmarkOutline, createOutline, pencilOutline, settingsOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { insertMigration } from '../../dbconnections/Insert';
import { selectVersion } from '../../dbconnections/Select';
import "../DataImport/Import.css"
import FileLoader from '../FileLoader/fileloader';
import "./NewProject.css"

let projectName :any;
let ctt         :any;
let rounding    :any;
let job         :any;
let keyword     :any;
let country     :any;
let analyticalReviewsId:any;
let versionId   :any;

const NewCustomProjectForm = (props:any) =>{
    //console.log(props)
    const [getNewReviews, setNewReviews]                = useState<any>()
    const [getExistingReviews, setExistingReviews]      = useState<any>()
    const [getDuplicate, setDuplicate]                  = useState<any>()
    const [getProjectName, setProjectName]              = useState<any>()
    const [getBilling, setBilling]                      = useState<any>()
    const [getCountry, setCountry]                      = useState<any>()
    const [hideContinue, showContinue]                  = useState<any>()
    const [getCTT, setCTT]                              = useState<any>(0)
    const [getRounding, setRounding]                    = useState<any>('')
    const [getKeyword, setKeyword]                      = useState<any>('')
    const txtJournalProjectName:any                     = useRef<HTMLIonInputElement>();
    
    const ddlRounding:any                               = useRef<HTMLIonSelectElement>();
    const txtJobCode:any                                = useRef<HTMLIonInputElement>();
    const txtKeywordTextSearch:any                      = useRef<HTMLIonInputElement>();
    const ddlCountry:any                                = useRef<HTMLIonSelectElement>();
    const chkNoTB:any                                   = useRef<HTMLIonCheckboxElement>();
    const ddlDelimiter:any                              = useRef<HTMLIonSelectElement>();
    const ddlWorksheet:any                              = useRef<HTMLIonRadioGroupElement>();
    const ddlTextQualifier:any                          = useRef<HTMLIonSelectElement>();
    const txtFileDummy:any                              = useRef<HTMLIonInputElement>();
    const txtStartDate:any                              = useRef<HTMLIonDatetimeElement>();
    const txtEndDate:any                                = useRef<HTMLIonDatetimeElement>();
    const createProject = () =>{
        var x:any = []
        
        if(props.application_id == 67){
            rounding = 0
            ctt = 0
        } 

        x.push({
            project_name:txtJournalProjectName.current.value,
            billing_code:txtJobCode.current.value,
            ctt:ctt,
            country:ddlCountry.current.value,
            version_id:versionId,
            rounding:rounding,
            user_id:props.state.props.user_id,
            keyword:getKeyword,
            package_id:props.package_id,
            application_id:props.application_id,
            primary_host:props.state.props.primary_host
        })
        insertMigration(x[0])
        .then(response=>{console.log(response);return response})
        .then(data=>{
            props.setMigrationId(data.migrationid)
            //alert(data.migrationid)
        }) 
    }
    const formValidation = (form:any) =>{
        var x:any = document.getElementsByClassName("check")
        switch(form){
            case 1: 
            if(x.length >= 3){
                showContinue(true)
            }else{
                showContinue(false)
            }
            break;
        }
    }
    const resetFormValidation =()=>{
        showContinue(false)
    }
    const [getMateriality   , setMateriality]               =useState<boolean>(false)

    return (
            <div className="general-form-left-division size-12">
                <div className="ion-text-center">
                    <IonRow>
                        <IonCol>
                            <IonIcon icon={createOutline} className="text-gt size-48"></IonIcon>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center size-20">
                            New File Details
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <FileLoader 
                                state={props.state} 
                                form_name="Custom Data" 
                                restore_id={1} 
                                ctt={getCTT}
                                rounding ={""}
                                migration_name ={getProjectName}
                                billing_code = {'CUSTOM'}
                                country = {"0"}
                                migration_id = {props.state.migration_id}
                                load_counter = {(e:any)=>{}}
                                file_name = {(e:any)=>{}}
                                gl_version_id={(e:any)=>{}}
                            />
                        </IonCol>
                    </IonRow>
                </div>
            </div>
        )
    
}
export default NewCustomProjectForm;