import { IonModal, IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonLoading, IonItem, IonTextarea, IonButton, IonSelect, IonSelectOption, IonList, IonSearchbar } from '@ionic/react';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, trendingUpSharp, trashBinOutline, closeOutline, peopleOutline, sendOutline, personOutline, checkmarkOutline, pencilOutline} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import $ from 'jquery';

var shareId:any = ''
function validateEmail(obj:any,valueToTest:any){
    var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (testEmail.test(valueToTest)){
        // Do whatever if it passes
        console.log(1)
        $(obj).css("color",'#000')
        return true
    }else{
        // Do whatever if it fails.
        console.log(2)
        $(obj).css("color",'red')
        return false
    }
}
const ShareJETsProject = (props:any) =>{
    const [hideShareModal, showShareModal]                      =   useState<boolean>(false)
    const [getActiveUsers, setActiveUsers]                      =   useState<any>()
    const [hideContinue,showContinue]                           = useState<boolean>(false)
    let txtEmailShare:any                                       =   useRef<HTMLIonInputElement>()
    let txtShareEmail                                           = useRef<HTMLIonInputElement>(null);
    let txtShareEmailSearch                                     = useRef<HTMLIonSearchbarElement>(null);
    let txtShareForename                                        = useRef<HTMLIonInputElement>(null);
    let txtShareSurname                                         = useRef<HTMLIonInputElement>(null);
    let ddlShareDesignation                                     = useRef<HTMLIonSelectElement>(null)
    const [checkEmail, setEmail]                                = useState<boolean>(false)
    const [checkForename, setForename]                          = useState<boolean>(false)
    const [checkSurname, setSurname]                            = useState<boolean>(false)
    const [checkDesignation, setDesignation]                    = useState<boolean>(false)
    const [hideSearchDetail, showSearchDetail]                  = useState<boolean>(true)
    const [hideSearchDesignation, showSearchDesignation]        = useState<boolean>(false)
    const [getSearchList, setSearchList]                        = useState<any>()
    
    const formValidation = (form:any) =>{
        var x:any = document.getElementsByClassName("check")
        switch(form){
            case 1: if(x.length == 4){
                        showContinue(true)
                    }else{
                        showContinue(false)
                    }
                    break;
            case 2: 
  
        }
    }
    
    const callSearchContact = (searchString:any) =>{
      txtShareEmail.current!.value = txtShareEmailSearch.current!.value
      if (searchString.length >= 3){
        let list:any;
        fetch(props.state.primary_host+"journals/dbconnect/select.jsp?etl=selectcontactlist"+
          "&email="+searchString,
          {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
          }
        )
        .then(response => response.json())
        .then(data =>{
          let d:any = []
          d.push(data.data)
          list = (
            <IonItem className="search" key={-1} onClick={ev=>{
              let y:any=null; 
              setSearchList(y); 
              shareId = 0;
              txtShareEmail.current!.value = "";
              txtShareForename.current!.value = "";
              txtShareSurname.current!.value = "";
              }}>
              <IonRow>
                <IonCol>
                  <IonIcon icon={sendOutline} className="create-new-button" ></IonIcon>&nbsp;<b>Create New Contact</b> 
                </IonCol>
              </IonRow>
            </IonItem>
          )
          list = d[0].map((x:any, i:number)=>{
            
            if(x.surname != "" && x.forename != ""){
              console.log(x.forename+" "+x.surname)
              if(i == 0){
                return(
                  <div>
                    <IonItem className="search" key={-1} onClick={ev=>{
                      let y:any=null; 
                      setSearchList(y); 
                      shareId = 0;
                      txtShareEmail.current!.value = searchString;
                      txtShareForename.current!.value = "";
                      txtShareSurname.current!.value = "";
                      ddlShareDesignation.current!.value = null;
                      }}>
                      <IonRow className="search-list-row">
                        <IonCol>
                          <IonIcon icon={sendOutline} color="primary" ></IonIcon>&nbsp;<b>Create New Contact</b> 
                        </IonCol>
                      </IonRow>
                    </IonItem>
                    {x.email != ""?
                      <IonItem className="search" key={i} onClick={ev=>{
                        let y:any=null; 
                        shareId = x.contactid; 
                        setSearchList(y);
                        txtShareForename.current!.value = x.forename;
                        txtShareSurname.current!.value = x.surname;
                        txtShareEmail.current!.value = x.email;
                        }}>
                        <IonRow className="search-list-row">
                          <IonCol>
                            <IonIcon icon={sendOutline} color="primary" ></IonIcon>&nbsp;{x.email} 
                          </IonCol>
                          <IonCol>
                            <IonIcon icon={personOutline} color="primary"></IonIcon>&nbsp;<span>{x.forename} {x.surname}</span>
                          </IonCol>
                        </IonRow>
                      </IonItem>
                      :
                      <div></div>
                    }
                  </div>
                  )
                }else{
                return(
                  <IonItem className="search" key={i} onClick={ev=>{
                    let y:any=null; 
                    shareId = x.contactid; 
                    setSearchList(y);
                    txtShareForename.current!.value = x.forename;
                    txtShareSurname.current!.value = x.surname;
                    txtShareEmail.current!.value = x.email;
                    }}>
                    <IonRow className="search-list-row">
                      <IonCol size="6">
                        <IonIcon icon={sendOutline} color="primary" ></IonIcon>&nbsp;{x.email} 
                      </IonCol>
                      <IonCol size="6">
                        <IonIcon icon={personOutline} color="primary"></IonIcon>&nbsp;<span>{x.forename} {x.surname}</span>
                      </IonCol>
                    </IonRow>
                  </IonItem>
                  )
                }
              }
            }
          )
          setSearchList(list)
        })
      }
    }
    
    const insertJournalsContacts = () =>{
      fetch(props.state.primary_host+"/journals/dbconnect/insert.jsp?etl=insertjournalscontacts"+
        "&createdby="+props.state.user_id+
        "&contact_id="+shareId+
        "&designation_id="+ddlShareDesignation.current!.value!+
        "&migration_id="+props.state.migration_id+
        "&email="+txtShareEmail.current!.value!+
        "&forename="+txtShareForename.current!.value!+
        "&surname="+txtShareSurname.current!.value!,
        {
          headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
      )
      .then(response => response.json())
      .then(data =>{
          shareJournalProject()
          resetJournalContacts()
      })
      .catch(error =>{
          alert("insert journal contact:"+error)
      })
    }
    const resetJournalContacts = () =>{
      if(ddlShareDesignation.current != null)
        ddlShareDesignation.current!.value = null
      if(txtShareForename.current! != null)
        txtShareForename.current!.value! = "";
      if(txtShareSurname.current! != null)
        txtShareSurname.current!.value! = "";
      if(txtShareEmail.current! != null)
        txtShareEmail.current!.value! = "";
      if(txtShareEmailSearch.current! != null)
        txtShareEmailSearch.current!.value! = "";
    }
    const shareJournalProject = () =>{
        var list:any = []
        fetch(props.state.primary_host+"/journals/dbconnect/select.jsp?etl=selectjournalprojectcontacts"+
        "&migration_id="+props.state.migration_id+
        "&contactid"+props.state.user_id,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            list.push(data.data);
            var contacts:any = list[0].map((x:any, i:number)=>{
            return(
                <IonRow>
                    <IonCol size="11">{x.email}</IonCol>
                    <IonCol size="1"><IonIcon color="danger" onClick={()=>{}} icon={closeOutline}></IonIcon></IonCol>
                </IonRow>
            )
            })
            setActiveUsers(contacts)
        })
        .catch(error =>{
            alert(error +": Get Journal Contacts")
        })
    }
    
    const [hideModal, showModal]                    = useState<any>(false)
    const [getState, setState]                      = useState<any>(false)
    useEffect(() => {
      //alert()
      //setState(props.modal)
      //showShareModal(props.modal)
      //callProjectContacts()
    }, []);
    return(
        <div>
          <IonRow>
            <IonCol >
              <IonLabel><b>Search Email Address</b></IonLabel>
              <IonSearchbar onIonChange={ev=>{callSearchContact(ev.detail.value)}} placeholder="Enter Email Address" ref={txtShareEmailSearch} type="text"></IonSearchbar>
              <div>
                <IonList>
                  {getSearchList}
                </IonList>
              </div>
            </IonCol>
          </IonRow>
          <div className="config-2-1">
            <IonHeader className="nav-beam">
              <IonRow>
                <IonCol>
                  <IonIcon icon={pencilOutline} size="large" ></IonIcon>
                  &nbsp;&nbsp;&nbsp;<b>Create New Contact / Contact Details</b>
                </IonCol>
              </IonRow>
            </IonHeader>
              <IonRow className="ion-padding">
                <IonCol size="11">
                  <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput ref={txtShareEmail} type="text" onIonChange={ev=>{setEmail(validateEmail(txtShareEmail.current,txtShareEmail.current!.value)); formValidation(1)}}></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="1">
                {checkEmail ?
                  <IonIcon icon={checkmarkOutline} color="success" size="large" className="check"></IonIcon>
                  :
                  <IonIcon icon={closeOutline} color="danger" size="large" className=""></IonIcon>
                }
                </IonCol>
              </IonRow>
              <IonRow className="ion-padding">
                <IonCol size="5">
                  <IonItem>
                    <IonLabel position="floating">Forename</IonLabel>
                    <IonInput ref={txtShareForename} type="text" onIonChange={ev=>{if(txtShareForename.current!.value!.toString().length >=2){setForename(true)}else{setForename(false)}; formValidation(1)}}></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="1">
                {checkForename ?
                  <IonIcon icon={checkmarkOutline} color="success" size="large" className="check"></IonIcon>
                  :
                  <IonIcon icon={closeOutline} color="danger" size="large" className=""></IonIcon>
                } 
                </IonCol>
                <IonCol size="5"> 
                  <IonItem>
                    <IonLabel position="floating">Surname</IonLabel>
                    <IonInput ref={txtShareSurname} type="text" onIonChange={ev=>{if(txtShareSurname.current!.value!.toString().length >=2){setSurname(true)}else{setSurname(false);}; formValidation(1)}}></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="1">
                {checkSurname ?
                  <IonIcon icon={checkmarkOutline} color="success" size="large" className="check"></IonIcon>
                  :
                  <IonIcon icon={closeOutline} color="danger" size="large" className=""></IonIcon>
                } 
                </IonCol>
              </IonRow>
              <IonRow className="ion-padding">
                <IonCol size="11">
                  <IonItem>
                    <IonLabel position="floating">Designation</IonLabel>
                    <IonSelect ref={ddlShareDesignation} onIonChange={ev=>{if(ddlShareDesignation.current!.value != null){setDesignation(true)}else{setDesignation(false)}; formValidation(1)}}>
                      <IonSelectOption value="1185">Director</IonSelectOption>
                      <IonSelectOption value="1186">Manager</IonSelectOption>
                      <IonSelectOption value="1187">Accounts</IonSelectOption>
                      <IonSelectOption value="1188">Other</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="1">
                  {checkDesignation ?
                    <IonIcon icon={checkmarkOutline} color="success" size="large" className="check"></IonIcon>
                    :
                    <IonIcon icon={closeOutline} color="danger" size="large" className=""></IonIcon>
                  }
                </IonCol>
              </IonRow>
              <IonRow className="ion-padding">
                <IonCol size="10"></IonCol>
                <IonCol size="1" >
                {hideContinue &&  <IonButton onClick={ev=>{insertJournalsContacts()}}>Share</IonButton>}
                </IonCol>                        
              </IonRow> 
            </div>
        </div>
    )
}
export default ShareJETsProject