import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonIcon, IonCard, IonCardHeader, IonTitle, IonCardContent, IonFooter, IonImg, IonHeader, IonItem, IonLabel, IonButton, IonInput, IonCheckbox, IonSpinner, IonContent, IonLoading, IonSelectOption, IonSelect } from '@ionic/react';
import {arrowForward, arrowForwardOutline, checkmarkOutline, chevronForward, close, closeOutline, cloudOutline, cloudUploadOutline, documentOutline, settingsOutline} from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { runInThisContext } from 'vm';
import { restoreJournalsFile, runJournalsWithFilters, selectVersion } from '../../dbconnections/Select';
//import { setJournalFilter } from '../../functions/functions';
import "../DataImport/Import.css"
import FileLoader from '../FileLoader/fileloader';
import NewProjectForm from '../Forms/NewProject';
import Xero from '../Xero';
let trCodeList:any=[];
let jnlFilter:any;
let counter:any = 0;
let glVersionId:any = 0;
let glFileName:any = "";
let files:any ={
  opening:0,
  closing:0,
  gl:0,
  coa:0,
}
let errors:boolean = false;

const Import = (props:any) =>{
    const [getJNLFilter, setJNLFilter]                  = useState<string>("");
    const getTestArray = () =>{
      var jnl ="";
      console.log("start:"+jnl)
      for(var i = 0 ; i < trCodeList.length; i++){
        if(jnl != ""){
          if(trCodeList[i].isChecked)
            jnl = jnl + ",\"jnl_"+ trCodeList[i].id+"\"";
        }
        if(jnl == ""){
          if(trCodeList[i].isChecked){
            jnl = "\"jnl_"+ trCodeList[i].id+"\"";
          }
        } 
        console.log("loop:"+i+":"+jnl)
      }
      console.log("end:"+jnl)
      setJNLFilter(jnl)
      return jnl
    }
    const setJournalFilter = (id:any) =>{
      for(var i = 0; i < trCodeList.length; i++){
          console.log("Before:"+trCodeList[i].id+" "+trCodeList[i].isChecked)
          if(trCodeList[i].id == id){
              trCodeList[i].isChecked = !trCodeList[i].isChecked;
          }
          console.log("After:"+trCodeList[i].id+" "+trCodeList[i].isChecked)
      }
      
      jnlFilter="";
      for(var i = 0 ; i < trCodeList.length; i++){
          if(trCodeList[i].isChecked && jnlFilter.length > 0){
              jnlFilter = jnlFilter + ",\"jnl_"+ trCodeList[i ].id+"\"";
          }
          if(trCodeList[i].isChecked && jnlFilter.length == 0){
              jnlFilter = "\"jnl_"+ trCodeList[i].id+"\"";
          }
          
      }
      getTestArray()
  }
  const [getSelectorManualTop, setSelectorManualTop] = useState<any>("ml-top-panel-up")
  const [getFileName, setFileName]                = useState<any>()
  const [getIntegrityData, setIntegrityData]      = useState<any>()
  const [getIntegrityReport, setIntegrityReport]  = useState<any>()
  const [getMainOptions, setMainOptions]      = useState<any>(true)
  const [getSetupDetails, setSetupDetails]    = useState<any>(false)
  const [getUploads, setUploads]              = useState<any>(false)

  const [getOnlineSystems, setOnlineSystems]  = useState<any>(true)
  const [getFileSystems, setFileSystems]      = useState<any>(true)
  const [getDatabaseSystems, setDatabaseSystems] = useState<any>(true)

  const [getProjectName, setProjectName]      = useState<any>('')
  const [getRounding, setRounding]            = useState<any>('')
  const [getBillingCode, setBillingCode]      = useState<any>('')
  const [getCTT, setCTT]                      = useState<any>(0)
  const [getCountry, setCountry]              = useState<any>(0)
  const [getMigrationId, setMigrationId]      = useState<any>(0)
  const [getGLVersionId, setGLVersionId]          = useState<any>(0)
  const [getGLName, setGLName]                = useState<any>("")
  const [getStructuredForm5, setStructuredForm5] = useState<any>(false)
  const [getStructuredForm6, setStructuredForm6] = useState<any>(false)
  const [getXero, setXero]                    = useState<any>(false)
  const [getPackageId, setPackageId]          = useState<any>()

  const [getFileCounter, setFileCounter]      = useState<number>(0)
  const [getLoader, setLoader]                        = useState<HTMLIonSelectOptionElement>();

  const [getTransactionCodes, setTransactionCodes ]   = useState<any>();
  const [getTestResults,setTestResults]               = useState<HTMLIonItemElement>();
  const [getFileTestResults,setFileTestResults]       = useState<HTMLIonItemElement>();

  let txtStartDate:any                            = useRef<HTMLIonInputElement>()
  let txtEndDate:any                              = useRef<HTMLIonInputElement>()
  const runTest = () =>{
      let a:any = <IonSpinner name="dots" ></IonSpinner>
      setShowLoading(true)
      setLoader(a)
      setTestResults(a)
      var d:any = []
      if(props.application_id == 67){
        d.push({
          primary_host:props.props.primary_host,
          glVersionId:glVersionId,
          jnlFilter:jnlFilter,
          startdate:txtStartDate.current.value,
          enddate:txtEndDate.current.value,
          ctt:0,
          migrationId:getMigrationId,
          packageId:getPackageId,
          applicationId:props.application_id
        })
      }
      if(props.application_id == 66){
        d.push({
          primary_host:props.props.primary_host,
          glVersionId:glVersionId,
          jnlFilter:jnlFilter,
          startdate:txtStartDate.current.value,
          enddate:txtEndDate.current.value,
          ctt:getCTT,
          migrationId:getMigrationId,
          packageId:getPackageId,
          applicationId:props.application_id
        })
      }
      var option:any = []
      runJournalsWithFilters(d[0])
      .then(response=>{return response})
      .then(data =>{
        console.log(data)
        option.push(data)
        let results = option[0].map((x:any, i:number)=>{
          return(
            <IonCol className="" key={i} size="4">
              <IonRow className="">
                <IonCol size="10" className="">
                  {x.listdesc}
                </IonCol>
                <IonCol size="2" className="">
                  {
                    (
                      x.error == 0 ?
                      <IonIcon color="success" size="large" icon={checkmarkOutline}></IonIcon>:
                      <IonIcon color="danger" size="large" icon={closeOutline}></IonIcon>
                    )
                  }
                </IonCol>
              </IonRow>
            </IonCol>
          )
        })
        setShowLoading(false)
        setStructuredForm5(false)
        setStructuredForm6(true)
        a = null
        setTestResults(a)
        setTestResults(results)
      })
    }
    const setSystem = (s:any) =>{
      resetSystem()
      switch(s){
        case 1: setFileSystems(false); setDatabaseSystems(false);setView(2);break;
        case 2: setOnlineSystems(false); setDatabaseSystems(false);setView(4);break;
        case 3: setFileSystems(false); setOnlineSystems(false);setView(2);break;
      }
    }

    const resetSystem = () =>{
      setOnlineSystems(true);
      setFileSystems(true);
      setDatabaseSystems(true);
      setSetupDetails(false);
      setUploads(false);
    }

    const setView = (v:any) =>{
      resetView()
      switch(v){
        case  1: setMainOptions(true); break;
        case  2: setMainOptions(true);setSetupDetails(true); break;
        case  3: setMainOptions(true);setSetupDetails(true);setUploads(true); break;
        case  4: setXero(true);break;
      }
    }
    const resetView = () =>{
      setMainOptions(false); 
      setSetupDetails(false);
      setUploads(false);     
    }
    const [getResults, setResults]                    = useState<any>()
    const [getShowLoading, setShowLoading]              = useState<boolean>(false)
    const [getVersionId, setVersionId]            = useState<any>()

    const callTransactionCodes = () =>{
      var option:any = [];
      fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?dbo=select_transaction_codes"+
      "&version_id="+glVersionId
      ,
      {
          headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
      )
      .then(response => response.json()) 
      .then(data =>{
        setShowLoading(false)
        setStructuredForm5(true)
        option.push(data.data) ;
        txtStartDate.current.value = data.data[0].mindate;
        txtEndDate.current.value =data.data[0].maxdate;
        console.log("Start Date:"+data.data[0].mindate)
        console.log("End Date:"+data.data[0].maxdate)
        let elements = option[0].map((x:any,i:number) =>{
          trCodeList.push({id:x.id,isChecked:false})
          return(
              <IonCol size="4" key={i}>
                  <IonItem>
                      <IonLabel>{x.code} {x.description}</IonLabel>
                      <IonCheckbox checked={false} className="jnl" onClick={ev =>{setJournalFilter(x.id);}} ></IonCheckbox>
                  </IonItem>
              </IonCol> 
          )
        })
        setTransactionCodes(elements);
        //setShowLoading(false)
        var a:any = null
        setLoader(a)
      })
    }
    const processFiles =()=>{
      //console.log(props)
      //alert(counter)
      //alert(getFileCounter)
      console.log(files)
      console.log(files.length)
      var f:any;
      counter = 0
      for(f in files){
        console.log(f)
        console.log(files[f]/1)
        if(files[f]/1 != 0)
          counter += files[f]/1
      }
      var option:any = []
      console.log("counter:"+counter)
      console.log("app_id"+props.application_id)
      if(getPackageId == 112 || getPackageId == 113){
        callTransactionCodes()
      }else{
        if((counter == 10 && props.application_id/1 == 67) || (counter == 6  && props.application_id/1 == 66)){
          setShowLoading(true)
          console.log("process files")
          setStructuredForm5(true)
          var data:any = []
          let a:any = <IonSpinner name="dots"></IonSpinner>
          setLoader(a)
          data.push({
            primary_host:props.props.primary_host,
            version_id:glVersionId, 
            gl_file_name:glFileName, 
            project_name:getProjectName, 
            created_by:props.props.user_id,
            package_id:getPackageId,
            application_id:props.application_id,
            migration_id:getMigrationId
          })
          restoreJournalsFile(data[0])
          .then(response =>{return response})
          .then(data =>{
            setShowLoading(false)
            option.push(data) ;
            //alert(option)
            txtStartDate.current.value = option[0][0].mindate;
            txtEndDate.current.value = option[0][0].maxdate;
            console.log("Start Date:"+option[0][0].mindate)
            console.log("End Date:"+option[0][0].maxdate)
            let elements = option[0].map((x:any,i:number) =>{
              trCodeList.push({id:x.id,isChecked:false})
              return(
                  <IonCol size="4" key={i}>
                      <IonItem>
                          <IonLabel>{x.code} {x.description}</IonLabel>
                          <IonCheckbox checked={false} className="jnl" onClick={ev =>{setJournalFilter(x.id);}} ></IonCheckbox>
                      </IonItem>
                  </IonCol> 
              )
            })
            
          
          setTransactionCodes(elements);
          //setShowLoading(false)
          a = null
          setLoader(a)
          })
        }
      }
    }
   

    const callDataIntegrity = (fileRestoreId:any) =>{
      console.log(props)
      var option:any = []
      fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?test=selectdataintegrity1"+
      "&migration_id="+getMigrationId+
      "&filerestoreid="+fileRestoreId
      ,
      {
          headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
      }
      )
      .then(response => response.json()) 
      .then(data =>{
          var path:any = "https://www.dmzee.co.za/files/out/integrity_heavy_"+data.data[0].uniqueid+".json"
          //var path:any = data
          //alert(path)
          fetch(path,
              {
                  headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
              }
          )
          .then(response => response.json())
          .then(data=>{
              console.log(data.data)
              option.push(data.data)
              var debit:any = "";
              var elements:any = option[0].map((x:any, i:any)=>{
                if(x.filerestoreid != 0){
                    if(
                        x.stage != "File Name" && 
                        x.stage != "Script" && 
                        x.stage != "Control Total - Debit" && 
                        x.stage != "Control Total - Credit" &&
                        x.stage != "Number Of Duplicate Accounts"
                        )
                        return(
                            <IonRow key={i}>
                                <IonCol><b>{x.stage}</b></IonCol>
                                <IonCol><i>{x.details}</i></IonCol>
                            </IonRow>
                        )
                        if(x.stage == "File Name"){
                            var tr1 =  <IonRow key={i} className="header-row-ar"><IonCol><b>{x.stagename}</b></IonCol></IonRow>
                            var tr2 =  <IonRow key={i} className=""><IonCol><b>{x.stage}</b></IonCol><IonCol><i>{x.details}</i></IonCol></IonRow>
                            var z:any = [tr1, tr2]
                            return(
                            z
                            )
                        }
                        if(x.stage == "Script")
                        return(
                            <IonRow key={i} className="script-row">
                                <IonCol><b>{x.stage}</b></IonCol>
                                <IonCol><i>{x.details}</i></IonCol>
                            </IonRow>
                        )
                        if(x.stage == "Number Of Duplicate Accounts"){
                            if(x.details != "0")
                            errors = true;
                            return(
                                <IonRow key={i} className="">
                                    <IonCol><b>{x.stage}</b></IonCol>
                                    <IonCol className={x.details != "0"? "finding-highlight":""}><i>{x.details}</i></IonCol>
                                </IonRow>
                            )
                        }
                        if(x.stage == "Control Total - Debit"){
                        debit = x.details
                        return(
                            <IonRow key={i}>
                                <IonCol><b>{x.stage}</b></IonCol>
                                <IonCol><i>{x.details}</i></IonCol>
                            </IonRow>
                        )
                        }
                        if(x.stage == "Control Total - Credit"){
                        if(debit == x.details)
                        return(
                            <IonRow key={i}>
                                <IonCol><b>{x.stage}</b></IonCol>
                                <IonCol><i>{x.details}</i></IonCol>
                            </IonRow>
                        )
                        if(debit != x.details){
                            errors = true;
                            return(
                                <IonRow key={i}>
                                    <IonCol><b>{x.stage}</b></IonCol>
                                    <IonCol className="finding-highlight"><i>{x.details}</i></IonCol>
                                </IonRow>
                            )
                        }
                    }
                }
                if(x.filerestoreid == 0){
                    return(
                        <div key={i}>
                            <IonRow className="script-row">
                                <IonCol><b>{x.stage}</b></IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="script-row"><i>{x.details}</i></IonCol>
                            </IonRow>
                        </div>
                    )
                }
              })
              setIntegrityData(elements)
              setIntegrityReport(true)
          })
          
      })
    }
    const validateFile = async (type:any, isfile:any, packageId:any, restore_id:any) =>{
      setShowLoading(true)
      console.log("validate file")
      let data:any = [];
      return fetch(props.props.primary_host+'/journals/dbconnect/select.jsp?etl=checkvalidfile2'+
          "&migrationid="+getMigrationId+
          "&packageid="+packageId+
          "&type="+type+
          "&isfile="+isfile
          ,
          {
              headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
          }
      )
      .then(response => response.json())
      .then(d =>{
          data.push(d.data); 
          callHeaders(data, restore_id);
          setShowLoading(false)
      })
      .catch(error=>{
        setShowLoading(false)
        alert(error)
      })
  }
  
  const callHeaders = (results:any, restore_id:any) =>{
      setShowLoading(true)
      let data:any = []
      return fetch(props.props.primary_host+'/journals/dbconnect/select.jsp?etl=checkvalidatefileheader'+
          "&migrationid="+getMigrationId+
          "&filerestoreid="+restore_id
          ,
          {
              headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
          }
      )
      .then(response => response.json())
      .then(d =>{
          data.push(d.data)
          console.log(data)
          let options:any = data[0].map((x:any,i:number) =>{
              console.log(x.header)
            return(
                <IonSelectOption key={i} value={x.header}>{x.header}</IonSelectOption>
            )
          
          })
          return options
      }).then(h =>{
        setShowLoading(false)
        console.log("check validate render data:"+results)
        let headers:any = h;
        let r:any = () =>{
            return(
            <div>
            <IonRow className="approval-table-header">
                    <IonCol size="4"><b>Required Headers</b></IonCol>
                    <IonCol size="1"></IonCol>
                    <IonCol size="3"><b>File Headers Found</b></IonCol>
                    <IonCol size="2"><b>Header Located</b></IonCol>
                    <IonCol size="2"><b>Data Validated</b></IonCol>
            </IonRow>
                {
                    results[0].map((x:any, i:number) =>{
                        return(
                            
                            <IonRow key={i} className="text-align-center ">
                                <IonCol size="4">{x.Details}</IonCol>
                                <IonCol size="1">
                                    <IonIcon size="large" icon={arrowForwardOutline}></IonIcon>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem>
                                    <IonSelect value={x.Details} >
                                        {headers}
                                    </IonSelect>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="2">
                                    {x.located = 1?
                                    <IonIcon size="large" color="success" icon={checkmarkOutline}></IonIcon>:
                                    <IonIcon size="large" color="danger" icon={closeOutline}></IonIcon>
                                    }
                                </IonCol>
                                <IonCol size="2">
                                    {x.ok = 1?
                                    <IonIcon size="large" color="success" icon={checkmarkOutline}></IonIcon>:
                                    <IonIcon size="large" color="danger" icon={closeOutline}></IonIcon>
                                    }
                                </IonCol>
                            </IonRow>
                        )
                    })
                }
                </div>
        )}
        setShowLoading(false)
        //setIntegrityReport(true)
        setFileTestResults(r);
        callDataIntegrity(restore_id)
      })
      .catch(error=>{
        setShowLoading(false)
        alert(error)
      })
    }
    const [getRestoreDB, setRestoreDB]          = useState<any>(0)
    return (
        <div className="new-connection-options ">
          
          <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'} ></IonLoading>
          <IonHeader>
            <IonRow>
              <IonCol className="ion-padding size-20">
                <IonIcon icon={cloudUploadOutline} className="text-gt size-32"></IonIcon>&nbsp;&nbsp;
                New Project
                </IonCol>
            </IonRow>
          </IonHeader>
          {getMainOptions &&
          <IonRow>
            {getSetupDetails &&
            <IonCol size="1" className='ion-padding ion-text-center'>
              &nbsp;
            </IonCol>
            }
            {getOnlineSystems &&
            <IonCol size="4">
              <div className="import-card ion-text-center" onClick={ev=>{/*set_file_load_option=3;setFileLoader(false); setAPIConfig(true);showNewConnectionOptions(false)*/}}>
                <IonCardHeader>
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <IonIcon icon={cloudOutline}  className="size-48 text-gt"></IonIcon>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol >
                      <IonTitle className='text-black size-18'>Online Systems API Config</IonTitle>
                    </IonCol>
                  </IonRow>
                </IonCardHeader>
                <IonCardContent>
                  <IonRow className="ion-text-left">
                    <IonCol className="custom-account-text-icon" onClick={()=>{setPackageId(1); setView(4); setSystem(2)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/xero.png" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                                Xero
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-text-left">
                    <IonCol className="custom-account-text-icon" onClick={()=>{setPackageId(0); setView(2); setSystem(1)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/sageone.jpg" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              Sage One (coming soon)
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-text-left">
                    <IonCol className="custom-account-text-icon" onClick={()=>{setPackageId(0); setView(2); setSystem(1)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/quickbooks-logo.png"  />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              Quickbooks  (coming soon)
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </div>
            </IonCol>
            }
            {getFileSystems &&
            <IonCol size="4" className="ion-text-center">
              <div className="import-card" onClick={ev=>{/*set_file_load_option=1;setFileLoader(true); setAPIConfig(false);showNewConnectionOptions(false)*/}}>
                <IonCardHeader>
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <IonIcon icon={documentOutline} className="size-48 text-gt"></IonIcon>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonTitle className='text-black size-18'>Exported Data File Loader</IonTitle>
                    </IonCol>
                  </IonRow>
                </IonCardHeader>
                <IonCardContent className="ion-text-left">
                  <IonRow>
                    <IonCol className="custom-account-text-icon" onClick={()=>{setView(2); setSystem(1); setPackageId(2)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/excel.png" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              Excel
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="custom-account-text-icon" onClick={()=>{setView(2); setSystem(2); setPackageId(2)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/text.png" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              Custom Text Delimited Files
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="custom-account-text-icon" onClick={()=>{setView(2); setSystem(2); setPackageId(2)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/csv.png" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              CSV Files
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </div>
            </IonCol>
            }
            {getDatabaseSystems &&
            <IonCol size="4" className="ion-text-center">
              <div className="import-card" onClick={ev=>{/*set_file_load_option=2;setFileLoader(true); setAPIConfig(false);showNewConnectionOptions(false)*/}}>
              <IonCardHeader>  
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <FontAwesomeIcon icon={faDatabase} className="size-48 text-gt" ></FontAwesomeIcon>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonTitle className='text-black size-18'>Backed Up Database Loader</IonTitle>
                    </IonCol>
                  </IonRow>
                </IonCardHeader>
                <IonCardContent className="ion-text-left">
                  <IonRow className="ion-text-left">
                    <IonCol className="custom-account-text-icon" onClick={()=>{setPackageId(113); setView(2); setSystem(3)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/sage.png" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              Sage Pastel
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="custom-account-text-icon" onClick={()=>{setPackageId(113); setView(2); setSystem(3)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/sage.png" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              Sage Evolution
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="custom-account-text-icon" onClick={()=>{setPackageId(112); setView(2); setSystem(3)}}>
                        <IonRow>
                            <IonCol>
                              <IonImg src="../../../assets/images/accpac.png" />
                            </IonCol>
                            <IonCol size="10" onClick={()=>{}} >
                              AccPac
                            </IonCol>
                            <IonCol className="ion-text-right" >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </div>
            </IonCol>
            }
            {getSetupDetails &&
            <IonCol size="1" className='ion-padding ion-text-center'>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <IonIcon icon={arrowForward} className="size-42"></IonIcon>
            </IonCol>
            }
            {getSetupDetails &&
              
              <IonCol size="5" className="import-card-input">
                <NewProjectForm 
                  state={props} 
                  application_id={props.application_id}
                  package_id={getPackageId}
                  setSystem={(e:any)=>{resetSystem()}} 
                  setUpLoads={(e:any)=>{
                    if(getPackageId == 2){
                      setView(3)
                    }
                    if(getPackageId == 1){
                      setView(4)
                    }
                    if(getPackageId == 113 || getPackageId == 112){
                      setView(3)
                    }
                  }} 
                  setProjectName={(e:any)=>{setProjectName(e)}}
                  setBillingCode={(e:any)=>{setBillingCode(e)}}
                  setCTT={(e:any)=>{setCTT(e)}}
                  setRounding={(e:any)=>{setRounding(e)}}
                  setCountry={(e:any)=>{setCountry(e)}}
                  setMigrationId={(e:any)=>{setMigrationId(e)}}
                  project_name={(e:any)=>{setProjectName(e)}}
                />
              </IonCol>
            }
            </IonRow>
          }
          {getXero &&
            <IonRow className='ion-padding'>
              <IonCol className='ion-padding'>
                <Xero primary_host={props.props.primary_host} primary={"https://www.dmzee.co.za/"} user_id={props.props.user_id} />
              </IonCol>
            </IonRow>
          }
          {getUploads &&
          <div className="file-loader-section ion-padding">
            <IonRow>
              <IonCol size="12">
                <IonRow>
                  <IonCol>
                    <FileLoader 
                      state={props} 
                      form_name="Opening TB" 
                      restore_id={1} 
                      package_id={2}
                      ctt={getCTT}
                      rounding ={getRounding}
                      migration_name ={getProjectName}
                      billing_code = {getBillingCode}
                      country = {getCountry}
                      migration_id = {getMigrationId}
                      load_counter = {(e:any)=>{files.opening = 1; processFiles();}}
                      file_name = {(e:any)=>{}}
                      gl_version_id={(e:any)=>{}}
                      integrity={(e:any)=>{callDataIntegrity(e)}}
                      validateFile={(e:any)=>{validateFile(e.type, e.isfile, e.packageId, 1)}}
                    />
                  </IonCol>
                  <IonCol>
                    <FileLoader 
                      state={props} 
                      form_name="Closing TB" 
                      restore_id={3}
                      package_id={2}
                      ctt={getCTT}
                      rounding ={getRounding}
                      migration_name ={getProjectName}
                      billing_code = {getBillingCode}
                      country = {getCountry}
                      migration_id = {getMigrationId}
                      load_counter = {(e:any)=>{files.closing = 3; processFiles()}}
                      file_name = {(e:any)=>{}}
                      gl_version_id={(e:any)=>{}}
                      integrity={(e:any)=>{callDataIntegrity(e)}}
                      validateFile={(e:any)=>{validateFile(e.type, e.isfile, e.packageId,3)}}
                    />
                  </IonCol>
                  {(getPackageId != 112 && getPackageId != 113) &&
                  <IonCol>
                    <FileLoader 
                      state={props} 
                      form_name="General Ledger" 
                      restore_id={2}
                      package_id={getPackageId}
                      ctt={getCTT}
                      rounding ={getRounding}
                      migration_name ={getProjectName}
                      billing_code = {getBillingCode}
                      country = {getCountry}
                      migration_id = {getMigrationId}
                      load_counter = {(e:any)=>{files.gl = 2; processFiles()}}
                      version_id={(e:any)=>{setGLVersionId(e);glVersionId = e}}
                      file_name = {(e:any)=>{glFileName=e}}
                      gl_version_id ={(e:any)=>{glVersionId = e}}
                      integrity={(e:any)=>{callDataIntegrity(e)}}
                      validateFile={(e:any)=>{validateFile(e.type, e.isfile, e.packageId, 2)}}
                    />
                  </IonCol>  
                  }                  
                  {(props.application_id/1 == 67) &&
                  <IonCol>
                    <FileLoader 
                      state={props} 
                      form_name="Chart Of Accounts" 
                      restore_id={4}
                      package_id={2}
                      ctt={getCTT}
                      rounding ={getRounding}
                      migration_name ={getProjectName}
                      billing_code = {getBillingCode}
                      country = {getCountry}
                      migration_id = {getMigrationId}
                      load_counter = {(e:any)=>{files.coa = 4; processFiles()}}
                      file_name = {(e:any)=>{}}
                      gl_version_id ={(e:any)=>{}}
                      integrity={(e:any)=>{callDataIntegrity(e)}}
                      validateFile={(e:any)=>{validateFile(e.type, e.isfile, e.packageId, 4)}}
                    />
                  </IonCol>
                  }
                  {(getPackageId == 112 || getPackageId == 113) &&
                  <IonCol>
                    <FileLoader 
                      state={props} 
                      form_name="Data Backup" 
                      restore_id={2}
                      package_id={getPackageId}
                      ctt={getCTT}
                      rounding ={getRounding}
                      migration_name ={getProjectName}
                      billing_code = {getBillingCode}
                      country = {getCountry}
                      migration_id = {getMigrationId}
                      load_counter = {(e:any)=>{files.gl = 2;processFiles()}}
                      file_name = {(e:any)=>{setFileName(e)}}
                      gl_version_id ={(e:any)=>{setGLVersionId(e);glVersionId = e}}
                      version_id = {(e:any)=>{setGLVersionId(e);glVersionId = e}}
                      integrity={(e:any)=>{}}
                      load_manual={(e:any)=>{setVersionId(e.version_id);setFileName(e.file_name);setSelectorManualTop("ml-top-panel-down")}}
                      validateFile={(e:any)=>{}}
                      restore_db={getRestoreDB}
                    />
                  </IonCol>
                  }
                </IonRow>
              </IonCol>
            </IonRow>
            {getStructuredForm5 &&
            <IonRow>
              <IonCol className="size-10">
                {getResults}
                    <div className="load-settings size-10">
                        <IonRow>
                            <IonCol className="ion-text-left size-14">
                                <IonIcon icon={settingsOutline} size="large" className="icon-gt"></IonIcon>
                                &nbsp;&nbsp;&nbsp;<b>Setup Details</b>
                            </IonCol>
                        </IonRow>
                        <IonRow>&nbsp;</IonRow>
                        <IonItem>
                            <IonCol size="12">
                                <IonLabel className=""><b>Date Range & Available Transaction Codes</b></IonLabel>
                            </IonCol>
                        </IonItem>
                        <IonRow>&nbsp;</IonRow>
                        <IonItem>
                            <IonCol size="12">
                                <IonLabel className=""><b>Select GL Start and End Date</b></IonLabel>
                            </IonCol>
                        </IonItem>
                        <IonRow>
                            <IonCol size="5">
                                <IonItem>
                                    <IonLabel position="floating">Start Date</IonLabel>
                                    <IonInput type="date" ref={txtStartDate} />
                                </IonItem>
                            </IonCol>
                            <IonCol size="1" className="ion-padding">
                                {getLoader}
                            </IonCol>
                            <IonCol size="5">
                                <IonItem>
                                    <IonLabel position="floating">End Date</IonLabel>
                                    <IonInput type="date" ref={txtEndDate}   />
                                </IonItem>
                            </IonCol>
                            <IonCol size="1" className="ion-padding">
                                {getLoader}
                            </IonCol>
                        </IonRow>
                    </div>
              </IonCol>
              <IonCol>
                <IonRow>
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <IonItem>
                            <IonCol size="10">
                                <IonLabel className=""><b>Select Journal Transaction Code(s)</b></IonLabel>
                            </IonCol>
                            <IonCol size="2">
                                {getLoader}
                            </IonCol>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonContent>
                  <IonRow>
                      {getTransactionCodes}
                  </IonRow>
                </IonContent>
                <IonRow>
                      <IonCol size="8"></IonCol>
                      <IonCol size="4">
                          <IonButton 
                          className="button-width-max" 
                          color="primary" 
                          onClick={e => {
                            setStructuredForm5(false)
                            runTest()
                        }}
                      >
                        Continue
                      </IonButton>
                    </IonCol>
                  </IonRow>
              </IonCol>
            </IonRow>
            }
            {getStructuredForm6 &&
            <div>
              <IonRow>
                <IonCol className="ion-text-left size-20">
                  <IonIcon icon={checkmarkOutline} className="size-48 text-gt"></IonIcon>Test Results
                </IonCol>
              </IonRow>
              <IonRow className="size-12">
                {getTestResults}
              </IonRow>
            </div>
            }
            {(getIntegrityReport && (!getStructuredForm6 && !getStructuredForm5)) &&
            <IonContent className="integrity-data">
              <IonRow>
                <IonCol>
                  File Test 
                </IonCol>
                <IonCol>
                  Integrity Test
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  {getFileTestResults}
                </IonCol>
                <IonCol>
                  {getIntegrityData}
                </IonCol>
              </IonRow>
              
            </IonContent>
            }
          </div>
          }

        </div>
    )
}

export default Import