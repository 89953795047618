import { IonCol, IonIcon, IonLoading, IonRow, } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
import React,{ useRef, useState } from 'react';
import Switch from "react-switch";

export var cumulative:any = "0"
let opening_date:any="";
let closing_date:any="";
let migrationId:any = 0;
let first_load:any = 1;
let view_option:any = 1;
let adebit:any=0.00;
let acredit:any=0.00;
let aopening:any=0.00;
let aclosing:any=0.00;
let amovement:any=0.00;
let account_level_0:any = "0";
let account_level_1:any = "0";
let account_level_2:any = "0";
let account_level_3:any = "1";
let start_date:any="";
let end_date:any="";
let analytical_report_id:any=0;
let row_id:any = "";
let account_level_0_key:any = ''
let account_level_1_key:any = ''
let account_level_2_key:any = ''
let account_level_3_key:any = ''
let account_level_0_desc:any = '';
let account_level_1_desc:any = '';
let date_desc:any = '';
let kpi_exception:any=0;
let kpi_number:any = 0;
var ca_period1:any = 0;
var ca_period2:any = 0;
var ca_period3:any = 0;
var ca_period4:any = 0;
var ca_period5:any = 0;
var ca_period6:any = 0;
var ca_period7:any = 0;
var ca_period8:any = 0;
var ca_period9:any = 0;
var ca_period10:any = 0;
var ca_period11:any = 0;
var ca_period12:any = 0;

var nca_period1:any = 0;
var nca_period2:any = 0;
var nca_period3:any = 0;
var nca_period4:any = 0;
var nca_period5:any = 0;
var nca_period6:any = 0;
var nca_period7:any = 0;
var nca_period8:any = 0;
var nca_period9:any = 0;
var nca_period10:any = 0;
var nca_period11:any = 0;
var nca_period12:any = 0;

var cl_period1:any = 0;
var cl_period2:any = 0;
var cl_period3:any = 0;
var cl_period4:any = 0;
var cl_period5:any = 0;
var cl_period6:any = 0;
var cl_period7:any = 0;
var cl_period8:any = 0;
var cl_period9:any = 0;
var cl_period10:any = 0;
var cl_period11:any = 0;
var cl_period12:any = 0;

var ncl_period1:any = 0;
var ncl_period2:any = 0;
var ncl_period3:any = 0;
var ncl_period4:any = 0;
var ncl_period5:any = 0;
var ncl_period6:any = 0;
var ncl_period7:any = 0;
var ncl_period8:any = 0;
var ncl_period9:any = 0;
var ncl_period10:any = 0;
var ncl_period11:any = 0;
var ncl_period12:any = 0;

var e_period1:any = 0;
var e_period2:any = 0;
var e_period3:any = 0;
var e_period4:any = 0;
var e_period5:any = 0;
var e_period6:any = 0;
var e_period7:any = 0;
var e_period8:any = 0;
var e_period9:any = 0;
var e_period10:any = 0;
var e_period11:any = 0;
var e_period12:any = 0;
var months:any = []
const addCommas = (nStr:any) =>{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}
const resetCounters = () =>{
ca_period1 = 0;
ca_period2 = 0;
ca_period3 = 0;
ca_period4 = 0;
ca_period5 = 0;
ca_period6 = 0;
ca_period7 = 0;
ca_period8 = 0;
ca_period9 = 0;
ca_period10 = 0
ca_period11 = 0
ca_period12 = 0
nca_period1 = 0
nca_period2 = 0
nca_period3 = 0
nca_period4 = 0
nca_period5 = 0
nca_period6 = 0
nca_period7 = 0
nca_period8 = 0
nca_period9 = 0
nca_period10 = 0 
nca_period11 = 0 
nca_period12 = 0 
cl_period1 = 0;
cl_period2 = 0;
cl_period3 = 0;
cl_period4 = 0;
cl_period5 = 0;
cl_period6 = 0;
cl_period7 = 0;
cl_period8 = 0;
cl_period9 = 0;
cl_period10 = 0
cl_period11 = 0
cl_period12 = 0
ncl_period1 = 0
ncl_period2 = 0
ncl_period3 = 0
ncl_period4 = 0
ncl_period5 = 0
ncl_period6 = 0
ncl_period7 = 0
ncl_period8 = 0
ncl_period9 = 0
ncl_period10 = 0
ncl_period11 = 0
ncl_period12 = 0
e_period1 = 0;
e_period2 = 0;
e_period3 = 0;
e_period4 = 0;
e_period5 = 0;
e_period6 = 0;
e_period7 = 0;
e_period8 = 0;
e_period9 = 0;
e_period10 = 0;
e_period11 = 0;
e_period12 = 0;
}
const BalanceSheetMonthlyPivot = (props:any) =>{
    const [getCumulative, setCumulative]            = useState<boolean>(false)
    const [getShowLoading, setShowLoading]          = useState<boolean>(false)
    
    const [getPivot, setPivot]                      =useState<HTMLDivElement>()
    const [getCurrentAssetRow, setCurrentAssetRow]  =useState<HTMLDivElement>()
    const [getNonCurrentAssetRow, setNonCurrentAssetRow]  =useState<HTMLDivElement>()
    const [getEquityRow, setEquityRow]  =useState<HTMLDivElement>()
    const [getCurrentLiabilitiesRow, setCurrentLiabilitiesRow]  =useState<HTMLDivElement>()
    const [getNonCurrentLiabilitiesRow, setNonCurrentLiabilitiesRow]  =useState<HTMLDivElement>()
    const setBalanceSheetPivot = (data:any) =>{
        console.log(data)
        resetCounters()
        //resetBalanceSheet()  
        let options:any = []
        setShowLoading(true)
        options.push(data.data)
        var headers:any = []
        var h:any;
        var i:any =0;
        for(h in options[0][0]){
            if(i > 8)
                headers.push(h);
        i++;
        }
        var desc:any = <IonCol size="2" key={-1} className="pivot-header-desc ion-text-left"><b>Account Description</b></IonCol>
        months = headers.map((x:any,i:number)=>{
            return(
                <IonCol key={i}  className="pivot-header ion-text-right size-10"><b>{x}</b></IonCol>
            )
        })
        var combo:any = [desc,months]
        setPivot(combo)
        var current_assets_start:any = options[0].map((x:any, i:any)=>{
            var combo1:any = []
            if(x.account_level_2_key == 1){
                var j:any = 0
                var desc:any = <IonCol size="2" key={i} className=" size-10 pivot-header-desc summary-account ion-text-left"><b>{x["account_level_0"]+" - "+x["account_level_0_description"]}</b></IonCol>
                var values1:any = []
                
                for(j=0; j < 12; j++){ 
                    if(j > -1){
                        values1.push(<IonCol key={i+1}  className="size-10 pivot-details ion-text-right">{addCommas(x[headers[j]])}</IonCol>)
                        if (j == 0)
                        ca_period1 += x[headers[j]]/1;
                        if (j == 1)
                        ca_period2 += x[headers[j]]/1;
                        if (j == 2)
                        ca_period3 += x[headers[j]]/1;
                        if (j == 3)
                        ca_period4 += x[headers[j]]/1;
                        if (j == 4)
                        ca_period5 += x[headers[j]]/1;
                        if (j == 5)
                        ca_period6 += x[headers[j]]/1;
                        if (j == 6)
                        ca_period7 += x[headers[j]]/1;
                        if (j == 7)
                        ca_period8 += x[headers[j]]/1;
                        if (j == 8)
                        ca_period9 += x[headers[j]]/1;
                        if (j == 9)
                        ca_period10 += x[headers[j]]/1;
                        if (j == 10)
                        ca_period11 += x[headers[j]]/1;
                        if (j == 11)
                        ca_period12 += x[headers[j]]/1;
                    }
                }
                combo1 =  [desc,values1]
            }
            return(
                <IonRow className="summary-detail-row">{combo1}</IonRow>
            )
        })
        var non_current_assets_start:any = options[0].map((x:any, i:any)=>{
            var combo2:any = []
            if(x.account_level_2_key == 2){
                var j:any = 0
                var desc:any = <IonCol size="2" key={i} className="pivot-header-desc summary-account ion-text-left size-10"><b>{x["account_level_0"]+" - "+x["account_level_0_description"]}</b></IonCol>
                var values1:any = []
                for(j=0; j < 12; j++){
                    if(j > -1){
                        //console.log(x[headers[j]])
                        values1.push(<IonCol key={i+1} className="pivot-details ion-text-right size-10">{addCommas(x[headers[j]])}</IonCol>)
                        if (j == 0)
                        nca_period1 += x[headers[j]]/1;
                        if (j == 1)
                        nca_period2 += x[headers[j]]/1;
                        if (j == 2)
                        nca_period3 += x[headers[j]]/1;
                        if (j == 3)
                        nca_period4 += x[headers[j]]/1;
                        if (j == 4)
                        nca_period5 += x[headers[j]]/1;
                        if (j == 5)
                        nca_period6 += x[headers[j]]/1;
                        if (j == 6)
                        nca_period7 += x[headers[j]]/1;
                        if (j == 7)
                        nca_period8 += x[headers[j]]/1;
                        if (j == 8)
                        nca_period9 += x[headers[j]]/1;
                        if (j == 9)
                        nca_period10 += x[headers[j]]/1;
                        if (j == 10)
                        nca_period11 += x[headers[j]]/1;
                        if (j == 11)
                        nca_period12 += x[headers[j]]/1;  
                    }
                }
                combo2 =  [desc,values1]
            }
            return(
                <IonRow className="summary-detail-row">{combo2}</IonRow>
            )
        })
        var equity_start:any = options[0].map((x:any, i:any)=>{
            var combo3:any = []
            if(x.account_level_2_key == 3){
                var j:any = 0
                var desc:any = <IonCol size="2" key={i} className="pivot-header-desc summary-account ion-text-left size-10"><b>{x["account_level_0"]+" - "+x["account_level_0_description"]}</b></IonCol>
                var values1:any = []
                for(j=0; j < 12; j++){
                    if(j > -1){
                        //console.log(x[headers[j]])
                        values1.push(<IonCol key={i+1} className="pivot-details ion-text-right size-10">{addCommas(x[headers[j]])}</IonCol>)
                        if (j == 0)
                        e_period1 += x[headers[j]]/1;
                        if (j == 1)
                        e_period2 += x[headers[j]]/1;
                        if (j == 2)
                        e_period3 += x[headers[j]]/1;
                        if (j == 3)
                        e_period4 += x[headers[j]]/1;
                        if (j == 4)
                        e_period5 += x[headers[j]]/1;
                        if (j == 5)
                        e_period6 += x[headers[j]]/1;
                        if (j == 6)
                        e_period7 += x[headers[j]]/1;
                        if (j == 7)
                        e_period8 += x[headers[j]]/1;
                        if (j == 8)
                        e_period9 += x[headers[j]]/1;
                        if (j == 9)
                        e_period10 += x[headers[j]]/1;
                        if (j == 10)
                        e_period11 += x[headers[j]]/1;
                        if (j == 11)
                        e_period12 += x[headers[j]]/1;
                    }
                }
                combo3 =  [desc,values1]
            }
            return(
                <IonRow className="summary-detail-row">{combo3}</IonRow>
            )
        })
        var current_liabilities_start:any = options[0].map((x:any, i:any)=>{
            var combo4:any = []
            if(x.account_level_2_key == 4){
                var j:any = 0
                var desc:any = <IonCol size="2" key={i} className="pivot-header-desc summary-account ion-text-left size-10"><b>{x["account_level_0"]+" - "+x["account_level_0_description"]}</b></IonCol>
                var values1:any = []
                for(j=0; j < 12; j++){
                    if(j > -1){
                        //console.log(x[headers[j]])
                        values1.push(<IonCol key={i+1} className="pivot-details ion-text-right size-10">{addCommas(x[headers[j]])}</IonCol>)
                        if (j == 0)
                        cl_period1 += x[headers[j]]/1; 
                        if (j == 1)
                        cl_period2 += x[headers[j]]/1;
                        if (j == 2)
                        cl_period3 += x[headers[j]]/1;
                        if (j == 3)
                        cl_period4 += x[headers[j]]/1;
                        if (j == 4)
                        cl_period5 += x[headers[j]]/1;
                        if (j == 5)
                        cl_period6 += x[headers[j]]/1;
                        if (j == 6)
                        cl_period7 += x[headers[j]]/1;
                        if (j == 7)
                        cl_period8 += x[headers[j]]/1;
                        if (j == 8)
                        cl_period9 += x[headers[j]]/1;
                        if (j == 9)
                        cl_period10 += x[headers[j]]/1;
                        if (j == 10)
                        cl_period11 += x[headers[j]]/1;
                        if (j == 11)
                        cl_period12 += x[headers[j]]/1;
                    }
                }
                combo4 =  [desc,values1]
            }
            return(
                <IonRow className="summary-detail-row">{combo4}</IonRow>
            )
        })
        var non_current_liabilities_start:any = options[0].map((x:any, i:any)=>{
            var combo5:any = []
            if(x.account_level_2_key == 5){
                var j:any = 0
                var desc:any = <IonCol size="2" key={i} className="pivot-header-desc summary-account ion-text-left size-10"><b>{x["account_level_0"]+" - "+x["account_level_0_description"]}</b></IonCol>
                var values1:any = []
                for(j=0; j < 12; j++){
                    if(j > -1){
                        console.log(x[headers[j]])
                        values1.push(<IonCol key={i+1} className="pivot-details ion-text-right size-10">{addCommas(x[headers[j]])}</IonCol>)
                        if (j == 0)
                        ncl_period1 += x[headers[j]]/1;
                        if (j == 1)
                        ncl_period2 += x[headers[j]]/1;
                        if (j == 2)
                        ncl_period3 += x[headers[j]]/1;
                        if (j == 3)
                        ncl_period4 += x[headers[j]]/1;
                        if (j == 4)
                        ncl_period5 += x[headers[j]]/1;
                        if (j == 5)
                        ncl_period6 += x[headers[j]]/1;
                        if (j == 6)
                        ncl_period7 += x[headers[j]]/1;
                        if (j == 7)
                        ncl_period8 += x[headers[j]]/1;
                        if (j == 8)
                        ncl_period9 += x[headers[j]]/1;
                        if (j == 9)
                        ncl_period10 += x[headers[j]]/1;
                        if (j == 10)
                        ncl_period11 += x[headers[j]]/1;
                        if (j == 11)
                        ncl_period12 += x[headers[j]]/1;
                    }
                }
                combo5 =  [desc,values1]
            }
            return(
                <IonRow className="summary-detail-row">{combo5}</IonRow>
            )
        }) 
        setShowLoading(false)
        setCurrentAssetRow(current_assets_start)
        setNonCurrentAssetRow(non_current_assets_start)
        setEquityRow(equity_start)
        setCurrentLiabilitiesRow(current_liabilities_start)
        setNonCurrentLiabilitiesRow(non_current_liabilities_start)
    
    }
    const [hideCurrentAssets, showCurrentAssets]      = useState<boolean>(true)
    const [hideNonCurrentAssets, showNonCurrentAssets]      = useState<boolean>(true)
    const [hideEquity, showEquity]                    = useState<boolean>(true)
    const [hideCurrentLiabilities, showCurrentLiabilities]      = useState<boolean>(true)
    const [hideNonCurrentLiabilities, showNonCurrentLiabilties]      = useState<boolean>(true)
    React.useEffect(() => {
      console.log(props.data)
      setBalanceSheetPivot(props.data)
    }, []);        
    return(
        <div className="pivot-table">
            <IonLoading onWillDismiss={() => setShowLoading(false)} 
                isOpen={getShowLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Loading..."} ></IonLoading>
            <IonRow className="size-20">
              <IonCol size="6">
                <b>Balance Sheet Monthly</b>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <label>
                  <span className="size-16">Movement</span>&nbsp;
                  <Switch checkedIcon={false} uncheckedIcon={false} onChange={(checked)=>{setCumulative(checked);if(checked){cumulative=1}else{cumulative=0};props.setBalanceSheetPivotCumulative(cumulative);console.log(checked)}} checked={getCumulative} />
                  &nbsp;<span className="size-16">Cumulative</span>
                </label>
              </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12" className="summary-account-level-1 ">Current Assets</IonCol>
                <IonCol className="summary-row-header size-10" size="12">
                  <IonRow>
                    <IonCol size="2" >
                      <IonIcon onClick={()=>{showCurrentAssets(!hideCurrentAssets)}} icon={chevronForward} className={hideCurrentAssets? "size-10 rotate-icon-forward" : "size-10 rotate-icon-downward" }></IonIcon>
                      &nbsp;Account Description
                    </IonCol>
                    {months}
                  </IonRow>
                </IonCol>
                {!hideCurrentAssets &&
                  <IonCol size="12">{getCurrentAssetRow}</IonCol>
                }
                <IonCol size="12" className='summary-row-total ion-text-right size-10'>
                  <IonRow className="">
                    <IonCol size="2" className="ion-text-left">Total</IonCol>
                    <IonCol>{addCommas((ca_period1).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period2).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period3).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period4).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period5).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period6).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period7).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period8).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period9).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period10).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period11).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ca_period12).toFixed(2))}</IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="12" className="summary-account-level-1 ">Non Current Assets</IonCol>
                <IonCol className="summary-row-header size-10" size="12">
                  <IonRow>
                    <IonCol size="2">
                      <IonIcon onClick={()=>{showNonCurrentAssets(!hideNonCurrentAssets)}} icon={chevronForward} className={hideNonCurrentAssets? "size-10 rotate-icon-forward" : "size-10 rotate-icon-downward" }></IonIcon>
                      &nbsp;Account Description
                    </IonCol>
                    {months}
                  </IonRow>
                </IonCol>
                {!hideNonCurrentAssets &&
                  <IonCol size="12">{getNonCurrentAssetRow}</IonCol>
                }
                <IonCol className='summary-row-total ion-text-right size-10'>
                  <IonRow className="">
                    <IonCol size="2" className="ion-text-left">Total</IonCol>
                    <IonCol>{addCommas((nca_period1).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period2).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period3).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period4).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period5).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period6).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period7).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period8).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period9).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period10).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period11).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((nca_period12).toFixed(2))}</IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="12" className="summary-account-level-1 ">Equity</IonCol>
                <IonCol className="summary-row-header size-10" size="12">
                <IonRow>
                    <IonCol size="2">
                      <IonIcon onClick={()=>{showEquity(!hideEquity)}} icon={chevronForward} className={hideEquity? "size-10 rotate-icon-forward" : "size-10 rotate-icon-downward" }></IonIcon>
                      &nbsp;Account Description
                    </IonCol>
                    {months}
                  </IonRow>
                </IonCol>
                {!hideEquity &&
                <IonCol size="12">{getEquityRow}</IonCol>
                }
                <IonCol size="12" className='summary-row-total ion-text-right size-10'>
                  <IonRow className="">
                    <IonCol size="2" className="ion-text-left">Total</IonCol>
                    <IonCol>{addCommas((e_period1).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period2).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period3).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period4).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period5).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period6).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period7).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period8).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period9).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period10).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period11).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((e_period12).toFixed(2))}</IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="12" className="summary-account-level-1 ">Current Liabilities</IonCol>
                <IonCol className="summary-row-header size-10" size="12">
                  <IonRow>
                    <IonCol size="2">
                      <IonIcon onClick={()=>{showCurrentLiabilities(!hideCurrentLiabilities)}} icon={chevronForward} className={hideCurrentLiabilities? "size-10 rotate-icon-forward" : "size-10 rotate-icon-downward" }></IonIcon>
                      &nbsp;Account Description
                    </IonCol>
                    {months}
                  </IonRow>
                </IonCol>
                {!hideCurrentLiabilities &&
                  <IonCol size="12">{getCurrentLiabilitiesRow}</IonCol>
                }
                <IonCol size="12" className='summary-row-total ion-text-right size-10'>
                  <IonRow className="">
                    <IonCol size="2" className="ion-text-left">Total</IonCol>
                    <IonCol>{addCommas((cl_period1).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period2).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period3).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period4).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period5).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period6).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period7).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period8).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period9).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period10).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period11).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((cl_period12).toFixed(2))}</IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="12" className="summary-account-level-1 ">Non Current Liabilities</IonCol>
                <IonCol className="summary-row-header size-10" size="12">
                  <IonRow>
                    <IonCol size="2">
                      <IonIcon onClick={()=>{showNonCurrentLiabilties(!hideNonCurrentLiabilities)}} icon={chevronForward} className={hideNonCurrentLiabilities? "size-10 rotate-icon-forward" : "size-10 rotate-icon-downward" }></IonIcon>
                      &nbsp;Account Description
                    </IonCol>
                    {months}
                  </IonRow>
                </IonCol>
                {!hideNonCurrentLiabilities &&
                <IonCol size="12">
                  {getNonCurrentLiabilitiesRow}
                </IonCol>
                }
                <IonCol size="12" className='summary-row-total ion-text-right size-10'>
                <IonRow className="">
                    <IonCol size="2" className="ion-text-left">Total</IonCol>
                    <IonCol>{addCommas((ncl_period1).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period2).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period3).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period4).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period5).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period6).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period7).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period8).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period9).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period10).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period11).toFixed(2))}</IonCol>
                    <IonCol>{addCommas((ncl_period12).toFixed(2))}</IonCol>
                  </IonRow>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default BalanceSheetMonthlyPivot;
