import { IonSearchbar, IonLoading, IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSelect, IonSelectOption, IonSpinner, IonThumbnail, IonTitle, IonToolbar, IonTextarea, IonPopover, IonVirtualScroll, IonCheckbox, IonCardTitle } from '@ionic/react';
import { apps,starOutline, ellipsisVerticalOutline, businessOutline, checkmarkOutline, analyticsOutline, analyticsSharp, arrowBackSharp, cloudOutline, documentOutline, filterSharp, gridOutline, homeOutline, list, listOutline, logInOutline, logOutOutline, menuOutline, personOutline, pulseOutline, settingsOutline, wifiOutline, trashBin, trashBinOutline, checkmarkCircle, closeCircle, refreshCircle, caretUp, caretDown, pulseSharp, pulse, notifications, notificationsCircle, pencilSharp, closeOutline, trashOutline, star, arrowForwardCircleOutline, print, chevronForward, chevronDown, searchOutline, videocamOutline, codeDownload, clipboardOutline, logoGoogle, logoGooglePlaystore, shareOutline, returnUpBack, analytics, grid, createOutline, addOutline, statsChartOutline, heartDislikeSharp, searchCircle } from 'ionicons/icons';
import React,{ useRef, useState,  } from 'react';
import Highcharts, { isNumber } from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import './PredictiveForm.css' 
import ProjectsSide from '../SideBar/SubComponents/ProjectsSidePredictive';
import { callAccountComparisonByYear, callAccountComparisonByYearCorrelationMatrix, callAnalyticalReviews, callYearlyComparison } from '../../dbconnections/Select';
import SkewTable from '../Tables/Predictive/SkewTable';
import CorrelationTable from '../Tables/Predictive/CorrelationTable';
import { isNumeric } from '../../functions/functions';
import { Resizable } from 're-resizable';
import $ from 'jquery'
import PredictiveFormYearly from './PredictiveFormYearly';
import PredictiveFormCorrelation from './PredictiveFormCorrelation';
import PredictiveFormTreatment from './PredictiveFormTreatment';
import PredictiveFormForecast from './PredictiveFormForecast';
    var selectedOptions:any = {
        account_level_0_key:[],
        account_level_1_key:[],
        account_level_2_key:[],
        account_level_3_key:[]
    }
    var selectedNames:any = {
        account_name_0:[],
        account_name_1:[],
        account_name_2:[],
        account_name_3:[]
    }
    var items:any = [9,10,25,26]
    var names:any = []
    var yearlyChart:any = []
    var yearlyMatrix:any = []
    var yearlyLineChart:any = []
    var start_date:any              = '2021-01-01';
    var end_date:any                = '2021-12-31';
    var account_level_0:any         ='0';
    var account_level_1:any         ='0';
    var account_level_2:any         ='0';
    var account_level_3:any         ='0';
    let dim3List:any                =[];
    let dim3Filter:any              = "";
    let action_type_id:any          = 1;
    let custom_chart_id:any         =0;
    const setDim3Filter = (id:any) =>{
        for(var i = 0; i < dim3List.length; i++){
            console.log("Before:"+dim3List[i].id+" "+dim3List[i].isChecked)
            if(dim3List[i].id == id){
                dim3List[i].isChecked = !dim3List[i].isChecked;
            }
            console.log("After:"+dim3List[i].id+" "+dim3List[i].isChecked)
        }
        
        dim3Filter="";
        for(var i = 0 ; i < dim3List.length; i++){
            if(dim3List[i].isChecked && dim3Filter.length > 0){
                dim3Filter = dim3Filter + ","+ dim3List[i ].id+"";
            }
            if(dim3List[i].isChecked && dim3Filter.length == 0){
                dim3Filter = ""+ dim3List[i].id+"";
            }
            
        }
        console.log(dim3Filter)
    }
    let dim2List:any                =[];
    let dim2Filter:any              = "";
    const setDim2Filter = (id:any) =>{
        for(var i = 0; i < dim2List.length; i++){
            console.log("Before:"+dim2List[i].id+" "+dim2List[i].isChecked)
            if(dim2List[i].id == id){
                dim2List[i].isChecked = !dim2List[i].isChecked;
            }
            console.log("After:"+dim3List[i].id+" "+dim3List[i].isChecked)
        }
        
        dim2Filter="";
        for(var i = 0 ; i < dim2List.length; i++){
            if(dim2List[i].isChecked && dim2Filter.length > 0){
                dim2Filter = dim2Filter + ","+ dim2List[i ].id+"";
            }
            if(dim2List[i].isChecked && dim2Filter.length == 0){
                dim2Filter = ""+ dim2List[i].id+"";
            }
            
        }
        console.log(dim2Filter)
    }
    var existing:any = []
    var properties:any = ''
    const PredictiveForm = (props:any) =>{
        
        const [getAnalyticalReviewsId, setAnalyticalReviewsId]              = useState<any>()
        const [getLiveConnectionsListData, setLiveConnectionsListData]      = useState<any>()
        const [getLiveConnections, setLiveConnections]                      = useState<any>("predictive-live-connections-area-hide")
        const [getActualConnections, setActualConnections]                  = useState<any>("predictive-live-connections-area-hide")
        const [getPickAccount, setPickAccount]                              = useState<any>(false)
        const [getNewChartProject, setNewChartProject]                      = useState<any>(false)
        const [getAccountSelector, setAccountSelector]                      = useState<string>("predictive-account-selector-hide")
        const [getAccountSettings, setAccountSettings]                      = useState<string>("custom-account-settings-hide")
        const [getSelectedAccount, setSelectedAccount]                      = useState<HTMLIonColElement>()
        const [getAccountLevel3, setAccountLevel3]                          = useState<HTMLIonItemElement>()
        const [getAccountLevel2, setAccountLevel2]                          = useState<HTMLIonItemElement>()
        const [getAccountLevel1, setAccountLevel1]                          = useState<HTMLIonItemElement>()
        const [getAccountLevel0, setAccountLevel0]                          = useState<HTMLIonItemElement>()
        const [getMyCharts, setMyCharts]                                    = useState<HTMLIonColElement>()
        const txtStartDate:any                                              = useRef<HTMLIonInputElement>()
        const txtEndDate:any                                                = useRef<HTMLIonInputElement>()
        const txtChartName:any                                              = useRef<HTMLIonInputElement>()
        const [hideNewChart, showNewChart]                                  = useState<boolean>(false)
        const [getShowLoading, setShowLoading]                              = useState<boolean>(false)
        const [getTreatment, setTreatment]                                  = useState<boolean>(false)
        const [getTreatmentForecast, setTreatmentForecast]                  = useState<boolean>(false)
        const [getMatrix, setMatrix]                                        = useState<boolean>(false)
        const callDimAccountLevel1  = () =>{
            let options:any = []
            fetch(props.props.primary_host+"select?etl=selectaccountlevel1_react"+
            "&account_level_2_key="+0+//ddlAccountLevel2.current!.value+
            "&analyticalreviewsid="+props.props.analytical_reviews_id
            ,
            {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
            )
            .then(response => response.json())
            .then(data =>{
            options.push(data.data)
            let o = options[0].map((x:any, i:any)=>{
                return(
                <IonRow>
                    <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,1,x.account_level_1_key, x.account_level_1)/*setAccountProperty(1,, x.account_level_1)*/}}>
                        <IonRow>
                            <IonCol size="10" className="unselected"  >
                            {x.account_level_1}
                            </IonCol>
                            <IonCol className="ion-text-right" onClick={()=>{}} >
                                <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                )
            })
            setAccountLevel1(o);
            })
        }
        
        const callDimAccountLevel0  = () =>{
            let options:any = []
            fetch(props.props.primary_host+"select?etl=selectaccountlevel0_react"+
            "&account_level_1_key="+0+//ddlAccountLevel1.current!.value+
            "&analyticalreviewsid="+props.props.analytical_reviews_id
            ,
                {
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                }
            )
            .then(response => response.json())
            .then(data =>{
                options.push(data.data)
                let o = options[0].map((x:any, i:any)=>{
                return(
                    <IonCol size="12" key={i} className="ion-padding">
                        <div onClick={(e)=>{setSelectorOptions(e,0,x.account_level_0_key, x.account_level_0_description)/*setAccountProperty(0,,x.account_level_0_description)*/}} className="custom-account-text-icon unselected">
                            <b>{x.account_level_0}</b><br/><div className="size-12">{x.account_level_0_description}</div>
                        </div>
                    </IonCol>
                )
                })
                setAccountLevel0(o)
            })
        }
        const setSelectorOptions = (obj:any, level:any, id:any, name:any) =>{
            console.log(obj.currentTarget)
            if($(obj.currentTarget).hasClass("unselected")){
                if(level == 3){
                    selectedOptions.account_level_3_key.push(id) ;
                    selectedNames.account_name_3.push(name)
                }
                if(level == 2){
                    selectedOptions.account_level_2_key.push(id) ;
                    selectedNames.account_name_2.push(name)
                }
                if(level == 1){
                    selectedOptions.account_level_1_key.push(id) ;
                    selectedNames.account_name_1.push(name)
                }
                if(level == 0){
                    selectedOptions.account_level_0_key.push(id) ;
                    selectedNames.account_name_0.push(name)
                }
                $(obj.currentTarget).removeClass("unselected")
                $(obj.currentTarget).addClass("selected1")
            }else{
                $(obj.currentTarget).removeClass("selected1")
                $(obj.currentTarget).addClass("unselected1")
                var old = selectedOptions
                console.log("step 1")
                resetSelectedOptions(obj, level, id)
                console.log("old")
                console.log(old)
                for(var i = 0; i < old.length; i++){
                    if(level == 3 ){
                        console.log(old.account_level_3_key)
                        for(var x = 0; x < old.account_level_3_key.length; x++){
                            console.log(old.account_level_3_key[x])
                            if(old.account_level_3_key[x] != id)
                                selectedOptions.account_level_3_key.push(old.account_level_3_key[x]) ;
                        }
                    }
                    if(level == 2 ){

                        if(old.account_level_2_key[i] != id)
                            selectedOptions.account_level_2_key.push(old.account_level_2_key[i]) ;
                    }
                    if(level == 1 ){
                        if(old.account_level_1_key[i] != id)
                            selectedOptions.account_level_1_key.push(old.account_level_1_key[i]) ;
                    }
                    if(level == 0 ){
                        if(old.account_level_0_key[i] != id)
                            selectedOptions.account_level_0_key.push(old.account_level_0_key[i]) ;
                    }
                }
            }
            
            console.log(selectedOptions)
        }
        const resetSelectedOptions = (obj:any, level:any, id:any) =>{
            if(level == 3){
                selectedOptions.account_level_3_key = [0];
            }
            if(level == 2){
                selectedOptions.account_level_2_key = [0];
            }
            if(level == 1){
                selectedOptions.account_level_1_key = [0];
            }
            if(level == 0){
                selectedOptions.account_level_0_key = [0];
            }
        }

        const setItemsArray = () =>{
            items = []
            items = selectedOptions.account_level_0_key
            names = selectedNames.account_name_0
            //console.log(selectedOptions.account_level_0_key)
            //alert(selectedOptions.account_level_0_key.length)
            //for(var i = 0 ; i < selectedOptions.account_level_0_key.length; i++){
            //    items.push(selectedOptions.account_level_0_key[i])
            //    console.log(selectedOptions.account_level_0_key[i])
            //}
            console.log(items)
            callComparisonCharts()
            setPickAccount(false)
            setRatio(true)
            //setView(2)
        }
        const setView = (v:any) =>{
            switch(v){
                case 1:resetView();break;
                case 2:setNewChartProject(true);break;
                case 3:setPickAccount(true);setNewChartProject(true);break;
                case 4:setTreatment(true);setNewChartProject(true);break;
                case 5:setTreatmentForecast(true);setNewChartProject(true);break;
            }
        }
        const resetView = () =>{
            setNewChartProject(false) ;
            setPickAccount(false)     ;
            
        }
        const callLiveConnections = () =>{
            callAnalyticalReviews(props.props)
            .then(response=>{return response})
            .then(data=>{
                setLiveConnectionsListData(data)

            })
            .then(()=>{
                setLiveConnections("custom-live-connections-area-show")
            })
        }
                
        const setAccountProperty = (account_level:any , account_level_key:any, account_name:any) =>{
            //setAccountSelector("custom-account-selector-hide")
            //setCustomChartManifest(account_level, account_level_key, account_name)   
        }
                
        const setDateFormatHighCharts = (d:string) =>{
            var dt = new Date(d);
            var date = dt.getTime();
            //console.log(date)
            return date
        }
        const drawlineSeries = (type:any, series:any, chart:any, chartTitle:any, axisType:any) =>{
            let o:any = null
            if(chart == 9 )
                setChart1(o)
            if(chart == 10 )
                setChart2(o)
            if(chart == 25)
                setChart3(o)
            if(chart == 26)
                setChart4(o)
            let lineChart:any = {
                            chart:{
                                type: type,
                            },
                            title:{
                                text:''
                            },
                            subTitle:{
                                text:''
                            },
                            xAxis: {
                                type: axisType
                            },
                            series:series,
                            turboThreshold:0,
                            responsive: {
                                rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    layout: 'horizontal'
                                    },
                                    yAxis: {
                                    labels: {
                                        align: 'left',
                                        x: 0,
                                        y: -5
                                    },
                                    title: {
                                        text: chartTitle
                                    }
                                    },
                                    subtitle: {
                                    text: null
                                    },
                                    credits: {
                                    enabled: false
                                    }
                                }
                                }]
                            }
                        };
            
            let c:any = (<HighchartsReact onLoad={(e:any)=>{reflow(e.current)}} highcharts={Highcharts} options={lineChart}></HighchartsReact>)
            if(chart == 9 )
                setChart1(c)
            if(chart == 10 )
                setChart2(c)
            if(chart == 25)
                setChart3(c)
            if(chart == 26)
                setChart4(c)
            
        }
        const drawlineSeries2 = (type:any, series:any, chart:any, chartTitle:any, axisType:any) =>{
            let o:any = null
            if(chart == 9 )
                setChart11(o)
            if(chart == 10 )
                setChart22(o)
            if(chart == 25)
                setChart33(o)
            if(chart == 26)
                setChart44(o)
            let lineChart:any = {
                            chart:{
                                type: type,
                                
                            },
                            title:{
                                text:''
                            },
                            subTitle:{
                                text:''
                            },
                            xAxis: {
                                type: {axisType}
                            },
                            series:series,
                            turboThreshold:0,
                            responsive: {
                                rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    layout: 'horizontal'
                                    },
                                    yAxis: {
                                    labels: {
                                        align: 'left',
                                        x: 0,
                                        y: -5
                                    },
                                    title: {
                                        text: chartTitle
                                    }
                                    },
                                    subtitle: {
                                    text: null
                                    },
                                    credits: {
                                    enabled: false
                                    }
                                }
                                }]
                            }
                        };
            let c:any = (<HighchartsReact onLoad={(e:any)=>{reflow(e.current)}} highcharts={Highcharts} options={lineChart} ></HighchartsReact>)
            console.log(chart)
            if(chart/1 == 9 )
                setChart11(c)
            if(chart/1 == 10 )
                setChart22(c)
            if(chart/1 == 25)
                setChart33(c)
            if(chart/1 == 26)
                setChart44(c)
            
        }
        const reflow = (obj:any) =>{
            alert()
            obj.chart.reflow()
        }
        const [getChartCorrelationByYear, setChartCorrelationByYear]          = useState<any>()

        const callYearlyComparisonChart = async () =>{
            setShowLoading(true)
            var y:any       = []
            for(var i:any = 0 ; i < selectedOptions.account_level_0_key.length; i++){
                var x:any = []
                console.log(selectedOptions.account_level_0_key[i])
                x.push({
                    primary_host:props.props.primary_host,
                    account_key:selectedOptions.account_level_0_key[i], 
                    analytical_reviews_id:props.props.analytical_reviews_id, 
                    start_date:txtStartDate.current!.value, 
                    end_date:txtEndDate.current!.value,
                    account_names:names[i]

                })
                //var data:any    = await callYearlyComparison(x[0])
                
                     
                y.push(<PredictiveFormYearly
                        primary_host={props.props.primary_host}
                        account_key={selectedOptions.account_level_0_key[i]}
                        analytical_reviews_id={props.props.analytical_reviews_id }
                        start_date={txtStartDate.current!.value} 
                        end_date={txtEndDate.current!.value}
                        account_names={names[i]}
                        
                    />
                    )
                //renderCharts2(data, items[i])
                //if(items[i]/1 == 26)
                //setShowLoading(false)
                
            }
            if (true){
                //console.log(data.data)
                //setView(3)
                setLinearProgression(true)
                setRatio(false)
                setTreatment(false)
                setMatrix(false)
                setTreatmentForecast(false)
            }
            setChartCorrelationByYear(y)
            //callAccountComparisonByYearChart()
        }

        const callAccountComparisonByYearChart = async () =>{
            setShowLoading(true)
            //var items:any = [9,10,25,26]
            for(var i:any = 0 ; i < items.length; i++){
                var x:any = []
                x.push({
                    primary_host:props.props.primary_host,
                    key:items[i], 
                    analytical_reviews_id:props.props.analytical_reviews_id, 
                    start_date:txtStartDate.current!.value, 
                    end_date:txtEndDate.current!.value,
                    account_names:names[i]
                })
                var data:any  = await callAccountComparisonByYear(x[0])
                //renderCharts22(data, items[i])
                setShowLoading(false)
                yearlyChart.push(<PredictiveFormYearly data={data} />)
            }
            callAccountComparisonByYearCorrelation()
        }

        const callAccountComparisonByYearCorrelation = async () =>{
            setShowLoading(true)
            //var items:any = [9,10,25,26]
            for(var i:any = 0 ; i < items.length; i++){
                var x:any = []
                x.push({
                    primary_host:props.props.primary_host,
                    key:items[i], 
                    analytical_reviews_id:props.props.analytical_reviews_id, 
                    start_date:txtStartDate.current!.value, 
                    end_date:txtEndDate.current!.value
                })
                var data:any  = await callAccountComparisonByYearCorrelationMatrix(x[0])
                yearlyMatrix.push(<CorrelationTable data={data.data} />)
                /*if(items[i] == 9){
                    var q:any = <CorrelationTable data={data.data} />
                    setCorrelationTable1(q)
                }
                if(items[i] == 10){
                    var q:any = <CorrelationTable data={data.data} />
                    setCorrelationTable2(q)
                }
                if(items[i] == 25){ 
                    var q:any = <CorrelationTable data={data.data} />
                    setCorrelationTable3(q)
                }
                if(items[i] == 26){
                    var q:any = <CorrelationTable data={data.data} />
                    setCorrelationTable4(q)
                }*/
                
                setShowLoading(false)
            }
        }
        const renderFullLine = () =>{

            for(var i = 0; i < yearlyChart.length; i++)
                     <IonRow>
                        <IonCol size="6" >
                            <IonRow>
                                <IonCol><b>Revenue</b></IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="chart-frame ion-padding" size="12">
                                    {getChart1}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="6" >
                            <IonRow>
                                <IonCol><b>Revenue By Year</b></IonCol>
                                <IonCol className="ion-text-right" onClick={()=>{
                                    setCorrelationTableView1(!getCorrelationTableView1)
                                }}>
                                    <IonIcon icon={gridOutline} className="size-24 text-gt "></IonIcon>&nbsp;
                                    View Correlation Matrix
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="chart-frame ion-padding" size="12">
                                    {getChart11}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
        }
        const [getChartTreatment, setChartTreatment]          = useState<any>()
        const callPreTreatmentCharts = () =>{
            setShowLoading(true)
            var y:any       = []
            for(var i:any = 0 ; i < selectedOptions.account_level_0_key.length; i++){
                var x:any = []
                console.log(selectedOptions.account_level_0_key[i])
                                     
                y.push(<PredictiveFormTreatment
                        primary_host={props.props.primary_host}
                        account_key={selectedOptions.account_level_0_key[i]}
                        analytical_reviews_id={props.props.analytical_reviews_id }
                        start_date={txtStartDate.current!.value} 
                        end_date={txtEndDate.current!.value}
                        account_names={names[i]}
                        
                    />
                    )
                
            }
            if (true){
                //console.log(data.data)
                setView(4)
                setLinearProgression(false)
                setRatio(false)
                setTreatment(true)
                setMatrix(false)
                setTreatmentForecast(false)
            }
            setChartTreatment(y)
        }

        const callPostTreatmentCharts = () =>{
            setShowLoading(true)
            //var items:any = [9,10,25,26]
            for(var i = 0 ; i < items.length; i++){
                fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?pre=select_treatment_post"+
                    "&period_type="+0+
                    "&account_level_1_key="+items[i]+
                    "&analyticalreviewsid="+props.props.analytical_reviews_id+
                    "&startdate="+txtStartDate.current!.value+
                    "&enddate="+txtEndDate.current!.value,
                    {
                        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                    }
                )
                .then(response => response.json())
                .then(data =>{
                    console.log(data.data[0].account_level_1_key)
                    renderCharts4(data, data.data[0].account_level_1_key)
                    setShowLoading(false)
                })
                .catch(error=>{
                    setShowLoading(false)
                    var z:any = null
                    setSelectedAccount(z)
                })
            }
        }

        const [getChartTreatmentForecast, setChartTreatmentForecast]                = useState<any>()
        const callPostTreatmentForecastingCharts = () =>{
            setShowLoading(true)
            var y:any       = []
            for(var i:any = 0 ; i < selectedOptions.account_level_0_key.length; i++){
                var x:any = []
                console.log(selectedOptions.account_level_0_key[i])
                                     
                y.push(<PredictiveFormForecast
                        primary_host={props.props.primary_host}
                        account_key={selectedOptions.account_level_0_key[i]}
                        analytical_reviews_id={props.props.analytical_reviews_id }
                        start_date={txtStartDate.current!.value} 
                        end_date={txtEndDate.current!.value}
                        account_names={names[i]}
                        
                    />
                    )
                
            }
            if (true){
                //console.log(data.data)
                setView(5)
                setLinearProgression(false)
                setRatio(false)
                setTreatment(false)
                setMatrix(false)
                setTreatmentForecast(true)
            }
            setChartTreatmentForecast(y)
        }

        const callComparisonCharts = () =>{ 
            setShowLoading(true)
            //for(var i = 0; i < items.length; i++){
                fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?pre=select_period_year_comparison"+
                    "&period_type="+0+
                    "&analyticalreviewsid="+props.props.analytical_reviews_id+
                    "&startdate="+txtStartDate.current!.value+
                    "&enddate="+txtEndDate.current!.value+
                    "&account_level_1_key="+JSON.stringify(items).replaceAll('"',"").replaceAll("[","").replaceAll("]","")+
                    "&account_names="+JSON.stringify(names).replaceAll('"',"").replaceAll("[","").replaceAll("]","")
                    ,
                    {
                        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                    }
                )
                .then(response => response.json())
                .then(data =>{
                    callComparisonChartsCorrelation()
                    renderCharts(data)
                    setShowLoading(false)
                    setView(2)
                    setLinearProgression(false)
                    setRatio(true)
                    setTreatment(false)
                    setMatrix(false)
                    setTreatmentForecast(false)
                })
                .catch(error=>{
                    setShowLoading(false)
                    var z:any = null
                    setSelectedAccount(z)
                })
            //}
        }

        const callComparisonChartsCorrelation = () =>{ 
            setShowLoading(true)
            //for(var i = 0; i < items.length; i++){
                fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?pre=select_period_year_comparison_correlation"+
                    "&period_type="+0+
                    "&analyticalreviewsid="+props.props.analytical_reviews_id+
                    "&startdate="+txtStartDate.current!.value+
                    "&enddate="+txtEndDate.current!.value+
                    "&account_level_1_key="+JSON.stringify(items).replaceAll('"',"").replaceAll("[","").replaceAll("]","")+
                    "&account_names="+JSON.stringify(names).replaceAll('"',"").replaceAll("[","").replaceAll("]","")
                    ,
                    {
                        headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                    }
                )
                .then(response => response.json())
                .then(data =>{
                    var q:any = <CorrelationTable data={data.data} />
                    setCorrelationTable(q)
                    setShowLoading(false)
                //setMatrix(true)
                })
                .catch(error=>{
                    setShowLoading(false)
                    var z:any = null
                    setSelectedAccount(z)
                })
            //}
        }

        const renderCharts = (data:any) =>{
            setView(2)
            setRatio(true)
            setLinearProgression(false)
            setTreatment(false)
            setMatrix(false)
            setTreatmentForecast(false)
            var series =[]
            var prop:any;
            var headers:any = []
            var y:any = 0;
            var z:any = null;
            var r:any = 0;
            var c:any = 0;
            var tr:any = 0;
            var tp:any = 0;

            setSelectedAccount(z)
            var series =[]
            var prop:any;
            var headers:any = []
            var y:any = 0;
            var z:any = null;
            setSelectedAccount(z)
            for(prop in data.data[0]){
                if(prop != "datekey" && prop != "month" && prop != "year" ){
                    headers.push(prop)
                }
            }
            console.log(headers)
            console.log(data.data[0])
            console.log(data.data[0][headers[0]])
            for(var i = 0 ; i < headers.length; i++){
                var s:any = []
                for (var j = 0 ; j < data.data.length; j++){
                    if(isNumeric(data.data[j][headers[i]]))
                        s.push({x:data.data[j].datekey/1, y:data.data[j][headers[i]]/1})
                    if(!isNumeric(data.data[j][headers[i]]))
                        s.push({x:data.data[j].datekey/1, y:0})                    
                }
                series.push({name:headers[i], data:s, type:'spline'})
            }
            drawlineSeries("spline", series, 9, null, 'linear' )
              
            console.log(series) 
        }

        const renderCharts2 = (data:any, chart:any) =>{
            if (true){
                console.log(data.data)
                setView(3)
                setLinearProgression(true)
                setRatio(false)
                setTreatment(false)
                setMatrix(false)
                setTreatmentForecast(false)
            }
            //alert(chart)
            var series =[]
            var prop:any;
            var headers:any = []
            var y:any = 0;
            var z:any = null;
            setSelectedAccount(z)
            switch(chart/1){
                case 9  : headers.push("Revenue");break;
                case 10 : headers.push("Cost of Sales");break;
                case 25 : headers.push("Trade Receivables");break;
                case 26 : headers.push("Trade Payables");break;
            }
            console.log(headers)
            for(var i = 0 ; i < headers.length; i++){ 
                var s = []
                for (var j = 0 ; j < data.data.length; j++){
                    s.push({x:data.data[j].datekey/1, y:data.data[j][headers[i]]/1})
                }
                series.push({name:headers[i], data:s, type:'spline'})
                drawlineSeries("line", series, chart,null, 'linear' )
            }
            console.log(series) 
            console.log("chart_id:"+chart)
        }
        const renderCharts22 = (data:any, chart:any) =>{
            if (true){
                console.log(data.data)
                setView(3)
                setLinearProgression(true)
                setRatio(false)
                setTreatment(false)
                setMatrix(false)
                setTreatmentForecast(false)
            }
            //alert(chart)
            var series =[]
            var prop:any;
            var headers:any = []
            var y:any = 0;
            var z:any = null;
            setSelectedAccount(z)
            var x:any = 1
            for (prop in data.data[0]){
                if(prop!="DAY" && prop!="MONTH" && prop!="YEAR" && prop!="DESC"  && prop!="month" && prop!="year" )
                    headers.push(prop)
                x++
            }
            console.log(headers)
            for(var i = 0 ; i < headers.length; i++){ 
                var s = []
                for (var j = 0 ; j < data.data.length; j++){
                    //console.log(data.data[j][headers[i]])
                    //console.log(data.data[j].DAY)
                    if(data.data[j][headers[i]] == "null")
                        s.push({x: data.data[j].MONTH/1, y: 0})
                    if(data.data[j][headers[i]] != "null")
                        s.push({x: data.data[j].MONTH/1, y: data.data[j][headers[i]]/1})
                }
                series.push({name:headers[i], data:s, type:'spline'})
            }
            drawlineSeries2("line", series, chart,null, 'linear' )
            console.log(series) 
            console.log("chart_id:"+chart)
        }
        const renderCharts3 = (data:any, chart:any) =>{
            var series:any =[]
            //var s:any =[]
            var y:any = 0;
            var prop:any;
            var headers:any = []
            for(prop in data.data[0]){
                if(y >= 0){
                    if( 
                        prop == "1 stdev above"     || 
                        prop == "2 stdev above"     ||
                        prop == "3 stdev above"     || 
                        prop == "1 stdev below"     || 
                        prop == "2 stdev below"     ||
                        prop == "3 stdev below"     ||
                        prop == "mean"              ||
                        prop == "netdaily trend"    || 
                        prop == "value" 
                        ){
                        headers.push(prop)
                    }
                }
            }
            console.log(headers)
            for (var j = 0 ; j < headers.length; j++){
                var s = []
                for(var i = 0 ; i < data.data.length; i++){
                    s.push({x:data.data[i].period/1, y:data.data[i][headers[j]]/1})
                }
                if( 
                    headers[j] == "1 stdev above" || 
                    headers[j] == "2 stdev above" ||
                    headers[j] == "3 stdev above" || 
                    headers[j] == "1 stdev below" || 
                    headers[j] == "2 stdev below" ||
                    headers[j] == "3 stdev below" ||
                    headers[j] == "mean"          ||
                    headers[j] == "netdaily trend"
                ){
                    if( headers[j] == "1 stdev above" || headers[j] == "1 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'purple', marker:{radius:0, color: 'yellow'}})
                    if( headers[j] == "2 stdev above" || headers[j] == "2 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'orange', marker:{radius:0, color: 'orange'}})
                    if( headers[j] == "3 stdev above" || headers[j] == "3 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'red', marker:{radius:0, color: 'red'}})
                    if( headers[j] == "netdaily trend")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dash', radius:0, color: 'green', marker:{radius:0, color: 'green'}})
                    if( headers[j] == "mean")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, radius:0, color: 'blue', marker:{radius:0, color: 'blue'}})
                }else{
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, radius:0, color: 'black', marker:{radius:0, color: 'black'}})
                }
                drawlineSeries("line", series, chart,data.data[0].account_level_1, 'linear' )
            }
            console.log(series)
            //series.push({name:data.data[0].account_level_1, data:s})
            //drawlineSeries("spline", series, chart, null)
            setView(4)
            setRatio(false)
            setLinearProgression(false)
            setTreatment(true)
            setTreatmentForecast(false)
            console.log(series)
        }
        const renderCharts4 = (data:any, chart:any) =>{
            var series:any =[]
            //var s:any =[]
            var headers:any = []
            var prop:any = []
            var y:any = 0
            for(prop in data.data[0]){
                if(y >= 0){
                    //console.log(prop)
                    if( 
                        prop == "1 stdev above" || 
                        prop == "2 stdev above" ||
                        prop == "3 stdev above" || 
                        prop == "1 stdev below" || 
                        prop == "2 stdev below" ||
                        prop == "3 stdev below" ||
                        prop == "mean"          ||
                        prop == "netdaily trend" || 
                        //prop == "balance trend" ||
                        prop == "value" 
                        ){
                        headers.push(prop)
                    }
                }
            }
            console.log(headers)
            for (var j = 0 ; j < headers.length; j++){
                var s = []
                for(var i = 0 ; i < data.data.length; i++){
                    s.push({x:data.data[i].period/1, y:data.data[i][headers[j]]/1})
                }
                if( 
                    headers[j] == "1 stdev above" || 
                    headers[j] == "2 stdev above" ||
                    headers[j] == "3 stdev above" || 
                    headers[j] == "1 stdev below" || 
                    headers[j] == "2 stdev below" ||
                    headers[j] == "3 stdev below" ||
                    headers[j] == "mean"          ||
                    headers[j] == "netdaily trend"
                ){
                    if( headers[j] == "1 stdev above" || headers[j] == "1 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'purple', marker:{radius:0, color: 'yellow'}})
                    if( headers[j] == "2 stdev above" || headers[j] == "2 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'orange', marker:{radius:0, color: 'orange'}})
                    if( headers[j] == "3 stdev above" || headers[j] == "3 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'red', marker:{radius:0, color: 'red'}})
                    if( headers[j] == "netdaily trend")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dash', radius:0, color: 'green', marker:{radius:0, color: 'green'}})
                    if( headers[j] == "mean")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1,  radius:0, color: 'blue', marker:{radius:0, color: 'blue'}})
                }else{
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, radius:0, color: 'black', marker:{radius:0, color: 'black'}})
                }
                drawlineSeries2("line", series, chart,data.data[0].account_level_1, 'linear' )
            }
            //series.push({name:data.data[0].account_level_1, data:s})
            //drawlineSeries2("spline", series, chart, null,'linear')
            setView(4)
            setRatio(false)
            setLinearProgression(false)
            setTreatment(true)
            setTreatmentForecast(false)
            console.log(series)
        }

        const renderCharts5 = (data:any, chart:any) =>{
            var series:any =[]
            //var s:any =[]
            var headers:any = []
            var prop:any = []
            var y:any = 0
            for(prop in data.data[0]){
                if(y >= 0){
                    //console.log(prop)
                    if( 
                        prop == "1 stdev above" || 
                        prop == "2 stdev above" ||
                        prop == "3 stdev above" || 
                        prop == "1 stdev below" || 
                        prop == "2 stdev below" ||
                        prop == "3 stdev below" ||
                        prop == "mean"          ||
                        prop == "netdaily trend" || 
                        //prop == "balance trend" ||
                        prop == "value1"        ||
                        prop == "value2" 
                        ){
                        headers.push(prop)
                    }
                }
            }
            console.log(headers)
            for (var j = 0 ; j < headers.length; j++){
                var s = []
                for(var i = 0 ; i < data.data.length; i++){
                    s.push({x:data.data[i].period/1, y:data.data[i][headers[j]]/1})
                }
                if( 
                    headers[j] == "1 stdev above" || 
                    headers[j] == "2 stdev above" ||
                    headers[j] == "3 stdev above" || 
                    headers[j] == "1 stdev below" || 
                    headers[j] == "2 stdev below" ||
                    headers[j] == "3 stdev below" ||
                    headers[j] == "mean"          ||
                    headers[j] == "netdaily trend"
                ){
                    if( headers[j] == "1 stdev above" || headers[j] == "1 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'purple', marker:{radius:0, color: 'yellow'}})
                    if( headers[j] == "2 stdev above" || headers[j] == "2 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'orange', marker:{radius:0, color: 'orange'}})
                    if( headers[j] == "3 stdev above" || headers[j] == "3 stdev below")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dot', radius:0, color: 'red', marker:{radius:0, color: 'red'}})
                    if( headers[j] == "netdaily trend")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dash', radius:0, color: 'green', marker:{radius:0, color: 'green'}})
                    if( headers[j] == "mean")
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, radius:0, color: 'blue', marker:{radius:0, color: 'blue'}})
                }else{
                    if(headers[j] == "value2"){
                        series.push({name:headers[j], data:s, type:'spline', lineWidth:2, opacity:1, dashStyle: 'dash', radius:0, color: 'grey', marker:{radius:0, color: 'grey'}})
                    }else{
                        series.push({name:headers[j], data:s, type:'spline', zoneAxis: 'x',zone:[{value:(data.data.length-12)/1,dashStyle: 'dot'}], lineWidth:2, opacity:1, radius:0, color: 'black', marker:{radius:0, color: 'black'}})
                    }
                }
                drawlineSeries2("line", series, chart,data.data[0].account_level_1, 'linear' )
            }
            //series.push({name:data.data[0].account_level_1, data:s})
            //drawlineSeries2("spline", series, chart, null,'linear')
            setView(5)
            setRatio(false)
            setLinearProgression(false)
            setTreatment(false)
            setTreatmentForecast(true)
            console.log(series)
        }
        const [getChart1, setChart1]                            = useState<any>()
        const [getChart2, setChart2]                            = useState<any>()
        const [getChart3, setChart3]                            = useState<any>()
        const [getChart4, setChart4]                            = useState<any>()

        const [getChart11, setChart11]                          = useState<any>()
        const [getChart22, setChart22]                          = useState<any>()
        const [getChart33, setChart33]                          = useState<any>()
        const [getChart44, setChart44]                          = useState<any>()

        const [getData10, setData10]                            = useState<any>()
        const [getData9, setData9]                              = useState<any>()
        const [getData25, setData25]                            = useState<any>()
        const [getData26, setData26]                            = useState<any>()     

        const [getRatio, setRatio]                              = useState<any>(false)
        const [getLinearProgression, setLinearProgression]      = useState<any>(false)

        const [getName, setName]                                = useState<any>()
        const [getSection, setSection]                          = useState<any>()
        const [getBudgeting, setBudgeting]                      = useState<boolean>(false)

        const [getCorrelationTable, setCorrelationTable]        = useState<any>()
        const [getCorrelationTable1, setCorrelationTable1]      = useState<any>()
        const [getCorrelationTable2, setCorrelationTable2]      = useState<any>()
        const [getCorrelationTable3, setCorrelationTable3]      = useState<any>()
        const [getCorrelationTable4, setCorrelationTable4]      = useState<any>()
        const [getCorrelationTableView1, setCorrelationTableView1]      = useState<any>()
        const [getCorrelationTableView2, setCorrelationTableView2]      = useState<any>()
        const [getCorrelationTableView3, setCorrelationTableView3]      = useState<any>()
        const [getCorrelationTableView4, setCorrelationTableView4]      = useState<any>()
        const setActualDataId = (actual_analytical_reviews_id:any) =>{
            fetch(props.props.primary_host+"dbconnect/dbdynamic.jsp?pre=update_actual_analytical_reviews_id"+
                "&analytical_reviews_id="+props.props.analytical_reviews_id+
                "&actual_data_analytical_reviews_id="+actual_analytical_reviews_id
                ,
                {
                    headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
                }
            )
            .then(response => response.json())
            .then(data =>{
                setShowLoading(false)
            })
            .catch(error=>{
                setShowLoading(false)
                var z:any = null
                setSelectedAccount(z)
            })
        }
        React.useEffect(() => {
            
        }, []);
        return( 
        <div className="">
            <div className={getAccountSelector + " ion-padding"}>
                <IonHeader className="">
                    <IonRow>
                        <IonCol className="size-16"><b>Account Selector</b></IonCol>
                        <IonCol className="ion-text-right">
                            <IonIcon onClick={()=>{setAccountSelector("predictive-account-selector-hide"); setItemsArray()}} className="size-32" icon={closeOutline}></IonIcon>
                        </IonCol>
                    </IonRow>
                </IonHeader>
                <IonRow>
                    <IonCol size="3" className="ion-text-center ion-padding">
                        <IonRow>
                            <IonCol><b>Account Level 3</b></IonCol>
                        </IonRow>
                        <IonContent className="custom-account-content size-14">
                        <IonRow>
                            <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,3,1,"Assets");/*setAccountProperty(3,1,"Assets")*/}}>
                                <IonRow>
                                    <IonCol size="10" className="unselected"  >
                                        Assets
                                    </IonCol>
                                    <IonCol className="ion-text-right" onClick={()=>{}} >
                                        <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,3,3,"Liabilities")}}>
                                <IonRow>
                                    <IonCol size="10" className="unselected" >
                                        Liabilities
                                    </IonCol>
                                    <IonCol className="ion-text-right" onClick={()=>{}} >
                                        <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,3,2, "Owner Equity")}}>
                                <IonRow>
                                    <IonCol size="10" className="unselected" >
                                        Owners Equity
                                    </IonCol>
                                    <IonCol className="ion-text-right" onClick={()=>{}} >
                                        <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,3,4, "Income")}}>
                                <IonRow>
                                    <IonCol size="10" className="unselected" >
                                        Income
                                    </IonCol>
                                    <IonCol className="ion-text-right" onClick={()=>{}} >
                                        <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,3,5, "Expenses")}}>
                                <IonRow>
                                    <IonCol size="10" className="unselected" >
                                        Expenses
                                    </IonCol>
                                    <IonCol className="ion-text-right" onClick={()=>{}} >
                                        <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        </IonContent>
                    </IonCol>
                    <IonCol size="3" className="ion-text-center ion-padding">
                        <IonRow>
                            <IonCol><b>Account Level 2</b></IonCol>
                        </IonRow>
                        <IonContent className="custom-account-content size-14">
                            <IonRow>
                                <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,2,1,"Current Assets")}}>
                                    <IonRow>
                                        <IonCol size="10" className="unselected" >
                                            Current Assets
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,2,2,"Non Current Assets")}}>
                                    <IonRow>
                                        <IonCol size="10" className="unselected" >
                                            Non Current Assets
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,2,3,"Owners Equity")}}>
                                    <IonRow>
                                        <IonCol size="10" className="unselected" >
                                            Owners Equity
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,2,4,"Current Liabilities")}} >
                                    <IonRow>
                                        <IonCol size="10" className="unselected">
                                            Current Liabilities
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,2,5,"Non Current Liabilities")}}>
                                    <IonRow>
                                        <IonCol size="10" className="unselected" >
                                            Non Current Liabilities
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,2,6, "Income")}}>
                                    <IonRow>
                                        <IonCol size="10" className="unselected" >
                                        Income
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="custom-account-text-icon unselected" onClick={(e)=>{setSelectorOptions(e,2,7, "Expenses")}}>
                                    <IonRow>
                                        <IonCol size="10" className="unselected" >
                                            Expenses
                                        </IonCol>
                                        <IonCol className="ion-text-right" onClick={()=>{}} >
                                            <IonIcon icon={chevronForward} className="size-20"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonContent>
                    </IonCol>
                    <IonCol className="ion-text-center ion-padding">
                        <IonRow>
                            <IonCol><b>Account Level 1</b></IonCol>
                        </IonRow>
                        <IonContent className="custom-account-content size-14">
                            {getAccountLevel1}
                        </IonContent>
                    </IonCol>
                    <IonCol className="ion-text-center ion-padding">
                        <IonRow>
                            <IonCol><b>Account Level 0</b></IonCol>
                        </IonRow>
                        <IonContent className="custom-account-content size-14">
                            {getAccountLevel0}
                        </IonContent>
                    </IonCol>
                </IonRow>
            </div>
            
            <div className={getLiveConnections}>
                <ProjectsSide 
                    state={props.props} 
                    data={getLiveConnectionsListData}
                    setAnalyticalReviewsId={(e:any)=>{
                        console.log("return:"+props.props)
                        console.log(props.props)
                        console.log(e);
                        props.props.analytical_reviews_id = e.analytcal_reviews_id;
                        setAnalyticalReviewsId(e.analytcal_reviews_id);
                        txtStartDate.current!.value = e.start_date;
                        txtEndDate.current!.value = e.end_date;
                        setName(e.name)
                        setLiveConnections("predictive-live-connections-area-hide")
                        setPickAccount(true);
                        callDimAccountLevel0()
                        callDimAccountLevel1()
                        //callComparisonCharts()
                    }}
                    setState={(id:any)=>{}}
                    setLiveConnections={(e:any)=>{setLiveConnections(e)}}
                    getLiveConnectionsList={getLiveConnectionsListData}
                    setPageView={(e:any)=>{}} 
                    setOrganizationId={(e:any)=>{}} 
                    setOrganzationName={(e:any)=>{}} 
                    setLastUpdate={(e:any)=>{}} 
                    setYearEndDay={(e:any)=>{}} 
                    setYearEndMonth={(e:any)=>{}} 
                    setAuthorizedOrganization={(e:any)=>{}} 
                    setIndustry={(e:any)=>{}} 
                    setSector={(e:any)=>{}} 
                    setNativeCurrency={(e:any)=>{}} 
                    showSettingStage={(e:any)=>{}} 
                    callXeroAvailableYears={(e:any)=>{}} 
                />
            </div>
            <div className={getActualConnections}>
                <ProjectsSide 
                    state={props.props} 
                    data={getLiveConnectionsListData}
                    setAnalyticalReviewsId={(e:any)=>{
                        
                        props.props.analytical_reviews_id = e.analytcal_reviews_id;
                        setActualDataId(e.analytcal_reviews_id);
                        txtStartDate.current!.value = e.start_date;
                        txtEndDate.current!.value = e.end_date;
                        setActualConnections("predictive-live-connections-area-hide")
                        //callComparisonCharts()
                    }}
                    setState={(id:any)=>{}}
                    setLiveConnections={(e:any)=>{setLiveConnections(e)}}
                    getLiveConnectionsList={getLiveConnectionsListData}
                    setPageView={(e:any)=>{}} 
                    setOrganizationId={(e:any)=>{}} 
                    setOrganzationName={(e:any)=>{}} 
                    setLastUpdate={(e:any)=>{}} 
                    setYearEndDay={(e:any)=>{}} 
                    setYearEndMonth={(e:any)=>{}} 
                    setAuthorizedOrganization={(e:any)=>{}} 
                    setIndustry={(e:any)=>{}} 
                    setSector={(e:any)=>{}} 
                    setNativeCurrency={(e:any)=>{}} 
                    showSettingStage={(e:any)=>{}} 
                    callXeroAvailableYears={(e:any)=>{}} 
                />
            </div>
            <div className="predictive-chart-control-panel ion-padding ion-text-center size-10">
            <IonRow>
                <IonRow onClick={()=>{callLiveConnections();setView(1)}} className="ion-text-center  size-14">
                    <IonCol>
                        <IonIcon className="size-42 text-gt icon-start hover" icon={cloudOutline}></IonIcon> <br/>
                        <div className="">Data Source</div>
                    </IonCol>
                </IonRow>
                {getPickAccount &&
                <div>
                    <IonRow>
                        <IonCol>
                            <div onClick={()=>{setAccountSelector("predictive-account-selector-show")}} className="ion-text-center size-14">
                                <IonRow>
                                    <IonCol size="12" className="">
                                        <IonIcon className="size-42 icon-gt icon-start hover " icon={listOutline}></IonIcon><br/>
                                        <div className="">Pick Account To Forecast</div>
                                    </IonCol>
                                </IonRow>
                            </div> 
                        </IonCol> 
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div onClick={()=>{setActualConnections("custom-live-connections-area-show")}} className="ion-text-center size-14">
                                <IonRow>
                                    <IonCol size="12" className="">
                                        <IonIcon className="size-42 icon-gt icon-start hover " icon={cloudOutline}></IonIcon><br/>
                                        <div className="">Pick Actual Data</div>
                                    </IonCol>
                                </IonRow>
                            </div> 
                        </IonCol> 
                    </IonRow>
                </div>
                }    
                {(getNewChartProject && !getPickAccount) &&
                <IonRow className="size-14 ion-text-center">
                    <IonCol size="12"></IonCol>
                    <IonCol size="12" onClick={()=>{callComparisonCharts();setSection("Account Correlation"); }}>
                        <IonIcon className="size-42 icon-gt icon-start hover" icon={apps}></IonIcon> <br/>
                        <div className="">Account Correlation</div>
                    </IonCol>
                    <IonCol size="12"></IonCol>
                    <IonCol size="12" onClick={()=>{callYearlyComparisonChart();setSection("Yearly Comparison By Month")}}>
                        <IonIcon className="size-42 icon-gt icon-start hover" icon={statsChartOutline}></IonIcon> <br/>
                        <div className="">Account Correlation <br/> By Year</div>
                    </IonCol>
                    <IonCol size="12"></IonCol>
                    <IonCol size="12" onClick={()=>{callPreTreatmentCharts();setSection("Treatment")}}>
                        <IonIcon className="size-42 icon-gt icon-start hover" icon={analytics}></IonIcon> <br/>
                        <div className="">
                        Treatment</div>
                    </IonCol>
                    <IonCol size="12"></IonCol>
                    <IonCol size="12" onClick={()=>{setSection("Forecasting");callPostTreatmentForecastingCharts();}}>
                        <IonIcon className="size-42 icon-gt icon-start hover" icon={searchOutline}></IonIcon> <br/>
                        <div className="">Forecast</div>
                </IonCol>
                </IonRow>
                }
            </IonRow>
            </div>
            <div className="predictive-chart-panel ion-padding">
                <IonHeader>
                    <IonRow>
                        <IonCol className="size-16"><b>Predictive Analysis - {getName}</b></IonCol>
                        <IonCol size="3" className="ion-text-right">
                            <IonLabel className="size-14">Start Date:&nbsp;</IonLabel> 
                            <IonInput ref={txtStartDate}  placeholder="Start Date" type="date" className='input-style-11 size-16 '/>
                        </IonCol>
                        <IonCol size="3" className="ion-text-right">
                            <IonLabel className="size-14">End Date:&nbsp;</IonLabel>
                            <IonInput ref={txtEndDate}   placeholder="End Date" type="date"  className='input-style-11 size-16 '/> 
                        </IonCol>
                        <IonCol size="1">
                            <div className="fetch-button" onClick={()=>{start_date = txtStartDate.current!.value; end_date = txtEndDate.current!.value;/*callComparisonCharts()*/}}>Fetch</div>
                        </IonCol>
                    </IonRow>
                </IonHeader>
                <IonContent className="predictive-main">
                    <IonRow>
                        <IonCol className="text-gt">
                            <b>{getSection}</b>
                        </IonCol>
                    </IonRow>
                    <div>
                        {getRatio &&
                            <div className='predictive-chart-area'>
                                <IonRow>
                                    <IonCol><b>Correlation By Account Chart</b></IonCol>
                                    <IonCol onClick={()=>{setRatio(false); setMatrix(true)}}  className="ion-text-right">
                                        <IonIcon icon={gridOutline} className="size-24 text-gt "></IonIcon>&nbsp;
                                        <b>Show Matrix</b>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol  onLoad={()=>{}}  className="chart-frame ion-padding" size="12">{getChart1}</IonCol>
                                </IonRow>
                            </div>
                            }
                            {getMatrix &&
                            <div className='predictive-chart-area'>
                                <IonRow>
                                    <IonCol size="12" >
                                        <IonRow>
                                            <IonCol><b>Correlation By Account Maxtrix</b></IonCol>
                                            <IonCol onClick={()=>{setRatio(true); setMatrix(false)}} className="ion-text-right">
                                                <IonIcon icon={analyticsOutline} className="size-24 text-gt "></IonIcon>&nbsp;
                                                <b>Show Chart</b>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow> 
                                            <IonCol className="chart-frame ion-padding" size="12">
                                                {getCorrelationTable}
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            </div>
                        }
                    </div>
                    {getTreatment &&
                    <div>
                        {getChartTreatment}
                    </div>
                    }
                    {getTreatmentForecast &&
                    <div>
                        {getChartTreatmentForecast}
                    </div>
                    }
                    {getLinearProgression &&
                    <div>
                        {getChartCorrelationByYear}
                    </div>
                    }

                </IonContent>
                {/*<IonFooter>
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol size="12" className="size-16"><b>Selected Accounts</b></IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonFooter>*/}
            </div>
        </div>
    )
}
export default PredictiveForm;