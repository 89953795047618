import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonInput, IonHeader,IonRow, IonCol, IonIcon, IonLabel, IonFooter, IonRadioGroup, IonRadio, IonContent, IonItem, IonCheckbox, IonButton } from '@ionic/react';
import { chevronDown, print, listOutline, analytics, returnUpBack, businessOutline, calendarOutline, shareOutline, trashOutline, settingsOutline, calculator} from 'ionicons/icons';
import { useState,useEffect, useRef } from 'react';
import { callBalanceSheetInitial, callBalanceByAccount, callMovementByAccount, callAnalyticalReviews, callSummaryDetail, callDetailTransactions, callXeroAvailableYears, callFactDebtRatio, callFactDebtToEquityRatio, callFactCurrentRatio, callFactWorkingCapitalToAssetsRatio, callFactIncomeExpenses, callBalanceSheetMonthlyPivot, callFactAccountBalance, callCustomCharts, callBalanceSheet, callJournalProjects } from '../../dbconnections/Select';
import { addCommas } from '../../functions/functions';
var migrationId:any = 0;
var project_name:any = 0;
let testListData:any;
let exportList:any ={
    117:1,
    118:1,
    119:1,
    121:1,
    122:1,
    123:1,
    124:1,
    125:1,
    //126:1,
    127:1,
    128:1,
    129:1,
    130:1,
    145:1,
    146:1,
    //147:1,
    151:1
}
const include =(testId:any)=>{
    let list:any = [117,118,119,121,122,123,124,125,126,127,128,129,130,145,146,147,151]
    for(var i = 0 ; i < list.length;i++){
        if(testId == list[i]){
            return true;
        }
    }
    return false;
}
let export_journal_id :any = "";
const ExportTests = (props:any) =>{
    const [getPrintList, setPrintList]              = useState<any>();
    const callJournalTestExportList = (id:any, project:any) =>{
        //setShowLoading(true)
        migrationId = props.migration_id;
        project_name = props.project_name;
        fetch(props.primary_host+"select?etl=selectmigrationmanifesttable"+
        "&migration_id="+id+
        "&isdistinct=1"
        ,
        {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
        }
        )
        .then(response => response.json())
        .then(data =>{
            //setShowLoading(false)
            testListData = data;
            renderJournalTestExportList(id,data.data, false);
        })
        .catch(error=>{
        alert("call export tests:"+error)
        //setShowLoading(false)
        })
    }
    const renderJournalTestExportList = (migrationId:any, data:any, showTable:any) =>{
        //setShowLoading(true)
        let d:any = []
        
        data.sort(function(a:any, b:any) {
            if (true) {
                return (a["test_name"] > b["test_name"]) ? 1 : ((a["test_name"] < b["test_name"]) ? -1 : 0);
            } else {
                return (b["test_name"] > a["test_name"]) ? 1 : ((b["test_name"] < a["test_name"]) ? -1 : 0);
            }
        })
        
        d.push(data)
        let tests:any = d[0].map((x:any, i:number)=>{
            if(x.test_name != "null" && include(x.journal_test_id))
            return(
            
            <IonCol key={i} size="6">
            <IonRow>
                <IonCol size="8">
                <IonItem  >
                        <IonCheckbox id={"export_"+x.journal_test_id} className="export_list_item" onIonChange={ev=>{if(ev.detail.checked && x.rows < 1000000){setExportList(x.journal_test_id, 1)}else{setExportList(x.journal_test_id, 0)}}} disabled={x.rows < 1000000? false:true} checked={x.rows < 1000000? true:false} ></IonCheckbox>&nbsp;&nbsp;
                        <IonLabel className="ptext">{x.test_name}</IonLabel>
                </IonItem>
                </IonCol>
                <IonCol className="ion-padding">
                ({addCommas(x.rows)})
                </IonCol>
                {x.rows < 1000000?
                <IonCol className="ion-padding">
                    <FontAwesomeIcon icon={faFileExcel} className="size-32" onClick={ev=>{export_journal_id=x.journal_test_id;callExportList()}}></FontAwesomeIcon>
                </IonCol>
                :
                <IonCol className="ion-padding">
                    <FontAwesomeIcon icon={faFileCsv} className="size-32" onClick={ev=>{export_journal_id=x.journal_test_id;callExportList()}}></FontAwesomeIcon>
                </IonCol>
                }
            </IonRow>
            </IonCol>
            )
        })
        //setTestList2(tests)
        //setShowLoading(false)
        setPrintList(tests);
       //setLevel(10);
    }
    const callExportList = () =>{
        //setShowLoading(true);
        alert("You are about to export all exceptions. Please allow a few moments for processing")
        //projectName = projectName;
        fetch(props.primary_host+"GetReport?"+
          "migration_id="+migrationId+
          "&journal_id="+export_journal_id
          ,
          {
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
          }
        )
        .then(response => response)
        .then(data =>{
          console.log(data)
          //setShowLoading(false)
          var url = props.primary_host+'files/out/'
          console.log(url+project_name+'.xlsx')
          window.location.assign(url+project_name+'.xlsx');
        })
        .catch(error =>{
          //setShowLoading(false)
          alert("export excel workbook:"+error)
        })
      }  
    const setExportJournalId = () =>{
        var prop:any;
        export_journal_id = ""
        for(prop in exportList){
        console.log(prop)
        console.log(exportList[prop])
        if(exportList[prop] == 1){
            if(export_journal_id == ""){
            export_journal_id = prop
            }else{
            export_journal_id = export_journal_id +","+ prop
            }
        }
        }
        console.log(export_journal_id)
    }
    const setExportList = (id:any, status:any) =>{
        exportList[id] = status
        console.log(exportList)
        var i = "export_"+id;
        console.log(i)
        var item:any = document.getElementById(i)
        item.checked = status;
    }
    const clearExportList = () =>{
        let prop:any;
        for(prop in exportList){
        setExportList(prop, 0)
        }
    }
    const setAllExportList = () =>{
        let prop:any;
        for(prop in exportList){
        setExportList(prop, 1)
        }
    }
    useEffect(() => {
        callJournalTestExportList(props.migration_id,props.project_name)
    }, [props]); 
    return(
        <div>
            <IonRow>
                <IonCol size="10">
                    <b>Test List For Export</b>
                </IonCol>
                <IonCol>
                    <IonButton onClick={ev=>{clearExportList()}}>Clear All</IonButton>
                </IonCol>
                <IonCol>
                    <IonButton onClick={ev=>{setAllExportList()}}>Set All</IonButton>
                </IonCol>
            </IonRow>
            <IonRow>
                {getPrintList}
            </IonRow>
            <IonRow>
                <IonCol size="10"></IonCol>
                <IonCol>
                    <IonButton onClick={ev=>{setExportJournalId();callExportList()}}>Export Selected Reports</IonButton>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default ExportTests