import { IonSearchbar, IonLoading, IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRadio, IonRadioGroup, IonRange, IonRow, IonSelect, IonSelectOption, IonSpinner, IonThumbnail, IonTitle, IonToolbar, IonTextarea, IonPopover, IonVirtualScroll, IonCheckbox, IonCardTitle } from '@ionic/react';
import { apps,starOutline, ellipsisVerticalOutline, businessOutline, checkmarkOutline, analyticsOutline, analyticsSharp, arrowBackSharp, cloudOutline, documentOutline, filterSharp, gridOutline, homeOutline, list, listOutline, logInOutline, logOutOutline, menuOutline, personOutline, pulseOutline, settingsOutline, wifiOutline, trashBin, trashBinOutline, checkmarkCircle, closeCircle, refreshCircle, caretUp, caretDown, pulseSharp, pulse, notifications, notificationsCircle, pencilSharp, closeOutline, trashOutline, star, arrowForwardCircleOutline, print, chevronForward, chevronDown, searchOutline, videocamOutline, codeDownload, clipboardOutline, logoGoogle, logoGooglePlaystore, shareOutline, returnUpBack, analytics, grid, createOutline, addOutline, statsChartOutline, heartDislikeSharp, searchCircle } from 'ionicons/icons';
import React,{ useRef, useState,  } from 'react';
import Highcharts, { isNumber } from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import './PredictiveForm.css' 
import ProjectsSide from '../SideBar/SubComponents/ProjectsSidePredictive';
import { callAccountComparisonByYear, callAccountComparisonByYearCorrelationMatrix, callAnalyticalReviews, callYearlyComparison } from '../../dbconnections/Select';
import SkewTable from '../Tables/Predictive/SkewTable';
import CorrelationTable from '../Tables/Predictive/CorrelationTable';
import { isNumeric } from '../../functions/functions';
import { Resizable } from 're-resizable';
    var items:any = []
    var account_names:any = []
    var start_date:any              = '2021-01-01';
    var end_date:any                = '2021-12-31';
    var account_level_0:any         ='0';
    var account_level_1:any         ='0';
    var account_level_2:any         ='0';
    var account_level_3:any         ='0';
    let dim3List:any                =[];
    let dim3Filter:any              = "";
    let action_type_id:any          = 1;
    let custom_chart_id:any         =0;
    const setDim3Filter = (id:any) =>{
        for(var i = 0; i < dim3List.length; i++){
            console.log("Before:"+dim3List[i].id+" "+dim3List[i].isChecked)
            if(dim3List[i].id == id){
                dim3List[i].isChecked = !dim3List[i].isChecked;
            }
            console.log("After:"+dim3List[i].id+" "+dim3List[i].isChecked)
        }
        
        dim3Filter="";
        for(var i = 0 ; i < dim3List.length; i++){
            if(dim3List[i].isChecked && dim3Filter.length > 0){
                dim3Filter = dim3Filter + ","+ dim3List[i ].id+"";
            }
            if(dim3List[i].isChecked && dim3Filter.length == 0){
                dim3Filter = ""+ dim3List[i].id+"";
            }
            
        }
        console.log(dim3Filter)
    }
    let dim2List:any                =[];
    let dim2Filter:any              = "";
    const setDim2Filter = (id:any) =>{
        for(var i = 0; i < dim2List.length; i++){
            console.log("Before:"+dim2List[i].id+" "+dim2List[i].isChecked)
            if(dim2List[i].id == id){
                dim2List[i].isChecked = !dim2List[i].isChecked;
            }
            console.log("After:"+dim3List[i].id+" "+dim3List[i].isChecked)
        }
        
        dim2Filter="";
        for(var i = 0 ; i < dim2List.length; i++){
            if(dim2List[i].isChecked && dim2Filter.length > 0){
                dim2Filter = dim2Filter + ","+ dim2List[i ].id+"";
            }
            if(dim2List[i].isChecked && dim2Filter.length == 0){
                dim2Filter = ""+ dim2List[i].id+"";
            }
            
        }
        console.log(dim2Filter)
    }
    var existing:any = []
    var properties:any = ''
    const PredictiveFormYearly = (props:any) =>{

        const [getChart1, setChart1]                                    = useState<any>()
        const [getChart2, setChart2]                                    = useState<any>()
        const [getCorrelationTableView1, setCorrelationTableView1]      = useState<any>()
        const [getCorrelationTable1, setCorrelationTable1]              = useState<any>()

        const renderCharts2 = (data:any, chart:any, name:any) =>{
            if (true){
                console.log(data.data)
            }
            //alert(chart)
            var series =[]
            var prop:any;
            var headers:any = []
            var y:any = 0;
            var z:any = null;
            //setSelectedAccount(z)
            //switch(chart/1){
            //    case 9  : headers.push("Revenue");break;
            //    case 10 : headers.push("Cost of Sales");break;
            //    case 25 : headers.push("Trade Receivables");break;
            //    case 26 : headers.push("Trade Payables");break;
            //}

            console.log(name)
            
            var s = []
            for (var j = 0 ; j < data.data.length; j++){
                s.push({x:data.data[j].datekey/1, y:data.data[j][name]/1})
            }
            series.push({name:name, data:s, type:'spline'})
            drawlineSeries("line", series, chart,null, 'linear' )
            
            console.log(series) 
            console.log("chart_id:"+chart)
        }
        const renderCharts22 = (data:any, chart:any) =>{
            if (true){
                console.log(data.data)
            }
            //alert(chart)
            var series =[]
            var prop:any;
            var headers:any = []
            var y:any = 0;
            var z:any = null;
            //setSelectedAccount(z)
            var x:any = 1
            for (prop in data.data[0]){
                if(prop!="DAY" && prop!="MONTH" && prop!="YEAR" && prop!="DESC"  && prop!="month" && prop!="year" )
                    headers.push(prop)
                x++
            }
            console.log(headers)
            for(var i = 0 ; i < headers.length; i++){ 
                var s = []
                for (var j = 0 ; j < data.data.length; j++){
                    //console.log(data.data[j][headers[i]])
                    //console.log(data.data[j].DAY)
                    if(data.data[j][headers[i]] == "null")
                        s.push({x: data.data[j].MONTH/1, y: 0})
                    if(data.data[j][headers[i]] != "null")
                        s.push({x: data.data[j].MONTH/1, y: data.data[j][headers[i]]/1})
                }
                series.push({name:headers[i], data:s, type:'spline'})
            }
            drawlineSeries2("line", series, chart,null, 'linear' )
            console.log(series) 
            console.log("chart_id:"+chart)
        }
        const renderCharts = (data:any, chart:any) =>{
            var series =[]
            var prop:any;
            var headers:any = []
            var y:any = 0;
            var z:any = null;
            var x:any = 1
            for (prop in data.data[0]){
                if(prop!="datekey" && prop!="DAY" && prop!="MONTH" && prop!="YEAR" && prop!="DESC"  && prop!="month" && prop!="year" )
                    headers.push(prop)
                x++
            }
            console.log(headers)
            for(var i = 0 ; i < headers.length; i++){ 
                var s = []
                for (var j = 0 ; j < data.data.length; j++){
                    //console.log(data.data[j][headers[i]])
                    //console.log(data.data[j].DAY)
                    if(data.data[j][headers[i]] == "null")
                        s.push({x: data.data[j].MONTH/1, y: 0})
                    if(data.data[j][headers[i]] != "null")
                        s.push({x: data.data[j].MONTH/1, y: data.data[j][headers[i]]/1})
                }
                series.push({name:headers[i], data:s, type:'spline'})
            }
            drawlineSeries2("line", series, chart,null, 'linear' )
            console.log(series) 
            console.log("chart_id:"+chart)
        }
        const drawlineSeries = (type:any, series:any, chart:any, chartTitle:any, axisType:any) =>{
            let o:any = null
            setChart1(o)
            let lineChart:any = {
                            chart:{
                                type: type,
                            },
                            title:{
                                text:''
                            },
                            subTitle:{
                                text:''
                            },
                            xAxis: {
                                type: axisType
                            },
                            series:series,
                            turboThreshold:0,
                            responsive: {
                                rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    layout: 'horizontal'
                                    },
                                    yAxis: {
                                    labels: {
                                        align: 'left',
                                        x: 0,
                                        y: -5
                                    },
                                    title: {
                                        text: chartTitle
                                    }
                                    },
                                    subtitle: {
                                    text: null
                                    },
                                    credits: {
                                    enabled: false
                                    }
                                }
                                }]
                            }
                        };
            
            let c:any = (<HighchartsReact onLoad={(e:any)=>{}} highcharts={Highcharts} options={lineChart}></HighchartsReact>)
            setChart1(c)
            
        }
        const drawlineSeries2 = (type:any, series:any, chart:any, chartTitle:any, axisType:any) =>{
            let o:any = null
            setChart2(o)

            let lineChart:any = {
                            chart:{
                                type: type,
                                
                            },
                            title:{
                                text:''
                            },
                            subTitle:{
                                text:''
                            },
                            xAxis: {
                                type: {axisType}
                            },
                            series:series,
                            turboThreshold:0,
                            responsive: {
                                rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    layout: 'horizontal'
                                    },
                                    yAxis: {
                                    labels: {
                                        align: 'left',
                                        x: 0,
                                        y: -5
                                    },
                                    title: {
                                        text: chartTitle
                                    }
                                    },
                                    subtitle: {
                                    text: null
                                    },
                                    credits: {
                                    enabled: false
                                    }
                                }
                                }]
                            }
                        };
            let c:any = (<HighchartsReact onLoad={(e:any)=>{}} highcharts={Highcharts} options={lineChart} ></HighchartsReact>)
            console.log(chart)
            setChart2(c)
            
        }
        const callYearlyComparisonChart = async () =>{
            //setShowLoading(true)
            //for(var i:any = 0 ; i < items.length; i++){
                var x:any = []
                x.push({
                    primary_host:props.primary_host,
                    key:props.account_key, 
                    analytical_reviews_id:props.analytical_reviews_id, 
                    start_date:props.start_date, 
                    end_date:props.end_date,
                    account_names:props.account_names

                })
                var data:any  = await callYearlyComparison(x[0])
                renderCharts2(data, props.account_key, props.account_names)
                //if(items[i]/1 == 26)
                //setShowLoading(false)
                
            //}
            callAccountComparisonByYearChart()
        }

        const callAccountComparisonByYearChart = async () =>{
            //setShowLoading(true)
            //var items:any = [9,10,25,26]
            //for(var i:any = 0 ; i < items.length; i++){
                var x:any = []
                x.push({
                    primary_host:props.primary_host,
                    key:props.account_key, 
                    analytical_reviews_id:props.analytical_reviews_id, 
                    start_date:props.start_date, 
                    end_date:props.end_date,
                    account_names:props.account_names
                })
                var data:any  = await callAccountComparisonByYear(x[0])
                renderCharts22(data, props.account_key)
                //setShowLoading(false)
                //yearlyChart.push(<PredictiveFormYearly data={data} />)
            //}
            callAccountComparisonByYearCorrelation()
        }

        const callAccountComparisonByYearCorrelation = async () =>{
            //setShowLoading(true)
            //var items:any = [9,10,25,26]
            //for(var i:any = 0 ; i < items.length; i++){
                var x:any = []
                x.push({
                    primary_host:props.primary_host,
                    key:props.account_key, 
                    analytical_reviews_id:props.analytical_reviews_id, 
                    start_date:props.start_date, 
                    end_date:props.end_date,
                    account_names:props.account_names
                })
                var data:any  = await callAccountComparisonByYearCorrelationMatrix(x[0])
                
                //if(items[i] == 9){
                var q:any = <CorrelationTable data={data.data} />
                setCorrelationTable1(q)
                //}
                /*if(items[i] == 10){
                    var q:any = <CorrelationTable data={data.data} />
                    setCorrelationTable2(q)
                }
                if(items[i] == 25){ 
                    var q:any = <CorrelationTable data={data.data} />
                    setCorrelationTable3(q)
                }
                if(items[i] == 26){
                    var q:any = <CorrelationTable data={data.data} />
                    setCorrelationTable4(q)
                }*/
                
                //setShowLoading(false)
            //}
        }
        React.useEffect(() => {
            //renderCharts(props.data,0)
            callYearlyComparisonChart()
            //callAccountComparisonByYearChart()
        }, []);
        return(
            <div className='size-12'>
            {!getCorrelationTableView1 &&
                <IonRow>
                    <IonCol size="6" className='ion-padding' >
                        <IonRow>
                            <IonCol><b>{props.account_names}</b></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="chart-frame ion-padding" size="12">
                                {getChart1}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol size="6" className='ion-padding' >
                        <IonRow>
                            <IonCol><b>{props.account_names} By Year</b></IonCol>
                            <IonCol className="ion-text-right" onClick={()=>{
                                setCorrelationTableView1(!getCorrelationTableView1)
                            }}>
                                <IonIcon icon={gridOutline} className="size-16 text-gt "></IonIcon>&nbsp;
                                View Correlation Matrix
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="chart-frame ion-padding" size="12">
                                {getChart2}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            }
            {getCorrelationTableView1 &&
                <IonRow>
                    <IonCol size="12" >
                        <IonRow>
                            <IonCol><b>{props.account_names} By Year Correlation Matrix</b></IonCol>
                            <IonCol className="ion-text-right" onClick={()=>{
                                setCorrelationTableView1(!getCorrelationTableView1)
                            }}>
                                <IonIcon icon={analyticsOutline} className="size-24 text-gt "></IonIcon>&nbsp;
                                View Chart
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="chart-frame ion-padding" size="12">
                                {getCorrelationTable1}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            }
            </div>
        )
    }
    export default PredictiveFormYearly